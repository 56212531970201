import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
// import withStyles from "@material-ui/core/styles/withStyles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableRow from "@material-ui/core/TableRow";
import Tabs from 'components/CustomTabs/CustomTabs.jsx'
import Cloud from '@material-ui/icons/Cloud'
import test1 from 'assets/img/test1.png'
import test2 from 'assets/img/test2.png'

import tasksStyle from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.jsx'
import { API } from 'aws-amplify'
import Button from 'components/CustomButtons/Button.jsx'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import Accordion from 'components/Accordion/Accordion.jsx'

//core for react-intl
import { FormattedMessage } from 'react-intl'

const moment = require('moment')

class SmallEconomicInfoTabs extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        console.log(this.props.currency_from_id)
        console.log(this.props.currency_to_id)
    }

    render() {
        const { classes } = this.props

        return (
            <Tabs
                title="Currency Tools:"
                headerColor="info"
                tabs={[
                    {
                        tabName: 'Economic Calendar',
                        tabIcon: Cloud,
                        tabContent: <React.Fragment>{test1}</React.Fragment>,
                    },
                    {
                        tabName: 'FX Charts',
                        tabIcon: Cloud,
                        tabContent: (
                            <React.Fragment>
                                <img src={test2} />
                            </React.Fragment>
                        ),
                    },
                    {
                        tabName: 'FX Rates',
                        tabIcon: Cloud,
                        tabContent: <React.Fragment>Tab 3</React.Fragment>,
                    },
                    {
                        tabName: 'Economic Holidays',
                        tabIcon: Cloud,
                        tabContent: <React.Fragment>Tab 4</React.Fragment>,
                    },
                ]}
            />
        )
    }
}

export default SmallEconomicInfoTabs
