import { API } from 'aws-amplify'

export const BENEFICIARIES_SELECT_SET_ID = 'BENEFICIARIES_SELECT_SET_ID'
export const IDENTIFICATION_SELECT_UI = 'IDENTIFICATION_SELECT_UI'
export const IDENTIFICATION_UPDATE_NEW_IDENTIFICATION_CREATION =
    'IDENTIFICATION_UPDATE_NEW_IDENTIFICATION_CREATION'
export const IDENTIFICATION_FETCH_IDENTIFICATION_REQUEST =
    'IDENTIFICATION_FETCH_IDENTIFICATION_REQUEST'
export const IDENTIFICATION_FETCH_IDENTIFICATION_SUCCESS =
    'IDENTIFICATION_FETCH_IDENTIFICATION_SUCCESS'
export const IDENTIFICATION_FETCH_BENEFICIARY_LIST_REQUEST =
    'IDENTIFICATION_FETCH_BENEFICIARY_LIST_REQUEST'
export const IDENTIFICATION_FETCH_IDENTIFICATION_LIST_SUCCESS =
    'IDENTIFICATION_FETCH_IDENTIFICATION_LIST_SUCCESS'
export const IDENTIFICATION_START_NEW_IDENTIFICATION_CREATION =
    'IDENTIFICATION_START_NEW_IDENTIFICATION_CREATION'
export const BENEFICIARIES_UPDATE_NEW_BENEFICIARY_CREATION =
    'BENEFICIARIES_UPDATE_NEW_BENEFICIARY_CREATION'
export const BENEFICIARIES_NEW_BENEFICIARY_DATABASE_CREATE_REQUEST =
    'BENEFICIARIES_NEW_BENEFICIARY_DATABASE_CREATE_REQUEST'
export const IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_START =
    'IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_START'
export const IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_SUCCESS =
    'IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_SUCCESS'

export function fetchIdentificationListRequest() {
    return {
        type: IDENTIFICATION_FETCH_BENEFICIARY_LIST_REQUEST,
    }
}

export function fetchIdentificationListSuccess(data) {
    return {
        type: IDENTIFICATION_FETCH_IDENTIFICATION_LIST_SUCCESS,
        identification_list: data,
        receivedAt: Date.now(),
    }
}

export function fetchIdentificationList() {
    return function(dispatch) {
        dispatch(fetchIdentificationListRequest())
        API.get('identification', `/get/list_not_deleted`)
            .then(response => {
                // console.log(response);
                dispatch(fetchIdentificationListSuccess(response))
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export async function createNewIdentificationRecordInDatabase(data) {
    // console.log(data);
    let database_result = API.post('identification', `/create/in-database`, {
        body: {
            ...data,
        },
    })
        .then(database_result => {
            // console.log(database_result);

            API.post('email', `/interaction/main`, {
                body: {
                    interaction: 'Identification document uploaded',
                    data: data,
                    result: database_result.insertId,
                },
            })
                .then(response => {
                    // console.log(response);
                })
                .catch(error => {
                    console.log(error)
                })
            API.post('email', `/client/identification-document-uploaded`, {
                body: {
                    data: data,
                },
            })
                .then(response => {
                    // console.log(response);
                })
                .catch(error => {
                    console.log(error)
                })

            return database_result
        })
        .catch(error => {
            console.log(error)
        })
    return database_result
}

export function createNewIdentificationRecord(data) {
    // console.log(data);
    return async function(dispatch) {
        dispatch(newIdentificationRecordCreationDatabaseStart())

        try {
            let database_result = await createNewIdentificationRecordInDatabase(
                data
            )
            // console.log(database_result);
            dispatch(
                newIdentificationRecordCreationDatabaseSuccess(
                    database_result.insertId
                )
            )
            // dispatch(insertIntoLagacyCRM(database_result, data));
            return database_result
        } catch (e) {
            console.log(e)
        }
    }
}

export function selectIdentificationUi(value) {
    return {
        type: IDENTIFICATION_SELECT_UI,
        value: value,
    }
}

export function setBeneficiarySelectId(id) {
    return {
        type: BENEFICIARIES_SELECT_SET_ID,
        value: id,
    }
}

export function loadIdentificationRecordDetailsRequest() {
    return {
        type: IDENTIFICATION_FETCH_IDENTIFICATION_REQUEST,
    }
}
export function loadIdentificationRecordDetailsSuccess() {
    return {
        type: IDENTIFICATION_FETCH_IDENTIFICATION_SUCCESS,
    }
}
export function startNewIdentificationRecordCreation() {
    return {
        type: IDENTIFICATION_START_NEW_IDENTIFICATION_CREATION,
    }
}

export function updateNewIdentificationRecordCreation(key, value) {
    // console.log(key, value);
    return {
        type: IDENTIFICATION_UPDATE_NEW_IDENTIFICATION_CREATION,
        value: { key, value },
    }
}

export function newIdentificationRecordCreationDatabaseStart() {
    return {
        type: IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_START,
    }
}

export function newIdentificationRecordCreationDatabaseSuccess(id) {
    return {
        type: IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_SUCCESS,
        value: id,
    }
}
