import React from 'react'
import { API } from 'aws-amplify'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react'
import CustomReactSelect from '../../Forms/CustomReactSelect/CustomReactSelect'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'

const axios = require('axios')

const style = {
    ...customCheckboxRadioSwitch,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    infoText: {
        fontWeight: '300',
        margin: '10px 0 30px',
        textAlign: 'center',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    inputAdornment: {
        position: 'relative',
    },
}

const min_transfer_amount = 0

class Step1TransferTo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            from_currency: '',
            to_currency: '',
            from_amount: '',
            to_amount: '',

            from_currency_state: 'error',
            to_currency_state: 'error',
            from_amount_state: 'error',
            to_amount_state: 'error',

            beneficiaries_list: [],
            beneficiary_id: '',
            beneficiary: {},
            purpose_of_payment_detail_state: '',
            amount_to_state: '',
            currencies_list: [],
            currencies_list_priority: [],
        }
    }

    componentDidMount() {
        API.get('currencies', `/currencies/get-list-priority`)
            .then(response => {
                this.setState(
                    {
                        // currencies_list: response.fullList,
                        currencies_list_priority: response,
                    },
                    () => {
                        this.queryStrings()
                    }
                )
            })
            .catch(error => {
                console.log(error)
            })

        API.get('beneficiaries', `/beneficiaries/get`)
            .then(response => {
                this.setState({
                    beneficiaries_list: response,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    queryStrings() {
        // console.log(this.state);
        // console.log(this.props);
        const query_strings = queryString.parse(this.props.location.search)
        // console.log(query_strings);
        if (Object.keys(query_strings).length !== 0) {
            if (query_strings.from_amount) {
                this.setState(
                    {
                        from_currency: Number(query_strings.from_currency),
                        from_currency_state: 'success',
                        to_currency: Number(query_strings.to_currency),
                        to_currency_state: 'success',
                        from_amount: query_strings.from_amount,
                        from_amount_state: 'success',
                        last_user_type_amount_name: 'from_amount',
                    },
                    () => {
                        this.handleAmount('to_amount')
                    }
                )
            }
            if (query_strings.to_amount) {
                this.setState(
                    {
                        from_currency: Number(query_strings.from_currency),
                        from_currency_state: 'success',
                        to_currency: Number(query_strings.to_currency),
                        to_currency_state: 'success',
                        to_amount: query_strings.to_amount,
                        to_amount_state: 'success',
                        last_user_type_amount_name: 'to_amount',
                    },
                    () => {
                        this.handleAmount('from_amount')
                    }
                )
            }
        }
    }

    getBeneficiaryDetails(beneficiary_id) {
        API.get('beneficiaries', `/beneficiaries/getb/${beneficiary_id}`)
            .then(response => {
                this.setState({
                    beneficiary: response[0],
                })
                this.props.updateNewTransferCreation('beneficiary', response[0])

                this.setState({
                    beneficiary: Object.assign({}, this.state.beneficiary, {
                        account_currency_nickname: `${response[0].full_name} [${
                            response[0].iso_alpha_3
                        }]`,
                    }),
                })

                return response
            })
            .then(response => {
                API.get(
                    'currencies',
                    `/currencies/get/${response[0].account_currency}`
                )
                    .then(response => {
                        this.setState({
                            beneficiary: Object.assign(
                                {},
                                this.state.beneficiary,
                                {
                                    account_currency_nickname: `${
                                        response.full_name
                                    } [${response.iso_alpha_3}]`,
                                }
                            ),
                        })
                        this.props.updateNewTransferCreation(
                            'currency_to_id',
                            response.id
                        )
                        this.props.updateNewTransferCreation(
                            'currency_to',
                            response
                        )

                        API.post('email', `/interaction/main`, {
                            body: {
                                interaction:
                                    'New Transfer Request Step 2 PayingTo',
                                data: {
                                    client_id: this.props.app_state
                                        .current_client.id,
                                    client_nickname: this.props.app_state
                                        .current_client.nickname,
                                    beneficiary_id: this.state.beneficiary.id,
                                    beneficiary_nickname: this.state.beneficiary
                                        .nickname,
                                },
                                result: 'New transfer request proceeding',
                            },
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error)
            })
    }

    fetchNewRate = () => {
        if (
            this.props.payments.new_payment_data.to_currency &&
            this.props.payments.new_payment_data.from_currency
        ) {
            let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
            axios
                .get(url, {
                    params: {
                        currencies: this.props.payments.new_payment_data
                            .from_currency.iso_alpha3,
                        source: this.props.payments.new_payment_data.to_currency
                            .iso_alpha3,
                        format: '1',
                    },
                })
                .then(response => {
                    let key = `${this.props.payments.new_payment_data.currency_to.iso_alpha_3.toUpperCase()}${this.props.transfers.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}`
                    let rate_string = response.data.quotes[key]

                    let amount_to = Number.parseFloat(
                        this.props.transfers.new_transfer_data.amount_to
                    )
                    let rate = Number.parseFloat(rate_string)
                    let rate_adjust =
                        Number.parseFloat(
                            this.props.app_state.current_client.default_rate
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_division
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_entity
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_team
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_staff
                        )
                    let adjusted_rate = rate + rate * rate_adjust

                    let amount_from = amount_to * adjusted_rate

                    this.props.updateNewTransferCreation(
                        'current_rate',
                        adjusted_rate.toFixed(5)
                    )
                    this.props.updateNewTransferCreation(
                        'amount_from',
                        amount_from.toFixed(5)
                    )
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    sendState() {
        return this.state
    }
    // function that verifies if two strings are equal
    compare(string1, string2) {
        if (string1 === string2) {
            return true
        }
        return false
    }
    // function that returns true if value is email, false otherwise
    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (emailRex.test(value)) {
            return true
        }
        return false
    }
    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }
    // function that verifies if a value is larger than a number or not
    isLargerThanNumber(value, number) {
        if (value !== '' && value >= number) {
            return true
        }
        return false
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'email':
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + ' _state']: 'error' })
                }
                break
            case 'equalTo':
                if (
                    this.compare(
                        event.target.value,
                        this.state[stateNameEqualTo]
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'isLargerThanNumber':
                if (
                    this.isLargerThanNumber(
                        event.target.value,
                        stateNameEqualTo
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
        // Calculate "to amount"
        if (stateName === 'from_amount') {
            this.setState({ last_user_type_amount_name: 'from_amount' })
            this.handleAmount('to_amount')
        }
        // Calculate "from amount"
        if (stateName === 'to_amount') {
            this.setState({ last_user_type_amount_name: 'to_amount' })
            this.handleAmount('from_amount')
        }
        // if (stateName === "amount_to") {
        //   this.fetchNewRate()
        // }
    }

    handleAmount(amount_name) {
        let from_currency_Iso = ''
        let to_currency_Iso = ''

        this.state.currencies_list_priority.map((item, index) => {
            if (item.id == this.state.from_currency) {
                from_currency_Iso = item.iso_alpha_3
            }
            if (item.id == this.state.to_currency) {
                to_currency_Iso = item.iso_alpha_3
            }
        })

        let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
        axios
            .get(url, {
                params: {
                    currencies: to_currency_Iso,
                    source: from_currency_Iso,
                    format: '1',
                },
            })
            .then(response => {
                let key = from_currency_Iso + to_currency_Iso
                let rate_string = response.data.quotes[key]

                let rate = Number.parseFloat(rate_string)

                let rate_adjust =
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client
                            .default_rate_division
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_entity
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_team
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_staff
                    )
                let adjusted_rate = rate - rate * rate_adjust
                if (this.state.from_currency == this.state.to_currency) {
                    adjusted_rate = rate
                }

                if (
                    this.state.from_currency_state === 'success' &&
                    this.state.to_currency_state === 'success'
                ) {
                    // handle to_amount
                    if (
                        amount_name === 'to_amount' &&
                        this.state.from_amount_state === 'success'
                    ) {
                        let amount_from = Number.parseFloat(
                            this.state.from_amount
                        )
                        let amount_to = amount_from * adjusted_rate

                        this.setState({ ['to_amount']: amount_to.toFixed(2) })
                        if (amount_to >= min_transfer_amount) {
                            this.setState({ ['to_amount_state']: 'success' })
                        } else {
                            this.setState({ ['to_amount_state']: 'error' })
                        }
                    }

                    // handle from_amount
                    if (
                        amount_name === 'from_amount' &&
                        this.state.to_amount_state === 'success'
                    ) {
                        let amount_to = Number.parseFloat(this.state.to_amount)
                        let amount_from = amount_to / adjusted_rate

                        this.setState({
                            ['from_amount']: amount_from.toFixed(2),
                        })
                        if (amount_from >= min_transfer_amount) {
                            this.setState({ ['from_amount_state']: 'success' })
                        } else {
                            this.setState({ ['from_amount_state']: 'error' })
                        }
                    }
                }

                this.setState({ ['adjusted_rate']: adjusted_rate })
            })
            .catch(error => {
                console.log(error)
            })
    }

    // handleSelectChange = event => {
    //   this.setState({ [event.target.name]: event.target.value });
    //   // console.log(this.props);
    //   this.props.updateNewTransferCreation(event.target.name, event.target.value);
    //   this.setState({ [event.target.name + "_state"]: "success" });
    //   // console.log(this.props);
    // };

    handleCustomReactSelectChange = (
        name,
        previous_from_currency,
        previous_to_currency
    ) => value => {
        var option_value
        if (value === null) {
            option_value = null
        } else {
            option_value = value.value
        }
        this.setState(
            {
                [name]: option_value,
            },
            () => {
                switch (this.state.last_user_type_amount_name) {
                    case 'from_amount':
                        this.handleAmount('to_amount')
                        break
                    case 'to_amount':
                        this.handleAmount('from_amount')
                        break
                    default:
                        this.handleAmount()
                }
            }
        )
        this.setState({ [name + '_state']: 'success' })
    }

    isValidated() {
        if (this.state.from_currency_state !== 'success') {
            this.setState({ from_currency_state: 'error' })
        }
        if (this.state.to_currency_state !== 'success') {
            this.setState({ to_currency_state: 'error' })
        }
        if (this.state.from_amount_state !== 'success') {
            this.setState({ from_amount_state: 'error' })
        }
        if (this.state.to_amount_state !== 'success') {
            this.setState({ to_amount_state: 'error' })
        }

        if (
            this.state.from_currency_state === 'success' &&
            this.state.to_currency_state === 'success' &&
            this.state.from_amount_state === 'success' &&
            this.state.to_amount_state === 'success'
        ) {
            API.get('currencies', `/currencies/get/${this.state.from_currency}`)
                .then(response => {
                    this.props.updateNewTransferCreation(
                        'currency_from',
                        response
                    )
                })
                .catch(error => {
                    console.log(error)
                })
            API.get('currencies', `/currencies/get/${this.state.to_currency}`)
                .then(response => {
                    this.props.updateNewTransferCreation(
                        'currency_to',
                        response
                    )
                })
                .catch(error => {
                    console.log(error)
                })

            this.props.updateNewTransferCreation(
                'currency_from_id',
                this.state.from_currency
            )
            this.props.updateNewTransferCreation(
                'currency_to_id',
                this.state.to_currency
            )
            this.props.updateNewTransferCreation(
                'amount_from',
                this.state.from_amount
            )
            this.props.updateNewTransferCreation(
                'amount_to',
                this.state.to_amount
            )
            this.props.updateNewTransferCreation(
                'client_rate',
                this.state.adjusted_rate
            )

            return true
        }

        return false
    }

    render() {
        const { classes } = this.props
        // For CustomReactSelect. Generate select options for dropdown list.
        const select_currency_select_options = this.state.currencies_list_priority.map(
            item => ({
                value: item.id,
                label: item.iso_alpha_3 + ': ' + item.full_name,
            })
        )

        return (
            <React.Fragment>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12}>
                        <h4 className={classes.infoText} />
                    </GridItem>
                </GridContainer>

                <GridContainer justify="center">
                    <GridItem xs={12} sm={6}>
                        <CustomReactSelect
                            label="From Currency"
                            options={select_currency_select_options}
                            value={this.state.from_currency}
                            onChange={this.handleCustomReactSelectChange(
                                'from_currency'
                            )}
                            isClearable={false}
                            // isDisabled={!this.state.edit_mode}
                        />
                    </GridItem>

                    <GridItem xs={12} sm={6}>
                        <CustomReactSelect
                            label="To Currency"
                            options={select_currency_select_options}
                            value={this.state.to_currency}
                            onChange={this.handleCustomReactSelectChange(
                                'to_currency'
                            )}
                            isClearable={false}
                            // isDisabled={!this.state.edit_mode}
                        />
                    </GridItem>
                </GridContainer>

                <GridContainer justify="center">
                    <GridItem xs={12} sm={6}>
                        <CustomInput
                            success={this.state.from_amount_state === 'success'}
                            error={this.state.from_amount_state === 'error'}
                            labelText={<span>From amount</span>}
                            id="from_amount"
                            labelProps={{
                                shrink: true,
                            }}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: this.state.from_amount,
                                onChange: event =>
                                    this.change(
                                        event,
                                        'from_amount',
                                        'isLargerThanNumber',
                                        min_transfer_amount
                                    ),
                            }}
                        />

                        {/* <Button variant="contained" color="default" className={classes.button} onClick={this.handleEvent}>
              Get Rate
              <CloudUploadIcon className={classes.rightIcon} />
            </Button> */}
                    </GridItem>

                    <GridItem xs={12} sm={6}>
                        {/* {(this.state.to_amount && !isNaN(this.state.to_amount)) && (
              <GridContainer>
                <GridItem> */}
                        <CustomInput
                            success={this.state.to_amount_state === 'success'}
                            error={this.state.to_amount_state === 'error'}
                            labelText={<span>To amount</span>}
                            id="to_amount"
                            labelProps={{
                                shrink: true,
                            }}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: this.state.to_amount,
                                onChange: event =>
                                    this.change(
                                        event,
                                        'to_amount',
                                        'isLargerThanNumber',
                                        min_transfer_amount
                                    ),
                                // disabled: true
                            }}
                            helpText={
                                !isNaN(this.state.adjusted_rate)
                                    ? 'Transfer Rate: ' +
                                      Number.parseFloat(
                                          this.state.adjusted_rate
                                      ).toFixed(5)
                                    : ''
                            }
                        />
                        {/* </GridItem>
              </GridContainer>
            )} */}
                    </GridItem>
                </GridContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(style)(Step1TransferTo))
