import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'

// import ReactTable from 'react-table'
// import { FormattedNumber } from 'react-intl'
// import Table from 'components/Table/Table.jsx'
import {injectIntl} from 'react-intl'

// import * as jsPDF from 'jspdf'
// import html2canvas from 'html2canvas'
import {API, Storage} from 'aws-amplify'
// import { Divider } from '@material-ui/core'
import {Table} from 'antd'

const axios = require('axios')
var moment = require('moment')

const forexWorldWideLogo = require('assets/img/pdf_img/forexworldwide_logo_transparent_280x50.42dc12df.png')
const forexSportLogo = require('assets/img/pdf_img/fxsport_signature_gmail.png')
const logo_4qpf = require('assets/img/brand/4QPFa.png')
const logo_skiaspen = require('assets/img/brand/skiaspen.png')
const goStudyLogo = require('assets/img/pdf_img/GOSTUDY_HorizontalUsage_Black_250x50.png')

// const us_flag = require("assets/img/flags/US.png");
// const au_flag = require("assets/img/flags/AU.png");
// const gb_flag = require("assets/img/flags/GB.png");

// var systemNews = [
//   "Alpha Testing: Staff",
//   "Alpha Testing: Clients",
//   "Implementing: Beneficiaries",
//   "Development: Transfers"
// ];

const style = {
    root: {},
    pageHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 22,
    },
    pageSubHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 18,
    },
    tableHeaders: {
        width: 150,
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 8,
    },
    tableCells: {
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
    },
    backgroundBlue: {
        backgroundColor: '#348feb20',
    },
}

class PaymentRequestViewPage extends React.Component {

    state = {
        value: 0,
        currencies_list_priority: [],
        map_data: {},
        // beneficiaries_list: [],
        beneficiary_id: '',
        transfer_heading: 'Current Transfers',
        transfer: {},
        currency_from: {},
        currency_to: {
            iso_alpha_3: '',
        },
        payouts_list: [],
        team: null,
        client: null,
        beneficiary: null
    }

    componentDidMount() {
        this.updateTransferData(this.props.match.params.transferPendingID)
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.prefill !== this.props.prefill) {
            this.updateTransferData()
        }
    }

    async updateTransferData(option_value) {
        this.setState({transfer_id: option_value})

        const [transfer_pending, payouts] = await Promise.all([
            API.post('commons', '/fetch', {
                body: {
                    context: ['transfers_pending'],
                    fields: ['*'],
                    condition: {id: option_value}
                }
            }),
            API.post('commons', '/fetch', {
                body: {
                    context: ['transfers_pending_payouts'],
                    fields: ['*'],
                    condition: {pending_transfer_id: option_value}
                }
            }),
        ]);
        this.setState({transfer: transfer_pending[0], payouts_list: payouts});

        const [currency, currencyList] = await Promise.all([
            API.post('commons', '/fetch', {
                body: {
                    context: ['currencies'],
                    fields: ['*'],
                    condition: {id: transfer_pending[0].currency_to_id}
                }
            }),
            API.post('commons', '/fetch', {
                body: {
                    context: ['currencies'],
                    fields: ['*']
                }
            }),
        ]);
        this.setState({currency_to: currency[0], currencies_list_priority: currencyList});

        const [client] = await API.post("commons", "/fetch", {
            body: {
                context: ['clients'],
                fields: ['*'],
                condition: {id: transfer_pending[0].client_id}
            }
        });
        this.setState({client: client})

        const [team] = await API.post("commons", "/fetch", {
            body: {
                context: ['teams'],
                fields: ['*'],
                condition: {id: client.team_id}
            }
        });
        this.setState({team: team});

        const [beneficiary] = await API.post("commons", "/fetch", {
            body: {
                context: ['beneficiaries'],
                fields: ['*'],
                condition: {id: payouts[0].beneficiary_id}
            }
        });
        this.setState({beneficiary: beneficiary});
        // API.get('transfers_pending', `/get-full/id/${option_value}`)
        //     .then(response => {
        //         console.log(response)
        //         this.setState({
        //             transfer: response.transfer_pending,
        //             payouts_list: response.payouts,
        //         });
        //
        //         API.get(
        //             'currencies',
        //             `/currencies/get/${
        //                 response.transfer_pending.currency_to_id
        //             }`
        //         )
        //             .then(response => {
        //                 console.log(response)
        //                 this.setState({
        //                     currency_to: response,
        //                 })
        //             })
        //             .catch(error => {
        //                 console.log(error)
        //             })
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     });
        //
        // API.get('currencies', `/currencies/get-list-priority`)
        //     .then(response => {
        //         // console.log(response);
        //         this.setState({
        //             currencies_list_priority: response,
        //         })
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     })
    }

    payoutItemTable = () => {
        const {classes} = this.props;

        let totalPayment = 0
        for (let i = 0; i < this.state.payouts_list.length; i++) {
            totalPayment += Number.parseFloat(
                this.state.payouts_list[i].amount_to
            )
        }

        let returnValue = []

        if (!this.state.payouts_list.length) {
            returnValue.push(
                <tr>
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr className={classes.backgroundBlue}>
                            <th className={classes.tableHeaders}>
                                Beneficiary
                            </th>
                            <td className={classes.tableCells}>
                                <React.Fragment>
                                    <strong>
                                        Payout will be made to your currency
                                        balance
                                    </strong>
                                </React.Fragment>
                            </td>
                        </tr>

                        <tr>
                            <th className={classes.tableHeaders}>
                                Amount Due
                            </th>
                            <td className={classes.tableCells}>
                                <React.Fragment>
                                    <strong>
                                        {this.props.intl.formatNumber(
                                            this.state.transfer.amount_to,
                                            {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }
                                        )}
                                        {` `}
                                        <div
                                            className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                        />
                                        {` `}
                                        {this.state.currency_to.iso_alpha_3.toUpperCase()}
                                    </strong>
                                </React.Fragment>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </tr>
            )
        }

        for (let i = 0; i < this.state.payouts_list.length; i++) {
            const payout = this.state.payouts_list[i]
            if (i === 0 || (i > 0 && payout.beneficiary_id !== this.state.payouts_list[0].beneficiary_id)) {
                let beneficiaryNickname = this.state.beneficiary.nickname;
                if (payout.holdingAccountPayee) {
                    beneficiaryNickname = `Payment made to ${this.state.currency_to.iso_alpha_3.toUpperCase()} Currency Balance`
                }

                returnValue.push(
                    <tr>
                        <table style={{width: '100%'}}>
                            <tbody style={{width: '100%'}}>
                            <tr style={{width: '100%'}}>
                                <td className={classes.tableCells}>
                                    <table
                                        style={{
                                            width: '100%',
                                        }}
                                    >
                                        <tbody>
                                        <tr>
                                            <th
                                                className={
                                                    classes.tableHeaders
                                                }
                                            >
                                                Payable To
                                            </th>
                                            <td
                                                className={
                                                    classes.tableCells
                                                }
                                            >
                                                <React.Fragment>
                                                    <strong>
                                                        {
                                                            beneficiaryNickname
                                                        }
                                                    </strong>
                                                </React.Fragment>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                className={
                                                    classes.tableHeaders
                                                }
                                            >
                                                Address
                                            </th>
                                            <td
                                                className={
                                                    classes.tableCells
                                                }
                                            >
                                                <React.Fragment>
                                                    {
                                                        this.state.beneficiary.ben_address_line_1
                                                    }
                                                    &nbsp;
                                                    {
                                                        this.state.beneficiary.ben_address_line_2
                                                    }
                                                    &nbsp;
                                                    {
                                                        this.state.beneficiary.ben_address_suburb
                                                    }
                                                    &nbsp;
                                                    {
                                                        this.state.beneficiary.ben_address_state
                                                    }
                                                    &nbsp;
                                                    {
                                                        this.state.beneficiary.ben_address_postcode
                                                    }
                                                </React.Fragment>
                                            </td>
                                        </tr>
                                        {this.state.beneficiary.internalAccount && (
                                            <>
                                                <tr>
                                                    <th
                                                        className={
                                                            classes.tableHeaders
                                                        }
                                                    >
                                                        Payment Type
                                                    </th>
                                                    <td
                                                        className={
                                                            classes.tableCells
                                                        }
                                                    >
                                                        Internal Account
                                                        Payment
                                                    </td>
                                                </tr>
                                            </>
                                        )}{' '}
                                        {/* payout.internalAccount &&  */}
                                        {!this.state.beneficiary.internalAccount && (
                                            <>
                                                <tr>
                                                    <th
                                                        className={
                                                            classes.tableHeaders
                                                        }
                                                    >
                                                        Bank Name
                                                    </th>
                                                    <td
                                                        className={
                                                            classes.tableCells
                                                        }
                                                    >
                                                        {this.state.beneficiary.bank_legal_name ? (
                                                            <span>
                                                                        {
                                                                            this.state.beneficiary.bank_legal_name
                                                                        }
                                                                <br/>
                                                                    </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th
                                                        className={
                                                            classes.tableHeaders
                                                        }
                                                    >
                                                        Address
                                                    </th>
                                                    <td
                                                        className={
                                                            classes.tableCells
                                                        }
                                                    >
                                                        <React.Fragment>
                                                            {
                                                                this.state.beneficiary.bank_address_line_1
                                                            }
                                                            &nbsp;
                                                            {
                                                                this.state.beneficiary.bank_address_line_2
                                                            }
                                                            &nbsp;
                                                            {
                                                                this.state.beneficiary.bank_address_suburb
                                                            }
                                                            &nbsp;
                                                            {
                                                                this.state.beneficiary.bank_address_state
                                                            }
                                                            &nbsp;
                                                            {
                                                                this.state.beneficiary.bank_address_state
                                                            }
                                                            &nbsp;
                                                            {
                                                                this.state.beneficiary.bank_address_postcode
                                                            }
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                            </>
                                        )}{' '}
                                        {/* !payout.internalAccount &&  */}
                                        {this.state.beneficiary.swift_code && (
                                            <tr>
                                                <th
                                                    className={
                                                        classes.tableHeaders
                                                    }
                                                >
                                                    SWIFT Code
                                                </th>
                                                <td
                                                    className={
                                                        classes.tableCells
                                                    }
                                                >
                                                    {this.state.beneficiary.swift_code
                                                        ? this.state.beneficiary.swift_code
                                                        : ''}
                                                </td>
                                            </tr>
                                        )}
                                        {this.state.beneficiary.bsb_code && (
                                            <tr>
                                                <th
                                                    className={
                                                        classes.tableHeaders
                                                    }
                                                >
                                                    BSB
                                                </th>
                                                <td
                                                    className={
                                                        classes.tableCells
                                                    }
                                                >
                                                    {this.state.beneficiary.bsb_code
                                                        ? this.state.beneficiary.bsb_code
                                                        : ''}
                                                </td>
                                            </tr>
                                        )}
                                        {this.state.beneficiary.account_number && (
                                            <tr>
                                                <th
                                                    className={
                                                        classes.tableHeaders
                                                    }
                                                >
                                                    Account Number
                                                </th>
                                                <td
                                                    className={
                                                        classes.tableCells
                                                    }
                                                >
                                                    {this.state.beneficiary.account_number
                                                        ? this.state.beneficiary.account_number
                                                        : ''}
                                                </td>
                                            </tr>
                                        )}
                                        {this.state.beneficiary.iban && (
                                            <tr>
                                                <th
                                                    className={
                                                        classes.tableHeaders
                                                    }
                                                >
                                                    IBAN
                                                </th>
                                                <td
                                                    className={
                                                        classes.tableCells
                                                    }
                                                >
                                                    {this.state.beneficiary.iban ? (
                                                        <span>
                                                                    {
                                                                        this.state.beneficiary.iban
                                                                    }
                                                                </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                        {this.state.beneficiary.aba_routing_number && (
                                            <tr>
                                                <th
                                                    className={
                                                        classes.tableHeaders
                                                    }
                                                >
                                                    ABA Routing
                                                </th>
                                                <td
                                                    className={
                                                        classes.tableCells
                                                    }
                                                >
                                                    {this.state.beneficiary.aba_routing_number ? (
                                                        <span>
                                                                    {
                                                                        this.state.beneficiary.aba_routing_number
                                                                    }
                                                            <br/>
                                                                </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                        {this.state.beneficiary.sort_code && (
                                            <tr>
                                                <th
                                                    className={
                                                        classes.tableHeaders
                                                    }
                                                >
                                                    Sort Code
                                                </th>
                                                <td
                                                    className={
                                                        classes.tableCells
                                                    }
                                                >
                                                    {this.state.beneficiary.sort_code ? (
                                                        <span>
                                                                    {
                                                                        this.state.beneficiary.sort_code
                                                                    }
                                                            <br/>
                                                                </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                        {this.state.beneficiary.transit_code && (
                                            <tr>
                                                <th
                                                    className={
                                                        classes.tableHeaders
                                                    }
                                                >
                                                    Transit Code
                                                </th>
                                                <td
                                                    className={
                                                        classes.tableCells
                                                    }
                                                >
                                                    {this.state.beneficiary.transit_code ? (
                                                        <span>
                                                                    {
                                                                        this.state.beneficiary.transit_code
                                                                    }
                                                            <br/>
                                                                </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                        {this.state.beneficiary.bank_code && (
                                            <tr>
                                                <th
                                                    className={
                                                        classes.tableHeaders
                                                    }
                                                >
                                                    Bank Code
                                                </th>
                                                <td
                                                    className={
                                                        classes.tableCells
                                                    }
                                                >
                                                    {this.state.beneficiary.bank_code ? (
                                                        <span>
                                                                    {
                                                                        this.state.beneficiary.bank_code
                                                                    }
                                                            <br/>
                                                                </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </tr>
                )
            }
            if (
                this.state.payouts_list.length > 1 &&
                payout.beneficiary_id ==
                this.state.payouts_list[0].beneficiary_id
            ) {
                // console.log(payout.amount)

                returnValue.push(
                    <tr>
                        <table style={{width: '100%'}}>
                            <tbody>
                            {/* {
    this.state.payouts_list.map((item) => {
    return <tr> */}
                            <tr style={{width: '100%'}}>
                                {/* <td style={{ verticalAlign: "top", paddingRight: 20, paddingLeft: 8, paddingTop: 10, paddingBottom: 10 }}> */}
                                <td>
                                    <table style={{width: '100%'}}>
                                        <tbody style={{width: '100%'}}>
                                        <tr
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            <td
                                                className={
                                                    classes.tableCells
                                                }
                                            >
                                                <table
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <tbody>
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        />
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            <React.Fragment
                                                            >
                                                                <em>
                                                                    Payout{' '}
                                                                    {i +
                                                                        1}

                                                                    :
                                                                </em>
                                                                &nbsp;&nbsp;&nbsp;
                                                                {this.props.intl.formatNumber(
                                                                    payout.amount_to,
                                                                    {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                )}
                                                                {` `}
                                                                <div
                                                                    className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                                                />
                                                                {` `}
                                                                {this.state.currency_to.iso_alpha_3.toUpperCase()}
                                                            </React.Fragment>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </tr>
                )
            }
        }
        return returnValue
    }

    proceedToTransfer = async (
        pendingTransferId,
        currency_iso_alpha_3,
        amount_to,
        tpp_beneficiary_id,
        purpose_of_payment_detail,
        detail_1
    ) => {
        await API.put('transfers_pending', `/update/${pendingTransferId}`, {
            body: {
                status_id: 3,
            },
        })
            .then(apiResponse => {
                console.log(apiResponse)
            })
            .catch(error => {
                console.log(error)
            })
        this.props.history.push(
            `/shortcut_transfer/AUD-${currency_iso_alpha_3}--${amount_to}-${tpp_beneficiary_id}-${purpose_of_payment_detail}--${detail_1}?pt=${pendingTransferId}`
        )
    }

    getLogo = brand => {
        switch (brand) {
            case 58:
                return logo_4qpf
                break
            case 59:
                return logo_skiaspen
                break
            default:
                return forexWorldWideLogo
                break
        }
    }

    downloadInvoice = () => {
        Storage.get(this.state.transfer.attachmentFileId, {expires: 60}).then(
            item => {
                fetch(item)
                    .then(response => response.blob())
                    .then(blob => {
                        const a = document.createElement('a')
                        const url = window.URL.createObjectURL(blob)
                        a.href = url
                        a.download = this.state.transfer.id + '_REQUEST_INVOICE'
                        a.click()
                        window.URL.revokeObjectURL(url)
                    })
                    .catch(error =>
                        console.error('Error downloading file:', error)
                    )
            }
        )
    }

    buildPayoutList = () => {
        const payoutList = this.state.payouts_list.map(i => ({
            key: i.id,
            date:
                this.state.transfer && this.state.transfer.record_created
                    ? moment(
                        this.state.transfer &&
                        this.state.transfer.record_created
                    ).format('DD/MM/YYYY')
                    : '-',
            description: i.invoice_detail,
            amount: this.props.intl.formatNumber(i.amount_to, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
        }))
        payoutList.push({
            date: '',
            description: '',
            key: '',
            amount: (
                <strong>
                    {this.props.intl.formatNumber(
                        this.state.transfer.amount_to,
                        {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }
                    )}
                </strong>
            ),
        })
        return payoutList
    }

    buildPayoutColumns = () => {
        const columns = [
            {key: 1, dataIndex: 'date', title: 'Date'},
            {key: 2, dataIndex: 'description', title: 'Description'},
            {key: 3, dataIndex: 'amount', title: 'Amount'},
        ]
        return columns
    }

    render() {
        const {classes} = this.props;

        // console.log('client', this.state.client)
        // console.log('transfer', this.state.transfer)
        // console.log('beneficiary', this.state.beneficiary)
        // console.log('payouts_list', this.state.payouts_list)
        // let main_component = null
        // main_component = <TrackTransfer prefill={this.props.match.params.prefill}/>

        if (!this.state.transfer)
            return (
                <React.Fragment>
                    <strong>Error: Unable to access transfer</strong>
                </React.Fragment>
            )
        if (!this.state.currency_from) return null
        if (!this.state.currency_to) return null
        if (!this.state.payouts_list) return null
        if (!this.state.payouts_list.length) return null
        if (!this.state.team) return null
        if (!this.state.client) return null
        if (!this.state.beneficiary) return null

        let logo = this.getLogo(this.state.team && this.state.team.id);

        return (
            <React.Fragment>
                <div>
                    <GridContainer alignItems="stretch">
                        <GridItem xs={12}>
                            <Card style={{height: '90%'}}>
                                <CardHeader color="info" stats icon/>
                                <CardBody>
                                    <p className={classes.cardCategory}></p>
                                    <div
                                        id="divToPrint"
                                        className="mt4"
                                        style={{
                                            hidden: 'hidden',

                                            width: '210mm',
                                            minHeight: 'auto',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            padding: '20mm',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img
                                                src={require(`assets/img/brand/${
                                                    this.state.team
                                                        .brand_sidebar_logo
                                                }`)}
                                                alt="logo"
                                                width={'100px'}
                                                className={classes.headerImage}
                                                style={{
                                                    width:
                                                        this.state
                                                            .payouts_list[0]
                                                            .beneficiary_id ===
                                                        5585
                                                            ? '100px'
                                                            : '',
                                                }}
                                            />
                                            {this.state.payouts_list.length >
                                                0 && (
                                                    <>
                                                    <span
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                            alignItems: 'end',
                                                        }}
                                                    >
                                                        <h4>
                                                            <span>
                                                                {
                                                                    this.state
                                                                        .beneficiary
                                                                        .ben_legal_name
                                                                }
                                                            </span>
                                                        </h4>
                                                        <span
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                                justifyContent:
                                                                    'end',
                                                                alignItems:
                                                                    'end',
                                                            }}
                                                        >
                                                            <React.Fragment>
                                                                <span>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .beneficiary
                                                                            .ben_address_line_1
                                                                    }
                                                                </span>
                                                                <span>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .beneficiary
                                                                            .ben_address_line_2
                                                                    }
                                                                </span>
                                                                <span>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .beneficiary
                                                                            .ben_address_suburb
                                                                    }{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .beneficiary
                                                                            .ben_address_state
                                                                    }{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .beneficiary
                                                                            .ben_address_postcode
                                                                    }
                                                                </span>
                                                                <span>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .beneficiary
                                                                            .beneficiary_country_name
                                                                    }
                                                                </span>
                                                            </React.Fragment>
                                                        </span>
                                                    </span>
                                                    </>
                                                )}
                                        </div>
                                        <hr/>
                                        <h3 className={classes.pageHeadings}>
                                            {
                                                this.state.team.brand_pr_paymentRequestHeading
                                            }
                                        </h3>
                                        <GridContainer>
                                            <GridItem
                                                xs={12}
                                                md={12}
                                                style={{textAlign: 'right'}}
                                            >
                                                <strong>Due Date: </strong>
                                                {
                                                    this.state.transfer.expiryDate != null
                                                        ? moment(this.state.transfer.expiryDate).format('DD/MM/YYYY')
                                                        : '-'
                                                }
                                            </GridItem>
                                        </GridContainer>
                                        <br/>

                                        <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                <h5
                                                    className={
                                                        classes.pageSubHeadings
                                                    }
                                                >
                                                    Invoice To:
                                                </h5>

                                                <p>
                                                    <strong>
                                                        {this.state
                                                            .client
                                                            .nickname
                                                        }
                                                    </strong>
                                                    <br/>

                                                    {this.state.client !=
                                                    null ? (
                                                        <>
                                                            <span>
                                                                {
                                                                    this.state.client.residential_street_line_1
                                                                }
                                                            </span>
                                                            <br/>
                                                            <span>
                                                                {
                                                                    this.state.client.residential_street_line_2
                                                                }
                                                            </span>
                                                            {this.state.client.residential_street_line_2 && (
                                                                <span>
                                                                    <br/>
                                                                </span>
                                                            )}
                                                            <span>
                                                                {
                                                                    this.state.client.residential_street_suburb
                                                                }
                                                            </span>
                                                            &nbsp;
                                                            <span>
                                                                {
                                                                    this.state.client.residential_street_state
                                                                }
                                                            </span>
                                                            &nbsp;
                                                            <span>
                                                                {
                                                                    this.state.client.residential_street_postcode
                                                                }
                                                            </span>
                                                            &nbsp;
                                                            <br/>
                                                            <em>
                                                                {this.state.client.email}
                                                            </em>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </p>

                                                <br/>
                                                <h5
                                                    className={
                                                        classes.pageSubHeadings
                                                    }
                                                >
                                                    Invoice Details:
                                                </h5>
                                                <p>
                                                    <strong>Currency:</strong>
                                                    {` `}
                                                    <div
                                                        className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                                    />
                                                    {` `}
                                                    {this.state.currency_to.iso_alpha_3.toUpperCase()}
                                                </p>
                                                <p>
                                                    <strong>Summary:</strong>
                                                    {` `}
                                                    {
                                                        this.state
                                                            .payouts_list[0]
                                                            .detail_1
                                                    }
                                                </p>

                                                <Table
                                                    dataSource={this.buildPayoutList()}
                                                    size={'small'}
                                                    columns={this.buildPayoutColumns()}
                                                    pagination={false}
                                                    bordered={true}
                                                    style={{
                                                        marginBottom: '5px',
                                                    }}
                                                />
                                                <hr/>
                                                <h5 className={classes.pageSubHeadings}>
                                                    Payment Details
                                                </h5>
                                                <div
                                                    style={{
                                                        border:
                                                            '1px solid #eee',
                                                    }}
                                                >
                                                    <table
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <tbody>
                                                        {this.payoutItemTable()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </CardBody>
                                <CardFooter/>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

const PaymentRequestViewPagePrintContainer = connect(
    mapStateToProps
    // mapDispatchToProps
)(injectIntl(withRouter(withStyles(style)(PaymentRequestViewPage))))

export default PaymentRequestViewPagePrintContainer
