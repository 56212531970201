import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import ChartistGraph from 'react-chartist'
import { VectorMap } from 'react-jvectormap'

import withStyles from '@material-ui/core/styles/withStyles'
import Warning from '@material-ui/icons/Warning'
import AccessTime from '@material-ui/icons/AccessTime'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Danger from 'components/Typography/Danger.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import Tabs from 'components/CustomTabs/CustomTabs.jsx'

import { dailySalesChart } from 'variables/charts'

import dashboardStyle from './DashboardPageStyle'

import CustomInput from 'components/CustomInput/CustomInput.jsx'
import { API } from 'aws-amplify'
import CustomReactSelect from '../../components/Forms/CustomReactSelect/CustomReactSelect'

//core for react-intl
import { FormattedMessage } from 'react-intl'
import DashboardTransfer from '../../components/DashboardTransfer/DashboardTransfer'
import EconomicCalender from '../../components/EconomicInformation/EconomicCalender'
import Cloud from '@material-ui/core/SvgIcon/SvgIcon'
import FxCharts from '../../components/EconomicInformation/FxCharts'
import FxRates from '../../components/EconomicInformation/FxRates'
import EconomicHoliday from '../../components/EconomicInformation/EconomicHoliday'
import CustomTransferList from '../../components/CustomTransferList/CustomTransferList'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const axios = require('axios')
var Chartist = require('chartist')

const min_transfer_amount = 0

class ExternalTransferPage extends React.Component {
    state = {
        value: 0,
        currencies_list_priority: [],
        map_data: {},
        beneficiaries_list: [],
        beneficiary_id: '',
        transfer_heading: 'Current Transfers',
    }

    componentDidMount() {
        // let url = `https://apilayer.net/api/timeframe?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
        // axios
        //     .get(url, {
        //         params: {
        //             currencies: 'USD',
        //             source: 'AUD',
        //             start_date: '2019-02-01',
        //             end_date: '2019-03-01',
        //         },
        //     })
        //     .then(response => {
        //         let data = []
        //         for (var item in response.data.quotes) {
        //             data.push(response.data.quotes[item]['AUDUSD'])
        //         }
        //         let data2 = []
        //         data2.push(data)
        //         this.setState({ audusd: { series: data2 } })
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     })

        // url = `https://apilayer.net/api/timeframe?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
        // axios
        //     .get(url, {
        //         params: {
        //             currencies: 'EUR',
        //             source: 'AUD',
        //             start_date: '2019-02-01',
        //             end_date: '2019-03-01',
        //         },
        //     })
        //     .then(response => {
        //         let data = []
        //         for (var item in response.data.quotes) {
        //             data.push(response.data.quotes[item]['AUDEUR'])
        //         }
        //         let data2 = []
        //         data2.push(data)
        //         this.setState({ audeur: { series: data2 } })
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     })

        // url = `https://apilayer.net/api/timeframe?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
        // axios
        //     .get(url, {
        //         params: {
        //             currencies: 'NZD',
        //             source: 'AUD',
        //             start_date: '2019-02-01',
        //             end_date: '2019-03-01',
        //         },
        //     })
        //     .then(response => {
        //         let data = []
        //         for (var item in response.data.quotes) {
        //             data.push(response.data.quotes[item]['AUDNZD'])
        //         }
        //         let data2 = []
        //         data2.push(data)
        //         this.setState({ audnzd: { series: data2 } })
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     })

        // url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
        // axios
        //     .get(url, {
        //         params: {
        //             currencies: 'USD,EUR,NZD',
        //             source: 'AUD',
        //             format: '1',
        //         },
        //     })
        //     .then(response => {
        //         this.setState({
        //             current_audusd: response.data.quotes['AUDUSD'],
        //             current_audeur: response.data.quotes['AUDEUR'],
        //             current_audnzd: response.data.quotes['AUDNZD'],
        //         })
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     })

        // API.get('currencies', `/currencies/get-list-priority`)
        //     .then(response => {
        //         this.setState({
        //             // currencies_list: response.fullList,
        //             currencies_list_priority: response,
        //         })
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     })

        // API.get('beneficiaries', `/beneficiaries/get`)
        //     .then(response => {
        //         this.setState({
        //             beneficiaries_list: response,
        //         })
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     })

        API.get('transfers', `/get-list-for-dashboard`)
            .then(response => {
                if (response.length === 0) {
                    this.setState({
                        has_transfer_record: false,
                    })
                } else {
                    this.setState({
                        has_transfer_record: true,
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (
        //   !prevProps.app_state.current_client &&
        //   this.props.app_state.current_client
        // ) {
        //   //logging message - Account status updated
        //   console.log(
        //     "Account status updated: ",
        //     this.props.app_state.current_client.account_status
        //   );
        // }
    }

    // //function to add currency
    // addCurrency(map_values, currency, currency_map_value) {
    //     switch (currency) {
    //         case 1:
    //             map_values.AU = currency_map_value
    //             break
    //         case 5:
    //             map_values.US = currency_map_value
    //             break
    //         case 8:
    //             map_values.GB = currency_map_value
    //             break
    //         case 9:
    //             map_values.GB = currency_map_value
    //             map_values.IT = currency_map_value
    //             map_values.ES = currency_map_value
    //             map_values.FR = currency_map_value
    //             map_values.PT = currency_map_value
    //             map_values.DE = currency_map_value
    //             break
    //         case 6:
    //             map_values.AT = currency_map_value
    //             break
    //         default:
    //     }
    // }

    // change(event, stateName, type, stateNameEqualTo) {
    //     switch (type) {
    //         case 'length':
    //             if (this.verifyLength(event.target.value, stateNameEqualTo)) {
    //                 this.setState({ [stateName + '_state']: 'success' })
    //             } else {
    //                 this.setState({ [stateName + '_state']: 'error' })
    //             }
    //             break
    //         case 'isLargerThanNumber':
    //             if (
    //                 this.isLargerThanNumber(
    //                     event.target.value,
    //                     stateNameEqualTo
    //                 )
    //             ) {
    //                 this.setState({ [stateName + '_state']: 'success' })
    //             } else {
    //                 this.setState({ [stateName + '_state']: 'error' })
    //             }
    //             break
    //         default:
    //             break
    //     }
    //     this.setState({ [stateName]: event.target.value })
    //     // Calculate "to amount"
    //     if (stateName === 'from_amount') {
    //         this.setState({ last_user_type_amount_name: 'from_amount' })
    //         this.handleAmount('to_amount')
    //     }
    //     // Calculate "from amount"
    //     if (stateName === 'to_amount') {
    //         this.setState({ last_user_type_amount_name: 'to_amount' })
    //         this.handleAmount('from_amount')
    //     }

    //     // if (stateName === "amount_to") {
    //     //   this.fetchNewRate()
    //     // }
    // }

    // //function to delete currency
    // deleleCurrency(map_values, currency_deleted, the_other_currency) {
    //     switch (currency_deleted) {
    //         case 1: // AUD
    //             delete map_values.AU // Australia
    //             break
    //         case 5: // USD
    //             delete map_values.US // United States
    //             break
    //         case 8: // GBP
    //             // if the other currency is not Euro, delete GB
    //             if (the_other_currency !== 9) {
    //                 delete map_values.GB // United Kingdom
    //             }
    //             break
    //         case 9: // Eur. For Europe: italy, spain, france, portugal, germany
    //             // if the other currency is not GB, delete GB
    //             if (the_other_currency !== 8) {
    //                 delete map_values.GB // United Kingdom
    //             }
    //             delete map_values.IT // Italy
    //             delete map_values.ES // Spain
    //             delete map_values.FR // France
    //             delete map_values.PT // Portugal
    //             delete map_values.DE // Germany
    //             break
    //         case 6: // ATS
    //             delete map_values.AT // 	Austria
    //             break
    //         default:
    //     }
    // }

    // //function to get economic news modules
    // getTabsInfo() {
    //     var ec_info_economicnews = this.props.app_state.current_client
    //         .ec_info_economicnews
    //     var tabsContent = []
    //     // var tmp = {};
    //     if (ec_info_economicnews == 1) {
    //         tabsContent.push({
    //             tabName: 'Economic Calendar',
    //             tabIcon: Cloud,
    //             tabContent: <EconomicCalender />,
    //         })
    //     }

    //     var ec_info_dashboard_charts = this.props.app_state.current_client
    //         .ec_info_dashboard_charts
    //     if (ec_info_dashboard_charts == 1) {
    //         tabsContent.push({
    //             tabName: 'FX Charts',
    //             tabIcon: Cloud,
    //             tabContent: <FxCharts />,
    //         })
    //     }

    //     var economicholidays = this.props.app_state.current_client
    //         .ec_info_economicholidays

    //     if (economicholidays == 1) {
    //         tabsContent.push({
    //             tabName: 'Economic Holidays',
    //             tabIcon: Cloud,
    //             tabContent: <EconomicHoliday />,
    //         })
    //     }

    //     var ec_info_rates = this.props.app_state.current_client.ec_info_rates
    //     if (ec_info_rates == 1) {
    //         tabsContent.push({
    //             tabName: 'Fx Rates',
    //             tabIcon: Cloud,
    //             tabContent: <FxRates />,
    //         })
    //     }

    //     return <Tabs title="" headerColor="info" tabs={tabsContent} />
    // }

    // handleAmount(amount_name) {
    //     let from_currency_Iso = ''
    //     let to_currency_Iso = ''

    //     this.state.currencies_list_priority.map((item, index) => {
    //         if (item.id == this.state.from_currency) {
    //             from_currency_Iso = item.iso_alpha_3
    //         }
    //         if (item.id == this.state.to_currency) {
    //             to_currency_Iso = item.iso_alpha_3
    //         }
    //     })

    //     let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
    //     axios
    //         .get(url, {
    //             params: {
    //                 currencies: to_currency_Iso,
    //                 source: from_currency_Iso,
    //                 format: '1',
    //             },
    //         })
    //         .then(response => {
    //             let key = from_currency_Iso + to_currency_Iso
    //             let rate_string = response.data.quotes[key]

    //             let rate = Number.parseFloat(rate_string)

    //             let rate_adjust =
    //                 Number.parseFloat(
    //                     this.props.app_state.current_client.default_rate
    //                 ) +
    //                 Number.parseFloat(
    //                     this.props.app_state.current_client
    //                         .default_rate_division
    //                 ) +
    //                 Number.parseFloat(
    //                     this.props.app_state.current_client.default_rate_entity
    //                 ) +
    //                 Number.parseFloat(
    //                     this.props.app_state.current_client.default_rate_team
    //                 ) +
    //                 Number.parseFloat(
    //                     this.props.app_state.current_client.default_rate_staff
    //                 )
    //             let adjusted_rate = rate - rate * rate_adjust
    //             if (this.state.from_currency == this.state.to_currency) {
    //                 adjusted_rate = rate
    //             }

    //             if (
    //                 this.state.from_currency_state === 'success' &&
    //                 this.state.to_currency_state === 'success'
    //             ) {
    //                 // handle to_amount
    //                 if (
    //                     amount_name === 'to_amount' &&
    //                     this.state.from_amount_state === 'success'
    //                 ) {
    //                     let amount_from = Number.parseFloat(
    //                         this.state.from_amount
    //                     )
    //                     let amount_to = amount_from * adjusted_rate

    //                     this.setState({ ['to_amount']: amount_to.toFixed(2) })
    //                     if (amount_to >= min_transfer_amount) {
    //                         this.setState({ ['to_amount_state']: 'success' })
    //                     } else {
    //                         this.setState({ ['to_amount_state']: 'error' })
    //                     }
    //                 }

    //                 // handle from_amount
    //                 if (
    //                     amount_name === 'from_amount' &&
    //                     this.state.to_amount_state === 'success'
    //                 ) {
    //                     let amount_to = Number.parseFloat(this.state.to_amount)
    //                     let amount_from = amount_to / adjusted_rate

    //                     this.setState({
    //                         ['from_amount']: amount_from.toFixed(2),
    //                     })
    //                     if (amount_from >= min_transfer_amount) {
    //                         this.setState({ ['from_amount_state']: 'success' })
    //                     } else {
    //                         this.setState({ ['from_amount_state']: 'error' })
    //                     }
    //                 }
    //             }
    //             this.setState({ ['adjusted_rate']: adjusted_rate })
    //             // Save currency name into state for passing query strings
    //             this.setState({ ['from_currency_name']: from_currency_Iso })
    //             this.setState({ ['to_currency_name']: to_currency_Iso })
    //         })
    //         .catch(error => {
    //             console.log(error)
    //         })
    // }

    // handleChange = (event, value) => {
    //     this.setState({ value })
    // }
    // handleChangeIndex = index => {
    //     this.setState({ value: index })
    // }

    // handleCustomReactSelectChange = (
    //     name,
    //     previous_from_currency,
    //     previous_to_currency
    // ) => value => {
    //     var option_value
    //     if (value === null) {
    //         option_value = null
    //     } else {
    //         option_value = value.value
    //     }
    //     this.setState(
    //         {
    //             [name]: option_value,
    //         },
    //         () => {
    //             switch (this.state.last_user_type_amount_name) {
    //                 case 'from_amount':
    //                     this.handleAmount('to_amount')
    //                     break
    //                 case 'to_amount':
    //                     this.handleAmount('from_amount')
    //                     break
    //                 default:
    //                     this.handleAmount()
    //             }
    //         }
    //     )
    //     this.setState({ [name + '_state']: 'success' })
    //     if (name === 'from_currency' || name === 'to_currency') {
    //         this.updateMapData(
    //             name,
    //             previous_from_currency,
    //             previous_to_currency,
    //             value
    //         )
    //     }
    // }

    // // function that verifies if a value is larger than a number or not
    // isLargerThanNumber(value, number) {
    //     if (value !== '' && value >= number) {
    //         return true
    //     }
    //     return false
    // }

    // // render
    // render_account_warning() {
    //     switch (this.props.app_state.current_client.account_status) {
    //         case 1:
    //             return (
    //                 <React.Fragment>
    //                     <Danger>
    //                         <Warning /> Account is not yet activated.
    //                     </Danger>
    //                     <br />
    //                     <p>
    //                         This account has not yet been activated. Please{' '}
    //                         <a href="/user-profile?display=todo">click here </a>
    //                         for more information.
    //                     </p>
    //                 </React.Fragment>
    //             )
    //         default:
    //             return null
    //     }
    // }

    // with beneficinary field
    // renderMainComponentForTransfers() {
    //     const { classes } = this.props
    //     // For CustomReactSelect. Generate select options for dropdown list.
    //     const beneficiary_list_select_options = this.state.beneficiaries_list
    //         .sort((a, b) => a.nickname.localeCompare(b.nickname))
    //         .map(item => {
    //             return { value: item.id, label: item.nickname }
    //         })

    //     return (
    //         <GridItem xs={12}>
    //             <Card style={{ height: '90%' }}>
    //                 <CardHeader color="info" stats icon>
    //                     {/*<CardIcon color="info">*/}
    //                     {/*  <Icon>content_copy</Icon>*/}
    //                     {/*</CardIcon>*/}
    //                     {/*<p className={classes.cardCategory}>Favourite</p>*/}
    //                     {/*<h3 className={classes.cardTitle}>Functions</h3>*/}
    //                 </CardHeader>
    //                 <CardBody>
    //                     <p className={classes.cardCategory}>
    //                         <FormattedMessage
    //                             id="dashboard.heading1"
    //                             defaultMessage={`Welcome to`}
    //                         />
    //                     </p>
    //                     <h3 className={classes.cardTitle}>
    //                         <FormattedMessage
    //                             id="dashboard.heading2"
    //                             defaultMessage={`Forex Student Money Transfer Service`}
    //                         />
    //                     </h3>
    //                     {this.props.app_state.current_brand === 'gostudy' && (
    //                         <span>
    //                             Payment provider for{' '}
    //                             <strong>Go Study Australia</strong>
    //                         </span>
    //                     )}
    //                     {this.props.app_state.current_brand === 'redhill' && (
    //                         <span>
    //                             Payment provider for{' '}
    //                             <strong>RedHill Education</strong>
    //                         </span>
    //                     )}

    //                     <GridContainer justify="center">
    //                         <GridItem xs={6}>
    //                             <hr />
    //                             <p>{/* Welcome to */}</p>
    //                             <p>{/* Welcome message */}</p>
    //                             <br />
    //                             <h4 className={classes.infoText}>
    //                                 <FormattedMessage
    //                                     id="dashboard.heading3"
    //                                     defaultMessage={`Request a new transfer...`}
    //                                 />
    //                             </h4>
    //                             <GridContainer justify="left">
    //                                 <GridItem xs={12} sm={10} md={6}>
    //                                     <CustomReactSelect
    //                                         label={
    //                                             <FormattedMessage
    //                                                 id="dashboard.selectBeneficiary"
    //                                                 defaultMessage={`Select beneficiary`}
    //                                             />
    //                                         }
    //                                         options={
    //                                             beneficiary_list_select_options
    //                                         }
    //                                         value={this.state.beneficiary_id}
    //                                         onChange={this.handleCustomReactSelectChange(
    //                                             'beneficiary_id'
    //                                         )}
    //                                         isClearable={false}
    //                                         // isDisabled={!this.state.edit_mode}
    //                                     />
    //                                 </GridItem>
    //                             </GridContainer>

    //                             <GridContainer justify="left">
    //                                 <GridItem xs={12} sm={10} md={6}>
    //                                     <Button
    //                                         color="primary"
    //                                         // fullWidth
    //                                         // size="sm"
    //                                         onClick={() =>
    //                                             this.props.history.push(
    //                                                 `/payment/new/?beneficiary_id=${
    //                                                     this.state
    //                                                         .beneficiary_id
    //                                                 }`
    //                                             )
    //                                         }
    //                                         disabled={
    //                                             this.state
    //                                                 .beneficiary_id_state !==
    //                                             'success'
    //                                         } //need to be modified later
    //                                     >
    //                                         <FormattedMessage
    //                                             id="dashboard.button"
    //                                             defaultMessage={`Proceed`}
    //                                         />
    //                                     </Button>
    //                                 </GridItem>
    //                             </GridContainer>
    //                         </GridItem>
    //                         <GridItem xs={6}>
    //                             {this.renderMapComponent()}
    //                         </GridItem>
    //                     </GridContainer>
    //                 </CardBody>
    //                 <CardFooter stats>
    //                     <div className={classes.stats}>
    //                         {/*<Danger>*/}
    //                         {/*  <Warning />*/}
    //                         {/*</Danger>*/}
    //                         {/*<a href="#pablo" onClick={e => e.preventDefault()}>*/}
    //                         {/*  Modify Favourites (under construction)*/}
    //                         {/*</a>*/}
    //                     </div>
    //                 </CardFooter>
    //             </Card>
    //         </GridItem>
    //     )
    // }

    // with currency and amount fields
    // renderMainComponentForTransfers2() {
    //     const { classes } = this.props
    //     // For CustomReactSelect. Generate select options for dropdown list.
    //     const select_currency_select_options = this.state.currencies_list_priority.map(
    //         item => ({
    //             value: item.id,
    //             label: item.iso_alpha_3 + ': ' + item.full_name,
    //         })
    //     )

    //     // Dynamic Strings
    //     let transferRateCondition = ''

    //     if (!isNaN(this.state.adjusted_rate)) {
    //         transferRateCondition = 'dashboard.transferRate'
    //     } else {
    //         transferRateCondition = 'dashboard.blank'
    //     }

    //     return (
    //         <GridItem xs={12}>
    //             <Card style={{ height: '90%' }}>
    //                 <CardHeader color="info" stats icon>
    //                     {/*<CardIcon color="info">*/}
    //                     {/*  <Icon>content_copy</Icon>*/}
    //                     {/*</CardIcon>*/}
    //                     {/*<p className={classes.cardCategory}>Favourite</p>*/}
    //                     {/*<h3 className={classes.cardTitle}>Functions</h3>*/}
    //                 </CardHeader>
    //                 <CardBody>
    //                     <p className={classes.cardCategory}>
    //                         <FormattedMessage
    //                             id="dashboard.heading1"
    //                             defaultMessage={`Welcome to`}
    //                         />
    //                     </p>
    //                     <h3 className={classes.cardTitle}>
    //                         <FormattedMessage
    //                             id="dashboard.heading2"
    //                             defaultMessage={`Forex Student Money Transfer Service`}
    //                         />
    //                     </h3>
    //                     <GridContainer justify="center">
    //                         <GridItem xs={6}>
    //                             <p>
    //                                 <FormattedMessage
    //                                     id="dashboard.welcome"
    //                                     defaultMessage={` `}
    //                                 />
    //                             </p>
    //                             <p>
    //                                 <FormattedMessage
    //                                     id="dashboard.welcomeMessage"
    //                                     defaultMessage={` `}
    //                                 />
    //                             </p>
    //                             <br />
    //                             <h4 className={classes.infoText}>
    //                                 <FormattedMessage
    //                                     id="dashboard.heading3"
    //                                     defaultMessage={`Request a new transfer...`}
    //                                 />
    //                             </h4>
    //                             {this.props.app_state.current_brand ===
    //                                 'gostudy' && (
    //                                 <span>
    //                                     Payment provider for{' '}
    //                                     <strong>Go Study Australia</strong>
    //                                 </span>
    //                             )}
    //                             {this.props.app_state.current_brand ===
    //                                 'redhill' && (
    //                                 <span>
    //                                     Payment provider for{' '}
    //                                     <strong>RedHill Education</strong>
    //                                 </span>
    //                             )}

    //                             <GridContainer justify="center">
    //                                 <GridItem xs={12} sm={6}>
    //                                     <CustomReactSelect
    //                                         label={
    //                                             <FormattedMessage
    //                                                 id="dashboard.fromCurrency"
    //                                                 defaultMessage={`From currency `}
    //                                             />
    //                                         }
    //                                         options={
    //                                             select_currency_select_options
    //                                         }
    //                                         value={this.state.from_currency}
    //                                         onChange={this.handleCustomReactSelectChange(
    //                                             'from_currency',
    //                                             this.state.from_currency,
    //                                             this.state.to_currency
    //                                         )}
    //                                         isClearable={false}
    //                                         // isDisabled={!this.state.edit_mode}
    //                                     />
    //                                 </GridItem>
    //                                 <GridItem xs={12} sm={6}>
    //                                     <CustomReactSelect
    //                                         label={
    //                                             <FormattedMessage
    //                                                 id="dashboard.toCurrency"
    //                                                 defaultMessage={`To currency `}
    //                                             />
    //                                         }
    //                                         options={
    //                                             select_currency_select_options
    //                                         }
    //                                         value={this.state.to_currency}
    //                                         onChange={this.handleCustomReactSelectChange(
    //                                             'to_currency',
    //                                             this.state.from_currency,
    //                                             this.state.to_currency
    //                                         )}
    //                                         isClearable={false}
    //                                         // isDisabled={!this.state.edit_mode}
    //                                     />
    //                                 </GridItem>
    //                             </GridContainer>

    //                             <GridContainer justify="center">
    //                                 <GridItem xs={12} sm={6}>
    //                                     <CustomInput
    //                                         success={
    //                                             this.state.from_amount_state ===
    //                                             'success'
    //                                         }
    //                                         error={
    //                                             this.state.from_amount_state ===
    //                                             'error'
    //                                         }
    //                                         labelText={
    //                                             <span>
    //                                                 <FormattedMessage
    //                                                     id="dashboard.fromAmount"
    //                                                     defaultMessage={`From amount`}
    //                                                 />
    //                                             </span>
    //                                         }
    //                                         id="from_amount"
    //                                         labelProps={{
    //                                             shrink: true,
    //                                         }}
    //                                         formControlProps={{
    //                                             fullWidth: true,
    //                                         }}
    //                                         inputProps={{
    //                                             value: this.state.from_amount,
    //                                             onChange: event =>
    //                                                 this.change(
    //                                                     event,
    //                                                     'from_amount',
    //                                                     'isLargerThanNumber',
    //                                                     min_transfer_amount
    //                                                 ),
    //                                         }}
    //                                     />
    //                                 </GridItem>
    //                                 <GridItem xs={12} sm={6}>
    //                                     <CustomInput
    //                                         success={
    //                                             this.state.to_amount_state ===
    //                                             'success'
    //                                         }
    //                                         error={
    //                                             this.state.to_amount_state ===
    //                                             'error'
    //                                         }
    //                                         labelText={
    //                                             <span>
    //                                                 <FormattedMessage
    //                                                     id="dashboard.toAmount"
    //                                                     defaultMessage={`To amount`}
    //                                                 />
    //                                             </span>
    //                                         }
    //                                         id="to_amount"
    //                                         labelProps={{
    //                                             shrink: true,
    //                                         }}
    //                                         formControlProps={{
    //                                             fullWidth: true,
    //                                         }}
    //                                         inputProps={{
    //                                             value: this.state.to_amount,
    //                                             onChange: event =>
    //                                                 this.change(
    //                                                     event,
    //                                                     'to_amount',
    //                                                     'isLargerThanNumber',
    //                                                     min_transfer_amount
    //                                                 ),
    //                                             // disabled: true
    //                                         }}
    //                                         //helpText={!isNaN(this.state.adjusted_rate) ? dashboardTransferRateString+": " + Number.parseFloat(this.state.adjusted_rate).toFixed(5) : ""}
    //                                         helpText={
    //                                             <span>
    //                                                 <FormattedMessage
    //                                                     id={
    //                                                         transferRateCondition
    //                                                     }
    //                                                     defaultMessage={`TransferRate: `}
    //                                                 />
    //                                                 {!isNaN(
    //                                                     this.state.adjusted_rate
    //                                                 )
    //                                                     ? Number.parseFloat(
    //                                                           this.state
    //                                                               .adjusted_rate
    //                                                       ).toFixed(5)
    //                                                     : ''}
    //                                             </span>
    //                                         }
    //                                     />
    //                                 </GridItem>
    //                             </GridContainer>

    //                             <GridContainer justify="left">
    //                                 <GridItem xs={12} sm={12}>
    //                                     <Button
    //                                         color="primary"
    //                                         // fullWidth
    //                                         // size="sm"
    //                                         // onClick={() => this.props.history.push(`/transfers/new/?from_currency=${this.state.from_currency_name}&to_currency=${this.state.to_currency_name}&from_amount=${this.state.from_amount}`)}
    //                                         // onClick={() => this.props.history.push(`/transfers/new/?from_currency=${this.state.from_currency}&to_currency=${this.state.to_currency}&from_amount=${this.state.from_amount}`)}
    //                                         onClick={() => {
    //                                             if (
    //                                                 this.state
    //                                                     .last_user_type_amount_name ===
    //                                                 'from_amount'
    //                                             ) {
    //                                                 this.props.history.push(
    //                                                     `/transfers/new/?from_currency=${
    //                                                         this.state
    //                                                             .from_currency
    //                                                     }&to_currency=${
    //                                                         this.state
    //                                                             .to_currency
    //                                                     }&from_amount=${
    //                                                         this.state
    //                                                             .from_amount
    //                                                     }`
    //                                                 )
    //                                             } else if (
    //                                                 this.state
    //                                                     .last_user_type_amount_name ===
    //                                                 'to_amount'
    //                                             ) {
    //                                                 this.props.history.push(
    //                                                     `/transfers/new/?from_currency=${
    //                                                         this.state
    //                                                             .from_currency
    //                                                     }&to_currency=${
    //                                                         this.state
    //                                                             .to_currency
    //                                                     }&to_amount=${
    //                                                         this.state.to_amount
    //                                                     }`
    //                                                 )
    //                                             }
    //                                         }}
    //                                         disabled={
    //                                             this.state
    //                                                 .from_currency_state !==
    //                                                 'success' ||
    //                                             this.state.to_currency_state !==
    //                                                 'success' ||
    //                                             this.state.from_amount_state !==
    //                                                 'success' ||
    //                                             this.state.to_amount_state !==
    //                                                 'success'
    //                                         }
    //                                     >
    //                                         <FormattedMessage
    //                                             id="dashboard.button"
    //                                             defaultMessage={`Start A New Transfer`}
    //                                         />
    //                                     </Button>
    //                                 </GridItem>
    //                             </GridContainer>
    //                         </GridItem>
    //                         <GridItem xs={6}>
    //                             {this.renderMapComponent()}
    //                         </GridItem>
    //                     </GridContainer>
    //                 </CardBody>
    //                 <CardFooter stats>
    //                     <div className={classes.stats} />
    //                 </CardFooter>
    //             </Card>
    //         </GridItem>
    //     )
    // }

    // renderMapComponent() {
    //     return (
    //         <VectorMap
    //             map={'world_mill'}
    //             backgroundColor="transparent"
    //             zoomOnScroll={false}
    //             containerStyle={{
    //                 width: '100%',
    //                 height: '420px',
    //             }}
    //             containerClassName="map"
    //             regionStyle={{
    //                 initial: {
    //                     fill: '#e4e4e4',
    //                     'fill-opacity': 0.9,
    //                     stroke: 'none',
    //                     'stroke-width': 0,
    //                     'stroke-opacity': 0,
    //                 },
    //             }}
    //             series={{
    //                 regions: [
    //                     {
    //                         values: this.state.map_data,
    //                         scale: ['#ffffff', '#26c6da'],
    //                         normalizeFunction: 'polynomial',
    //                     },
    //                 ],
    //             }}
    //         />
    //     )
    // }

    // toggleChecked(checked) {
    //     if (checked) {
    //         this.setState({
    //             checked: false,
    //             toggleTransfer: '8',
    //             transfer_heading: 'Past Transfers',
    //         })
    //         return false
    //     }
    //     this.setState({
    //         checked: true,
    //         toggleTransfer: '123456',
    //         transfer_heading: 'Current Transfers',
    //     })
    //     return true
    // }

    // updateMapData(
    //     currency_name,
    //     previous_from_currency,
    //     previous_to_currency,
    //     currency_object
    // ) {
    //     var map_values = this.state.map_data
    //     const from_currency_map_value = 1
    //     const to_currency_map_value = 1
    //     // update the properties for visulization
    //     if (currency_name === 'from_currency') {
    //         // delele previous currency property in map_values
    //         if (previous_from_currency !== previous_to_currency) {
    //             this.deleleCurrency(
    //                 map_values,
    //                 previous_from_currency,
    //                 previous_to_currency
    //             )
    //         }
    //         // add new currency property in map_values
    //         this.addCurrency(
    //             map_values,
    //             currency_object.value,
    //             from_currency_map_value
    //         )
    //     }
    //     if (currency_name === 'to_currency') {
    //         // delele previous currency property in map_values
    //         if (previous_to_currency !== previous_from_currency) {
    //             this.deleleCurrency(
    //                 map_values,
    //                 previous_to_currency,
    //                 previous_from_currency
    //             )
    //         }
    //         // add new currency property in map_values
    //         this.addCurrency(
    //             map_values,
    //             currency_object.value,
    //             to_currency_map_value
    //         )
    //     }
    //     this.setState({ map_data: map_values })
    // }

    // // function that verifies if a string has a given length or not
    // verifyLength(value, length) {
    //     if (value.length >= length) {
    //         return true
    //     }
    //     return false
    // }

    render() {
        const { classes } = this.props

        if (!this.props.app_state.current_client) {
            return null
        }
        if (this.props.app_state.current_client.account_status == 1) {
            this.props.history.push('/pending-registration')
        }
        if (this.props.app_state.current_client.account_status == 5) {
            this.props.history.push('/registration')
        }

        if (this.props.app_state.current_client.account_status == 6) {
            this.props.history.push('/migrate')
        }

        // let main_component = null
        // // let main_component_temp = null;
        // if (this.props.app_state.current_brand === 'gostudy') {
        //     main_component = this.renderMainComponentForTransfers()
        // } else {
        //     main_component = (
        //         <DashboardTransfer prefill={this.props.match.params.prefill} />
        //     )
        // }

        let main_component = <DashboardTransfer prefill={this.props.match.params.prefill} />

        return (
            <React.Fragment>
                <div>
                    <GridContainer alignItems="stretch">
                        {main_component}
                        {/* {main_component_temp} */}
                    </GridContainer>

                    {false && (
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card chart className={classes.cardHover}>
                                    <CardHeader
                                        color="info"
                                        className={classes.cardHeaderHover}
                                    >
                                        <ChartistGraph
                                            className="ct-chart-white-colors"
                                            data={this.state.audeur}
                                            type="Line"
                                            options={{
                                                lineSmooth: Chartist.Interpolation.cardinal(
                                                    {
                                                        tension: 0,
                                                    }
                                                ),
                                                low: 0.61,
                                                high: 0.64,
                                                chartPadding: {
                                                    top: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                },
                                            }}
                                            listener={dailySalesChart.animation}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <div
                                            className={classes.cardHoverUnder}
                                        />
                                        <h4 className={classes.cardTitle}>
                                            AUD/EUR 30 days
                                        </h4>
                                        Current price{' '}
                                        {Number.parseFloat(
                                            this.state.current_audeur
                                        ).toFixed(5)}{' '}
                                        (
                                        {Number.parseFloat(
                                            Number(this.state.current_audeur) -
                                                Number(
                                                    this.state.current_audeur
                                                ) *
                                                    Number(
                                                        this.props.app_state
                                                            .current_client
                                                            .default_rate_entity
                                                    )
                                        ).toFixed(5)}
                                        ){/* </p> */}
                                    </CardBody>
                                    <CardFooter chart>
                                        <div className={classes.stats}>
                                            <AccessTime /> updated 30 seconds
                                            ago
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card chart className={classes.cardHover}>
                                    <CardHeader
                                        color="info"
                                        className={classes.cardHeaderHover}
                                    >
                                        <ChartistGraph
                                            className="ct-chart-white-colors"
                                            data={this.state.audusd}
                                            type="Line"
                                            options={{
                                                lineSmooth: Chartist.Interpolation.cardinal(
                                                    {
                                                        tension: 0,
                                                    }
                                                ),
                                                low: 0.7,
                                                high: 0.73,
                                                chartPadding: {
                                                    top: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                },
                                            }}
                                            listener={dailySalesChart.animation}
                                        />
                                        }
                                    </CardHeader>
                                    <CardBody>
                                        <div
                                            className={classes.cardHoverUnder}
                                        />
                                        <h4 className={classes.cardTitle}>
                                            AUD/USD 30 days
                                        </h4>
                                        <p className={classes.cardCategory}>
                                            Current price{' '}
                                            {Number.parseFloat(
                                                this.state.current_audusd
                                            ).toFixed(5)}
                                        </p>
                                    </CardBody>
                                    <CardFooter chart>
                                        <div className={classes.stats}>
                                            <AccessTime /> updated 30 seconds
                                            ago
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card chart className={classes.cardHover}>
                                    <CardHeader
                                        color="info"
                                        className={classes.cardHeaderHover}
                                    >
                                        <ChartistGraph
                                            className="ct-chart-white-colors"
                                            data={this.state.audnzd}
                                            type="Line"
                                            options={{
                                                lineSmooth: Chartist.Interpolation.cardinal(
                                                    {
                                                        tension: 0,
                                                    }
                                                ),
                                                low: 1.03,
                                                high: 1.06,
                                                chartPadding: {
                                                    top: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                },
                                            }}
                                            listener={dailySalesChart.animation}
                                        />
                                        }
                                    </CardHeader>
                                    <CardBody>
                                        <div
                                            className={classes.cardHoverUnder}
                                        />
                                        <h4 className={classes.cardTitle}>
                                            AUD/NZD 30 days
                                        </h4>
                                        Current price{' '}
                                        {Number.parseFloat(
                                            this.state.current_audnzd
                                        ).toFixed(5)}{' '}
                                        (
                                        {Number.parseFloat(
                                            Number(this.state.current_audnzd) -
                                                Number(
                                                    this.state.current_audnzd
                                                ) *
                                                    Number(
                                                        this.props.app_state
                                                            .current_client
                                                            .default_rate_entity
                                                    )
                                        ).toFixed(5)}
                                        ){/* </p> */}
                                    </CardBody>
                                    <CardFooter chart>
                                        <div className={classes.stats}>
                                            <AccessTime /> updated 30 seconds
                                            ago
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    )}

                    {this.props.app_state.current_client.ec_info_dashboard == 1 && (
                        <></>
                        // <GridContainer>
                        //     <GridItem xs={12} sm={12} md={12}>
                        //         <Card chart className={classes.cardHover}>
                        //             <CardBody>{this.getTabsInfo()}</CardBody>
                        //         </Card>
                        //     </GridItem>
                        // </GridContainer>
                    )}

                    {this.state.has_transfer_record && ( //if has_transfer_record, the component displays #742
                        <GridContainer alignItems="stretch">
                            <GridItem xs={12} md={12}>
                                <Card
                                    style={{
                                        display: 'flex',
                                        height: '90%',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <CardHeader color="info" stats icon>
                                        <CardIcon color="info">
                                            <i className="fas fa-exchange-alt" />
                                        </CardIcon>
                                        <p className={classes.cardCategory} />
                                        <h3 className={classes.cardTitle}>
                                            {/* {this.state.transfer_heading} */}
                                            Recent Transfers
                                        </h3>

                                        {/* Turn on for #774*/}
                                        {/* <div>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={
                                                            this.state.checked
                                                        }
                                                        onChange={event =>
                                                            this.toggleChecked(
                                                                event.target
                                                                    .checked
                                                            )
                                                        }
                                                        value="checkedA"
                                                        classes={{
                                                            switchBase:
                                                                classes.switchBase,
                                                            checked:
                                                                classes.switchChecked,
                                                            thumb:
                                                                classes.switchIcon,
                                                            track:
                                                                classes.switchBar,
                                                        }}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label,
                                                }}
                                                label={
                                                    'Switch current/past transfers'
                                                }
                                            />
                                        </div> */}
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12}>
                                                <CustomTransferList
                                                    //#774 debug needs to turn this on
                                                    status={
                                                        this.state
                                                            .toggleTransfer
                                                    }
                                                    // start_date={"01-09-2019"}
                                                    // end_date={"02-09-2019"}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                    <CardFooter stats />
                                </Card>
                            </GridItem>
                        </GridContainer>
                    )}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

const ExternalTransferPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExternalTransferPage)

export default withRouter(
    withStyles(dashboardStyle)(ExternalTransferPageContainer)
)
