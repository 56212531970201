import React from 'react'

const CurrencyRatesWithFlags = ({ from, to, rate }) => {
    return (
        <div
            style={{ display: 'inline-flex', alignItems: 'center', gap: '2px', backgroundColor: '#fbfbfb' }}
        >
            <div
                className={`currency-flag currency-flag-${
                    from ? from.toLowerCase() : ''
                }`}
            /> <span style={{fontSize: '0.8rem', paddingTop: 4}}>{from}</span>
            <div
                style={{ marginLeft: '4px' }}
                className={`currency-flag currency-flag-${
                    to ? to.toLowerCase() : ''
                }`}
            /> <span style={{fontSize: '0.8rem', paddingTop: 4}}>{to}</span>
            <span style={{ marginLeft: '4px' }}>{rate.toFixed(5)}</span>
        </div>
    )
}

export default CurrencyRatesWithFlags
