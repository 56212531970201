import { connect } from 'react-redux'
import Step2PayingTo from './Step2PayingTo'
import { updateNewTransferCreation } from '../../../../redux/actions/transfers'

import {
    //startNewBeneficiaryCreation,
    updateNewBeneficiaryCreation,
    createNewBeneficiary,
} from '../../../../redux/actions/beneficiaries'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        beneficiaries: state.beneficiaries,
        transfers: state.transfers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewTransferCreation: (key, value) => {
            dispatch(updateNewTransferCreation(key, value))
        },
        //startNewBeneficiaryCreation: () => {
        //  dispatch(startNewBeneficiaryCreation())
        //},
        createNewBeneficiary: data => {
            dispatch(createNewBeneficiary(data))
        },
        updateNewBeneficiaryCreation: (key, value) => {
            dispatch(updateNewBeneficiaryCreation(key, value))
        },
    }
}

const Step2PayingToContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Step2PayingTo)

export default Step2PayingToContainer
