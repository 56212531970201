import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { onLoginSuccess, updateCurrentBrand } from '../../redux/actions'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'

// @material-ui/icons
import Email from '@material-ui/icons/Email'
// import LockOutline from "@material-ui/icons/LockOutline";
// import Face from "@material-ui/icons/Face";
import Edit from '@material-ui/icons/Edit'

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import CustomInput from '../../components/CustomInput/CustomInput.jsx'
import Button from '../../components/CustomButtons/Button.jsx'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import CardHeader from '../../components/Card/CardHeader.jsx'
import CardFooter from '../../components/Card/CardFooter.jsx'

// import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import { API, Auth } from 'aws-amplify'

// import { GoogleLogin } from 'react-google-login';

// react component used to create sweet alerts
import SweetAlert from 'react-bootstrap-sweetalert'
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'

// original components import in the "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx"
import {
    container,
    cardTitle,
} from 'assets/jss/material-dashboard-pro-react.jsx'

import { FormattedMessage } from 'react-intl'

//Tom query things
import queryString from 'query-string'

// Combine sweetAlertStyle and loginPageStyle
const style = theme => ({
    ...sweetAlertStyle,
    container: {
        ...container,
        zIndex: '4',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '100px',
        },
    },
    cardTitle: {
        ...cardTitle,
        color: '#FFFFFF',
    },
    textCenter: {
        textAlign: 'center',
    },
    justifyContentCenter: {
        justifyContent: 'center !important',
    },
    customButtonClass: {
        '&,&:focus,&:hover': {
            color: '#FFFFFF',
        },
        marginLeft: '5px',
        marginRight: '5px',
    },
    inputAdornment: {
        marginRight: '18px',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    cardHidden: {
        opacity: '0',
        transform: 'translate3d(0, -60px, 0)',
    },
    cardHeader: {
        marginBottom: '20px',
    },
    socialLine: {
        padding: '0.9375rem 0',
    },
})

// console.log(style);

// function facebookWaitForInit() {
//   return new Promise((res, rej) => {
//     const hasFbLoaded = () => {
//       if (window.FB) {
//         res();
//       } else {
//         setTimeout(hasFbLoaded, 300);
//       }
//     };
//     hasFbLoaded();
//   });
// }

class LoginPage extends React.Component {
    constructor(props) {
        super(props)
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            cardAnimation: 'cardHidden',
            email: '',
            password: '',
            // facebookIsLoading: true,
            password_reset_code_sending: false,
            password_reset_code_sent: false,
            password_reset_confirming: false,
            password_reset_confirmed: false,
            confirm_code: '',
            alert: null,
        }
    }

    componentDidMount = async () => {
        //Tom query things and password Encryption and Decryption module
        const query_strings = queryString.parse(this.props.location.search)
        const email = query_strings['email']
        const pw = query_strings['referrer']
        const tc = query_strings['tc']

        //clean up the code sample
        //let password = "Passw0rd!";
        //let encrypted = btoa(password);
        //let decrypted = atob(encrypted);
        let decrypted = atob(pw)

        //let tc = Date.now();
        let tc60 = Date.now() + 60000
        let tc2 = Date.now()

        if (tc2 >= tc && tc2 < tc60) {
            // http://localhost:3006/pages/login-direct?email=andredicioccio@gmail.com&referrer=Passw0rd!
            // http://localhost:3006/pages/login-direct?email=andredicioccio@gmail.com&referrer=UGFzc3cwcmQh
            // console.log(query_strings);
            // console.log(email);

            this.setState({
                email: email,
                password: decrypted,
            })

            if (email && decrypted) {
                try {
                    await Auth.signIn(email.toLowerCase(), decrypted)
                    // this.props.userHasAuthenticated(true);
                    // const user_profile = await this.getUserProfile(user.username);
                    // const { username, first_name, middle_name, last_name } = user_profile;
                    this.props.userHasAuthenticated(true)

                    // this.props.set_current_user(user_profile);
                    // this.props.onLoginSuccess(user.username);
                    // this.props.history.push("/");
                } catch (e) {
                    // alert(e.message);
                    console.log(e)
                    if (e.code === 'UserNotConfirmedException') {
                        const newUser = await Auth.resendSignUp(
                            this.state.email.toLowerCase()
                        )
                        this.props.history.push('/pages/verify-email')
                        // console.log(newUser);
                    }
                    if (e.code === 'NotAuthorizedException') {
                        // const newUser = await Auth.resendSignUp(this.state.email);
                        // this.props.history.push("/pages/verify-email");
                        // console.log(newUser);
                        this.setState({
                            password: '',
                        })
                        alert(e.message)
                    }
                    if (e.code === 'UserNotFoundException') {
                        // const newUser = await Auth.resendSignUp(this.state.email);
                        // this.props.history.push("/pages/verify-email");
                        // console.log(newUser);
                        this.setState({
                            password: '',
                        })
                        alert(e.message)
                    }
                }
            }
        }

        console.log(this.props.match.params.team)
        if (this.props.match.params.team) {
            this.props.updateCurrentBrand(this.props.match.params.team)
        }
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function() {
                this.setState({ cardAnimation: '' })
            }.bind(this),
            700
        )
        const ga =
            window.gapi && window.gapi.auth2
                ? window.gapi.auth2.getAuthInstance()
                : null
        if (!ga) this.createScript()
        // await facebookWaitForInit();
        // this.setState({ facebookIsLoading: false });
    }

    componentWillUnmount() {
        clearTimeout(this.timeOutFunction)
        this.timeOutFunction = null
    }

    // Google Login Code
    createScript() {
        // load the Google SDK
        const script = document.createElement('script')
        script.src = 'https://apis.google.com/js/platform.js'
        script.async = true
        script.onload = this.initGapi
        document.body.appendChild(script)
    }

    initGapi() {
        // init the Google SDK client
        const g = window.gapi
        g.load('auth2', function() {
            g.auth2.init({
                client_id:
                    '466361976938-6j1ti27carhuh59pilb69ghv3o293q0e.apps.googleusercontent.com',
                // authorized scopes
                scope: 'profile email openid',
            })
        })
    }

    signInGoogle = () => {
        const ga = window.gapi.auth2.getAuthInstance()
        ga.signIn().then(
            googleUser => {
                this.getAWSCredentials(googleUser)
            },
            error => {
                console.log(error)
            }
        )
    }
    getAWSCredentials = async googleUser => {
        // console.log(googleUser)
        const { id_token, expires_at } = googleUser.getAuthResponse()
        const profile = googleUser.getBasicProfile()
        let user = {
            email: profile.getEmail(),
            name: profile.getName(),
        }
        // console.log(user)

        // const credentials = await Auth.federatedSignIn(
        //   'google',
        //   { token: id_token, expires_at },
        //   user
        // );
        await Auth.federatedSignIn(
            'google',
            { token: id_token, expires_at },
            user
        )
        // console.log('credentials', credentials);
        const user_profile = await this.getUserProfileByEmail(user.email)
        // console.log(user_profile);

        if (user_profile) {
            this.props.userHasAuthenticated(true)
            this.props.set_current_user(user_profile.aws)
            this.props.onLoginSuccess(user_profile.aws_cognito_id)
            this.props.history.push('/')
        } else {
            this.props.history.push(
                `/pages/register-page?source=google&email=${user.email}&name=${
                    user.name
                }`
            )
        }
    }

    // // Facebook Login code
    // signInFacebook = () => {
    //   window.FB.login(this.checkLoginState, { scope: "public_profile,email" });
    // };
    // checkLoginState = () => {
    //   window.FB.getLoginStatus(this.statusChangeCallback);
    // };
    // statusChangeCallback = response => {
    //   if (response.status === "connected") {
    //     this.facebookHandleResponse(response.authResponse);
    //   } else {
    //     this.facebookHandleError(response);
    //   }
    // };

    // facebookHandleError(error) {
    //   alert(error);
    // }

    // facebookHandleResponse(data) {
    //   console.log(data);
    //   const { accessToken, expiresIn } = data;
    //   const expires_at = expiresIn * 1000 + new Date().getTime();
    //   if (!accessToken) {
    //     return;
    //   }

    //   const fb = window.FB;
    //   fb.api("/me", { fields: "name,email" }, response => {
    //     console.log(response);
    //     const user = {
    //       name: response.name,
    //       email: response.email
    //     };
    //     console.log(user);
    //     Auth.federatedSignIn("facebook", { token: accessToken, expires_at }, user)
    //       .then(async credentials => {
    //         console.log(credentials);
    //         console.log(user);
    //         const user_profile = await this.getUserProfileByEmail(user.email);
    //         console.log(user_profile);
    //         if (user_profile) {
    //           this.props.userHasAuthenticated(true);
    //           this.props.set_current_user(user_profile);
    //           this.props.onLoginSuccess(user_profile.aws_cognito_id);
    //           this.props.history.push("/");
    //         } else {
    //           this.props.history.push(`/pages/register-page?source=facebook&email=${user.email}&name=${user.name}`);
    //         }

    //       });
    //   });
    //   // })
    //   // const user_profile = await this.getUserProfileByEmail(user.email);
    //   // console.log(user_profile);
    //   // this.props.set_current_user(user_profile);
    //   // this.props.history.push("/");

    //   // const user_profile = await this.getUserProfileByEmail(credentials.email);
    //   // console.log(user_profile);
    //   // this.props.userHasAuthenticated(true);
    //   // // const user_profile = await this.getUserProfile(user.username);
    //   // // const { username, first_name, middle_name, last_name } = user_profile;
    //   // this.props.set_current_user(user_profile);
    //   // // this.props.onLoginSuccess(user.username);
    //   // this.props.history.push("/");

    //   // console.log(user)
    //   // this.setState({ facebookIsLoading: true });

    //   // try {
    //   //   const credentials = await Auth.federatedSignIn(
    //   //     "facebook",
    //   //     { token, expires_at },
    //   //     user
    //   //   );
    //   //   this.setState({ facebookIsLoading: false });
    //   //   // this.props.onLogin(response);
    //   //   console.log('credentials', credentials);

    //   // const user_profile = await this.getUserProfileByEmail(credentials.username);
    //   // console.log(user_profile);
    //   // this.props.userHasAuthenticated(true);
    //   // const user_profile = await this.getUserProfile(user.username);
    //   // const { username, first_name, middle_name, last_name } = user_profile;
    //   // this.props.set_current_user(user_profile);
    //   // this.props.onLoginSuccess(user.username);
    //   // this.props.history.push("/");

    //   // } catch (e) {
    //   //   this.setState({ facebookIsLoading: false });
    //   //   this.facebookHandleError(e);
    //   // }
    // }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0
    }

    validate_email() {
        let value = this.state.email
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (emailRex.test(value)) {
            return true
        }
        return false
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value,
        })
    }

    handleSubmit = async event => {
        event.preventDefault()
        // this.props.history.push("/");
        try {
            await Auth.signIn(
                this.state.email.toLowerCase(),
                this.state.password
            )
            // this.props.userHasAuthenticated(true);
            // const user_profile = await this.getUserProfile(user.username);
            // const { username, first_name, middle_name, last_name } = user_profile;
            this.props.userHasAuthenticated(true)

            // this.props.set_current_user(user_profile);
            // this.props.onLoginSuccess(user.username);
            // this.props.history.push("/");
        } catch (e) {
            // alert(e.message);
            console.log(e)
            if (e.code === 'UserNotConfirmedException') {
                const newUser = await Auth.resendSignUp(
                    this.state.email.toLowerCase()
                )
                this.props.history.push('/pages/verify-email')
                // console.log(newUser);
            }
            if (e.code === 'NotAuthorizedException') {
                // const newUser = await Auth.resendSignUp(this.state.email);
                // this.props.history.push("/pages/verify-email");
                // console.log(newUser);
                this.setState({
                    password: '',
                })
                alert(e.message)
            }
            if (e.code === 'UserNotFoundException') {
                // const newUser = await Auth.resendSignUp(this.state.email);
                // this.props.history.push("/pages/verify-email");
                // console.log(newUser);
                this.setState({
                    password: '',
                })
                alert(e.message)
            }
        }
    }

    async populateData(aws_cognito_id) {
        try {
            console.log(aws_cognito_id)
            const user = await this.getUserProfile(aws_cognito_id)
            const { username, first_name, middle_name, last_name } = user
            console.log(user)

            this.setState({
                user,
                username,
                first_name,
                middle_name,
                last_name,
            })
        } catch (e) {
            console.error(e)
        }
    }

    async getUserProfile(aws_cognito_id) {
        return API.get('portal', `/users/get-by-id/${aws_cognito_id}`)
    }

    async getUserProfileByEmail(email) {
        return API.get('portal', `/users/get-by-email/${email}`)
    }

    responseGoogle = response => {
        console.log(response)
    }

    // Forgotten password functionality
    handle_forgot_password_click = async email => {
        this.setState({ password_reset_code_sending: true })

        try {
            await Auth.forgotPassword(this.state.email.toLowerCase())
            this.setState({ password_reset_code_sent: true })
        } catch (e) {
            alert(e.message)
            this.setState({ password_reset_code_sending: false })
        }
    }

    handle_forgot_password_confirm_click = async event => {
        event.preventDefault()

        this.setState({ password_reset_confirming: true })

        try {
            await Auth.forgotPasswordSubmit(
                this.state.email.toLowerCase(),
                this.state.confirm_code,
                this.state.password
            )
            this.setState({ password_reset_confirmed: true })
        } catch (e) {
            alert(e.message)
            this.setState({ password_reset_confirming: false })
        }
    }

    validate_password_reset_form() {
        return (
            this.state.confirm_code.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.password_confirm
        )
    }

    validate_confirmation_form() {
        return this.state.confirm_code.length > 0
    }

    render_confirmation_form() {
        const { classes } = this.props
        return (
            <form
                className={classes.form}
                onSubmit={this.handle_forgot_password_confirm_click}
            >
                <Card login className={classes[this.state.cardAnimaton]}>
                    <CardHeader
                        className={`${classes.cardHeader} ${
                            classes.textCenter
                        }`}
                        color="primary"
                    >
                        <h4 className={classes.cardTitle}>Reset Password</h4>
                    </CardHeader>
                    <CardBody>
                        <p>Use this form to set a new password.</p>
                        <p>
                            You will need to enter a confirmation code which has
                            been emailed to: {this.state.email}.
                        </p>
                        <CustomInput
                            id="confirm_code"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                                value: this.state.confirm_code,
                                onChange: event => {
                                    this.handleChange(event)
                                },
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        className={classes.inputAdornment}
                                    >
                                        <Edit
                                            className={
                                                classes.inputAdornmentIcon
                                            }
                                        />
                                    </InputAdornment>
                                ),
                                placeholder: 'Enter Code From Email...',
                            }}
                        />
                        <CustomInput
                            // labelText="Password..."
                            id="password"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                                value: this.state.password,
                                onChange: event => {
                                    this.handleChange(event)
                                },
                                type: 'password',
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        className={classes.inputAdornment}
                                    >
                                        <Icon
                                            className={
                                                classes.inputAdornmentIcon
                                            }
                                        >
                                            lock_outline
                                        </Icon>
                                    </InputAdornment>
                                ),
                                placeholder: 'Set New Password...',
                            }}
                        />
                        <CustomInput
                            // labelText="Confirm Password..."
                            id="confirm_password"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                                value: this.state.confirm_password,
                                onChange: event => {
                                    this.handleChange(event)
                                },
                                type: 'password',
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        className={classes.inputAdornment}
                                    >
                                        <Icon
                                            className={
                                                classes.inputAdornmentIcon
                                            }
                                        >
                                            lock_outline
                                        </Icon>
                                    </InputAdornment>
                                ),
                                placeholder: 'Confirm New Password...',
                            }}
                        />
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                        <Button
                            color="rose"
                            simple
                            size="lg"
                            block
                            disabled={!this.validate_confirmation_form()}
                            type="submit"
                            // onClick={() => this.props.history.push("/pages/login-page")}
                        >
                            Set New Password
                        </Button>
                    </CardFooter>
                </Card>
            </form>
        )
    }

    render_login_form() {
        const { classes } = this.props
        // console.log(this.props);

        //setup for language components
        let languageObj_en_json = require('../../translations/en.json')
        let languageObj_zh_json = require('../../translations/zh.json')
        let languageObj_de_json = require('../../translations/de.json')
        let languageObj_es_json = require('../../translations/es.json')
        let languageObj_fr_json = require('../../translations/fr.json')
        let languageObj_it_json = require('../../translations/it.json')
        let languageObj_pt_json = require('../../translations/pt.json')

        //this.props.language.language_current_ui
        let languageObj = ''
        switch (this.props.language.language_current_ui) {
            case 'es':
                languageObj = languageObj_es_json
                //menu_item_text = prop.name_es || prop.name;
                break
            case 'de':
                languageObj = languageObj_de_json
                //menu_item_text = prop.name_de || prop.name;
                break
            case 'zh':
                languageObj = languageObj_zh_json
                //menu_item_text = prop.name_zh || prop.name;
                break
            case 'fr':
                languageObj = languageObj_fr_json
                //menu_item_text = prop.name_fr || prop.name;
                break
            case 'it':
                languageObj = languageObj_it_json
                //menu_item_text = prop.name_it || prop.name;
                break
            case 'pt':
                languageObj = languageObj_pt_json
                //menu_item_text = prop.name_it || prop.name;
                break
            default:
                languageObj = languageObj_en_json
                //menu_item_text = prop.name;
                break
        }

        // Handle Branding
        let login_form_title = 'Client Portal Login'
        login_form_title = languageObj['login.formTitle']
        {
            /*<FormattedMessage 
      id="login.formTitle"
      defaultMessage={
        `Client Portal Login`} 
      />*/
        }
        switch (this.props.app_state.current_brand) {
            case 'gostudy':
                login_form_title = 'Go Study Student Login'
                break
        }

        return (
            <form onSubmit={this.handleSubmit}>
                <Card login className={classes[this.state.cardAnimation]}>
                    <CardHeader
                        className={`${classes.cardHeader} ${
                            classes.textCenter
                        }`}
                        color="primary"
                    >
                        <h4 className={classes.cardTitle}>
                            {login_form_title}
                            {/*<FormattedMessage 
              id="login.formTitle"
              defaultMessage={
                `Client Portal Login`} 
              />*/}
                        </h4>
                        {/* <Button
               color="transparent"
               justIcon
               key={"fblogin"}
               className={classes.customButtonClass}
               onClick={this.signInFacebook}
              >
               <i className={"fab fa-facebook-square"}/>
              </Button>
              <Button
               color="transparent"
               justIcon
               key={"googlelogin"}
               className={classes.customButtonClass}
               onClick={this.signInGoogle}
              >
               <i className={"fab fa-google-plus"}/>
              </Button> */}
                    </CardHeader>
                    <CardBody>
                        <CustomInput
                            labelText={
                                <FormattedMessage
                                    id="login.email"
                                    defaultMessage={`Email...`}
                                />
                                //"Email..."
                            }
                            id="email"
                            value={this.state.email}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: this.state.email,
                                onChange: event => {
                                    this.handleChange(event)
                                },
                                type: 'email',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Email
                                            className={
                                                classes.inputAdornmentIcon
                                            }
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <CustomInput
                            labelText={
                                <FormattedMessage
                                    id="login.password"
                                    defaultMessage={`Password`}
                                />
                                //"Password"
                            }
                            id="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: this.state.password,
                                onChange: event => {
                                    this.handleChange(event)
                                },
                                type: 'password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon
                                            className={
                                                classes.inputAdornmentIcon
                                            }
                                        >
                                            lock_outline
                                        </Icon>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <p style={{ fontSize: '10px', textAlign: 'center' }}>
                            To reset password, enter email and click 'reset
                            password'..
                            <br />
                            By continuing you accept our{' '}
                            <a
                                href="https://www.forexworldwide.com/legal"
                                target="_blank"
                            >
                                Terms and Conditions
                            </a>
                            .
                        </p>

                        {/*<p className={classes.justifyContentCenter}>*/}
                        {/*  Please note, your use of this system is subject to the terms and conditions*/}
                        {/*  contained at <a href="https://www.forexworldwide.com/legal" target="_blank">*/}
                        {/*  www.forexworldwide.com/legal</a>*/}
                        {/*</p>*/}
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                        <GridContainer justify="center">
                            <GridContainer justify="center">
                                <GridItem xs={9} sm={9} md={9}>
                                    <Button
                                        color="rose"
                                        disabled={!this.validateForm()}
                                        type="submit"
                                        size="sm"
                                        block
                                    >
                                        <GridContainer>
                                            <GridItem xs={1} sm={1} md={1}>
                                                <Icon>lock_outline</Icon>
                                            </GridItem>
                                            <GridItem xs={10} sm={10} md={10}>
                                                <FormattedMessage
                                                    id="login.login"
                                                    defaultMessage={`Login`}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </Button>
                                </GridItem>
                            </GridContainer>
                            <GridContainer justify="center">
                                <GridItem xs={9} sm={9} md={9}>
                                    <Button
                                        color="info"
                                        disabled={!this.validate_email()}
                                        type="submit"
                                        size="sm"
                                        block
                                        onClick={() =>
                                            this.handle_forgot_password_click(
                                                this.state.email
                                            )
                                        }
                                    >
                                        <GridContainer>
                                            <GridItem xs={1} sm={1} md={1}>
                                                <Icon>lock_outline</Icon>
                                            </GridItem>
                                            <GridItem xs={10} sm={10} md={10}>
                                                <FormattedMessage
                                                    id="login.resetPassword"
                                                    defaultMessage={`Reset Password`}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </Button>
                                </GridItem>
                            </GridContainer>
                            {/* <GridContainer justify="center">
                <GridItem xs={9} sm={9} md={9}>
                  <Button
                    color="facebook"
                    // justIcon
                    key={"fblogin"}
                    // onClick={this.signInFacebook}
                    onClick={this.warningAlert.bind(this)}
                    // simple
                    size="sm"
                    // disabled={!this.validate_email()}
                    block
                  >
                    <GridContainer>
                      <GridItem xs={1} sm={1} md={1}>
                        <i className={"fab fa-facebook-square"} />
                      </GridItem>
                      <GridItem xs={10} sm={10} md={10}>
                        <FormattedMessage
                          id="login.loginWithFacebook"
                          defaultMessage={
                            `Login with Facebook`}
                        />
                      </GridItem>
                    </GridContainer>
                  </Button>
                </GridItem>
              </GridContainer> */}
                            <GridContainer justify="center">
                                <GridItem xs={9} sm={9} md={9}>
                                    <Button
                                        color="google"
                                        // justIcon
                                        key={'googlelogin'}
                                        onClick={this.signInGoogle}
                                        // simple
                                        size="sm"
                                        // disabled={!this.validate_email()}
                                        block
                                    >
                                        <GridContainer>
                                            <GridItem xs={1} sm={1} md={1}>
                                                <i
                                                    className={'fab fa-google'}
                                                />
                                            </GridItem>
                                            <GridItem xs={10} sm={10} md={10}>
                                                <FormattedMessage
                                                    id="login.loginWithGoogle"
                                                    defaultMessage={`Login with Google`}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </GridContainer>
                    </CardFooter>
                </Card>
            </form>
        )
    }

    render_success_message() {
        const { classes } = this.props
        return (
            <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="primary"
                >
                    <h4 className={classes.cardTitle}>
                        Password Reset Success
                    </h4>
                </CardHeader>
                <CardBody>
                    <p>Your password has been reset.</p>
                    <p>
                        <a href="/pages/login">Click here </a>
                        to login with your new credentials.
                    </p>
                </CardBody>
            </Card>
        )
    }

    // warningAlert() {
    //   console.log("asdasdsa")
    //   this.setState({
    //     alert: (
    //       <SweetAlert
    //         warning
    //         style={{ display: "block", marginTop: "-100px" }}
    //         title={<span style={{ color: "black" }}>Sorry!</span>}
    //         onConfirm={() => this.hideAlert()}
    //         onCancel={() => this.hideAlert()}
    //         confirmBtnCssClass={
    //           this.props.classes.button + " " + this.props.classes.success
    //         }
    //       >
    //         <span style={{ color: "black" }}>Facebook Login is presently unavailable!</span>
    //       </SweetAlert>
    //     )
    //   });
    // }

    // hideAlert() {
    //   this.setState({
    //     alert: null
    //   });
    // }

    render() {
        const { classes } = this.props
        // console.log(this.props.match.params);
        //console.log(this.props)
        return (
            <div className={classes.container}>
                {this.state.alert}
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        {!this.state.password_reset_code_sent
                            ? this.render_login_form()
                            : !this.state.password_reset_confirmed
                                ? this.render_confirmation_form()
                                : this.render_success_message()}
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

// export default withStyles(style, { withTheme: true })(LoginPage);

// import Link from onLoginSuccess../components/Link'
// import LoginPage from "./LoginPage";

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateCurrentBrand: brand => {
            dispatch(updateCurrentBrand(brand))
        },
        onLoginSuccess: user_id => {
            dispatch(onLoginSuccess(user_id))
        },
    }
}

const LoginPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(style, { withTheme: true })(LoginPage))

export default LoginPageContainer
