import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import Assignment from '@material-ui/icons/Assignment'
// import Person from "@material-ui/icons/Person";
// import Edit from "@material-ui/icons/Edit";
// import Close from "@material-ui/icons/Close";

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
// import Table from "components/Table/Table.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'

import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'

import EconomicInformationPage from '../../components/EconomicInformation/EconomicInformationPage'

class CurrencyTools extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: [],
        }
        this.handleToggle = this.handleToggle.bind(this)
    }
    handleToggle(value) {
        const { checked } = this.state
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        this.setState({
            checked: newChecked,
        })
    }
    render() {
        // eslint-disable-next-line react/prop-types
        const { classes } = this.props
        // const roundButtons = [
        //   { color: "info", icon: Person },
        //   { color: "success", icon: Edit },
        //   { color: "danger", icon: Close }
        // ].map((prop, key) => {
        //   return (
        //     <Button
        //       round
        //       color={prop.color}
        //       className={classes.actionButton + " " + classes.actionButtonRound}
        //       key={key}
        //     >
        //       <prop.icon className={classes.icon} />
        //     </Button>
        //   );
        // });
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Currency Tools
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <EconomicInformationPage
                            // currency_from_id={this.props.transfers_dashboard.new_transfer_data.currency_from_id}
                            // currency_to_id={this.props.transfers_dashboard.new_transfer_data.currency_to_id}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default withStyles(extendedTablesStyle)(CurrencyTools)
