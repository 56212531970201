import React from 'react'
import { connect } from 'react-redux'
import { API, Storage } from 'aws-amplify'
import {
    change_current_step,
    clearNewTransfer,
    updateNewTransferCreation,
    updateNewTransferCreationBeneficiary,
} from '../../redux/actions/transfers_dashboard'
import withStyles from '@material-ui/core/styles/withStyles'
import dashboardStyle from '../../views/Pages/DashboardPageStyle'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'

import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'

import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

//core for react-intl
import { FormattedMessage } from 'react-intl'
import { VectorMap } from 'react-jvectormap'
import DashboardTransferStep1 from './DashboardTransferStep1'
import DashboardTransferStep2 from './DashboardTransferStep2'
import DashboardTransferStep3 from './DashboardTransferStep3'
import EconomicHoliday from '../EconomicInformation/EconomicHoliday'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />
})

const moment = require('moment')

class DashboardTransfer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            current_step: 1,
            economic_info_modal_open: false,
            economic_info_modal_title: '',
            secondStepFunction: null,
            fetchNewRate: null,
            time: {},
            seconds: 1,
        }
        this.timer = 0
        this.go = false
    }

    componentDidMount() {}

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.prefill !== this.props.prefill) {
            this.props.clearNewTransfer()
        }
    }

    componentWillUnmount() {
        this.props.clearNewTransfer()
    }

    isValidated(step) {
        let return_flag = true
        console.log('props are', this.props)
        if (step === 1) {
            let beneficiary_array = this.props.transfers_dashboard
                .new_transfer_data.multiple_beneficiary_id || [{}]
            if (
                this.props.transfers_dashboard.new_transfer_data
                    .currency_from_state !== 'success'
            ) {
                this.props.updateNewTransferCreation(
                    'currency_from_state',
                    'error'
                )
                return_flag = false
            }
            if (
                this.props.transfers_dashboard.new_transfer_data
                    .currency_to_state !== 'success'
            ) {
                this.props.updateNewTransferCreation(
                    'currency_to_state',
                    'error'
                )
                return_flag = false
            }
            if (
                this.props.transfers_dashboard.new_transfer_data
                    .amount_from_state !== 'success'
            ) {
                this.props.updateNewTransferCreation(
                    'amount_from_state',
                    'error'
                )
                return_flag = false
            }
            if (
                this.props.transfers_dashboard.new_transfer_data
                    .amount_to_state !== 'success'
            ) {
                this.props.updateNewTransferCreation('amount_to_state', 'error')
                return_flag = false
            }
            if (
                this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].beneficiary_id_state !==
                    'success' &&
                this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].checked_beneficiary_details !==
                    true
            ) {
                beneficiary_array[0].beneficiary_id_state = 'error'
                this.props.updateNewTransferCreationBeneficiary(
                    beneficiary_array
                )
                return_flag = false
            }
            if (
                this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0]
                    .purpose_of_payment_detail_state !== 'success'
            ) {
                beneficiary_array[0].purpose_of_payment_detail_state = 'error'
                this.props.updateNewTransferCreationBeneficiary(
                    beneficiary_array
                )
                return_flag = false
            }
            if (
                this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].purpose_of_payment_detail === 1
            )
                if (
                    this.props.transfers_dashboard.new_transfer_data
                        .multiple_beneficiary_id[0]
                        .purpose_of_payment_other_state !== 'success'
                ) {
                    beneficiary_array[0].purpose_of_payment_other_state =
                        'error'
                    this.props.updateNewTransferCreationBeneficiary(
                        beneficiary_array
                    )
                    return_flag = false
                }
        }

        return return_flag
    }
    secondsToTime = secs => {
        let hours = Math.floor(secs / (60 * 60))

        let divisor_for_minutes = secs % (60 * 60)
        let minutes = Math.floor(divisor_for_minutes / 60)

        let divisor_for_seconds = divisor_for_minutes % 60
        let seconds = Math.ceil(divisor_for_seconds)

        let obj = {
            h: hours,
            m: minutes,
            s: seconds,
        }
        return obj
    }
    startTimer = () => {
        if (this.props.transfers_dashboard.current_step === 2) {
            this.go = true
        }

        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000)
        }
    }
    stopTimer = () => {
        this.go = false
        this.setState({
            seconds: 0,
            time: 0,
        })
        clearInterval(this.timer)
        this.timer = null
    }
    countDown = () => {
        let seconds = this.state.seconds - 1
        if (this.go) {
            this.setState({
                time: this.secondsToTime(seconds),
                seconds: seconds,
            })

            if (seconds === 0) {
                this.state.fetchNewRate()
                this.setState({
                    seconds: 30,
                })
            }
        }
    }
    switch_to_step1() {
        this.setState({
            current_step: 1,
        })
        this.props.change_current_step(1)
    }

    switch_to_next_change_current_step() {
        if (this.state.current_step === 1) {
            if (this.isValidated(this.state.current_step)) {
                this.setState({
                    current_step: 2,
                })
                this.props.change_current_step(2)
            }
        } else if (this.state.current_step === 2) {
            this.setState({
                current_step: 3,
            })
            this.props.change_current_step(3)
        }
    }

    switch_to_previous_change_current_step() {
        if (this.state.current_step === 2) {
            this.setState({
                current_step: 1,
            })
            this.props.change_current_step(1)
        } else if (this.state.current_step === 3) {
            this.setState({
                current_step: 2,
            })
            this.props.change_current_step(2)
        }
    }

    renderMapComponent() {
        return (
            <VectorMap
                map={'world_mill'}
                backgroundColor="transparent"
                zoomOnScroll={false}
                containerStyle={{
                    width: '100%',
                    height: '420px',
                }}
                containerClassName="map"
                regionStyle={{
                    initial: {
                        fill: '#e4e4e4',
                        'fill-opacity': 0.9,
                        stroke: 'none',
                        'stroke-width': 0,
                        'stroke-opacity': 0,
                    },
                }}
                series={{
                    regions: [
                        {
                            values: this.props.transfers_dashboard.map_data,
                            scale: ['#26c6da', '#4caf50'],
                            normalizeFunction: 'polynomial',
                        },
                    ],
                }}
            />
        )
    }

    render_economic_information = () => {}

    handle_economic_info_modal_close = () => {
        this.setState({
            economic_info_modal_open: false,
        })
    }

    handle_economic_info_modal_open = info_choice => {
        let economic_info_modal_title = ''
        switch (info_choice) {
            case 'economic_info':
                economic_info_modal_title = 'Economic Data Releases'
                break
            case 'economic_holidays':
                economic_info_modal_title = 'Economic Holidays'
                break
            case 'fx_charts':
                economic_info_modal_title = 'FX Charts'
                break
        }
        this.setState({
            economic_info_modal_open: true,
            economic_info_modal_title,
            economic_info_choice: info_choice,
        })
    }

    get_economic_info_modal = () => {
        switch (this.state.economic_info_choice) {
            case 'economic_holidays':
                return <EconomicHoliday />
        }
    }
    onClickSecondStep = () => {
        this.state.secondStepFunction()
    }
    handle_new_transfer = () => {
        this.props.clearNewTransfer()
        this.switch_to_step1()
    }
    render() {
        const { classes } = this.props
        let branding = ''
        try {
            switch (this.props.app_state.current_team.brand_app_name) {
                case 'Forex WorldWide Money Transfer Service':
                    branding = 'Forex WorldWide Money Transfer Service'
                    break
                default:
                    branding = this.props.app_state.current_team.brand_app_name
                    break
            }
        } catch (err) {
            console.log(err)
            branding = 'Forex WorldWide Money Transfer Service'
        }

        let transfer_step
        switch (this.state.current_step) {
            case 1:
                transfer_step = (
                    <DashboardTransferStep1
                        prefill={this.props.prefill}
                        on_back_click={() =>
                            this.switch_to_previous_change_current_step()
                        }
                        on_proceed_click={() =>
                            this.switch_to_next_change_current_step()
                        }
                    />
                )
                break
            case 2:
                transfer_step = (
                    <DashboardTransferStep2
                        prefill={this.props.prefill}
                        on_back_click={() =>
                            this.switch_to_previous_change_current_step()
                        }
                        on_proceed_click={() =>
                            this.switch_to_next_change_current_step()
                        }
                        switch_to_step1={() => this.switch_to_step1()}
                        startTimer={() => this.startTimer()}
                        gp={this.go}
                        stopTimer={() => this.stopTimer()}
                        setCallable={callable =>
                            this.setState({ secondStepFunction: callable })
                        }
                        setRateState={callable =>
                            this.setState({ fetchNewRate: callable })
                        }
                    />
                )
                break
            case 3:
                transfer_step = (
                    <DashboardTransferStep3
                        prefill={this.props.prefill}
                        switch_to_step1={() => this.switch_to_step1()}
                    />
                )
                break
            default:
                transfer_step = <DashboardTransferStep1 />
                break
        }

        return (
            <React.Fragment>
                <GridItem xs={12}>
                    <Card style={{ height: '90%' }}>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} md={6}>
                                    {transfer_step}
                                </GridItem>
                                <GridItem xs={12} md={6}>
                                    {this.renderMapComponent()}
                                </GridItem>

                                <GridContainer>
                                    {(() => {
                                        switch (this.state.current_step) {
                                            case 1:
                                                return (
                                                    <React.Fragment>
                                                        <GridItem xs={6} />
                                                        <GridItem
                                                            xs={3}
                                                            className={
                                                                classes.paddingZero
                                                            }
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        />

                                                        <GridItem
                                                            xs={3}
                                                            className={
                                                                classes.paddingZero
                                                            }
                                                        >
                                                            <Button
                                                                style={{
                                                                    width:
                                                                        '80%',
                                                                }}
                                                                color="purple"
                                                                onClick={() =>
                                                                    this.switch_to_next_change_current_step()
                                                                }
                                                            >
                                                                Proceed
                                                            </Button>
                                                        </GridItem>
                                                    </React.Fragment>
                                                )
                                            case 2:
                                                return (
                                                    <React.Fragment>
                                                        <GridItem xs={6} />
                                                        <GridItem
                                                            xs={3}
                                                            className={
                                                                classes.paddingZero
                                                            }
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        >
                                                            <Button
                                                                style={{
                                                                    width:
                                                                        '80%',
                                                                }}
                                                                color="white"
                                                                onClick={() =>
                                                                    this.switch_to_previous_change_current_step()
                                                                }
                                                            >
                                                                Modify Request
                                                            </Button>
                                                        </GridItem>

                                                        <GridItem
                                                            xs={3}
                                                            className={
                                                                classes.paddingZero
                                                            }
                                                        >
                                                            <Button
                                                                style={{
                                                                    width:
                                                                        '80%',
                                                                }}
                                                                color="purple"
                                                                onClick={
                                                                    this
                                                                        .onClickSecondStep
                                                                }
                                                            >
                                                                Proceed (
                                                                {
                                                                    this.state
                                                                        .seconds
                                                                }{' '}
                                                                sec)
                                                            </Button>
                                                        </GridItem>
                                                    </React.Fragment>
                                                )
                                            case 3:
                                                return (
                                                    <React.Fragment>
                                                        <GridItem xs={6} />
                                                        <GridItem
                                                            xs={3}
                                                            className={
                                                                classes.paddingZero
                                                            }
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        />

                                                        <GridItem
                                                            xs={3}
                                                            className={
                                                                classes.paddingZero
                                                            }
                                                        >
                                                            <Button
                                                                style={{
                                                                    width:
                                                                        '80%',
                                                                }}
                                                                color="purple"
                                                                onClick={
                                                                    this
                                                                        .handle_new_transfer
                                                                }
                                                            >
                                                                New Transfer
                                                            </Button>
                                                        </GridItem>
                                                    </React.Fragment>
                                                )
                                            default:
                                                transfer_step = (
                                                    <DashboardTransferStep1 />
                                                )
                                                break
                                        }
                                    })()}
                                </GridContainer>
                            </GridContainer>
                            <br />
                        </CardBody>
                        {this.render_economic_information()}
                    </Card>
                </GridItem>
                <GridItem />
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal,
                    }}
                    open={this.state.economic_info_modal_open}
                    transition={Transition}
                    keepMounted
                    onClose={() => this.handleClose('modal')}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description"
                >
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <h4 className={classes.modalTitle}>
                            {this.state.economic_info_modal_title}
                        </h4>
                    </DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}
                    >
                        {this.get_economic_info_modal()}
                    </DialogContent>
                    <DialogActions
                        className={
                            classes.modalFooter +
                            ' ' +
                            classes.modalFooterCenter
                        }
                    >
                        <Button
                            onClick={() =>
                                this.handle_economic_info_modal_close()
                            }
                            color="success"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
        transfers_dashboard: state.transfers_dashboard,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        change_current_step: step => {
            dispatch(change_current_step(step))
        },
        clearNewTransfer: () => {
            dispatch(clearNewTransfer())
        },
        updateNewTransferCreation: (key, value) => {
            dispatch(updateNewTransferCreation(key, value))
        },
        updateNewTransferCreationBeneficiary: array => {
            dispatch(updateNewTransferCreationBeneficiary(array))
        },
    }
}

const DashboardTransferContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(DashboardTransfer))

export default DashboardTransferContainer
