import { connect } from 'react-redux'
import TransfersList from './TransfersList'
import {
    fetchTransferList,
    selectTransferUi,
} from '../../../redux/actions/transfers'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers: state.transfers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchTransferList: () => {
            dispatch(fetchTransferList())
        },
        selectTransferUi: data => {
            dispatch(selectTransferUi(data))
        },
    }
}

const TransfersListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TransfersList)

export default TransfersListContainer
