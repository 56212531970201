import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import React, { Fragment } from 'react'
import CustomInput from '../../CustomInput/CustomInput'
import GridContainer from '../../Grid/GridContainer'
import GridItem from '../../Grid/GridItem'

const BankDetails = ({
    classes,
    bank_address_country,
    swift_code,
    country_list_prio,
    iban,
    account_number,
    bsb_code,
    aba_routing_number,
    sort_code,
    transit_code,
    bank_code,
    show_swift,
    show_iban,
    show_bsb,
    show_account_number,
    show_aba,
    show_sort,
    show_transit,
    show_bank,

    edit_mode,
    handleChange,
    handleSelectChange,
    handleSubmit,
}) => {
    return (
        <Fragment>
            <Typography variant="h5" style={{ fontWeight: 700 }}>
                Bank Details
            </Typography>
            <div style={{ padding: 20, marginBottom: 24 }}>
                <form onSubmit={handleSubmit}>
                    <br />
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                            >
                                <InputLabel
                                    htmlFor="simple-select"
                                    className={classes.selectLabel}
                                >
                                    Country
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu,
                                    }}
                                    classes={{
                                        select: classes.select,
                                    }}
                                    value={bank_address_country || '0'}
                                    onChange={handleSelectChange}
                                    inputProps={{
                                        disabled: !edit_mode,
                                        name: 'bank_address_country',
                                        id: 'bank_address_country',
                                    }}
                                >
                                    <MenuItem
                                        key="0x0"
                                        value="0"
                                        disabled
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        Bank Country
                                    </MenuItem>

                                    {country_list_prio
                                        .sort(
                                            (a, b) =>
                                                a.list_priority <
                                                b.list_priority
                                        )
                                        .map(item => {
                                            return (
                                                <MenuItem
                                                    key={item.id}
                                                    classes={{
                                                        root:
                                                            classes.selectMenuItem,
                                                        selected:
                                                            classes.selectMenuItemSelected,
                                                    }}
                                                    value={item.id}
                                                >
                                                    {item.full_name}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                        </GridItem>
                    </GridContainer>

                    <br />
                    <hr />

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            {show_swift && (
                                <CustomInput
                                    labelText="Swift code"
                                    id="swift_code"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        disabled: !edit_mode,
                                        value: swift_code || '',
                                        onChange: event => {
                                            handleChange(event)
                                        },
                                    }}
                                />
                            )}
                            {show_iban && (
                                <CustomInput
                                    labelText="IBAN"
                                    id="iban"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        disabled: !edit_mode,
                                        value: iban || '',
                                        onChange: event => {
                                            handleChange(event)
                                        },
                                    }}
                                />
                            )}
                            {show_bsb && (
                                <CustomInput
                                    labelText="BSB code"
                                    id="bsb_code"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        disabled: !edit_mode,
                                        value: bsb_code || '',
                                        onChange: event => {
                                            handleChange(event)
                                        },
                                    }}
                                />
                            )}
                            {show_account_number && (
                                <CustomInput
                                    labelText="Account number"
                                    id="account_number"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        disabled: !edit_mode,
                                        value: account_number || '',
                                        onChange: event => {
                                            handleChange(event)
                                        },
                                    }}
                                />
                            )}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            {show_aba && (
                                <CustomInput
                                    labelText="ABA routing number"
                                    id="aba_routing_number"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        disabled: !edit_mode,
                                        value: aba_routing_number || '',
                                        onChange: event => {
                                            handleChange(event)
                                        },
                                    }}
                                />
                            )}
                            {show_sort && (
                                <CustomInput
                                    labelText="Sort code"
                                    id="sort_code"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        disabled: !edit_mode,
                                        value: sort_code || '',
                                        onChange: event => {
                                            handleChange(event)
                                        },
                                    }}
                                />
                            )}
                            {show_transit && (
                                <CustomInput
                                    labelText="Transit Code &amp; Institution No"
                                    id="transit_code"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        disabled: !edit_mode,
                                        value: transit_code || '',
                                        onChange: event => {
                                            handleChange(event)
                                        },
                                    }}
                                />
                            )}
                            {show_bank && (
                                <CustomInput
                                    labelText="Bank Code"
                                    id="bank_code"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        disabled: !edit_mode,
                                        value: bank_code || '',
                                        onChange: event => {
                                            handleChange(event)
                                        },
                                    }}
                                />
                            )}
                        </GridItem>
                    </GridContainer>
                </form>
            </div>
        </Fragment>
    )
}

export default BankDetails
