import React, { Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import hoverCardStyle from '../../assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx'
import {
    successColor,
    tooltip,
    cardTitle,
} from '../../assets/jss/material-dashboard-pro-react.jsx'
import { Slide } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from 'components/CustomButtons/Button.jsx'

const styles = {
    ...hoverCardStyle,
    tooltip,
    cardTitle: {
        ...cardTitle,
        marginTop: '0px',
        marginBottom: '3px',
    },
    modalFooter: {
        padding: '15px',
        textAlign: 'right',
        paddingTop: '0',
        margin: '0',
    },
    modalFooterCenter: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    legerText: {
        fontSize: 'smaller',
        fontWeight: '500',
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
    },
    cardProductTitle: {
        ...cardTitle,
        marginTop: '0px',
        marginBottom: '3px',
        textAlign: 'center',
    },
    cardCategory: {
        color: '#999999',
        fontSize: '14px',
        paddingTop: '10px',
        marginBottom: '0',
        marginTop: '0',
        margin: '0',
    },
    cardProductDesciprion: {
        textAlign: 'center',
        color: '#999999',
    },
    stats: {
        color: '#999999',
        fontSize: '12px',
        lineHeight: '22px',
        display: 'inline-flex',
        '& svg': {
            position: 'relative',
            top: '4px',
            width: '16px',
            height: '16px',
            marginRight: '3px',
        },
        '& .fab,& .fas,& .far,& .fal,& .material-icons': {
            position: 'relative',
            top: '4px',
            fontSize: '16px',
            marginRight: '3px',
        },
    },
    productStats: {
        paddingTop: '7px',
        paddingBottom: '7px',
        margin: '0',
    },
    successText: {
        color: successColor,
    },
    upArrowCardCategory: {
        width: 14,
        height: 14,
    },
    underChartIcons: {
        width: '17px',
        height: '17px',
    },
    cardBody: {
        padding: '5%',
    },
    price: {
        color: 'inherit',
        '& h4': {
            marginBottom: '0px',
            marginTop: '0px',
        },
    },
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />
})

export const CustomDialogue = withStyles(styles)(
    ({ classes, modalState, toggleModal, children, title, onSubmit }) => {
        return (
            <Fragment>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal,
                    }}
                    open={modalState}
                    transition={Transition}
                    keepMounted
                    onClose={() => toggleModal(false)}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description"
                >
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <h4 className={classes.modalTitle}>{title}</h4>
                    </DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}
                    />
                    <div className={classes.cardBody}>{children}</div>
                    <DialogActions
                        className={
                            classes.modalFooter +
                            ' ' +
                            classes.modalFooterCenter
                        }
                    >
                        <Button
                            onClick={() => {
                                onSubmit()
                                toggleModal(false)
                            }}
                            className={classes.primaryB}
                        >
                            Create Record
                        </Button>
                        <Button
                            className={classes.primaryC}
                            onClick={() => toggleModal(false)}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
)
