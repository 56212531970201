import { connect } from 'react-redux'
import IdentificationNew from './IdentificationNew'
import {
    createNewIdentificationRecord,
    updateNewIdentificationRecordCreation,
    startNewIdentificationRecordCreation,
    //   selectStaffUi
} from '../../redux/actions/identification'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        identification: state.identification,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createNewIdentificationRecord: data => {
            // console.log(data)
            dispatch(createNewIdentificationRecord(data))
        },
        updateNewIdentificationRecordCreation: (key, value) => {
            // console.log(data)
            dispatch(updateNewIdentificationRecordCreation(key, value))
        },
        startNewIdentificationRecordCreation: () => {
            dispatch(startNewIdentificationRecordCreation())
        },
        // selectStaffUi: (data) => {
        //   dispatch(selectStaffUi(data))
        // },
    }
}

const IdentificationNewContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IdentificationNew)

export default IdentificationNewContainer
