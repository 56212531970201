import React from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'

// core components
import Button from 'components/CustomButtons/Button.jsx'

import defaultImage from 'assets/img/image_placeholder.jpg'
import defaultAvatar from 'assets/img/placeholder.jpg'
import defaultPdf from 'assets/img/preview_pdf.jpg'
import { s3Upload } from 'libs/awsLib.js'

import Dropzone from 'react-dropzone'

class IdentificationUpload extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            file: null,
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
        }
        this.handleImageChange = this.handleImageChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
    }

    handleImageChange = async e => {
        e.preventDefault()
        let reader = new FileReader()
        let file = e.target.files[0]
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            })
        }
        this.props.updateNewIdentificationRecordCreation('file', file)
        reader.readAsDataURL(file)

        try {
            const attachment = file ? await s3Upload(file) : null
            console.log(attachment)
            this.props.updateNewIdentificationRecordCreation(
                'file_id',
                attachment
            )
        } catch (e) {
            alert(e)
        }
    }

    handleSubmit(e) {
        console.log(e)
        e.preventDefault()
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    }

    handleClick() {
        this.refs.fileInput.click()
    }

    handleRemove() {
        this.setState({
            file: null,
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
        })
        this.props.updateNewIdentificationRecordCreation('file', null)
        this.props.updateNewIdentificationRecordCreation('file_id', '')
        this.refs.fileInput.value = null
    }

    onDrop = async e => {
        console.log(e)
        let reader = new FileReader()
        let file = e[0]
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            })
        }
        this.props.updateNewIdentificationRecordCreation('file', file)
        reader.readAsDataURL(file)

        try {
            const attachment = file ? await s3Upload(file) : null

            console.log(attachment)
            this.setState({ file_id: attachment })

            this.props.updateNewIdentificationRecordCreation(
                'file_id',
                attachment
            )
            // eslint-disable-next-line react/prop-types
            //this.props.updateFunction(attachment, this.props.index);
        } catch (e) {
            alert(e)
        }

        // return;
        // acceptedFiles.forEach((file) => {
        //   const reader = new FileReader()

        //   reader.onabort = () => console.log('file reading was aborted')
        //   reader.onerror = () => console.log('file reading has failed')
        //   reader.onload = () => {
        //   // Do whatever you want with the file contents
        //     const binaryStr = reader.result
        //     console.log(binaryStr)
        //   }
        //   reader.readAsArrayBuffer(file)
        // })
    }

    render() {
        var {
            avatar,
            addButtonProps,
            changeButtonProps,
            removeButtonProps,
        } = this.props
        var preview_src = this.state.imagePreviewUrl
        if (this.state.file) {
            if (this.state.file.type == 'application/pdf') {
                preview_src = defaultPdf
            }
        }

        // console.log(this.state.file)
        return (
            <div className="fileinput text-center">
                <input
                    type="file"
                    onChange={this.handleImageChange}
                    // eslint-disable-next-line react/no-string-refs
                    ref="fileInput"
                />
                <div className={'thumbnail' + (avatar ? ' img-circle' : '')}>
                    <img src={preview_src} alt="..." />
                </div>

                <section className="container">
                    <div className="dropzone">
                        <Dropzone onDrop={this.onDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <section
                                    style={{
                                        width: '100%',
                                        backgroundColor: '#e6e6e6',
                                        border: '1px dotted #999999',
                                        padding: 10,
                                        textAlign: 'center',
                                    }}
                                >
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p
                                            style={{
                                                color: '#444',
                                                minHeight: 60,
                                            }}
                                        >
                                            Drop image or PDF file HERE
                                        </p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                </section>

                <p>
                    {(!this.props.identification
                        .new_identification_record_data ||
                        !this.props.identification
                            .new_identification_record_data.file_id) && (
                        <span>No file has been loaded</span>
                    )}
                </p>
                <p>
                    {this.props.identification.new_identification_record_data &&
                        this.props.identification.new_identification_record_data
                            .file_id && <span>File has now loaded</span>}
                </p>
                <div>
                    {this.state.file === null ? (
                        <Button
                            {...addButtonProps}
                            onClick={() => this.handleClick()}
                        >
                            {avatar ? 'Add Photo' : 'Select image'}
                        </Button>
                    ) : (
                        <span>
                            <Button
                                {...changeButtonProps}
                                onClick={() => this.handleClick()}
                            >
                                Change
                            </Button>
                            {avatar ? <br /> : null}
                            <Button
                                {...removeButtonProps}
                                onClick={() => this.handleRemove()}
                            >
                                <i className="fas fa-times" /> Remove
                            </Button>
                        </span>
                    )}
                </div>
            </div>
        )
    }
}

IdentificationUpload.propTypes = {
    avatar: PropTypes.bool,
    addButtonProps: PropTypes.object,
    changeButtonProps: PropTypes.object,
    removeButtonProps: PropTypes.object,
}

export default IdentificationUpload
