import React from 'react'
import PropTypes from 'prop-types'
import { API } from 'aws-amplify'

// import { connect } from "react-redux";

import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'

import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import Button from '../../CustomButtons/Button'

import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react'
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
// var moment = require('moment');

const styles = {
    ...switchStyles,
    ...selectStyles,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px',
    },
    registerButton: {
        float: 'right',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}

class TransferDetailPayoutCommunication extends React.Component {
    constructor(props) {
        super(props)
        // console.log(props);
        this.state = {
            remittance_advice_email_sending: false,
            remittance_advice_email_sent: false,
            remittance_advice_email_sending_bene: false,
            remittance_advice_email_sent_bene: false,
        }
    }

    // componentDidMount() {
    // }

    handle_send_payout_advice = async event => {
        // event.preventDefault();
        this.setState({
            remittance_advice_email_sending: true,
        })
        API.post('email', `/client/payout-advice`, {
            body: {
                id: this.props.id,
                rate: this.props.rate,
                amount_from: this.props.amount_from,
                amount_to: this.props.amount_to,
                currency_from: this.props.currency_from,
                currency_to: this.props.currency_to,
                rate: this.props.rate,
                settlement_date: this.props.settlement_date,
                beneficiary_id: this.props.beneficiary_id,
                language_id: this.props.language_id,
                payouts_list: this.props.payouts_list,
                transfer_id: this.props.transfer_id,
                // amount_from: this.props.amount_from,
                // eba_account_name: this.props.eba_account_name,
                // remitting_bank_id: this.props.remitting_bank_id,
                // remittance_reference: this.props.remittance_reference
            },
        })
            .then(response => {
                console.log(response)
                this.setState({
                    remittance_advice_email_sent: true,
                    remittance_advice_email_sending: false,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    //A function to send out payout advice to beneficiary email address
    handle_send_payout_advice_bene = async event => {
        // event.preventDefault();
        this.setState({
            remittance_advice_email_sending_bene: true,
        })
        console.log(this.props.beneficiary_id)
        API.post('email', `/beneficiaries/payout-advice`, {
            body: {
                id: this.props.id,
                rate: this.props.rate,
                amount_to: this.props.amount_to,
                currency_to: this.props.currency_to,
                beneficiary_id: this.props.beneficiary_id,
                language_id: this.props.language_id,
                payouts_list: this.props.payouts_list,
                transfer_id: this.props.transfer_id,
                // amount_from: this.props.amount_from,
                // eba_account_name: this.props.eba_account_name,
                // remitting_bank_id: this.props.remitting_bank_id,
                // remittance_reference: this.props.remittance_reference
            },
        })
            .then(response => {
                console.log(response)
                this.setState({
                    remittance_advice_email_sent_bene: true,
                    remittance_advice_email_sending_bene: false,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        const { classes } = this.props

        // console.log( {
        //   id: typeof(this.props.id),
        //   rate: typeof(this.props.rate),
        //   amount_to: typeof(this.props.amount_to),
        //   currency_to: typeof(this.props.currency_to),
        //   eba_account_name: typeof(this.props.eba_account_name),
        //   remitting_bank_id: typeof(this.props.remitting_bank_id),
        //   remittance_reference: typeof(this.props.remittance_reference),
        //   beneficiary_id: typeof(this.props.beneficiary_id),
        //   //language_id: typeof(this.props.language_id),
        //   payouts_list: typeof(this.props.payouts_list)
        // });
        // console.log(this.state);
        // console.log(this.props);
        return (
            <div>
                <GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Button
                                fullwidth="true"
                                color="info"
                                disabled={!this.props.enabled}
                                size="sm"
                                onClick={this.handle_send_payout_advice}
                                className={classes.updateProfileButton}
                            >
                                <span style={{ height: 20 }}> Email </span>
                            </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            &nbsp;&nbsp; Send payout advice to{' '}
                            {this.props.clients_email
                                ? this.props.clients_email
                                : 'client'}
                            {this.state.remittance_advice_email_sending && (
                                <span style={{ height: 10 }}>
                                    (email sending)
                                </span>
                            )}
                            {this.state.remittance_advice_email_sent && (
                                <span style={{ height: 10 }}>(email sent)</span>
                            )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <br />
                            <Button
                                fullwidth="true"
                                color="info"
                                disabled={this.props.transfer_status !== 8} //disable if transfer status is not completed
                                size="sm"
                                onClick={() => {
                                    var win = window.open(
                                        `/transfers/receipt/transfer/${
                                            this.props.transfer_id
                                        }`,
                                        '_blank'
                                    )
                                    win.focus()
                                }}
                                className={classes.updateProfileButton}
                            >
                                <span style={{ height: 20 }}>
                                    {' '}
                                    View / PDF Payout Receipt{' '}
                                </span>
                            </Button>
                        </GridItem>
                    </GridContainer>
                    {/* #856 Comment this button out and wait until #858 to finish
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                fullwidth="true"
                color="info"
                disabled={!this.props.enabled}
                size="sm"
                onClick={this.handle_send_payout_advice_bene}
                className={classes.updateProfileButton}
              >
                <span style={{ height: 20 }}>Email </span>
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              &nbsp;&nbsp; Send payout advice to beneficiary
              {this.state.remittance_advice_email_sending_bene && (
                <span style={{ height: 10 }}>(email sending)</span>
              )}
              {this.state.remittance_advice_email_sent_bene && (
                <span style={{ height: 10 }}>(email sent)</span>
              )}
            </GridItem>
          </GridContainer>
          */}
                </GridContainer>
            </div>
        )
    }
}

TransferDetailPayoutCommunication.propTypes = {
    classes: PropTypes.object.isRequired,
    enabled: PropTypes.bool,
    id: PropTypes.number,
    rate: PropTypes.number,
    amount_to: PropTypes.number,
    currency_to: PropTypes.string,
    // eba_account_name: PropTypes.undefined,
    // remitting_bank_id: PropTypes.undefined,
    // remittance_reference: PropTypes.undefined,
    clients_email: PropTypes.string,
    language_id: PropTypes.number,
    beneficiary_id: PropTypes.object,
    payouts_list: PropTypes.array,
}

// Prepare for redux

// const mapStateToProps = state => {
//   return {
//     app_state: state.app_state
//   };
// };

// const TransferDetailPayoutCommunicationContainer = connect(
//   mapStateToProps
//   //mapDispatchToProps
// )(TransferDetailPayoutCommunicationContainer);

// export default withRouter(withStyles(styles)(TransferDetailPayoutCommunicationContainer));

export default withRouter(withStyles(styles)(TransferDetailPayoutCommunication))
