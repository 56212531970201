import React from 'react'
import {connect} from 'react-redux'
import {createNewRegistration, updateNewRegistrationCreation} from '../../../redux/actions/registration'
import withStyles from '@material-ui/core/styles/withStyles'
import {API} from 'aws-amplify'
import {fetchIdentificationList} from '../../../redux/actions/identification'
import {Button, Card, Col, DatePicker, Divider, Form, Input, Modal, Row, Select, Table, message} from "antd";
import {CloseOutlined, CloudUploadOutlined, DeleteOutlined, EyeOutlined, SaveOutlined} from "@ant-design/icons";
import {DataStatus} from "../../ComponentInfo/Status";
import ListTable from "../../ListTable/ListTable";
import dayjs from "dayjs";
import FileUploader from "../../FileUploader/FileUploader";
import TypographyCountry from "../../CurrencySelect/TypographyCountry";
import {RegistrationContext} from "../../../libs/RegistrationProvider";
import {AppTemplates} from "../../../AppTemplates";

const style = {
    expandedBackground: {
        backgroundColor: 'rgb(240, 242, 245)',
        padding: '15px',
        borderRadius: '5px'
    },
}

class Step4Identification extends React.Component {

    documentForm = React.createRef();

    static contextType = RegistrationContext;

    constructor(props) {
        super(props);
        this.state = {
            formValues: {document_category_id: 1, uploads: []},
            document_category_list: [],
            existingIdentifications: [],
            componentInfos: {
                identifications: {status: DataStatus.Loaded, callback: () => this.fetchIdentificationList()},
                documentCategories: {status: DataStatus.Loaded, callback: () => this.fetchDocumentCategories()},
                saveDocumentInfo: {status: DataStatus.Loaded},
            },
            isModalOpen: false
        }
    }

    componentDidMount() {
        this.fetchIdentificationList();
        this.fetchDocumentCategories();
    }

    fetchDocumentCategories() {
        API.get('identification', '/get/categories', {})
            .then(response => this.setState({document_category_list: response}))
            .catch(error => console.log(error));
    }

    fetchIdentificationList = () => {
        this.setState(prev => ({
            ...prev,
            componentInfos: {...prev.componentInfos, identifications: {...prev.componentInfos.identifications, status: DataStatus.Loading}}
        }));
        API.get('identification', '/get/list_not_deleted', {})
            .then(response => {
                this.setState(prev => ({
                    ...prev,
                    componentInfos: {...prev.componentInfos, identifications: {...prev.componentInfos.identifications, status: DataStatus.Loaded}}
                }));
                this.setState({existingIdentifications: response});
            })
            .catch(error => {
                this.setState(prev => ({
                    ...prev,
                    componentInfos: {...prev.componentInfos, identifications: {...prev.componentInfos.identifications, status: DataStatus.ErrorState}}
                }));
            });
    }

    buildColumns = () => {
        return [
            {
                key: 'id',
                title: 'Individual',
                dataIndex: 'client_nickname',
                sorter: (a,b) => a.id - b.id,
                defaultSortOrder: 'descend',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'client_nickname',
                })
            },
            {
                key: 'document_category_id',
                title: 'Category',
                dataIndex: 'document_category_id',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'document_category_id',
                    render: (text, record) => {
                        const category = this.state.document_category_list.find(item => item.id === record.document_category_id);
                        return category && category.nickname || '-';
                    }
                })
            },
            // {
            //     key: 'nickname',
            //     title: 'Notes',
            //     dataIndex: 'nickname',
            //     ...this.props.getColumnSearchProps({
            //         dataIndex: 'nickname',
            //     })
            // },
            {
                key: 'document_number',
                title: 'Document Number',
                dataIndex: 'document_number',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'document_number',
                })
            },
            {
                key: 'document_expiry',
                title: 'Document Expiry',
                dataIndex: 'document_expiry',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'document_expiry',
                    filterInputType: "DATE",
                    render: (text) => text != null ? dayjs(text).format('D MMMM YYYY') : '-'
                })
            },
            {
                key: 'id_status',
                title: '',
                dataIndex: 'id_status',
                render: (text, record) => <div style={{ display: 'flex', gap: '5px' }}>
                    <Button type={'primary'} size={'small'} icon={<EyeOutlined/>} onClick={async () => {
                        const load = message.loading("Opening document preview. Please wait..");
                        const jsonAttachments = record.attachments;
                        let list = [];
                        if (jsonAttachments != null) {
                            const attachments = JSON.parse(jsonAttachments).ids || [];
                            for (const item of attachments) {
                                await API.get("identification", `/get/document/${item}`, {}).then(response => {
                                    if (response != null) {
                                        list.push(response);
                                    }
                                });
                            }
                            load();
                        }
                        Modal.confirm({
                            title: 'Document Preview',
                            width: '900px',
                            okText: 'Close',
                            maskClosable: true,
                            cancelButtonProps: {hidden: true},
                            content: <>
                                <Divider/>
                                {list.length > 0 ? <Row gutter={[16, 16]}>

                                    {list.map(i => <>
                                        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                                            <FileUploader previewOnly={true}
                                                          thumbType={'picture-card'}
                                                          uploaderType={'PDF'}
                                                          fetchFileList={() => {
                                                              const file = {
                                                                  name: i.file_ref,
                                                                  uid: i.file_ref,
                                                                  status: 'done'
                                                              }
                                                              return Promise.resolve([file]);
                                                          }}></FileUploader>
                                        </Col>
                                    </>)}

                                </Row> : 'No documents uploaded'}
                            </>
                        })
                    }}></Button>
                    <Button type={'primary'}
                            size={'small'}
                            danger={true}
                            icon={<DeleteOutlined/>}
                            onClick={() => {
                                Modal.confirm({
                                    title: 'Delete this identification ?',
                                    content: 'Are you sure you want to delete this identification ?',
                                    onOk: () => {
                                        API.get("identification", `/remove/document/${record.id}`, {})
                                            .then(() => {
                                                this.fetchIdentificationList();
                                            }).catch(err => {
                                            console.log(err);
                                        })
                                    }
                                })
                            }}></Button>
                </div>
            }
        ]
    }

    handleSubmit = (requestBody) => {
        requestBody.clientId = this.props.app_state.current_client.id;
        const loading = message.loading("Saving identification. Please wait..", 0);
        this.setState(prev => ({
            ...prev,
            componentInfos: {...prev.componentInfos, saveDocumentInfo: {...prev.componentInfos.saveDocumentInfo, status: DataStatus.Loading}}
        }));
        requestBody.nickname = this.state.document_category_list.find(i => i.id === requestBody.document_category_id).nickname;
        API.post("identification", "/create/save-id-documents", {body: requestBody}).then(() => {
            this.setState(prev => ({
                ...prev,
                isModalOpen: false,
                componentInfos: {...prev.componentInfos, saveDocumentInfo: {...prev.componentInfos.saveDocumentInfo, status: DataStatus.Loaded}}
            }));
            this.fetchIdentificationList();
            this.documentForm.current.resetFields();
        }).catch((error) => {
            this.setState(prev => ({
                ...prev,
                componentInfos: {...prev.componentInfos, saveDocumentInfo: {...prev.componentInfos.saveDocumentInfo, status: DataStatus.ErrorState}}
            }));
        }).finally(() => loading());
    }

    isValidated = () => {
        this.context.setRegistration(prev => ({...prev, identifications: this.state.existingIdentifications}));
        return true;
    }

    preValidate = async () => {
        const isValidated = this.state.existingIdentifications.length > 0;
        if (!isValidated) {
            message.warn("There must be at least one form of identification");
        }
        return isValidated;
    }

    sendState() {
        return this.state;
    }

    render() {
        return <>
            <div className={this.props.classes.expandedBackground}>
                <Card title={
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span>Identification Documents</span>
                        <Button type={'primary'}
                                onClick={() => this.setState(prev => ({...prev, isModalOpen: true}))}
                                icon={<CloudUploadOutlined/>}>Upload Documents</Button>
                    </div>
                }>

                    <h5>Identification Documents Documents</h5>
                    <p>
                        Please upload at least two identification records. <br/>
                        One must be a photo ID which shows your full name (eg passport or drivers licence)<br/>
                        The second must be a document which shows your full name and address (eg utility bill or bank
                        statement)<br/>
                        You cannot use the same document for both - eg drivers licence can be either Photo ID OR Address
                    </p>

                    <Table size={'small'}
                           tableLayout="fixed" // Set the table layout to fixed
                           pagination={{
                               position: ['bottomCenter'], // Set pagination position to bottom center
                           }}
                           columns={this.buildColumns()}
                           dataSource={this.state.existingIdentifications}
                           loading={this.state.componentInfos.identifications.status === DataStatus.Loading}></Table>

                </Card>
                <Modal title={'Document List'}
                       width={'1000px'}
                       visible={this.state.isModalOpen}
                       style={{top: 10, zIndex: 1033}}
                       bodyStyle={{maxHeight: '80vh', overflowY: 'scroll', padding: '10px'}}
                       onOk={() => {
                           this.documentForm.current.submit();
                       }}
                       onCancel={() => {
                           this.documentForm.current.resetFields();
                           this.setState(prev => ({...prev, isModalOpen: false}));
                       }}
                       okText={'Save Documents'}
                       okButtonProps={{
                           loading: this.state.componentInfos.saveDocumentInfo.status === DataStatus.Loading,
                           icon: <SaveOutlined />
                       }}
                       cancelButtonProps={{
                           icon: <CloseOutlined />
                       }}>

                    <Form layout={'vertical'} initialValues={{ document_category_id: 1, uploads: []}}
                          name={'document-form'}
                          form={this.documentForm.current}
                          onValuesChange={(c, a) => this.setState(prev => ({...prev, formValues: a}))}
                          ref={this.documentForm}
                          onFinish={this.handleSubmit}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                {
                                    this.context.registration.account.account_type > 1 && <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name={'client_contact_id'} label={'Beneficial Owner'}>
                                        <Select showSearch={true}
                                                allowClear={true}
                                                placeholder={`${this.props.app_state.current_client.first_name} ${this.props.app_state.current_client.last_name}`}
                                                options={this.context.registration.beneficialOwners.map(i => ({
                                                    label: `${i.firstName} ${i.lastName}`,
                                                    value: i.id
                                                }))}></Select>
                                    </Form.Item>
                                }
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                        <Form.Item name={'document_category_id'}
                                                   rules={[{required: true, message: 'Document Category is required'}]}
                                                   label={'Document Category'}>
                                            <Select options={this.state.document_category_list.map(item => ({
                                                ...item,
                                                label: item.nickname,
                                                value: item.id
                                            }))}></Select>
                                        </Form.Item>
                                        <Form.Item name={'document_issue_country'}
                                                   rules={[{required: true, message: 'Country is required'}]}
                                                   label={'Issue Country'}>
                                            <Select showSearch={true}
                                                    allowClear={true}
                                                    placeholder={'-- SELECT --'}
                                                    optionFilterProp={'alt'}
                                                    optionLabelProp={'label'} options={this.context.state.countries.map(i => ({
                                                ...i,
                                                value: i.id,
                                                id: i.id,
                                                alt: [i.iso_alpha_3, i.full_name],
                                                label: <TypographyCountry iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>
                                            }))}></Select>
                                        </Form.Item>
                                        {
                                            this.state.formValues && (
                                                this.state.formValues.document_category_id === 2 || 
                                                this.state.formValues.document_category_id === 3 
                                            ) && <Form.Item name={'document_detail'}
                                                          rules={[{required: true, message: 'required'}]}
                                                          label={<>
                                                          {this.state.formValues ? (this.state.formValues.document_category_id === 3 ? 'Card Color' : 'Issue State') : '-'}
                                                          </>}>
                                                <Input />
                                            </Form.Item>
                                        }
                                        {
                                            this.state.formValues && (
                                                this.state.formValues.document_category_id === 1 ||
                                                this.state.formValues.document_category_id === 2 ||
                                                this.state.formValues.document_category_id === 3 ||
                                                this.state.formValues.document_category_id === 4
                                            ) && <Form.Item name={'document_number'}
                                                   rules={[{required: true, message: 'Document Number is required'}]}
                                                   label={'Document Number'}>
                                                <Input />
                                            </Form.Item>
                                        }

                                            {this.state.formValues
                                                    && (
                                                        this.state.formValues.document_category_id === 1 ||
                                                        this.state.formValues.document_category_id === 2 ||
                                                        this.state.formValues.document_category_id === 3 ||
                                                        this.state.formValues.document_category_id === 4
                                                    ) && <Form.Item name={'document_expiry'}
                                                       normalize={(e) => e && dayjs(e).startOf('day').format('YYYY-MM-DD')}
                                                       rules={[{required: true, message: 'Document Expiry is required'}]}
                                                       getValueProps={(value) =>
                                                           value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}
                                                       }
                                                       label={'Document Expiry'}>
                                                <DatePicker format={this.state.formValues.document_category_id === 3 ? 'MM/YYYY': 'DD/MM/YYYY'} />
                                            </Form.Item>}
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                        {AppTemplates.documentHelpTemplate(this.state.formValues.document_category_id)}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Form.Item name={'documents'} getValueFromEvent={(e) => e.map(i => i.filename)}>
                                    <FileUploader uploaderType={'DRAGDROP'}></FileUploader>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        </>
    }
}

Step4Identification = withStyles(style)(Step4Identification)

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        registration: state.registration,
        language: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createNewRegistration: data => {
            dispatch(createNewRegistration(data))
        },
        updateNewRegistrationCreation: (key, value) => {
            dispatch(updateNewRegistrationCreation(key, value))
        },
        fetchIdentificationList: () => {
            dispatch(fetchIdentificationList())
        }
    }
}

const Step4IdentificationContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Step4Identification)

export default ListTable(Step4IdentificationContainer)
