import React from 'react'
import {connect} from 'react-redux'

// import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'
// react plugin for creating charts
import ChartistGraph from 'react-chartist'
// react plugin for creating vector maps
import {VectorMap} from 'react-jvectormap'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// import Tooltip from "@material-ui/core/Tooltip";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from '@material-ui/icons/Warning'
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from '@material-ui/icons/AccessTime'
// import Refresh from "@material-ui/icons/Refresh";
// import Edit from "@material-ui/icons/Edit";
// import Place from "@material-ui/icons/Place";
// import ArtTrack from "@material-ui/icons/ArtTrack";
// import Language from "@material-ui/icons/Language";
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
// import Table from "components/Table/Table.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Danger from 'components/Typography/Danger.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import Tabs from 'components/CustomTabs/CustomTabs.jsx'
import {dailySalesChart,} from 'variables/charts'
import dashboardStyle from './DashboardPageStyle'
import EconomicCalender from '../../components/EconomicInformation/EconomicCalender'
import Cloud from '@material-ui/core/SvgIcon/SvgIcon'
import FxCharts from '../../components/EconomicInformation/FxCharts'
import FxRates from '../../components/EconomicInformation/FxRates'
import EconomicHoliday from '../../components/EconomicInformation/EconomicHoliday'
import CustomTransferList from '../../components/CustomTransferList/CustomTransferList'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DashboardMultiPay from '../../components/DashboardMultiPay/DashboardMultipay'
import {fetchCurrenciesNotDeletedList} from "../../redux/reducers/currencies";
import {BeneficiaryAPI} from "../../components/Beneficiaries/BeneficiaryUtil";

const axios = require('axios')
var Chartist = require('chartist')

const min_transfer_amount = 0

class MultipayPage extends React.Component {
    state = {
        value: 0,
        currencies_list_priority: [],
        map_data: {},
        beneficiaries_list: [],
        beneficiary_id: '',
        transfer_heading: 'Current Transfers',
    }

    componentDidMount() {
        let url = `https://apilayer.net/api/timeframe?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
        axios
            .get(url, {
                params: {
                    currencies: 'USD',
                    source: 'AUD',
                    start_date: '2019-02-01',
                    end_date: '2019-03-01',
                },
            })
            .then(response => {
                // console.log(response);
                let data = []
                for (var item in response.data.quotes) {
                    data.push(response.data.quotes[item]['AUDUSD'])
                }
                let data2 = []
                data2.push(data)
                this.setState({audusd: {series: data2}})
            })
            .catch(error => {
                console.log(error)
            })

        url = `https://apilayer.net/api/timeframe?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
        axios
            .get(url, {
                params: {
                    currencies: 'EUR',
                    source: 'AUD',
                    start_date: '2019-02-01',
                    end_date: '2019-03-01',
                },
            })
            .then(response => {
                // console.log(response);
                let data = []
                for (var item in response.data.quotes) {
                    data.push(response.data.quotes[item]['AUDEUR'])
                }
                let data2 = []
                data2.push(data)
                this.setState({audeur: {series: data2}})
            })
            .catch(error => {
                console.log(error)
            })

        url = `https://apilayer.net/api/timeframe?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
        axios
            .get(url, {
                params: {
                    currencies: 'NZD',
                    source: 'AUD',
                    start_date: '2019-02-01',
                    end_date: '2019-03-01',
                },
            })
            .then(response => {
                // console.log(response);
                let data = []
                for (var item in response.data.quotes) {
                    data.push(response.data.quotes[item]['AUDNZD'])
                }
                let data2 = []
                data2.push(data)
                this.setState({audnzd: {series: data2}})
            })
            .catch(error => {
                console.log(error)
            })

        url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
        axios
            .get(url, {
                params: {
                    currencies: 'USD,EUR,NZD',
                    source: 'AUD',
                    format: '1',
                },
            })
            .then(response => {
                // console.log(response);
                this.setState({
                    current_audusd: response.data.quotes['AUDUSD'],
                    current_audeur: response.data.quotes['AUDEUR'],
                    current_audnzd: response.data.quotes['AUDNZD'],
                })
            })
            .catch(error => {
                console.log(error)
            })

        this.props.fetchCurrencies().then(res => this.setState({currencies_list_priority: res}));

        BeneficiaryAPI.fetchBeneficiaries().then(response => {
            this.setState({
                beneficiaries_list: response,
            })
        })
            .catch(error => {
                console.log(error)
            })
    }

    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }

    // function that verifies if a value is larger than a number or not
    isLargerThanNumber(value, number) {
        if (value !== '' && value >= number) {
            return true
        }
        return false
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({[stateName + '_state']: 'success'})
                } else {
                    this.setState({[stateName + '_state']: 'error'})
                }
                break
            case 'isLargerThanNumber':
                if (
                    this.isLargerThanNumber(
                        event.target.value,
                        stateNameEqualTo
                    )
                ) {
                    this.setState({[stateName + '_state']: 'success'})
                } else {
                    this.setState({[stateName + '_state']: 'error'})
                }
                break
            default:
                break
        }
        this.setState({[stateName]: event.target.value})
        // Calculate "to amount"
        if (stateName === 'from_amount') {
            this.setState({last_user_type_amount_name: 'from_amount'})
            this.handleAmount('to_amount')
        }
        // Calculate "from amount"
        if (stateName === 'to_amount') {
            this.setState({last_user_type_amount_name: 'to_amount'})
            this.handleAmount('from_amount')
        }

        // if (stateName === "amount_to") {
        //   this.fetchNewRate()
        // }
    }

    handleAmount(amount_name) {
        let from_currency_Iso = ''
        let to_currency_Iso = ''

        this.state.currencies_list_priority.map((item, index) => {
            if (item.id == this.state.from_currency) {
                from_currency_Iso = item.iso_alpha_3
            }
            if (item.id == this.state.to_currency) {
                to_currency_Iso = item.iso_alpha_3
            }
        })

        let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
        axios
            .get(url, {
                params: {
                    currencies: to_currency_Iso,
                    source: from_currency_Iso,
                    format: '1',
                },
            })
            .then(response => {
                let key = from_currency_Iso + to_currency_Iso
                let rate_string = response.data.quotes[key]

                let rate = Number.parseFloat(rate_string)

                let rate_adjust =
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client
                            .default_rate_division
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_entity
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_team
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_staff
                    )
                let adjusted_rate = rate - rate * rate_adjust
                if (this.state.from_currency == this.state.to_currency) {
                    adjusted_rate = rate
                }

                if (
                    this.state.from_currency_state === 'success' &&
                    this.state.to_currency_state === 'success'
                ) {
                    // handle to_amount
                    if (
                        amount_name === 'to_amount' &&
                        this.state.from_amount_state === 'success'
                    ) {
                        let amount_from = Number.parseFloat(
                            this.state.from_amount
                        )
                        let amount_to = amount_from * adjusted_rate

                        this.setState({['to_amount']: amount_to.toFixed(2)})
                        if (amount_to >= min_transfer_amount) {
                            this.setState({['to_amount_state']: 'success'})
                        } else {
                            this.setState({['to_amount_state']: 'error'})
                        }
                    }

                    // handle from_amount
                    if (
                        amount_name === 'from_amount' &&
                        this.state.to_amount_state === 'success'
                    ) {
                        let amount_to = Number.parseFloat(this.state.to_amount)
                        let amount_from = amount_to / adjusted_rate

                        this.setState({
                            ['from_amount']: amount_from.toFixed(2),
                        })
                        if (amount_from >= min_transfer_amount) {
                            this.setState({['from_amount_state']: 'success'})
                        } else {
                            this.setState({['from_amount_state']: 'error'})
                        }
                    }
                }

                this.setState({['adjusted_rate']: adjusted_rate})
                // Save currency name into state for passing query strings
                this.setState({['from_currency_name']: from_currency_Iso})
                this.setState({['to_currency_name']: to_currency_Iso})
            })
            .catch(error => {
                console.log(error)
            })
    }

    handleChange = (event, value) => {
        this.setState({value})
    }
    handleChangeIndex = index => {
        this.setState({value: index})
    }

    handleCustomReactSelectChange = (
        name,
        previous_from_currency,
        previous_to_currency
    ) => value => {
        var option_value
        if (value === null) {
            option_value = null
        } else {
            option_value = value.value
        }
        this.setState(
            {
                [name]: option_value,
            },
            () => {
                switch (this.state.last_user_type_amount_name) {
                    case 'from_amount':
                        this.handleAmount('to_amount')
                        break
                    case 'to_amount':
                        this.handleAmount('from_amount')
                        break
                    default:
                        this.handleAmount()
                }
            }
        )
        this.setState({[name + '_state']: 'success'})
        if (name === 'from_currency' || name === 'to_currency') {
            this.updateMapData(
                name,
                previous_from_currency,
                previous_to_currency,
                value
            )
        }
    }

    updateMapData(
        currency_name,
        previous_from_currency,
        previous_to_currency,
        currency_object
    ) {
        var map_values = this.state.map_data
        const from_currency_map_value = 1
        const to_currency_map_value = 1
        // update the properties for visulization
        if (currency_name === 'from_currency') {
            // delele previous currency property in map_values
            if (previous_from_currency !== previous_to_currency) {
                this.deleleCurrency(
                    map_values,
                    previous_from_currency,
                    previous_to_currency
                )
            }
            // add new currency property in map_values
            this.addCurrency(
                map_values,
                currency_object.value,
                from_currency_map_value
            )
        }
        if (currency_name === 'to_currency') {
            // delele previous currency property in map_values
            if (previous_to_currency !== previous_from_currency) {
                this.deleleCurrency(
                    map_values,
                    previous_to_currency,
                    previous_from_currency
                )
            }
            // add new currency property in map_values
            this.addCurrency(
                map_values,
                currency_object.value,
                to_currency_map_value
            )
        }
        this.setState({map_data: map_values})
    }

    deleleCurrency(map_values, currency_deleted, the_other_currency) {
        switch (currency_deleted) {
            case 1: // AUD
                delete map_values.AU // Australia
                break
            case 5: // USD
                delete map_values.US // United States
                break
            case 8: // GBP
                // if the other currency is not Euro, delete GB
                if (the_other_currency !== 9) {
                    delete map_values.GB // United Kingdom
                }
                break
            case 9: // Eur. For Europe: italy, spain, france, portugal, germany
                // if the other currency is not GB, delete GB
                if (the_other_currency !== 8) {
                    delete map_values.GB // United Kingdom
                }
                delete map_values.IT // Italy
                delete map_values.ES // Spain
                delete map_values.FR // France
                delete map_values.PT // Portugal
                delete map_values.DE // Germany
                break
            case 6: // ATS
                delete map_values.AT // 	Austria
                break
            default:
        }
    }

    addCurrency(map_values, currency, currency_map_value) {
        switch (currency) {
            case 1:
                map_values.AU = currency_map_value
                break
            case 5:
                map_values.US = currency_map_value
                break
            case 8:
                map_values.GB = currency_map_value
                break
            case 9:
                map_values.GB = currency_map_value
                map_values.IT = currency_map_value
                map_values.ES = currency_map_value
                map_values.FR = currency_map_value
                map_values.PT = currency_map_value
                map_values.DE = currency_map_value
                break
            case 6:
                map_values.AT = currency_map_value
                break
            default:
        }
    }

    render_account_warning() {
        switch (this.props.app_state.current_client.account_status) {
            case 1:
                return (
                    <React.Fragment>
                        <Danger>
                            <Warning/> Account is not yet activated.
                        </Danger>
                        <br/>
                        <p>
                            This account has not yet been activated. Please{' '}
                            <a href="/user-profile?display=todo">click here </a>
                            for more information.
                        </p>
                    </React.Fragment>
                )
            default:
                return null
        }
    }

    renderMapComponent() {
        return (
            <VectorMap
                map={'world_mill'}
                backgroundColor="transparent"
                zoomOnScroll={false}
                containerStyle={{
                    width: '100%',
                    height: '420px',
                }}
                containerClassName="map"
                regionStyle={{
                    initial: {
                        fill: '#e4e4e4',
                        'fill-opacity': 0.9,
                        stroke: 'none',
                        'stroke-width': 0,
                        'stroke-opacity': 0,
                    },
                }}
                series={{
                    regions: [
                        {
                            values: this.state.map_data,
                            scale: ['#ffffff', '#26c6da'],
                            normalizeFunction: 'polynomial',
                        },
                    ],
                }}
            />
        )
    }

    getTabsInfo() {
        var ec_info_economicnews = this.props.app_state.current_client
            .ec_info_economicnews
        var tabsContent = []
        // var tmp = {};
        if (ec_info_economicnews == 1) {
            tabsContent.push({
                tabName: 'Economic Calendar',
                tabIcon: Cloud,
                tabContent: <EconomicCalender/>,
            })
        }

        var ec_info_dashboard_charts = this.props.app_state.current_client
            .ec_info_dashboard_charts
        if (ec_info_dashboard_charts == 1) {
            tabsContent.push({
                tabName: 'FX Charts',
                tabIcon: Cloud,
                tabContent: <FxCharts/>,
            })
        }

        var economicholidays = this.props.app_state.current_client
            .ec_info_economicholidays

        if (economicholidays == 1) {
            tabsContent.push({
                tabName: 'Economic Holidays',
                tabIcon: Cloud,
                tabContent: <EconomicHoliday/>,
            })
        }

        var ec_info_rates = this.props.app_state.current_client.ec_info_rates
        if (ec_info_rates == 1) {
            tabsContent.push({
                tabName: 'Fx Rates',
                tabIcon: Cloud,
                tabContent: <FxRates/>,
            })
        }

        return <Tabs title="" headerColor="info" tabs={tabsContent}/>
    }

    toggleChecked(checked) {
        if (checked) {
            this.setState({
                checked: false,
                toggleTransfer: '8',
                transfer_heading: 'Past Transfers',
            })
            return false
        }
        this.setState({
            checked: true,
            toggleTransfer: '123456',
            transfer_heading: 'Current Transfers',
        })
        return true
    }

    render() {
        const {classes} = this.props

        if (!this.props.app_state.current_client) {
            return null
        }
        if (this.props.app_state.current_client.account_status == 1) {
            this.props.history.push('/pending-registration')
        }
        if (this.props.app_state.current_client.account_status == 5) {
            this.props.history.push('/registration')
        }

        if (this.props.app_state.current_client.account_status == 6) {
            this.props.history.push('/migrate')
        }

        let main_component = null
        main_component = (
            <DashboardMultiPay prefill={this.props.match.params.prefill}/>
        )

        return (
            <React.Fragment>
                <div>
                    <GridContainer alignItems="stretch">
                        {main_component}
                        {/* {main_component_temp} */}
                    </GridContainer>

                    {this.props.app_state.current_client
                        .ec_info_dashboard_charts == 0 && (
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card chart className={classes.cardHover}>
                                    <CardHeader
                                        color="info"
                                        className={classes.cardHeaderHover}
                                    >
                                        <ChartistGraph
                                            className="ct-chart-white-colors"
                                            data={this.state.audeur}
                                            type="Line"
                                            options={{
                                                lineSmooth: Chartist.Interpolation.cardinal(
                                                    {
                                                        tension: 0,
                                                    }
                                                ),
                                                low: 0.61,
                                                high: 0.64,
                                                chartPadding: {
                                                    top: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                },
                                            }}
                                            listener={dailySalesChart.animation}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <div
                                            className={classes.cardHoverUnder}
                                        />
                                        <h4 className={classes.cardTitle}>
                                            AUD/EUR 30 days
                                        </h4>
                                        Current price{' '}
                                        {Number.parseFloat(
                                            this.state.current_audeur
                                        ).toFixed(5)}{' '}
                                        (
                                        {Number.parseFloat(
                                            Number(this.state.current_audeur) -
                                            Number(
                                                this.state.current_audeur
                                            ) *
                                            Number(
                                                this.props.app_state
                                                    .current_client
                                                    .default_rate_entity
                                            )
                                        ).toFixed(5)}
                                        ){/* </p> */}
                                    </CardBody>
                                    <CardFooter chart>
                                        <div className={classes.stats}>
                                            <AccessTime/> updated 30 seconds
                                            ago
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card chart className={classes.cardHover}>
                                    <CardHeader
                                        color="info"
                                        className={classes.cardHeaderHover}
                                    >
                                        <ChartistGraph
                                            className="ct-chart-white-colors"
                                            data={this.state.audusd}
                                            type="Line"
                                            options={{
                                                lineSmooth: Chartist.Interpolation.cardinal(
                                                    {
                                                        tension: 0,
                                                    }
                                                ),
                                                low: 0.7,
                                                high: 0.73,
                                                chartPadding: {
                                                    top: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                },
                                            }}
                                            listener={dailySalesChart.animation}
                                        />
                                        }
                                    </CardHeader>
                                    <CardBody>
                                        <div
                                            className={classes.cardHoverUnder}
                                        />
                                        <h4 className={classes.cardTitle}>
                                            AUD/USD 30 days
                                        </h4>
                                        <p className={classes.cardCategory}>
                                            Current price{' '}
                                            {Number.parseFloat(
                                                this.state.current_audusd
                                            ).toFixed(5)}
                                        </p>
                                    </CardBody>
                                    <CardFooter chart>
                                        <div className={classes.stats}>
                                            <AccessTime/> updated 30 seconds
                                            ago
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card chart className={classes.cardHover}>
                                    <CardHeader
                                        color="info"
                                        className={classes.cardHeaderHover}
                                    >
                                        <ChartistGraph
                                            className="ct-chart-white-colors"
                                            data={this.state.audnzd}
                                            type="Line"
                                            options={{
                                                lineSmooth: Chartist.Interpolation.cardinal(
                                                    {
                                                        tension: 0,
                                                    }
                                                ),
                                                low: 1.03,
                                                high: 1.06,
                                                chartPadding: {
                                                    top: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                },
                                            }}
                                            listener={dailySalesChart.animation}
                                        />
                                        }
                                    </CardHeader>
                                    <CardBody>
                                        <div
                                            className={classes.cardHoverUnder}
                                        />
                                        <h4 className={classes.cardTitle}>
                                            AUD/NZD 30 days
                                        </h4>
                                        Current price{' '}
                                        {Number.parseFloat(
                                            this.state.current_audnzd
                                        ).toFixed(5)}{' '}
                                        (
                                        {Number.parseFloat(
                                            Number(this.state.current_audnzd) -
                                            Number(
                                                this.state.current_audnzd
                                            ) *
                                            Number(
                                                this.props.app_state
                                                    .current_client
                                                    .default_rate_entity
                                            )
                                        ).toFixed(5)}
                                        ){/* </p> */}
                                    </CardBody>
                                    <CardFooter chart>
                                        <div className={classes.stats}>
                                            <AccessTime/> updated 30 seconds
                                            ago
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    )}

                    {this.props.app_state.current_client.ec_info_dashboard ==
                        1 && (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card chart className={classes.cardHover}>
                                        <CardBody>{this.getTabsInfo()}</CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        )}

                    <GridContainer alignItems="stretch">
                        <GridItem xs={12} md={12}>
                            <Card
                                style={{
                                    display: 'flex',
                                    height: '90%',
                                    flexDirection: 'column',
                                }}
                            >
                                <CardHeader color="info" stats icon>
                                    <CardIcon color="info">
                                        <i className="fas fa-exchange-alt"/>
                                    </CardIcon>

                                    <h3 className={classes.cardTitle}>
                                        {this.state.transfer_heading}
                                        {/*<FormattedMessage*/}
                                        {/*  id="dashboard.submittedTransfers"*/}
                                        {/*  defaultMessage={*/}
                                        {/*    `Transfers List`}*/}
                                        {/*/>*/}
                                    </h3>

                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.checked}
                                                    onChange={event =>
                                                        this.toggleChecked(
                                                            event.target.checked
                                                        )
                                                    }
                                                    value="checkedA"
                                                    classes={{
                                                        switchBase:
                                                        classes.switchBase,
                                                        checked:
                                                        classes.switchChecked,
                                                        thumb:
                                                        classes.switchIcon,
                                                        track:
                                                        classes.switchBar,
                                                    }}
                                                />
                                            }
                                            classes={{
                                                label: classes.label,
                                            }}
                                            label={
                                                'Switch current/past transfers'
                                            }
                                        />
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <CustomTransferList
                                                status={
                                                    this.state.toggleTransfer
                                                }
                                                // start_date={"01-09-2019"}
                                                // end_date={"02-09-2019"}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter stats/>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </React.Fragment>
        )
    }
}

// DashboardPage.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withRouter(withStyles(dashboardStyle)(MultipayPage));

// import { onLoginSuccess } from "../../redux/actions";
// import Link from onLoginSuccess../components/Link'
// import DashboardPage from "./DashboardPage";

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
        currencies: state.currencies
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCurrencies: () => dispatch(fetchCurrenciesNotDeletedList())
    }
}

const MultipayPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(dashboardStyle)(MultipayPage)))

export default MultipayPageContainer
