import React from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'

// core components
// import Button from 'components/CustomButtons/Button.jsx'

import defaultImage from 'assets/img/image_placeholder_2.jpg'
//import defaultAvatar from "assets/img/placeholder.jpg";
import defaultPdf from 'assets/img/preview_pdf.jpg'

// AWS components
import { Storage } from 'aws-amplify'

import { s3Upload } from 'libs/awsLib.js'
//import { SSL_OP_NO_COMPRESSION } from "constants";

import SweetAlert from 'react-bootstrap-sweetalert'
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'

//import PdfViewer from "../Utilities/PdfViewer";
import Dropzone from 'react-dropzone'


import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

import { Button, Space } from 'antd';

const DocumentUploadStyle = {
    ...sweetAlertStyle,
}

class DocumentUpload extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            file: '',
            file_id: '',
            file_url: '',
            isLoaded: false,
            // eslint-disable-next-line react/prop-types
            imagePreviewUrl: this.props.placeholder
                ? // eslint-disable-next-line react/prop-types
                  this.props.placeholder
                : !this.props.noPlaceholder
                    ? null
                    : defaultImage,
        }
        this.handleImageChange = this.handleImageChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
    }

    handleClick() {
        // eslint-disable-next-line react/no-string-refs
        this.refs.fileInput.click()
    }

    handleImageChange = async e => {
        e.preventDefault()
        let reader = new FileReader()
        let file = e.target.files[0]
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            })
        }
        // this.props.updateNewIdentificationRecordCreation("file", file)
        reader.readAsDataURL(file)

        try {
            const attachment = file ? await s3Upload(file) : null

            console.log(attachment)
            this.setState({
                file_id: attachment,
                isLoaded: true,
            })

            // this.props.updateNewIdentificationRecordCreation("file_id", attachment)
            // eslint-disable-next-line react/prop-types
            this.props.updateFunction(attachment, this.props.index)
        } catch (e) {
            alert(e)
        }
    }

    onDrop = async e => {
        console.log(e)
        let reader = new FileReader()
        let file = e[0]
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            })
        }
        // this.props.updateNewIdentificationRecordCreation("file", file)
        reader.readAsDataURL(file)

        try {
            const attachment = file ? await s3Upload(file) : null

            console.log(attachment)
            this.setState({
                file_id: attachment,
                isLoaded: true,
            })

            // this.props.updateNewIdentificationRecordCreation("file_id", attachment)
            // eslint-disable-next-line react/prop-types
            this.props.updateFunction(attachment, this.props.index)
        } catch (e) {
            alert(e)
        }

        // return;
        // acceptedFiles.forEach((file) => {
        //   const reader = new FileReader()

        //   reader.onabort = () => console.log('file reading was aborted')
        //   reader.onerror = () => console.log('file reading has failed')
        //   reader.onload = () => {
        //   // Do whatever you want with the file contents
        //     const binaryStr = reader.result
        //     console.log(binaryStr)
        //   }
        //   reader.readAsArrayBuffer(file)
        // })
    }

    handleRemove() {
        //Remove Avatar due to #740
        this.setState({
            file: '', //null, //don't set it to null, it will crash by cannot read type "null" - workaround #835
            // eslint-disable-next-line react/prop-types
            imagePreviewUrl: this.props.placeholder
                ? // eslint-disable-next-line react/prop-types
                  this.props.placeholder
                : defaultImage, //this.props.avatar ? defaultAvatar : defaultImage #740 changed
            file_id: '',
            file_url: '',
            isLoaded: false,
        })
        // this.props.updateNewIdentificationRecordCreation("file", null)
        // eslint-disable-next-line react/no-string-refs
        this.refs.fileInput.value = null
        // eslint-disable-next-line react/prop-types
        this.props.onRemove(this.props.index)
    }

    handleSubmit(e) {
        console.log(e)
        e.preventDefault()
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    }

    /*
   *  Function added because of #740 opens that image up - larger - in a modal window
   *
   *  Author: Tom
   */
    hideAlert() {
        this.setState({
            alert: null,
        })
    }
    //end of function

    /*
   *  Function added because of #740 opens that image up - larger - in a modal window
   *
   *  Author: Tom
   */
    async openModal() {
        //console.log("== Open modal!! ==");
        // sweetalert Source: http://djorg83.github.io/react-bootstrap-sweetalert/

        let file_id

        //file_id = this.props.registration.new_registration_data.multi_document[0].document;
        try {
            file_id = this.state.file_id
        } catch (error) {
            file_id = ''
        }

        let file_url = ''
        if (file_id !== '') {
            file_url = await Storage.get(file_id, { expires: 60 })
        }

        try {
            await this.setState({
                file_url: file_url,
                alert: (
                    <SweetAlert
                        style={{ display: 'block', marginTop: '-100px' }}
                        title={
                            <img
                                src={
                                    this.state.imagePreviewUrl //default set to imagePreviewUrl
                                }
                                width="90%"
                            />
                        }
                        confirmBtnText="Close"
                        onConfirm={() => this.hideAlert()}
                        confirmBtnCssClass={
                            // eslint-disable-next-line react/prop-types
                            this.props.classes.button +
                            '  ' +
                            this.props.classes.info
                        }
                    />
                ),
            })
        } catch (error) {
            console.log(error)
        }
    }
    //end of function

    /*
   *  Function added because of #816 opens that PDF up - larger - in a modal window later
   *
   *  Author: Tom
   */
    async openModalPDF() {
        console.log('== Open modal PDF!! ==')
        // sweetalert Source: http://djorg83.github.io/react-bootstrap-sweetalert/
        await this.hideAlert()

        let file_id

        //file_id = this.props.registration.new_registration_data.multi_document[0].document;
        try {
            file_id = this.state.file_id
        } catch (error) {
            file_id = ''
        }

        let file_url = ''
        if (file_id !== '') {
            file_url = await Storage.get(file_id, { expires: 60 })
        }

        this.setState({
            file_url: file_url,
            alert: null,
        })

        await window.open(
            this.state.file_id
                ? this.state.file_url
                : '../../assets/test/error_page.html' //testing error page
        )
        /*
      <PdfViewer 
        file = {
          this.state.file.type == "application/pdf"
          ? file_url //defaultPdf
          : this.state.imagePreviewUrl
        }
      />
    */
    }

    render() {
        var {
            //circle,
            addButtonProps,
            changeButtonProps,
            removeButtonProps,
        } = this.props
        var preview_src = this.state.imagePreviewUrl
        if (this.state.file) {
            // console.log(this.state.imagePreviewUrl);
            if (this.state.file.type == 'application/pdf') {
                // console.log(this.state.file);
                // console.log(this.state.imagePreviewUrl);
                preview_src = defaultPdf
            }
        }

        // console.log(this.state);
        // console.log(this.state.file)
        return (
            <React.Fragment>
                    <h6>Upload {this.props.description ? this.props.description : 
                        this.props.back ? 'Back Image' : 'Front Image'}</h6>
                {!this.state.file_id && (
                    <section className="container">
                        <div className="dropzone">
                            <Dropzone onDrop={this.onDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <section
                                        style={{
                                            width: '100%',
                                            backgroundColor: '#e6e6e6',
                                            border: '1px dotted #999999',
                                            padding: 10,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p
                                                style={{
                                                    color: '#444',
                                                    minHeight: 60,
                                                }}
                                            >
                                                Drop image or PDF file HERE
                                            </p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                    </section>
                )}
                <div className="fileinput text-center">
                    <input
                        type="file"
                        onChange={this.handleImageChange}
                        // eslint-disable-next-line react/no-string-refs
                        ref="fileInput"
                    />
                    {/* remove Circle and Avatar due to #740 */}

                    {/*<p>{(!this.props.identification.new_identification_record_data || !this.props.identification.new_identification_record_data.file_id) && <span>No file has been loaded</span>}</p>*/}
                    {/*<p>{(this.props.identification.new_identification_record_data && this.props.identification.new_identification_record_data.file_id) && <span>File has now loaded</span>}</p>*/}
                    <div>
                        {this.state.file_id &&
                        this.state.file !== '' &&
                        this.state.file.type !== 'application/pdf' ? (
                            <React.Fragment>
                                <div
                                    style={{ maxHeight: 200, maxWidth: 400 }}
                                    //onClick={() => this.openModal()}
                                    className={'thumbnail'}
                                >
                                    <img
                                        src={preview_src}
                                        alt="..."
                                        //onClick={() => this.openModal()}
                                    />
                                </div>
                                <br />
                                <Button
                                    disabled={!this.state.isLoaded}
                                    color="primary"
                                    onClick={() => this.openModal()} //View Image
                                >
                                    View file
                                </Button>
                            </React.Fragment>
                        ) : (
                            //start solution of #816
                            <React.Fragment>
                                <div
                                    style={{ maxHeight: 600, maxWidth: 800 }}
                                    //onClick={() => this.openModal()}
                                    className={'thumbnail'}
                                >
                                    {preview_src && 
                                    <img
                                    src={preview_src}
                                    alt="..."
                                    //onClick={() => this.openModalPDF()}
                                    />
                                    }
                                </div>
                                <br />
                                {this.state.file !== '' && (
                                    <Button
                                        disabled={!this.state.isLoaded}
                                        color="primary"
                                        onClick={() => this.openModalPDF()} //View PDF
                                    >
                                        View file
                                    </Button>
                                )}
                            </React.Fragment>
                        )}
                        {this.state.file === '' ? (
                            <GridContainer justify="center">
                                <GridItem xs={12}>
                            <Button
                                {...addButtonProps}
                                onClick={() => this.handleClick()}
                            >
                                Upload {this.props.description ? this.props.description : 
                                    this.props.back ? 'Back Image' : 'Front Image'}
                            </Button>
                            </GridItem>
                            </GridContainer>
                        ) : (
                            <GridContainer justify="center">
                                <GridItem xs={12}>
            
                                {this.props.change && (
                                    <React.Fragment>
                                        <Button
                                            {...changeButtonProps}
                                            onClick={() => this.handleClick()}
                                        >
                                            Change
                                        </Button>
                                        <br />
                                    </React.Fragment>
                                )}
                                <Button
                                    {...removeButtonProps}
                                    onClick={() => this.handleRemove()}
                                >
                                    Remove
                                </Button>
                              </GridItem>
                            </GridContainer>
                        )}
                    </div>
                    {this.state.alert}
                </div>
            </React.Fragment>
        )
    }
}

DocumentUpload.propTypes = {
    avatar: PropTypes.bool,
    change: PropTypes.bool, // allows user to change image
    addButtonProps: PropTypes.object,
    changeButtonProps: PropTypes.object,
    removeButtonProps: PropTypes.object,
}

export default withStyles(DocumentUploadStyle)(DocumentUpload)
