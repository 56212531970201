import React from 'react'
import {updateNewRegistrationCreation} from '../../../redux/actions/registration'
import {connect} from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import {API} from 'aws-amplify'
import {RegistrationContext} from "../../../libs/RegistrationProvider";
import {CloudUploadOutlined, DeleteOutlined, EyeOutlined, ReloadOutlined} from "@ant-design/icons";
import {Button, Card, Col, Divider, Form, Input, message, Modal, Row, Table, Typography} from "antd";
import {DataStatus} from "../../ComponentInfo/Status";
import FileUploader from "../../FileUploader/FileUploader";

const styles = {
    expandedBackground: {
        backgroundColor: 'rgb(240, 242, 245)', padding: '15px', borderRadius: '5px'
    }, marginBottom: 0
}

class Step3_1AdditionalDocuments extends React.Component {

    documentForm = React.createRef();
    static contextType = RegistrationContext;

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isModalOpen: false,
            isDocumentModalOpen: false,
            componentInfos: {
                documentCompInfo: {status: DataStatus.Loaded, callback: () => this.buildTableData()},
                documentSaveInfo: {status: DataStatus.Loaded}
            }
        }
    }

    componentDidMount() {
        this.buildTableColumns();
        this.buildTableData();

    }

    sendState() {
        return this.state
    }

    buildTableColumns = () => {
        return [{
            key: 'description', title: 'Description', dataIndex: 'description'
        }, {
            key: 'recordCreated',
            title: '',
            dataIndex: 'recordCreated',
            render: (text, record) => <div style={{display: 'flex', gap: '5px'}}>
                <Button type={'primary'}
                        size={'small'}
                        icon={<EyeOutlined/>}
                        onClick={async () => {
                            Modal.confirm({
                                width: '900px',
                                okText: 'Close',
                                maskClosable: true,
                                cancelButtonProps: {hidden: true},
                                title: 'Document Preview',
                                content: <>
                                    <Divider/>
                                    {record.attachments.length > 0 ? <Row gutter={[16, 16]}>
                                        {record.attachments.map(i => <>
                                            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                                                <FileUploader key={i}
                                                              previewOnly={true}
                                                              thumbType={'picture-card'}
                                                              uploaderType={'PDF'}
                                                              fetchFileList={() => {
                                                                  const file = {
                                                                      name: i,
                                                                      uid: i,
                                                                      status: 'done'
                                                                  }
                                                                  return Promise.resolve([file]);
                                                              }}></FileUploader>
                                            </Col>
                                        </>)}

                                    </Row> : 'No documents uploaded'}
                                </>
                            });
                        }}></Button>
                <Button type={'primary'}
                        danger={true}
                        onClick={() => {
                            Modal.confirm({
                                title: 'Are you sure ?',
                                content: `Are you sure you want to delete document uploads #${record.id}`,
                                onOk: () => {
                                    this.removeClientDocument(record);
                                }
                            })
                        }}
                        size={'small'} icon={<DeleteOutlined/>}></Button>
            </div>
        }]
    }

    buildTableData = () => {
        this.setState(prev => ({
            ...prev,
            componentInfos: {
                ...prev.componentInfos,
                documentCompInfo: {...prev.componentInfos.documentCompInfo, status: DataStatus.Loading}
            }
        }));
        API.get("client-documents", "/", {})
            .then(response => {
                this.setState(prev => ({
                    ...prev,
                    data: response,
                    componentInfos: {
                        ...prev.componentInfos,
                        documentCompInfo: {...prev.componentInfos.documentCompInfo, status: DataStatus.Loaded}
                    }
                }));
            })
            .catch(err => {
                console.log(err);
                this.setState(prev => ({
                    ...prev,
                    data: [],
                    componentInfos: {
                        ...prev.componentInfos,
                        documentCompInfo: {...prev.componentInfos.documentCompInfo, status: DataStatus.ErrorState}
                    }
                }));
            });
    }

    removeClientDocument = (data) => {
        const loading = message.loading("Removing client document", 0);
        API.get("client-documents", `/delete/${data.id}`, {})
            .then(() => {
                message.success("Document removed");
                this.buildTableData();
            }).catch(err => {
            console.log(err)
        }).finally(() => loading());
    }

    onSaveDocumentBtnClick = (values) => {
        this.setState(prev => ({
            ...prev,
            componentInfos: {
                ...prev.componentInfos,
                documentSaveInfo: {...prev.componentInfos.documentSaveInfo, status: DataStatus.Loading}
            }
        }));
        API.post("client-documents", "/upload", {body: values}).then(response => {
            this.documentForm.current.resetFields();
            this.setState(prev => ({
                ...prev,
                isModalOpen: false,
                componentInfos: {
                    ...prev.componentInfos,
                    documentSaveInfo: {...prev.componentInfos.documentSaveInfo, status: DataStatus.Loaded}
                }
            }));
            this.buildTableData();
        }).catch(err => {
            console.log("Unable to upload documents");
            this.setState(prev => ({
                ...prev,
                componentInfos: {
                    ...prev.componentInfos,
                    documentSaveInfo: {...prev.componentInfos.documentSaveInfo, status: DataStatus.ErrorState}
                }
            }));
        })
    }

    isValidated() {
        this.context.setRegistration(prev => ({...prev, documents: this.state.data}));
        return true;
    }

    preValidate = async () => true;

    render() {
        return <div className={this.props.classes.expandedBackground}>
            <Card title={<div style={{display: 'flex', justifyContent: 'space-between'}}>
                <span>Company Documents</span>
                <div style={{display: 'flex', gap: '5px'}}>
                    <Button type={'primary'}
                            icon={<CloudUploadOutlined/>}
                            onClick={() => {
                                this.setState(prev => ({...prev, isModalOpen: true}));
                            }}>Upload Documents</Button>
                    <Button icon={<ReloadOutlined/>}
                            onClick={() => this.buildTableData()}>Refresh List</Button>
                </div>
            </div>}>
                <Typography.Paragraph strong={true} italic={true}>
                            As this is a business contact, please upload a copy of any ASIC extracts or Trust Deeds or other 
                            documents which may be relevant to the organisation.
                </Typography.Paragraph>

                <Table columns={this.buildTableColumns()}
                       dataSource={this.state.data}
                       loading={this.state.componentInfos.documentCompInfo.status === DataStatus.Loading}></Table>
            </Card>
            <Modal visible={this.state.isModalOpen}
                   title={'Upload Documents'}
                   onOk={() => {
                       this.documentForm.current.submit();
                   }}
                   okButtonProps={{
                       loading: this.state.componentInfos.documentSaveInfo.status === DataStatus.Loading
                   }}
                   onCancel={() => {
                       this.documentForm.current.resetFields();
                       this.setState(prev => ({...prev, isModalOpen: false}));
                   }}>
                <Form ref={this.documentForm}
                      onFinish={this.onSaveDocumentBtnClick}>
                    <Form.Item name="description" label={'Description'}>
                        <Input.TextArea/>
                    </Form.Item>
                    <Form.Item name={'uploads'} label={'Upload'} getValueFromEvent={(e) => e.map(i => i.filename)}>
                        <FileUploader uploaderType={'DRAGDROP'}></FileUploader>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    }
}

Step3_1AdditionalDocuments = withStyles(styles)(Step3_1AdditionalDocuments)
const mapStateToProps = state => {
    return {
        app_state: state.app_state, registration: state.registration, language: state.language,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updateNewRegistrationCreation: (key, value) => {
            dispatch(updateNewRegistrationCreation(key, value));
        },
    };
}

const Step3_1AdditionalDocumentsContainer = connect(mapStateToProps, mapDispatchToProps)(Step3_1AdditionalDocuments);
export default Step3_1AdditionalDocumentsContainer;