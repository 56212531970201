import React from 'react';
import {Button, Card, Col, Row} from "antd";
import withStyles from "@material-ui/core/styles/withStyles";


const styles = {
    marginLeft1: {
        marginLeft: '1em'
    }
}
const DashboardWidgetWrapper = ({ contentTitle, children, classes, buttons = [] }) => {

    const titleExpand = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <h4 style={{fontWeight: 800}}>
                    {contentTitle}
                </h4>
                {/* <span>
                    <strong>
                        {contentTitle}
                    </strong>
                </span> */}
                <div>
                    {
                        buttons.map((item, index) => {
                            return <Button 
                                    // size={"small"} 
                                        className={classes.marginLeft1} 
                                        style={{borderRadius: '5px'}}
                                        key={index} 
                                        type={item.type}
                                        danger={item.danger || false}
                                        onClick={item.action}>
                                        <strong>
                                            {item.label}
                                        </strong>
                                    </Button>
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
                <Card title={titleExpand()} style={{ borderRadius: '10px'}}>
                    {children}
                </Card>
            </Col>
        </Row>
    )
}

export default withStyles(styles)(DashboardWidgetWrapper);