import React from 'react'
import ReactTable from 'react-table'
import { matchSorter } from 'match-sorter'
import Flag from 'react-world-flags'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import withStyles from '@material-ui/core/styles/withStyles'

var axios = require('axios')
const moment = require('moment')

const countries = require('../../assets/data/countries.json')

const styles = theme => ({
    tab: {
        minWidth: 40,
    },
    avatar: {
        width: 20,
        height: 20,
        marginLeft: 6,
        backgroundColor: 'rgb(185, 185, 185)',
    },
    chip: {
        margin: theme.spacing.unit,
        '&:hover': {
            backgroundColor: 'rgba(3, 77, 104, 0.7)',
            color: 'white',
            fontWeight: 450,
        },
    },
    chipActive: {
        margin: theme.spacing.unit,
        backgroundColor: 'rgba(3, 77, 104, 0.7)',
        color: 'white',
        fontWeight: 450,
    },
})

class EconomicHoliday extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            posts: [],
            important_posts: [],

            selectedDate: moment().format('DD MMMM YYYY'),
            selectedCountry: 'ALL',
            snackbar_open: false,
        }
    }

    componentDidMount() {
        this.populate_data()
    }

    populate_data = () => {
        let selected_date = moment(this.state.selectedDate).dayOfYear()
        let posts = []

        let url = `https://api.forexworldwide.com/economic-holiday`
        axios
            .get(url)
            .then(res => {
                for (let item in res.data) {
                    let push_data = {
                        id: item,
                        timestamp: res.data[item].timestamp,
                        date: moment(res.data[item].timestamp).format(
                            'DD-MM-YYYY'
                        ),
                        country: res.data[item].country,
                        exchange: res.data[item].exchange,
                        holiday: res.data[item].holiday,
                    }
                    posts.push(push_data)
                }
            })
            .then(() => {
                this.setState({
                    rows: posts,
                    posts: posts,
                    loading: false,
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    buildTableData() {
        var post_length = this.state.posts.length
        if (post_length > 0) {
            const rows = this.state.posts.filter(row => {
                if (this.state.selectedCountry === 'ALL') {
                    return true
                } else if (this.state.selectedCountry === 'EUROPE') {
                    switch (row.country.toUpperCase()) {
                        case 'AUSTRIA':
                            return true
                        case 'BELGIUM':
                            return true
                        case 'CROATIA':
                            return true
                        case 'CYPRUS':
                            return true
                        case 'DENMARK':
                            return true
                        case 'ESTONIA':
                            return true
                        case 'FINLAND':
                            return true
                        case 'FRANCE':
                            return true
                        case 'IRELAND':
                            return true
                        case 'ITALY':
                            return true
                        case 'MALTA':
                            return true
                        case 'NETHERLANDS':
                            return true
                        case 'NORWAY':
                            return true
                        case 'POLAND':
                            return true
                        case 'UNITED KINGDOM':
                            return true
                        case 'SLOVAKIA':
                            return true
                        case 'SPAIN':
                            return true
                        case 'GERMANY':
                            return true
                    }
                }
                return row.country.toUpperCase() === this.state.selectedCountry
            })

            return rows.map((prop, key) => {
                return {
                    id: prop.id,
                    timestamp: prop.timestamp,
                    date: prop.date,
                    country: prop.country,
                    exchange: prop.exchange,
                    holiday: prop.holiday,
                }
            })
        }
    }

    handleCurrencyChange = currency => {
        this.setState({
            selectedCountry: currency.toUpperCase(),
        })
    }

    render() {
        const { classes } = this.props
        return (
            <React.Fragment>
                <div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection:
                                this.props.width !== 'xs' ? 'row' : 'column',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Chip
                            avatar={
                                <Avatar
                                    className={classes.avatar}
                                    src={require('../../assets/futuro-icons/25_travel_the_world.svg')}
                                />
                            }
                            label="ALL"
                            onClick={() => this.handleCurrencyChange('ALL')}
                            className={classes.chip}
                            s
                        />
                        <Chip
                            avatar={
                                <Avatar>
                                    <span
                                        className={`currency-flag currency-flag-aud`}
                                    />
                                </Avatar>
                            }
                            label="AUD"
                            onClick={() =>
                                this.handleCurrencyChange('AUSTRALIA')
                            }
                            className={classes.chip}
                        />
                        <Chip
                            avatar={
                                <Avatar>
                                    <span
                                        className={`currency-flag currency-flag-cad`}
                                    />
                                </Avatar>
                            }
                            label="CAD"
                            onClick={() => this.handleCurrencyChange('CANADA')}
                            className={classes.chip}
                        />
                        <Chip
                            avatar={
                                <Avatar>
                                    <span
                                        className={`currency-flag currency-flag-cny`}
                                    />
                                </Avatar>
                            }
                            label="CNY"
                            onClick={() => this.handleCurrencyChange('CHINA')}
                            className={classes.chip}
                        />
                        <Chip
                            avatar={
                                <Avatar>
                                    <span
                                        className={`currency-flag currency-flag-eur`}
                                    />
                                </Avatar>
                            }
                            label="EUR"
                            onClick={() => this.handleCurrencyChange('EUROPE')}
                            className={classes.chip}
                        />

                        <Chip
                            avatar={
                                <Avatar>
                                    <span
                                        className={`currency-flag currency-flag-jpy`}
                                    />
                                </Avatar>
                            }
                            label="JPY"
                            onClick={() => this.handleCurrencyChange('JAPAN')}
                            className={classes.chip}
                        />
                        <Chip
                            avatar={
                                <Avatar>
                                    <span
                                        className={`currency-flag currency-flag-nzd`}
                                    />
                                </Avatar>
                            }
                            label="NZD"
                            onClick={() =>
                                this.handleCurrencyChange('NEW ZEALAND')
                            }
                            className={classes.chip}
                        />
                        <Chip
                            avatar={
                                <Avatar>
                                    <span
                                        className={`currency-flag currency-flag-usd`}
                                    />
                                </Avatar>
                            }
                            label="USD"
                            onClick={() =>
                                this.handleCurrencyChange('UNITED STATES')
                            }
                            className={classes.chip}
                        />
                    </div>
                    <ReactTable
                        data={this.buildTableData()}
                        //filterable
                        sortable={false}
                        columns={[
                            {
                                Header: 'Date ',
                                accessor: 'date',
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                        keys: ['date'],
                                    }),
                                filterAll: true,
                                maxWidth: 150,
                            },
                            {
                                Header: 'Country',
                                accessor: 'country',
                                Cell: c => {
                                    let flag = ''

                                    for (var item in countries) {
                                        if (
                                            c.value.toUpperCase() ===
                                            countries[item].name.toUpperCase()
                                        ) {
                                            flag = countries[
                                                item
                                            ].alpha2.toLowerCase()
                                        }
                                    }

                                    return (
                                        <span>
                                            <Flag
                                                code={`${flag.toUpperCase()}`}
                                                height="16"
                                                width="25"
                                            />{' '}
                                            &nbsp; {c.value}
                                        </span>
                                    )
                                },
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                        keys: ['country'],
                                    }),
                                filterAll: true,
                                maxWidth: 200,
                            },
                            {
                                Header: 'Exchange',
                                accessor: 'exchange',
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                        keys: ['exchange'],
                                    }),
                                filterAll: true,
                                maxWidth: 500,
                            },
                            {
                                Header: 'Holiday',
                                accessor: 'holiday',
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                        keys: ['holiday'],
                                    }),
                                filterAll: true,
                                maxWidth: 250,
                            },
                        ]}
                        defaultPageSize={20}
                        pageSizeOptions={[3, 6]}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(EconomicHoliday)
