import React from 'react';
const TypographyCountry = (props) => {
    return <span className={`${props.className}`}>
            {/*<span className={`${props.className} currency-flag currency-flag-${(props.iso_alpha_3 || '').toLowerCase()}`}*/}
        {/*      style={{...props.styles, marginBottom: '-3px', marginRight: '3px' }} />*/}
        {/*<span>{props.children}</span>*/}

        <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '5px'
        }}>
            <img alt='currency'
                 src={`https://fwwportal-branding.s3.us-east-1.amazonaws.com/flags/${(props.iso_alpha_2 || '').toUpperCase()}.png`}
                 width="24px" height="16px"/>
        <span> {props.children}</span>
        </div>

    </span>

}

export default TypographyCountry;