import { connect } from 'react-redux'
import CreateNewTransferWizard from './CreateNewTransferWizard'
import {
    startNewTransferCreation,
    createNewTransfer,
} from '../../redux/actions/transfers_2'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers_2: state.transfers_2,
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startNewTransferCreation: () => {
            dispatch(startNewTransferCreation())
        },
        createNewTransfer: data => {
            dispatch(createNewTransfer(data))
        },
    }
}

const CreateNewTransferWizardContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateNewTransferWizard)

export default CreateNewTransferWizardContainer
