import { API } from 'aws-amplify'

export const TRANSFERS_DASHBOARD_MULTIPLE_UPDATE_NEW_TRANSFER_CREATION =
    'TRANSFERS_DASHBOARD_MULTIPLE_UPDATE_NEW_TRANSFER_CREATION'
export const TRANSFERS_DASHBOARD_MULTIPLE_NEW_TRANSFER_DATABASE_CREATE_REQUEST =
    'TRANSFERS_DASHBOARD_MULTIPLE_NEW_TRANSFER_DATABASE_CREATE_REQUEST'
export const TRANSFERS_DASHBOARD_MULTIPLE_NEW_TRANSFER_DATABASE_CREATE_SUCCESS =
    'TRANSFERS_DASHBOARD_MULTIPLE_NEW_TRANSFER_DATABASE_CREATE_SUCCESS'
export const TRANSFERS_DASHBOARD_MULTIPLE_CHANGE_CURRENT_STEP =
    'TRANSFERS_DASHBOARD_MULTIPLE_CHANGE_CURRENT_STEP'
export const TRANSFERS_DASHBOARD_MULTIPLE_UPDATE_MAPDATA =
    'TRANSFERS_DASHBOARD_MULTIPLE_UPDATE_MAPDATA'
export const TRANSFERS_DASHBOARD_MULTIPLE_CLEAR_NEW_TRANSFER =
    'TRANSFERS_DASHBOARD_MULTIPLE_CLEAR_NEW_TRANSFER'

export function change_current_step(new_step) {
    return {
        type: TRANSFERS_DASHBOARD_MULTIPLE_CHANGE_CURRENT_STEP,
        value: new_step,
    }
}

export function update_map_data(data) {
    return {
        type: TRANSFERS_DASHBOARD_MULTIPLE_UPDATE_MAPDATA,
        value: data,
    }
}

export function clearNewTransfer() {
    return {
        type: TRANSFERS_DASHBOARD_MULTIPLE_CLEAR_NEW_TRANSFER,
    }
}

export async function createNewTransferInDatabase(data) {
    let database_result = API.post('payments', `/create/from_dashboard_multi`, {
        body: {
            ...data,
        },
    })
        .then(database_result => {
            return database_result
        })
        .catch(error => {
            console.log(error)
        })
    return database_result
}

export function createNewTransfer(data) {
    return async function(dispatch) {
        dispatch(newTransferCreationDatabaseStart())

        try {
            let database_result = await createNewTransferInDatabase(data)
            dispatch(
                newTransferCreationDatabaseSuccess(database_result.insertId)
            )
            return database_result
        } catch (e) {
            console.log(e)
        }
    }
}

export function updateNewTransferCreation(key, value) {
    return {
        type: TRANSFERS_DASHBOARD_MULTIPLE_UPDATE_NEW_TRANSFER_CREATION,
        value: { key, value },
    }
}
export function updateNewTransferCreationBeneficiary(array) {
    return {
        type: TRANSFERS_DASHBOARD_MULTIPLE_UPDATE_NEW_TRANSFER_CREATION,
        value: { key: 'multiple_beneficiary_id', value: array },
    }
}
export function newTransferCreationDatabaseStart() {
    return {
        type: TRANSFERS_DASHBOARD_MULTIPLE_NEW_TRANSFER_DATABASE_CREATE_REQUEST,
    }
}
export function newTransferCreationDatabaseSuccess(id) {
    return {
        type: TRANSFERS_DASHBOARD_MULTIPLE_NEW_TRANSFER_DATABASE_CREATE_SUCCESS,
        value: id,
    }
}
