import { makeStyles, Typography } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import CardHeader from '../../components/Card/CardHeader'
import { FormattedMessage } from 'react-intl'
import DynamicTable from '../../components/DynamicTable/DynamicTable.jsx'
import Button from '../../components/CustomButtons/Button.jsx'
import { API } from 'aws-amplify'
import { validateUploadedData } from './utils.js'

const useStyles = makeStyles(() => ({
    registerButton: {
        float: 'right',
        marginTop: '12px',
    },
}))

const Upload = ({ history }) => {
    const classes = useStyles()

    const [beneficiaries, setBeneficiaries] = useState([])

    const [tableHeaders, setTableHeaders] = useState([])
    const [tableRows, setTableRows] = useState([])
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const fileHandler = event => {
        const XLSX = require('xlsx')
        const reader = new FileReader()
        reader.readAsBinaryString(event.target.files[0])

        reader.onload = e => {
            const workbook = XLSX.read(e.target.result, { type: 'binary' })
            const wsname = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[wsname]
            const json_data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
            const {
                error,
                rows,
                headers,
                beneficiaries,
            } = validateUploadedData(json_data)

            if (error) {
                setError(error)
            } else {
                setTableHeaders(headers)
                setTableRows(rows)
                setBeneficiaries(beneficiaries)
            }
        }
    }

    const handleSubmit = () => {
        if (tableRows.length <= 0) {
            return
        }
        setLoading(true)
        const payload = {
            beneficiaries,
        }

        API.post('beneficiaries', '/create/bulk', { body: payload })
            .then(() => {
                history.push('/beneficiaries/view-beneficiaries')
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Fragment>
            <Card>
                <CardHeader icon>
                    <h4 className={classes.cardIconTitle}>
                        <FormattedMessage
                            id="Upload details for Transfers"
                            defaultMessage={'Upload details'}
                        />
                    </h4>
                </CardHeader>
                <CardBody>
                    <GridContainer justify="left">
                        <GridItem xs={12}>
                            <input
                                type="file"
                                style={{ padding: '10px 0' }}
                                onChange={fileHandler}
                            />
                        </GridItem>
                    </GridContainer>
                    {!error ? (
                        tableHeaders.length > 0 &&
                        tableRows.length > 0 && (
                            <DynamicTable
                                headers={tableHeaders}
                                rows={tableRows}
                            />
                        )
                    ) : (
                        <Typography color="error">{error}</Typography>
                    )}
                    <Button
                        disabled={loading}
                        color="rose"
                        onClick={handleSubmit}
                        size="sm"
                        className={classes.registerButton}
                    >
                        {loading ? 'Uploading...' : 'Save'}
                    </Button>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default Upload
