import { connect } from 'react-redux'
import IdentificationUpload from './IdentificationUpload'
import {
    updateNewIdentificationRecordCreation,
} from '../../../redux/actions/identification'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        identification: state.identification,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewIdentificationRecordCreation: (key, value) => {
            // console.log(data)
            dispatch(updateNewIdentificationRecordCreation(key, value))
        },
    }
}

const IdentificationUploadContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IdentificationUpload)

export default IdentificationUploadContainer
