import { connect } from 'react-redux'
import TransfersNew from './TransfersNew'
import { fetchTransferList } from '../../../redux/actions/transfers'

const mapStateToProps = state => {
    return {
        // app_state: state.app_state,
        // clients: state.clients
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchTransferList: () => {
            dispatch(fetchTransferList())
        },
        // fetchClientList: () => {
        //   dispatch(fetchClientList())
        // },
        // selectClientUi: (data) => {
        //   dispatch(selectClientUi(data))
        // },
    }
}

const TransfersNewContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TransfersNew)

export default TransfersNewContainer
