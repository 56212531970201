import React from 'react'
import { connect } from 'react-redux'

// import Datetime from "react-datetime";
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
// import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
// import Button from "components/CustomButtons/Button.jsx";
// import CustomInput from "components/CustomInput/CustomInput.jsx";
// import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from 'components/Card/CardAvatar.jsx'
// import Table from "components/Table/Table.jsx";
// import NavPills from "components/NavPills/NavPills.jsx";

import { API } from 'aws-amplify'
// import {Auth} from "aws-amplify";

// import userProfileStyles from "./UserProfileStyles";
import avatar from '../../assets/img/faces/blank-profile-picture.jpg'
// import PictureUpload from "../../components/CustomUpload/PictureUpload";

// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";

// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import ChangeEmailDialog from "./UserProfile/ChangeEmailDialog.jsx";
// import { Grid } from "@material-ui/core";

// import ReactTable from "react-table";
// import {matchSorter} from "match-sorter";
// import { isNull } from "util";

// For CustomReactSelect
// import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx'

//core for react-intl
// import {FormattedMessage} from "react-intl";
// import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import { cardTitle } from '../../assets/jss/material-dashboard-pro-react.jsx'

// var moment = require('moment');

// require('react-datetime');

const userProfileStyles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
}

class PendingRegistration extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            update_loading: false,
            user: null,
            aws_cognito_id: '',
            account_type: '',
            email: '',
            title: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            // change_password_dialog: false,
            // old_password: "",
            // new_password: "",
            // new_password_confirm: "",
            // new_password_state: "",
            // new_password_confirm_state: "",
            // new_password_changing: false,
            // change_email_dialog: false,

            // language_id_list: [],
            // country_list: [],
            // country_list_prio: [],
            // client_todo_list: [],

            current_client_id: '',

            // date_of_birth: "",
            // place_of_birth_city: "",
            // place_of_birth_country: "",
            // nationality: "",
            // marital_status: "",
            // language_id: "",

            // need_dob: false,
            // need_address: false,
            // need_telephone: false
        }
    }

    async componentDidMount() {
        // console.log("componentDidMount");

        if (this.props.isAuthenticated && this.props.current_user) {
            // console.log("authenticated & current_user so getting details");
            try {
                const user = await this.getUserProfile(
                    this.props.current_user.aws_cognito_id
                )
                //console.log(user);
                const {
                    aws_cognito_id,
                    email,
                    title,
                    first_name,
                    middle_name,
                    last_name,
                    external_reference,
                    // residential_street_line_1,
                    // residential_street_line_2,
                    // residential_street_suburb,
                    // residential_street_state,
                    // residential_street_postcode,
                    // residential_street_country,
                    // postal_street_line_1,
                    // postal_street_line_2,
                    // postal_street_suburb,
                    // postal_street_state,
                    // postal_street_postcode,
                    // postal_street_country,
                    // telephone_home,
                    // telephone_mobile,
                    // telephone_work,
                    // email_secondary,
                    // account_status_notes,
                    // date_of_birth,
                    // place_of_birth_city,
                    // place_of_birth_country,
                    // nationality,
                    // marital_status,
                    // language_id
                } = user

                this.setState({
                    aws_cognito_id,
                    email,
                    title,
                    first_name,
                    middle_name,
                    last_name,
                    external_reference,
                    // residential_street_line_1,
                    // residential_street_line_2,
                    // residential_street_suburb,
                    // residential_street_state,
                    // residential_street_postcode,
                    // residential_street_country,
                    // postal_street_line_1,
                    // postal_street_line_2,
                    // postal_street_suburb,
                    // postal_street_state,
                    // postal_street_postcode,
                    // postal_street_country,
                    // telephone_home,
                    // telephone_mobile,
                    // telephone_work,
                    // email_secondary,
                    // account_status_notes,
                    // date_of_birth,
                    // place_of_birth_city,
                    // place_of_birth_country,
                    // nationality,
                    // marital_status,
                    // language_id
                })
            } catch (e) {
                console.error(e)
            }
        }

        // API.get("countries", `/countries/list_not_deleted`)
        //   .then(response => {
        //     // console.log(response);
        //     this.setState({
        //       // country_list: response.fullList,
        //       country_list_prio: response
        //     });
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });
        // API.get("admin", `/get_laguages`)
        //   .then(response => {
        //     // console.log(response);
        //     this.setState({
        //       language_id_list: response
        //     });
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isAuthenticated && nextProps.current_user) {
            try {
                const user = await this.getUserProfile(
                    nextProps.current_user.aws_cognito_id
                )
                const {
                    aws_cognito_id,
                    account_type,
                    email,
                    title,
                    first_name,
                    middle_name,
                    last_name,
                    external_reference,
                    // residential_street_line_1,
                    // residential_street_line_2,
                    // residential_street_suburb,
                    // residential_street_state,
                    // residential_street_postcode,
                    // residential_street_country,
                    // postal_street_line_1,
                    // postal_street_line_2,
                    // postal_street_suburb,
                    // postal_street_state,
                    // postal_street_postcode,
                    // postal_street_country,
                    // telephone_home,
                    // telephone_mobile,
                    // telephone_work,
                    // email_secondary,
                    // account_status_notes,
                    // date_of_birth,
                    // place_of_birth_city,
                    // place_of_birth_country,
                    // nationality,
                    // marital_status,
                    // language_id
                } = user

                // if (this.is_empty(date_of_birth)) {
                //   this.setState({
                //     need_dob: true
                //   });
                // }
                //
                // if (this.is_empty(telephone_home) && this.is_empty(telephone_mobile) && this.is_empty(telephone_work)) {
                //   this.setState({
                //     need_telephone: true
                //   });
                // }
                //
                // if (this.is_empty(residential_street_line_1)) {
                //   this.setState({
                //     need_address: true
                //   });
                // }
                //
                this.setState({
                    aws_cognito_id,
                    account_type,
                    email,
                    title,
                    first_name,
                    middle_name,
                    last_name,
                    external_reference,
                    // residential_street_line_1,
                    // residential_street_line_2,
                    // residential_street_suburb,
                    // residential_street_state,
                    // residential_street_postcode,
                    // residential_street_country,
                    // postal_street_line_1,
                    // postal_street_line_2,
                    // postal_street_suburb,
                    // postal_street_state,
                    // postal_street_postcode,
                    // postal_street_country,
                    // telephone_home,
                    // telephone_mobile,
                    // telephone_work,
                    // email_secondary,
                    // account_status_notes,
                    // date_of_birth,
                    // place_of_birth_city,
                    // place_of_birth_country,
                    // nationality,
                    // marital_status,
                    // language_id
                })

                // if (user.account_status === 1) {
                //   this.setState({
                //     account_status_notes: <React.Fragment>
                //       <strong>
                //         You will be contacted shortly to confirm account activation.
                //         <br/>
                //         <br/>
                //         If you have any queries in the meanwhile, please <a href={"mailto:admin@forexworldwide.com"}>contact
                //         us</a> via email.
                //       </strong>
                //     </React.Fragment>
                //   })
                // }
            } catch (e) {
                console.error(e)
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            !prevProps.app_state.current_team &&
            this.props.app_state.current_team
        ) {
            //console.log(this.props.app_state)
            let id = 3
            id = this.props.app_state.current_team.brand_domain //set the domain_id in

            //API.get("admin", `/get_domains/get/id/1`)
            API.get('admin', `/get_domains/get/id/${id}`)
                .then(response => {
                    console.log(response)
                    this.setState({
                        current_domain: response,
                        responsible_email: response.email_address,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    async getUserProfile(aws_cognito_id) {
        return API.get('portal', `/users/get-by-id/${aws_cognito_id}`)
    }

    render() {
        const { classes } = this.props

        if (!this.props.app_state.current_client) {
            return null
        }
        if (this.props.app_state.current_client.account_status !== 1) {
            this.props.history.push('/')
        }

        const team_nickname = this.state.current_domain
            ? 'The ' + this.state.current_domain.domain_text + ' Team'
            : ''

        //console.log(this.state.current_domain);
        //console.log(this.state.responsible_email);

        //module: work around to run #671 set emails by Tom
        // switch (division_id){
        //   case 1:
        //     //division_id = 1, domain_name: forexworldwide.com
        //     //team_nickname = {this.props.current_user.team_nickname}
        //     team_responsible_email = "admin@forexworldwide.com";
        //     break;
        //   case 2:
        //     //division_id = 2, domain_name: forexsport.com
        //     //team_nickname = ""
        //     team_responsible_email = "admin@forexsport.com";
        //     break;
        //   case 3:
        //     //division_id = 3, domain_name: forexstudent.com
        //     //team_nickname = "team_nickname"
        //     team_responsible_email = "admin@forexstudent.com";
        //     break;
        //   default:
        //     //team_nickname = "The Forex Sport Team"
        //     team_responsible_email = "admin@forexpersonal.com";
        //     break;
        // }

        return (
            <React.Fragment>
                <div>
                    <GridContainer alignItems="center">
                        <GridItem xs={12} sm={12} md={12}>
                            <Card profile>
                                <CardAvatar profile>
                                    <a
                                        href="#"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <img src={avatar} alt="..." />
                                    </a>
                                </CardAvatar>
                                <CardBody profile>
                                    <h4 className={classes.cardTitle}>
                                        {`${this.state.first_name}  ${
                                            this.state.last_name
                                        }`}
                                    </h4>
                                    <h4>
                                        Important - Please Read - Your
                                        Registration Is Now Pending
                                    </h4>

                                    <hr />

                                    <div
                                        style={{
                                            textAlign: 'left',
                                            paddingLeft: 40,
                                        }}
                                    >
                                        <p>Dear Member</p>
                                        <p>
                                            Thank you. Your online registration
                                            has been received.
                                        </p>
                                        <p>
                                            To activate your account, Australian
                                            regulations require that we verify
                                            your identity.
                                            <br />
                                            Once we receive your documents we
                                            will electronically verify you based
                                            on the documents that you have
                                            provided.
                                            <br />
                                            When we successfully verify you,
                                            your account will be activated and
                                            you will be notified via email.
                                        </p>
                                        <p>
                                            Please note that from time to time
                                            we are not able to verify clients
                                            and may require further
                                            documentation.
                                            <br />
                                            This may include certified
                                            documents.
                                        </p>

                                        <hr />

                                        <p>
                                            <strong>
                                                If you have not uploaded
                                                documents
                                            </strong>
                                        </p>
                                        <p>
                                            <strong>
                                                If you have not uploaded
                                                documents to this system, please
                                                send either a photo or scanned
                                                copy of the following required
                                                documents to{' '}
                                                {this.state.current_domain
                                                    ? this.state.current_domain
                                                          .email_address
                                                    : 'admin@forexsport.com'}
                                                .
                                            </strong>
                                            <br />
                                            <br />
                                            1. A copy of one of the following
                                            documents as proof of your identity:
                                            <br />- Passport
                                            <br />- Driver's licence ( front and
                                            back )<br />
                                            <br />
                                            AND
                                            <br />
                                            2. Provide one of the following
                                            documents as proof of your address
                                            (not older than 3 months):
                                            <br />- Bank statement; or
                                            <br />- Utility bill (e.g. gas,
                                            electricity, water, home phone or
                                            internet bill - not mobile phone)
                                            <br />
                                        </p>
                                        <hr />
                                        <p>
                                            {
                                                //#671
                                                //customise email address (taken from division.brand_domain - domain email_address)
                                            }
                                            Please contact us on{' '}
                                            <a
                                                href={`mailto:${
                                                    this.state.responsible_email
                                                }`}
                                                target="_top"
                                            >
                                                {this.state.responsible_email}
                                            </a>{' '}
                                            if you have any questions.
                                            <br />
                                            <br />
                                            Thanks
                                            <br />
                                            {team_nickname} Team
                                            {
                                                //#671
                                                //old:The Forex Sport Team
                                                //setup a module to branding the name
                                                //team name (division settings shortname)
                                            }
                                        </p>
                                    </div>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </React.Fragment>
        )
    }
}

// export default withStyles(userProfileStyles)(PendingRegistration);

// import { updateRegistrationRedirect } from "../../redux/actions";
// import DashboardPage from "./DashboardPage";

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // updateRegistrationRedirect: (value) => {
        //   dispatch(updateRegistrationRedirect(value));
        // }
    }
}

const PendingRegistrationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(userProfileStyles)(PendingRegistration))

export default PendingRegistrationContainer
