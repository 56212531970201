import {
    CLIENTS_UPDATE_NEW_CLIENT_CREATION_EMAIL,
    CLIENTS_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME,
    CLIENTS_UPDATE_NEW_CLIENT_CREATION_LASTNAME,
    STAFF_SELECT_STAFF_MEMBER,
    PAYMENTS_FETCH_TRANSFER_LIST_REQUEST,
    PAYMENTS_FETCH_TRANSFER_LIST_SUCCESS,
    PAYMENTS_FETCH_TRANSFER_REQUEST,
    PAYMENTS_FETCH_TRANSFER_SUCCESS,
    PAYMENTS_NEW_TRANSFER_DATABASE_CREATE_REQUEST,
    PAYMENTS_NEW_TRANSFER_DATABASE_CREATE_SUCCESS,
    PAYMENTS_SELECT_SET_ID,
    PAYMENTS_SELECT_UI,
    PAYMENTS_START_NEW_TRANSFER_CREATION,
    PAYMENTS_UPDATE_NEW_TRANSFER_CREATION,
    PAYMENTS_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE,
} from '../actions/payments.js'

const initialState = {
    payment_current_ui: 'list',
    is_fetching: false,
    did_invalidate: false,
    payment_list: [],
    payment_detail_fetching: false,
    payment_detail_loaded: false,
    payment_detail_id: null,
    new_payment_data: {},
    new_payment_type: null,
    new_payment_submitted: false,
    new_payment_created: false,
}

function Payments(state = initialState, action) {
    switch (action.type) {
        case STAFF_SELECT_STAFF_MEMBER:
            return Object.assign({}, state, {
                staff_detail_id: action.staff_id,
            })

        case PAYMENTS_FETCH_TRANSFER_LIST_REQUEST:
            return Object.assign({}, state, {
                is_fetching: true,
            })

        case PAYMENTS_FETCH_TRANSFER_LIST_SUCCESS:
            return Object.assign({}, state, {
                is_fetching: false,
                payment_list: action.payment_list,
                lastUpdated: action.receivedAt,
            })

        case PAYMENTS_SELECT_UI:
            return Object.assign({}, state, {
                payment_current_ui: action.value,
            })

        case PAYMENTS_SELECT_SET_ID:
            return Object.assign({}, state, {
                payment_detail_id: action.value,
            })

        case PAYMENTS_FETCH_TRANSFER_REQUEST:
            return Object.assign({}, state, {
                payment_detail_fetching: true,
                payment_detail_loaded: false,
            })

        case PAYMENTS_FETCH_TRANSFER_SUCCESS:
            return Object.assign({}, state, {
                payment_detail_fetching: false,
                payment_detail_loaded: true,
            })

        case PAYMENTS_START_NEW_TRANSFER_CREATION:
            return Object.assign({}, state, {
                new_payment_submitted: false,
            })
        case PAYMENTS_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE:
            return Object.assign({}, state, {
                new_payment_type: action.value,
            })
        case CLIENTS_UPDATE_NEW_CLIENT_CREATION_EMAIL:
            return Object.assign({}, state, {
                new_client_data: Object.assign({}, state.new_client_data, {
                    email: action.value,
                }),
            })
        case CLIENTS_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME:
            return Object.assign({}, state, {
                new_client_data: Object.assign({}, state.new_client_data, {
                    first_name: action.value,
                }),
            })
        case CLIENTS_UPDATE_NEW_CLIENT_CREATION_LASTNAME:
            return Object.assign({}, state, {
                new_client_data: Object.assign({}, state.new_client_data, {
                    last_name: action.value,
                }),
            })
        case PAYMENTS_UPDATE_NEW_TRANSFER_CREATION:
            return Object.assign({}, state, {
                new_payment_data: Object.assign({}, state.new_payment_data, {
                    [action.value.key]: action.value.value,
                }),
            })
        case PAYMENTS_NEW_TRANSFER_DATABASE_CREATE_REQUEST:
            return Object.assign({}, state, {
                new_payment_submitted: true,
                new_payment_created: false,
            })
        case PAYMENTS_NEW_TRANSFER_DATABASE_CREATE_SUCCESS:
            return Object.assign({}, state, {
                new_payment_submitted: false,
                new_payment_data: {},
                new_payment_created: true,
                payment_detail_id: action.value,
            })

        default:
            return state
    }
}

export default Payments
