import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
    fetchInvoicePendingList,
    // selectTransferUi
} from '../../../redux/actions/transfers_pending'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import Card from '../../../components/Card/Card.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'

import CardHeader from '../../../components/Card/CardHeader'
import CardIcon from '../../../components/Card/CardIcon'
import PermIdentity from '@material-ui/icons/PermIdentity'

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react.jsx'
import Button from '../../../components/CustomButtons/Button'
import InvoiceListComponentContainer from '../../../components/Invoicing/InvoiceListComponent'

import { FormattedMessage } from 'react-intl'

const styles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },

    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}

/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientsList
 * ----------------------------------------------------------------------------
 */
class InvoicesListPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        // const { fetchClientList } = this.props
        // console.log(this.props);
        // fetchClientList();
    }

    render() {
        // console.log(this.props);
        const { classes } = this.props

        if (!this.props.app_state.current_client) {
            return null
        }
        if (this.props.app_state.current_client.account_status == 1) {
            this.props.history.push('/pending-registration')
        }
        if (this.props.app_state.current_client.account_status == 5) {
            this.props.history.push('/registration')
        }

        if (this.props.app_state.current_client.account_status == 6) {
            this.props.history.push('/migrate')
        }

        return (
            <div>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <PermIdentity />
                        </CardIcon>
                        <div className={classes.flexEnd}>
                            <h4 className={classes.cardIconTitle}>
                                Invoice List
                            </h4>
                            <div>
                                <Button
                                    round
                                    color="primary"
                                    // onClick={() => this.props.history.push("/transfers/list")}
                                >
                                    <FormattedMessage
                                        id="newTransfer.cardItem1.showAll"
                                        defaultMessage={`Show All`}
                                    />
                                </Button>
                                {/* Take this one out in version 1150, Issue #904 by Tom
                //   <Button
                //   round
                //   color="info"
                //   //onClick={() => this.props.history.push("/transfers/new")} //#Bug fix #612 
                //   onClick={() => this.props.history.push("/payment/new/")}
                // >
                //   <FormattedMessage
                //     id="newTransfer.cardItem1.createNew"
                //     defaultMessage={`Create New`}
                //   />
                </Button>*/}
                                <Button
                                    round
                                    color="info"
                                    onClick={() =>
                                        this.props.fetchInvoicePendingList()
                                    }
                                >
                                    <FormattedMessage
                                        id="newTransfer.cardItem1.refresh"
                                        defaultMessage={`Refresh`}
                                    />
                                </Button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12}>
                                <InvoiceListComponentContainer />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

// TransfersListCurrent.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withStyles(styles)(InvoicesListContainer);
const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers_pending: state.transfers_pending,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchInvoicePendingList: () => {
            dispatch(fetchInvoicePendingList())
        },
        // selectTransferUi: (data) => {
        //    dispatch(selectTransferUi(data))
        // },
    }
}

const InvoicesListPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(InvoicesListPage))

export default InvoicesListPageContainer
