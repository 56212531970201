import { API } from 'aws-amplify'
import { 
    // fetchTransferList, 
    fetchTransferListV2 
} from './transfers'
import { 
    // fetchBeneficiaryList_New, 
    fetchBeneficiaryListByClientId 
} from './beneficiaries'

export const UPDATE_CURRENT_BRAND = 'UPDATE_CURRENT_BRAND'
export const UPDATE_SRC = 'UPDATE_SRC'
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
export const UPDATE_CURRENT_USER_ID = 'UPDATE_CURRENT_USER_ID'
export const UPDATE_CURRENT_USER_LOG_OUT = 'UPDATE_CURRENT_USER_LOG_OUT'
export const UPDATE_ECONOMIC_CALENDAR = 'UPDATE_ECONOMIC_CALENDAR'
export const UPDATE_CURRENT_TEAM = 'UPDATE_CURRENT_TEAM'

async function update_client_database(aws_cognito_id, client_detail) {
    // console.log(aws_cognito_id, client_detail);
    API.put('clients', `/update/aws_cognito_id/${aws_cognito_id}`, {
        body: client_detail,
    })
        .then(database_result => {
            // console.log(database_result);
            return database_result
        })
        .catch(error => {
            console.log(error)
        })
}

async function get_current_client_details(aws_cognito_id) {
    // console.log(aws_cognito_id);
    // return API.get('portal', `/users/get-by-id/${aws_cognito_id}`)
    return API.get('usersNew', `/users-new/get-by-id/${aws_cognito_id}`)
        .then(database_result => {
            // console.log(database_result);

            API.get('transfers', `/get_purpose/team/${database_result.team_id}`)
                .then(response => {
                    // console.log(response)
                    database_result.purpose_of_payment_list = response
                })
                .catch(error => {
                    console.log(error)
                })

            return database_result
        })
        .catch(error => {
            console.log(error)
            // return API.get("portal", `/users/get-by-id/${aws_cognito_id}`)
            //   .then((database_result) => {
            //     // console.log(database_result);

            //     API.get("transfers", `/get_purpose/id/${database_result.division_id}`)
            //       .then((response) => {
            //         // console.log(response)
            //         database_result.purpose_of_payment_list = response;
            //       })
            //       .catch((error) => {
            //         console.log(error);
            //       });

            //     return database_result;
            //   })
            //   .catch((error) => {
            //     console.log(error);
            //   });
        })
}

async function getCurrentClientDetailsByClientId(clientId) {
    // console.log(aws_cognito_id);
    return API.get('usersNew', `/users/get-by-clientid/${clientId}`)
        .then(database_result => {
            console.log(database_result);

            API.get('transfers', `/get_purpose/team/${database_result.team_id}`)
                .then(response => {
                    // console.log(response)
                    database_result.purpose_of_payment_list = response
                })
                .catch(error => {
                    console.log(error)
                })

            return database_result
        })
        .catch(error => {
            console.log(error)
            // return API.get("portal", `/users/get-by-id/${aws_cognito_id}`)
            //   .then((database_result) => {
            //     // console.log(database_result);

            //     API.get("transfers", `/get_purpose/id/${database_result.division_id}`)
            //       .then((response) => {
            //         // console.log(response)
            //         database_result.purpose_of_payment_list = response;
            //       })
            //       .catch((error) => {
            //         console.log(error);
            //       });

            //     return database_result;
            //   })
            //   .catch((error) => {
            //     console.log(error);
            //   });
        })
}

async function getCurrentUserAccess(aws_cognito_id) {
    console.log(aws_cognito_id);
    return {}
    // return API.get('portal', `/users/get-by-id/${aws_cognito_id}`)
    //     .then(database_result => {
    //         // console.log(database_result);
    //         return database_result
    //     })
    //     .catch(error => {
    //         console.log(error)
    //     })
}

async function get_current_division_details(division_id) {
    // console.log(division_id);
    return API.get('divisions', `/get/id/${division_id}`)
        .then(database_result => {
            // console.log(database_result);
            return database_result
        })
        .catch(error => {
            console.log(error)
        })
}

export function get_current_team_details_v2(division_id) {
    return async function(dispatch) {
        try {
            const database_result = await API.get(
                'teams',
                `/get/id/${division_id}`
            )
            dispatch({
                type: UPDATE_CURRENT_TEAM,
                current_team: database_result,
            })
            return database_result
        } catch (e) {
            console.log(e.message)
        }
    }
}

async function get_current_team_details(division_id) {
    // console.log(division_id);
    return API.get('teams', `/get/id/${division_id}`)
        .then(database_result => {
            // console.log(database_result);
            return database_result
        })
        .catch(error => {
            console.log(error)
        })
}

// async function get_current_team_fees(teamId) {
//     return API.get('teams', `/get/transfer-fees/${teamId}`)
//         .then(database_result => {
//             console.log('Team Fees', database_result)
//             return database_result
//         })
//         .catch(error => {
//             console.log(error)
//         })
// }

async function get_current_team_fees(clientID) {
    return API.get('teams', `/get/transfer-fees-byclient/${clientID}`)
        .then(database_result => {
            console.log('Team Fees', database_result)
            return database_result
        })
        .catch(error => {
            console.log(error)
        })
}

async function get_current_domain_details(domain_id) {
    // console.log(domain_id);
    return API.get('admin', `/get_domains/get/id/${domain_id}`)
        .then(database_result => {
            // console.log(database_result);
            return database_result
        })
        .catch(error => {
            console.log(error)
        })
}

async function get_current_country_list() {
    return API.get('countries', `/countries/list_not_deleted`)
        .then(database_result => {
            // console.log(database_result);
            return database_result
        })
        .catch(error => {
            console.log(error)
        })
}

async function get_current_currency_list() {
    return await API.get('currencies', `/currencies/get-list-priority`, {});
}

export function onLoginSuccess(aws_cognito_id) {
    return async function(dispatch) {
        try {
            await update_client_database(aws_cognito_id, {
                portal_last_login_datetime: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace('T', ' '),
            })
            const client_record = await get_current_client_details(
                aws_cognito_id
            )
            // console.log(client_record)
            const division_details = await get_current_division_details(
                client_record.division_id
            )
            const team_details = await get_current_team_details(
                client_record.team_id
            )
            const team_fees = await get_current_team_fees(client_record.id)
            const domain_details = await get_current_domain_details(
                division_details.brand_domain
            )
            const country_list = await get_current_country_list();
            const currency_list = await get_current_currency_list();

            const current_user_access = await getCurrentUserAccess(aws_cognito_id)

            dispatch(
                updateCurrentClientIntoStore(
                    aws_cognito_id,
                    client_record,
                    division_details,
                    team_details,
                    team_fees,
                    domain_details,
                    country_list,
                    currency_list,
                    current_user_access
                )
            )
        } catch (e) {
            // alert(e.message);
            console.log(e.message)
        }
    }
}

export function onInAppLoginSuccess(aws_cognito_id) {
    return async function(dispatch) {
        try {
            // await update_client_database(aws_cognito_id, {
            //     portal_last_login_datetime: new Date()
            //         .toISOString()
            //         .slice(0, 19)
            //         .replace('T', ' '),
            // })
            const client_record = await get_current_client_details(
                aws_cognito_id
            )
            const division_details = await get_current_division_details(
                client_record.division_id
            )
            const team_details = await get_current_team_details(
                client_record.team_id
            )
            const team_fees = await get_current_team_fees(client_record.id)
            console.log('Teamfees', team_fees)
            const domain_details = await get_current_domain_details(
                division_details.brand_domain
            )
            const country_list = await get_current_country_list()

            const current_user_access = await getCurrentUserAccess(aws_cognito_id)

            dispatch(fetchTransferListV2(client_record.id))
            // dispatch(fetchBeneficiaryList_New(aws_cognito_id))
            dispatch(fetchBeneficiaryListByClientId(client_record.id))
            dispatch(
                updateCurrentClientIntoStore(
                    aws_cognito_id,
                    client_record,
                    division_details,
                    team_details,
                    team_fees,
                    domain_details,
                    country_list,
                    current_user_access
                )
            )
        } catch (e) {
            console.log(e.message)
        }
    }
}

export function onInAppLoginSuccesClientId(clientId) {
    return async function(dispatch) {
        try {
            const aws_cognito_id = null;
            // await update_client_database(aws_cognito_id, {
            //     portal_last_login_datetime: new Date()
            //         .toISOString()
            //         .slice(0, 19)
            //         .replace('T', ' '),
            // })
            const client_record = await getCurrentClientDetailsByClientId(
                clientId
            )
            const division_details = await get_current_division_details(
                client_record.division_id
            )
            const team_details = await get_current_team_details(
                client_record.team_id
            )
            const team_fees = await get_current_team_fees(client_record.id)
            console.log('Teamfees', team_fees)
            const domain_details = await get_current_domain_details(
                division_details.brand_domain
            )
            const country_list = await get_current_country_list();
            const currency_list = await get_current_currency_list();

            const current_user_access = await getCurrentUserAccess(aws_cognito_id)

            dispatch(fetchTransferListV2(client_record.id))
            // dispatch(fetchBeneficiaryList_New(aws_cognito_id))
            dispatch(fetchBeneficiaryListByClientId(client_record.id))
            dispatch(
                updateCurrentClientIntoStore(
                    aws_cognito_id,
                    client_record,
                    division_details,
                    team_details,
                    team_fees,
                    domain_details,
                    country_list,
                    currency_list,
                    current_user_access
                )
            )
        } catch (e) {
            console.log(e.message)
        }
    }
}

export function updateEconomicCalendar(value) {
    return {
        type: UPDATE_ECONOMIC_CALENDAR,
        value,
    }
}

export function updateCurrentBrand(brand) {
    return {
        type: UPDATE_CURRENT_BRAND,
        brand,
    }
}

export function updateSrc(src) {
    return {
        type: UPDATE_SRC,
        src,
    }
}

export function updateCurrentUserId(user_id) {
    return {
        type: UPDATE_CURRENT_USER_ID,
        user_id,
    }
}

export function updateCurrentClientIntoStore(
    aws_cognito_id,
    client_record,
    division_details,
    team_details,
    team_fees,
    domain_details,
    country_list,
    currency_list,
    current_user_access
) {
    let brand = null
    // console.log(client_record)
    switch (client_record.team_id) {
        case 2:
        case 4:
        case 6:
        case 8:
        case 9:
            brand = 'gostudy'
            break
        case 7:
            brand = 'redhill'
            break
        case 10:
        case 11:
            brand = 'abcu'
            break
        case 12:
        case 13:
        case 14:
            brand = 'smithsonian'
            break
        case 21:
        case 23:
            brand = 'pimwa'
            break
        case 24:
            brand = 'dorman'
            break
        case 25:
            brand = 'wayne'
            break
        case 38:
        case 39:
        case 40:
            brand = 'crocmedia'
            break
        case 46:
            brand = 'baca'
            break
        default:
            // brand = "dorman";
            brand = null
    }
    // console.log(brand);

    let headClient = false
    if (!client_record.userClient) {
        headClient = true
    }

    return {
        type: UPDATE_CURRENT_USER,
        aws_cognito_id,
        current_client_record: client_record,
        brand: brand,
        current_division: division_details,
        current_team: team_details,
        current_team_fees: team_fees,
        current_domain: domain_details,
        country_list: country_list,
        currency_list: currency_list,
        current_user_access: current_user_access,
        head_client: headClient
    }
}

export function logOutUser() {
    return {
        type: UPDATE_CURRENT_USER_LOG_OUT,
    }
}
