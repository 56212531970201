import React from 'react'
import ReactTable from 'react-table'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
// import TransferList from "./TransferList";
import {
    // fetchClientList,
    fetchInvoicePendingList,
    fetchTransferPendingList,
    // setTransferSelectId
} from '../../redux/actions/transfers_pending'

// @material-ui/icons
import Dvr from '@material-ui/icons/AddCircle'

import {Button as AntButton, message, Table} from 'antd'
import {EyeOutlined, DownloadOutlined } from '@ant-design/icons'

import Button from 'components/CustomButtons/Button.jsx'

/**
 * STYLING
 */
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'

import { FormattedNumber } from 'react-intl'
import 'react-dates/initialize'
import Badge from '../Badge/Badge'
import {API, Storage} from 'aws-amplify'
import ListTable from "../ListTable/ListTable";
import {isEmpty} from "../../libs/miscFunc";

var moment = require('moment')

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
    },
}

/**
 * CLASS - TransferList
 */
class PaymentRequestListComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            entity_list: [],
            startDate: moment().subtract(6, 'month'),
            endDate: moment().add(1, 'month'),
            transferList: []
        }
    }

    componentDidMount() {
        // this.props.fetchTransferPendingList()
        this.fetchTransferList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_client !== this.props.app_state.current_client) {
          console.log(this.props.app_state.current_client)
          // this.props.fetchStaffList()
          this.fetchTransferList()
        }
      }

      fetchTransferList = () => {
        this.setState({transferList: []});
        if (this.props.app_state.current_client) {
          const clientID = this.props.app_state.current_client.id
          // API.get('transfers', `/list/staff/${staffID}`)
          // .then(response => {
          //   console.log(response);
          //   this.setState({transferList: response});
          // })
          // .catch(error => {
          //   console.log(error);
          // });
    
          API.get('transfers_pending', `/list/client/${clientID}`)
          .then(response => {
              console.log(response)
              this.setState({transferList: response});
            //   dispatch(fetchTransferPendingListSuccess(response))
          })
          .catch(error => {
              console.log(error)
          })        }
      }


    // handle_date_change = (startDate, endDate) => {
    //     // console.log(startDate, endDate);
    //     this.setState({ startDate, endDate })
    //     this.buildTableData()
    // }

    proceedToTransfer = async (
        pendingTransferId,
        currency_iso_alpha_3,
        amount_to,
        tpp_beneficiary_id,
        purpose_of_payment_detail,
        detail_1
    ) => {
        await API.put('transfers_pending', `/update/${pendingTransferId}`, {
            body: {
                status_id: 3,
            },
        })
            .then(apiResponse => {
                console.log(apiResponse)
            })
            .catch(error => {
                console.log(error)
            })
        this.props.history.push(
            `/shortcut_transfer/AUD-${currency_iso_alpha_3}--${amount_to}-${tpp_beneficiary_id}-${purpose_of_payment_detail}--${detail_1}?pt=${pendingTransferId}`
        )
    }

    buildTableData() {
        let transfer_pending_list = this.props.transfers_pending
            .transfer_pending_list
        // console.log(transfer_pending_list)

        let date_range_transfer_list = transfer_pending_list
        if (this.state.startDate && this.state.endDate) {
            date_range_transfer_list = transfer_pending_list.filter(
                transfer => {
                    return (
                        this.state.startDate.isBefore(
                            moment(transfer.transaction_datetime).add(1, 'day')
                        ) &&
                        this.state.endDate.isAfter(
                            moment(transfer.transaction_datetime)
                        )
                    )
                }
            )
        }

        // console.log(transfer_pending_list)
        if (!transfer_pending_list) return []
        var transfer_list_length = transfer_pending_list.length
        if (transfer_list_length > 0) {
            // return this.props.transfers.transfer_list.map((prop, key) => {
            return transfer_pending_list.map((prop, key) => {
                return {
                    id: prop.id,
                    // client_fullname: prop.client_nickname,
                    transaction_date: prop.transaction_datetime,
                    status_id: prop.status,
                    memo: prop.memo,
                    nickname: prop.nickname,
                    settlement_date: prop.settlement_date,
                    invoice_date: prop.invoice_date,
                    due_date: prop.due_date,
                    expiryDate: prop.expiryDate,
                    // currency_from: `${prop.currency_from_iso_alpha_3}`,
                    // amount_from: `${prop.amount_from}`,
                    currency_iso_alpha_3: `${prop.currency_iso_alpha_3}`,
                    amount_to: `${prop.amount_to}`,
                    // client_rate: prop.client_rate.toFixed(5),
                    status_id: prop.status_id,
                    status: prop.status,
                    tpp_beneficiary_id: prop.tpp_beneficiary_id,
                    beneficiary_nickname: prop.beneficiary_nickname,
                    apop_description: prop.apop_description,
                    tpp_detail: prop.detail_1,
                    purpose_of_payment_detail: prop.purpose_of_payment_detail,
                    actions: (
                        <div className="actions-right">
                            <Button
                                justIcon
                                // round
                                // simple
                                onClick={() => {
                                    // eslint-disable-next-line react/prop-types
                                    // this.props.history.push(`/invoices/view/${prop.id}`);
                                    this.proceedToTransfer(
                                        prop.id,
                                        prop.currency_iso_alpha_3,
                                        prop.amount_to,
                                        prop.tpp_beneficiary_id,
                                        prop.purpose_of_payment_detail,
                                        prop.detail_1
                                    )
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Dvr />
                            </Button>{' '}
                        </div>
                    ),
                }
            })
        }
    }

    getSteps() {
        return [
            'Submitted',
            'Transfer acknowledged',
            'Funds received from client',
            'Payment made to Beneficiary',
            'Completed',
        ]
    }
    getSteps1() {
        return ['Submitted', 'Not Proceeding']
    }

    downloadInvoice = (data) => {
        if (isEmpty(data.attachmentFileId)) {
            this.props.history.push(`/payment-requests/view/${data.id}`);
        } else {
            const msg = message.loading("Downloading invoice.. Please wait..", 0);
            Storage.get(data.attachmentFileId, {expires: 60}).then(item => {
                fetch(item)
                    .then(response => response.blob())
                    .then(blob => {
                        const a = document.createElement('a');
                        const url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = data.id + '_REQUEST_INVOICE';
                        a.click();
                        window.URL.revokeObjectURL(url);
                        message.success("Invoice downloaded");
                    })
                    .catch(error => {
                        message.error("Failed to download invoice. Please try again");
                        console.error('Error downloading file:', error)
                    })
                    .finally(() => msg());
            })
        }
    }

    render() {
        // console.log(this.props.past)
        // console.log(this.props.current)
        const steps = this.getSteps()
        const steps1 = this.getSteps1()

        if (!this.props.transfers_pending.transfer_pending_list) return null

        // eslint-disable-next-line react/prop-types
        var transfer_status = this.props.transfers_pending.transfer_pending_list.map(
            (prop, key) => {
                return prop.status
            }
        )
        var transfer_status_unique = [...new Set(transfer_status)]
        var transfer_status_optionlist = transfer_status_unique.map(
            (prop, key) => {
                return (
                    <option value={prop} key={key}>
                        {prop}
                    </option>
                )
            }
        )

        let tableData = []
        let date_range_transfer_list = this.state.transferList;
        const transfer_list_length = date_range_transfer_list.length;
    
        if (transfer_list_length > 0) {
            // return this.props.transfers.transfer_list.map((prop, key) => {
                tableData = date_range_transfer_list.map((prop, key) => {
                return {
                    id: prop.id,
                    // client_fullname: prop.client_nickname,
                    transaction_date: prop.transaction_datetime,
                    status_id: prop.status,
                    memo: prop.memo,
                    nickname: prop.nickname,
                    settlement_date: prop.settlement_date,
                    invoice_date: prop.invoice_date,
                    due_date: prop.due_date,
                    expiryDate: prop.expiryDate,
                    // currency_from: `${prop.currency_from_iso_alpha_3}`,
                    // amount_from: `${prop.amount_from}`,
                    currency_iso_alpha_3: `${prop.currency_iso_alpha_3}`,
                    amount_to: `${prop.amount_to}`,
                    // client_rate: prop.client_rate.toFixed(5),
                    status_id: prop.status_id,
                    status: prop.status,
                    tpp_beneficiary_id: prop.tpp_beneficiary_id,
                    beneficiary_nickname: prop.beneficiary_nickname,
                    apop_description: prop.apop_description,
                    tpp_detail: prop.detail_1,
                    purpose_of_payment_detail: prop.purpose_of_payment_detail,
                    attachmentFileId: prop.attachmentFileId,
                    actions: (
                        <div >
                            <AntButton
                                // justIcon
                                // round
                                // simple
                                // type='link'
                                type="primary"
                                size={'small'}
                                icon={isEmpty(prop.attachmentFileId) ? <EyeOutlined /> : <DownloadOutlined />}
                                onClick={() => {
                                    // eslint-disable-next-line react/prop-types
                                    // this.props.history.push(`/invoices/view/${prop.id}`);
                                    this.downloadInvoice(prop)
                                }}
                                // color="info"
                                // className="edit"
                            >
                                {'Invoice'}
                            </AntButton>{' '}
                            <AntButton
                                // justIcon
                                // round
                                // simple
                                // type='link'
                                type="primary"
                                style={{width: '70px'}}
                                size={'small'}
                                danger={true}
                                onClick={() => {
                                    // eslint-disable-next-line react/prop-types
                                    this.proceedToTransfer(
                                        prop.id,
                                        prop.currency_iso_alpha_3,
                                        prop.amount_to,
                                        prop.tpp_beneficiary_id,
                                        prop.purpose_of_payment_detail,
                                        prop.detail_1
                                    )
                                }}
                                // color="warning"
                                // className="edit"
                            >
                                Pay Now
                            </AntButton>
                        </div>
                    ),
                }
            })
        }


        // console.log(this.props);
        return (
            <React.Fragment>
                <Table
                    dataSource={tableData}
                    loading={!tableData}
                    columns={[
                        {
                            title: 'ID',
                            dataIndex: 'id',
                            defaultSortOrder: ['descend'],
                            ...this.props.getColumnSearchProps({
                                dataIndex: 'id',
                                render: (text, record) => {
                                    return <>
                                        <Badge
                                            color={
                                                record.status === 'Draft'
                                                    ? 'warning'
                                                    : record.status ===
                                                    'Submitted'
                                                        ? 'info'
                                                        : record.status ===
                                                        'Acknowledged'
                                                            ? 'success'
                                                            : record.status ===
                                                            'Processing'
                                                                ? 'success'
                                                                : record.status ===
                                                                'Awaiting Funds'
                                                                    ? 'danger'
                                                                    : record.status ===
                                                                    'Funds Received'
                                                                        ? 'danger'
                                                                        : record.status ===
                                                                        'Payment Made'
                                                                            ? 'success'
                                                                            : record.status ===
                                                                            'Completed'
                                                                                ? 'primary'
                                                                                : record.status ===
                                                                                'On Hold'
                                                                                    ? 'danger'
                                                                                    : record.status ===
                                                                                    'Not Proceeding'
                                                                                        ? 'primary'
                                                                                        : 'success'
                                            }
                                        >
                                            ID: {record.id}
                                            {
                                                //c.original.transfer_status
                                            }
                                        </Badge>
                                    </>
                                }
                            })
                        },
                        {
                            title: 'Beneficiary',
                            dataIndex: 'beneficiary_nickname',
                        },
                        {
                            title: 'Payment',
                            dataIndex: 'apop_description'
                        },
                        {
                            title: 'Detail',
                            dataIndex: 'tpp_detail'
                        },
                        {
                            title: 'Amount',
                            dataIndex: 'amount_to',
                            ...this.props.getColumnSearchProps({
                                dataIndex: 'amount_to',
                                render: (text, record) => {
                                    return  <>
                                        <div
                                            className={`currency-flag currency-flag-${
                                                record.currency_iso_alpha_3
                                                    ? record.currency_iso_alpha_3.toLowerCase()
                                                    : ''
                                            }`}
                                        />{' '}
                                        {record.currency_to_iso_alpha_3}
                                        <FormattedNumber
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            value={text}
                                        />{' '}
                                    </>
                                }
                            })

                        },
                        {
                            Header: 'Expiry',
                            accessor: 'expiryDate',
                            ...this.props.getColumnSearchProps({
                                dataIndex: 'expiryDate',
                                render: (text, record) => (
                                    <>
                                        {text
                                            ? moment(text).format('DD/MM/YYYY')
                                            : ''}{' '}
                                    </>
                                ),
                            })
                        },
                        {
                            title: 'Status ',
                            dataIndex: 'status'
                        },
                        {
                            title: 'Actions',
                            dataIndex: 'actions',
                            width: '250px',
                        },
                    ]}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers_pending: state.transfers_pending,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchTransferPendingList: () => {
            dispatch(fetchTransferPendingList())
        },
    }
}

const PaymentRequestListComponentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(styles)(ListTable(PaymentRequestListComponent))))

export default PaymentRequestListComponentContainer
