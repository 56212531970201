export default {
    s3: {
        REGION: 'YOUR_S3_UPLOADS_BUCKET_REGION',
        BUCKET: 'YOUR_S3_UPLOADS_BUCKET_NAME',
    },
    apiGateway: {
        REGION: 'ap-southeast-2',
        URL: 'https://portal-api.forexworldwide.com/email',
    },
    cognito: {
        REGION: 'ap-southeast-2',
        USER_POOL_ID: 'ap-southeast-2_pLZ4EKm2d',
        APP_CLIENT_ID: '36hpfb5vkdiqp59ptdj6vtccks',
        IDENTITY_POOL_ID: 'ap-southeast-2:8f95960b-dcd6-44c7-80aa-6ab6dbf8ece0',
    },
}
