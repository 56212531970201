import React, {useEffect} from 'react'
import {useParams, withRouter} from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import 'react-dates/initialize'
import {matchSorter} from 'match-sorter'
import ReactTable from 'react-table'
import Card from '../Card/Card'
import CardBody from '../Card/CardBody'
import CardHeader from '../Card/CardHeader'
import Table from '../Table/Table'
import {fetchForwardDetails, fetchForwardDrawDowns,} from '../../redux/actions/forwards'
import ForwardDetailSummary from "../../views/Dashboard/Forwards/tabComponents/ForwardDetailSummary";
import {Divider, Tabs} from "antd";
import ForwardDetailDrawDowns from "../../views/Dashboard/Forwards/tabComponents/ForwardDetailDrawDowns";

const styles = {
    infoItem: {},
}

const ForwardsDetail = ({
                            forward,
                            fetching,
                            forwardDrawDown,
                            classes,
                            intl,
                            fetchForwardDetails,
                            fetchForwardDrawDowns
                        }) => {
    const {id: forwardId} = useParams()

    useEffect(() => {
        forwardId && fetchForwardDetails(forwardId)
        forwardId && fetchForwardDrawDowns(forwardId)
    }, [])

    return fetching || !forward || !forwardDrawDown ? (
        <>
            <span>Loading...</span>
        </>
    ) : (
        <>
            <ForwardDetailSummary forward={forward} fetchForwardDetails={fetchForwardDetails}/>
            <Divider/>
            <Tabs>
                <Tabs.TabPane tab="Draw Downs" key = "drawdown">
                    <ForwardDetailDrawDowns loading={fetching} forwardDrawDown={forwardDrawDown} />
                </Tabs.TabPane>
            </Tabs>
            </>
    )
}

const mapStateToProps = state => ({
    forward: state.forwards.forwardDetail,
    fetching: state.forwards.fetching,
    forwardDrawDown: state.forwards.forwardDrawDown,
})

const mapDispatchToProps = dispatch => ({
    fetchForwardDetails: id => dispatch(fetchForwardDetails(id)),
    fetchForwardDrawDowns: id => dispatch(fetchForwardDrawDowns(id)),
})

const ForwardsDetailComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(injectIntl(withStyles(styles)(ForwardsDetail))))

export default ForwardsDetailComponent
