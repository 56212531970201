import {
    FETCH_BENEFICIARY_LIST_REQUEST,
    FETCH_BENEFICIARY_LIST_SUCCESS,
    BENEFICIARY_CREATE_NEW_SHOW_UI,
    BENEFICIARY_CREATE_NEW_START,
    BENEFICIARY_CREATE_NEW_CANCEL,
    BENEFICIARY_CREATE_NEW_COMPLETE,
    BENEFICIARY_SELECT_UI,
    BENEFICIARY_ENTITIES_LIST_IS_DIRTY,
    BENEFICIARY_BENEFICIARY_EDIT,
    BENEFICIARY_NEW_BENEFICIARY_CREATE,
} from '../actions/beneficiaries.js'

const initialState = {
    is_fetching: false,
    beneficiaries: [],
    did_invalidate: false,
    beneficiaries_list_is_dirty: false,
    beneficiaries_current_ui: 'list',
    beneficiaries_create_new_in_process: false,
    beneficiaries_id: '',
    new_beneficiary_data: null,
}

function beneficiaries(state = initialState, action) {
    switch (action.type) {
        case FETCH_BENEFICIARY_LIST_REQUEST:
            console.log(action)
            return Object.assign({}, state, {
                is_fetching: true,
            })

        case FETCH_BENEFICIARY_LIST_SUCCESS:
            return Object.assign({}, state, {
                is_fetching: false,
                beneficiaries: action.beneficiaries,
                lastUpdated: action.receivedAt,
            })

        case BENEFICIARY_CREATE_NEW_SHOW_UI:
            return Object.assign({}, state, {
                beneficiaries_current_ui: 'list',
            })

        case BENEFICIARY_CREATE_NEW_START:
            return Object.assign({}, state, {
                beneficiaries_create_new_in_process: true,
            })

        case BENEFICIARY_CREATE_NEW_CANCEL:
            return Object.assign({}, state, {
                beneficiaries_create_new_in_process: false,
                beneficiaries_current_ui: 'list',
            })

        case BENEFICIARY_CREATE_NEW_COMPLETE:
            return Object.assign({}, state, {
                beneficiaries_create_new_in_process: false,
                beneficiaries_current_ui: 'list',
                beneficiaries_list_is_dirty: true,
            })

        case BENEFICIARY_SELECT_UI:
            return Object.assign({}, state, {
                beneficiaries_current_ui: action.value,
            })
        case BENEFICIARY_ENTITIES_LIST_IS_DIRTY:
            return Object.assign({}, state, {
                beneficiaries_list_is_dirty: action.value,
            })
        case BENEFICIARY_BENEFICIARY_EDIT:
            return Object.assign({}, state, {
                beneficiaries_create_new_in_process: false,
                beneficiaries_current_ui: 'edit',
                beneficiaries_id: action.value,
            })
        case BENEFICIARY_NEW_BENEFICIARY_CREATE:
            return Object.assign({}, state, {
                new_beneficiary_data: Object.assign(
                    {},
                    state.new_beneficiary_data,
                    {
                        [action.value.key]: action.value.value,
                    }
                ),
            })
        default:
            return state
    }
}

export default beneficiaries
