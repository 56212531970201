import React from 'react'
import ReactDOM from 'react-dom'
import DayjsUtils from "dayjs";
// import { createBrowserHistory } from "history";
// import { Router, Route, Switch } from "react-router-dom";

// import indexRoutes from "routes/index.jsx";

import 'assets/scss/material-dashboard-pro-react.css?v=1.4.0'
import 'react-dates/lib/css/_datepicker.css'

// import Amplify from "aws-amplify";
// import config from "./config/awsConfig";
import AppContainer from './AppContainer'

import {applyMiddleware, compose, createStore} from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './redux/reducers/index.js'

import { createLogger } from 'redux-logger'

import thunkMiddleware from 'redux-thunk'
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import dayjs from "dayjs";

// Amplify.configure({
//   Auth: {
//     mandatorySignIn: true,
//     region: config.cognito.REGION,
//     userPoolId: config.cognito.USER_POOL_ID,
//     identityPoolId: config.cognito.IDENTITY_POOL_ID,
//     userPoolWebClientId: config.cognito.APP_CLIENT_ID
//   },
//   // Storage: {
//   //   region: config.s3.REGION,
//   //   bucket: config.s3.BUCKET,
//   //   identityPoolId: config.cognito.IDENTITY_POOL_ID
//   // },
//   API: {
//     endpoints: [
//       {
//         name: "portal",
//         endpoint: config.apiGateway.URL,
//         region: config.apiGateway.REGION
//       },
//     ]
//   }
// });
//
//
//
// const hist = createBrowserHistory();

const logger = createLogger({
    collapsed: true,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware, logger)));


ReactDOM.render(
    <Provider store={store}>
            <AppContainer />
    </Provider>,
    document.getElementById('root')
)
