import React from 'react'
import ReactTable from 'react-table'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
// import TransferList from "./TransferList";
import {
    // fetchClientList,
    fetchTransferList,
    setTransferSelectId,
} from '../../redux/actions/transfers'

// @material-ui/icons
import Dvr from '@material-ui/icons/Dvr'
import Button from 'components/CustomButtons/Button.jsx'

/**
 * STYLING
 */
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'
import { matchSorter } from 'match-sorter'

import { FormattedNumber } from 'react-intl'
import 'react-dates/initialize'

import {
    DateRangePicker,
    SingleDatePicker,
    DayPickerRangeController,
} from 'react-dates'

import Badge from '../Badge/Badge'

import Card from '../Card/Card'
//import CardHeader from "../Card/CardHeader";
import CardBody from '../Card/CardBody'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { API } from 'aws-amplify'

var moment = require('moment')

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
    },
}
class HorizontalStepperNew extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {}

    render() {
        let stepperSteps = [
            'Submitted',
            'Transfer acknowledged',
            'Funds received from client',
            'Payment made to Beneficiary',
            'Completed',
        ]
        let ActivTab = 0
        switch (this.props.transfer_status) {
            case 1:
                // draft
                ActivTab = 0
                break
            case 2:
                // in process
                ActivTab = 1
                break
            case 3:
                // acknowledged
                ActivTab = 1
                break
            case 4:
                // processing
                ActivTab = 1
                break
            case 5:
                // awaiting funds
                ActivTab = 1
                break
            case 6:
                // funds received
                ActivTab = 2
                break
            case 7:
                // payment made
                ActivTab = 3
                break
            case 8:
                // completed
                ActivTab = 4
                break
            case 9:
                // on hold
                ActivTab = 0
                break
            // not proceeding]
            case 10:
                ActivTab = 0
                break
        }

        return (
            <Stepper activeStep={ActivTab}>
                {stepperSteps.map((label, index) => {
                    const stepProps = {}
                    const labelProps = {}

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        )
    }
}

export default withStyles(styles)(HorizontalStepperNew)
