import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import Button from 'components/CustomButtons/Button.jsx'

import ReactTable from 'react-table'
import { FormattedNumber } from 'react-intl'
import Table from 'components/Table/Table.jsx'
import { injectIntl } from 'react-intl'

import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

import { API } from 'aws-amplify'

import queryString from 'query-string'
import { getLetterheadLogo, receiptFooter, getSignoffName } from '../../libs/branding'

const axios = require('axios')
var moment = require('moment')

const forexWorldWideLogo = require('assets/img/pdf_img/forexworldwide_logo_transparent_280x50.42dc12df.png')
const forexSportLogo = require('assets/img/pdf_img/fxsport_signature_gmail.png')
const goStudyLogo = require('assets/img/pdf_img/GOSTUDY_HorizontalUsage_Black_250x50.png')

const style = {
    root: {},
    pageHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 22,
    },
    pageSubHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 18,
    },
    tableHeaders: {
        width: 150,
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 8,
    },
    tableCells: {
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
    },
    backgroundBlue: {
        backgroundColor: '#348feb40',
    },
    topLetterheadLogo: {
        width: '300px',
    },
}

const TheadComponent = props => null // a component returning null (to hide) or you could write as per your requirement

class HoldingAccountReceiptPage extends React.Component {
    state = {
        value: 0,
        currencies_list_priority: [],
        map_data: {},
        beneficiaries_list: [],
        beneficiary_id: '',
        transfer_heading: 'Current Transfers',
        transfer: {},
        team: { brand_sidebar_logo: '' },
    }

    componentDidMount() {
        this.getHoldingAccountReceiptData(
            this.props.match.params.holding_account_line_item
        )
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (
            nextProps.holding_account_line_item !==
            this.props.holding_account_line_item
        ) {
            this.getHoldingAccountReceiptData(
                nextProps.holding_account_line_item
            )
        }
    }

    getHoldingAccountReceiptData(option_value) {
        this.setState({ holdingAccountLineItemId: option_value })

        API.get(
            'holding_accounts',
            `/receipt/holding-account/${option_value}`
        ).then(response => {
            console.log(response)
            this.setState({
                line_item: response.line_item,
                holding_account: response.holding_account,
                currency: response.currency,
                currencies: response.currencies,
                team: response.team,
            })
        })
    }

    printDocument() {
        const input = document.getElementById('divToPrint')
        html2canvas(input).then(canvas => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF('p', 'mm', 'a4')
            const width = pdf.internal.pageSize.getWidth()
            const height = pdf.internal.pageSize.getHeight()
            pdf.addImage(imgData, 'JPEG', 0, 0, width, height)
            // pdf.output('dataurlnewwindow');
            pdf.save(
                `holding_account_receipt_${this.props.match.params.holding_account_line_item.padStart(
                    5,
                    '0'
                )}`
            )
        })
    }

    getSignatureLine = brand => {
        switch (brand) {
            case '1':
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img
                                src={forexWorldWideLogo}
                                alt="ForexWorldWide Logo"
                            />
                            <br />
                            <strong>Forex WorldWide</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexworldwide.com
                            <br />T +61 (03) 9008 1880
                            <br />W www.forexworldwide.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) in Australia and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            case '2':
                break
            case '4':
                return (
                    <React.Fragment>
                        <p>
                            <strong>Forex Student</strong>
                            <br />
                            Forex Student is a trading name of Forex Sport Pty
                            Ltd AFSL 401379.
                            <br />
                            Forex Student is a provider of money transfer
                            services for Go Study Australia Pty Ltd.
                            <br />
                            <br />
                            <table width="40%">
                                <tr>
                                    <td>
                                        <img
                                            src={forexWorldWideLogo}
                                            alt="ForexWorldWide Logo"
                                        />
                                    </td>
                                    <td>
                                        <img
                                            src={goStudyLogo}
                                            alt="ForexWorldWide Logo"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        ABN 22 147 363 175
                                        <br />
                                        AFSL 401379
                                        <br />
                                        Level 4, 100 Collins Street
                                        <br />
                                        Melbourne Vic 3000
                                        <br />
                                        <br />E admin@forexworldwide.com
                                        <br />T +61 (03) 9008 1880
                                        <br />W www.forexworldwide.com
                                        <br />
                                    </td>
                                    <td>
                                        ABN 000 000 000 <br />
                                        <br />
                                        000 <br />
                                        000
                                        <br />
                                        <br />E abc@abc.com
                                        <br />T +61 (03) 0000 0000
                                        <br />F +61 (03) 0000 0000
                                        <br />W www.gostudy.com.au
                                        <br />
                                    </td>
                                </tr>
                            </table>
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) in Australia and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            case '24':
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img src={forexSportLogo} alt="ForexSport Logo" />
                            <br />
                            <br />
                            <strong>Forex Sport</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexsport.com
                            <br />W www.forexsport.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) in Australia and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            case '29':
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img src={forexSportLogo} alt="ForexSport Logo" />
                            <br />
                            <strong>Forex Sport</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexsport.com
                            <br />T +61 (03) 9008 1880
                            <br />W www.forexsport.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) in Australia and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            case '24':
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img src={forexSportLogo} alt="ForexSport Logo" />
                            <br />
                            <br />
                            <strong>Forex Sport</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexsport.com
                            <br />W www.forexsport.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) in Australia and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break

            default:
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img
                                src={forexWorldWideLogo}
                                alt="ForexWorldWide Logo"
                            />
                            <br />
                            <strong>Forex WorldWide</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexworldwide.com
                            <br />T +61 (03) 9008 1880
                            <br />W www.forexworldwide.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) in Australia and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
        }
    }

    render() {
        const { classes } = this.props

        if (!this.state.holding_account)
            return (
                <React.Fragment>
                    <strong>Error: Unable to access receipt</strong>
                </React.Fragment>
            )
        if (!this.state.line_item)
            return (
                <React.Fragment>
                    <strong>Error: Unable to access receipt</strong>
                </React.Fragment>
            )

        if (!this.state.team)
            return (
                <React.Fragment>
                    <strong>Error: Unable to access receipt</strong>
                </React.Fragment>
            )

        const logo = getLetterheadLogo(this.state.team.id)

        return (
            <React.Fragment>
                <div style={{ margin: '0 auto' }}>
                    <GridContainer alignItems="stretch">
                        <GridItem xs={12}>
                            <Card style={{ height: '90%' }}>
                                <CardHeader color="info" stats icon />
                                <CardBody>
                                    <p className={classes.cardCategory}>
                                        {/* {branding} */}
                                        {/*<FormattedMessage*/}
                                        {/*  id="dashboard.heading1"*/}
                                        {/*  defaultMessage={*/}
                                        {/*    `Welcome to`}*/}
                                        {/*/>*/}
                                    </p>
                                    <div
                                        id="divToPrint"
                                        className="mt4"
                                        style={{
                                            hidden: 'hidden',

                                            width: '210mm',
                                            minHeight: '297mm',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            padding: '10mm',
                                        }}
                                    >
                                        <img
                                            src={logo}
                                            alt="logo"
                                            className={
                                                classes.topLetterheadLogo
                                            }
                                        />
                                        <br />
                                        <hr />
                                        <h3 className={classes.pageHeadings}>
                                            {this.state.currency.iso_alpha_3.toUpperCase()} Deposit Receipt
                                        </h3>
                                        <p>
                                            Please note details of transaction:
                                        </p>
                                        <br />

                                        <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                <h5
                                                    className={
                                                        classes.pageSubHeadings
                                                    }
                                                >
                                                    Transaction Details
                                                </h5>
                                                <div
                                                    style={{
                                                        border:
                                                            '1px solid #a1a1a1',
                                                    }}
                                                >
                                                    <table
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <tbody
                                                            style={{
                                                                fontFamily:
                                                                    'Arial',
                                                                textAlign:
                                                                    'left',
                                                                verticalAlign:
                                                                    'top',
                                                            }}
                                                        >
                                                            <tr
                                                                className={
                                                                    classes.backgroundBlue
                                                                }
                                                            >
                                                                <th
                                                                    className={
                                                                        classes.tableHeaders
                                                                    }
                                                                >
                                                                    Date
                                                                </th>
                                                                <td
                                                                    className={
                                                                        classes.tableCells
                                                                    }
                                                                >
                                                                    <React.Fragment
                                                                    >
                                                                        {this
                                                                            .state
                                                                            .line_item
                                                                            .transaction_datetime ? (
                                                                            <React.Fragment
                                                                            >
                                                                                {moment(
                                                                                    this
                                                                                        .state
                                                                                        .line_item
                                                                                        .transaction_datetime
                                                                                ).format(
                                                                                    'DD/MM/YYYY'
                                                                                )}
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </React.Fragment>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    className={
                                                                        classes.tableHeaders
                                                                    }
                                                                >
                                                                    Client
                                                                </th>
                                                                <td
                                                                    className={
                                                                        classes.tableCells
                                                                    }
                                                                >
                                                                    <React.Fragment
                                                                    >
                                                                        {this
                                                                            .state
                                                                            .holding_account
                                                                            .client_nickname
                                                                            ? this
                                                                                  .state
                                                                                  .holding_account
                                                                                  .client_nickname
                                                                            : ''}
                                                                    </React.Fragment>
                                                                </td>
                                                            </tr>
                                                            <tr className={classes.backgroundBlue}>
                                                                <th
                                                                    className={
                                                                        classes.tableHeaders
                                                                    }
                                                                >
                                                                    Currency
                                                                </th>
                                                                <td
                                                                    className={
                                                                        classes.tableCells
                                                                    }
                                                                >
                                                                    <React.Fragment
                                                                    >
                                                                        <div
                                                                            className={`currency-flag currency-flag-${this.state.currency.iso_alpha_3.toLowerCase()}`}
                                                                        />{' '}
                                                                        <strong>
                                                                            {this.state.currency.iso_alpha_3.toUpperCase()}{' '}
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .currency
                                                                                    .full_name
                                                                            }
                                                                        </strong>
                                                                    </React.Fragment>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    className={
                                                                        classes.tableHeaders
                                                                    }
                                                                >
                                                                    Amount
                                                                </th>
                                                                <td
                                                                    className={
                                                                        classes.tableCells
                                                                    }
                                                                >
                                                                    <React.Fragment
                                                                    >
                                                                        {this
                                                                            .state
                                                                            .line_item
                                                                            .credit ? (
                                                                            <React.Fragment
                                                                            >
                                                                                {this.props.intl.formatNumber(
                                                                                    this
                                                                                        .state
                                                                                        .line_item
                                                                                        .credit,
                                                                                    {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2,
                                                                                    }
                                                                                )}
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </React.Fragment>
                                                                </td>
                                                            </tr>
                                                            <tr className={classes.backgroundBlue}>
                                                                <th
                                                                    className={
                                                                        classes.tableHeaders
                                                                    }
                                                                >
                                                                    Description
                                                                </th>
                                                                <td
                                                                    className={
                                                                        classes.tableCells
                                                                    }
                                                                >
                                                                    <React.Fragment
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .line_item
                                                                                .memo
                                                                        }
                                                                    </React.Fragment>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br />
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                <p>
                                                    Funds have been credited to
                                                    your holding account.
                                                </p>
                                                <p>
                                                    If any of the above details
                                                    are incorrect, please
                                                    contact us immediately.
                                                </p>
                                                <p>
                                                    The Dealing Team<br/>
                                                    <strong>
                                                        {getSignoffName(this.state.team.id)}
                                                    </strong>
                                                </p>

                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer justify="center" style={{paddingTop: 300}}>
                                            <GridItem xs={12} md={12}>
                                                {receiptFooter(this.state.team.id)}
                                                {/*{' '}*/}
                                                {/*<div>*/}
                                                {/*    {' '}*/}
                                                {/*    {this.getSignatureLine(*/}
                                                {/*        `${this.state.team.id}`*/}
                                                {/*    )}{' '}*/}
                                                {/*</div>{' '}*/}
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </CardBody>
                                <CardFooter />
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

// const mapDispatchToProps = (dispatch) => {
// return {
//   onLoginSuccess: (user_id) => {
//     dispatch(onLoginSuccess(user_id));
//   }
// };
// };

const HoldingAccountReceiptPageContainer = connect(
    mapStateToProps
    // mapDispatchToProps
)(injectIntl(withRouter(withStyles(style)(HoldingAccountReceiptPage))))

export default HoldingAccountReceiptPageContainer
