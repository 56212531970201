import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import React, { Fragment } from 'react'
import CustomInput from '../../CustomInput/CustomInput'
import GridContainer from '../../Grid/GridContainer'
import GridItem from '../../Grid/GridItem'

const BankAddress = ({
    classes,
    edit_mode,
    bank_legal_name,
    bank_address_line_1,
    bank_address_line_2,
    bank_address_suburb,
    bank_address_state,
    bank_address_postcode,
    bank_address_country,
    country_list_prio,
    handleChange,
    handleSelectChange,
    handleSubmit,
}) => {
    return (
        <Fragment>
            <Typography variant="h5" style={{ fontWeight: 700 }}>
                Bank Address
            </Typography>
            <div style={{ padding: 20, marginBottom: 24 }}>
                <form onSubmit={handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Bank Name"
                                id="bank_legal_name"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: !edit_mode,
                                    value: bank_legal_name || '',
                                    onChange: event => {
                                        handleChange(event)
                                    },
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Address (line 1)"
                                id="bank_address_line_1"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: !edit_mode,
                                    value: bank_address_line_1 || '',
                                    onChange: event => {
                                        handleChange(event)
                                    },
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Address (Lane 2)"
                                id="bank_address_line_2"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: !edit_mode,
                                    value: bank_address_line_2 || '',
                                    onChange: event => {
                                        handleChange(event)
                                    },
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                labelText="Suburb"
                                id="bank_address_suburb"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: !edit_mode,
                                    value: bank_address_suburb || '',
                                    onChange: event => {
                                        handleChange(event)
                                    },
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                labelText="State"
                                id="bank_address_state"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: !edit_mode,
                                    value: bank_address_state || '',
                                    onChange: event => {
                                        handleChange(event)
                                    },
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                labelText="Postcode"
                                id="bank_address_postcode"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: !edit_mode,
                                    value: bank_address_postcode || '',
                                    onChange: event => {
                                        handleChange(event)
                                    },
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                            >
                                <InputLabel
                                    htmlFor="simple-select"
                                    className={classes.selectLabel}
                                >
                                    Country
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu,
                                    }}
                                    classes={{
                                        select: classes.select,
                                    }}
                                    value={bank_address_country || '0'}
                                    onChange={handleSelectChange}
                                    inputProps={{
                                        disabled: !edit_mode,
                                        name: 'bank_address_country',
                                        id: 'bank_address_country',
                                    }}
                                >
                                    <MenuItem
                                        key="0x0"
                                        value="0"
                                        disabled
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        Select Country
                                    </MenuItem>

                                    {country_list_prio
                                        .sort(
                                            (a, b) =>
                                                a.list_priority <
                                                b.list_priority
                                        )
                                        .map(item => {
                                            return (
                                                <MenuItem
                                                    key={item.id}
                                                    classes={{
                                                        root:
                                                            classes.selectMenuItem,
                                                        selected:
                                                            classes.selectMenuItemSelected,
                                                    }}
                                                    value={item.id}
                                                >
                                                    {item.full_name}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                        </GridItem>
                    </GridContainer>
                </form>
            </div>
        </Fragment>
    )
}

export default BankAddress
