import React from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'

import { API, Storage } from 'aws-amplify'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'

// @material-ui/icons
import Dvr from '@material-ui/icons/Dvr'
// import Close from "@material-ui/icons/Close";

// core components
// import GridContainer from "components/Grid/GridContainer.jsx";
// import GridItem from "components/Grid/GridItem.jsx";
import Button from 'components/CustomButtons/Button.jsx'
// import Card from "components/Card/Card.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'
import { matchSorter } from 'match-sorter'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import Card from '../Card/Card'
import CardBody from '../Card/CardBody'
import CustomReactSelect from '../Forms/CustomReactSelect/CustomReactSelect'
import CustomInput from '../CustomInput/CustomInput'
import { AppTemplates } from '../../AppTemplates'
import Grid from '@material-ui/core/Grid'
import DocumentUploadNew from '../DocumentUpload/DocumentUploadNew'
import SweetAlert from 'react-bootstrap-sweetalert'
import axios from 'axios'
import sweetAlertStyle from '../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'
import { CircularProgress } from '@material-ui/core'

var moment = require('moment')

const styles = {
    ...sweetAlertStyle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
    },
    modalImage: {
        width: '100%',
        border: '1px solid #ccc',
        padding: '5px',
        cursor: 'pointer'
    },
    leftAlignedHeader: { textAlign: 'left !important'},
    rightAlignedHeader: { textAlign: 'right !important'},
}

/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientList
 * ----------------------------------------------------------------------------
 */
class IdentificationList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            entity_list: [],
            isModalOpen: false,
            alert: null,
            documentImages: [],
            imageLoading: false
        }
    }

    componentDidMount() {
        const { fetchIdentificationList } = this.props
        // console.log(this.props);
        fetchIdentificationList(this.props.client_id)
    }

    hideAlert = () => { this.setState({ alert: null })};

    buildTableData() {
        // console.log(this.props);
        var identification_list_length = this.props.identification
            .identification_list.length
        if (identification_list_length > 0) {
            return this.props.identification.identification_list.map(
                (prop, key)  => {

                    return {
                        id: prop.id,
                        client_contact_name: prop.client_contact_name,
                        nickname: prop.nickname,
                        file_id: prop.file_ref,
                        file_id_back: prop.file_ref_back,
                        client_name: prop.client_nickname,
                        document_category: prop.document_category,
                        document_number: prop.document_number,
                        document_expiry: prop.document_expiry,
                        id_status: prop.status_nickname,
                        attachments: prop.attachments,
                        actions: (
                            <div className="actions-right">
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        // let obj = this.props.staff.staff_list.find(o => o.aws_cognito_id === prop.aws_cognito_id);
                                        // this.props.setEntityDetailId(prop.id);
                                        // this.props.selectEntityUi("edit")
                                        // this.props.setBeneficiarySelectId(prop.id);
                                        this.props.history.push(
                                            `/identification/edit/${prop.id}`
                                        )
                                        // alert(
                                        //   "You've clicked EDIT button on \n{ \nName: " +
                                        //   obj.first_name +
                                        //   ", \nlast name: " +
                                        //   obj.last_name +
                                        //   ", \nemail: " +
                                        //   obj.email +
                                        //   "\n}."
                                        // );
                                    }}
                                    color="warning"
                                    className="edit"
                                >
                                    <Dvr />
                                </Button>{' '}
                            </div>
                        ),
                    }
                }
            )
            // console.log(data);
        }
    }

    async getUrl(file_id) {
        const url =  await Storage.get(file_id, { expires: 60 })
        const response = await axios.get(url);
        const mimeType = response.headers['content-type'];
        if (mimeType.startsWith('image/')) {
            return {fileType: 'image', url: url}
        } else if (mimeType === 'application/pdf') {
            return {fileType: 'pdf', url: url}
        }
    }

    openModal(url) {
        return this.setState({
            alert: <SweetAlert
                style={{ display: 'block', marginTop: '-100px' }}
                title={
                    <img
                        src={
                            url //default set to imagePreviewUrl
                        }
                        width="90%"
                    />
                }
                confirmBtnText="Close"
                onConfirm={() => this.hideAlert()}
                confirmBtnCssClass={
                    // eslint-disable-next-line react/prop-types
                    this.props.classes.button +
                    '  ' +
                    this.props.classes.info
                }
            />
        })
    }

    onCloseDialog = () => {
        this.setState({ isModalOpen: false });
    }

    toggleViewImageModal = (jsonAttachments) => {
        this.setState({documentImages: []});
        if (jsonAttachments != null) {
            const attachments = JSON.parse(jsonAttachments).ids || [];
            if (attachments.length > 0) this.setState({imageLoading: true});
            attachments.forEach(item => {
                API.get("identification", `/get/document/${item}`, {}).then(response => {
                    if (response != null) {
                        this.getUrl(response.file_ref).then(url =>  {
                            const images = this.state.documentImages;
                            images.push(url);
                            this.setState({documentImages: images});
                            this.setState({imageLoading: false});
                        })
                    } else {
                        this.setState({imageLoading: false});
                    }
                });
            })
        }
        this.setState({isModalOpen: true});
    }

    removeIdentification = (data) => {
        API.get("identification", `/remove/document/${data.id}`, {})
            .then(() => {
            this.props.fetchIdentificationList(this.props.client_id);
        }).catch(err => {
            console.log(err);
        })
    }

    render() {
        // console.log(this.props);

        const columns = this.props.forRegistration ? [
            {
                Header: 'Beneficiary',
                accessor: 'client_contact_name',
                headerClassName: this.props.classes.leftAlignedHeader,
                Cell: c => (
                    <React.Fragment>
                        <div style={{alignItems: 'center'}}>
                            <span>{c.value == null ? c.original.client_name : c.value}</span>
                        </div>
                    </React.Fragment>
                ),
                // filterMethod: (filter, rows) =>
                //     matchSorter(rows, filter.value, {
                //         keys: ['document_category'],
                //     }),
                // filterAll: true,
                // maxWidth: 200,
            },
            {
                Header: 'Category',
                accessor: 'document_category',
                headerClassName: this.props.classes.leftAlignedHeader,
                // filterMethod: (filter, rows) =>
                //     matchSorter(rows, filter.value, {
                //         keys: ['document_category'],
                //     }),
                // filterAll: true,
                // maxWidth: 200,
            },
            {
                Header: 'Notes',
                accessor: 'nickname',
                headerClassName: this.props.classes.leftAlignedHeader,
                // maxWidth: 200,
            },
            {
                Header: 'Document Number',
                accessor: 'document_number',
                headerClassName: this.props.classes.leftAlignedHeader,
                // maxWidth: 200,
            },
            {
                Header: 'Expiry',
                accessor: 'document_expiry',
                headerClassName: this.props.classes.leftAlignedHeader,
                Cell: c => (
                    <React.Fragment>
                        {c.value
                            ? moment(c.value).format('DD/MM/YYYY')
                            : ''}{' '}
                    </React.Fragment>
                ),
                // maxWidth: 150,
            },
            {
              Header: "Document",
              accessor: "file_id",
              headerClassName: this.props.classes.leftAlignedHeader,
              Cell: c => {
                return (
                <React.Fragment>
                    <Button variant={'outlined'} color={'primary'}
                        onClick={() => this.toggleViewImageModal(c.original.attachments)}><i className={'fa fa-eye'}></i></Button>
                    <Button variant={'outlined'} color={'secondary'}
                            onClick={() => this.removeIdentification(c.original)}><i className={'fa fa-trash'}></i></Button>
                    {/*<button */}
                    {/*    disabled={!c.original.file_id_back}*/}
                    {/*    onClick={() => {*/}
                    {/*    this.getUrl(c.original.file_id_back).then(url => { window.open(url) })*/}
                    {/*}}>View Back</button>*/}
                </React.Fragment>
            )},
            },
            // {
            //     Header: 'Actions',
            //     accessor: 'actions',
            //     sortable: false,
            //     filterable: false,
            // },
        ] : [
            {
                Header: 'Client ',
                accessor: 'client_name',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                        keys: ['client_name'],
                    }),
                filterAll: true,
                maxWidth: 150,
            },
            {
                Header: 'Document Nickname',
                accessor: 'nickname',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                        keys: ['nickname'],
                    }),
                filterAll: true,
                maxWidth: 200,
            },
            {
                Header: 'Document Category',
                accessor: 'document_category',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                        keys: ['document_category'],
                    }),
                filterAll: true,
                maxWidth: 200,
            },
            {
                Header: 'Document Number',
                accessor: 'document_number',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                        keys: ['document_number'],
                    }),
                filterAll: true,
                maxWidth: 200,
            },
            {
                Header: 'Document Expiry',
                accessor: 'document_expiry',
                Cell: c => (
                    <React.Fragment>
                        {c.value
                            ? moment(c.value).format('DD/MM/YYYY')
                            : ''}{' '}
                    </React.Fragment>
                ),
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                        keys: ['document_expiry'],
                    }),
                filterAll: true,
                maxWidth: 150,
            },
            // {
            //   Header: "File Identifier",
            //   accessor: "file_id",
            //   filterMethod: (filter, rows) =>
            //     matchSorter(rows, filter.value, { keys: ["file_id"] }),
            //   filterAll: true,
            //   maxWidth: 150
            // },
            {
                Header: 'Status',
                accessor: 'id_status',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                        keys: ['status_nickname'],
                    }),
                filterAll: true,
                maxWidth: 150,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sortable: false,
                filterable: false,
            },
        ]

        const data = this.buildTableData()
        // console.log(data);

        return (
                <React.Fragment>
                    <ReactTable
                        // data={this.state.entity_list}
                        data={data}
                        filterable={!this.props.forRegistration}
                        columns={columns}
                        sortable={!this.props.forRegistration}
                        defaultSorted={[
                            {
                                id: 'client_name',
                                desc: true,
                            },
                            {
                                id: 'document_category',
                                desc: true,
                            },
                            {
                                id: 'nickname',
                                desc: true,
                            },
                        ]}
                        showPageSizeOptions={this.props.forRegistration ? false : true}
                        defaultPageSize={this.props.forRegistration ? 5 : 10}
                        showPaginationTop={this.props.forRegistration ? false : true}
                        showPaginationBottom={this.props.forRegistration ? true : false}
                        className="-striped -highlight"
                    />
                    <Dialog maxWidth={'lg'} open={this.state.isModalOpen} onClose={(event, reason) => this.onCloseDialog(event, reason)}>
                        <DialogTitle id="form-dialog-title">Documents List</DialogTitle>
                        <DialogContent style={{ minWidth: '1000px' }}>
                            <GridContainer>
                                {
                                    this.state.documentImages.length > 0 ? this.state.documentImages.map((item, index) => {
                                        return <GridItem xs={3} sm={3}>
                                            {
                                                    <figure onClick={() => item.fileType === 'pdf' ? window.open(item.url, '_blank') : this.openModal(item.url)}>
                                                        <img className={this.props.classes.modalImage} height="150"
                                                             src={item.fileType === 'pdf' ? 'https://static.vecteezy.com/system/resources/previews/017/196/581/original/pdf-icon-on-transparent-background-free-png.png' : item.url}
                                                             alt={index} />
                                                    </figure>
                                            }
                                                </GridItem>
                                    }) : (this.state.imageLoading ? <CircularProgress /> : 'No Document Found')
                                }
                            </GridContainer>
                        </DialogContent>
                    </Dialog>
                    {this.state.alert}
                </React.Fragment>
            )
    }
}

export default withRouter(withStyles(styles)(IdentificationList))
