import {
    BENEFICIARIES_SELECT_SET_ID,
    IDENTIFICATION_SELECT_UI,
    IDENTIFICATION_FETCH_IDENTIFICATION_REQUEST,
    IDENTIFICATION_FETCH_IDENTIFICATION_SUCCESS,
    IDENTIFICATION_FETCH_BENEFICIARY_LIST_REQUEST,
    IDENTIFICATION_FETCH_IDENTIFICATION_LIST_SUCCESS,
    IDENTIFICATION_START_NEW_IDENTIFICATION_CREATION,
    IDENTIFICATION_UPDATE_NEW_IDENTIFICATION_CREATION,
    IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_SUCCESS,
} from '../actions/identification.js'

const initialState = {
    identification_current_ui: 'list',
    is_fetching: false,
    identification_list: [],
    identification_list_lastUpdated: [],
    identification_record_detail_fetching: false,
    identification_record_detail_loaded: false,
    identification_record_detail_id: null,
    new_identification_record_data: null,
    new_identification_record_submitted: false,
    new_identification_record_created: false,
}

function identification(state = initialState, action) {
    switch (action.type) {
        case IDENTIFICATION_FETCH_BENEFICIARY_LIST_REQUEST:
            return Object.assign({}, state, {
                is_fetching: true,
            })

        case IDENTIFICATION_FETCH_IDENTIFICATION_LIST_SUCCESS:
            return Object.assign({}, state, {
                is_fetching: false,
                identification_list: action.identification_list,
                identification_list_lastUpdated: action.receivedAt,
            })

        case IDENTIFICATION_SELECT_UI:
            return Object.assign({}, state, {
                identification_current_ui: action.value,
            })

        case BENEFICIARIES_SELECT_SET_ID:
            return Object.assign({}, state, {
                beneficiary_detail_id: action.value,
            })

        case IDENTIFICATION_FETCH_IDENTIFICATION_REQUEST:
            return Object.assign({}, state, {
                identification_record_detail_fetching: true,
                identification_record_detail_loaded: false,
            })

        case IDENTIFICATION_FETCH_IDENTIFICATION_SUCCESS:
            return Object.assign({}, state, {
                identification_record_detail_fetching: false,
                identification_record_detail_loaded: true,
            })

        case IDENTIFICATION_START_NEW_IDENTIFICATION_CREATION:
            return Object.assign({}, state, {
                new_identification_record_data: {},
            })
        case IDENTIFICATION_UPDATE_NEW_IDENTIFICATION_CREATION:
            return Object.assign({}, state, {
                new_identification_record_data: Object.assign(
                    {},
                    state.new_identification_record_data,
                    {
                        [action.value.key]: action.value.value,
                    }
                ),
            })

        case IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_SUCCESS:
            return Object.assign({}, state, {
                new_identification_record_submitted: false,
                new_identification_record_created: true,
                identification_record_detail_id: action.value,
            })

        default:
            return state
    }
}

export default identification
