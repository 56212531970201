import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import {
    fetchTransferList,
    setTransferSelectId,
    selectTransferUi,
} from '../../../redux/actions/transfers'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import Card from '../../../components/Card/Card.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'

// import clientsNewStyles from "./ClientsNewStyles";
import CardHeader from '../../../components/Card/CardHeader'
import CardIcon from '../../../components/Card/CardIcon'
import PermIdentity from '@material-ui/icons/PermIdentity'

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react.jsx'
import Button from '../../../components/CustomButtons/Button'
// import TransfersEditContainer from "../../../components/Transfers/TransferEditContainer";
import TransfersEditContainer from '../../../components/Transfers/TransferEdit'

import { FormattedMessage } from 'react-intl'

const styles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },

    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}

/**
 * ----------------------------------------------------------------------------
 * CLASS - TransfersEdit
 * ----------------------------------------------------------------------------
 */
class TransfersEdit extends React.Component {
    // constructor(props) {
    //   super(props);
    // }

    render() {
        // console.log(this.props);
        const { classes } = this.props
        return (
            <div>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <PermIdentity />
                        </CardIcon>
                        <div className={classes.flexEnd}>
                            <h4 className={classes.cardIconTitle}>
                                <FormattedMessage
                                    id="editTransfers.title"
                                    defaultMessage={`Transfer Management Functions`}
                                />
                            </h4>
                            <div>
                                <Button
                                    round
                                    color="info"
                                    onClick={() =>
                                        this.props.history.push(
                                            '/transfers/list'
                                        )
                                    }
                                >
                                    <FormattedMessage
                                        id="newTransfer.cardItem1.showAll"
                                        defaultMessage={`Show All`}
                                    />
                                </Button>
                                <Button round color="primary">
                                    <FormattedMessage
                                        id="editTransfers.showRecord"
                                        defaultMessage={`Show Record`}
                                    />
                                </Button>
                                {/* Take this one out in version 1150, Issue #904 by Tom
                //<Button
                //   round
                //   color="info"
                //   //onClick={() => this.props.history.push("/transfers/new")} //#Bug fix #612
                //   onClick={() => this.props.history.push("/payment/new/")}
                // >
                //   <FormattedMessage
                //     id="newTransfer.cardItem1.createNew"
                //     defaultMessage={`Create New`}
                //   />
                </Button>*/}
                                <Button
                                    round
                                    color="info"
                                    disabled
                                    // onClick={() => this.props.fetchTransferList()}
                                >
                                    <FormattedMessage
                                        id="newTransfer.cardItem1.refresh"
                                        defaultMessage={`Refresh`}
                                    />
                                </Button>
                            </div>
                        </div>
                        {/* <h4 className={classes.cardIconTitle}>
              Transfer Management Functions
            </h4> */}
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12}>
                                {/* <TransfersEditContainer transfer_detail_id={this.props.transfers.transfer_detail_id}/> */}
                                <TransfersEditContainer
                                    transfer_detail_id={
                                        this.props.match.params.transfer_id
                                    }
                                    {...this.props}
                                />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

TransfersEdit.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers: state.transfers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchTransferList: () => {
            dispatch(fetchTransferList())
        },
        setTransferSelectId: id => {
            dispatch(setTransferSelectId(id))
        },
        selectTransferUi: data => {
            dispatch(selectTransferUi(data))
        },
    }
}

const TransfersEditPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(TransfersEdit))

export default TransfersEditPageContainer

// export default withStyles(styles)(TransfersEdit);
