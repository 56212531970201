import {
    CLIENTS_UPDATE_NEW_CLIENT_CREATION_EMAIL,
    CLIENTS_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME,
    CLIENTS_UPDATE_NEW_CLIENT_CREATION_LASTNAME,
    STAFF_SELECT_STAFF_MEMBER,
    TRANSFERS_FETCH_TRANSFER_LIST_REQUEST,
    TRANSFERS_FETCH_TRANSFER_LIST_SUCCESS,
    TRANSFERS_FETCH_TRANSFER_REQUEST,
    TRANSFERS_FETCH_TRANSFER_SUCCESS,
    TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_REQUEST,
    TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_SUCCESS,
    TRANSFERS_SELECT_SET_ID,
    TRANSFERS_SELECT_UI,
    TRANSFERS_START_NEW_TRANSFER_CREATION,
    TRANSFERS_UPDATE_NEW_TRANSFER_CREATION,
    TRANSFERS_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE,
} from '../actions/transfers.js'

const initialState = {
    transfer_current_ui: 'list',
    is_fetching: false,
    did_invalidate: false,
    transfer_list: [],
    transfer_detail_fetching: false,
    transfer_detail_loaded: false,
    transfer_detail_id: null,
    new_transfer_data: {},
    new_transfer_type: null,
    new_transfer_submitted: false,
    new_transfer_created: false,
}

function clients(state = initialState, action) {
    switch (action.type) {
        case STAFF_SELECT_STAFF_MEMBER:
            return Object.assign({}, state, {
                staff_detail_id: action.staff_id,
            })

        case TRANSFERS_FETCH_TRANSFER_LIST_REQUEST:
            return Object.assign({}, state, {
                is_fetching: true,
            })

        case TRANSFERS_FETCH_TRANSFER_LIST_SUCCESS:
            return Object.assign({}, state, {
                is_fetching: false,
                transfer_list: action.transfer_list,
                lastUpdated: action.receivedAt,
            })

        case TRANSFERS_SELECT_UI:
            return Object.assign({}, state, {
                transfer_current_ui: action.value,
            })

        case TRANSFERS_SELECT_SET_ID:
            return Object.assign({}, state, {
                transfer_detail_id: action.value,
            })

        case TRANSFERS_FETCH_TRANSFER_REQUEST:
            return Object.assign({}, state, {
                transfer_detail_fetching: true,
                transfer_detail_loaded: false,
            })

        case TRANSFERS_FETCH_TRANSFER_SUCCESS:
            return Object.assign({}, state, {
                transfer_detail_fetching: false,
                transfer_detail_loaded: true,
            })

        case TRANSFERS_START_NEW_TRANSFER_CREATION:
            return Object.assign({}, state, {
                new_transfer_submitted: false,
            })

        case TRANSFERS_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE:
            return Object.assign({}, state, {
                new_transfer_type: action.value,
            })

        case CLIENTS_UPDATE_NEW_CLIENT_CREATION_EMAIL:
            return Object.assign({}, state, {
                new_client_data: Object.assign({}, state.new_client_data, {
                    email: action.value,
                }),
            })

        case CLIENTS_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME:
            return Object.assign({}, state, {
                new_client_data: Object.assign({}, state.new_client_data, {
                    first_name: action.value,
                }),
            })

        case CLIENTS_UPDATE_NEW_CLIENT_CREATION_LASTNAME:
            return Object.assign({}, state, {
                new_client_data: Object.assign({}, state.new_client_data, {
                    last_name: action.value,
                }),
            })

        case TRANSFERS_UPDATE_NEW_TRANSFER_CREATION:
            return Object.assign({}, state, {
                new_transfer_data: Object.assign({}, state.new_transfer_data, {
                    [action.value.key]: action.value.value,
                }),
            })

        case TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_REQUEST:
            return Object.assign({}, state, {
                new_transfer_submitted: true,
                new_transfer_created: false,
            })

        case TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_SUCCESS:
            return Object.assign({}, state, {
                new_transfer_submitted: false,
                new_transfer_data: {},
                new_transfer_created: true,
                transfer_detail_id: action.value,
            })

        default:
            return state
    }
}

export default clients
