import {useLayoutEffect, useRef} from "react";
import {Select, Form, Input, Typography} from "antd";
import React from 'react';
import TypographyCurrency from "./TypographyCurrency";

const validateAmount = (rule, value) => {

    return Promise.resolve();
};
const CurrencySelect = ({
                            state,
                            rightLabel,
                            fieldName,
                            fieldLabel,
                            currencyFieldName,
                            currencyFieldLabel,
                            onCurrencyChange,
                            validated = false,
                            disabled = false
                        }) => {

    const inputNumberRef = useRef(null);

    useLayoutEffect(() => {
        if (inputNumberRef.current) {
            const inputElement = inputNumberRef.current.input;
            if (inputElement) {
                inputElement.style.textAlign = 'right';
            }
        }
    }, [inputNumberRef]);

    return <>
        <span style={{border: 0, width: '100%'}}>
            <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                {
                    rightLabel && <div style={{ display: 'flex', justifyContent: 'start'}}>
                        <Typography.Text strong type={'danger'}
                                         style={{fontSize: '12px', paddingRight: '5px'}}>{rightLabel}</Typography.Text>
                    </div>
                }
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
            <div style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '5px'
            }}>
                <Form.Item shouldUpdate={true}
                           name={currencyFieldName}
                           label={currencyFieldLabel}
                           rules={[{required: validated, message: `Field is required`}]}
                           style={{margin: 0, padding: 0}}>
                    <Select size={'large'}
                            optionFilterProp={'alt'}
                            showSearch={true}
                            style={{minWidth: '100px'}}
                            onChange={(val) => onCurrencyChange ? onCurrencyChange(currencyFieldName, val) : null}
                            options={state.currencies.map(i => ({
                                ...i,
                                value: i.id,
                                alt: i.iso_alpha_3,
                                label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.iso_alpha_3}</TypographyCurrency>
                            }))}></Select>
                </Form.Item>
                <div style={{width: '100%'}}>
                    <Form.Item shouldUpdate={true} style={{margin: 0, padding: 0, textAlign: 'right'}} name={fieldName}
                               label={fieldLabel} rules={[
                        {required: validated, message: ''},
                        {
                            validator: (_, value) => {
                                if (Number(value) === 0) {
                                    return Promise.reject('Enter valid amount');
                                }
                                if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                                    return Promise.reject('Please enter a valid number with up to 2 decimal places');
                                }
                                if (value && parseFloat(value) === 0) {
                                    return Promise.reject('Amount cannot be zero');
                                }
                                return Promise.resolve();
                            },
                        }
                    ]}>
                        <Input disabled={disabled} ref={inputNumberRef} style={{width: '100%', textAlign: 'right'}}
                               size={'large'}/>
                    </Form.Item>
                </div>
            </div>
        </div>
        </span>
    </>
}

export default CurrencySelect;