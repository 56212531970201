import React from 'react'
import PropTypes from 'prop-types'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    withStyles,
} from '@material-ui/core'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow)

const DynamicTable = props => {
    const { className, headers, rows } = props

    return (
        <TableContainer>
            <Table
                className={className}
                size="small"
                aria-label="upload details table"
            >
                <TableHead>
                    <TableRow>
                        {headers.map((headerField, index) => (
                            <StyledTableCell key={index}>
                                {headerField}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <StyledTableRow key={index}>
                            {row.map((bodyField, index) => (
                                <StyledTableCell key={index}>
                                    {bodyField}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

DynamicTable.propTypes = {
    className: PropTypes.string,
    headers: PropTypes.arrayOf(PropTypes.any).isRequired,
    rows: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default DynamicTable
