import React from 'react'
import { connect } from 'react-redux'
// import { onLoginSuccess } from "../../redux/actions";
// import Link from onLoginSuccess../components/Link'
// import UserProfile from "./UserProfile";
import axios from 'axios'

import Datetime from 'react-datetime'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from '@material-ui/icons/PermIdentity'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import Clearfix from 'components/Clearfix/Clearfix.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardAvatar from 'components/Card/CardAvatar.jsx'
import Table from 'components/Table/Table.jsx'
import NavPills from 'components/NavPills/NavPills.jsx'

import { API } from 'aws-amplify'
import { Auth } from 'aws-amplify'

// import styles from "./UserProfileStyles";
import avatar from '../../assets/img/faces/blank-profile-picture.jpg'
// import PictureUpload from "../../components/CustomUpload/PictureUpload";

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ChangeEmailDialog from './UserProfile/ChangeEmailDialog.jsx'
// import { Grid } from "@material-ui/core";

// import ReactTable from "react-table";
// import {matchSorter} from "match-sorter";
// import { isNull } from "util";

// For CustomReactSelect
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx'

//core for react-intl
import { FormattedMessage } from 'react-intl'
// import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import { cardTitle } from '../../assets/jss/material-dashboard-pro-react.jsx'
import ListItem from '@material-ui/core/ListItem'
import { NavLink } from 'react-router-dom'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'

var moment = require('moment')

require('react-datetime')

const styles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
}

class MigrateFromBackendPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            external_reference: '',
            migration_01: false,
            migration_01_detail: '',
            migration_02: false,
            migration_02_detail: '',
            migration_03: false,
            migration_03_detail: '',
            migration_04: false,
            migration_04_detail: '',
            migration_05: false,
            migration_05_detail: '',
        }
    }

    async componentDidMount() {
        // console.log("componentDidMount");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    async componentWillReceiveProps(nextProps, nextContext) {
        if (!nextProps.app_state.current_client) return
        if (!nextProps.app_state.current_team) return
    }
    async saveUserProfile(user) {
        // console.log(user);
        return API.put(
            'portal',
            `/users/update/${
                this.props.app_state.current_client_aws_cognito_id
            }`,
            {
                body: user,
            }
        )
    }

    async getUserProfile(aws_cognito_id) {
        return API.get('portal', `/users/get-by-id/${aws_cognito_id}`)
    }

    async run_migration() {
        if (
            this.props.isAuthenticated &&
            this.props.current_user &&
            !this.state.migration_01
        ) {
            this.setState({
                migration_01: true,
            })

            try {
                const user = await this.getUserProfile(
                    this.props.current_user.aws_cognito_id
                )
                const { aws_cognito_id, email, first_name, last_name } = user

                this.setState({
                    aws_cognito_id,
                    email,
                    first_name,
                    last_name,
                })

                let migrating_from

                this.setState({
                    migration_02: true,
                    migration_02_detail: (
                        <span>Commencing client migration process</span>
                    ),
                })

                console.log('Migrate: Checking email', email)
                this.setState({
                    migration_03: true,
                    migration_03_detail: (
                        <span>Checking Forex Sport databases for {email}</span>
                    ),
                })

                let clients_check_fx = await this.clients_check_fx(email)
                console.log(clients_check_fx)
                this.setState({
                    migration_03_detail: (
                        <span>
                            Checking Forex Sport databases for {email}
                            <br />
                            Located: {
                                clients_check_fx.response.record_count
                            }{' '}
                            record(s)
                        </span>
                    ),
                })

                let clients_check_fww = await this.clients_check_fww(email)
                console.log(clients_check_fww)
                this.setState({
                    migration_03_detail: (
                        <span>
                            Checking Forex Sport databases for {email}
                            <br />
                            Located: {
                                clients_check_fx.response.record_count
                            }{' '}
                            record(s)
                            <br />
                            Checking Forex WorldWide databases for {email}
                            <br />
                            Located: {
                                clients_check_fww.response.record_count
                            }{' '}
                            record(s)
                        </span>
                    ),
                })
                console.log('Migrate: Checking email complete')

                if (
                    clients_check_fx.response.record_count > 0 &&
                    clients_check_fww.response.record_count > 0
                ) {
                    console.log(
                        'Migrate: Record located in BOTH FX and FWW',
                        email
                    )
                    this.setState({
                        migration_03_detail: (
                            <span>
                                Checking Forex Sport databases for {email}
                                <br />
                                Located:{' '}
                                {clients_check_fx.response.record_count}{' '}
                                record(s)
                                <br />
                                Checking Forex WorldWide databases for {email}
                                <br />
                                Located:{' '}
                                {clients_check_fww.response.record_count}{' '}
                                record(s)
                                <br />
                                Migration:{' '}
                                <strong>
                                    Located record in BOTH Forex Sport and Forex
                                    WorldWide databases - not proceeding
                                </strong>
                                <br />
                            </span>
                        ),
                    })
                } else if (clients_check_fx.response.record_count > 0) {
                    console.log('Migrate: Processing FX', email)
                    migrating_from = 'fx'
                    let client_migrate_fx = await this.client_migrate_fx(
                        email,
                        this.props.app_state.current_client.id,
                        this.props.app_state.current_team.id
                    )
                    console.log(client_migrate_fx)
                    this.setState({
                        migration_03_detail: (
                            <span>
                                Checking Forex Sport databases for {email}
                                <br />
                                Located:{' '}
                                {clients_check_fx.response.record_count}{' '}
                                record(s)
                                <br />
                                Checking Forex WorldWide databases for {email}
                                <br />
                                Located:{' '}
                                {clients_check_fww.response.record_count}{' '}
                                record(s)
                                <br />
                                Migrated: record for{' '}
                                {client_migrate_fx.response.first_name}{' '}
                                {client_migrate_fx.response.last_name}
                                <br />
                            </span>
                        ),
                    })
                    console.log('Migrate: Processing FX Complete')
                } else if (clients_check_fww.response.record_count > 0) {
                    migrating_from = 'fww'
                    console.log('Migrate: Processing FWW', email)
                    let client_migrate_fww = await this.client_migrate_fww(
                        email,
                        this.props.app_state.current_client.id,
                        this.props.app_state.current_team.id
                    )
                    console.log(client_migrate_fww)
                    this.setState({
                        migration_03_detail: (
                            <span>
                                Checking Forex Sport databases for {email}
                                <br />
                                Located:{' '}
                                {clients_check_fx.response.record_count}{' '}
                                record(s)
                                <br />
                                Checking Forex WorldWide databases for {email}
                                <br />
                                Located:{' '}
                                {clients_check_fww.response.record_count}{' '}
                                record(s)
                                <br />
                                Migrated: record for{' '}
                                {client_migrate_fww.response.first_name}{' '}
                                {client_migrate_fww.response.last_name}
                                <br />
                            </span>
                        ),
                    })
                    console.log('Migrate: Processing FWW Complete')
                }

                console.log('Migrate: Starting Beneficiaries')
                this.setState({
                    migration_04: true,
                    migration_04_detail: (
                        <span>
                            Starting beneficiary import process
                            <br />
                        </span>
                    ),
                })

                let beneficiaries_migrate_fx
                let beneficiaries_migrate_fww
                if (migrating_from === 'fx') {
                    beneficiaries_migrate_fx = await this.beneficiaries_migrate_fx(
                        this.props.app_state.current_client.id,
                        email
                    )
                    console.log(beneficiaries_migrate_fx)
                    // this.setState({
                    //   migration_04: true,
                    //   migration_04_detail: <span>
                    //   Starting beneficiary import from Forex Sport<br/>
                    //   Imported {Object.keys(beneficiaries_migrate_fx.database_result).length} record(s)<br/>
                    // </span>
                    // });
                }

                if (migrating_from === 'fww') {
                    beneficiaries_migrate_fww = await this.beneficiaries_migrate_fww(
                        this.props.app_state.current_client.id,
                        email
                    )
                    console.log(beneficiaries_migrate_fww)
                    // this.setState({
                    //   migration_04: true,
                    //   migration_04_detail: <span>
                    //   Starting beneficiary import from Forex Sport<br/>
                    //   Imported {Object.keys(beneficiaries_migrate_fx.database_result).length} record(s)<br/>
                    //   Starting beneficiary import from Forex WorldWide<br/>
                    //   Imported {Object.keys(beneficiaries_migrate_fww.database_result).length} record(s)<br/>
                    // </span>
                    // });
                }

                this.setState({
                    migration_04: true,
                    migration_04_detail: (
                        <span>
                            Starting beneficiary import from Forex Sport
                            <br />
                            Imported{' '}
                            {
                                Object.keys(
                                    beneficiaries_migrate_fx
                                        ? beneficiaries_migrate_fx.database_result
                                        : {}
                                ).length
                            }{' '}
                            record(s)
                            <br />
                            Starting beneficiary import from Forex WorldWide
                            <br />
                            Imported{' '}
                            {
                                Object.keys(
                                    beneficiaries_migrate_fww
                                        ? beneficiaries_migrate_fww.database_result
                                        : {}
                                ).length
                            }{' '}
                            record(s)
                            <br />
                        </span>
                    ),
                })

                console.log('Migrate: Beneficiary Import Complete')

                console.log('Migrate: Transfers - starting')
                let transfers_migrate_fx
                let transfers_migrate_fww

                if (migrating_from === 'fx') {
                    transfers_migrate_fx = await this.transfers_migrate_fx(
                        this.props.app_state.current_client.id,
                        email
                    )
                    console.log(transfers_migrate_fx)
                }

                if (migrating_from === 'fww') {
                    transfers_migrate_fww = await this.transfers_migrate_fww(
                        this.props.app_state.current_client.id,
                        email
                    )
                    console.log(transfers_migrate_fww)
                }

                this.setState({
                    migration_05: true,
                    migration_05_detail: (
                        <span>
                            Starting transfer import from Forex Sport
                            <br />
                            Imported{' '}
                            {
                                Object.keys(
                                    transfers_migrate_fx
                                        ? transfers_migrate_fx.database_result
                                        : {}
                                ).length
                            }{' '}
                            record(s)
                            <br />
                            Starting transfer import from Forex WorldWide
                            <br />
                            Imported{' '}
                            {
                                Object.keys(
                                    transfers_migrate_fww
                                        ? transfers_migrate_fww.database_result
                                        : {}
                                ).length
                            }{' '}
                            record(s)
                            <br />
                        </span>
                    ),
                })

                console.log('Migrate: Transfers - completed')
                try {
                    await this.saveUserProfile({
                        // aws_cognito_id: aws_cognito_id,
                        account_status: 2,
                        external_reference: this.state.external_reference,
                    })
                    // this.props.history.push("/");
                    // this.props.reload_user(this.state.aws_cognito_id);
                    window.location.href = '/'
                } catch (e) {
                    alert(e)
                    // this.setState({ isLoading: false });
                }
            } catch (e) {
                console.error(e)
            }
        }
    }

    async clients_check_fx(email) {
        return await API.post('clients', `/email_check_backend`, {
            body: {
                database: 'fx',
                email: email,
            },
        })
        // console.log(result)
    }

    async clients_check_fww(email) {
        return await API.post('clients', `/email_check_backend`, {
            body: {
                database: 'fww',
                email: email,
            },
        })
        // console.log(result)
    }

    async client_migrate_fx(email, client_id, team_id) {
        return await API.post('clients', `/migrate_backend`, {
            body: {
                email: email,
                database: 'fx',
                client_id,
                team_id,
            },
        })
        // console.log(result)
    }

    async client_migrate_fww(email, client_id, team_id) {
        return await API.post('clients', `/migrate_backend`, {
            body: {
                email: email,
                database: 'fww',
                client_id,
                team_id,
            },
        })
        // console.log(result)
    }

    async beneficiaries_migrate_fx(client_id, email) {
        return await API.post('beneficiaries', `/migrate_backend`, {
            body: {
                database: 'fx',
                client_id,
                email,
            },
        })
    }

    async beneficiaries_migrate_fww(client_id, email) {
        return await API.post('beneficiaries', `/migrate_backend`, {
            body: {
                database: 'fww',
                client_id,
                email,
            },
        })
    }

    async transfers_migrate_fx(client_id, email) {
        return await API.post('transfers', `/migrate_backend`, {
            body: {
                database: 'fx',
                client_id,
                email,
            },
        })
    }

    async transfers_migrate_fww(client_id, email) {
        return await API.post('transfers', `/migrate_backend`, {
            body: {
                database: 'fww',
                client_id,
                email,
            },
        })
    }

    // async saveUserProfile(user) {
    //   return API.put("portal", `/users/update/${this.state.aws_cognito_id}`, {
    //     body: user
    //   });
    // }

    compare(string1, string2) {
        if (string1 === string2) {
            return true
        }
        return false
    }

    is_empty(x) {
        return (
            typeof x == 'undefined' ||
            x == null ||
            x == false || //same as: !x
            x.length == 0 ||
            x == '' ||
            x.replace(/\s/g, '') == '' ||
            !/[^\s]/.test(x) ||
            /^\s*$/.test(x)
        )
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'email':
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + ' _state']: 'error' })
                }
                break
            case 'equalTo':
                if (
                    this.compare(
                        event.target.value,
                        this.state[stateNameEqualTo]
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
        // this.props.updateNewClientCreation(stateName, event.target.value)
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value,
        })
    }

    handleSelectChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    }

    // handleSubmit = async event => {
    //   event.preventDefault();
    //   this.setState({ isLoading: true });

    //   const {
    //     // aws_cognito_id,
    //     title,
    //     first_name,
    //     middle_name,
    //     last_name,
    //     // email,
    //     external_reference,
    //     residential_street_line_1,
    //     residential_street_line_2,
    //     residential_street_suburb,
    //     residential_street_state,
    //     residential_street_postcode,
    //     residential_street_country,
    //     postal_street_line_1,
    //     postal_street_line_2,
    //     postal_street_suburb,
    //     postal_street_state,
    //     postal_street_postcode,
    //     postal_street_country,
    //     telephone_home,
    //     telephone_mobile,
    //     telephone_work,
    //     email_secondary,
    //     language_id,
    //     date_of_birth,
    //     place_of_birth_city,
    //     place_of_birth_country,
    //     nationality,
    //     marital_status
    //   } = this.state;
    //   try {
    //     await this.saveUserProfile({
    //       // aws_cognito_id: aws_cognito_id,
    //       title: title,
    //       first_name: first_name,
    //       middle_name: middle_name,
    //       last_name: last_name,
    //       external_reference: external_reference,
    //       residential_street_line_1: residential_street_line_1,
    //       residential_street_line_2: residential_street_line_2,
    //       residential_street_suburb: residential_street_suburb,
    //       residential_street_state: residential_street_state,
    //       residential_street_postcode: residential_street_postcode,
    //       residential_street_country: residential_street_country,
    //       postal_street_line_1: postal_street_line_1,
    //       postal_street_line_2: postal_street_line_2,
    //       postal_street_suburb: postal_street_suburb,
    //       postal_street_state: postal_street_state,
    //       postal_street_postcode: postal_street_postcode,
    //       postal_street_country: postal_street_country,
    //       telephone_home: telephone_home,
    //       telephone_mobile: telephone_mobile,
    //       telephone_work: telephone_work,
    //       email_secondary: email_secondary,
    //       language_id: language_id,
    //       date_of_birth: date_of_birth,
    //       place_of_birth_city: place_of_birth_city,
    //       place_of_birth_country: place_of_birth_country,
    //       nationality: nationality,
    //       marital_status: marital_status
    //     });
    //     // this.props.history.push("/");
    //     this.props.reload_user(this.state.aws_cognito_id);
    //   } catch (e) {
    //     alert(e);
    //     // this.setState({ isLoading: false });
    //   }
    // };

    // For CustomReactSelect
    handleCustomReactSelectChange = name => value => {
        // console.log("handleCustomReactSelectChange name: " + name)
        // console.log("handleCustomReactSelectChange value: ")
        // console.log(value)
        if (value === null) {
            this.setState({
                [name]: null,
            })
        } else {
            this.setState({
                [name]: value.value,
            })
        }
    }

    // For Country CustomReactSelect
    handleCustomCountryReactSelectChange = name => value => {
        // console.log("handleCustomReactSelectChange name: " + name)
        // console.log("handleCustomReactSelectChange value: ")
        // console.log(value)
        if (value === null) {
        } else {
            // console.log(value.value);
            this.setState({
                [name]: value.value,
            })
        }
    }

    render() {
        const { classes } = this.props
        if (!this.props.app_state.current_client) {
            return null
        }

        if (this.props.app_state.current_client.account_status !== 6) {
            this.props.history.push('/')
        }

        // For CustomReactSelect. Generate select options for dropdown list.
        const title_select_options = [
            { label: 'Mr' },
            { label: 'Ms' },
            { label: 'Mrs' },
        ].map(item => ({
            value: item.label,
            label: item.label,
        }))
        const marital_select_options = [
            { id: 1, label: 'Single' },
            { id: 2, label: 'Married' },
            { id: 3, label: 'DeFacto' },
            { id: 4, label: 'Separated' },
            { id: 5, label: 'Divorced' },
            { id: 6, label: 'Not Disclosed' },
        ].map(item => ({
            value: item.id,
            label: item.label,
        }))
        // const language_select_options = this.state.language_id_list
        //   .sort((a, b) => a.nickname.localeCompare(b.nickname))
        //   .map(item => ({
        //     value: item.id,
        //     label: item.nickname
        //   }));
        // const country_list_select_options = this.state.country_list_prio
        //   .map(item => ({
        //     value: item.id,
        //     label: item.full_name
        //   }));
        // if (!this.props.isAuthenticated || !this.props.current_user || !this.props.app_state.current_client) {
        //   return <div>NO CURRENT USER</div>;
        // }

        return (
            <React.Fragment>
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                        <PermIdentity />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Migration
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6} md={4}>
                                            <h5>
                                                Welcome to the Forex Sport
                                                Dorman Portal
                                            </h5>
                                            <h6>Powered by PeletonPay</h6>
                                            <br />
                                            <p>
                                                Please enter your Dorman
                                                Reference number below and press
                                                "Proceed" to immediately
                                                commence using our new FX
                                                Platform.
                                            </p>
                                            <CustomInput
                                                labelText={
                                                    'Please enter your DORMAN reference number'
                                                }
                                                id="external_reference"
                                                disabled
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value:
                                                        this.state
                                                            .external_reference ||
                                                        '',
                                                    onChange: event => {
                                                        this.handleChange(event)
                                                    },
                                                }}
                                            />
                                            <Button
                                                color="primary"
                                                onClick={() =>
                                                    this.run_migration()
                                                }
                                            >
                                                Proceed
                                            </Button>
                                        </GridItem>
                                    </GridContainer>

                                    <hr />
                                    <List>
                                        {this.state.migration_01 && (
                                            <ListItem>
                                                <ListItemText>
                                                    1. Migration in process
                                                    <br />
                                                    {
                                                        this.state
                                                            .migration_01_detail
                                                    }
                                                </ListItemText>
                                            </ListItem>
                                        )}
                                        {this.state.migration_02 && (
                                            <ListItem>
                                                <ListItemText>
                                                    2. Migrate client - start
                                                    <br />
                                                    {
                                                        this.state
                                                            .migration_02_detail
                                                    }
                                                </ListItemText>
                                            </ListItem>
                                        )}
                                        {this.state.migration_03 && (
                                            <ListItem>
                                                <ListItemText>
                                                    3. Migrate beneficiaries -
                                                    checking Forex Sport & Forex
                                                    WorldWide
                                                    <br />
                                                    {
                                                        this.state
                                                            .migration_03_detail
                                                    }
                                                </ListItemText>
                                            </ListItem>
                                        )}
                                        {this.state.migration_04 && (
                                            <ListItem>
                                                <ListItemText>
                                                    4. Migrate client - checking
                                                    Forex WorldWide
                                                    <br />
                                                    {
                                                        this.state
                                                            .migration_04_detail
                                                    }
                                                </ListItemText>
                                            </ListItem>
                                        )}
                                        {this.state.migration_05 && (
                                            <ListItem>
                                                <ListItemText>
                                                    5. Migrate transfers -
                                                    checking Forex Sport & Forex
                                                    WorldWide
                                                    <br />
                                                    {
                                                        this.state
                                                            .migration_05_detail
                                                    }
                                                </ListItemText>
                                            </ListItem>
                                        )}
                                    </List>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </React.Fragment>
        )
    }
}

// export default withStyles(userProfileStyles)(MigrateFromBackendPage);

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

// const mapDispatchToProps = (dispatch) => {
// return {
//   onLoginSuccess: (user_id) => {
//     dispatch(onLoginSuccess(user_id));
//   }
// };
// };

const MigrateFromBackendPageContainer = connect(
    mapStateToProps
    // mapDispatchToProps
)(withStyles(styles)(MigrateFromBackendPage))

export default MigrateFromBackendPageContainer
