import { connect } from 'react-redux'
import LanguageSelection from './LanguageSelection'
import { selectLanguageUi } from '../../redux/actions/language'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectLanguageUi: data => {
            dispatch(selectLanguageUi(data))
        },
    }
}

const LanguageSelectionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LanguageSelection)

export default LanguageSelectionContainer
