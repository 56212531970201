import {
    FETCH_FORWARD_LIST_REQUEST,
    FETCH_FORWARD_LIST_SUCCESS,
    FETCH_FORWARD_LIST_FAILURE,
    FETCH_FORWARD_DETAIL_REQUEST,
    FETCH_FORWARD_DETAIL_SUCCESS,
    FETCH_FORWARD_DETAIL_FAILURE,
    FETCH_FORWARD_DRAWDOWN_FAILURE,
    FETCH_FORWARD_DRAWDOWN_REQUEST,
    FETCH_FORWARD_DRAWDOWN_SUCCESS,
} from '../actions/forwards.js'

const initialState = {
    forwardList: [],
    forwardDetail: {},
    forwardDrawDown: [],
    fetching: false,
}

export default function forwards(state = initialState, action) {
    switch (action.type) {
        case FETCH_FORWARD_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
            }

        case FETCH_FORWARD_LIST_SUCCESS:
            return {
                ...state,
                fetching: false,
                forwardList: action.data,
            }

        case FETCH_FORWARD_LIST_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case FETCH_FORWARD_DETAIL_REQUEST:
            return {
                ...state,
                fetching: true,
            }

        case FETCH_FORWARD_DETAIL_SUCCESS:
            return {
                ...state,
                fetching: false,
                forwardDetail: action.data[0],
            }

        case FETCH_FORWARD_DETAIL_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case FETCH_FORWARD_DRAWDOWN_REQUEST:
            return {
                ...state,
                fetching: true,
            }

        case FETCH_FORWARD_DRAWDOWN_SUCCESS:
            return {
                ...state,
                fetching: false,
                forwardDrawDown: action.data,
            }

        case FETCH_FORWARD_DRAWDOWN_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        default:
            return state
    }
}
