import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { API } from 'aws-amplify'
import { FormattedNumber } from 'react-intl'
import ReactTable from 'react-table'
import { matchSorter } from 'match-sorter'
import withStyles from '@material-ui/core/styles/withStyles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Dvr from '@material-ui/icons/Dvr'
import Close from '@material-ui/icons/Close'
import moment from 'moment'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { AiOutlineSearch } from 'react-icons/ai'
import Card from '../Card/Card'
import CardBody from '../Card/CardBody'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import Button from '../CustomButtons/Button'

const style = theme => ({
    id: {
        color: '#0BACF4',
    },
    currencyContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    currency: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'fit-content',
        '& > .currency-flag': {
            marginRight: '0.5rem',
            width: 52,
            height: 26,
        },
    },
    currencyLabel: {
        textAlign: 'left',
        '& > p': {
            marginBottom: -5,
            fontSize: '0.6rem',
        },
    },
    progress: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > span': {
            marginLeft: 12,
            fontSize: 12,
            textTransform: 'uppercase',
        },
    },
    progressBar: {
        width: 50,
    },
})

const CurrencyElement = ({ classes, currency, value }) => (
    <div className={classes.currency}>
        <div
            className={`currency-flag currency-flag-${
                currency ? currency.toLowerCase() : ''
            }`}
        />
        <div className={classes.currencyLabel}>
            <p>{currency}</p>
            <FormattedNumber
                minimumFractionDigits={2}
                maximumFractionDigits={2}
                value={value}
            />
        </div>
    </div>
)

class CustomTransferList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            transfer_list: [],
            entity_list: [],
        }
    }

    componentDidMount() {
        API.get('transfers', `/get-list-not-deleted/${this.props.app_state.current_client.id}`)
            .then(response => {
                this.setState({
                    transfer_list: response,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidUpdate() {}

    handleClose = e => {
        window.location = '/transfers/edit/' + e
    }

    buildTableData() {
        if (typeof this.props.status === 'string') {
            let status_filter_transfer_list = this.state.transfer_list
            if (this.props.status === '8') {
                status_filter_transfer_list = status_filter_transfer_list.filter(
                    transfer => {
                        return transfer.status === 8 || transfer.status === 10
                    }
                )
            }

            if (this.props.status === '123456') {
                status_filter_transfer_list = status_filter_transfer_list.filter(
                    transfer => {
                        return transfer.status < 7 || transfer.status === 9
                    }
                )
            }

            const status_transfer_list_length =
                status_filter_transfer_list.length
            if (status_transfer_list_length > 0) {
                return status_filter_transfer_list.map((prop, key) => {
                    return {
                        id: prop.id,
                        client_fullname: prop.client_nickname,
                        transaction_date: prop.transaction_datetime,
                        memo: prop.memo,
                        nickname: prop.nickname,
                        settlement_date: prop.settlement_date,
                        beneficiary: prop.beneficiaries_nickname,
                        currency_from: `${prop.currency_from_iso_alpha_3}`,
                        amount_from: `${prop.amount_from}`,
                        currency_to: `${prop.currency_to_iso_alpha_3}`,
                        amount_to: `${prop.amount_to}`,
                        client_rate: prop.client_rate.toFixed(5),
                        transfer_status: prop.transfer_status,
                        transfer_status_name: prop.transfer_status_name,
                        status_date: moment(prop.status_date).format(
                            'DD/MM/YYYY'
                        ),
                        status_id: prop.status,
                        actions: (
                            <div className="actions-right">
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        this.props.history.push(
                                            `/transfers/edit/${prop.id}`
                                        )
                                    }}
                                    color="warning"
                                    className="edit"
                                >
                                    <Dvr />
                                </Button>{' '}
                                {this.props.app_state
                                    .current_staff_super_admin && (
                                    <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() => {
                                            var data = this.props.transfers
                                                .transfer_list
                                            data.find((o, i) => {
                                                if (o.id === prop.id) {
                                                    this.setState({
                                                        dialog_delete_confirm: true,
                                                        dialog_delete_item:
                                                            prop.id,
                                                        dialog_delete_item_name: `${
                                                            prop.nickname
                                                        }`,
                                                    })

                                                    return true
                                                }
                                                return false
                                            })
                                            this.setState({ data: data })
                                        }}
                                        color="danger"
                                        className="remove"
                                    >
                                        <Close />
                                    </Button>
                                )}{' '}
                            </div>
                        ),
                    }
                })
            }
        }

        const transfer_list_length = this.state.transfer_list.length
        if (transfer_list_length > 0) {
            return this.state.transfer_list.map((prop, key) => {
                return {
                    id: prop.id,
                    client_fullname: prop.client_nickname,
                    transaction_date: prop.transaction_datetime,
                    memo: prop.memo,
                    nickname: prop.nickname,
                    settlement_date: prop.settlement_date,
                    beneficiary: prop.beneficiaries_nickname,
                    currency_from: `${prop.currency_from_iso_alpha_3}`,
                    amount_from: `${prop.amount_from}`,
                    currency_to: `${prop.currency_to_iso_alpha_3}`,
                    amount_to: `${prop.amount_to}`,
                    client_rate: prop.client_rate.toFixed(5),
                    transfer_status: prop.transfer_status,
                    transfer_status_name: prop.transfer_status_name,
                    status_date: moment(prop.status_date).format('DD/MM/YYYY'),
                    status_id: prop.status,
                    actions: (
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    this.props.history.push(
                                        `/transfers/edit/${prop.id}`
                                    )
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Dvr />
                            </Button>
                            {this.props.app_state.current_staff_super_admin && (
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        var data = this.props.transfers
                                            .transfer_list
                                        data.find((o, i) => {
                                            if (o.id === prop.id) {
                                                this.setState({
                                                    dialog_delete_confirm: true,
                                                    dialog_delete_item: prop.id,
                                                    dialog_delete_item_name: `${
                                                        prop.nickname
                                                    }`,
                                                })

                                                return true
                                            }
                                            return false
                                        })
                                        this.setState({ data: data })
                                    }}
                                    color="danger"
                                    className="remove"
                                >
                                    <Close />
                                </Button>
                            )}
                        </div>
                    ),
                }
            })
        }
    }

    getSteps() {
        return [
            'Submitted',
            'Transfer acknowledged',
            'Funds received from client',
            'Payment made to Beneficiary',
            'Completed',
        ]
    }

    getSteps1() {
        return ['Submitted', 'Not Proceeding']
    }

    render() {
        const { classes } = this.props
        const steps = this.getSteps()
        const steps1 = this.getSteps1()

        const transfer_status = this.state.transfer_list.map((prop, key) => {
            return prop.transfer_status
        })
        const transfer_status_unique = [...new Set(transfer_status)]
        const transfer_status_optionlist = transfer_status_unique.map(
            (prop, key) => {
                return (
                    <option key={key} value={prop}>
                        {prop}
                    </option>
                )
            }
        )

        const getFilter = (filter, onChange) => {
            return (
                <Fragment>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <AiOutlineSearch />
                        <input
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                        />
                    </div>
                </Fragment>
            )
        }

        return (
            <div>
                <ReactTable
                    data={this.buildTableData()}
                    filterable
                    columns={[
                        {
                            Header: 'Id',
                            accessor: 'id',
                            Cell: c => (
                                <span className={classes.id}>{c.value}</span>
                            ),
                            Filter: ({ filter, onChange }) =>
                                getFilter(filter, onChange),
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                    keys: ['id'],
                                }),
                            filterAll: true,
                            maxWidth: 75,
                        },
                        {
                            Header: 'Transaction',
                            accessor: 'transaction_date',
                            Cell: c => (
                                <React.Fragment>
                                    {moment(c.value).format('DD/MM/YYYY')}
                                </React.Fragment>
                            ),
                            Filter: ({ filter, onChange }) =>
                                getFilter(filter, onChange),
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                    keys: ['transaction_date'],
                                }),
                            filterAll: true,
                        },
                        {
                            Header: 'Settlement',
                            accessor: 'settlement_date',
                            Cell: c => (
                                <React.Fragment>
                                    {moment(c.value).format('DD/MM/YYYY')}
                                </React.Fragment>
                            ),
                            Filter: ({ filter, onChange }) =>
                                getFilter(filter, onChange),
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                    keys: ['settlement_date'],
                                }),
                            filterAll: true,
                        },
                        {
                            Header: 'Beneficiary',
                            accessor: 'beneficiary',
                            Cell: c => (
                                <span>{c.value}</span>
                            ),
                            sortable: false,
                            filterable: false,
                        },
                        {
                            Header: 'From',
                            accessor: 'amount_from',
                            Cell: c => (
                                <CurrencyElement
                                    currency={c.original.currency_from}
                                    value={c.original.amount_from}
                                    classes={classes}
                                />
                            ),
                            sortable: false,
                            filterable: false,
                        },
                        {
                            Header: '',
                            sortable: false,
                            filterable: false,
                            width: 50,
                            Cell: c => <span>⮕</span>,
                        },
                        {
                            Header: 'To',
                            accessor: 'amount_from',
                            Cell: c => (
                                <CurrencyElement
                                    currency={c.original.currency_to}
                                    value={c.original.amount_to}
                                    classes={classes}
                                />
                            ),
                            sortable: false,
                            filterable: false,
                        },
                        {
                            Header: 'Rate',
                            accessor: 'client_rate',
                            Filter: ({ filter, onChange }) =>
                                getFilter(filter, onChange),
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                    keys: ['client_rate'],
                                }),
                            filterAll: true,
                        },
                        {
                            Header: 'Status ',
                            accessor: 'transfer_status',
                            Cell: c => (
                                <div className={classes.progress}>
                                    <CircularProgressbar
                                        className={classes.progressBar}
                                        value={
                                            c.value === 'In Process' ? 50 : 100
                                        }
                                        text={`${
                                            c.value === 'In Process' ? 50 : 100
                                        }%`}
                                        styles={buildStyles({
                                            textColor: `#000`,
                                            textSize: '30px',
                                            pathColor:
                                                c.value === 'In Process'
                                                    ? '#632DE6'
                                                    : c.value === 'Completed'
                                                        ? '#238636'
                                                        : '#FF0000',
                                        })}
                                    />
                                    <span>{c.value}</span>
                                </div>
                            ),
                            Filter: ({ filter, onChange }) =>
                                getFilter(filter, onChange),
                            filterMethod: (filter, row) => {
                                if (filter.value === 'all') return true
                                return row[filter.id] == filter.value
                            },
                            Filter: ({ filter, onChange }) => (
                                <select
                                    onChange={event =>
                                        onChange(event.target.value)
                                    }
                                    style={{ width: '100%' }}
                                    value={filter ? filter.value : 'all'}
                                >
                                    <option value="all">Show All</option>
                                    {transfer_status_optionlist}
                                </select>
                            ),
                        },
                        {
                            Header: '',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                            maxWidth: 80,
                        },
                    ]}
                    defaultSorted={[
                        {
                            id: 'transaction_date',
                            desc: true,
                        },
                        {
                            id: 'id',
                            desc: true,
                        },
                    ]}
                    defaultPageSize={10}
                    showPaginationBottom
                    className="-highlight"
                    SubComponent={v => {
                        let ActivTab = 0
                        if (v.original.status_id == 2) ActivTab = 0
                        if (v.original.status_id == 3) ActivTab = 1
                        if (v.original.status_id == 4) ActivTab = 1
                        if (v.original.status_id == 5) ActivTab = 1
                        if (v.original.status_id == 6) ActivTab = 2
                        if (v.original.status_id == 7) ActivTab = 3
                        if (v.original.status_id == 8) ActivTab = 4
                        if (v.original.status_id == 10) ActivTab = 1

                        return (
                            <Card>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={9}>
                                            {v.original.status_id != 10 && (
                                                <Stepper activeStep={ActivTab}>
                                                    {steps.map(
                                                        (label, index) => {
                                                            const stepProps = {}
                                                            const labelProps = {}

                                                            return (
                                                                <Step
                                                                    key={label}
                                                                    {...stepProps}
                                                                >
                                                                    <StepLabel
                                                                        {...labelProps}
                                                                    >
                                                                        {label}
                                                                    </StepLabel>
                                                                </Step>
                                                            )
                                                        }
                                                    )}
                                                </Stepper>
                                            )}
                                            {v.original.status_id == 10 && (
                                                <Stepper activeStep={ActivTab}>
                                                    {steps1.map(
                                                        (label, index) => {
                                                            const stepProps = {}
                                                            const labelProps = {}

                                                            return (
                                                                <Step
                                                                    key={label}
                                                                    {...stepProps}
                                                                >
                                                                    <StepLabel
                                                                        {...labelProps}
                                                                    >
                                                                        {label}
                                                                    </StepLabel>
                                                                </Step>
                                                            )
                                                        }
                                                    )}
                                                </Stepper>
                                            )}
                                        </GridItem>
                                        <GridItem xs={12} sm={3}>
                                            <h6>Actions</h6>
                                            <p>
                                                <Button
                                                    style={{ width: 200 }}
                                                    color="primary"
                                                    size="sm"
                                                    onClick={() => {
                                                        API.get(
                                                            'transfers',
                                                            `/get/id/${
                                                                v.original.id
                                                            }`
                                                        ).then(
                                                            transfer_detail => {
                                                                this.props.history.push(
                                                                    `/externalTransfer/${
                                                                        v
                                                                            .original
                                                                            .currency_from
                                                                    }-${
                                                                        v
                                                                            .original
                                                                            .currency_to
                                                                    }-${
                                                                        v
                                                                            .original
                                                                            .amount_from
                                                                    }-${
                                                                        v
                                                                            .original
                                                                            .amount_to
                                                                    }-${
                                                                        transfer_detail
                                                                            .fullList
                                                                            .beneficiary_id ===
                                                                        null
                                                                            ? ''
                                                                            : transfer_detail
                                                                                  .fullList
                                                                                  .beneficiary_id
                                                                    }-${
                                                                        transfer_detail
                                                                            .fullList
                                                                            .purpose_of_payment_id ===
                                                                        null
                                                                            ? ''
                                                                            : transfer_detail
                                                                                  .fullList
                                                                                  .purpose_of_payment_id
                                                                    }-${
                                                                        transfer_detail
                                                                            .fullList
                                                                            .purpose_of_payment_detail ===
                                                                        null
                                                                            ? ' '
                                                                            : transfer_detail
                                                                                  .fullList
                                                                                  .purpose_of_payment_detail
                                                                    }`
                                                                )
                                                            }
                                                        )
                                                    }}
                                                >
                                                    Copy to New Transfer
                                                </Button>
                                                <br />
                                                <Button
                                                    style={{ width: 200 }}
                                                    color="primary"
                                                    size="sm"
                                                    onClick={() => {
                                                        this.props.history.push(
                                                            `/transfers/edit/${
                                                                v.original.id
                                                            }`
                                                        )
                                                    }}
                                                    className="edit"
                                                >
                                                    View Transfer Details
                                                </Button>
                                                <br />
                                            </p>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        )
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
    }
}

const CustomTransferListContainer = connect(
    mapStateToProps,
    null
)(withRouter(withStyles(style)(CustomTransferList)))

export default CustomTransferListContainer
