import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import dashboardStyle from './DashboardPageStyle'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Clearfix from 'components/Clearfix/Clearfix.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import CardAvatar from 'components/Card/CardAvatar.jsx'
import PermIdentity from '@material-ui/icons/PermIdentity'
import Button from 'components/CustomButtons/Button.jsx'

import avatar from '../../assets/img/faces/blank-profile-picture.jpg'
import sendMoney from '../../assets/custom-icons/Icon-SendMoney.png'
import receiveMoney from '../../assets/custom-icons/Icon-ReceiveMoney.png'
import externalTransfer from '../../assets/custom-icons/Icon-ExternalTransfer.png'
import trackTransfer from '../../assets/custom-icons/Icon-TrackTransfer.png'
import pastTransfer from '../../assets/custom-icons/Icon-PastTransfer.png'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import {API} from "aws-amplify";
import CurrencyChip from "../../components/CurrencyChip/CurrencyChip";

class DashboardPage extends React.Component {

    constructor(props) {
        super(props);
    }
    state = {
        account_list: []
    }

    componentDidMount() {
        this.fetchHoldingAccounts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_client !== this.props.app_state.current_client) {
            this.fetchHoldingAccounts();
        }
    }

    fetchHoldingAccounts = async () => {
        if (!this.props.app_state.current_client) return []
        const currentClient = this.props.app_state.current_client.id;
        const response = await API.get('holding_accounts', `/get/all/v2/${currentClient}}`);
        console.log(response);
        const account_list = response.holding_account_list.map(record => ({
            currencies_short_name: record.currencies_short_name,
            balance: record.balance,
            url: `/holding-account?account=${record.id}`
        }));
        this.setState({account_list: account_list});
    }
    renderDashboardTopRow(from_code, to_code) {
        if (from_code && to_code) {
            return (
                <React.Fragment>
                    <GridItem md={6}>
                        <Card
                            onClick={() =>
                                window.location.replace(
                                    `/shortcut_transfer/${to_code}`
                                )
                            }
                        >
                            <CardBody>
                                <div
                                    style={{
                                        padding: 20,
                                        height: '25vh',
                                        textAlign: 'center',
                                    }}
                                >
                                    <CardAvatar
                                        // profile
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 25,
                                        }}
                                    >
                                        <img
                                            src={sendMoney}
                                            alt="..."
                                            style={{ height: 100 }}
                                        />
                                    </CardAvatar>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <h3>
                                                {
                                                    this.props.app_state
                                                        .current_team
                                                        .brand_send_to_description
                                                }
                                            </h3>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    style={{ width: '100%', fontSize: '1.3rem', fontWeight: 600 }}
                                    color="info"
                                    onClick={() =>
                                        window.location.replace(
                                            `/shortcut_transfer/${to_code}`
                                        )
                                    }
                                >
                                    {
                                        this.props.app_state.current_team
                                            .brand_send_to_description
                                    }
                                </Button>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem md={3}>
                        <Card
                            onClick={() =>
                                window.location.replace(
                                    `/shortcut_transfer/${from_code}`
                                )
                            }
                        >
                            <CardBody>
                                <div
                                    style={{
                                        padding: 20,
                                        height: '25vh',
                                        textAlign: 'center',
                                    }}
                                >
                                    <CardAvatar
                                        // profile
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 25,
                                        }}
                                    >
                                        <img
                                            src={receiveMoney}
                                            alt="..."
                                            style={{ height: 100 }}
                                        />
                                    </CardAvatar>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <h3>
                                                {
                                                    this.props.app_state
                                                        .current_team
                                                        .brand_send_from_description
                                                }
                                            </h3>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    style={{ width: '100%', fontSize: '1.3rem', fontWeight: 600 }}
                                    color="info"
                                    onClick={() =>
                                        window.location.replace(
                                            `/shortcut_transfer/${from_code}`
                                        )
                                    }
                                >
                                    {
                                        this.props.app_state.current_team
                                            .brand_send_from_description
                                    }
                                </Button>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem md={3}>
                        <Card
                            onClick={() =>
                                window.location.replace(`/externalTransfer`)
                            }
                        >
                            <CardBody>
                                <div
                                    style={{
                                        padding: 20,
                                        height: '25vh',
                                        textAlign: 'center',
                                    }}
                                >
                                    <CardAvatar
                                        // profile
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 25,
                                        }}
                                    >
                                        <img
                                            src={externalTransfer}
                                            alt="..."
                                            style={{ height: 100 }}
                                        />
                                    </CardAvatar>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <h3>Request FX Transfer</h3>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    style={{ width: '100%', fontSize: '1.3rem', fontWeight: 600 }}
                                    color="info"
                                    onClick={() =>
                                        window.location.replace(
                                            `/externalTransfer`
                                        )
                                    }
                                >
                                    Make FX Transfer
                                </Button>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </React.Fragment>
            )
            } else if (to_code) {
                return (
                    <React.Fragment>
                        <GridItem md={6}>
                            <Card
                                onClick={() =>
                                    window.location.replace(
                                        `/shortcut_transfer/${to_code}`
                                    )
                                }
                            >
                                <CardBody>
                                    <div
                                        style={{
                                            padding: 20,
                                            height: '25vh',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <CardAvatar
                                            // profile
                                            style={{
                                                marginTop: 0,
                                                marginBottom: 25,
                                            }}
                                        >
                                            <img
                                                src={sendMoney}
                                                alt="..."
                                                style={{ height: 100 }}
                                            />
                                        </CardAvatar>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <h3>
                                                    {
                                                        this.props.app_state
                                                            .current_team
                                                            .brand_send_to_description
                                                    }
                                                </h3>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <Button
                                        style={{ width: '100%', fontSize: '1.3rem', fontWeight: 600 }}
                                        color="info"
                                        onClick={() =>
                                            window.location.replace(
                                                `/shortcut_transfer/${to_code}`
                                            )
                                        }
                                    >
                                        {
                                            this.props.app_state.current_team
                                                .brand_send_to_description
                                        }
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem md={6}>
                            <Card
                                onClick={() =>
                                    window.location.replace(`/externalTransfer`)
                                }
                            >
                                <CardBody>
                                    <div
                                        style={{
                                            padding: 20,
                                            height: '25vh',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <CardAvatar
                                            // profile
                                            style={{
                                                marginTop: 0,
                                                marginBottom: 25,
                                            }}
                                        >
                                            <img
                                                src={externalTransfer}
                                                alt="..."
                                                style={{ height: 100 }}
                                            />
                                        </CardAvatar>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <h3>Request FX Transfer</h3>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <Button
                                        style={{ width: '100%', fontSize: '1.3rem', fontWeight: 600 }}
                                        color="info"
                                        onClick={() =>
                                            window.location.replace(
                                                `/externalTransfer`
                                            )
                                        }
                                    >
                                        Make FX Transfer
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </React.Fragment>
                )
            } else {
                return (
                <React.Fragment>
                    <GridItem md={6}>
                        <NavLink to='/externalTransfer'>
                        <Card
                            // onClick={() =>
                            //     window.location.replace(`/externalTransfer`)
                            // }
                        >
                            <CardBody>
                                <div
                                    style={{
                                        padding: 20,
                                        height: '25vh',
                                        textAlign: 'center',
                                    }}
                                >
                                    <CardAvatar
                                        // profile
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 25,
                                        }}
                                    >
                                        <img
                                            src={externalTransfer}
                                            alt="..."
                                            style={{ height: 100 }}
                                        />
                                    </CardAvatar>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <h3 style={{fontWeight: 800}}>
                                                Request A New FX Transfer
                                            </h3>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    style={{ width: '100%', fontSize: '1.3rem', fontWeight: 600 }}
                                    color="info"
                                    // onClick={() =>
                                    //     window.location.replace(
                                    //         `/externalTransfer`
                                    //         )
                                    //     }
                                        >
                                    Make FX Transfer
                                </Button>
                            </CardFooter>
                        </Card>
                            </NavLink>
                    </GridItem>
                    <GridItem md={6}>
                    <NavLink to='/beneficiaries/view-beneficiaries'>
                        <Card
                            // onClick={() =>
                            //     window.location.replace(
                            //         `/beneficiaries/view-beneficiaries`
                            //     )
                            // }
                        >
                            <CardBody>
                                <div
                                    style={{
                                        padding: 20,
                                        height: '25vh',
                                        textAlign: 'center',
                                    }}
                                >
                                    <CardAvatar
                                        // profile
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 25,
                                        }}
                                    >
                                        <img
                                            src={externalTransfer}
                                            alt="..."
                                            style={{ height: 100 }}
                                        />
                                    </CardAvatar>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <h3 style={{fontWeight: 800}}>
                                                Manage Beneficiaries
                                            </h3>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    style={{ width: '100%', fontSize: '1.3rem', fontWeight: 600 }}
                                    color="info"
                                    // onClick={() =>
                                    //     window.location.replace(
                                    //         `/beneficiaries/view-beneficiaries`
                                    //     )
                                    // }
                                >
                                    Manage Beneficiaries
                                </Button>
                            </CardFooter>
                        </Card>
                        </NavLink>
                    </GridItem>
                </React.Fragment>
            )
        }
    }

    render() {
        const { classes } = this.props

        // Pre Render Checks
        if (!this.props.app_state.current_team) {
            return null
        }
        if (!this.props.app_state.current_client) {
            return null
        }
        if (this.props.app_state.current_client.account_status == 1) {
            this.props.history.push('/pending-registration')
        }
        if (this.props.app_state.current_client.account_status == 5) {
            this.props.history.push('/registration')
        }

        if (this.props.app_state.current_client.account_status == 6) {
            this.props.history.push('/migrate')
        }

        let to_code
        let from_code
        if (
            this.props.app_state.current_team.brand_send_to_code &&
            this.props.app_state.current_team.brand_send_to_description
        ) {
            let to_reference = '-'
            switch (this.props.app_state.current_team.id) {
                case 24:
                    if (
                        this.props.app_state.current_client.external_reference
                    ) {
                        to_reference = `${this.props.app_state.current_client.external_reference
                            .toString()
                            .replace('-', ' ')}`
                    } else {
                        to_reference = ``
                    }
                    break
                default:
                    if (
                        this.props.app_state.current_client.external_reference
                    ) {
                        to_reference = `${this.props.app_state.current_client.external_reference
                            .toString()
                            .replace('-', ' ')}`
                    } else {
                        to_reference = ``
                    }
                    break
            }
            to_code = this.props.app_state.current_team.brand_send_to_code
                .toString()
                .replace('CLIENTREF', to_reference)
        }

        if (
            this.props.app_state.current_team.brand_send_from_code &&
            this.props.app_state.current_team.brand_send_from_description
        ) {
            let from_reference = '-'
            switch (this.props.app_state.current_team.id) {
                case 24:
                    if (
                        this.props.app_state.current_client.external_reference
                    ) {
                        from_reference = `${this.props.app_state.current_client.external_reference
                            .toString()
                            .replace('-', ' ')}`
                    } else {
                        from_reference = ``
                    }
                    break
                default:
                    if (
                        this.props.app_state.current_client.external_reference
                    ) {
                        from_reference = `${this.props.app_state.current_client.external_reference
                            .toString()
                            .replace('-', ' ')}`
                    } else {
                        from_reference = ``
                    }
                    break
            }
            from_code = this.props.app_state.current_team.brand_send_from_code
                .toString()
                .replace('CLIENTREF', from_reference)
        }

        return (
            <React.Fragment>
                <GridContainer style={{paddingTop: 50}}>
                    <GridItem md={12}>
                        {this.props.app_state.current_team.brand_sidebar_ledger === 1 && this.props.app_state.current_client.feature_ledger === 1 && <div style={{
                            borderRadius: '5px',
                            width: '100%',
                            background: '#fff',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            paddingRight: '10px',
                            height: '50px'
                        }}>
                            <div style={{float: 'left', paddingLeft: 20}}>
                                <h4 style={{fontWeight: 800, paddingTop: 0}}>Currency Balances: </h4>
                            </div>
                            <div style={{float: 'right'}}>
                                <CurrencyChip borderColor={'#00acc1'} data={this.state.account_list}/>
                                <Clearfix/>
                            </div>
                        </div>}
                    </GridItem>
                    {this.renderDashboardTopRow(from_code, to_code)}
                    <GridItem md={6}>
                        <NavLink to='/transfers/current_list'>
                            <Card
                                // onClick={() =>
                                //     window.location.replace(
                                //         `/transfers/current_list`
                                //     )
                                // }
                            >
                                <CardBody>
                                    <div
                                        style={{
                                            padding: 20,
                                            height: '25vh',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <CardAvatar
                                            // profile
                                            style={{
                                                marginTop: 0,
                                                marginBottom: 25,
                                            }}
                                        >
                                            <img
                                                src={trackTransfer}
                                                alt="..."
                                                style={{height: 100}}
                                            />
                                        </CardAvatar>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <h3 style={{fontWeight: 800}}>
                                                    Current Transfers
                                                </h3>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <Button
                                        style={{width: '100%', fontSize: '1.3rem', fontWeight: 600}}
                                        color="info"
                                        // onClick={() =>
                                        //     window.location.replace(
                                        //         `/transfers/current_list`
                                        //     )
                                        // }
                                    >
                                        View Current Transfers
                                    </Button>
                                </CardFooter>
                            </Card>
                        </NavLink>
                    </GridItem>
                    <GridItem md={6}>
                        <NavLink to='/transfers/past_list'>
                            <Card
                                // onClick={() =>
                                //     window.location.replace(`/transfers/past_list`)
                                // }
                            >
                                <CardBody>
                                    <div
                                        style={{
                                            padding: 20,
                                            height: '25vh',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <CardAvatar
                                            // profile
                                            style={{
                                                marginTop: 0,
                                                marginBottom: 25,
                                            }}
                                        >
                                            <img
                                                src={pastTransfer}
                                                alt="..."
                                                style={{height: 100}}
                                            />
                                        </CardAvatar>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <h3 style={{fontWeight: 800}}>
                                                    Past Transfers
                                                </h3>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <Button
                                        style={{width: '100%', fontSize: '1.3rem', fontWeight: 600}}
                                        color="info"
                                        // onClick={() =>
                                        //     window.location.replace(
                                        //         `/transfers/past_list`
                                        //     )
                                        // }
                                    >
                                        View Past Transfers
                                    </Button>
                                </CardFooter>
                            </Card>
                        </NavLink>
                    </GridItem>
                </GridContainer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

const DashboardPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardPage)

export default withRouter(withStyles(dashboardStyle)(DashboardPageContainer))
