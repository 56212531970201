import { connect } from 'react-redux'
import ForwardsList from './ForwardsList'
import { fetchForwardList } from '../../../redux/actions/forwards'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchForwardList: (clientId) => dispatch(fetchForwardList(clientId)),
    }
}

const ForwardsListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ForwardsList)

export default ForwardsListContainer
