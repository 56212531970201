import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { onLoginSuccess, updateCurrentBrand } from '../../redux/actions'
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import { Button as MButton } from '@material-ui/core'
import { Grid as DefaultGrid } from '@material-ui/core'

// @material-ui/icons
import Email from '@material-ui/icons/Email'
import Edit from '@material-ui/icons/Edit'

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import CustomInput from '../../components/CustomInput/CustomInput.jsx'
import Button from '../../components/CustomButtons/Button.jsx'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import CardHeader from '../../components/Card/CardHeader.jsx'
import CardFooter from '../../components/Card/CardFooter.jsx'

import { API, Auth } from 'aws-amplify'
import axios from 'axios'

// react component used to create sweet alerts
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'

import {
    container,
    cardTitle,
} from 'assets/jss/material-dashboard-pro-react.jsx'

import { FormattedMessage } from 'react-intl'
import CustomTextField from '../../components/CustomInput/CustomTextField'

// Combine sweetAlertStyle and loginPageStyle
const style = theme => ({
    ...sweetAlertStyle,
    container: {
        ...container,
        zIndex: '4',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '100px',
        },
    },
    cardTitle: {
        ...cardTitle,
        color: '#FFFFFF',
        fontSize: '1.8em',
        fontWeight: 400,
    },
    cardBody: {},
    textCenter: {
        textAlign: 'center',
    },
    justifyContentCenter: {
        justifyContent: 'center !important',
    },
    customButtonClass: {
        '&,&:focus,&:hover': {
            color: '#FFFFFF',
        },
        marginLeft: '5px',
        marginRight: '5px',
    },
    inputAdornment: {
        marginRight: '18px',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    cardHidden: {
        opacity: '0',
        transform: 'translate3d(0, -60px, 0)',
    },
    cardHeader: {
        marginBottom: '20px',
    },
    socialLine: {
        padding: '0.9375rem 0',
    },
    activeButton: {
        backgroundColor: '#57acee',
        width: '100%',
    },
    nonActiveButton: {
        backgroundColor: 'white',
        width: '100%',
        color: 'black',
    },
})

// function facebookWaitForInit() {
//   return new Promise((res, rej) => {
//     const hasFbLoaded = () => {
//       if (window.FB) {
//         res();
//       } else {
//         setTimeout(hasFbLoaded, 300);
//       }
//     };
//     hasFbLoaded();
//   });
// }

class LoginPage extends React.Component {
    constructor(props) {
        super(props)
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            cardAnimation: 'cardHidden',
            email: '',
            password: '',
            // facebookIsLoading: true,
            password_reset_code_sending: false,
            password_reset_code_sent: false,
            password_reset_confirming: false,
            password_reset_confirmed: false,
            confirm_code: '',
            alert: null,
            addToTeam: false,
            emailChecking: false,
            is_fx_member: false,
            is_fww_member: false,
            migrate_id: null,
            migrate_fx: null,
            migrate_fww: null,
        }
    }

    componentDidMount = async () => {
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.get('add_to_team') === 'true') {
            this.setState({
                addToTeam: true,
            })
        }
        if (this.props.match.params.referral_code) {
            this.props.updateCurrentBrand(this.props.match.params.referral_code)
        }
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function() {
                this.setState({ cardAnimation: '' })
            }.bind(this),
            700
        )
        const ga =
            window.gapi && window.gapi.auth2
                ? window.gapi.auth2.getAuthInstance()
                : null
        if (!ga) this.createScript()
        // await facebookWaitForInit();
        // this.setState({ facebookIsLoading: false });
    }

    componentWillUnmount() {
        clearTimeout(this.timeOutFunction)
        this.timeOutFunction = null
    }

    // Google Login Code
    createScript() {
        // load the Google SDK
        const script = document.createElement('script')
        script.src = 'https://apis.google.com/js/platform.js'
        script.async = true
        script.onload = this.initGapi
        document.body.appendChild(script)
    }

    initGapi() {
        // init the Google SDK client
        const g = window.gapi
        g.load('auth2', function() {
            g.auth2.init({
                client_id:
                    '466361976938-6j1ti27carhuh59pilb69ghv3o293q0e.apps.googleusercontent.com',
                // authorized scopes
                scope: 'profile email openid',
            })
        })
    }

    signInGoogle = () => {
        const ga = window.gapi.auth2.getAuthInstance()
        ga.signIn().then(
            googleUser => {
                this.getAWSCredentials(googleUser)
            },
            error => {
                console.log(error)
            }
        )
    }

    getAWSCredentials = async googleUser => {
        const { id_token, expires_at } = googleUser.getAuthResponse()
        const profile = googleUser.getBasicProfile()
        let user = {
            email: profile.getEmail(),
            name: profile.getName(),
        }
        await Auth.federatedSignIn(
            'google',
            { token: id_token, expires_at },
            user
        )
        const user_profile = await this.getUserProfileByEmail(user.email)

        if (user_profile) {
            this.props.userHasAuthenticated(true)
            this.props.set_current_user(user_profile.aws)
            this.props.onLoginSuccess(user_profile.aws_cognito_id)
            this.props.history.push('/')
        } else {
            this.props.history.push(
                `/pages/register?source=google&email=${user.email}&name=${
                    user.name
                }`
            )
        }
    }

    // // Facebook Login code
    // signInFacebook = () => {
    //   window.FB.login(this.checkLoginState, { scope: "public_profile,email" });
    // };

    // checkLoginState = () => {
    //   window.FB.getLoginStatus(this.statusChangeCallback);
    // };

    // statusChangeCallback = (response) => {
    //   if (response.status === "connected") {
    //     this.facebookHandleResponse(response.authResponse);
    //   } else {
    //     this.facebookHandleError(response);
    //   }
    // };

    // facebookHandleError(error) {
    //   alert(error);
    // }

    // facebookHandleResponse(data) {
    //   console.log(data);
    //   const { accessToken, expiresIn } = data;
    //   const expires_at = expiresIn * 1000 + new Date().getTime();
    //   if (!accessToken) {
    //     return;
    //   }

    //   const fb = window.FB;
    //   fb.api("/me", { fields: "name,email" }, (response) => {
    //     console.log(response);
    //     const user = {
    //       name: response.name,
    //       email: response.email,
    //     };
    //     console.log(user);
    //     Auth.federatedSignIn(
    //       "facebook",
    //       { token: accessToken, expires_at },
    //       user
    //     ).then(async (credentials) => {
    //       console.log(credentials);
    //       console.log(user);
    //       const user_profile = await this.getUserProfileByEmail(user.email);
    //       console.log(user_profile);
    //       if (user_profile) {
    //         this.props.userHasAuthenticated(true);
    //         this.props.set_current_user(user_profile);
    //         this.props.onLoginSuccess(user_profile.aws_cognito_id);
    //         this.props.history.push("/");
    //       } else {
    //         this.props.history.push(
    //           `/pages/register?source=facebook&email=${user.email}&name=${
    //             user.name
    //           }`
    //         );
    //       }
    //     });
    //   });
    // }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0
    }

    validate_email() {
        let value = this.state.email
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (emailRex.test(value)) {
            return true
        }
        return false
    }

    handleChange = async event => {
        const value = event.target.value
        this.setState({
            [event.target.id]: event.target.value,
        })
        if (event.target.id === 'email') {
            this.setState({
                is_fx_member: false,
                is_fww_member: false,
                migrate_id: null,
                migrate_fx: null,
                migrate_fww: null
            })
        }
        if (event.target.id == 'email') {
            // console.log(value)
            this.setState({
                emailChecking: true
            })
            const res_fx = await axios.post(
                'https://forexsport.com/API/check_fx_member_1.php',
                { email: value },
                { headers: { 'Access-Control-Allow-Origin': '*' } }
            )
            console.log('FX: ', res_fx.data)
            if (res_fx.data) {
                const res_fx_fww = await axios.post(
                    'https://forexsport.com/API/check_fww_member_2.php',
                    { email: value },
                    { headers: { 'Access-Control-Allow-Origin': '*' } }
                )
                console.log('FX (FWW): ', res_fx_fww.data)
                if (res_fx_fww.data) {
                    this.setState({
                        is_fx_member: true,
                    })
                } 
            }
            const res_fww = await axios.post(
                'https://forexsport.com/API/check_fww_member_1.php',
                { email: value },
                { headers: { 'Access-Control-Allow-Origin': '*' } }
            )
            console.log('FWW: ', res_fww.data)
            if (res_fww.data) {
                this.setState({
                    is_fww_member: true,
                })
            }

            if (this.validate_email()) {
                const migrateResult = await axios.get(`https://t93rvxdcr4.execute-api.ap-southeast-2.amazonaws.com/dev/migrate_check/${value}`, {});
                if (migrateResult.data && migrateResult.data.migrate) {
                    this.setState({
                        migrate_id: migrateResult.data.id,
                        migrate_fx: migrateResult.data.fx,
                        migrate_fww: migrateResult.data.fww,
                    })
                }
            }
            this.setState({
                emailChecking: false
            })
        }
    }

    handleSubmit = async event => {
        event.preventDefault()

        // alert("30 Nov 2022: Temporary Maintenance. Please check back in 30-60 minutes.")
        // return;
        if (this.state.migrate_fx) {
            window.location.href=(`https://portal.forexworldwide.com/pages/migrate?id=${this.state.migrate_id}`)
            return
        }
        if (this.state.migrate_fww) {
            window.location.href=(`https://portal.forexworldwide.com/pages/migrate?id=${this.state.migrate_id}`)
            return
        }

        if (this.state.is_fx_member) {
            window.location.href=(`https://forexsport.com/fx/clients/login.php?submit=true&email=${this.state.email}&pass=${this.state.password}`)
        }

        if (this.state.is_fww_member) {
            window.location.href=(`https://forexsport.com/fww/clients/login.php?submit=true&email=${this.state.email}&pass=${this.state.password}`)
        }

        try {
            await Auth.signIn(
                this.state.email.toLowerCase(),
                this.state.password
            )
            this.props.userHasAuthenticated(true)
        } catch (e) {
            console.log(e)
            if (e.code === 'UserNotConfirmedException') {
                this.props.history.push('/pages/verify-email')
            }
            if (e.code === 'NotAuthorizedException') {
                this.setState({
                    password: '',
                })
                alert(e.message)
            }
            if (e.code === 'UserNotFoundException') {
                this.setState({
                    password: '',
                })
                alert(e.message);
            }
            if (e.code === 'UsernameExistsException') {
                alert(`This user already exists. Please select a different email`);
                this.setState({ email: '' });
            }
        }
    }

    async populateData(aws_cognito_id) {
        try {
            console.log(aws_cognito_id)
            const user = await this.getUserProfile(aws_cognito_id)
            const { username, first_name, middle_name, last_name } = user
            console.log(user)

            this.setState({
                user,
                username,
                first_name,
                middle_name,
                last_name,
            })
        } catch (e) {
            console.error(e)
        }
    }

    async getUserProfile(aws_cognito_id) {
        return API.get('portal', `/users/get-by-id/${aws_cognito_id}`)
    }

    async getUserProfileByEmail(email) {
        return API.get('portal', `/users/get-by-email/${email}`)
    }

    responseGoogle = response => {
        console.log(response)
    }

    // Forgotten password functionality
    handle_forgot_password_click = async email => {

        if (this.state.is_fx_member) {
            window.location.href=(`https://forexsport.com/fx/clients/iforgot.php?email=${this.state.email}`)
        }

        if (this.state.is_fww_member) {
            window.location.href=(`https://forexsport.com/fww/clients/iforgot.php?email=${this.state.email}`)
        }

        this.setState({ password_reset_code_sending: true })
        try {
            await Auth.forgotPassword(this.state.email.toLowerCase())
            this.setState({ password_reset_code_sent: true })
        } catch (e) {
            alert(e.message)
            this.setState({ password_reset_code_sending: false })
        }
    }

    handle_forgot_password_confirm_click = async event => {
        event.preventDefault()
        this.setState({ password_reset_confirming: true })
        try {
            await Auth.forgotPasswordSubmit(
                this.state.email.toLowerCase(),
                this.state.confirm_code,
                this.state.password
            )
            this.setState({ password_reset_confirmed: true })
        } catch (e) {
            alert(e.message)
            this.setState({ password_reset_confirming: false })
        }
    }

    validate_password_reset_form() {
        return (
            this.state.confirm_code.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.password_confirm
        )
    }

    validate_confirmation_form() {
        return this.state.confirm_code.length > 0
    }

    render_confirmation_form() {
        const { classes } = this.props
        let languageObj_en_json = require('../../translations/en.json')
        let languageObj_zh_json = require('../../translations/zh.json')
        let languageObj_de_json = require('../../translations/de.json')
        let languageObj_es_json = require('../../translations/es.json')
        let languageObj_fr_json = require('../../translations/fr.json')
        let languageObj_it_json = require('../../translations/it.json')
        let languageObj_pt_json = require('../../translations/pt.json')
        let logo
        let form_text
        let languageObj = ''
        switch (this.props.language.language_current_ui) {
            case 'es':
                languageObj = languageObj_es_json
                break
            case 'de':
                languageObj = languageObj_de_json
                break
            case 'zh':
                languageObj = languageObj_zh_json
                break
            case 'fr':
                languageObj = languageObj_fr_json
                break
            case 'it':
                languageObj = languageObj_it_json
                break
            case 'pt':
                languageObj = languageObj_pt_json
                break
            default:
                languageObj = languageObj_en_json
                break
        }

        // Handle Branding
        let login_form_title = 'Client Portal Login'
        login_form_title = languageObj['login.formTitle']
        switch (this.props.app_state.current_brand) {
            case 'dorman':
                form_text = 'Dorman'
                break
            case 'idta':
                form_text = 'IDTA'
                break
            case 'gostudy':
                logo = require('assets/img/GOSTUDY_HorizontalWhite.png')
                form_text = 'GoStudy'
                break
            case 'abctravel':
                logo = require('assets/img/brand/travel-logo.jpg')
                form_text = 'ABC Travel'
                break
            case 'crocmedia':
                logo = require('assets/img/brand/crocmedia_white.png')
                form_text = 'CrocMedia'
                break
            case 'forexsport':
                logo = require('assets/img/brand/forexsport.png')
                form_text = 'Forex Sport'
                break
            case 'forexsportninja':
                logo = require('assets/img/brand/forexsport.png')
                form_text = 'Forex Sport (Ninja)'
                break
            case 'forexworldwide':
                logo = require('assets/img/brand/forex_worldwide_edited.png')
                form_text = 'Forex WorldWide'
                break
            case 'payfxdemo':
                logo = require('assets/img/brand/payfxcom_250.png')
                form_text = 'PayFX.com [DEMO]';
                break
            case 'skiaspen':
                logo = require('assets/img/brand/skiaspen.png')
                form_text = 'Ski Aspen';
                break
            }
        return (
            <form
                className={classes.form}
                onSubmit={this.handle_forgot_password_confirm_click}
            >
                <Card
                    login
                    className={`${classes[this.state.cardAnimaton]} ${
                        classes.backgroundTransparent
                    }`}
                    style={{
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }}
                >
                    <CardHeader
                        className={`${classes.cardHeader} ${
                            classes.textCenter
                        }`}
                        color="primary"
                    >
                        {logo && <img src={logo} height={70} width={''} />}
                        <h4 className={classes.cardTitle}>
                            <FormattedMessage
                                id="login.resetPassword"
                                defaultMessage={`Reset Password`}
                            />
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <p>
                            &nbsp;
                            <FormattedMessage
                                id="reset.renderConfirmationForm.text"
                                defaultMessage={`A confirmation code has been emailed to`}
                            />
                            &nbsp;
                            {this.state.email}.
                        </p>
                        <CustomTextField
                            id="confirm_code"
                            formControlProps={{
                                fullWidth: true,
                                className: `${
                                    classes.customFormControlClasses
                                } ${classes.backgroundColor}`,
                            }}
                            inputProps={{
                                value: this.state.confirm_code,
                                variant: 'outlined',
                                label: (
                                    <FormattedMessage
                                        id="reset.conformation.text"
                                        defaultMessage={`Enter Confirmation Code`}
                                    />
                                ),
                                style: { backgroundColor: 'white' },
                                onChange: event => {
                                    this.handleChange(event)
                                },
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        className={classes.inputAdornment}
                                    >
                                        <Edit
                                            className={
                                                classes.inputAdornmentIcon
                                            }
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <CustomTextField
                            id="password"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                                value: this.state.password,
                                variant: 'outlined',
                                style: { backgroundColor: 'white' },
                                label: (
                                    <FormattedMessage
                                        id="reset.newPassword.text"
                                        defaultMessage={`Enter New Password`}
                                    />
                                ),
                                onChange: event => {
                                    this.handleChange(event)
                                },
                                type: 'password',
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        className={classes.inputAdornment}
                                    >
                                        <Icon
                                            className={
                                                classes.inputAdornmentIcon
                                            }
                                        >
                                            lock_outline
                                        </Icon>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <CustomTextField
                            id="confirm_password"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                                value: this.state.confirm_password,
                                variant: 'outlined',
                                style: { backgroundColor: 'white' },
                                label: (
                                    <FormattedMessage
                                        id="reset.newConfirmPassword.text"
                                        defaultMessage={`Enter Confirm Password`}
                                    />
                                ),
                                onChange: event => {
                                    this.handleChange(event)
                                },
                                type: 'password',
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        className={classes.inputAdornment}
                                    >
                                        <Icon
                                            className={
                                                classes.inputAdornmentIcon
                                            }
                                        >
                                            lock_outline
                                        </Icon>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                        <Button
                            color="purple"
                            style={{ height: '40px' }}
                            disabled={!this.validate_confirmation_form()}
                            type="submit"
                            size="sm"
                            block
                        >
                            <FormattedMessage
                                id="login.resetPassword"
                                defaultMessage={`Reset Password`}
                            />
                        </Button>
                    </CardFooter>
                    <Button
                        color="tumblr"
                        simple
                        size="lg"
                        block
                        onClick={() =>
                            this.setState({ password_reset_code_sent: false })
                        }
                    >
                        <b>
                            &nbsp;
                            <FormattedMessage
                                id="reset.returnLogin.text"
                                defaultMessage={`RETURN TO LOGIN PAGE`}
                            />
                        </b>
                    </Button>
                </Card>
            </form>
        )
    }

    render_login_form() {
        const { classes } = this.props

        //setup for language components
        let languageObj_en_json = require('../../translations/en.json')
        let languageObj_zh_json = require('../../translations/zh.json')
        let languageObj_de_json = require('../../translations/de.json')
        let languageObj_es_json = require('../../translations/es.json')
        let languageObj_fr_json = require('../../translations/fr.json')
        let languageObj_it_json = require('../../translations/it.json')
        let languageObj_pt_json = require('../../translations/pt.json')

        let languageObj = ''
        switch (this.props.language.language_current_ui) {
            case 'es':
                languageObj = languageObj_es_json
                break
            case 'de':
                languageObj = languageObj_de_json
                break
            case 'zh':
                languageObj = languageObj_zh_json
                break
            case 'fr':
                languageObj = languageObj_fr_json
                break
            case 'it':
                languageObj = languageObj_it_json
                break
            case 'pt':
                languageObj = languageObj_pt_json
                break
            default:
                languageObj = languageObj_en_json
                break
        }

        // Handle Branding
        let login_form_title = 'Client Portal Login'
        let login_form_brand_name = 'Client Portal Login'
        login_form_title = languageObj['login.formTitle']
        switch (this.props.app_state.current_brand) {
            case 'abctravel':
                login_form_title = 'ABC Travel Client Login'
                login_form_brand_name = login_form_title;
                break
            case 'gostudy':
                login_form_title = 'Go Study Student Login'
                login_form_brand_name = login_form_title;
                break
            case 'dorman':
                login_form_title = 'IDTA FX Portal Login'
                login_form_brand_name = login_form_title;
                break
            case 'idta':
                login_form_title = 'IDTA FX Portal Login'
                login_form_brand_name = login_form_title;
                break
            case 'baca':
                login_form_title = 'Bac A Bank Portal Login'
                login_form_brand_name = login_form_title;
                break
            case 'forexworldwide':
                login_form_title = <span><strong>Forex Worldwide</strong><br/>FX Portal Login</span>
                login_form_brand_name= 'Forex Worldwide FX Portal Login'
                break
            case 'forexsport':
                login_form_title = <span><strong>Forex Sport</strong><br/>FX Portal Login</span>
                login_form_brand_name= 'Forex Sport FX Portal Login'
                break
            case 'forexsportninja':
                login_form_title = <span><strong>Forex Sport (Ninja)</strong><br/>FX Portal Login</span>
                login_form_brand_name= 'Forex Sport FX Portal Login'
                break
            }
        // Branding
        let logo
        let form_text
        switch (this.props.app_state.current_brand) {
            case 'gostudy':
                logo = require('assets/img/GOSTUDY_HorizontalWhite.png')
                form_text = 'GoStudy'
                break
            case 'abctravel':
                logo = require('assets/img/brand/travel-logo.jpg')
                form_text = 'ABC Travel'
                break
            case 'dorman':
                logo = require('assets/img/brand/idta.png')
                form_text = 'IDTA (Dorman)'
                break
            case 'idta':
                logo = require('assets/img/brand/idta.png')
                form_text = 'IDTA'
                break
            case 'forexsport':
                logo = require('assets/img/brand/forexsport.png')
                form_text = 'Forex Sport'
                break
            case 'forexsportninja':
                logo = require('assets/img/brand/forexsport.png')
                form_text = 'Forex Sport (Ninja)'
                break
            case 'forexworldwide':
                logo = require('assets/img/brand/forex_worldwide_edited.png')
                form_text = 'Forex WorldWide'
                break
            case 'payfxdemo':
                logo = require('assets/img/brand/payfxcom_250.png')
                form_text = 'PayFX.com [DEMO]';
                break
            case 'skiaspen':
                logo = require('assets/img/brand/skiaspen.png')
                form_text = 'Ski Aspen';
                break
            }

        return (
            <form onSubmit={this.handleSubmit}>
                <Card
                    login
                    className={classes[this.state.cardAnimation]}
                    style={{ backgroundColor: '#e0e0e0', boxShadow: 'none' }}
                >
                    <CardHeader
                        className={`${classes.cardHeader} ${
                            classes.textCenter
                        }`}
                        color="primary"
                    >
                        {logo && <img src={logo} height={70} width={''} />}
                        <h4
                            className={classes.cardTitle}
                            style={{
                                paddingTop: 12,
                                fontWeight: '300',
                                color: 'white',
                            }}
                        >
                            {login_form_title}
                        </h4>
                        <br/>
                        <em>Powered by Forex Worldwide</em>
                    </CardHeader>
                    <CardBody className={classes.cardBody}>
                        <DefaultGrid
                            container
                            justify="center"
                            style={{ padding: '0', margin: '0' }}
                        >
                            <DefaultGrid item xs={6} sm={6} md={6}>
                                <Button className={classes.activeButton}>
                                    Login
                                </Button>
                            </DefaultGrid>
                            <DefaultGrid item xs={6} sm={6} md={6}>
                                <NavLink
                                    to={
                                        '/pages/register/' +
                                        this.props.app_state.current_brand
                                    }
                                >
                                    <Button className={classes.nonActiveButton}>
                                        Register
                                    </Button>
                                </NavLink>
                            </DefaultGrid>
                        </DefaultGrid>

                        <CustomTextField
                            id="email"
                            value={this.state.email}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: this.state.email,
                                variant: 'outlined',
                                label: 'Email...',
                                style: { backgroundColor: 'white' },
                                onChange: event => {
                                    this.handleChange(event)
                                },
                                type: 'email',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Email
                                            className={
                                                classes.inputAdornmentIcon
                                            }
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <CustomTextField
                            labelText={
                                <FormattedMessage
                                    id="login.password"
                                    defaultMessage={`Password`}
                                />
                            }
                            id="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: this.state.password,
                                variant: 'outlined',
                                style: { backgroundColor: 'white' },
                                label: 'Password',
                                onChange: event => {
                                    this.handleChange(event)
                                },
                                type: 'password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon
                                            className={
                                                classes.inputAdornmentIcon
                                            }
                                        >
                                            lock_outline
                                        </Icon>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <p style={{ fontSize: '11px', textAlign: 'center' }}>
                            <FormattedMessage
                                id="login.resetwording.line2"
                                defaultMessage={`By continuing you agree to our"`}
                            />
                            &nbsp;
                            <a
                                href="https://www.forexworldwide.com/legal"
                                target="_blank"
                            >
                                <FormattedMessage
                                    id="login.termsAndConditon"
                                    defaultMessage={`Terms and Conditions`}
                                />
                            </a>
                        </p>

                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                                {this.state.is_fww_member && (
                                    <Button
                                        color="purple"
                                        style={{ height: '40px' }}
                                        disabled={!this.validateForm()}
                                        type="submit"
                                        size="sm"
                                        block
                                    >
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                Login to ForexWorldwide
                                            </GridItem>
                                        </GridContainer>
                                    </Button>
                                )}
                                {this.state.is_fx_member && (
                                    <Button
                                        color="purple"
                                        style={{ height: '40px' }}
                                        disabled={!this.validateForm()}
                                        type="submit"
                                        size="sm"
                                        block
                                    >
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                Login to ForexSport
                                            </GridItem>
                                        </GridContainer>
                                    </Button>
                                )}
                                {!this.state.is_fx_member &&
                                    !this.state.is_fww_member && (
                                        <Button
                                            color="purple"
                                            style={{ height: '40px' }}
                                            disabled={!this.validateForm() || this.state.emailChecking}
                                            type="submit"
                                            size="sm"
                                            block
                                        >
                                            <GridContainer>
                                                <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                >
                                                    <FormattedMessage
                                                        id="login.login"
                                                        defaultMessage={`proceed`}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </Button>
                                    )}
                            </GridItem>
                        </GridContainer>

                        {this.state.migrate_fx && 
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={12}>
                                    <em>System Migration FX {this.state.migrate_fx}</em>
                                </GridItem>
                            </GridContainer>
                        }

                        {this.state.migrate_fww &&
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={12}>
                                    <em>System Migration FWW {this.state.migrate_fww}</em>
                                </GridItem>
                            </GridContainer>
                        }

                        {this.state.addToTeam && (
                            <p
                                style={{
                                    fontSize: '11px',
                                    textAlign: 'center',
                                }}
                            >
                                <strong>
                                    By logging in you will be added to team:{' '}
                                    {form_text}
                                </strong>
                            </p>
                        )}
                        <p style={{ fontSize: '11px', textAlign: 'center' }}>
                            <b>
                                <FormattedMessage
                                    id="login.resetwording.line1"
                                    defaultMessage={`HAVING TROUBLE? `}
                                />
                                <a
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        this.handle_forgot_password_click(
                                            this.state.email
                                        )
                                    }
                                    target="_blank"
                                >
                                    <FormattedMessage
                                        id="login.resetPassword2"
                                        defaultMessage={`RESET PASSWORD`}
                                    />
                                </a>
                            </b>
                        </p>
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                        <GridContainer justify="center">
                            <GridContainer justify="center">
                                <GridItem xs={11} sm={11} md={11}>


{/* COMMENTED OUT TO TEMPORARILY HIDE SOCIAL MEDIA REGISTRATION */}

                                    {/* {!this.state.is_fx_member && !this.state.is_fww_member && (
                                    <Button
                                        color="google"
                                        style={{ height: '40px' }}
                                        key={'googlelogin'}
                                        onClick={this.signInGoogle}
                                        size="sm"
                                        block
                                    >
                                        <GridContainer>
                                            <GridItem xs={1} sm={1} md={1}>
                                                <i
                                                    className={'fab fa-google'}
                                                />
                                            </GridItem>
                                            <GridItem xs={10} sm={10} md={10}>
                                                <FormattedMessage
                                                    id="login.loginWithGoogle"
                                                    defaultMessage={`SIGN IN WITH GOOGLE`}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </Button>
                                    )} */}

{/* COMMENTED OUT TO TEMPORARILY HIDE SOCIAL MEDIA REGISTRATION */}


                                    <p
                                        style={{
                                            fontSize: '12px',
                                            lineHeight: '0.9',
                                            textAlign: 'center',
                                        }}
                                    >
                                        This <strong>{login_form_brand_name}</strong>{' '}
                                        is powered by Forex WorldWide. <br/>
                                        Forex Sport Pty Limited, ABN 22 147 363 175, trading as Forex Worldwide,
                                        is regulated by the Australian Securities and Investments Commission (ASIC)
                                        in Australia and holds an Australian Financial Services Licence (AFSL 401379).
                                    </p>
                                </GridItem>
                            </GridContainer>
                        </GridContainer>
                    </CardFooter>
                </Card>
            </form>
        )
    }

    render_success_message() {
        const { classes } = this.props
        return (
            <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="primary"
                >
                    <h4 className={classes.cardTitle}>
                        Password Reset Success
                    </h4>
                </CardHeader>
                <CardBody>
                    <p>Your password has been reset.</p>
                    <p>
                        <a href="/pages/login">Click here </a>
                        to login with your new credentials.
                    </p>
                </CardBody>
            </Card>
        )
    }

    hideAlert() {
        this.setState({
            alert: null,
        })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>
                {this.state.alert}
                <GridContainer justify="center">
                    <GridItem xs={12} sm={7} md={5}>
                        {!this.state.password_reset_code_sent
                            ? this.render_login_form()
                            : !this.state.password_reset_confirmed
                                ? this.render_confirmation_form()
                                : this.render_success_message()}
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateCurrentBrand: brand => {
            dispatch(updateCurrentBrand(brand))
        },
        onLoginSuccess: user_id => {
            dispatch(onLoginSuccess(user_id))
        },
    }
}

const LoginPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(style, { withTheme: true })(LoginPage))

export default LoginPageContainer
