import React from 'react'
import { connect } from 'react-redux'
// import Step4Confirm from './Step4Confirm'
import {
    createNewRegistration,
    updateNewRegistrationCreation,
} from '../../../redux/actions/registration'
import withStyles from '@material-ui/core/styles/withStyles'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
import { API } from 'aws-amplify'
//import CustomReactSelect from "../../Forms/CustomReactSelect/CustomReactSelect";
import CardBody from '../../Card/CardBody'
import Card from '../../Card/Card'
import CardHeader from '../../Card/CardHeader'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
//import Check from "@material-ui/core/SvgIcon/SvgIcon";
import Button from '@material-ui/core/Button'
import {RegistrationContext} from "../../../libs/RegistrationProvider";

//import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const style = {
    ...customCheckboxRadioSwitch,

    infoText: {
        fontWeight: '300',
        margin: '10px 0 30px',
        textAlign: 'center',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    inputAdornment: {
        position: 'relative',
    },
}

class Step4Confirm extends React.Component {

    static contextType = RegistrationContext;

    constructor(props) {
        super(props)
        this.state = {
            country_list: [],
            get_heard_us: [],
            client_source: '',
            client_source_state: '',
            rego_confirm_1: false,
            rego_confirm_2: false,
        }
    }

    componentDidMount() {
        API.get('clients', `/get_heard_us`)
            .then(response => {
                this.setState({
                    get_heard_us: response,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    // function that returns true if value is email, false otherwise
    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (emailRex.test(value)) {
            return true
        }
        return false
    }
    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }

    updateBenTelephone(ben_telephone_preferred, phoneNumber) {
        const phone_type = { 1: 'work', 2: 'afterhours', 3: 'mobile' }

        let item
        for (item in phone_type) {
            if (item === ben_telephone_preferred) {
                this.props.updateNewRegistrationCreation(
                    'ben_telephone_' + phone_type[item],
                    phoneNumber
                )
            } else {
                this.props.updateNewRegistrationCreation(
                    'ben_telephone_' + phone_type[item],
                    ''
                )
            }
        }
        this.setState({ ['ben_telephone']: phoneNumber })
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'email':
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })

        this.props.updateNewRegistrationCreation(stateName, event.target.value)

        if (stateName == 'ben_telephone') {
            this.updateBenTelephone(
                this.state.ben_telephone_preferred,
                event.target.value
            )
        } else {
            this.setState({ [stateName]: event.target.value })
            this.props.updateNewRegistrationCreation(
                stateName,
                event.target.value
            )
        }
    }

    handleChange = event => {
        this.setState({ legal_type: event.target.value })
        this.props.updateNewRegistrationCreation(
            [event.target.name],
            event.target.value
        )
    }

    handleToggle = name => event => {
        this.setState({ [name]: event.target.checked })
        if (event.target.checked === true) {
            this.props.updateNewRegistrationCreation(name, 1)
        } else {
            this.props.updateNewRegistrationCreation(name, 1)
        }
        this.setState({
            [`${name}_state`]: 'success',
        })
    }

    handleCustomReactSelectChange = name => value => {
        var option_value
        if (value === null) {
            option_value = null
        } else {
            option_value = value.value
        }
        this.setState(
            {
                [name]: option_value,
            },
            () => {}
        );
        this.context.setRegistration(prev => ({...prev, [name]: option_value}))
        this.props.updateNewRegistrationCreation(name, option_value)
    }

    isValidated() {
        this.setState({
            rego_confirm_1_state: this.state.rego_confirm_1
                ? 'success'
                : 'error',
            rego_confirm_2_state: this.state.rego_confirm_2
                ? 'success'
                : 'error',
        })

        if (this.state.rego_confirm_1 && this.state.rego_confirm_2) {
            this.context.setRegistration(prev => ({...prev, reg_disabled: true}));
            return true;
        } else {
            return false
        }
    }

    render() {
        const { classes } = this.props
        const select_heard_us_options = this.state.get_heard_us.map(item => ({
            value: item.id,
            label: item.nickname,
        }))

        return (
            <React.Fragment>
                <GridContainer justify="left">
                    <GridItem xs={12} sm={6}>
                        <Card>
                            <CardHeader>
                                <h4>Legal Documents</h4>
                            </CardHeader>
                            <CardBody>
                                {/*Legal Documents <br /> <br />*/}
                                <Button
                                    variant="contained"
                                    target="_blank"
                                    href="https://www.forexsport.com/static/pdf/ForexSport_RetailFSPClientAgreement.pdf"
                                    className={classes.button}
                                >
                                    View PDF
                                </Button>
                                &nbsp;&nbsp;Client Agreement <br />
                                <br />
                                <Button
                                    variant="contained"
                                    target="_blank"
                                    href="https://www.forexsport.com/static/pdf/ForexSport_PDS2015.pdf"
                                    className={classes.button}
                                >
                                    View PDF
                                </Button>
                                &nbsp;&nbsp;Product Disclosure Statement <br />
                                <br />
                                <Button
                                    variant="contained"
                                    target="_blank"
                                    href="https://www.forexsport.com/static/pdf/ForexSport_FSG.pdf"
                                    className={classes.button}
                                >
                                    View PDF
                                </Button>
                                &nbsp;&nbsp;Financial Services Guide <br />
                                <br />
                                {/*Please review the following carefully to ensure all details are correct. <br />*/}
                                {/*You are registering a personal account for  cvb cvb.*/}
                                {/*<hr/>*/}
                                {/*How you heard about us. <br/>*/}
                                {/*It would be appreciated if you could let us know how you heard about us. <br /> <br />*/}
                                {/*<CustomReactSelect*/}
                                {/*  label="How do you heard about us"*/}
                                {/*  options={select_heard_us_options}*/}
                                {/*  value={this.state.client_source}*/}
                                {/*  onChange={this.handleCustomReactSelectChange("client_source")}*/}
                                {/*  isClearable={false}*/}
                                {/*  // isDisabled={!this.state.edit_mode}*/}
                                {/*  error={this.state.client_source_state === "error"}*/}
                                {/*/>*/}
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={6}>
                        <Card>
                            <CardHeader>
                                <h4>Acknowledgement and Submit</h4>
                            </CardHeader>
                            <CardBody>
                                {/*Acknowledgements*/}
                                <div className={classes.checkboxAndRadio}>
                                    {/*<FormControlLabel*/}
                                    {/*  control={*/}
                                    {/*    <Checkbox*/}
                                    {/*      tabIndex={-1}*/}
                                    {/*      onClick={this.handleToggle("rego_confirm_1")}*/}
                                    {/*      checked={ this.state.rego_confirm_1 == 1 ? true : false }*/}
                                    {/*      checkedIcon={<Check className={classes.checkedIcon} />}*/}
                                    {/*      icon={<Check className={classes.uncheckedIcon} />}*/}
                                    {/*      classes={{*/}
                                    {/*        checked: classes.checked,*/}
                                    {/*        root: classes.checkRoot*/}
                                    {/*      }}*/}
                                    {/*    />*/}
                                    {/*  }*/}
                                    {/*  classes={{*/}
                                    {/*    label: classes.label*/}
                                    {/*  }}*/}
                                    {/*  label="I confirm that this information that I have provided in this registration is true and correct"*/}
                                    {/*/>*/}

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                error={
                                                    this.state
                                                        .rego_confirm_1_state ==
                                                    'error'
                                                }
                                                success={
                                                    this.state
                                                        .rego_confirm_1_state ==
                                                    'success'
                                                }
                                                tabIndex={-1}
                                                checked={
                                                    this.state.rego_confirm_1
                                                }
                                                onClick={this.handleToggle(
                                                    'rego_confirm_1'
                                                )}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                }}
                                            />
                                        }
                                        label="I confirm that this information that I have provided in this registration is true and correct"
                                    />
                                    {this.state.rego_confirm_1_state ==
                                        'error' && (
                                        <span style={{ color: 'red' }}>
                                            Please tick to confirm you have
                                            agreed to this term
                                        </span>
                                    )}
                                </div>

                                <div className={classes.checkboxAndRadio}>
                                    {/*<FormControlLabel*/}
                                    {/*  control={*/}
                                    {/*    <Checkbox*/}
                                    {/*      tabIndex={-1}*/}
                                    {/*      onClick={this.handleToggle("rego_confirm_2")}*/}
                                    {/*      checked={ this.state.rego_confirm_2 == 1  ? true : false}*/}
                                    {/*      checkedIcon={<Check className={classes.checkedIcon} />}*/}
                                    {/*      icon={<Check className={classes.uncheckedIcon} />}*/}
                                    {/*      classes={{*/}
                                    {/*        checked: classes.checked,*/}
                                    {/*        root: classes.checkRoot*/}
                                    {/*      }}*/}
                                    {/*    />*/}
                                    {/*  }*/}
                                    {/*  classes={{*/}
                                    {/*    label: classes.label*/}
                                    {/*  }}*/}
                                    {/*  label="I have read and agreed to the terms of the Client Agreement, PDS and FSG"*/}
                                    {/*/>*/}

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                tabIndex={-1}
                                                checked={
                                                    this.state.rego_confirm_2
                                                }
                                                onClick={this.handleToggle(
                                                    'rego_confirm_2'
                                                )}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                }}
                                            />
                                        }
                                        label="I have read and agreed to the terms of the Client Agreement, PDS and FSG"
                                    />
                                    {this.state.rego_confirm_2_state ==
                                        'error' && (
                                        <span style={{ color: 'red' }}>
                                            Please tick to confirm you have
                                            agreed to this term
                                        </span>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </React.Fragment>
        )
    }
}

// export default withStyles(style)(Step4Confirm)
Step4Confirm = withStyles(style)(Step4Confirm)


const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        registration: state.registration,
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createNewRegistration: data => {
            dispatch(createNewRegistration(data))
        },
        updateNewRegistrationCreation: (key, value) => {
            dispatch(updateNewRegistrationCreation(key, value))
        },
    }
}

const Step4ConfirmContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Step4Confirm)

export default Step4ConfirmContainer
