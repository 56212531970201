import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import React, { Fragment } from 'react'
import CustomInput from '../../CustomInput/CustomInput'
import GridContainer from '../../Grid/GridContainer'
import GridItem from '../../Grid/GridItem'

const Beneficiary = ({
    classes,
    edit_mode,
    account_currency,
    currencies_list_priority,
    nickname,
    nickname_state,
    ben_legal_name,
    ben_legal_name_state,
    handleChange,
    handleSelectChange,
    handleSubmit,
}) => {
    return (
        <Fragment>
            <Typography variant="h5" style={{ fontWeight: 700 }}>
                Beneficiary
            </Typography>
            <div style={{ padding: 20, marginBottom: 24 }}>
                <form onSubmit={handleSubmit}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={3}>
                            <CustomInput
                                success={nickname_state === 'success'}
                                error={nickname_state === 'error'}
                                labelText="Nickname *"
                                id="nickname"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: !edit_mode,
                                    value: nickname || '',
                                    onChange: event => {
                                        handleChange(event)
                                    },
                                }}
                            />
                        </GridItem>

                        <GridItem xs={6} sm={6} md={3}>
                            <FormControl
                                fullWidth
                                // error={!client_id > 0}
                                className={classes.selectFormControl}
                            >
                                <InputLabel
                                    htmlFor="simple-select"
                                    className={classes.selectLabel}
                                >
                                    Account Currency
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu,
                                    }}
                                    classes={{
                                        select: classes.select,
                                    }}
                                    value={account_currency || ''}
                                    onChange={handleSelectChange}
                                    inputProps={{
                                        disabled: !edit_mode,
                                        name: 'account_currency',
                                        id: 'account_currency',
                                    }}
                                >
                                    <MenuItem
                                        key="0x0"
                                        disabled
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        Select currency
                                    </MenuItem>
                                    {currencies_list_priority.map(item => {
                                        return (
                                            <MenuItem
                                                key={item.id}
                                                classes={{
                                                    root:
                                                        classes.selectMenuItem,
                                                    selected:
                                                        classes.selectMenuItemSelected,
                                                }}
                                                value={item.id}
                                            >
                                                {item.iso_alpha_3}:{' '}
                                                {item.full_name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                success={ben_legal_name_state === 'success'}
                                error={ben_legal_name_state === 'error'}
                                labelText="Legal Name *"
                                id="ben_legal_name"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: !edit_mode,
                                    value: ben_legal_name || '',
                                    onChange: event => {
                                        handleChange(event)
                                    },
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </form>
            </div>
        </Fragment>
    )
}

export default Beneficiary
