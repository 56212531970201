import React from 'react'
import PropTypes from 'prop-types'
import { API } from 'aws-amplify'

import { connect } from 'react-redux'

import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'

import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import Button from '../../CustomButtons/Button'

import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react'
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
// var moment = require('moment');

const styles = {
    ...switchStyles,
    ...selectStyles,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px',
    },
    registerButton: {
        float: 'right',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}

class TransferDetailRemittanceCommunication extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            remittance_advice_email_sending: false,
            remittance_advice_email_sent: false,
        }
    }

    // componentDidMount() {
    // }

    handle_send_remittance_advice = async event => {
        // event.preventDefault();
        this.setState({
            remittance_advice_email_sending: true,
        })
        // console.log( {
        //   id: this.props.id,
        //   rate: this.props.rate,
        //   amount_to: this.props.amount_to,
        //   currency_to: this.props.currency_to,
        //   currency_from: this.props.currency_from,
        //   amount_from: this.props.amount_from,
        //   eba_account_name: this.props.eba_account_name,
        //   remitting_bank_id: this.props.remitting_bank_id,
        //   remittance_reference: this.props.remittance_reference
        // });

        API.post('email', `/client/remittance-instructions`, {
            body: {
                id: this.props.id,
                rate: this.props.rate,
                amount_to: this.props.amount_to,
                currency_to: this.props.currency_to,
                currency_to_iso3: this.props.currency_to_iso3,
                currency_from: this.props.currency_from,
                currency_from_iso3: this.props.currency_from_iso3,
                amount_from: this.props.amount_from,
                eba_account_name: this.props.eba_account_name,
                remitting_bank_id: this.props.remitting_bank_id,
                remittance_reference: this.props.remittance_reference,
            },
        })
            .then(response => {
                // console.log(response);
                this.setState({
                    remittance_advice_email_sent: true,
                    remittance_advice_email_sending: false,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        const { classes } = this.props
        //console.log(this.props);

        // console.log( {
        //   id: typeof(this.props.id),
        //   rate: typeof(this.props.rate),
        //   amount_to: typeof(this.props.amount_to),
        //   currency_to: typeof(this.props.currency_to),
        //   currency_from: typeof(this.props.currency_from),
        //   amount_from: typeof(this.props.amount_from),
        //   eba_account_name: typeof(this.props.eba_account_name),
        //   remitting_bank_id: typeof(this.props.remitting_bank_id),
        //   remittance_reference: typeof(this.props.remittance_reference)
        // });

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Button
                            color="info"
                            //disabled={!this.props.enabled} //turn off to avoid crash issue effect by #797
                            disabled={true}
                            size="sm"
                            onClick={this.handle_send_remittance_advice}
                            className={classes.updateProfileButton}
                        >
                            Email
                        </Button>
                        &nbsp;&nbsp; Send payout instructions to{' '}
                        {
                            //this.props.app_state.current_client.email //turn off to avoid crash effect by issue #797
                        }
                        {'  '}
                        {!this.props.enabled && (
                            <span>(insufficient security level)</span>
                        )}
                        {this.state.remittance_advice_email_sending && (
                            <span>(email sending)</span>
                        )}
                        {this.state.remittance_advice_email_sent && (
                            <span>(email sent)</span>
                        )}
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

TransferDetailRemittanceCommunication.propTypes = {
    classes: PropTypes.object.isRequired,
    id: PropTypes.number,
    rate: PropTypes.number,
    amount_to: PropTypes.number,
    currency_to: PropTypes.string,
    currency_from: PropTypes.string,
    amount_from: PropTypes.number,
    eba_account_name: PropTypes.string,
    remitting_bank_id: PropTypes.number,
    remittance_reference: PropTypes.string,
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
    }
}

// const mapDispatchToProps = dispatch => {
//   return {
//     // fetchClientList: () => {
//     //   dispatch(fetchClientList())
//     // },
//     // fetchTransferList: () => {
//     //   dispatch(fetchTransferList())
//     // },
//     // setTransferSelectId: (id) => {
//     //    dispatch(setTransferSelectId(id))
//     // }
//   }
// }

const TransferDetailRemittanceCommunicationContainer = connect(
    mapStateToProps
    //mapDispatchToProps
)(TransferDetailRemittanceCommunication)

export default withRouter(
    withStyles(styles)(TransferDetailRemittanceCommunicationContainer)
)
