import React, { Fragment } from 'react'
import axios from 'axios'
import CurrencyTabs from '../../../components/CurrencySelect/CurrencyTabs'
import CurrencyRatesWithFlags from './CurrencyRatesWithFlags'

class FxRatesBoxHorizontal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,

            selectedCurrency: 'aud',

            rateData: [],
        }
    }

    componentWillMount() {
        this.fetchCurrencyData()

        this.timerAUDRATES = setInterval(() => this.fetchCurrencyData(), 20000)
    }

    componentWillUnmount() {
        clearInterval(this.timerAUDRATES)
    }

    fetchCurrencyData = () => {
        let baseCurrencies = ['AUD', 'EUR', 'GBP', 'USD']

        let promises = []
        for (let baseCurrency of baseCurrencies) {
            let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f&currencies=AUD,USD,EUR,GBP,NZD,HKD,JPY,SGD&source=${baseCurrency}&format=1`
            promises.push(axios.get(url))
        }

        let rates = []

        axios
            .all(promises)
            .then(results => {
                results.forEach(response => {
                    switch (response.data.source) {
                        case 'AUD':
                            for (var quote in response.data.quotes) {
                                var base = quote.slice(0, 3)
                                var terms = quote.slice(3, 6)
                                let currentRate = {
                                    id: `${base.toLowerCase()}${terms.toLowerCase()}`,
                                    from: base,
                                    to: terms,
                                    value:
                                        response.data.quotes[`${base}${terms}`],
                                }
                                rates.push(currentRate)
                            }
                            break
                        case 'EUR':
                            for (var quote in response.data.quotes) {
                                var base = quote.slice(0, 3)
                                var terms = quote.slice(3, 6)
                                let currentRate = {
                                    id: `${base.toLowerCase()}${terms.toLowerCase()}`,
                                    from: base,
                                    to: terms,
                                    value:
                                        response.data.quotes[`${base}${terms}`],
                                }
                                rates.push(currentRate)
                            }
                            break
                        case 'GBP':
                            for (var quote in response.data.quotes) {
                                var base = quote.slice(0, 3)
                                var terms = quote.slice(3, 6)
                                let currentRate = {
                                    id: `${base.toLowerCase()}${terms.toLowerCase()}`,
                                    from: base,
                                    to: terms,
                                    value:
                                        response.data.quotes[`${base}${terms}`],
                                }
                                rates.push(currentRate)
                            }
                            break
                        case 'USD':
                            for (var quote in response.data.quotes) {
                                var base = quote.slice(0, 3)
                                var terms = quote.slice(3, 6)
                                let currentRate = {
                                    id: `${base.toLowerCase()}${terms.toLowerCase()}`,
                                    from: base,
                                    to: terms,
                                    value:
                                        response.data.quotes[`${base}${terms}`],
                                }
                                rates.push(currentRate)
                            }
                            break
                    }
                })
            })

            .then(() => {
                this.setState({
                    rateData: rates,
                    rows: rates,
                    loading: false,
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    handleTabClick = ({ target: { value } }) => {
        if (value !== this.state.selectedCurrency) {
            this.setState({ selectedCurrency: value })
        }
    }

    componentDidUpdate() {
        console.log({ rateData: this.state.rateData })
    }

    render() {
        const filteredCurrencyData = this.state.rateData.filter(
            item => item.from.toLowerCase() === this.state.selectedCurrency
        )

        return (
            <Fragment>
                {this.state.loading && (
                    <div
                        style={{
                            marginTop: 50,
                            marginBottom: 50,
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        LOADING
                    </div>
                )}

                {!this.state.loading && (
                    <Fragment>
                        <CurrencyTabs
                            onTabClick={this.handleTabClick}
                            selected={this.state.selectedCurrency}
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '32px',
                                fontSize: '1rem',
                                marginTop: '16px',
                            }}
                        >
                            {filteredCurrencyData.map(data => {
                                return (
                                    <CurrencyRatesWithFlags
                                        key={data.id}
                                        from={data.from}
                                        to={data.to}
                                        rate={data.value}
                                    />
                                )
                            })}
                        </div>
                    </Fragment>
                )}
                <div />
            </Fragment>
        )
    }
}

export default FxRatesBoxHorizontal
