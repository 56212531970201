import { connect } from 'react-redux'
import IdentificationEdit from './IdentificationEdit'
import // selectIdentificationUi,
'../../../redux/actions/identification'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        identification: state.identification,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // setClientSelectId: (aws_cognito_id) => {
        //   dispatch(setClientSelectId(aws_cognito_id))
        // },
        // selectIdentificationUi: (data) => {
        //   dispatch(selectIdentificationUi(data))
        // },
    }
}

const IdentificationEditContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IdentificationEdit)

export default IdentificationEditContainer
