import React from 'react'
import { FormattedNumber } from 'react-intl'
import { Table } from 'antd'
import CurrencyTabs from '../../components/CurrencySelect/CurrencyTabs'

const axios = require('axios')

const CurrencyWithFlag = ({ currency }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
                className={`currency-flag currency-flag-${
                    currency ? currency.toLowerCase() : ''
                }`}
            />
            <span style={{ marginLeft: '4px' }}>{currency}</span>
        </div>
    )
}

const getRowId = row => row.id

class RateTickerVerticalBoxLarge extends React.Component {
    constructor(props) {
        super(props)
        // console.log(this.props.screenWidth);
        // console.log(isWidthUp('lg', this.props.screenWidth));

        this.state = {
            // get_started_dialog: false,

            loading: true,

            selectedCurrency: 'aud',

            columns: [
                {
                    title: 'Source',
                    key: 'from',
                    dataIndex: 'from',
                    render: currency => (<>
                            <CurrencyWithFlag currency={currency} />
                    </>
                    ),
                },
                {
                    title: 'Terms',
                    dataIndex: 'to',
                    key: 'to',
                    render: currency => (
                        <CurrencyWithFlag currency={currency} />
                    ),
                },
                {
                    title: 'Rate',
                    dataIndex: 'value',
                    key: 'value',
                },
                {
                    title: 'Inverse',
                    dataIndex: 'value',
                    key: 'value',
                    render: value => (
                        <FormattedNumber
                            minimumFractionDigits={2}
                            maximumFractionDigits={6}
                            value={1 / value}
                        />
                    ),
                },
            ],
            rateData: [],
        }
    }

    componentWillMount() {
        let baseCurrencies = ['AUD', 'EUR', 'GBP', 'USD']

        let promises = []
        for (let baseCurrency of baseCurrencies) {
            let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f&currencies=AUD,USD,EUR,GBP,NZD,HKD,JPY,SGD&source=${baseCurrency}&format=1`
            promises.push(axios.get(url))
        }

        let rates = []

        axios
            .all(promises)
            .then(results => {
                results.forEach(response => {
                    switch (response.data.source) {
                        case 'AUD':
                            for (var quote in response.data.quotes) {
                                var base = quote.slice(0, 3)
                                var terms = quote.slice(3, 6)
                                let currentRate = {
                                    id: `${base.toLowerCase()}${terms.toLowerCase()}`,
                                    from: base,
                                    to: terms,
                                    value:
                                        response.data.quotes[`${base}${terms}`],
                                }
                                rates.push(currentRate)
                            }
                            break
                        case 'EUR':
                            for (var quote in response.data.quotes) {
                                var base = quote.slice(0, 3)
                                var terms = quote.slice(3, 6)
                                let currentRate = {
                                    id: `${base.toLowerCase()}${terms.toLowerCase()}`,
                                    from: base,
                                    to: terms,
                                    value:
                                        response.data.quotes[`${base}${terms}`],
                                }
                                rates.push(currentRate)
                            }
                            break
                        case 'GBP':
                            for (var quote in response.data.quotes) {
                                var base = quote.slice(0, 3)
                                var terms = quote.slice(3, 6)
                                let currentRate = {
                                    id: `${base.toLowerCase()}${terms.toLowerCase()}`,
                                    from: base,
                                    to: terms,
                                    value:
                                        response.data.quotes[`${base}${terms}`],
                                }
                                rates.push(currentRate)
                            }
                            break
                        case 'USD':
                            for (var quote in response.data.quotes) {
                                var base = quote.slice(0, 3)
                                var terms = quote.slice(3, 6)
                                let currentRate = {
                                    id: `${base.toLowerCase()}${terms.toLowerCase()}`,
                                    from: base,
                                    to: terms,
                                    value:
                                        response.data.quotes[`${base}${terms}`],
                                }
                                rates.push(currentRate)
                            }
                            break
                    }

                    // let currentRate = {
                    //     id: `${response.data.baseCurrency.toLowerCase()}${response.data.termsCurrency.toLowerCase()}`,
                    //     from: response.data.baseCurrency,
                    //     to: response.data.termsCurrency,
                    //     value: response.data.value
                    // };
                    // console.log('processing:', currentRate);
                    // rates.push(currentRate);
                })
            })

            .then(() => {
                this.setState({
                    rateData: rates,
                    rows: rates,
                    loading: false,
                })
            })
            .then(() => {
                this.timerAUDRATES = setInterval(
                    () => this.update_data_all(),
                    20000
                )
            })

            .catch(err => {
                console.log(err)
            })
    }

    componentWillUnmount() {
        clearInterval(this.timerAUDRATES)
        // clearInterval(this.timerAUDUSD);
        //   clearInterval(this.timerAUDEUR);
        //   clearInterval(this.timerAUDGBP);
        //   clearInterval(this.timerAUDNZD);
        //   clearInterval(this.timerAUDHKD);
        //   clearInterval(this.timerAUDJPY);
        //   clearInterval(this.timerAUDSGD);
    }

    update_data_all = () => {
        // console.log('update_data_all()');
        let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f&currencies=USD,EUR,GBP,NZD,HKD,JPY,SGD&source=AUD&format=1`
        axios
            .get(url)
            .then(res => {
                // console.log(res);
                // let rate = res.data.result;
                // console.log(rate);
                //
                let rates_majors = this.state.rows.slice(0, 7)
                // console.log(rates_majors);
                let audusd = res.data.quotes['AUDUSD']
                let audeur = res.data.quotes['AUDEUR']
                let audgbp = res.data.quotes['AUDGBP']
                let audnzd = res.data.quotes['AUDNZD']
                let audhkd = res.data.quotes['AUDHKD']
                let audjpy = res.data.quotes['AUDJPY']
                let audsgd = res.data.quotes['AUDSGD']
                rates_majors[0].change = (
                    audusd.toFixed(5) - rates_majors[0].value
                ).toFixed(5)
                rates_majors[0].value = audusd
                rates_majors[1].change = (
                    audeur.toFixed(5) - rates_majors[1].value
                ).toFixed(5)
                rates_majors[1].value = audeur
                rates_majors[2].change = (
                    audgbp.toFixed(5) - rates_majors[2].value
                ).toFixed(5)
                rates_majors[2].value = audgbp
                rates_majors[3].change = (
                    audnzd.toFixed(5) - rates_majors[3].value
                ).toFixed(5)
                rates_majors[3].value = audnzd
                rates_majors[4].change = (
                    audhkd.toFixed(5) - rates_majors[4].value
                ).toFixed(5)
                rates_majors[4].value = audhkd
                rates_majors[5].change = (
                    audjpy.toFixed(5) - rates_majors[5].value
                ).toFixed(5)
                rates_majors[5].value = audjpy
                rates_majors[6].change = (
                    audsgd.toFixed(5) - rates_majors[6].value
                ).toFixed(5)
                rates_majors[6].value = audsgd
                this.setState({
                    rows_majors: rates_majors,
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    handleTabClick = ({ target: { value } }) => {
        if (value === this.state.selectedCurrency) {
            this.setState({ selectedCurrency: '' })
        } else {
            this.setState({ selectedCurrency: value })
        }
    }

    render() {
        const { classes } = this.props
        const { columns } = this.state
        const { selectedCurrency } = this.state
        const filteredByCurrencyData = this.state.rateData.filter(
            item => item.from.toLowerCase() === selectedCurrency
        )

        return (
            <div style={{ zIndex: 5 }}>
                {this.state.loading && (
                    <div
                        style={{
                            marginTop: 50,
                            marginBottom: 50,
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        LOADING
                    </div>
                )}

                {!this.state.loading && (
                    <div>
                        <CurrencyTabs
                            selected={selectedCurrency}
                            onTabClick={this.handleTabClick}
                        />
                        <Table
                            rowClassName="widget-custom-ant-table-row"
                            className='widget-custom-ant-table'
                            size="small"
                            columns={columns}
                            dataSource={
                                selectedCurrency
                                    ? filteredByCurrencyData
                                    : this.state.rateData
                            }
                            pagination={false}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default RateTickerVerticalBoxLarge
