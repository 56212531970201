import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Switch, Redirect, withRouter } from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import PagesHeaderContainer from '../components/Header/PagesHeader'
// import Footer from "../components/Footer/Footer.jsx";

import pagesRoutes from 'routes/pages.jsx'

// import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import UnauthenticatedRoute from '../components/Routes/UnauthenticatedRoute'
// import queryString from "query-string";

import ReactGA from "react-ga4";

ReactGA.initialize("G-WDBS85CGD2");


const style = theme => ({
    wrapper: {
        height: 'auto',
        minHeight: '100vh',
        position: 'relative',
        top: '0',
    },
    fullPage: {
        padding: '120px 0',
        position: 'relative',
        minHeight: '100vh',
        display: 'flex!important',
        margin: '0',
        border: '0',
        color: '#fff',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'fit-content!important',
        },
        '& footer': {
            position: 'absolute',
            bottom: '0',
            width: '100%',
            border: 'none !important',
        },
        // "&:before": {
        //   backgroundColor: "#E0E0E0"
        // },
        '&:before,&:after': {
            display: 'block',
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            zIndex: '2',
        },
    },
})

class Pages extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        document.body.style.overflow = 'unset'
        // console.log('location*: ', window.location.href)
        ReactGA.send({ hitType: "pageview", page: window.location.href, title: window.location.title });
        // const query_strings = queryString.parse(this.props.location.search);
        // console.log(query_strings);
        // if (query_strings) {
        //   if (query_strings.ref) {
        //     this.setState({
        //       ref: true,
        //       ref_code: query_strings.ref
        //     })
        //   }
        // }
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            // console.log('location: ', location.pathname)
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname });

        });
    }
    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        const { classes, ...rest } = this.props
        const style = {}

        const background = require('assets/img/brand/world-map.jpg')
        const background_baca = require('assets/img/brand/baca-background.jpeg')
        const background_gaura = require('assets/img/brand/india-background.jpg')
        const background_gostudy = require('assets/img/brand/education-students.jpg')
        if (
            this.props.app_state &&
            this.props.app_state.current_brand &&
            (window.location.pathname.toString().includes('login') ||
                window.location.pathname.toString().includes('register'))
        ) {
            if (this.props.app_state.current_brand === 'dorman') {
                style['backgroundImage'] = `url(${background})`
                style['backgroundSize'] = `cover`
            } else if (this.props.app_state.current_brand === 'idta') {
                style['backgroundImage'] = `url(${background})`
                style['backgroundSize'] = `cover`
            } else if (this.props.app_state.current_brand === 'baca') {
                style['backgroundImage'] = `url(${background_baca})`
                style['backgroundSize'] = `cover`
            } else if (this.props.app_state.current_brand === 'abctravel') {
                style['backgroundImage'] = `url(${background_baca})`
                style['backgroundSize'] = `cover`
            } else if (this.props.app_state.current_brand === 'gaura') {
                style['backgroundImage'] = `url(${background_gaura})`
                style['backgroundSize'] = `cover`
            } else if (this.props.app_state.current_brand === 'gostudy') {
                style['backgroundImage'] = `url(${background_gostudy})`
                style['backgroundSize'] = `cover`
            } else {
                style['backgroundColor'] = '#E0E0E0'
            }
        } else {
            style['backgroundColor'] = '#E0E0E0'
        }



        return (
            <div style={style}>
                <PagesHeaderContainer {...rest} />
                <div className={classes.wrapper} ref="wrapper">
                    <div className={classes.fullPage}>
                        <Switch>
                            {pagesRoutes.map((prop, key) => {
                                if (prop.collapse) {
                                    return null
                                }
                                if (prop.redirect) {
                                    return (
                                        <Redirect
                                            from={prop.path}
                                            to={prop.pathTo}
                                            key={key}
                                        />
                                    )
                                }
                                return (
                                    <UnauthenticatedRoute
                                        path={prop.path}
                                        component={prop.component}
                                        key={key}
                                        props={{
                                            isAuthenticated: this.props
                                                .isAuthenticated,
                                            userHasAuthenticated: this.props
                                                .userHasAuthenticated,
                                            set_current_user: this.props
                                                .set_current_user,
                                        }}
                                    />
                                )
                            })}
                        </Switch>
                        {/* <Footer white ref_code={this.props.app_state.current_brand} /> */}
                    </div>
                </div>
            </div>
        )
    }
}

// Pages.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withStyles(style)(Pages);

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
        language: state.language,
    }
}

const PagesContainer = connect(
    mapStateToProps
    // mapDispatchToProps
)(withStyles(style)(Pages))

export default withRouter(PagesContainer)
