import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { onLoginSuccess, updateCurrentBrand } from '../../redux/actions'
// import Link from onLoginSuccess../components/Link'
// import RegisterPage from "./RegisterPage";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from '@material-ui/core/Icon'

// @material-ui/icons
// import Language from "@material-ui/icons/Language";
// import ThumbUp from "@material-ui/icons/ThumbUp";
import VpnLock from '@material-ui/icons/VpnLock'
import Face from '@material-ui/icons/Face'
import Email from '@material-ui/icons/Email'
// import LockOutline from "@material-ui/icons/LockOutline";
// import Check from "@material-ui/icons/Check";

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import InfoArea from 'components/InfoArea/InfoArea.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'

import { API, Auth } from 'aws-amplify'
import queryString from 'query-string'
import axios from 'axios'

// import registerPageStyle from "./RegisterPageStyle";

// import { FormattedMessage } from "react-intl";

import {
    container,
    cardTitle,
} from '../../assets/jss/material-dashboard-pro-react.jsx'

import customCheckboxRadioSwitch from '../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
// import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'

const registerPageStyle = {
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch,
    cardTitle: {
        ...cardTitle,
        textAlign: 'center',
    },
    container: {
        ...container,
        position: 'relative',
        zIndex: '3',
        // paddingTop: "23vh"
    },
    cardSignup: {
        borderRadius: '6px',
        boxShadow:
            '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
        marginBottom: '100px',
        padding: '40px 0px',
        marginTop: '15vh',
    },
    center: {
        textAlign: 'center',
    },
    right: {
        textAlign: 'right',
    },
    left: {
        textAlign: 'left',
    },
    form: {
        padding: '0 20px',
        position: 'relative',
    },
    socialTitle: {
        fontSize: '18px',
    },
    customButtonClass: {
        '&,&:focus,&:hover': {
            color: '#FFFFFF',
        },
        marginLeft: '5px',
        marginRight: '5px',
    },
    inputAdornment: {
        marginRight: '18px',
        position: 'relative',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    customFormControlClasses: {
        margin: '0 12px',
    },
    checkboxLabelControl: {
        margin: '0',
    },
    checkboxLabel: {
        marginLeft: '6px',
        color: 'rgba(0, 0, 0, 0.26)',
    },
}

// function facebookWaitForInit() {
//   return new Promise((res, rej) => {
//     const hasFbLoaded = () => {
//       if (window.FB) {
//         res();
//       } else {
//         setTimeout(hasFbLoaded, 300);
//       }
//     };
//     hasFbLoaded();
//   });
// }
class MigratePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: [],
            // social: false,
            // facebookIsLoading: true,
            pageError: false,
            
            clientId: null,
            first_name: '',
            last_name: '',
            email: '',
            emailDisabled: false,
            email_state: '',
            email_error_message: '',
            password: '',
            password_state: '',
            confirm_password: '',
            confirm_code: '',
            newUser: null,
            referral_code: this.props.match.params.referral_code
                ? this.props.match.params.referral_code
                : '',
            // securities: false
        }
        // this.handleToggle = this.handleToggle.bind(this);
    }

    componentDidMount = async () => {
        // console.log(this.state);
        // console.log(this.props)
        // console.log(this.props.match.params.referral_code);
        if (this.props.match.params.referral_code) {
            this.props.updateCurrentBrand(this.props.match.params.referral_code)
        }

        //Tom query things and password Encryption and Decryption module
        const query_strings = queryString.parse(this.props.location.search)
        // console.log(query_strings);
        if (query_strings) {
            //#694 set a timestamp verification module here
            // tcPlus60sec and tcNewSystem to check if timestamp is correct (- see if tcNewSystem are within tc and tcPlus60sec)
            let tcPlus60sec = Date.now() + 60000
            let tcNewSystem = Date.now()
            let tc = query_strings.tc
            // console.log(tc)

            // console.log(query_strings)

            if (query_strings.id) {
                this.setState({clientId: query_strings.id})
                const migrateResult = await axios.get(`https://t93rvxdcr4.execute-api.ap-southeast-2.amazonaws.com/dev/migrate_check_2/${query_strings.id}`, {});
                console.log(migrateResult)
                if (migrateResult && migrateResult.data && migrateResult.data.email && migrateResult.data.migrate_ready == 1) {
                    this.setState({
                        email: migrateResult.data.email,
                        emailDisabled: true,
                        pageError: false
                    })
                } else {
                    this.setState({pageError: true})
                    window.location.href="/pages/login"
                }

            } else {
                this.setState({pageError: true})
            }



            // if (tcNewSystem >= tc && tcNewSystem < tcPlus60sec) {
            //     console.log(tc)
            //     console.log(tcPlus60sec)
            //     console.log('== correct timestamps!! ==')
            //     console.log(
            //         '== Start filling in information and create user into New System - Client Portal =='
            //     )

            //     if (query_strings.e) {
            //         let tempEmail = query_strings.e
            //         let decryptedEmail = atob(tempEmail)
            //         console.log(decryptedEmail)
            //         this.setState({
            //             email: decryptedEmail, //query_strings.email,
            //         })
            //     }
            //     if (query_strings.p) {
            //         //#694 set a decryption module here
            //         //Input: test cyphertext: encryptedPassword (e.g. UGFzc3cwcmQh) and tc (timestamp) //1573081953067 1573081953067
            //         //Output: test plaintext: (e.g. Passw0rd!)
            //         let tempPW = query_strings.p // "UGFzc3cwcmQh"
            //         let decryptedPW = atob(tempPW)

            //         console.log(decryptedPW)

            //         this.setState({
            //             password: decryptedPW, //query_strings.password,
            //             confirm_password: decryptedPW, //query_strings.password
            //         })
            //     }
            //     if (query_strings.f) {
            //         let tempFirstname = query_strings.f
            //         let decryptedFirstName = atob(tempFirstname)
            //         console.log(decryptedFirstName)
            //         this.setState({
            //             first_name: decryptedFirstName, //query_strings.firstname,
            //         })
            //     }
            //     if (query_strings.l) {
            //         let tempLastname = query_strings.l
            //         let decryptedLastName = atob(tempLastname)
            //         console.log(decryptedLastName)
            //         this.setState({
            //             last_name: decryptedLastName, //query_strings.lastname,
            //         })
            //     }

            //     if (
            //         query_strings.e &&
            //         query_strings.p &&
            //         query_strings.f &&
            //         query_strings.l
            //     ) {
            //         //#694
            //         let tempEmail = query_strings.e
            //         let decryptedEmail = atob(tempEmail)
            //         let tempPW = query_strings.p // "UGFzc3cwcmQh"
            //         let decryptedPW = atob(tempPW)
            //         let tempFirstname = query_strings.f
            //         let decryptedFirstName = atob(tempFirstname)
            //         let tempLastname = query_strings.l
            //         let decryptedLastName = atob(tempLastname)

            //         let email = decryptedEmail //query_strings.email;
            //         let password = decryptedPW //query_strings.password;
            //         let firstname = decryptedFirstName //query_strings.firstname;
            //         let lastname = decryptedLastName //query_strings.lastname;

            //         console.log(email)
            //         console.log(password)
            //         console.log(firstname)
            //         console.log(lastname)

            //         this.setState({ isLoading: true })

            //         try {
            //             const newUser = await Auth.signUp({
            //                 username: email.toLowerCase(),
            //                 password: password,
            //             })

            //             this.setState({
            //                 newUser,
            //             })
            //             console.log(newUser)
            //             await this.create_new_user_in_database(
            //                 newUser.userSub,
            //                 newUser.user.username.toLowerCase(),
            //                 firstname,
            //                 lastname,
            //                 this.state.referral_code.toLowerCase()
            //             )

            //             API.post('email', `/interaction/main`, {
            //                 body: {
            //                     interaction: 'New Client Registration',
            //                     data: {
            //                         username: newUser.user.username,
            //                         cognito: newUser.userSub,
            //                     },
            //                     result:
            //                         'AWS Cognito user created - awaiting confirmation',
            //                 },
            //             })
            //                 .then(response => {
            //                     console.log(response)
            //                 })
            //                 .catch(error => {
            //                     console.log(error)
            //                 })
            //         } catch (e) {
            //             alert(e.message)
            //             console.log(e)
            //             if (e.code === 'UsernameExistsException') {
            //                 const newUser = await Auth.resendSignUp(email)
            //                 this.setState({
            //                     newUser,
            //                 })
            //             }
            //         }
            //     }

            //     this.setState({ isLoading: false })
            // }

            // if (query_strings.email) {
            //   this.setState({
            //     email: query_strings.email,
            //   })
            // }
            // if (query_strings.name) {
            //   this.setState({
            //     first_name: query_strings.name.split(" ")[0],
            //     last_name: query_strings.name.split(" ")[1]
            //   })
            // }
            // if (query_strings.source === "google") {
            //   this.setState({
            //     source_message: "using your Google account",
            //     social: true,
            //     password_helptext: <span style={{fontWeight: 500}}>Please still select a password for this system.  <br/>You will thereafter be able to log in with your social media account or password.</span>
            //   })
            // }
            // if (query_strings.source === "facebook") {
            //   this.setState({
            //     source_message: "using your FaceBook account",
            //     social: true,
            //     password_helptext: <span style={{fontWeight: 500}}>Please still select a password for this system.  <br/>You will thereafter be able to log in with your social media account or password.</span>
            //   })
            // }
            // if (query_strings.code === "securities") {
            //   this.setState({
            //     securities: true
            //   })
            // }
        }

        // const ga = window.gapi && window.gapi.auth2 ?
        //   window.gapi.auth2.getAuthInstance() :
        //   null;
        // if (!ga) this.createScript();
        // await facebookWaitForInit();
        // this.setState({ facebookIsLoading: false });
    }

    // handleToggle(value) {
    //   const { checked } = this.state;
    //   const currentIndex = checked.indexOf(value);
    //   const newChecked = [...checked];
    //
    //   if (currentIndex === -1) {
    //     newChecked.push(value);
    //   } else {
    //     newChecked.splice(currentIndex, 1);
    //   }
    //
    //   this.setState({
    //     checked: newChecked
    //   });
    // }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + ' _state']: 'error' })
                }
                break
            case 'equalTo':
                if (
                    this.compare(
                        event.target.value,
                        this.state[stateNameEqualTo]
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
        // this.props.updateNewClientCreation(stateName, event.target.value)
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value,
        })
        if (event.target.id === 'email') {
            this.setState({
                email_exists_in_aws: false,
                email_error_message: '',
            })
        }

        // console.log(this.state);
    }

    // // Google Login Code
    // createScript() {
    //   // load the Google SDK
    //   const script = document.createElement("script");
    //   script.src = "https://apis.google.com/js/platform.js";
    //   script.async = true;
    //   script.onload = this.initGapi;
    //   document.body.appendChild(script);
    // }
    //
    // initGapi() {
    //   // init the Google SDK client
    //   const g = window.gapi;
    //   g.load("auth2", function() {
    //     g.auth2.init({
    //       client_id: "466361976938-6j1ti27carhuh59pilb69ghv3o293q0e.apps.googleusercontent.com",
    //       // authorized scopes
    //       scope: "profile email openid"
    //     });
    //   });
    // }
    // signInGoogle = () => {
    //   const ga = window.gapi.auth2.getAuthInstance();
    //   ga.signIn()
    //     .then(googleUser => {
    //         this.getAWSCredentials(googleUser);
    //       },
    //       error => {
    //         console.log(error);
    //       }
    //     );
    // };
    // getAWSCredentials = async (googleUser) => {
    //   // console.log(googleUser)
    //   const { id_token, expires_at } = googleUser.getAuthResponse();
    //   const profile = googleUser.getBasicProfile();
    //   let user = {
    //     email: profile.getEmail(),
    //     name: profile.getName()
    //   };
    //   // console.log(user)
    //
    //   // const credentials = await Auth.federatedSignIn(
    //   //   'google',
    //   //   { token: id_token, expires_at },
    //   //   user
    //   // );
    //   await Auth.federatedSignIn(
    //     "google",
    //     { token: id_token, expires_at },
    //     user
    //   );
    //   // console.log('credentials', credentials);
    //   const user_profile = await this.getUserProfileByEmail(user.email);
    //   // console.log(user_profile);
    //
    //   if (user_profile) {
    //     this.props.userHasAuthenticated(true);
    //     this.props.set_current_user(user_profile.aws);
    //     this.props.onLoginSuccess(user_profile.aws_cognito_id);
    //     this.props.history.push("/");
    //   } else {
    //     this.props.history.push(`/pages/register?source=google&email=${user.email}&name=${user.name}`);
    //   }
    //
    // };
    //
    // async getUserProfile(aws_cognito_id) {
    //   return API.get("portal", `/users/get-by-id/${aws_cognito_id}`);
    // }
    //
    // async getUserProfileByEmail(email) {
    //   return API.get("portal", `/users/get-by-email/${email}`);
    // }
    //
    // responseGoogle = (response) => {
    //   console.log(response);
    // };

    async check_if_email_exists_in_aws() {
        return API.get('clients', `/email_check/${this.state.email}`)
            .then(response => {
                // console.log(response);
                if (response !== 0) {
                    this.setState({
                        email_exists_in_aws: true,
                        email_error_message: 'Email already is registered',
                    })
                    // console.log("true")
                    return true
                } else {
                    this.setState({
                        email_exists_in_aws: false,
                        email_error_message: '',
                    })
                    // console.log("false")
                    return false
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    handleSubmit = async event => {
        event.preventDefault()
        // let email_exists_in_aws = await this.check_if_email_exists_in_aws()
        // if (email_exists_in_aws) return

        this.setState({ isLoading: true })

        try {
            const newUser = await Auth.signUp({
                username: this.state.email.toLowerCase(),
                password: this.state.password,
            })

            this.setState({
                newUser,
            })
            console.log(newUser)

            await this.updateMigrateUser(
                this.state.clientId,
                newUser.userSub,
                )

            await API.post('email', `/interaction/main`, {
                body: {
                    interaction: 'Migration - Step 1',
                    data: {
                        clientId: this.state.clientId,
                        username: newUser.user.username,
                        password: this.state.password,
                        cognito: newUser.userSub,
                    },
                    result: 'AWS Cognito user created - awaiting confirmation',
                },
            })
                .then(response => {
                    console.log(response)
                    // window.location.href="/pages/login"
                })
                .catch(error => {
                    console.log(error)
                })
        } catch (e) {
            alert(e.message)
            console.log(e)
            if (e.code === 'UsernameExistsException') {
                const newUser = await Auth.resendSignUp(this.state.email)
                this.setState({
                    newUser,
                })
            }
        }

        this.setState({ isLoading: false })
    }

    async updateMigrateUser(
        id,
        aws_cognito_id
    ) {
        API.post('usersNew', `/users/migrate/add-aws-cognito-id`, {
            body: {
                clientId: id,
                aws_cognito_id: aws_cognito_id,
            },
        })
            .then(response => {

                API.post('email', `/staff/migrated-confirmed`, {
                    body: {
                        data: {
                            clientId: id,
                            aws_cognito_id: aws_cognito_id,
                        },
                    },
                })
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(error)
                    })

                API.post('email', `/interaction/main`, {
                    body: {
                        interaction: 'Client Migrate #1',
                        data: {
                            clientId: id,
                            aws_cognito_id: aws_cognito_id,
                            ...response,
                        },
                        result: 'Success',
                    },
                })
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(error)
                    })

                return response
            })
            .catch(error => {
                console.log(error)
            })
    }

    async create_new_user_in_database(
        aws_cognito_id,
        email,
        first_name,
        last_name,
        referral_code
    ) {
        API.post('portal', `/users/migrate`, {
            body: {
                email: email,
                aws_cognito_id: aws_cognito_id,
                first_name: first_name,
                last_name: last_name,
                record_created_datetime: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace('T', ' '),
                record_modified_datetime: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace('T', ' '),
                portal_account_created: 1,
                portal_email_confirmed: 0,
                language_id: 1,
                referral_code: referral_code,
            },
        })
            .then(response => {
                // console.log(response);

                // API.post("email", `/client/migrated`, {
                //   body: {
                //     data: {
                //       "email": this.state.email.toLowerCase(),
                //       "first_name": this.state.first_name,
                //       "last_name": this.state.last_name,
                //       "password": this.state.password,
                //       "user_id": response.insertId
                //     }
                //   }
                // })
                //   .then(response => {
                //     console.log(response);
                //   })
                //   .catch(error => {
                //     console.log(error);
                //   });

                API.post('email', `/staff/migrated-confirmed`, {
                    body: {
                        data: {
                            email: this.state.email,
                            first_name: this.state.first_name,
                            last_name: this.state.last_name,
                            client_id: response.insertId,
                        },
                    },
                })
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(error)
                    })

                API.post('email', `/interaction/main`, {
                    body: {
                        interaction: 'New Client Cognito Confirmation',
                        data: {
                            email: this.state.email,
                            first_name: this.state.first_name,
                            last_name: this.state.last_name,
                            ...response,
                        },
                        result: 'Client migrated',
                    },
                })
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(error)
                    })

                return response
            })
            .catch(error => {
                console.log(error)
            })
    }

    async confirmUser(user_id, data) {
        // console.log(user_id, data)
        return API.put('portal', `/users/update/${user_id}`, {
            body: data,
        })
    }

    handleConfirmationSubmit = async event => {
        event.preventDefault()

        this.setState({ isLoading: true })

        try {
            await Auth.confirmSignUp(
                this.state.email.toLowerCase(),
                this.state.confirm_code
            )
            const signed_in_user = await Auth.signIn(
                this.state.email.toLowerCase(),
                this.state.password
            )

            // console.log(signed_in_user)
            await this.confirmUser(
                signed_in_user.username,
                {
                    portal_email_confirmed: 1,
                    // portal_last_login_datetime: new Date().toISOString().slice(0, 19).replace('T', ' ')
                }
            )
            // console.log(database_result)
            // console.log(this.state)

            // API.post("email", `/client/migrated-confirmed`, {
            //   body: {
            //     data: {
            //       "email": this.state.email,
            //       "first_name": this.state.first_name,
            //       "last_name": this.state.last_name
            //     }
            //   }
            // })
            //   .then(response => {
            //     console.log(response);
            //   })
            //   .catch(error => {
            //     console.log(error);
            //   });

            // this.props.userHasAuthenticated(true);
            this.props.history.push('/')
            // window.location.assign("http://localhost:3006")
            window.location.reload()
        } catch (e) {
            alert(e.message)
            this.setState({ isLoading: false })
        }
    }

    // function that returns true if value is valid password, false otherwise
    validatePassword(event, value, stateName) {
        // var passwordRex = /^(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z])(?=.*\d.*\d)(?=.*\W.*\W)[a-zA-Z0-9\S]{9,}$/;
        // This pattern requires at least two lowercase letters, two uppercase letters, two digits, and two special characters. There must be a minimum of 9 characters total, and no white space characters are allowed.
        // very strong password

        var passwordRex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*\S)[A-Za-z\d\S]{6,}$/
        // This pattern requires at least 6 characters and contain at least 1 number and 1 character

        this.setState({ [stateName]: event.target.value })
        // console.log(event.target.value);
        if (passwordRex.test(event.target.value)) {
            // console.log("True");
            this.setState({
                password_state: 'success',
                password_helptext: '',
            })
            return true
        }
        // console.log("False");
        this.setState({
            password_state: 'error',
            password_helptext:
                'Must be at least 6 characters and contain at least 1 number',
        })
        return false
    }

    validateForm() {
        // console.log(this.state);
        /*
        if(this.state.password !== this.state.confirm_password){
          this.setState({
            password_state: "error",
            password_helptext: "Must be longer than 8 characters and contain at least 1 uppercase & 1 number & 1 symbol character",
          });
          return false;
        }
    */
        return (
            this.state.email.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.confirm_password &&
            !this.state.email_exists_in_aws
            // this.state.checked[0] === 1
        )
    }

    validateConfirmationForm() {
        return this.state.confirm_code.length > 0
    }

    // warningAlert() {
    //   // console.log("asdasdsa")
    //   this.setState({
    //     alert: (
    //       <SweetAlert
    //         warning
    //         style={{ display: "block", marginTop: "-100px" }}
    //         title={<span style={{color: "black"}}>Sorry!</span>}
    //         onConfirm={() => this.hideAlert()}
    //         onCancel={() => this.hideAlert()}
    //         confirmBtnCssClass={
    //           this.props.classes.button + " " + this.props.classes.success
    //         }
    //       >
    //         <span style={{color: "black"}}>Facebook Login is presently unavailable</span>
    //       </SweetAlert>
    //     )
    //   });
    // }
    //
    // hideAlert() {
    //   this.setState({
    //     alert: null
    //   });
    // }

    renderForm() {
        const { classes } = this.props

        //setup for language components
        let languageObj_en_json = require('../../translations/en.json')
        let languageObj_zh_json = require('../../translations/zh.json')
        let languageObj_de_json = require('../../translations/de.json')
        let languageObj_es_json = require('../../translations/es.json')
        let languageObj_fr_json = require('../../translations/fr.json')
        let languageObj_it_json = require('../../translations/it.json')
        let languageObj_pt_json = require('../../translations/pt.json')

        //this.props.language.language_current_ui
        let languageObj = ''
        switch (this.props.language.language_current_ui) {
            case 'es':
                languageObj = languageObj_es_json
                //menu_item_text = prop.name_es || prop.name;
                break
            case 'de':
                languageObj = languageObj_de_json
                //menu_item_text = prop.name_de || prop.name;
                break
            case 'zh':
                languageObj = languageObj_zh_json
                //menu_item_text = prop.name_zh || prop.name;
                break
            case 'fr':
                languageObj = languageObj_fr_json
                //menu_item_text = prop.name_fr || prop.name;
                break
            case 'it':
                languageObj = languageObj_it_json
                //menu_item_text = prop.name_it || prop.name;
                break
            case 'pt':
                languageObj = languageObj_pt_json
                //menu_item_text = prop.name_it || prop.name;
                break
            default:
                languageObj = languageObj_en_json
                //menu_item_text = prop.name;
                break
        }

        return (
            <form className={classes.form} onSubmit={this.handleSubmit}>
                {/* <CustomInput
                    // labelText="First Name..."
                    id="first_name"
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.first_name,
                        onChange: event => {
                            this.handleChange(event)
                        },
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                        ),
                        placeholder: languageObj['register.firstname'],
                    }}
                /> */}
                {/* <CustomInput
                    // labelText="Last Name..."
                    id="last_name"
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.last_name,
                        onChange: event => {
                            this.handleChange(event)
                        },
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                        ),
                        placeholder: languageObj['register.lastname'],
                    }}
                /> */}
                <CustomInput
                    // labelText="Email..."
                    id="email"
                    helpText={this.state.email_error_message}
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.email,
                        disabled: this.state.emailDisabled,
                        onChange: event => {
                            this.handleChange(event)
                        },
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                        ),
                        type: 'email',
                        placeholder: languageObj['register.email'],
                    }}
                />
                <CustomInput
                    // labelText="Password..."
                    success={this.state.password_state === 'success'}
                    error={this.state.password_state === 'error'}
                    id="password"
                    helpText={this.state.password_helptext}
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.password,
                        onChange: event =>
                            this.validatePassword(
                                event,
                                'password',
                                'password'
                            ),
                        type: 'password',
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                </Icon>
                            </InputAdornment>
                        ),
                        placeholder: languageObj['register.password'],
                    }}
                />
                <CustomInput
                    // labelText="Confirm Password..."
                    success={this.state.confirm_password_state === 'success'}
                    error={this.state.confirm_password_state === 'error'}
                    id="confirm_password"
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.confirm_password,
                        onChange: event => {
                            this.handleChange(event)
                        },
                        type: 'password',
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                </Icon>
                            </InputAdornment>
                        ),
                        placeholder: languageObj['register.confirmPassword'],
                    }}
                />
                {/*{this.state.social &&*/}
                {/*  <p><em>You will be able to log-in using your social media account, however please provide an account password still, for security purposes.</em></p>*/}
                {/*}*/}
                {/*<CustomInput*/}
                {/*  id="referral_code"*/}
                {/*  formControlProps={{*/}
                {/*    fullWidth: true,*/}
                {/*    className: classes.customFormControlClasses*/}
                {/*  }}*/}
                {/*  inputProps={{*/}
                {/*    value: this.state.referral_code,*/}
                {/*    onChange: event => {*/}
                {/*      this.handleChange(event);*/}
                {/*    },*/}
                {/*    placeholder: "Please enter referrer code if you have one"*/}
                {/*  }}*/}
                {/*/>*/}
                {/*<FormControlLabel*/}
                {/*  classes={{*/}
                {/*    root: classes.checkboxLabelControl,*/}
                {/*    label: classes.checkboxLabel*/}
                {/*  }}*/}
                {/*  control={*/}
                {/*    <Checkbox*/}
                {/*      tabIndex={-1}*/}
                {/*      value={this.state.agree_terms}*/}
                {/*      onClick={() => this.handleToggle(1)}*/}
                {/*      checkedIcon={<Check className={classes.checkedIcon} />}*/}
                {/*      icon={<Check className={classes.uncheckedIcon} />}*/}
                {/*      classes={{*/}
                {/*        checked: classes.checked,*/}
                {/*        root: classes.checkRoot*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  }*/}
                {/*  label={*/}
                {/*    <span>*/}
                {/*      <FormattedMessage */}
                {/*        id="register.agreement"*/}
                {/*        defaultMessage={*/}
                {/*          `I agree to the `} */}
                {/*      /> <a href="https://www.forexworldwide.com/legal" target="_blank"> terms and conditions</a>.*/}
                {/*    </span>*/}
                {/*  }*/}
                {/*/>*/}
                <GridContainer justify="center">
                    <GridItem xs={9} sm={9} md={9}>
                        <br />
                        <Button
                            color="rose"
                            disabled={!this.validateForm()}
                            type="submit"
                            size="sm"
                            block
                        >
                            <GridContainer>
                                <GridItem xs={1} sm={1} md={1}>
                                    <Icon>lock_outline</Icon>
                                </GridItem>
                                <GridItem xs={10} sm={10} md={10}>
                                    Get Confirm Code
                                    {/*<FormattedMessage*/}
                                    {/*  id="register.register"*/}
                                    {/*  defaultMessage={*/}
                                    {/*    `Register Now`}*/}
                                    {/*/>*/}
                                </GridItem>
                            </GridContainer>
                        </Button>
                    </GridItem>
                </GridContainer>
                {/*<GridContainer  justify="center">*/}
                {/*{!this.state.social &&*/}
                {/*  <GridItem xs={9} sm={9} md={9}>*/}
                {/*    <Button*/}
                {/*      color="facebook"*/}
                {/*      // justIcon*/}
                {/*      key={"fblogin"}*/}
                {/*      // onClick={this.signInFacebook}*/}
                {/*      onClick={this.warningAlert.bind(this)}*/}
                {/*      // simple*/}
                {/*      size="sm"*/}
                {/*      // disabled={!this.validate_email()}*/}
                {/*      block*/}
                {/*    >*/}
                {/*      <GridContainer>*/}
                {/*        <GridItem xs={1} sm={1} md={1}>*/}
                {/*          <i className={"fab fa-facebook-square"}/>*/}
                {/*        </GridItem>*/}
                {/*        <GridItem xs={10} sm={10} md={10}>*/}
                {/*          <FormattedMessage*/}
                {/*            id="register.registerWithFacebook"*/}
                {/*            defaultMessage={*/}
                {/*              `Register with Facebook`}*/}
                {/*          />*/}
                {/*        </GridItem>*/}
                {/*      </GridContainer>*/}
                {/*    </Button>*/}
                {/*  </GridItem>*/}
                {/*  }*/}
                {/*  {!this.state.social &&*/}
                {/*  <GridItem xs={9} sm={9} md={9}>*/}
                {/*    <Button*/}
                {/*      color="google"*/}
                {/*      // justIcon*/}
                {/*      key={"googlelogin"}*/}
                {/*      onClick={this.signInGoogle}*/}
                {/*      // simple*/}
                {/*      size="sm"*/}
                {/*      // disabled={!this.validate_email()}*/}
                {/*      block*/}
                {/*    >*/}
                {/*      <GridContainer>*/}
                {/*        <GridItem xs={1} sm={1} md={1}>*/}
                {/*          <i className={"fab fa-google"}/>*/}
                {/*        </GridItem>*/}
                {/*        <GridItem xs={10} sm={10} md={10}>*/}
                {/*          <FormattedMessage*/}
                {/*            id="register.registerWithGoogle"*/}
                {/*            defaultMessage={*/}
                {/*              `Register with Google`}*/}
                {/*          />*/}
                {/*        </GridItem>*/}
                {/*      </GridContainer>*/}
                {/*    </Button>*/}
                {/*  </GridItem>*/}
                {/*  }*/}
                {/*</GridContainer>*/}

                {/*<div className={classes.center}>*/}
                {/*  <Button*/}
                {/*    round*/}
                {/*    color="primary"*/}
                {/*    disabled={!this.validateForm()}*/}
                {/*    type="submit"*/}
                {/*  // onClick={() => this.props.history.push("/pages/login-page")}*/}
                {/*  >*/}
                {/*    <FormattedMessage */}
                {/*        id="register.getStarted"*/}
                {/*        defaultMessage={*/}
                {/*          `Get Started`} */}
                {/*      /> */}
                {/*  </Button>*/}
                {/*</div>*/}
            </form>
        )
    }

    renderConfirmationForm() {
        const { classes } = this.props
        return (
            <form
                className={classes.form}
                onSubmit={this.handleConfirmationSubmit}
            >
                <InfoArea
                    title="Email Verification"
                    description=""
                    icon={VpnLock}
                    iconColor="info"
                />
                <p>
                    An email has been sent to {this.state.email} containing a
                    6-digit verification code.
                </p>
                <p>
                    Please check your email (and spam folder) and
                    enter the code below to login.
                </p>
                <CustomInput
                    // labelText="First Name..."
                    id="confirm_code"
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.confirm_code,
                        onChange: event => {
                            this.handleChange(event)
                        },
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                        ),
                        placeholder: 'Confirmation Code...',
                    }}
                />
                <div className={classes.center}>
                    <Button
                        round
                        color="primary"
                        disabled={!this.validateConfirmationForm()}
                        type="submit"
                        // onClick={() => this.props.history.push("/pages/login-page")}
                    >
                        Validate Code
                    </Button>
                </div>
            </form>
        )
    }

    render() {
        const { classes } = this.props

        // Branding
        let custom_message = ''
        switch (this.props.app_state.current_brand) {
            case 'gostudy':
                custom_message = 'GoStudy'
                break
            case 'gostudyadmin':
                custom_message = 'GoStudy Admin Team'
                break
            case 'gostudyaustralia':
                custom_message = 'GoStudy Australia'
                break
            case 'gostudyitaly':
                custom_message = 'GoStudy Italy'
                break
            case 'gostudyfrance':
                custom_message = 'GoStudy France'
                break
            case 'gostudyspain':
                custom_message = 'GoStudy Spain'
                break
            case 'redhill':
                custom_message = 'RedHill Education'
                break
            case 'abcu':
                custom_message = 'ABC University'
                break
            case 'smithsonian':
                custom_message = 'Smithsonian College'
                break
            case 'smithsonianadelaide':
                custom_message = 'Smithsonian College Adelaide'
                break
            case 'smithsoniansydney':
                custom_message = 'Smithsonian College Sydney'
                break
            case 'dorman':
                custom_message = 'Dorman Securities Forex Transfer Services'
                break
        }

        return (
            <div className={classes.container}>
                {this.state.alert}
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card className={classes.cardSignup}>
                            <h2 className={classes.cardTitle}>
                                Client Portal - Password Security Upgrade
                                {/*<FormattedMessage */}
                                {/*  id="register.registernow"*/}
                                {/*  defaultMessage={*/}
                                {/*    ` Register Now`} */}
                                {/*/>*/}
                            </h2>
                            <h5 className={classes.cardTitle}>
                                {custom_message}
                            </h5>
                            {/*{this.state.securities &&*/}
                            {/*<h5 className={classes.cardTitle}>Securities & Derivatives Portal</h5>*/}
                            {/*}*/}
                            <h5 className={classes.cardTitle}>
                                {this.state.source_message}
                            </h5>
                            <CardBody>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={12} md={5}>
                                        <InfoArea
                                            title="Worldwide Money Transfers"
                                            description=""
                                            icon={VpnLock}
                                            iconColor="info"
                                        />
                                        <p>
                                            As part of a security upgrade for your account,
                                            your password must be reset.
                                        </p>
                                        <p>
                                            Please provide a new password on this form and 
                                            a confirmation email code will be sent to you to verify.
                                        </p>
                                        <p>
                                            If you have any difficulties, please contact us via 
                                            the online chat on this page or you can call 
                                            during business hours on +61 3 9008 1880.
                                        </p>
                                    </GridItem>
                                    <GridItem xs={12} sm={8} md={5}>
                                        {this.state.newUser === null
                                            ? this.renderForm()
                                            : this.renderConfirmationForm()}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

MigratePage.propTypes = {
    classes: PropTypes.object.isRequired,
}

// export default withStyles(registerPageStyle)(RegisterPage);

// import { connect } from "react-redux";
// // import { onLoginSuccess } from "../../redux/actions";
// // import Link from onLoginSuccess../components/Link'
// import RegisterPage from "./RegisterPage";

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateCurrentBrand: brand => {
            dispatch(updateCurrentBrand(brand))
        },
        onLoginSuccess: user_id => {
            dispatch(onLoginSuccess(user_id))
        },
    }
}

const MigratePageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(registerPageStyle)(MigratePage))

export default MigratePageContainer
