import { connect } from 'react-redux'
import TransfersListCurrent from './TransfersListCurrent'
import {
    fetchTransferList,
    fetchTransferListV2,
    selectTransferUi,
} from '../../../redux/actions/transfers'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers: state.transfers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchTransferList: () => {
            dispatch(fetchTransferList())
        },
        fetchTransferListV2: aws_cognito_id => {
            dispatch(fetchTransferListV2(aws_cognito_id))
        },
        selectTransferUi: data => {
            dispatch(selectTransferUi(data))
        },
    }
}

const TransfersListCurrentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TransfersListCurrent)

export default TransfersListCurrentContainer
