import {FETCH_COUNTRIES_NOT_DELETED_SUCCESS} from "../actions/countries";

const initialState = [];

export function countries(state = initialState, action) {
    switch (action.type) {
        case FETCH_COUNTRIES_NOT_DELETED_SUCCESS:
            return [...state, ...action.payload]
        default:
            return state;
    }
}