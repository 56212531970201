import {Button, Card, Modal, Table, message, Form} from "antd";
import React, {useEffect, useRef, useState} from 'react';
import {DataStatus} from "../../ComponentInfo/Status";
import {API} from "aws-amplify";
import ListTable from "../../ListTable/ListTable";
import {CloudUploadOutlined, EyeOutlined, ReloadOutlined} from "@ant-design/icons";
import FileUploader from "../../FileUploader/FileUploader";
import dayjs from "dayjs";
import {useForm} from "antd/es/form/Form";

const TransferDocuments = ({ getColumnSearchProps, transfer_id, state }) => {

    const [form] = useForm();
    const uploadRef = useRef();
    const [componentState, setComponentState] = useState({
        data: [],
        isModalOpen: false,
        isLoading: false
    })

    const [componentInfo, setComponentInfo] = useState({
        status: DataStatus.Loaded,
        callback: () => fetchTransferDocuments()
    });

    const fetchTransferDocuments = () => {
        setComponentInfo(prev => ({...prev, status: DataStatus.Loading}));
        API.post("commons", "/fetch", {
            body: {
                context: 'transferDocuments',
                fields: ['*'],
                condition: {transferId: transfer_id, deleted: false, visiblePublic: true}
            }
        }).then(res => {
            setComponentInfo(prev => ({...prev, status: DataStatus.Loaded}));
            setComponentState(prev => ({...prev, data: res}));
        }).catch(() => {
            setComponentInfo(prev => ({...prev, status: DataStatus.ErrorState}));
        });
    }
    const buildColumns = () => {
        return [
            {
                title: 'Document No.',
                dataIndex: 'id',
                sorter: (a, b) => a.id - b.id,
                defaultSortOrder: 'descend'
            },
            {
                title: 'Created Date',
                dataIndex: 'recordCreated',
                ...getColumnSearchProps({
                    dataIndex: 'recordCreated',
                    filterInputType: 'DATE',
                    render: (text, record) =>  text != null ? dayjs(text).format('DD/MM/YYYY') : '-'
                })
            },
            {
                title: 'Description',
                dataIndex: 'description',
            },
            {
                title: '',
                dataIndex: 'documentId',
                ...getColumnSearchProps({
                    dataIndex: 'documentId',
                    render: (text, record) => {
                        return <div style={{ display: 'flex', justifyContent: 'start'}}>
                            <Button type={'primary'} icon={<EyeOutlined />} onClick={() => {
                                const loading = message.loading("Loading documents. Please wait..", 0);
                                let documents = [record.documentId];
                                const attachments = JSON.parse(record.attachments);
                                if (attachments && attachments.ids && attachments.ids.length > 0) {
                                    documents = [...documents, ...attachments.ids];
                                }
                                API.post("commons", "/fetch", {
                                    body: {
                                        context: 'documents',
                                        fields: ['*'],
                                        condition: {id: documents}
                                    }
                                }).then((response) => {
                                    Modal.confirm({
                                        width: '720px',
                                        title: 'Documents & Attachments',
                                        icon: false,
                                        content: <>
                                        {response.map(i => {
                                            return <FileUploader uploaderType={'PDF'} thumbType={'picture-card'} fetchFileList={() => {
                                                const files = [];
                                                if (i != null && i.file_ref != null) {
                                                    const file = {
                                                        filename: i.file_ref,
                                                        name: i.file_ref,
                                                        uid: i.file_ref,
                                                        status: "done"
                                                    }
                                                    files.push(file);
                                                }
                                                return Promise.resolve(files);
                                            }}></FileUploader>
                                        })}
                                        </>
                                    })
                                }).catch(err => {
                                    console.log(err);
                                }).finally(() => loading());
                            }}></Button>
                        </div>
                    }
                })
            }

        ]
    }

    const uploadTransferDocuments = async (values) => {
        setComponentState(prev => ({...prev, isLoading: true }));
        try {
            if (values.uploads && values.uploads.length > 0) {
                const attachments = {ids: []};
                for (const i of values.uploads) {
                    const requestBody = {
                        file_ref: i,
                        document_category_id: 4,
                        record_created_datetime: dayjs()
                    }
                    const docRes = await API.post("commons", "/insert", {
                        body: {
                            context: 'documents',
                            data: requestBody
                        }
                    });
                    attachments.ids.push(docRes.id);
                }

                const transferReqBody = {
                    recordCreated: dayjs(),
                    transferId: transfer_id,
                    attachments: JSON.stringify(attachments),
                    description: 'Uploaded By Client',
                    visiblePublic: true,
                    deleted: false

                }
                await API.post("commons", "/insert", {
                    body: {
                        context: 'transferDocuments',
                        data: transferReqBody
                    }
                });

                await API.post("commons", "/mail", {
                    body: {
                        to: `Portal Notifications <portalNotifications@forexworldwide.com>`,
                        subject: 'Documents have been uploaded by client # ' + state.current_client.id,
                        header: ['New Transfer Document Upload'],
                        data: [
                            {label: 'Client', value: `${state.current_client.nickname} #${state.current_client.id}`},
                            {label: 'Transfer ID', value: `#${transfer_id}`},
                        ],
                        footer: [`Client #${state.current_client.id} ${state.current_client.nickname} uploaded a transfer document`]
                    }
                });

                form.resetFields();
                uploadRef.current.clearList();
                setComponentState(prev => ({...prev, isLoading: false, isModalOpen: false}));
                fetchTransferDocuments();
            }
        } catch (e) {
            console.log(e);
            message.error("Failed to upload documents. Please try again..");
            setComponentState(prev => ({...prev, isLoading: false }));
        }
    }

    useEffect(() => {
        fetchTransferDocuments();
    }, [state.current_client]);

    return <Card title={<div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', gap: 5, justifyItems: 'center' }}>
        <Button type={'primary'} icon={<CloudUploadOutlined />}  onClick={() => setComponentState(prev => ({...prev, isModalOpen: true }))}>Upload Documents</Button>
        <Button type={'primary'} icon={<ReloadOutlined />} danger={true}  onClick={() => fetchTransferDocuments()}>Reload Page</Button>
    </div>}>
        <Modal visible={componentState.isModalOpen}
               okButtonProps={{ loading: componentState.isLoading }}
               cancelButtonProps={{ loading: componentState.isLoading }}
               onCancel={() => {
                   form.resetFields();
                   setComponentState(prev => ({...prev, isModalOpen: false }));
               }}
               title={'Upload Transfer Documents'}
                onOk={() => form.submit()}>
            <Form name={'document-upload-form'} layout={'vertical'} form={form} onFinish={uploadTransferDocuments}>
                <Form.Item name={'uploads'} label={'Upload'} getValueFromEvent={(e) => e.map(i => i.filename)}>
                    <FileUploader ref={uploadRef} uploaderType={'DRAGDROP'}></FileUploader>
                </Form.Item>
            </Form>
        </Modal>
        <Table size={'small'}
               columns={buildColumns()}
               dataSource={componentState.data}
               loading={componentInfo.status === DataStatus.Loading}></Table>
    </Card>
}

export default ListTable(TransferDocuments);