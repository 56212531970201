/* eslint-disable react/jsx-key */
import React from 'react'
import { connect } from 'react-redux'
import {
    // fetchTransferListRequest,
    // fetchTransferListSuccess,
    // fetchTransferList,
    updateNewTransferCreation,
    updateNewTransferCreationBeneficiary,
    createNewTransfer,
    // createNewTransferInDatabase,
    // createNewTransfer,
    // selectTransferUi,
    // setTransferSelectId,
    // loadTransferDetailsRequest,
    // loadTransferDetailsSuccess,
    // startNewTransferCreation,
    // updateNewTransferCreationTransferType,
    // updateNewTransferCreation,
    // newTransferCreationDatabaseStart,
    // newTransferCreationDatabaseSuccess
} from '../../redux/actions/transfers_dashboard'

import withStyles from '@material-ui/core/styles/withStyles'
import dashboardStyle from '../../views/Pages/DashboardPageStyle'
import { injectIntl } from 'react-intl'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'

// import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

//core for react-intl
// import { FormattedMessage } from "react-intl";
// import CustomReactSelect from "../Forms/CustomReactSelect/CustomReactSelect";
// import { VectorMap } from "react-jvectormap";
import { API } from 'aws-amplify'

// import CustomInput from "components/CustomInput/CustomInput.jsx";
// import DashboardTransferStep1 from "./DashboardTransferStep1";
import Table from 'components/Table/Table.jsx'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const moment = require('moment')
const axios = require('axios')
const uuidv4 = require('uuid/v4')

class DashboardTransferStep2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            current_step: 2,
            time: {},
            seconds: 1,
        }

        this.timer = 0
    }

    async componentDidMount() {
        this.props.setCallable(this.handle_transfer_creation)
        this.props.setRateState(this.fetchNewRate)

        const remittingAccountDetails = await API.post(
            'transfers',
            '/remitting-account/get-details-new',
            {
                body: {
                    currencyId: this.props.transfers_dashboard.new_transfer_data
                        .currency_from.id,
                    teamId: this.props.app_state.current_team.id,
                    clientId: this.props.app_state.current_client.id,
                },
            }
        )
            .then(response => {
                console.log(response)
                return response
            })
            .catch(error => {
                console.log(error)
            })

        this.props.updateNewTransferCreation(
            'remitting_bank_account_detail',
            remittingAccountDetails.entityBanksAccountResults
        )
        this.props.updateNewTransferCreation(
            'remitting_bank_detail',
            remittingAccountDetails.entityBanksResults
        )

        if (this.props.transfers_dashboard.current_step === 2) {
            this.props.startTimer()
        }
        if (this.props.transfers_dashboard.current_step !== 2) {
            this.props.stopTimer()
            this.props.go = false
        }
    }

    componentWillUnmount() {
        //this.go = false;
        this.props.stopTimer()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //this.startTimer();
        // if prefill changes, clear new transfer data and then go back to the first step
        if (prevProps.prefill !== this.props.prefill) {
            // clearNewTransfer in DashboardTransfer
            this.props.switch_to_step1()
        }
    }

    secondsToTime = secs => {
        let hours = Math.floor(secs / (60 * 60))

        let divisor_for_minutes = secs % (60 * 60)
        let minutes = Math.floor(divisor_for_minutes / 60)

        let divisor_for_seconds = divisor_for_minutes % 60
        let seconds = Math.ceil(divisor_for_seconds)

        let obj = {
            h: hours,
            m: minutes,
            s: seconds,
        }
        return obj
    }

    startTimer = () => {
        //console.log(this.props.transfers_dashboard.current_step === 2);
        if (this.props.transfers_dashboard.current_step === 2) {
            this.go = true
        }

        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000)
            //this.props.updateNewTransferCreation("current_rate_locked", false);
        }
    }

    // countDown = () => {
    //   //go means this is step 2, we start count down

    //   let seconds = this.state.seconds - 1;
    //   //console.log(this.go);
    //   if (this.go) {
    //     this.setState({
    //       time: this.secondsToTime(seconds),
    //       seconds: seconds
    //     });
    //     //this.props.updateNewTransferCreation("current_rate_ttl", seconds);

    //     if (seconds === 0) {
    //       this.fetchNewRate();
    //       this.setState({
    //         seconds: 30
    //       });
    //       //this.props.updateNewTransferCreation("current_rate_ttl", 30);
    //       //this.fetchNewRate();
    //     }
    //     //console.log(this.state.seconds);
    //   }
    // };

    // stopTimer = () => {
    //   this.go = false;
    //   this.setState({
    //     seconds: 0,
    //     time: 0
    //   });
    //   clearInterval(this.timer);
    //   this.timer = null;
    // };

    fetchNewRate = () => {
        let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
        axios
            .get(url, {
                params: {
                    currencies: this.props.transfers_dashboard.new_transfer_data
                        .currency_to.iso_alpha_3,
                    source: this.props.transfers_dashboard.new_transfer_data
                        .currency_from.iso_alpha_3,
                    format: '1',
                },
            })
            .then(response => {
                let key = `${this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}${this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}`
                let rate_string = response.data.quotes[key]

                // let amount_to = Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.amount_to);
                let rate = Number.parseFloat(rate_string)
                let rate_adjust =
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client
                            .default_rate_division
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_entity
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_team
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_staff
                    )
                let adjusted_rate = rate - rate * rate_adjust

                // Add Credit Card Rate Adjustment
                if (
                    this.props.transfers_dashboard.new_transfer_data
                        .payment_method === 'worldpay'
                ) {
                    adjusted_rate = adjusted_rate - adjusted_rate * 0.02
                }

                if (
                    this.props.transfers_dashboard.new_transfer_data
                        .currency_from_id ==
                    this.props.transfers_dashboard.new_transfer_data
                        .currency_to_id
                ) {
                    adjusted_rate = rate
                }

                // handle to_amount
                if (
                    this.props.transfers_dashboard.new_transfer_data
                        .amount_lock === 'from_amount'
                ) {
                    let amount_from = Number.parseFloat(
                        this.props.transfers_dashboard.new_transfer_data
                            .amount_from
                    )
                    let amount_to = amount_from * adjusted_rate
                    this.props.updateNewTransferCreation(
                        'amount_to',
                        amount_to.toFixed(2)
                    )
                }

                // handle from_amount
                if (
                    this.props.transfers_dashboard.new_transfer_data
                        .amount_lock === 'to_amount'
                ) {
                    let amount_to = Number.parseFloat(
                        this.props.transfers_dashboard.new_transfer_data
                            .amount_to
                    )
                    let amount_from = amount_to / adjusted_rate
                    this.props.updateNewTransferCreation(
                        'amount_from',
                        amount_from.toFixed(2)
                    )
                }
                this.props.updateNewTransferCreation(
                    'client_rate',
                    adjusted_rate
                )
            })
            .catch(error => {
                console.log(error)
            })
    }

    getNextBusinessDay(d, n) {
        d = new Date(d.getTime())
        var day = d.getDay()
        d.setDate(
            d.getDate() +
                n +
                (day === 6 ? 2 : +!day) +
                Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2
        )
        return d
    }
    async getWorldPayPaymentURL() {
        console.log(this.props.transfers_dashboard)
        if (
            this.props.transfers_dashboard.new_transfer_data.currency_from ==
            undefined
        )
            return

        let uuid_number = uuidv4()
        this.props.updateNewTransferCreation(
            'payment_method_reference',
            uuid_number
        )

        console.log(
            this.props.transfers_dashboard.new_transfer_data.amount_from
        )
        console.log(
            Number.parseFloat(
                this.props.transfers_dashboard.new_transfer_data.amount_from
            ) * 100 || ''
        )
        let from_amount =
            // Math.ceil(this.props.transfers_dashboard.new_transfer_data.amount_from) * 100 || "";
            Number.parseFloat(
                this.props.transfers_dashboard.new_transfer_data.amount_from
            ) * 100 || ''

        let from_currency =
            this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase() ||
            ''
        let clientObj =
            this.props.transfers_dashboard.new_transfer_data.client || ''

        let url = await API.post('worldpay', `/get-payment-url`, {
            body: {
                from_currency: from_currency,
                from_amount: from_amount,
                order_code: uuid_number,
                client: clientObj,
            },
        })
            .then(response => {
                let res = JSON.stringify(
                    response.paymentService.reply[0].orderStatus[0].reference[0]
                )
                var firstExplode = res.split(',')
                var res1 = firstExplode[0].substring(
                    6,
                    firstExplode[0].length - 1
                )
                res1 = res1.concat(
                    '&successURL=https%3A%2F%2Fportal-api.forexworldwide.com%2Fworldpay%2Fresponse%2Fsuccess&pendingURL=https%3A%2F%2Fportal-api.forexworldwide.com%2Fworldpay%2Fresponse%2Fpending&failureURL=https%3A%2F%2Fportal-api.forexworldwide.com%2Fworldpay%2Fresponse%2Ffailure&cancelURL=https%3A%2F%2Fportal-api.forexworldwide.com%2Fworldpay%2Fresponse%2Fcancel&errorURL=https%3A%2F%2Fportal-api.forexworldwide.com%2Fworldpay%2Fresponse%2Ferror'
                )
                this.props.updateNewTransferCreation('payment_method_url', res1)
                return res1
                // let rate = Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.client_rate);
                // let adjusted_rate_credit = rate + (rate * 0.02);

                // this.props.updateNewTransferCreation("current_rate", adjusted_rate_credit.toFixed(5));

                // let amount_to = Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.amount_to);
                // let amount_from = amount_to * adjusted_rate_credit;
                // this.props.updateNewTransferCreation("amount_from", amount_from.toFixed(5));
            })
            .catch(error => {
                console.log(error)
            })
        return url
    }

    handle_transfer_creation = async () => {
        let set_date = this.getNextBusinessDay(new Date(), 2)
        let settlement_date = set_date
            .toISOString()
            .slice(0, 10)
            .replace('T', ' ')

        if (
            this.props.transfers_dashboard.new_transfer_data.payment_method ===
            'worldpay'
        ) {
            let url = await this.getWorldPayPaymentURL()
            window.open(url)
        }

        // Step 0 - Email us
        // the following API call is working but the content might need to be modified
        API.post('email', `/interaction/main`, {
            body: {
                interaction: 'New Transfer Request',
                data: {
                    ...this.props.transfers_dashboard.new_transfer_data,
                    client_id: this.props.app_state.current_client.id,
                    client_nickname: this.props.app_state.current_client
                        .nickname,
                    record_created_datetime: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace('T', ' '),
                    // staff_id: this.props.app_state.current_staff.id,
                    transaction_datetime: new Date()
                        .toISOString()
                        .slice(0, 10)
                        .replace('T', ' '),
                    settlement_date: moment(set_date).format('DD/MM/YYYY'),
                    // client_rate: this.props.transfers_dashboard.new_transfer_data.client_rate,
                    status: '2',
                    from: 'Step 0 - Email us',
                    purpose: 'test only',
                },
                result: 'database submission in process',
            },
        })

        // console.log(settlement_date);
        // Step 1 - Email client
        // the following API call is working but the content might need to be modified

        let adjusted_remittance_reference = this.props.transfers_dashboard
            .new_transfer_data.remitting_bank_account_detail.reference_mandatory
            ? this.props.transfers_dashboard.new_transfer_data
                  .remitting_bank_account_detail.reference_mandatory
            : this.props.transfers_dashboard.new_transfer_data
                  .remitting_bank_account_detail.reference_prefix
                ? this.props.transfers_dashboard.new_transfer_data
                      .remitting_bank_account_detail.reference_prefix +
                  this.props.app_state.current_client.id
                : 'FS' +
                  this.props.app_state.current_client.id
                      .toString()
                      .padStart(5, '0')

        API.post('email', `/client/remittance-instructions`, {
            body: {
                id: this.props.app_state.current_client.id,
                rate: this.props.transfers_dashboard.new_transfer_data
                    .client_rate,
                settlement_date: moment(set_date).format('DD/MM/YYYY'),
                amount_to: this.props.transfers_dashboard.new_transfer_data
                    .amount_to,
                currency_to: this.props.transfers_dashboard.new_transfer_data
                    .currency_to.full_name,
                currency_to_iso3: this.props.transfers_dashboard
                    .new_transfer_data.currency_to.iso_alpha_3,
                amount_from: this.props.transfers_dashboard.new_transfer_data
                    .amount_from,
                currency_from: this.props.transfers_dashboard.new_transfer_data
                    .currency_from.full_name,
                currency_from_iso3: this.props.transfers_dashboard
                    .new_transfer_data.currency_from.iso_alpha_3,
                remitting_bank_id: this.props.transfers_dashboard
                    .new_transfer_data.remitting_bank_account_detail.id,
                eba_account_name: this.props.transfers_dashboard
                    .new_transfer_data.remitting_bank_account_detail
                    .eba_accout_name,
                remittance_reference: adjusted_remittance_reference,
                language_id: this.props.app_state.language_id,
                payment_method_reference: this.props.transfers_dashboard
                    .new_transfer_data.payment_method_reference,
                payment_method_url: this.props.transfers_dashboard
                    .new_transfer_data.payment_method_url,
            },
        })
            .then(response => {
                // console.log(response);
            })
            .catch(error => {
                console.log(error)
            })

        // Step 2 - Email beneficiary
        if (
            this.props.transfers_dashboard.new_transfer_data
                .checked_email_beneficiary
        ) {
            // "Email beneficiary " checkbox is working
            // the following API call is working but the content might need to be modified
            API.post('email', `/interaction/main`, {
                body: {
                    interaction: 'New Transfer Request',
                    data: {
                        from: 'Step 2 - Email beneficiary',
                        purpose: 'test only',
                    },
                    result: 'database submission in process',
                },
            })
        }

        // return;

        // Step 3 - Create transfer
        this.props.createNewTransfer({
            ...this.props.transfers_dashboard.new_transfer_data,
            nickname: `${
                this.props.transfers_dashboard.new_transfer_data.amount_from
            } ${
                this.props.transfers_dashboard.new_transfer_data
                    .currency_from_name
            } to ${
                this.props.transfers_dashboard.new_transfer_data.amount_to
            } ${
                this.props.transfers_dashboard.new_transfer_data
                    .currency_to_name
            } Settling ${moment(set_date).format('DD/MM/YYYY')}`,
            record_created_datetime: new Date()
                .toISOString()
                .slice(0, 10)
                .replace('T', ' '),
            transaction_datetime: new Date()
                .toISOString()
                .slice(0, 10)
                .replace('T', ' '),
            settlement_date: settlement_date,
            client_id: this.props.app_state.current_client.id,
            staff_id: this.props.app_state.current_client
                .responsible_staff_member,
            beneficiary_ids: this.props.transfers_dashboard.new_transfer_data
                .multiple_beneficiary_id,
        })
        this.props.on_proceed_click()
    }

    handleCheckboxToggle = name => event => {
        if (name === 'checked_email_beneficiary') {
            let checked_email_beneficiary = event.target.checked
            this.props.updateNewTransferCreation(
                'checked_email_beneficiary',
                checked_email_beneficiary
            )
        }
    }

    render() {
        const { classes } = this.props
        if (
            !this.props.transfers_dashboard.new_transfer_data.currency_from ||
            !this.props.transfers_dashboard.new_transfer_data.currency_to
        ) {
            return null
        }

        let request_summary = `You sell
      ${this.props.intl.formatNumber(
          this.props.transfers_dashboard.new_transfer_data.amount_from,
          {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }
      )} ${this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}
      to buy ${this.props.intl.formatNumber(
          this.props.transfers_dashboard.new_transfer_data.amount_to,
          {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }
      )} ${this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}
      `

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <h4>New Transfer Request - Confirm your details</h4>
                </GridItem>
                <GridItem>
                    <Table
                        tableData={[
                            [
                                'You Sell',
                                <React.Fragment>
                                    <div
                                        className={`currency-flag currency-flag-${this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toLowerCase()}`}
                                    />{' '}
                                    {this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}{' '}
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard
                                            .new_transfer_data.amount_from,
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'You Buy',
                                <React.Fragment>
                                    <div
                                        className={`currency-flag currency-flag-${this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toLowerCase()}`}
                                    />{' '}
                                    {this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}{' '}
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard
                                            .new_transfer_data.amount_to,
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'Exchange Rate',
                                <React.Fragment>
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard
                                            .new_transfer_data.client_rate,
                                        {
                                            minimumFractionDigits: 5,
                                            maximumFractionDigits: 5,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'Beneficiary',
                                <React.Fragment>
                                    {this.props.transfers_dashboard
                                        .new_transfer_data
                                        .multiple_beneficiary_id[0]
                                        .checked_beneficiary_details ===
                                    true ? (
                                        <React.Fragment>
                                            To Be Advised
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {
                                                this.props.transfers_dashboard
                                                    .new_transfer_data
                                                    .multiple_beneficiary_id[0]
                                                    .beneficiary.ben_legal_name
                                            }{' '}
                                            <br />
                                            {/* Hide email beneficiary checkbox because it's useless now*/}
                                            {/* {
                        //  !!this.props.transfers_dashboard.new_transfer_data
                        //   .multiple_beneficiary_id[0].beneficiary
                        //   .ben_email_main && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checked={
                                this.props.transfers_dashboard.new_transfer_data
                                  .checked_email_beneficiary
                              }
                              onClick={this.handleCheckboxToggle(
                                "checked_email_beneficiary"
                              )}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                              disabled={(this.props.transfers_dashboard.new_transfer_data
                                  .multiple_beneficiary_id[0].beneficiary
                                  .ben_email_main)
                                  ? false
                                  : true
                              }
                            />
                          }
                          label={
                            (this.props.transfers_dashboard.new_transfer_data
                              .multiple_beneficiary_id[0].beneficiary
                              .ben_email_main)
                              ? "Email beneficiary (" +
                                this.props.transfers_dashboard.new_transfer_data
                                  .multiple_beneficiary_id[0].beneficiary
                                  .ben_email_main +
                                ")"
                              : "Email beneficiary (User hasn't provided beneficiary's email)"
                          }
                        />
                        //  )
                      } */}
                                            {/* Paying to {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bank_legal_name} */}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'Purpose of Payment',
                                `${
                                    this.props.transfers_dashboard
                                        .new_transfer_data
                                        .multiple_beneficiary_id[0]
                                        .purpose_of_payment_detail_nickname
                                }`,
                            ],
                            [
                                'Payment Detail',
                                `${
                                    this.props.transfers_dashboard
                                        .new_transfer_data
                                        .multiple_beneficiary_id[0].detail_1
                                }`,
                            ],
                            ['Request Summary', `${request_summary}`],
                        ]}
                    />
                </GridItem>
            </GridContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
        transfers_dashboard: state.transfers_dashboard,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewTransferCreation: (key, value) => {
            dispatch(updateNewTransferCreation(key, value))
        },
        updateNewTransferCreationBeneficiary: array => {
            dispatch(updateNewTransferCreationBeneficiary(array))
        },
        createNewTransfer: data => {
            dispatch(createNewTransfer(data))
        },
    }
}

const DashboardTransferStep2Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(DashboardTransferStep2))

export default injectIntl(DashboardTransferStep2Container)
