import React from 'react'
import { API } from 'aws-amplify'

// core components
import Wizard from 'components/Wizard/Wizard.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

// import Step1ClientContainer from "./WizardSteps/Step1ClientContainer.js";
import Step2PayingToContainer from './WizardSteps/Step2PayingToContainer'
import Step3PayingFromContainer from './WizardSteps/Step3PayingFromContainer'
import Step4AmountsContainer from './WizardSteps/Step4AmountsContainer'
import Step5ConfirmContainer from './WizardSteps/Step5ConfirmContainer'
import { withRouter } from 'react-router-dom'

import { FormattedMessage } from 'react-intl'
const moment = require('moment')

//setup for language components
let languageObj_en_json = require('../../../translations/en.json')
let languageObj_zh_json = require('../../../translations/zh.json')
let languageObj_de_json = require('../../../translations/de.json')
let languageObj_es_json = require('../../../translations/es.json')
let languageObj_fr_json = require('../../../translations/fr.json')
let languageObj_it_json = require('../../../translations/it.json')
let languageObj_pt_json = require('../../../translations/pt.json')

class CreateNewTransferWizard extends React.Component {
    getNextBusinessDay(d, n) {
        d = new Date(d.getTime())
        var day = d.getDay()
        d.setDate(
            d.getDate() +
                n +
                (day === 6 ? 2 : +!day) +
                Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2
        )
        return d
    }

    componentDidMount() {
        this.props.startNewTransferCreation()
    }

    finished = async () => {
        let set_date = this.getNextBusinessDay(new Date(), 2)
        let settlement_date = set_date
            .toISOString()
            .slice(0, 10)
            .replace('T', ' ')

        API.post('email', `/interaction/main`, {
            body: {
                interaction: 'New Transfer Request',
                data: {
                    ...this.props.transfers.new_transfer_data,
                    record_created_datetime: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace('T', ' '),
                    client_id: this.props.app_state.current_client.id,
                    transaction_datetime: new Date()
                        .toISOString()
                        .slice(0, 10)
                        .replace('T', ' '),
                    settlement_date: moment(set_date).format('DD/MM/YYYY'),
                    client_rate: this.props.transfers.new_transfer_data
                        .current_rate,
                    status: '2',
                    language_id: this.props.app_state.current_client
                        .language_id,
                },
                result: 'database submission in process',
            },
        })

        API.post('email', `/client/remittance-instructions`, {
            body: {
                id: this.props.app_state.current_client.id,
                rate: this.props.transfers.new_transfer_data.current_rate,
                settlement_date: moment(set_date).format('DD/MM/YYYY'),
                amount_to: this.props.transfers.new_transfer_data.amount_to,
                currency_to: this.props.transfers.new_transfer_data.currency_to
                    .full_name,
                currency_to_iso3: this.props.transfers.new_transfer_data
                    .currency_to.iso_alpha_3,
                currency_from: this.props.transfers.new_transfer_data
                    .currency_from.full_name,
                currency_from_iso3: this.props.transfers.new_transfer_data
                    .currency_from.iso_alpha_3,
                amount_from: this.props.transfers.new_transfer_data.amount_from,
                eba_account_name: this.props.transfers.new_transfer_data
                    .remitting_bank_account_detail.eba_accout_name,
                remitting_bank_id: this.props.transfers.new_transfer_data
                    .remitting_bank,
                remittance_reference:
                    this.props.transfers.new_transfer_data
                        .remitting_bank_account_detail.reference_prefix +
                    this.props.app_state.current_client.id,
                language_id: this.props.app_state.current_client.language_id,
                payment_method_id: this.props.transfers.new_transfer_data
                    .payment_method_id,
                payment_method_url: this.props.transfers.new_transfer_data
                    .payment_method_url,
            },
        })
            .then(response => {
                console.log(response)
                // this.setState({
                //   remittance_advice_email_sent: true,
                //   remittance_advice_email_sending: false
                // });
            })
            .catch(error => {
                console.log(error)
            })

        await this.props.createNewTransfer({
            ...this.props.transfers.new_transfer_data,
            nickname: `${
                this.props.transfers_dashboard.new_transfer_data.amount_from
            } ${
                this.props.transfers_dashboard.new_transfer_data
                    .currency_from_name
            } to ${
                this.props.transfers_dashboard.new_transfer_data.amount_to
            } ${
                this.props.transfers_dashboard.new_transfer_data
                    .currency_to_name
            } Settling ${moment(set_date).format('DD/MM/YYYY')}`,
            record_created_datetime: new Date()
                .toISOString()
                .slice(0, 10)
                .replace('T', ' '),
            transaction_datetime: new Date()
                .toISOString()
                .slice(0, 10)
                .replace('T', ' '),
            settlement_date: settlement_date,
            client_id: this.props.app_state.current_client.id,
            client_rate: this.props.transfers.new_transfer_data.current_rate,
            staff_id: this.props.app_state.current_client
                .responsible_staff_member,
            beneficiary_ids: this.props.transfers.new_transfer_data
                .multiple_beneficiary_id,
            status: '2',
        })

        if (this.props.transfers.new_transfer_data.payment_method_id == '2') {
            window.location.replace(
                decodeURIComponent(
                    this.props.transfers.new_transfer_data.payment_method_url
                )
            )
        } else {
            this.props.history.push(`/transfers/list`)
        }
    }

    render() {
        //console.log(this.props)
        try {
            if (this.props.app_state.current_client.account_status !== 2) {
                // if (this.props.app_state.current_client.account_status === 1)
                //   let account_status ="PENDING";
                // else if ((this.props.app_state.current_client.account_status === 3))
                //   let account_status ="DECLINED";
                // else if ((this.props.app_state.current_client.account_status === 4))
                //   let account_status ="SUSPEND";

                let languageObj = ''
                switch (this.props.language.language_current_ui) {
                    case 'en':
                        languageObj = languageObj_en_json
                        break
                    case 'es':
                        languageObj = languageObj_es_json
                        break
                    case 'de':
                        languageObj = languageObj_de_json
                        break
                    case 'zh':
                        languageObj = languageObj_zh_json
                        break
                    case 'fr':
                        languageObj = languageObj_fr_json
                        break
                    case 'it':
                        languageObj = languageObj_it_json
                        break
                    case 'pt':
                        languageObj = languageObj_pt_json
                        break
                    default:
                        languageObj = languageObj_en_json
                }
                return (
                    <GridContainer justify="center">
                        {/* if the client status is not 2 (approved) then display warning */}

                        {/* //warning message on the top

              /<GridItem alignItems="center">
              <p><em style={{ color: "red"}}>
                <span>Warning. Your current status is
                  {!!(this.props.app_state.current_client.account_status === 1) && ("PENDING")

                  }
                  {!!(this.props.app_state.current_client.account_status === 2) && ("APPROVED")

                  }
                  {!!(this.props.app_state.current_client.account_status === 3) && ("DECLINED")

                  }
                  {!!(this.props.app_state.current_client.account_status === 4) && ("SUSPEND")

                  }
                  {" "}<br/>
                  You will be able to submit this transfer, but it will not be finalised until your status is "APPROVED"</span>
              </em></p>
            </GridItem>

            */}

                        <GridItem xs={12} sm={12}>
                            <Wizard
                                validate
                                steps={[
                                    // {
                                    //   stepName: "Client",
                                    //   stepComponent: Step1ClientContainer,
                                    //   stepId: "client"
                                    // },
                                    {
                                        stepName:
                                            languageObj[
                                                'newTransfer.stepName1'
                                            ],
                                        stepComponent: Step2PayingToContainer,
                                        stepId: 'paying_to',
                                    },
                                    {
                                        stepName:
                                            languageObj[
                                                'newTransfer.stepName2'
                                            ],
                                        stepComponent: Step3PayingFromContainer,
                                        stepId: 'paying_from',
                                    },
                                    /*{
                    stepName: "Transfer Amounts",
                    stepComponent: Step4AmountsContainer,
                    stepId: "transfer_amounts"
                  },*/
                                    {
                                        stepName:
                                            languageObj[
                                                'newTransfer.stepName3'
                                            ],
                                        stepComponent: Step5ConfirmContainer,
                                        stepId: 'confirm',
                                    },
                                ]}
                                title={
                                    <FormattedMessage
                                        id="newTransfer.title"
                                        defaultMessage={`Start a New FX Transfer`}
                                    />
                                    //"Start a New FX Transfer"
                                }
                                subtitle={
                                    <FormattedMessage
                                        id="newTransfer.subtitle"
                                        defaultMessage={`Transfer from one currency to make payment in another`}
                                    />
                                    //"Lodge a Foreign Exchange (FX) transfer request to payment from one currency to another.""
                                    //"Provider Payment - payment to agent or provider for an education related invoice."
                                }
                                warning={
                                    "Warning: Your current status hasn't been approved yet. You will be able to submit this transfer, but it will not be finalised until your status is APPROVED"
                                }
                                finishButtonClick={() => this.finished()}
                            />
                        </GridItem>
                    </GridContainer>
                )
            }

            let languageObj = ''
            switch (this.props.language.language_current_ui) {
                case 'en':
                    languageObj = languageObj_en_json
                    break
                case 'es':
                    languageObj = languageObj_es_json
                    break
                case 'de':
                    languageObj = languageObj_de_json
                    break
                case 'zh':
                    languageObj = languageObj_zh_json
                    break
                case 'fr':
                    languageObj = languageObj_fr_json
                    break
                case 'it':
                    languageObj = languageObj_it_json
                    break
                case 'pt':
                    languageObj = languageObj_pt_json
                    break
                default:
                    languageObj = languageObj_en_json
            }
            return (
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12}>
                        <Wizard
                            validate
                            steps={[
                                // {
                                //   stepName: "Client",
                                //   stepComponent: Step1ClientContainer,
                                //   stepId: "client"
                                // },
                                {
                                    stepName:
                                        languageObj['newTransfer.stepName1'],
                                    stepComponent: Step2PayingToContainer,
                                    stepId: 'paying_to',
                                },
                                {
                                    stepName:
                                        languageObj['newTransfer.stepName2'],
                                    stepComponent: Step3PayingFromContainer,
                                    stepId: 'paying_from',
                                },
                                /*{
                  stepName: "Transfer Amounts",
                  stepComponent: Step4AmountsContainer,
                  stepId: "transfer_amounts"
                },*/
                                {
                                    stepName:
                                        languageObj['newTransfer.stepName3'],
                                    stepComponent: Step5ConfirmContainer,
                                    stepId: 'confirm',
                                },
                            ]}
                            title={
                                <FormattedMessage
                                    id="newTransfer.title"
                                    defaultMessage={`Start a New FX Transfer`}
                                />
                                //"Start a New FX Transfer"
                            }
                            subtitle={
                                <FormattedMessage
                                    id="newTransfer.subtitle"
                                    defaultMessage={`Transfer from one currency to make payment in another`}
                                />
                                //"Lodge a Foreign Exchange (FX) transfer request to payment from one currency to another.""
                                //"Provider Payment - payment to agent or provider for an education related invoice."
                            }
                            warning={''}
                            finishButtonClick={() => this.finished()}
                        />
                    </GridItem>

                    {/* if the client status is not 2 (approved) then display warning */}
                </GridContainer>
            )
        } catch (err) {
            return (
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12}>
                        <Wizard
                            validate
                            steps={[
                                // {
                                //   stepName: "Client",
                                //   stepComponent: Step1ClientContainer,
                                //   stepId: "client"
                                // },
                                {
                                    stepName: 'Paying To',
                                    stepComponent: Step2PayingToContainer,
                                    stepId: 'paying_to',
                                },
                                {
                                    stepName: 'Paying From',
                                    stepComponent: Step3PayingFromContainer,
                                    stepId: 'paying_from',
                                },
                                /*{
                  stepName: "Transfer Amounts",
                  stepComponent: Step4AmountsContainer,
                  stepId: "transfer_amounts"
                },*/
                                {
                                    stepName: 'Confirm',
                                    stepComponent: Step5ConfirmContainer,
                                    stepId: 'confirm',
                                },
                            ]}
                            title={
                                <FormattedMessage
                                    id="newTransfer.title"
                                    defaultMessage={`Start a New FX Transfer`}
                                />
                                //"Start a New FX Transfer"
                            }
                            subtitle={
                                <FormattedMessage
                                    id="newTransfer.subtitle"
                                    defaultMessage={`Transfer from one currency to make payment in another`}
                                />
                                //"Lodge a Foreign Exchange (FX) transfer request to payment from one currency to another.""
                                //"Provider Payment - payment to agent or provider for an education related invoice."
                            }
                            warning={''}
                            finishButtonClick={() => this.finished()}
                        />
                    </GridItem>

                    {/* if the client status is not 2 (approved) then display warning */}
                </GridContainer>
            )
        }
    }
}

export default withRouter(CreateNewTransferWizard)
