import React from 'react'

import { connect } from 'react-redux'
// import BeneficiaryNew from "./BeneficiaryNew";
import {
    createNewBeneficiary,
    createNewBeneficiaryStart,
    createNewBeneficiaryComplete,
    cancelCreateNewBeneficiary,
    selectBeneficiaryUi,
    fetchBeneficiaryList,
} from '../../redux/actions/beneficiaries'

import withStyles from '@material-ui/core/styles/withStyles'
// core components
import Wizard from 'components/Wizard/Wizard.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import { cardTitle } from '../../assets/jss/material-dashboard-pro-react.jsx'
import { withRouter } from 'react-router-dom'
import { API, Storage } from 'aws-amplify'
import Button from 'components/CustomButtons/Button.jsx'
import Card from '../../components/Card/Card.jsx'
import CardHeader from '../Card/CardHeader'
import CardBody from '../../components/Card/CardBody.jsx'

import CustomReactSelect from '../Forms/CustomReactSelect/CustomReactSelect'
import CustomInput from 'components/CustomInput/CustomInput.jsx'

const entityNewStyles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px',
    },
    registerButton: {
        float: 'right',
    },
}

class BeneficiaryNew extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currencies_list_priority: [],
            show_iban: false,
            show_bsb: false,
            show_sort_code: false,
            show_swift_code: true,
            show_aba: false,
            show_account_number: true,

            country_list: [],
            currencies_list: [],

            showDocumentUpload: false,
        }
    }
    componentDidMount() {
        //this.props.startNewClientCreation();
        API.get('currencies', `/currencies/get-list-priority`)
            .then(response => {
                //console.log(response)
                this.setState({
                    currencies_list_priority: response,
                })
            })
            .catch(error => {
                console.log(error)
            })

        API.get('countries', `/countries/list_not_deleted`)
            .then(response => {
                this.setState({
                    country_list: response,
                })
            })
            .catch(error => {
                console.log(error)
            })

    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        // Test User Client Access to Send Transfer
        // console.log("TESTING USER CLIENT")
        if (this.props.app_state && this.props.app_state.current_client) {
            if (this.props.app_state.current_client.userClient) {
            // console.log("USER CLIENT")
            if (this.props.app_state.current_client.userClientResults.addBeneficiary) {
                // console.log("USER CLIENT - ADD BENEFICIARY")
            } else {
                this.props.history.push(`/dashboard`);
            }
    
            }
        }

    }

    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }

    // function that verifies if a value is larger than a number or not
    isLargerThanNumber(value, number) {
        if (value !== '' && value >= number) {
            return true
        }
        return false
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
    }

    handleCustomReactSelectChange = (
        name,
        previous_from_currency,
        previous_to_currency
    ) => value => {
        var option_value
        var option_label
        // var beneficiary_array = this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id || [{}];
        if (value === null) {
            option_value = null
            option_label = null
        } else {
            option_value = value.value
            option_label = value.label
        }

        if (name === 'bank_country') {
            // console.log(value.value)
            let country_dataset = this.state.country_list;
            console.log(country_dataset);
            for (let i = 0; i < country_dataset.length; i++) {
                if (country_dataset[i].id === option_value) {
                    // console.table(country_dataset[i])
                    this.setState({
                        show_swift_code: true,
                        show_account_number: true,
                    })

                    //iban check
                    if (country_dataset[i].use_iban === 1) {
                        this.setState({
                            show_iban: true,
                        })
                    } else {
                        this.setState({
                            show_iban: false,
                        })
                    }

                    //bsb check
                    if (country_dataset[i].use_bsb === 1) {
                        this.setState({
                            show_bsb: true,
                        })
                    } else {
                        this.setState({
                            show_bsb: false,
                        })
                    }

                    //aba_routing check
                    if (country_dataset[i].use_aba === 1) {
                        this.setState({
                            show_aba: true,
                        })
                    } else {
                        this.setState({
                            show_aba: false,
                        })
                    }

                    //sort_code check
                    if (country_dataset[i].use_sort_code === 1) {
                        this.setState({
                            show_sort_code: true,
                        })
                    } else {
                        this.setState({
                            show_sort_code: false,
                        })
                    }

                    //sort_code check
                    if (country_dataset[i].use_account_number === 1) {
                        this.setState({
                            show_account_number: true,
                        })
                    } else {
                        this.setState({
                            show_account_number: false,
                        })
                    }

                    // transit_code
                    if (country_dataset[i].use_transit_code === 1) {
                        this.setState({
                            show_transit_code: true,
                        })
                    } else {
                        this.setState({
                            show_transit_code: false,
                        })
                    }

                    // bank_code
                    if (country_dataset[i].use_bank_code === 1) {
                        this.setState({
                            show_bank_code: true,
                        })
                    } else {
                        this.setState({
                            show_bank_code: false,
                        })
                    }
                }
            }
        }

        this.setState({
            [name]: option_value,
        })
    }

    newBeneficiaryChange(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'email':
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + ' _state']: 'error' })
                }
                break
            case 'equalTo':
                if (
                    this.compare(
                        event.target.value,
                        this.state[stateNameEqualTo]
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
    }

    isValidateBeneficiary() {
        if (
            this.state.bank_country == '' ||
            typeof this.state.bank_country === 'undefined'
        ) {
            this.setState({ bank_country_state: 'error' })
        } else {
            this.setState({ bank_country_state: 'success' })
        }

        if (
            this.state.ben_legal_name == '' ||
            typeof this.state.ben_legal_name === 'undefined'
        ) {
            this.setState({ ben_legal_name_state: 'error' })
        } else {
            this.setState({ ben_legal_name_state: 'success' })
        }

        if (
            this.state.account_currency == '' ||
            typeof this.state.account_currency === 'undefined'
        ) {
            this.setState({ account_currency_state: 'error' })
        } else {
            this.setState({ account_currency_state: 'success' })
        }

        if (
            this.state.ben_legal_name == '' ||
            typeof this.state.ben_legal_name === 'undefined' ||
            this.state.account_currency == '' ||
            typeof this.state.account_currency === 'undefined' ||
            this.state.bank_country == '' ||
            typeof this.state.bank_country === 'undefined'
        ) {
            console.log('validate False')
            return false
        } else {
            console.log('validate true')
            return true
        }
    }

    finished = async () => {
        // console.log(this.props);

        if (this.isValidateBeneficiary()) {
            const {
                bank_country,
                ben_legal_name,
                ben_email_main,
                ben_address_line_1,
                ben_address_line_2,
                ben_address_suburb,
                ben_address_state,
                ben_address_postcode,
                ben_address_country,
                bank_legal_name,
                iban,
                account_currency,
                bsb_code,
                swift_code,
                account_number,
                aba_routing_number,
                sort_code,
                bank_address_line_1,
                bank_address_suburb,
                bank_address_state,
                bank_address_postcode,
            } = this.state

            try {
                await this.createBeneficiaryIntoDatabase({
                    client_id: this.props.app_state.current_client.id,
                    nickname: ben_legal_name,
                    ben_legal_name: ben_legal_name,
                    ben_email_main: ben_email_main,
                    ben_address_line_1: ben_address_line_1,
                    ben_address_line_2: ben_address_line_2,
                    ben_address_suburb: ben_address_suburb,
                    ben_address_state: ben_address_state,
                    ben_address_postcode: ben_address_postcode,
                    ben_address_country: ben_address_country,
                    bank_legal_name: bank_legal_name,
                    bank_address_country: bank_country,
                    iban: iban,
                    account_currency: account_currency,
                    bsb_code: bsb_code,
                    swift_code: swift_code,
                    account_number: account_number,
                    aba_routing_number: aba_routing_number,
                    sort_code: sort_code,
                    bank_address_line_1,
                    bank_address_suburb,
                    bank_address_state,
                    bank_address_postcode,
                })
            } catch (e) {
                console.log('error', e)
                this.setState({ isLoading: false })
            }
            this.props.history.push(`/beneficiaries/view-beneficiaries`)
        }
    }

    createBeneficiaryIntoDatabase = async data => {
        await this.props.createNewBeneficiary({
            ...data,
            // account_currency: data.account_currency.split(',')[1],
        })

        await API.get('beneficiaries', `/beneficiaries/get`)
            .then(response => {
                for (let i = response.length - 1; i > -1; i--) {
                    //console.log(response[i].account_currency);
                    if (
                        response[i].account_currency !== this.state.to_currency
                    ) {
                        //response.remove(i);
                        response.splice(i, 1) // Removes the (i)th element of response
                        //console.log("remove index"+i)
                    }
                }
                //console.log(response);
                this.setState({
                    beneficiaries_list: response,
                })
            })
            .catch(error => {
                console.log(error)
            })

        this.setState({
            ben_legal_name: '',
            ben_email_main: '',
            ben_address_line_1: '',
            ben_address_line_2: '',
            ben_address_suburb: '',
            ben_address_state: '',
            ben_address_postcode: '',
            ben_address_country: '',

            bank_legal_name: '',
            iban: '',
            account_currency: '',
            bsb_code: '',
            swift_code: '',
            account_number: '',
            aba_routing_number: '',
            sort_code: '',
            bank_address_line_1: '',
            bank_address_suburb: '',
            bank_address_state: '',
            bank_address_postcode: '',
        })

        return
    }

    render() {
        const { classes } = this.props
        // const form_valid = this.isValidateBeneficiary();


        const sortedCountry = this.state.country_list.sort((a,b) => a.full_name.localeCompare(b.full_name));
        const country_list_select_options = sortedCountry.map(
            item => ({
                value: item.id,
                alt: item.full_name,
                label: (
                    <React.Fragment>
                        <span>
                            <img
                                src={
                                    item.iso_alpha_2 !== ''
                                        ? require(`../../assets/img/flags/${
                                              item.iso_alpha_2
                                          }.png`)
                                        : ''
                                }
                            />
                        </span>{' '}
                        &nbsp; {item.full_name}{' '}
                    </React.Fragment>
                ),
            })
        )
        //console.log(this.state.currencies_list_priority)
        //console.log(this.state.currencies_list)
        const select_currency_select_options = this.state.currencies_list_priority.map(
            item => ({
                value: item.id,
                alt: item.full_name,
                label: (
                    <React.Fragment>
                        <span
                            className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`}
                        />
                        &nbsp;
                        {item.iso_alpha_3 + ': ' + item.full_name}
                    </React.Fragment>
                ),
            })
        )

        return (
            <React.Fragment>
                {/* <GridContainer>
                    <GridItem xs={12} sm={4}>
                        <h3>Create a New Beneficiary</h3>
                    </GridItem>
                </GridContainer> */}
                <GridContainer justify="left">
                    <GridItem xs={12} sm={4}>
                        <Card>
                            <CardHeader>
                                <h4>
                                    Step 1
                                </h4>
                                <p>
                                    Please selet the currency to be paid to this beneficiary, 
                                    and the country where the bank is located.
                                </p>
                                <hr/>
                            </CardHeader>
                            <CardBody>
                                <CustomReactSelect
                                    label={<span style={{fontWeight: 400}}>Currency</span>}
                                    options={select_currency_select_options}
                                    value={this.state.account_currency}
                                    onChange={this.handleCustomReactSelectChange(
                                        'account_currency'
                                    )}
                                    isClearable={false}
                                    error={
                                        this.state.account_currency_state ===
                                        'error'
                                    }
                                />
                                <br/>

                                <CustomReactSelect
                                    label={<span style={{fontWeight: 400}}>Bank Country</span>}
                                    options={country_list_select_options}
                                    value={this.state.bank_country}
                                    onChange={this.handleCustomReactSelectChange(
                                        'bank_country'
                                    )}
                                    isClearable={false}
                                    error={
                                        this.state.bank_country_state ===
                                        'error'
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

                <hr />
                {this.state.account_currency && this.state.bank_country && 
                <GridContainer>
                    <GridItem xs={12} md={6}>
                        <Card style={{ height: '95%' }}>
                            <CardHeader>
                                <h4>
                                    Beneficiary Details
                                </h4>
                                <p>
                                    Provide details here of the individual or entity that will receive the funds.
                                </p>
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    success={
                                        this.state.ben_legal_name_state ===
                                        'success'
                                    }
                                    error={
                                        this.state.ben_legal_name_state ===
                                        'error'
                                    }
                                    labelText={
                                        <span>Beneficiary Name</span>
                                    }
                                    id="ben_legal_name"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: this.state.ben_legal_name,
                                        onChange: event =>
                                            this.newBeneficiaryChange(
                                                event,
                                                'ben_legal_name',
                                                'length',
                                                3
                                            ),
                                    }}
                                />
                                {/* <CustomInput
                                    success={
                                        this.state.ben_email_main_state ===
                                        'success'
                                    }
                                    error={
                                        this.state.ben_email_main_state ===
                                        'error'
                                    }
                                    labelText={
                                        <span>
                                            Beneficiary Email [optional]
                                        </span>
                                    }
                                    helpText="May be used to provide copy of payout confirmation to beneficiary.  Confirmation is required from you on each transfer."
                                    id="ben_email_main"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: this.state.ben_email_main,
                                        onChange: event =>
                                            this.newBeneficiaryChange(
                                                event,
                                                'ben_email_main',
                                                'length',
                                                3
                                            ),
                                    }}
                                /> */}
                                <br/>
                                <br/>
                                <CustomReactSelect
                                    label="Beneificary Address Country"
                                    options={country_list_select_options}
                                    value={this.state.ben_address_country}
                                    onChange={this.handleCustomReactSelectChange(
                                        'ben_address_country'
                                    )}
                                    isClearable={false}
                                />

                                <CustomInput
                                    success={
                                        this.state.ben_address_line_1_state ===
                                        'success'
                                    }
                                    error={
                                        this.state.ben_address_line_1_state ===
                                        'error'
                                    }
                                    labelText={
                                        <span>Address (Line 1)</span>
                                    }
                                    id="ben_address_line_1"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: this.state.ben_address_line_1,
                                        onChange: event =>
                                            this.newBeneficiaryChange(
                                                event,
                                                'ben_address_line_1',
                                                'length',
                                                3
                                            ),
                                    }}
                                />
                                <GridContainer justify="flex-start">
                                    <GridItem
                                        xs={12}
                                        md={
                                            this.state.ben_address_country === 9
                                                ? 6
                                                : 8
                                        }
                                    >
                                        <CustomInput
                                            success={
                                                this.state
                                                    .ben_address_suburb_state ===
                                                'success'
                                            }
                                            error={
                                                this.state
                                                    .ben_address_suburb_state ===
                                                'error'
                                            }
                                            labelText={
                                                <span>Suburb / Region</span>
                                            }
                                            id="ben_address_suburb"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: this.state
                                                    .ben_address_suburb,
                                                onChange: event =>
                                                    this.newBeneficiaryChange(
                                                        event,
                                                        'ben_address_suburb',
                                                        'length',
                                                        3
                                                    ),
                                            }}
                                        />
                                    </GridItem>
                                    {this.state.ben_address_country === 9 && (
                                        <GridItem xs={12} md={3}>
                                            <CustomInput
                                                success={
                                                    this.state
                                                        .ben_address_state_state ===
                                                    'success'
                                                }
                                                error={
                                                    this.state
                                                        .ben_address_state_state ===
                                                    'error'
                                                }
                                                labelText={<span>State</span>}
                                                id="ben_address_state"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state
                                                        .ben_address_state,
                                                    onChange: event =>
                                                        this.newBeneficiaryChange(
                                                            event,
                                                            'ben_address_state',
                                                            'length',
                                                            2
                                                        ),
                                                }}
                                            />
                                        </GridItem>
                                    )}

                                    <GridItem
                                        xs={12}
                                        md={
                                            this.state.ben_address_country === 9
                                                ? 3
                                                : 4
                                        }
                                    >
                                        <CustomInput
                                            success={
                                                this.state
                                                    .ben_address_postcode_state ===
                                                'success'
                                            }
                                            error={
                                                this.state
                                                    .ben_address_postcode_state ===
                                                'error'
                                            }
                                            labelText={<span>Postcode</span>}
                                            id="ben_address_postcode"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: this.state
                                                    .ben_address_postcode,
                                                onChange: event =>
                                                    this.newBeneficiaryChange(
                                                        event,
                                                        'ben_address_postcode',
                                                        'length',
                                                        3
                                                    ),
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} md={6}>
                        <Card style={{ height: '95%' }}>
                            <CardHeader>
                                <h4>
                                    Bank Details
                                </h4>
                                <p>
                                    These details are for the Bank account that you have been given to 
                                    pay funds to the beneficiary specified on the left.
                                    Please also provide the address of the bank.
                                </p>
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    success={
                                        this.state.bank_legal_name_state ===
                                        'success'
                                    }
                                    error={
                                        this.state.bank_legal_name_state ===
                                        'error'
                                    }
                                    labelText={<span>Bank Name</span>}
                                    id="bank_legal_name"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: this.state.bank_legal_name,
                                        onChange: event =>
                                            this.newBeneficiaryChange(
                                                event,
                                                'bank_legal_name',
                                                'length',
                                                3
                                            ),
                                    }}
                                />
                                <GridContainer>
                                    <GridItem xs={12} md={6}>
                                        {this.state.show_iban && (
                                            <CustomInput
                                                success={
                                                    this.state.iban ===
                                                    'success'
                                                }
                                                error={
                                                    this.state.iban === 'error'
                                                }
                                                labelText={<span>IBAN</span>}
                                                id="iban"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state.iban,
                                                    onChange: event =>
                                                        this.newBeneficiaryChange(
                                                            event,
                                                            'iban',
                                                            'length',
                                                            3
                                                        ),
                                                }}
                                            />
                                        )}
                                        {this.state.show_swift_code && (
                                            <CustomInput
                                                success={
                                                    this.state
                                                        .swift_code_state ===
                                                    'success'
                                                }
                                                error={
                                                    this.state
                                                        .swift_code_state ===
                                                    'error'
                                                }
                                                labelText={
                                                    <span>SWIFT Code</span>
                                                }
                                                id="swift_code"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state
                                                        .swift_code,
                                                    onChange: event =>
                                                        this.newBeneficiaryChange(
                                                            event,
                                                            'swift_code',
                                                            'length',
                                                            3
                                                        ),
                                                }}
                                            />
                                        )}
                                        {this.state.show_aba && (
                                            <CustomInput
                                                success={
                                                    this.state
                                                        .aba_routing_number_state ===
                                                    'success'
                                                }
                                                error={
                                                    this.state
                                                        .aba_routing_number_state ===
                                                    'error'
                                                }
                                                labelText={
                                                    <span>
                                                        ABA Routing Number
                                                    </span>
                                                }
                                                id="aba_routing_number"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state
                                                        .aba_routing_number,
                                                    onChange: event =>
                                                        this.newBeneficiaryChange(
                                                            event,
                                                            'aba_routing_number',
                                                            'length',
                                                            3
                                                        ),
                                                }}
                                            />
                                        )}
                                        {this.state.show_sort_code && (
                                            <CustomInput
                                                success={
                                                    this.state
                                                        .sort_code_state ===
                                                    'success'
                                                }
                                                error={
                                                    this.state
                                                        .sort_code_state ===
                                                    'error'
                                                }
                                                labelText={
                                                    <span>Sort Code</span>
                                                }
                                                id="sort_code"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state.sort_code,
                                                    onChange: event =>
                                                        this.newBeneficiaryChange(
                                                            event,
                                                            'sort_code',
                                                            'length',
                                                            3
                                                        ),
                                                }}
                                            />
                                        )}
                                        {this.state.show_bsb && (
                                            <CustomInput
                                                success={
                                                    this.state
                                                        .bsb_code_state ===
                                                    'success'
                                                }
                                                error={
                                                    this.state
                                                        .bsb_code_state ===
                                                    'error'
                                                }
                                                labelText={<span>BSB</span>}
                                                id="bsb_code"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state.bsb_code,
                                                    onChange: event =>
                                                        this.newBeneficiaryChange(
                                                            event,
                                                            'bsb_code',
                                                            'length',
                                                            3
                                                        ),
                                                }}
                                            />
                                        )}
                                        {this.state.show_transit_code && (
                                            <CustomInput
                                                success={
                                                    this.state
                                                        .transit_code_state ===
                                                    'success'
                                                }
                                                error={
                                                    this.state
                                                        .transit_code_state ===
                                                    'error'
                                                }
                                                labelText={
                                                    <span>
                                                        Transit &amp;
                                                        Institution No
                                                    </span>
                                                }
                                                id="transit_code"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state
                                                        .transit_code,
                                                    onChange: event =>
                                                        this.newBeneficiaryChange(
                                                            event,
                                                            'transit_code',
                                                            'length',
                                                            3
                                                        ),
                                                }}
                                            />
                                        )}
                                        {this.state.show_bank_code && (
                                            <CustomInput
                                                success={
                                                    this.state
                                                        .bank_code_state ===
                                                    'success'
                                                }
                                                error={
                                                    this.state
                                                        .bank_code_state ===
                                                    'error'
                                                }
                                                labelText={
                                                    <span>Bank Code</span>
                                                }
                                                id="bank_code"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state.bank_code,
                                                    onChange: event =>
                                                        this.newBeneficiaryChange(
                                                            event,
                                                            'bank_code',
                                                            'length',
                                                            3
                                                        ),
                                                }}
                                            />
                                        )}
                                        {this.state.show_account_number && (
                                            <CustomInput
                                                success={
                                                    this.state
                                                        .account_number_state ===
                                                    'success'
                                                }
                                                error={
                                                    this.state
                                                        .account_number_state ===
                                                    'error'
                                                }
                                                labelText={
                                                    <span>Account Number </span>
                                                }
                                                id="account_number"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state
                                                        .account_number,
                                                    onChange: event =>
                                                        this.newBeneficiaryChange(
                                                            event,
                                                            'account_number',
                                                            'length',
                                                            3
                                                        ),
                                                }}
                                            />
                                        )}
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <CustomInput
                                            success={
                                                this.state
                                                    .bank_address_line_1_state ===
                                                'success'
                                            }
                                            error={
                                                this.state
                                                    .bank_address_line_1_state ===
                                                'error'
                                            }
                                            labelText={
                                                <span>Address (Line 1)</span>
                                            }
                                            id="bank_address_line_1"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: this.state
                                                    .bank_address_line_1,
                                                onChange: event =>
                                                    this.newBeneficiaryChange(
                                                        event,
                                                        'bank_address_line_1',
                                                        'length',
                                                        3
                                                    ),
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem
                                        xs={12}
                                        md={
                                            this.state.bank_country === 9
                                                ? 6
                                                : 8
                                        }
                                        >
                                        <CustomInput
                                            success={
                                                this.state
                                                    .bank_address_suburb_state ===
                                                'success'
                                            }
                                            error={
                                                this.state
                                                    .bank_address_suburb_state ===
                                                'error'
                                            }
                                            labelText={
                                                <span>Suburb / Region</span>
                                            }
                                            id="bank_address_suburb"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: this.state
                                                    .bank_address_suburb,
                                                onChange: event =>
                                                    this.newBeneficiaryChange(
                                                        event,
                                                        'bank_address_suburb',
                                                        'length',
                                                        3
                                                    ),
                                            }}
                                        />
                                    </GridItem>
                                    {this.state.bank_country === 9 && (
                                    <GridItem xs={12} md={3}>
                                        <CustomInput
                                            success={
                                                this.state
                                                    .bank_address_state_state ===
                                                'success'
                                            }
                                            error={
                                                this.state
                                                    .bank_address_state_state ===
                                                'error'
                                            }
                                            labelText={
                                                <span>State</span>
                                            }
                                            id="bank_address_state"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: this.state
                                                    .bank_address_state,
                                                onChange: event =>
                                                    this.newBeneficiaryChange(
                                                        event,
                                                        'bank_address_state',
                                                        'length',
                                                        3
                                                    ),
                                            }}
                                        />
                                    </GridItem>
                                    )}
                                    <GridItem
                                        xs={12}
                                        md={
                                            this.state.bank_country === 9
                                                ? 3
                                                : 4
                                        }
                                    >
                                        <CustomInput
                                            success={
                                                this.state
                                                    .bank_address_postcode_state ===
                                                'success'
                                            }
                                            error={
                                                this.state
                                                    .bank_address_postcode_state ===
                                                'error'
                                            }
                                            labelText={
                                                <span>
                                                    Postcode
                                                </span>
                                            }
                                            id="bank_address_postcode"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: this.state
                                                    .bank_address_postcode,
                                                onChange: event =>
                                                    this.newBeneficiaryChange(
                                                        event,
                                                        'bank_address_postcode',
                                                        'length',
                                                        3
                                                    ),
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                }

                <Button
                    color="primary"
                    onClick={this.finished}
                    className={classes.registerButton}
                    // disabled={!form_valid}
                >
                    Add Beneficiary
                </Button>
            </React.Fragment>
        )
    }
}

// export default withRouter(withStyles(entityNewStyles)(BeneficiaryNew));

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        beneficiaries: state.beneficiaries,
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createNewBeneficiary: data => {
            dispatch(createNewBeneficiary(data))
        },
        createNewBeneficiaryStart: () => {
            dispatch(createNewBeneficiaryStart())
        },
        createNewBeneficiaryComplete: data => {
            dispatch(createNewBeneficiaryComplete(data))
        },
        cancelCreateNewBeneficiary: () => {
            dispatch(cancelCreateNewBeneficiary())
        },
        selectBeneficiaryUi: () => {
            dispatch(selectBeneficiaryUi())
        },
        fetchBeneficiaryList: () => {
            dispatch(fetchBeneficiaryList())
        },
    }
}

const BeneficiaryNewContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(entityNewStyles)(BeneficiaryNew)))

export default BeneficiaryNewContainer
