// ##############################
// // // javascript library for creating charts
// #############################
var Chartist = require('chartist')

// ##############################
// // // Charts used in Dahsboard view
// #############################

// ##############################
// // // variables used to create animation on charts
// #############################

var delays = 80,
    durations = 500
var delays2 = 80,
    durations2 = 500

// ##############################
// // // Daily Sales
// #############################

const dailySalesChart = {
    data: {
        labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ],
        series: [
            [
                12000,
                17000,
                7000,
                17000,
                23000,
                18000,
                38000,
                5000,
                0,
                2500,
                18000,
                0,
            ],
        ],
    },
    options: {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0,
        }),
        low: 0,
        high: 50000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 10,
        },
    },
    // for animation
    animation: {
        draw: function(data) {
            if (data.type === 'line' || data.type === 'area') {
                data.element.animate({
                    d: {
                        begin: 600,
                        dur: 700,
                        from: data.path
                            .clone()
                            .scale(1, 0)
                            .translate(0, data.chartRect.height())
                            .stringify(),
                        to: data.path.clone().stringify(),
                        easing: Chartist.Svg.Easing.easeOutQuint,
                    },
                })
            } else if (data.type === 'point') {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: 'ease',
                    },
                })
            }
        },
    },
}

// ##############################
// // // Email Subscriptions
// #############################

const emailsSubscriptionChart = {
    data: {
        labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'Mai',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ],
        series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]],
    },
    options: {
        axisX: {
            showGrid: false,
        },
        low: 0,
        high: 1000,
        chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
        },
    },
    responsiveOptions: [
        [
            'screen and (max-width: 640px)',
            {
                seriesBarDistance: 5,
                axisX: {
                    labelInterpolationFnc: function(value) {
                        return value[0]
                    },
                },
            },
        ],
    ],
    animation: {
        draw: function(data) {
            if (data.type === 'bar') {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays2,
                        dur: durations2,
                        from: 0,
                        to: 1,
                        easing: 'ease',
                    },
                })
            }
        },
    },
}

// ##############################
// // // Completed Tasks
// #############################

const completedTasksChart = {
    data: {
        labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
        series: [[230, 750, 450, 300, 280, 240, 200, 190]],
    },
    options: {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0,
        }),
        low: 0,
        high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
    },
    animation: {
        draw: function(data) {
            if (data.type === 'line' || data.type === 'area') {
                data.element.animate({
                    d: {
                        begin: 600,
                        dur: 700,
                        from: data.path
                            .clone()
                            .scale(1, 0)
                            .translate(0, data.chartRect.height())
                            .stringify(),
                        to: data.path.clone().stringify(),
                        easing: Chartist.Svg.Easing.easeOutQuint,
                    },
                })
            } else if (data.type === 'point') {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: 'ease',
                    },
                })
            }
        },
    },
}

// ##############################
// // // Rounded Line Chart
// #############################

const roundedLineChart = {
    data: {
        labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
        series: [[12, 17, 7, 17, 23, 18, 38]],
    },
    options: {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 10,
        }),
        axisX: {
            showGrid: false,
        },
        low: 0,
        high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        showPoint: false,
    },
    animation: {
        draw: function(data) {
            if (data.type === 'line' || data.type === 'area') {
                data.element.animate({
                    d: {
                        begin: 600,
                        dur: 700,
                        from: data.path
                            .clone()
                            .scale(1, 0)
                            .translate(0, data.chartRect.height())
                            .stringify(),
                        to: data.path.clone().stringify(),
                        easing: Chartist.Svg.Easing.easeOutQuint,
                    },
                })
            } else if (data.type === 'point') {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: 'ease',
                    },
                })
            }
        },
    },
}

// ##############################
// // // Straight Lines Chart
// #############################

const straightLinesChart = {
    data: {
        labels: ["'07", "'08", "'09", "'10", "'11", "'12", "'13", "'14", "'15"],
        series: [[10, 16, 8, 13, 20, 15, 20, 34, 30]],
    },
    options: {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0,
        }),
        low: 0,
        high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        classNames: {
            point: 'ct-point ct-white',
            line: 'ct-line ct-white',
        },
    },
    animation: {
        draw: function(data) {
            if (data.type === 'line' || data.type === 'area') {
                data.element.animate({
                    d: {
                        begin: 600,
                        dur: 700,
                        from: data.path
                            .clone()
                            .scale(1, 0)
                            .translate(0, data.chartRect.height())
                            .stringify(),
                        to: data.path.clone().stringify(),
                        easing: Chartist.Svg.Easing.easeOutQuint,
                    },
                })
            } else if (data.type === 'point') {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: 'ease',
                    },
                })
            }
        },
    },
}

// ##############################
// // // Simple Bar Chart
// #############################

const simpleBarChart = {
    data: {
        labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'Mai',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ],
        series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]],
    },
    options: {
        seriesBarDistance: 10,
        axisX: {
            showGrid: false,
        },
    },
    responsiveOptions: [
        [
            'screen and (max-width: 640px)',
            {
                seriesBarDistance: 5,
                axisX: {
                    labelInterpolationFnc: function(value) {
                        return value[0]
                    },
                },
            },
        ],
    ],
    animation: {
        draw: function(data) {
            if (data.type === 'bar') {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays2,
                        dur: durations2,
                        from: 0,
                        to: 1,
                        easing: 'ease',
                    },
                })
            }
        },
    },
}

// ##############################
// // // Coloured Line Chart
// #############################

const colouredLineChart = {
    data: {
        labels: [
            "'06",
            "'07",
            "'08",
            "'09",
            "'10",
            "'11",
            "'12",
            "'13",
            "'14",
            "'15",
        ],
        series: [[287, 480, 290, 554, 690, 690, 500, 752, 650, 900, 944]],
    },
    options: {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 10,
        }),
        axisY: {
            showGrid: true,
            offset: 40,
        },
        axisX: {
            showGrid: false,
        },
        low: 0,
        high: 1000,
        showPoint: true,
        height: '300px',
    },
    animation: {
        draw: function(data) {
            if (data.type === 'line' || data.type === 'area') {
                data.element.animate({
                    d: {
                        begin: 600,
                        dur: 700,
                        from: data.path
                            .clone()
                            .scale(1, 0)
                            .translate(0, data.chartRect.height())
                            .stringify(),
                        to: data.path.clone().stringify(),
                        easing: Chartist.Svg.Easing.easeOutQuint,
                    },
                })
            } else if (data.type === 'point') {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: 'ease',
                    },
                })
            }
        },
    },
}

// ##############################
// // // Multiple Bars Chart
// #############################

const multipleBarsChart = {
    data: {
        labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'Mai',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ],
        series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
            [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695],
        ],
    },
    options: {
        seriesBarDistance: 10,
        axisX: {
            showGrid: false,
        },
        height: '300px',
    },
    responsiveOptions: [
        [
            'screen and (max-width: 640px)',
            {
                seriesBarDistance: 5,
                axisX: {
                    labelInterpolationFnc: function(value) {
                        return value[0]
                    },
                },
            },
        ],
    ],
    animation: {
        draw: function(data) {
            if (data.type === 'bar') {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays2,
                        dur: durations2,
                        from: 0,
                        to: 1,
                        easing: 'ease',
                    },
                })
            }
        },
    },
}

// ##############################
// // // Coloured Lines Chart
// #############################

const colouredLinesChart = {
    data: {
        labels: [
            "'06",
            "'07",
            "'08",
            "'09",
            "'10",
            "'11",
            "'12",
            "'13",
            "'14",
            "'15",
        ],
        series: [
            [287, 385, 490, 554, 586, 698, 695, 752, 788, 846, 944],
            [67, 152, 143, 287, 335, 435, 437, 539, 542, 544, 647],
            [23, 113, 67, 190, 239, 307, 308, 439, 410, 410, 509],
        ],
    },
    options: {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 10,
        }),
        axisY: {
            showGrid: true,
            offset: 40,
        },
        axisX: {
            showGrid: false,
        },
        low: 0,
        high: 1000,
        showPoint: true,
        height: '300px',
    },
    animation: {
        draw: function(data) {
            if (data.type === 'line' || data.type === 'area') {
                data.element.animate({
                    d: {
                        begin: 600,
                        dur: 700,
                        from: data.path
                            .clone()
                            .scale(1, 0)
                            .translate(0, data.chartRect.height())
                            .stringify(),
                        to: data.path.clone().stringify(),
                        easing: Chartist.Svg.Easing.easeOutQuint,
                    },
                })
            } else if (data.type === 'point') {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: 'ease',
                    },
                })
            }
        },
    },
}

// ##############################
// // // Pie Chart
// #############################

const pieChart = {
    data: {
        labels: ['62%', '32%', '6%'],
        series: [62, 32, 6],
    },
    options: {
        height: '230px',
    },
}

module.exports = {
    // Charts used in Dahsboard view
    dailySalesChart,
    emailsSubscriptionChart,
    completedTasksChart,
    // Charts used in Charts view
    roundedLineChart,
    straightLinesChart,
    simpleBarChart,
    colouredLineChart,
    multipleBarsChart,
    colouredLinesChart,
    pieChart,
}
