// ##############################
// // // Sidebar styles
// #############################

import {
    drawerWidth,
    drawerMiniWidth,
    transition,
    boxShadow,
    defaultFont,
    primaryColor,
    primaryBoxShadow,
    infoColor,
    successColor,
    blueColor,
    warningColor,
    dangerColor,
    roseColor,
} from 'assets/jss/material-dashboard-pro-react.jsx'

const sidebarStyle = theme => ({
    drawerPaperRTL: {
        [theme.breakpoints.up('md')]: {
            left: 'auto !important',
            right: '0 !important',
        },
        [theme.breakpoints.down('sm')]: {
            left: '0  !important',
            right: 'auto !important',
        },
    },
    drawerPaper: {
        border: 'none',
        position: 'fixed',
        top: '0',
        bottom: '0',
        left: '0',
        zIndex: '800',
        transitionProperty: 'top, bottom, width',
        transitionDuration: '.2s, .2s, .35s',
        transitionTimingFunction: 'linear, linear, ease',
        // overflow: 'auto',
        ...boxShadow,
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            position: 'fixed',
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            width: drawerWidth,
            ...boxShadow,
            position: 'fixed',
            display: 'block',
            top: '0',
            height: '100vh',
            right: '0',
            left: 'auto',
            zIndex: '800',
            visibility: 'visible',
            overflowY: 'visible',
            borderTop: 'none',
            textAlign: 'left',
            paddingRight: '0px',
            paddingLeft: '0',
            transform: `translate3d(${drawerWidth}px, 0, 0)`,
            ...transition,
        },
        '&:before,&:after': {
            position: 'absolute',
            zIndex: '3',
            width: '100%',
            height: '100%',
            content: '""',
            display: 'block',
            top: '0',
        },
    },
    blackBackground: {
        color: '#FFFFFF',
        '&:after': {
            background: '#131313',
            opacity: '1',
        },
    },
    blueBackground: {
        color: '#FFFFFF',
        '&:after': {
            background: '#00acc1',
            opacity: '1',
        },
    },
    whiteBackground: {
        color: '#3C4858',
        '&:after': {
            background: '#FFFFFF',
            opacity: '.93',
        },
    },
    whiteAfter: {
        '&:after': {
            backgroundColor: 'hsla(0,0%,71%,.3) !important',
        },
    },
    drawerPaperMini: {
        width: drawerMiniWidth + 'px!important',
    },
    logo: {
        padding: '5px 25px',
        margin: '0',
        display: 'block',
        position: 'relative',
        zIndex: '4',
        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '0',
            height: '1px',
            right: '15px',
            width: 'calc(100% - 30px)',
            backgroundColor: 'hsla(0,0%,100%,.3)',
        },
    },
    logoMini: {
        transition: 'all 300ms linear',
        opacity: 1,
        textAlign: 'center',
        display: 'inline-block',
    },
    logoMiniRTL: {
        float: 'right',
        marginRight: '30px',
        marginLeft: '26px',
    },
    logoNormal: {
        ...defaultFont,
        transition: 'all 300ms linear',
        display: 'block',
        opacity: '1',
        transform: 'translate3d(0px, 0, 0)',
        textTransform: 'uppercase',
        padding: '5px 0px',
        fontSize: '18px',
        whiteSpace: 'nowrap',
        fontWeight: '400',
        lineHeight: '30px',
        overflow: 'hidden',
        '&,&:hover,&:focus': {
            color: 'inherit',
        },
    },
    logoNormalRTL: {
        textAlign: 'right',
    },
    logoNormalSidebarMini: {
        opacity: '0',
        transform: 'translate3d(-25px, 0, 0)',
    },
    logoNormalSidebarMiniRTL: {
        transform: 'translate3d(25px, 0, 0)',
    },
    img: {
        width: '35px',
        verticalAlign: 'middle',
        border: '0',
    },
    imgSecond: {
        width: '35%',
        verticalAlign: 'middle',
        border: '0',
        margin: '10px 0',
    },
    imgSecond1: {
        width: '35%',
        verticalAlign: 'middle',
        border: '0',
        margin: '10px 0',
    },
    imgSecond2: {
        width: '100%',
        verticalAlign: 'middle',
        border: '0',
    },
    imgSecond3: {
        width: '50%',
        verticalAlign: 'middle',
        border: '0',
    },
    imgSecond4: {
        width: '60%',
        verticalAlign: 'middle',
        border: '0',
        margin: '10px 0',
    },
    imgSecond5: {
        width: '100%',
        verticalAlign: 'middle',
        border: '0',
    },
    imgSecond6: {
        width: '90%',
        verticalAlign: 'middle',
        border: '0',
        margin: '10px 0',
    },
    background6: {
        position: 'absolute',
        zIndex: '1',
        height: '100%',
        width: '100%',
        display: 'block',
        top: '0',
        left: '0',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        transition: 'all 300ms linear',
    },
    listSecondConfig: {
        marginTop: '0px',
        paddingLeft: '0',
        paddingTop: '0',
        paddingBottom: '0',
        marginBottom: '0',
        listStyle: 'none',
        color: 'inherit',
        '&:before,&:after': {
            display: 'table',
            content: '" "',
        },
        '&:after': {
            clear: 'both',
        },
    },
    list: {
        marginTop: '5px',
        paddingLeft: '0',
        paddingTop: '0',
        paddingBottom: '0',
        marginBottom: '0',
        listStyle: 'none',
        color: 'inherit',
        '&:before,&:after': {
            display: 'table',
            content: '" "',
        },
        '&:after': {
            clear: 'both',
        },
    },
    item: {
        color: 'inherit',
        position: 'relative',
        display: 'block',
        textDecoration: 'none',
        margin: '0',
        padding: '0',
    },
    textCenter: {
        textAlign: 'center',
    },
    userItem: {
        '&:last-child': {
            paddingBottom: '0px',
        },
    },
    itemLink: {
        paddingLeft: '10px',
        paddingRight: '10px',
        transition: 'all 300ms linear',
        margin: '5px 0px 0',
        borderRadius: '3px',
        position: 'relative',
        display: 'block',
        padding: '10px 15px',
        backgroundColor: 'transparent',
        ...defaultFont,
        width: 'auto',
        '&:hover': {
            outline: 'none',
            backgroundColor: 'rgba(200, 200, 200, 0.2)',
            boxShadow: 'none',
        },
        '&,&:hover,&:focus': {
            color: 'inherit',
        },
    },
    photoLogo: {
        background: 'transparent',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        transition: 'all 300ms linear',
        width: '80%',
        overflow: 'hidden',
        zIndex: '5',
        marginTop: '0',
        marginLeft: '10%',
        margin: 'auto',
        borderRadius: '50%',
    },
    itemIcon: {
        color: 'inherit',
        width: '30px',
        height: '24px',
        float: 'left',
        position: 'inherit',
        top: '3px',
        marginRight: '15px',
        textAlign: 'center',
        verticalAlign: 'middle',
        opacity: '0.8',
    },
    itemIconRTL: {
        float: 'right',
        marginLeft: '15px',
        marginRight: '1px',
    },
    itemText: {
        color: 'inherit',
        ...defaultFont,
        margin: '0',
        padding: '0 !important',
        lineHeight: '30px',
        fontSize: '14px',
        transform: 'translate3d(0px, 0, 0)',
        opacity: '1',
        transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
        position: 'relative',
        display: 'block',
        height: 'auto',
        whiteSpace: 'nowrap',
    },
    divider: {
        marginTop: '5px',
        backgroundColor: 'gray',
        margin: 'auto',
        width: '85%',
    },
    userItemText: {
        lineHeight: '22px',
        fontSize: '22px',
        fontWeight: '300',
        stye: 'normal',
        lineHeight: '132%',
        textAlign: 'center',
        color: '#FFFFFF',
    },
    userItemText3: {
        lineHeight: '22px',
        fontSize: '14px',
        fontWeight: '300',
        stye: 'normal',
        lineHeight: '132%',
        textAlign: 'center',
        color: '#FFFFFF',
    },
    close: {
        float: 'right',
    },
    userItemText2: {
        top: '22.86%',
        bottom: '74.86%',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '200%',
        cursor: 'pointer',
        textAlign: 'center',
        letterSpacing: '0.15em',
        textTransform: 'uppercase',
        color: '#FFFFFF',
    },
    itemTextRTL: {
        marginRight: '45px',
        textAlign: 'right',
    },
    itemTextMini: {
        transform: 'translate3d(-25px, 0, 0)',
        opacity: '0',
    },
    itemTextMiniRTL: {
        transform: 'translate3d(25px, 0, 0) !important',
    },
    collapseList: {
        marginTop: '0',
    },
    collapseItem: {
        position: 'relative',
        display: 'block',
        textDecoration: 'none',
        margin: '10px 0 0 0',
        padding: '0',
    },
    collapseActive: {
        outline: 'none',
        backgroundColor: 'rgba(200, 200, 200, 0.2)',
        boxShadow: 'none',
    },
    collapseItemLink: {
        transition: 'all 300ms linear',
        margin: '0 15px',
        borderRadius: '3px',
        position: 'relative',
        display: 'block',
        padding: '10px',
        backgroundColor: 'transparent',
        ...defaultFont,
        width: 'auto',
        '&:hover': {
            outline: 'none',
            backgroundColor: 'rgba(200, 200, 200, 0.2)',
            boxShadow: 'none',
        },
        '&,&:hover,&:focus': {
            color: 'inherit',
        },
    },
    collapseItemMini: {
        color: 'inherit',
        ...defaultFont,
        textTransform: 'uppercase',
        width: '30px',
        marginRight: '15px',
        textAlign: 'center',
        letterSpacing: '1px',
        position: 'relative',
        float: 'left',
        display: 'inherit',
        transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
        fontSize: '14px',
    },
    collapseItemMiniRTL: {
        float: 'right',
        marginLeft: '30px',
        marginRight: '1px',
    },
    collapseItemText: {
        color: 'inherit',
        ...defaultFont,
        margin: '0',
        position: 'relative',
        transform: 'translateX(0px)',
        opacity: '1',
        whiteSpace: 'nowrap',
        display: 'block',
        transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
        fontSize: '14px',
    },
    collapseItemTextRTL: {
        textAlign: 'right',
    },
    collapseItemTextMiniRTL: {
        transform: 'translate3d(25px, 0, 0) !important',
    },
    collapseItemTextMini: {
        transform: 'translate3d(-25px, 0, 0)',
        opacity: '0',
    },
    caret: {
        marginTop: '13px',
        position: 'absolute',
        right: '18px',
        transition: 'all 150ms ease-in',
        display: 'inline-block',
        width: '0',
        height: '0',
        marginLeft: '2px',
        verticalAlign: 'middle',
        borderTop: '4px solid',
        borderRight: '4px solid transparent',
        borderLeft: '4px solid transparent',
    },
    userCaret: {
        marginTop: '10px',
    },
    caretRTL: {
        left: '11px',
        right: 'auto',
    },
    caretActive: {
        transform: 'rotate(180deg)',
    },
    purple: {
        '&,&:hover,&:focus': {
            color: '#FFFFFF',
            backgroundColor: primaryColor,
            ...primaryBoxShadow,
        },
    },
    blue: {
        '&,&:hover,&:focus': {
            color: '#FFFFFF',
            backgroundColor: blueColor,
            boxShadow: blueColor,
        },
    },
    green: {
        '&,&:hover,&:focus': {
            color: '#FFFFFF',
            backgroundColor: successColor,
            boxShadow:
                '0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)',
        },
    },
    orange: {
        '&,&:hover,&:focus': {
            color: '#FFFFFF',
            backgroundColor: warningColor,
            boxShadow:
                '0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)',
        },
    },
    red: {
        '&,&:hover,&:focus': {
            color: '#FFFFFF',
            backgroundColor: dangerColor,
            boxShadow:
                '0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)',
        },
    },
    white: {
        '&,&:hover,&:focus': {
            color: '#3C4858',
            backgroundColor: '#FFFFFF',
            boxShadow:
                '0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(60,72,88,.4)',
        },
    },
    rose: {
        '&,&:hover,&:focus': {
            color: '#FFFFFF',
            backgroundColor: roseColor,
            boxShadow:
                '0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(233,30,99,.4)',
        },
    },
    sidebarWrapper: {
        position: 'relative',
        height: 'calc(100vh - 75px)',
        overflow: 'auto',
        width: '260px',
        zIndex: '4',
        overflowScrolling: 'touch',
        transitionProperty: 'top, bottom, width',
        transitionDuration: '.2s, .2s, .35s',
        transitionTimingFunction: 'linear, linear, ease',
        color: 'inherit',
        paddingBottom: '30px',
    },
    sidebarWrapperWithPerfectScrollbar: {
        overflow: 'hidden !important',
    },
    user: {
        paddingBottom: '20px',
        margin: '20px auto 0',
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '0',
            right: '15px',
            height: '1px',
            width: 'calc(100% - 30px)',
            backgroundColor: 'hsla(0,0%,100%,.3)',
        },
    },
    photo: {
        background: '#0BACF4',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        transition: 'all 300ms linear',
        width: '35%',
        overflow: 'hidden',
        zIndex: '5',
        marginTop: '35%',
        margin: 'auto',
        borderRadius: '50%',
    },
    photoRTL: {
        float: 'right',
        marginLeft: '12px',
        marginRight: '24px',
    },
    photoRTL: {
        float: 'right',
        marginLeft: '12px',
        marginRight: '24px',
    },
    withoutLogo: {
        width: '50%',
        margin: '10% 26% !important',
    },
    withLogo1: {
        width: '50%',
        margin: '10% 26% !important',
    },
    withLogo2: {
        width: '50%',
        margin: '10% 26% !important',
        marginTop: 0,
    },
    withLogo3: {
        width: '50%',
        margin: '10% 26% !important',
    },
    withLogo4: {
        width: '50%',
        margin: '10% 26% !important',
    },
    withLogo5: {
        width: '100%',
        margin: '10% 0% 4%!important',
    },
    withLogo6: {
        width: '80%',
        margin: '9% 13% !important',
    },
    withLogo7: {
        width: '100%',
        margin: '2% 0% 4%!important',
    },
    logo4QPF: {
        width: '80%',
        margin: '18% 10% !important'
    },
    logoSkiaspen: {
        width: '80%',
        margin: '10% 10% !important'
    },
    avatarImg: {
        border: '0',
        verticalAlign: 'middle',
        textAlign: 'center',
        padding: '0',
        margin: '0',
        paddingLeft: '0%',
    },
    userCollapseButton: {
        margin: '0',
        fontFamily: 'Roboto',
        color: 'lightgrey',
        padding: '0px 20px',
        '&:hover': {
            background: 'none',
        },
    },
    userCollapseLinks: {
        marginTop: '-4px',
        '&:hover,&:focus': {
            color: '#FFFFFF',
        },
    },
})

export default sidebarStyle
