import React from 'react'
import Tabs from 'components/CustomTabs/CustomTabs.jsx'
import Cloud from '@material-ui/icons/Cloud'
import ReactTable from 'react-table'
import { matchSorter } from 'match-sorter'
import Badge from '../Badge/Badge'
import EconomicHoliday from './EconomicHoliday'
import CurrencyAlertsContainer from './CurrencyAlerts'
import FxCharts from './FxCharts'
import EconomicCalender from './EconomicCalender'
import FxRates from './FxRates'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import withStyles from '@material-ui/core/styles/withStyles'

var axios = require('axios')
const moment = require('moment')

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 1200,
        marginLeft: 'auto',
        marginRight: 'auto',
        minHeight: 200,
        padding: 10,
    },
    component_title: {},
    table: {
        color: 'white',
    },
    avatar: {
        width: 20,
        height: 20,
        marginLeft: 6,
        backgroundColor: 'rgb(185, 185, 185)',
    },
    chip: {
        fontWeight: 500,
        '&:hover': {
            backgroundColor: '#1976d2',
            color: 'white',
        },
    },
    chipActive: {
        fontWeight: 500,
        backgroundColor: '#1976d2',
        color: 'white',
        '&:hover': {
            backgroundColor: 'white',
            color: '#1976d2',
        },
    },
})

class EconomicInformationPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            posts: [],
            important_posts: [],

            selectedDate: moment().format('DD MMMM YYYY'),
            selectedCurrency: 'ALL',
            snackbar_open: false,
        }
    }

    componentDidMount() {}

    render() {
        const { classes } = this.props

        return (
            <Tabs
                title="Currency Tools:"
                headerColor="info"
                tabs={[
                    // {
                    //     tabName: 'Economic Calendar',
                    //     tabIcon: Cloud,
                    //     tabContent: <EconomicCalender />,
                    // },
                    {
                        tabName: 'FX Charts',
                        tabIcon: Cloud,
                        tabContent: (
                            <React.Fragment>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <FxCharts />
                                    </GridItem>
                                    {/*<GridItem xs={6}>*/}
                                    {/*  <FxCharts/>*/}
                                    {/*</GridItem>*/}
                                </GridContainer>
                            </React.Fragment>
                        ),
                    },
                    // {
                    //     tabName: 'Economic Holidays',
                    //     tabIcon: Cloud,
                    //     tabContent: (
                    //         <React.Fragment>
                    //             <EconomicHoliday />
                    //         </React.Fragment>
                    //     ),
                    // },
                    // {
                    //     tabName: 'Currency Alerts',
                    //     tabIcon: Cloud,
                    //     tabContent: (
                    //         <React.Fragment>
                    //             <CurrencyAlertsContainer />
                    //         </React.Fragment>
                    //     ),
                    // },
                    // {
                    //   tabName: "FX Rates",
                    //   tabIcon: Cloud,
                    //   tabContent: (
                    //     <React.Fragment> <FxRates/></React.Fragment>
                    //   )
                    // }
                ]}
            />
        )
    }
}

export default withStyles(styles)(EconomicInformationPage)
