import React from 'react'
import PropTypes from 'prop-types'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Language from "@material-ui/icons/Language";
// import ThumbUp from "@material-ui/icons/ThumbUp";
// import VpnLock from "@material-ui/icons/VpnLock";
import Face from '@material-ui/icons/Face'
import Email from '@material-ui/icons/Email'
// import LockOutline from "@material-ui/icons/LockOutline";
// import Check from "@material-ui/icons/Check";

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
// import InfoArea from "components/InfoArea/InfoArea.jsx";
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'

import { API, Auth } from 'aws-amplify'

// import registerPageStyle from "./RegisterPageStyle";
import Icon from '@material-ui/core/Icon'

import {
    container,
    cardTitle,
} from '../../assets/jss/material-dashboard-pro-react.jsx'

import customCheckboxRadioSwitch from '../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
const registerPageStyle = {
    ...customCheckboxRadioSwitch,
    cardTitle: {
        ...cardTitle,
        textAlign: 'center',
    },
    container: {
        ...container,
        position: 'relative',
        zIndex: '3',
        // paddingTop: "23vh"
    },
    cardSignup: {
        borderRadius: '6px',
        boxShadow:
            '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
        marginBottom: '100px',
        padding: '40px 0px',
        marginTop: '15vh',
    },
    center: {
        textAlign: 'center',
    },
    right: {
        textAlign: 'right',
    },
    left: {
        textAlign: 'left',
    },
    form: {
        padding: '0 20px',
        position: 'relative',
    },
    socialTitle: {
        fontSize: '18px',
    },
    inputAdornment: {
        marginRight: '18px',
        position: 'relative',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    customFormControlClasses: {
        margin: '0 12px',
    },
    checkboxLabelControl: {
        margin: '0',
    },
    checkboxLabel: {
        marginLeft: '6px',
        color: 'rgba(0, 0, 0, 0.26)',
    },
}

class VerifyEmailPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: [],
            email: '',
            password: '',
            confirm_code: '',
            error_message: '',
            newUser: null,
        }
        this.handleToggle = this.handleToggle.bind(this)
    }

    handleToggle(value) {
        const { checked } = this.state
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        this.setState({
            checked: newChecked,
        })
    }

    handleChange = event => {
        const vfCode = (event.target.value == null || event.target.value === '' ) ? null : event.target.value.trim();
        this.setState({
            [event.target.id]: vfCode
        })
        // console.log(this.state);
    }

    // handleSubmit = async event => {
    //   event.preventDefault();
    //
    //   this.setState({ isLoading: true });
    //
    //   try {
    //     const newUser = await Auth.signUp({
    //       username: this.state.email,
    //       password: this.state.password
    //     });
    //
    //     this.setState({
    //       newUser
    //     });
    //   } catch (e) {
    //     alert(e.message);
    //     console.log(e);
    //     if (e.code === "UsernameExistsException") {
    //
    //       const newUser = await Auth.resendSignUp(this.state.email);
    //       this.setState({
    //         newUser
    //       });
    //     }
    //   }
    //
    //   this.setState({ isLoading: false });
    // };

    async updateUserProfile(user_id, data) {
        console.log(user_id, data)
        return API.put('portal', `/users/update/${user_id}`, {
            body: data,
        })
    }

    handleConfirmationSubmit = async event => {
        event.preventDefault()

        this.setState({ isLoading: true })
        var confirmed_user = ''
        try {
            confirmed_user = await Auth.confirmSignUp(
                this.state.email,
                this.state.confirm_code
            )
            // console.log(confirmed_user);

            const signed_in_user = await Auth.signIn(
                this.state.email,
                this.state.password
            )

            // Create database entry here for new user
            // console.log(this.state.email);
            // console.log(this.state);
            // console.log(signed_in_user);

            const database_result = await this.updateUserProfile(
                signed_in_user.username,
                {
                    portal_email_confirmed: 1,
                    portal_last_login_datetime: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace('T', ' '),
                }
            )
            // console.log(database_result);
            //
            this.props.userHasAuthenticated(true)
            API.post('email', `/interaction/main`, {
                body: {
                    interaction: 'New Client Cognito Confirmation',
                    data: {
                        ...signed_in_user,
                        ...database_result,
                    },
                    result: 'AWS Cognito user: CONFIRMED',
                },
            })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })

            // API.post('email', `/client/new-client-confirmed`, {
            //     body: {
            //         data: {
            //             email: this.state.email,
            //             first_name: this.state.first_name,
            //             last_name: this.state.last_name,
            //         },
            //     },
            // })
            //     .then(response => {
            //         console.log(response)
            //     })
            //     .catch(error => {
            //         console.log(error)
            //     })

            API.post('email', `/staff/new-client-created`, {
                body: {
                    data: {
                        email: this.state.email,
                        first_name: this.state.first_name,
                        last_name: this.state.last_name,
                    },
                },
            })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })

            this.props.history.push('/')
        } catch (e) {
            console.log(e)
            this.setState({
                error_message: e.message,
            })
            // alert(e.message);
            this.setState({ isLoading: false })
            if (confirmed_user === 'SUCCESS') {
                this.props.history.push('/')
            }
        }
    }

    // validateForm() {
    //   console.log(this.state);
    //   return (
    //     this.state.email.length > 0 &&
    //     this.state.password.length > 0 &&
    //     this.state.password === this.state.confirm_password &&
    //     this.state.checked[0] === 1
    //   );
    // }

    validateConfirmationForm() {
        return this.state.confirm_code.length > 0
    }

    resendAuthCode = async event => {
        try {
            await Auth.resendSignUp(this.state.email)
            alert(
                'New Verification code has been sent.  Please check your email (including Spam/Junk folders).'
            )
        } catch (e) {
            alert(
                'Resending code failed.  Please check you have entered correct email address.'
            )
        }
    }

    renderConfirmationForm() {
        const { classes } = this.props
        return (
            <form
                className={classes.form}
                onSubmit={this.handleConfirmationSubmit}
            >
                <p style={{ textAlign: 'center' }}>
                    Please enter your email address and the confirmation code
                    sent to you.
                    <br />
                    <br />
                    <em>
                        If you have not received a code, please check your
                        junk/spam folders.
                        <br />
                        To re-send a new code, enter your email address and
                        press "Resend Code" button below.
                    </em>
                </p>
                <CustomInput
                    // labelText="Email..."
                    id="email"
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.email,
                        onChange: event => {
                            this.handleChange(event)
                        },
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                        ),
                        type: 'email',
                        placeholder: 'Email...',
                    }}
                />
                <CustomInput
                    // labelText="Password"
                    id="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.password,
                        onChange: event => {
                            this.handleChange(event)
                        },
                        type: 'password',
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                </Icon>
                            </InputAdornment>
                        ),
                        placeholder: 'Password...',
                    }}
                />
                <CustomInput
                    // labelText="First Name..."
                    id="confirm_code"
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.confirm_code,
                        onChange: event => {
                            this.handleChange(event)
                        },
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                        ),
                        placeholder: 'Confirmation Code...',
                    }}
                />
                <p>{this.state.error_message}</p>
                <div className={classes.center}>
                    <Button
                        round
                        color="primary"
                        disabled={!this.validateConfirmationForm()}
                        type="submit"
                        // onClick={() => this.props.history.push("/pages/login-page")}
                    >
                        Validate Code
                    </Button>
                    <Button
                        round
                        color="primary"
                        disabled={!this.state.email}
                        // type="submit"
                        onClick={() => this.resendAuthCode()}
                    >
                        Resend New Code
                        {/* TODO: Translate */}
                    </Button>
                </div>
            </form>
        )
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <Card className={classes.cardSignup}>
                            <h2 className={classes.cardTitle}>
                                Account Verification
                            </h2>
                            <CardBody>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={10} md={10}>
                                        {this.renderConfirmationForm()}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

VerifyEmailPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(registerPageStyle)(VerifyEmailPage)
