import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'

import defaultImage from 'assets/img/image_placeholder_2.jpg'
import defaultPdf from 'assets/img/preview_pdf.jpg'

// AWS components
import { Storage } from 'aws-amplify'
import { s3Upload } from 'libs/awsLib.js'
import SweetAlert from 'react-bootstrap-sweetalert'
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'
import Dropzone from 'react-dropzone'
import uuid from 'uuid'
import Button from "@material-ui/core/Button";
import { AppTemplates } from '../../AppTemplates'


const DocumentUploadStyle = {
    ...sweetAlertStyle,
    imgContainer: {
        width: 'auto',
        marginTop: '10px'
    },
    imgList: {
        width: '100%',
        border: '1px solid #ccc',
        padding: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '5px',
        borderRadius: '5px'
    },
    trashIcon: {
        width: '85%',
    },
    figure: {
        width: '15%',
        margin: 0
    },
    thumbnail: {
        width: '100%',
        cursor: 'pointer'
    },
    clearfix: {
        clear: 'both'
    }
}

class DocumentUploadNew extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            file: '',
            file_id: '',
            file_url: '',
            isLoaded: false,
            uploads: [],
            imagePreviewUrl: this.props.placeholder
                ? this.props.placeholder
                : !this.props.noPlaceholder
                    ? null
                    : defaultImage,
        }
    }

    handleClick() {
        this.refs.fileInput.click();
    }

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    onDrop = (acceptedFiles) => {
        acceptedFiles.forEach(async (file) => {
            const attachment = file ? await s3Upload(file) : null;
            this.getBase64(file, url => {
                const uploads = this.state.uploads;
                const obj = {url: url, file: file, attachment: attachment, uid: `${file.name}-${uuid.v1()}`};
                uploads.push(obj);
                this.setState({uploads: uploads});
                this.props.updateFunction(obj);
            })
        })
    }
    /*onDrop = async e => {
        console.log(e)
        let reader = new FileReader()
        let file = e[0]
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            })
        }
        reader.readAsDataURL(file)

        try {
            const attachment = file ? await s3Upload(file) : null
            console.log(attachment)
            this.setState({
                file_id: attachment,
                isLoaded: true,
            })
            this.props.updateFunction(attachment, this.props.index)
        } catch (e) {
            alert(e)
        }
    }*/

    handleSubmit(e) {
        console.log(e)
        e.preventDefault();
    }

    hideAlert() {
        this.setState({
            alert: null,
        })
    }

    removeImage = (file) => {
        if (this.props.onRemove) {
            this.props.onRemove(file);
        }
        const uploads = this.state.uploads.filter(item => item.uid !== file.uid);
        this.setState({uploads: uploads});
    }

    openModal(url) {
        if (url.startsWith("data:application/pdf")) {
            AppTemplates.openBase64File(url);
        } else {
            return this.setState({
                alert: <SweetAlert
                    style={{ display: 'block', marginTop: '-100px' }}
                    title={
                        <img
                            src={
                                url //default set to imagePreviewUrl
                            }
                            width="90%"
                        />
                    }
                    confirmBtnText="Close"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnCssClass={
                        // eslint-disable-next-line react/prop-types
                        this.props.classes.button +
                        '  ' +
                        this.props.classes.info
                    }
                />
            })
        }
    }

    render() {
        let preview_src = this.state.imagePreviewUrl;
        const {classes} = this.props;
        if (this.state.file) {
            // console.log(this.state.imagePreviewUrl);
            if (this.state.file.type === 'application/pdf') {
                // console.log(this.state.file);
                // console.log(this.state.imagePreviewUrl);
                preview_src = defaultPdf
            }
        }

        // console.log(this.state);
        // console.log(this.state.file)
        return (
            <React.Fragment>
                <h6>Drop / Click to Upload Documents</h6>
                {!this.state.file_id && (
                    <section className="container">
                        <div className="dropzone">
                            <Dropzone onDrop={this.onDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <section
                                        style={{
                                            width: '100%',
                                            backgroundColor: '#e6e6e6',
                                            border: '1px dotted #999999',
                                            padding: 10,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p
                                                style={{
                                                    color: '#444',
                                                    minHeight: 60,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                Drop / Click to Upload PDF / Image files here..
                                            </p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                    </section>
                )}
                <div className={classes.imgContainer}>
                    {
                        this.state.uploads.map((item, index) => {
                            return <div className={classes.imgList} key={index}>
                                <figure className={classes.figure} onClick={() => this.openModal(item.url)}>
                                    <img src={item.url.startsWith("data:application/pdf") ? defaultPdf : item.url} alt={item.name} className={classes.thumbnail} />
                                </figure>
                                <span  className={classes.trashIcon}>
                                    <Button onClick={() => this.removeImage(item)}
                                            variant={'contained'} color={'primary'} size={'small'}
                                            style={{float: 'right'}}>
                                        <i style={{paddingRight: '5px', cursor: 'pointer'}} className={'fa fa-trash'}></i> Remove
                                    </Button>
                                </span>
                                <div className={classes.clearfix}></div>
                            </div>
                        })
                    }
                </div>
                {this.state.alert}
            </React.Fragment>
        )
    }
}

DocumentUploadNew.propTypes = {
    avatar: PropTypes.bool,
    change: PropTypes.bool, // allows user to change image
    addButtonProps: PropTypes.object,
    changeButtonProps: PropTypes.object,
    removeButtonProps: PropTypes.object,
}

export default withStyles(DocumentUploadStyle)(DocumentUploadNew)
