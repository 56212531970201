import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'

import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx'

function CustomTextArea({ ...props }) {
    const {
        classes,
        formControlProps,
        id,
        inputProps,
        error,
        white,
        inputRootCustomClasses,
        success,
        helpText,
    } = props
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
        [classes.whiteUnderline]: white,
    })

    const marginTop = classNames({
        [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
    })

    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white,
    })

    let formControlClasses
    let helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error,
    })

    if (formControlProps !== undefined) {
        formControlClasses = classNames(
            formControlProps.className,
            classes.formControl
        )
    } else {
        formControlClasses = classes.formControl
    }

    return (
        <FormControl {...formControlProps} className={formControlClasses}>
            <TextField
                classes={{
                    // input: inputClasses,
                    root: marginTop,
                    // disabled: classes.disabled,
                    // underline: underlineClasses
                }}
                id={id}
                {...inputProps}
            />
            {helpText !== undefined ? (
                <FormHelperText id={id + '-text'} className={helpTextClasses}>
                    {helpText}
                </FormHelperText>
            ) : null}
        </FormControl>
    )
}

CustomTextArea.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    helpText: PropTypes.node,
}

export default withStyles(customInputStyle)(CustomTextArea)
