import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import ListTable from '../../../../components/ListTable/ListTable'
import {Alert, Descriptions, Table, Tag} from 'antd'
import {CurrencyLocales} from "../../../../AppUtil";

const ForwardDetailDrawDowns = ({intl, forwardDrawDown, getColumnSearchProps, loading = false}) => {

    const [componentState, setComponentState] = useState({
        columns: [],
        data: forwardDrawDown,
        summary: {avgRate: {}, data: []}
    });


    const calculateForwardDrawDown = () => {
        let summary = [];
        let averageRate = {label: 'Average Rate', value: 0};
        if (forwardDrawDown != null && forwardDrawDown.length > 0) {
            const currencyFrom = forwardDrawDown[0].currency_from;
            const currencyTo = forwardDrawDown[0].currency_to;

            const amountFrom = forwardDrawDown.reduce((acc, obj) => acc + obj.amount_from, 0);
            const amountTo = forwardDrawDown.reduce((acc, obj) => acc + obj.amount_to, 0);

            summary = [
                {label: currencyFrom, value: amountFrom},
                {label: currencyTo, value: amountTo},
            ];

            averageRate.value = (amountTo / amountFrom).toFixed(4);
        } else {
            summary = [];
        }
        setComponentState(prev => ({...prev, summary: { avgRate: averageRate, data: summary}}));
    }

    useEffect(() => {
        calculateForwardDrawDown();
        setComponentState(prev => ({...prev, data: forwardDrawDown, columns: prev.columns}));
    }, [forwardDrawDown])

    const buildColumns = () => {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                sorter: { compare: (a,b) => a.id - b.id },
                ...getColumnSearchProps({
                    dataIndex: 'id',
                    label: 'id',
                    render: (text, record) => {
                        return (
                            <>
                                <Tag color={'success'}>ID: { record.id }</Tag>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Transaction Date',
                dataIndex: 'transaction_datetime',
                key: 'transaction_datetime',
                ...getColumnSearchProps({
                    dataIndex: 'transaction_datetime',
                    label: 'transaction_datetime',
                    filterInputType: 'DATE',
                    render: (text, record) => {
                        return (
                            <>{text}</>
                        )
                    }
                })
            },
            {
                title: 'Settlement Date',
                dataIndex: 'settlement_date',
                key: 'settlement_date',
                ...getColumnSearchProps({
                    dataIndex: 'settlement_date',
                    label: 'settlement_date',
                    filterInputType: 'DATE',
                    render: (text, record) => {
                        return (
                            <>{text}</>
                        )
                    }
                })
            },
            {
                title: 'Status',
                dataIndex: 'status_nickname',
                key: 'status_nickname',
                render: (text, record) => {
                    if (text === 'COMPLETED') {
                        return <Tag color={'success'}>{text}</Tag>
                    } else {
                        return <Tag color={'orange'}>{text}</Tag>
                    }
                }
            },
            {
                title: 'Beneficiary Name',
                dataIndex: 'beneficiaries_nickname',
                key: 'beneficiaries_nickname',
                sorter: { compare: (a,b) => a.beneficiaries_nickname - b.beneficiaries_nickname },
                render: (text) => text == null ? 'Currency Balance' : text
            },
            {
                title: 'Amount From',
                dataIndex: 'amount_from',
                key: 'amount_from',
                ...getColumnSearchProps({
                    dataIndex: 'amount_from',
                    label: 'amount_from',
                    render: (text, record) => {
                        return (
                            <>
                                <div className={`currency-flag currency-flag-${record.currency_from.toLowerCase()}`} />{' '}
                                {record.currency_from}{' '}
                                {intl.formatNumber(record.amount_from, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </>
                        );
                    }
                })
            },
            {
                title: 'Rate',
                dataIndex: 'client_rate',
                key: 'client_rate'
            },
            {
                title: 'Amount To',
                dataIndex: 'to',
                key: 'amount_to',
                ...getColumnSearchProps({
                    dataIndex: 'amount_to',
                    label: 'amount_to',
                    render: (text, record) => {
                        return (
                            <>
                                <div className={`currency-flag currency-flag-${record.currency_to.toLowerCase()}`} />{' '}
                                {record.currency_to}{' '}
                                {intl.formatNumber(record.amount_to, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </>
                        );
                    }
                })
            },
        ];
        setComponentState(prev => ({...prev, columns: columns}));
    }

    useEffect(() => {
        buildColumns();
    }, [0]);

    return <>
        <div style={{ display: 'flex', justifyContent: 'end'}}>
            {
                componentState.summary.data.length > 0 ? <Alert type={'info'} style={{ width: 300 }} message={
                    <>
                        <Descriptions column={1} style={{ marginBottom: 0, paddingBottom: 0 }}>
                            {(componentState.summary.data || []).map(item =>
                                <Descriptions.Item
                                    style={{ marginBottom: 0, paddingBottom: 0 }}
                                    label={`Total ${item.label}`}>{Intl.NumberFormat(CurrencyLocales[item.label], {
                                    style: 'currency',
                                    currency: item.label,
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                    useGrouping: true,
                                }).format(item.value)}</Descriptions.Item>
                            )}
                            <Descriptions.Item
                                style={{ marginBottom: 0, paddingBottom: 0 }}
                                label={`${componentState.summary.avgRate.label}`}>{componentState.summary.avgRate.value}</Descriptions.Item>
                        </Descriptions>
                    </>
                }></Alert> : <></>
            }
        </div>
        <Table
            dataSource={componentState.data}
            columns={componentState.columns}
            loading={loading}
            className='-highlight'
        />
    </>
}

export default injectIntl(ListTable(ForwardDetailDrawDowns));