/* eslint-disable react/jsx-key */
import React from "react";
import { connect } from "react-redux";
import {
  updateNewTransferCreation,
  updateNewTransferCreationBeneficiary,
  createNewTransfer
} from "../../redux/actions/transfers_dashboard";

import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../views/Pages/DashboardPageStyle";
import { injectIntl } from "react-intl";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { API } from "aws-amplify";
import Table from "components/Table/Table.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const moment = require("moment");
const axios = require("axios");

class ShortcutTransferStep2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_step: 2,
      time: {},
      seconds: 1
    };

    this.timer = 0;
  }

  async componentDidMount() {
    this.props.setCallable(this.handle_transfer_creation);
    this.props.setRateState(this.fetchNewRate);

    const remittingAccountDetails = await API.post('transfers', '/remitting-account/get-details-new', {
      body: {
        currencyId: this.props.transfers_dashboard.new_transfer_data.currency_from.id,
        teamId: this.props.app_state.current_team.id,
        clientId: this.props.app_state.current_client.id
      }
    })
    .then(response => {
      console.log(response)
      return response;
    })
    .catch(error => {
      console.log(error);
    });

    this.props.updateNewTransferCreation(
      "remitting_bank_account_detail",
      remittingAccountDetails.entityBanksAccountResults
    );
    this.props.updateNewTransferCreation(
      "remitting_bank_detail",
      remittingAccountDetails.entityBanksResults
    );

    if (this.props.transfers_dashboard.current_step === 2) {
      this.props.startTimer();
    }
    if (this.props.transfers_dashboard.current_step !== 2) {
      this.props.stopTimer();
      this.props.go = false;
    }
  }

  componentWillUnmount() {
    //this.go = false;
    this.props.stopTimer();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //this.startTimer();
    // if prefill changes, clear new transfer data and then go back to the first step
    if (prevProps.prefill !== this.props.prefill) {
      // clearNewTransfer in DashboardTransfer
      this.props.switch_to_step1();
    }
  }

  secondsToTime = secs => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  };

  startTimer = () => {
    if (this.props.transfers_dashboard.current_step === 2) {
      this.go = true;
    }

    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    //go means this is step 2, we start count down

    let seconds = this.state.seconds - 1;
    console.log(this.go);
    if (this.go) {
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds
      });

      if (seconds === 0) {
        this.fetchNewRate();
        this.setState({
          seconds: 30
        });
      }
    }
  };

  stopTimer = () => {
    this.go = false;
    this.setState({
      seconds: 0,
      time: 0
    });
    clearInterval(this.timer);
    this.timer = null;
  };

  fetchNewRate = () => {
    let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`;
    axios
      .get(url, {
        params: {
          currencies: this.props.transfers_dashboard.new_transfer_data
            .currency_to.iso_alpha_3,
          source: this.props.transfers_dashboard.new_transfer_data.currency_from
            .iso_alpha_3,
          format: "1"
        }
      })
      .then(response => {
        let key = `${this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}${this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}`;
        let rate_string = response.data.quotes[key];

        // let amount_to = Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.amount_to);
        let rate = Number.parseFloat(rate_string);
        let rate_adjust =
          Number.parseFloat(this.props.app_state.current_client.default_rate) +
          Number.parseFloat(
            this.props.app_state.current_client.default_rate_division
          ) +
          Number.parseFloat(
            this.props.app_state.current_client.default_rate_entity
          ) +
          Number.parseFloat(
            this.props.app_state.current_client.default_rate_team
          ) +
          Number.parseFloat(
            this.props.app_state.current_client.default_rate_staff
          );
        let adjusted_rate = rate - rate * rate_adjust;

        if (
          this.props.transfers_dashboard.new_transfer_data.currency_from_id ==
          this.props.transfers_dashboard.new_transfer_data.currency_to_id
        ) {
          adjusted_rate = rate;
        }

        // handle to_amount
        if (
          this.props.transfers_dashboard.new_transfer_data.amount_lock ===
          "from_amount"
        ) {
          let amount_from = Number.parseFloat(
            this.props.transfers_dashboard.new_transfer_data.amount_from
          );
          let amount_to = amount_from * adjusted_rate;
          this.props.updateNewTransferCreation(
            "amount_to",
            amount_to.toFixed(2)
          );
        }

        // handle from_amount
        if (
          this.props.transfers_dashboard.new_transfer_data.amount_lock ===
          "to_amount"
        ) {
          let amount_to = Number.parseFloat(
            this.props.transfers_dashboard.new_transfer_data.amount_to
          );
          let amount_from = amount_to / adjusted_rate;
          this.props.updateNewTransferCreation(
            "amount_from",
            amount_from.toFixed(2)
          );
        }
        this.props.updateNewTransferCreation("client_rate", adjusted_rate);
      })
      .catch(error => {
        console.log(error);
      });
  };

  getNextBusinessDay(d, n) {
    d = new Date(d.getTime());
    var day = d.getDay();
    d.setDate(
      d.getDate() +
        n +
        (day === 6 ? 2 : +!day) +
        Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2
    );
    return d;
  }

  handle_transfer_creation = () => {
    try {
    let set_date = this.getNextBusinessDay(new Date(), 2);
    let settlement_date = set_date
      .toISOString()
      .slice(0, 10)
      .replace("T", " ");


      let adjusted_remittance_reference;

      if (this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail.reference_mandatory) {
        adjusted_remittance_reference = this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail.reference_mandatory
      } else if (this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail.reference_prefix) {
        adjusted_remittance_reference = this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail.reference_prefix + this.props.app_state.current_client.id
      } else {
        adjusted_remittance_reference = this.props.app_state.current_client.id.toString().padStart(5, "0");
      }

      API.post("email", `/interaction/main`, {
      body: {
        interaction: "New Transfer Request",
        data: {
          ...this.props.transfers_dashboard.new_transfer_data,
          ...this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail,
          adjusted_remittance_reference,
          record_created_datetime: new Date()
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
          transaction_datetime: new Date()
            .toISOString()
            .slice(0, 10)
            .replace("T", " "),
          settlement_date: moment(set_date).format("DD/MM/YYYY"),
          status: "2",
          from: "ShortcutTransferStep2",
        },
        result: "New Transfer Request in process"
      }
    });

    let amountFromIncludingFee = this.props.transfers_dashboard.new_transfer_data.amount_from;
    if (this.props.transfers_dashboard.new_transfer_data.feeAmount) {
      amountFromIncludingFee = Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.amount_from) + Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.feeAmount);
    }

    API.post("email", `/client/remittance-instructions`, {
      body: {
        id: this.props.app_state.current_client.id,
        rate: this.props.transfers_dashboard.new_transfer_data.client_rate,
        settlement_date: moment(set_date).format("DD/MM/YYYY"),
        amount_to: this.props.transfers_dashboard.new_transfer_data.amount_to,
        currency_to: this.props.transfers_dashboard.new_transfer_data
          .currency_to.full_name,
        currency_to_iso3: this.props.transfers_dashboard.new_transfer_data
          .currency_to.iso_alpha_3,
        amount_from: amountFromIncludingFee,
        // amount_from: this.props.transfers_dashboard.new_transfer_data
        //   .amount_from,
        currency_from: this.props.transfers_dashboard.new_transfer_data
          .currency_from.full_name,
        currency_from_iso3: this.props.transfers_dashboard.new_transfer_data
          .currency_from.iso_alpha_3,
        remitting_bank_id: this.props.transfers_dashboard.new_transfer_data
        .remitting_bank_account_detail.id,
        eba_account_name: this.props.transfers_dashboard.new_transfer_data
          .remitting_bank_account_detail.eba_accout_name,
        remittance_reference: adjusted_remittance_reference,
        language_id: this.props.app_state.language_id,
        ledgerFrom: this.props.transfers_dashboard.new_transfer_data.ledgerFrom,
        ledgerTo: this.props.transfers_dashboard.new_transfer_data.ledgerTo,
        payout_details: this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id
      }
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });

    // Step 3 - Create transfer
    this.props.createNewTransfer({
      ...this.props.transfers_dashboard.new_transfer_data,
      nickname: `${
        // this.props.transfers_dashboard.new_transfer_data.amount_from
        amountFromIncludingFee
      } ${
        this.props.transfers_dashboard.new_transfer_data.currency_from_name
      } to ${this.props.transfers_dashboard.new_transfer_data.amount_to} ${
        this.props.transfers_dashboard.new_transfer_data.currency_to_name
      } Settling ${moment(set_date).format("DD/MM/YYYY")}`,
      record_created_datetime: new Date()
        .toISOString()
        .slice(0, 10)
        .replace("T", " "),
      transaction_datetime: new Date()
        .toISOString()
        .slice(0, 10)
        .replace("T", " "),
      settlement_date: settlement_date,
      client_id: this.props.app_state.current_client.id,
      staff_id: this.props.app_state.current_client.responsible_staff_member,
      beneficiary_ids: this.props.transfers_dashboard.new_transfer_data
        .multiple_beneficiary_id
    });
    this.props.on_proceed_click();
  } catch (err) {
    API.post("email", `/interaction/main`, {
      body: {
        interaction: "New Transfer Request - ERROR",
        data: {
          ...this.props.transfers_dashboard.new_transfer_data,
          ...this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail,
          client_id: this.props.app_state.current_client.id,
          client_nickname: this.props.app_state.current_client.nickname,
          record_created_datetime: new Date()
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
          transaction_datetime: new Date()
            .toISOString()
            .slice(0, 10)
            .replace("T", " "),
          from: "ShortcutTransferStep2",
        },
        result: "ERROR"
      }
    });
  }
  };

  handleCheckboxToggle = name => event => {
    if (name === "checked_email_beneficiary") {
      let checked_email_beneficiary = event.target.checked;
      this.props.updateNewTransferCreation(
        "checked_email_beneficiary",
        checked_email_beneficiary
      );
    }
  };

  render() {
    const { classes } = this.props;
    if (
      !this.props.transfers_dashboard.new_transfer_data.currency_from ||
      !this.props.transfers_dashboard.new_transfer_data.currency_to
    ) {
      return null;
    }

    let amountFromDisplay = this.props.transfers_dashboard.new_transfer_data.amount_from;
    if (this.props.transfers_dashboard.new_transfer_data.feeAmount) {
      amountFromDisplay = Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.amount_from) + Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.feeAmount);
    }

    let request_summary = `You sell
      ${this.props.intl.formatNumber(
        amountFromDisplay,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      )} ${this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}
      to buy ${this.props.intl.formatNumber(
        this.props.transfers_dashboard.new_transfer_data.amount_to,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      )} ${this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}
      `;

  
    return (
      <GridContainer>
        <GridItem xs={12}>
          <h4>Confirm transfer details</h4>
        </GridItem>
        <GridItem>
          <Table
            tableData={[
              [
                "You Sell",
                <React.Fragment>
                  <div
                    className={`currency-flag currency-flag-${this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toLowerCase()}`}
                  />{" "}
                  {this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}{" "}
                  {this.props.intl.formatNumber(
                    amountFromDisplay,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  )}
                </React.Fragment>
              ],
              [
                "You Buy",
                <React.Fragment>
                  <div
                    className={`currency-flag currency-flag-${this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toLowerCase()}`}
                  />{" "}
                  {this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}{" "}
                  {this.props.intl.formatNumber(
                    this.props.transfers_dashboard.new_transfer_data.amount_to,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  )}
                </React.Fragment>
              ],
              [
                "Exchange Rate",
                <React.Fragment>
                  {this.props.intl.formatNumber(
                    this.props.transfers_dashboard.new_transfer_data
                      .client_rate,
                    {
                      minimumFractionDigits: 5,
                      maximumFractionDigits: 5
                    }
                  )}
                </React.Fragment>
              ],
              [
                "Beneficiary",
                <React.Fragment>
                  {this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].checked_beneficiary_details ===
                  true ? (
                    <React.Fragment>To Be Advised</React.Fragment>
                  ) : (
                    <React.Fragment>
                      {
                        this.props.transfers_dashboard.new_transfer_data
                          .multiple_beneficiary_id[0].beneficiary.ben_legal_name
                      }{" "}







                      {/* DONT DELETE THIS - THIS IS JUST HIDING THE EMAIL BENEFICIARY FUNCTION - Andre */}
                      {/* <br />
                      {
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checked={
                                this.props.transfers_dashboard.new_transfer_data
                                  .checked_email_beneficiary
                              }
                              onClick={this.handleCheckboxToggle(
                                "checked_email_beneficiary"
                              )}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                              disabled={
                                this.props.transfers_dashboard.new_transfer_data
                                  .multiple_beneficiary_id[0].beneficiary
                                  .ben_email_main
                                  ? false
                                  : true
                              }
                            />
                          }
                          label={
                            this.props.transfers_dashboard.new_transfer_data
                              .multiple_beneficiary_id[0].beneficiary
                              .ben_email_main
                              ? "Email beneficiary (" +
                                this.props.transfers_dashboard.new_transfer_data
                                  .multiple_beneficiary_id[0].beneficiary
                                  .ben_email_main +
                                ")"
                              : "Email beneficiary (User hasn't provided beneficiary's email)"
                          }
                        />
                      } */}







                      {/* Paying to {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bank_legal_name} */}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ],
              [
                "Purpose of Payment",
                `${
                  this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0]
                    .purpose_of_payment_detail_nickname
                }`
              ],
              [
                "Payment Detail",
                `${
                  this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].detail_1
                }`
              ],
              ["Request Summary", `${request_summary}`]
            ]}
          />
        </GridItem>

        {this.props.app_state.current_brand === "crocmedia" && (
          <GridContainer
            justify="center"
            style={{ textAlign: "left", marginBottom: 20 }}
          >
            <GridItem xs={4} style={{ textAlign: "right", marginTop: 25 }}>
              <Button round color="info">
                Add
              </Button>
            </GridItem>
            <GridItem xs={8} style={{ marginTop: 10 }}>
              <hr />
              <p style={{ align: "left" }}>
                <strong>Travel Insurance</strong>
                <br />
                Add Travel Insurance Coverage with ABC Insurance.
              </p>
              <hr />
            </GridItem>
          </GridContainer>
        )}
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    transfers_dashboard: state.transfers_dashboard
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    },
    updateNewTransferCreationBeneficiary: array => {
      dispatch(updateNewTransferCreationBeneficiary(array));
    },
    createNewTransfer: data => {
      dispatch(createNewTransfer(data));
    }
  };
};

const ShortcutTransferStep2Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(ShortcutTransferStep2));

export default injectIntl(ShortcutTransferStep2Container);
