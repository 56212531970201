import { connect } from 'react-redux'
import TransfersListPast from './TransfersListPast'
import {
    fetchTransferList,
    fetchTransferListV2,
    selectTransferUi,
} from '../../../redux/actions/transfers'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers: state.transfers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchTransferList: () => {
            dispatch(fetchTransferList())
        },
        fetchTransferListV2: aws_cognito_id => {
            dispatch(fetchTransferListV2(aws_cognito_id))
        },
        selectTransferUi: data => {
            dispatch(selectTransferUi(data))
        },
    }
}

const TransfersListPastContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TransfersListPast)

export default TransfersListPastContainer
