import moment from "moment/moment";
import React from "react";
import {Button, Typography, Tooltip} from "antd";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import Badge from "../../components/Badge/Badge";


export const NewDashboardUtil = {
    getColor: (transferStatus) => {
        return transferStatus === "Draft"
            ? "warning"
            : transferStatus === "Submitted"
                ? "info"
                : transferStatus === "Acknowledged"
                    ? "success"
                    : transferStatus === "Processing"
                        ? "success"
                        : transferStatus === "Awaiting Funds"
                            ? "danger"
                            : transferStatus === "Funds Received"
                                ? "danger"
                                : transferStatus === "Payment Made"
                                    ? "success"
                                    : transferStatus === "Completed"
                                        ? "primary"
                                        : transferStatus === "On Hold"
                                            ? "danger"
                                            : transferStatus === "Not Proceeding"
                                                ? "primary"
                                                : "success";
    },
    getCircularValue: (value) => {
        return value === 'In Process'
            ? 30
            : value === 'Completed'
                ? 100
                : value === 'Acknowledged'
                    ? 20
                    : value === 'Funds Received'
                        ? 70
                        : value === 'Not Proceeding'
                            ? 100
                            : value === 'Processing'
                                ? 40
                                : 50
    },
    getCircularText: (text) => {
        return text === 'In Process'
            ? 30
            : text === 'Completed'
                ? 100
                : text === 'Acknowledged'
                    ? 20
                    : text === 'Funds Received'
                        ? 70
                        : text === 'Not Proceeding'
                            ? 100
                            : text === 'Processing'
                                ? 40
                                : 50

    },
    prepareTransfersColumns: (columnSearchProps, transfer_status_optionlist, classes, navigate) => {
        return [
            {
                title: 'ID',
                width: 60,
                dataIndex: 'id',
                ...columnSearchProps({
                    dataIndex: 'id',
                    render: (text, record) => {
                        return <Badge style={{ cursor: 'pointer' }} onClick={() => navigate.push(`/transfers/edit/${record.id}`)} color={NewDashboardUtil.getColor(record.transfer_status)}>ID: {record.id}</Badge>
                    }
                })
            },
            {
                title: "Dates",
                dataIndex: "transaction_date",
                ...columnSearchProps({
                    filterInputType: 'DATE',
                    dataIndex: 'transaction_date',
                    render: (text, record) => <>
                        Tx: {text} <br/>
                        Sett: {record.settlement_date}
                    </>,
                })
            },
            // {
            //     title: "ST",
            //     dataIndex: "settlement_date",
            //     ...columnSearchProps({
            //         dataIndex: 'settlement_date',
            //         filterInputType: 'DATE',
            //         render: (text, record) => <>{moment(text).format("DD/MM/YYYY")} </>,
            //     })
            // },
            {
                title: 'From',
                dataIndex: 'amount_from',
                ...columnSearchProps({
                    dataIndex: 'amount_from',
                    render: (text, record) => {
                        return <>
                            <img
                                src={`https://wise.com/public-resources/assets/flags/rectangle/${record.currency_from.toLowerCase()}.png`}
                                width="16px"
                                height="16px"
                                style={{
                                    verticalAlign: 'middle', marginBottom: '4px'
                                }}/>
                            {` `}
                            {record.currency_from}
                            {` `}
                            {record.amount_from ? Number.parseFloat(record.amount_from).toFixed(2) : ''}
                        </>
                    }
                })
            },
            // {
            //     title: '',
            //     width: 30,
            //     dataIndex: 'arrow'
            // },
            {
                title: "Rate",
                dataIndex: "client_rate"
            },
            {
                title: 'To',
                dataIndex: 'amount_to',
                ...columnSearchProps({
                    dataIndex: 'amount_to',
                    render: (text, record) => {
                        return <>
                            <img
                                src={`https://wise.com/public-resources/assets/flags/rectangle/${record.currency_to.toLowerCase()}.png`}
                                width="16px"
                                height="16px"
                                style={{
                                    verticalAlign: 'middle', marginBottom: '4px'
                                }}/>
                            {` `}
                            {record.currency_to}
                            {` `}
                            {record.amount_to ? Number.parseFloat(record.amount_to).toFixed(2) : ''}
                        </>
                    }
                })
            },
            {
                title: "Beneficiary",
                dataIndex: "beneficiary",
                ...columnSearchProps({
                    render: (text, record) => {
                        if (record.beneficiary) {
                            return <>
                                {record.beneficiary}
                            </>
                        } else {
                            return <>
                                Currency Balance
                            </>
                        }
                    }
                })
            },
            {
                title: 'Status ',
                dataIndex: 'transfer_status',
                filters: transfer_status_optionlist,
                onFilter: (value, record) => record.transfer_status && record.transfer_status.startsWith(value),
                ...columnSearchProps({
                    dataIndex: 'transfer_status',
                    filterInputType: 'SELECT',
                    render: (text, record) => <div className={classes.progress}>
                        <CircularProgressbar
                            className={`${classes.progressBar}`}
                            style={{...classes.trail, ...classes.path}}
                            value={NewDashboardUtil.getCircularValue(text)}
                            text={NewDashboardUtil.getCircularText(text) + '%'}
                            styles={buildStyles({
                                textColor: '#000',
                                textSize: '22px',
                                rotation: 50,
                                transformOrigin: 'left center',
                                trail: {
                                    stroke: '#d6d6d6',
                                    strokeLinecap: 'butt',
                                    transform: 'rotate(0.25turn)',
                                    transformOrigin: 'left center'
                                },
                                pathColor:
                                    text === 'In Process'
                                        ? 'success'
                                        : text === 'Completed'
                                            ? 'success'
                                            : text === 'Acknowledged'
                                                ? 'success'
                                                : text === 'Funds Received'
                                                    ? 'success'
                                                    : text === 'Not Proceeding'
                                                        ? 'red'
                                                        : text === 'Processing'
                                                            ? 'success'
                                                            : 'orange'
                            })}
                        ></CircularProgressbar>
                        <span>
                    &nbsp; &nbsp;
                            {text}
                  </span>
                    </div>
                })
            },
        ]
    },
    prepareBeneficiariesColumn: (columnSearchProps, navigate) => {
        return [
            {
                title: 'Currency',
                dataIndex: 'nickname',
                width: '10%',
                ...columnSearchProps({
                    dataIndex: 'nickname',
                    render: (text, record) => {
                        return <>
                            <div>
                                <img
                                    src={`https://wise.com/public-resources/assets/flags/rectangle/${record.currency_iso_alpha_3 ? record.currency_iso_alpha_3.toLowerCase() : ''}.png`}
                                    width="20px"
                                    style={{
                                        verticalAlign: 'middle', marginBottom: '4px'
                                    }}/>
                                {` `}
                                <span>{record.currency_iso_alpha_3}</span>
                            </div>
                        </>
                    }
                })
            },
            // {
            //     dataIndex: 'active',
            //     title: 'Active',
            //     render: (text) => {
            //         return <Tag color='success' style={{
            //             width: 70,
            //             textAlign: "center",
            //             cursor: 'pointer'
            //         }}>{text === 1 ? 'Active' : 'Inactive'}</Tag>
            //     }
            // },
            {
                title: 'Beneficiary',
                dataIndex: 'address_suburb',
                ...columnSearchProps({
                    render: (text, record) => {
                        return <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <Tooltip title={`Legal Name: ${record.ben_legal_name} | Name: ${record.nickname}`}>
                                <Typography.Text strong><strong>{record.ben_legal_name}</strong></Typography.Text>
                            </Tooltip>
                            <Typography.Text>{record.ben_address_line_1}</Typography.Text>
                            <Typography.Text>{record.ben_address_line_2}</Typography.Text>
                            <Typography.Text>{record.ben_address_suburb}</Typography.Text>
                        </div>
                    }
                })
            },
            {
                title: 'Bank',
                dataIndex: 't_nickname',
                ...columnSearchProps({
                    dataIndex: 't_nickname',
                    render: (text, record) => {
                        return (
                            <React.Fragment>
                                <div>
                                    {record.bank_legal_name ? (
                                        <React.Fragment>
                                            <strong>
                                                {record.bank_legal_name}
                                            </strong>
                                            <br/>
                                        </React.Fragment>
                                    ) : null}
                                    {record.bank_address_line_1 ? (
                                        <React.Fragment>
                                            {record.bank_address_line_1}
                                            <br/>
                                        </React.Fragment>
                                    ) : null}
                                    {record.bank_address_suburb ? (
                                        <React.Fragment>
                                            {record.bank_address_suburb}
                                            &nbsp;
                                        </React.Fragment>
                                    ) : null}
                                    {record.bank_address_state ? (
                                        <React.Fragment>
                                            {record.bank_address_state}
                                            &nbsp;
                                        </React.Fragment>
                                    ) : null}
                                    {record.bank_address_postcode ? (
                                        <React.Fragment>
                                            {record.bank_address_postcode}
                                            &nbsp;
                                        </React.Fragment>
                                    ) : null}
                                </div>
                            </React.Fragment>
                        )
                    },
                })
            },
            {
                title: 'Account',
                dataIndex: 't_nickname',
                ...columnSearchProps({
                    dataIndex: 't_nickname',
                    render: (text, record) => {
                        return (
                            <React.Fragment>
                                <div>
                                    {record.swift_code ? (
                                        <React.Fragment>
                                            <strong>Swift:</strong>{' '}
                                            {record.swift_code}
                                            <br/>
                                        </React.Fragment>
                                    ) : null}
                                    {record.aba_routing_number ? (
                                        <React.Fragment>
                                            <strong>ABA:</strong>{' '}
                                            {record.aba_routing_number}
                                            <br/>
                                        </React.Fragment>
                                    ) : null}
                                    {record.account_number ? (
                                        <React.Fragment>
                                            <strong>Account:</strong>{' '}
                                            {record.account_number}
                                            <br/>
                                        </React.Fragment>
                                    ) : null}
                                    {record.iban ? (
                                        <React.Fragment>
                                            <strong>IBAN:</strong>{' '}
                                            {record.iban}
                                            <br/>
                                        </React.Fragment>
                                    ) : null}
                                    <br/>
                                </div>
                            </React.Fragment>
                        )
                    },
                })
            },
            {
                title: '',
                dataIndex: 'account_currency',
                ...columnSearchProps({
                    render: (text, record) => {
                        return <>
                            <Button
                                type={'primary'}
                                style={{ backgroundColor: '#00acc1', marginBottom: '10px', borderRadius: '5px', height: '100%'}} 
                                >
                                        <span onClick={() => {
                                            navigate.push(
                                                `/shortcut_transfer/${record.currency_iso_alpha_3 === 'AUD' ? 'USD' : 'AUD'}-${record.currency_iso_alpha_3}---${record.id}---`
                                            )
                                        }}><i className="far fa-paper-plane"></i> PAY NOW</span>
                            </Button>
                        </>
                    }
                })
            },
        ]
    }
}