import React from 'react';
import {
    Button,
    Card,
    Col, DatePicker,
    Descriptions,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Row,
    Select,
    Table,
    Typography
} from "antd";
import withStyles from "@material-ui/core/styles/withStyles";
import {RegistrationContext} from "../../../libs/RegistrationProvider";
import {DeleteOutlined, EyeOutlined, PlusOutlined, ReloadOutlined, SaveOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import {createNewRegistration, updateNewRegistrationCreation} from "../../../redux/actions/registration";
import {DataStatus} from "../../ComponentInfo/Status";
import {API} from "aws-amplify";
import ListTable from "../../ListTable/ListTable";
import {RegistrationAPI} from "../RegistrationUtil";
import TypographyCountry from "../../CurrencySelect/TypographyCountry";
import dayjs from "dayjs";

const styles = {
    expandedBackground: {
        backgroundColor: 'rgb(240, 242, 245)',
        padding: '15px',
        borderRadius: '5px'
    },
    marginBottom: 0
}

class Step3AdditionalContacts extends React.Component {

    additionalContactForm = React.createRef();
    static contextType = RegistrationContext;

    constructor(props) {
        super(props);
        this.state = {
            formValues: {},
            clientContacts: [],
            componentInfos: {
                beneOwners: {status: DataStatus.Loaded, callback: () => this.fetchBeneficialOwners()},
                beneSave: {status: DataStatus.Loaded}
            },
            isModalOpen: false
        }
    }

    componentDidMount() {
        this.fetchBeneficialOwners();
    }

    fetchBeneficialOwners = () => {
        this.setState(prev => ({
            ...prev,
            componentInfos: {
                ...prev.componentInfos,
                beneOwners: {...prev.componentInfos.beneOwners, status: DataStatus.Loading}
            }
        }));
        API.post("commons", "/fetch", {
            body: {
                context: 'client_contacts',
                fields: ['*'],
                condition: {clientId: this.props.app_state.current_client.id}
            }
        }).then(res => {
            this.setState(prev => ({
                ...prev,
                clientContacts: res,
                componentInfos: {
                    ...prev.componentInfos,
                    beneOwners: {...prev.componentInfos.beneOwners, status: DataStatus.Loaded}
                }
            }));
            this.context.setRegistration(prev => ({...prev, beneficialOwners: res}));
        }).catch(() => {
            this.setState(prev => ({
                ...prev,
                componentInfos: {
                    ...prev.componentInfos,
                    beneOwners: {...prev.componentInfos.beneOwners, status: DataStatus.ErrorState}
                }
            }));
        })
    }

    buildColumns = () => {
        return [
            {
                key: 'first_name',
                title: 'First Name',
                dataIndex: 'firstName'
            },
            {
                key: 'last_name',
                title: 'Last Name',
                dataIndex: 'lastName'
            },
            {
                key: 'email',
                title: 'Email',
                dataIndex: 'email'
            },
            {
                key: 'telephone',
                title: 'Telephone',
                dataIndex: 'telephone'
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'state',
                render: (text, record) => <div style={{display: 'flex', gap: '5px'}}>
                    <Button type={'primary'} icon={<EyeOutlined/>} onClick={() => {
                        Modal.confirm({
                            title: `Beneficiary Details of ${record.firstName} ${record.firstName}`,
                            cancelButtonProps: {hidden: true},
                            okText: 'Close',
                            maskClosable: true,
                            content: <>
                                <Divider/>
                                <Descriptions column={1}>
                                    <Descriptions.Item
                                        label="Name">{record.firstName} {record.firstName}</Descriptions.Item>
                                    <Descriptions.Item label="Email">{record.email || '-'}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Address Line 1">{record.address_line_1 || '-'}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Address Line 2">{record.address_line_2 || '-'}</Descriptions.Item>
                                    <Descriptions.Item label="Suburb">{record.suburb || '-'}</Descriptions.Item>
                                    <Descriptions.Item label="State">{record.state || '-'}</Descriptions.Item>
                                    <Descriptions.Item label="Country">
                                        {record.country_id
                                            ? this.context.state.countries.find(item => item.id === record.country_id).full_name
                                            : '-'
                                        }</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Telephone">{record.telephone || '-'}</Descriptions.Item>
                                    <Descriptions.Item label="Position">{record.position || '-'}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Notes"><Typography.Paragraph>{record.notes || '-'}</Typography.Paragraph></Descriptions.Item>
                                </Descriptions>
                            </>
                        })
                    }}></Button>
                    <Button type={'primary'} danger={true} icon={<DeleteOutlined/>} onClick={() => {
                        Modal.confirm({
                            title: 'Are you sure?',
                            content: `Are you sure you want to delete this beneficial owner #${record.id}?`,
                            onOk: () => {
                                const loading = message.loading("Deleting beneficial owner. Please wait..", 0)
                                RegistrationAPI.removeClientContact(record.id).then(() => {
                                    this.fetchBeneficialOwners();
                                })
                                    .catch(() => message.error('Unable to delete the beneficiary. Please try again...'))
                                    .finally(() => loading());
                            }
                        })
                    }}></Button>
                </div>
            }
        ]
    }

    isValidated = () => true;

    async preValidate () {
        this.context.setRegistration(prev => ({...prev, beneficialOwners: this.state.clientContacts}));
        // if (this.context.registration.account.account_type !== 1) {
        //     message.warn("Please provide details of at least one beneficial owner to prevent delays", 5);
        // }
        return true;
    }

    handleSubmit = (values) => {
        this.setState(prev => ({
            ...prev,
            componentInfos: {
                ...prev.componentInfos,
                beneSave: {...prev.componentInfos.beneSave, status: DataStatus.Loading}
            }
        }));
        values.beneficial_owner = true;
        RegistrationAPI.addClientContact(values).then(response => {
            this.additionalContactForm.current.resetFields();
            this.setState(prev => ({
                ...prev,
                isModalOpen: false,
                componentInfos: {
                    ...prev.componentInfos,
                    beneSave: {...prev.componentInfos.beneSave, status: DataStatus.Loaded}
                }
            }));
            this.fetchBeneficialOwners();
            console.log("Saved successfully");
        }).catch(err => {
            console.log(err);
            this.setState(prev => ({
                ...prev,
                componentInfos: {
                    ...prev,
                    beneSave: {...prev.beneSave, status: DataStatus.ErrorState}
                }
            }));
            message.error('Cannot save the Beneficial Owner. Contact already exists');
        });
    }

    sendState() {
        return this.state;
    }


    render() {
        return (
            <>
                <div className={this.props.classes.expandedBackground}>
                    <Card title={<div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span>Beneficial Owners</span>
                        <div style={{display: 'flex', gap: '5px'}}>
                            <Button type={'primary'} icon={<PlusOutlined/>} onClick={() => this.setState(prev => ({...prev, isModalOpen: true}))}>Add Beneficial Owner</Button>
                            <Button onClick={() => {
                                this.fetchBeneficialOwners();
                            }} icon={<ReloadOutlined/>}>Refresh List</Button>
                        </div>
                    </div>}>
                        <Typography.Paragraph strong={true} italic={true}>
                            As this is a business contact, please provide full details of UBO's (Ultimate Beneficial
                            Owners) who hold 25% or greater of the beneficial interest in the organisation.
                            You do not need to provide details again
                            for {this.props.app_state.current_client.first_name} {this.props.app_state.current_client.last_name}.
                        </Typography.Paragraph>
                        <Table rowKey={'id'}
                               columns={this.buildColumns()}
                               dataSource={this.state.clientContacts}
                               loading={this.state.componentInfos.beneOwners.status === DataStatus.Loading}>
                        </Table>
                    </Card>
                    <Modal title={'Add New Beneficial Owner'}
                           visible={this.state.isModalOpen}
                           width={'1000px'}
                           style={{top: 10, zIndex: 1033}}
                           okButtonProps={{
                               icon: <SaveOutlined />,
                               loading: this.state.componentInfos.beneSave.status === DataStatus.Loading
                           }}
                           bodyStyle={{maxHeight: '80vh', overflowY: 'scroll', padding: '10px', backgroundColor: 'rgb(240, 242, 245)'}}
                           onOk={() => this.additionalContactForm.current.submit()} onCancel={() => {
                                this.additionalContactForm.current.resetFields();
                                this.setState(prev => ({...prev, isModalOpen: false}));
                           }}>
                        <Form name={'client-contacts'} ref={this.additionalContactForm} onFinish={this.handleSubmit}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                    <Card title={'Contact Details'} size={'small'}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                                <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="firstName" label={'First Name'} rules={[{required: true, message: 'First Name is required '}]}>
                                                    <Input placeholder={'Enter First Name'} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                                <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="lastName" label={'Last Name'} rules={[{required: true, message: 'Last Name is required '}]}>
                                                    <Input placeholder={'Enter Last Name'} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name={'email'} label={'Email'} rules={[{required: true, message: 'Email is required'}, {type: 'email', message: 'Must be valid email'}]}>
                                            <Input placeholder={'Enter Email'} />
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name={'telephone'} label={'Telephone'}>
                                            <Input placeholder={'Enter Phone No.'} />
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name={'address_line_1'} label={'Street Line 1'}>
                                            <Input placeholder={'Street Line 1'} />
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name={'address_line_2'} label={'Street Line 2'}>
                                            <Input placeholder={'Street Line 2'} />
                                        </Form.Item>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                                <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="suburb" label={'Suburb'}>
                                                    <Input placeholder={'Suburb / City'}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                                                <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="state" label={'State'}>
                                                    <Input placeholder={'-'}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                                                <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="postcode" label={'Post Code'}>
                                                    <Input placeholder={'E.g 12345'}/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="country_id" label={'Country'} rules={[{required: true, message: 'Country is required'}]}>
                                            <Select optionFilterProp={'alt'}
                                                    placeholder={'-- SELECT --'}
                                                    showSearch={true}
                                                    style={{minWidth: '100px'}}
                                                    options={this.context.state.countries.map(i => ({
                                                        ...i,
                                                        value: i.id,
                                                        alt: i.iso_alpha_3,
                                                        label: <TypographyCountry iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>
                                                    }))}></Select>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                    <Card title={'Personal Information'} size={'small'}>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="nationality" label={'Nationality'}>
                                            <Select optionFilterProp={'alt'}
                                                    placeholder={'-- SELECT --'}
                                                    showSearch={true}
                                                    style={{minWidth: '100px'}}
                                                    options={this.context.state.countries.map(i => ({
                                                        ...i,
                                                        value: i.id,
                                                        alt: i.iso_alpha_3,
                                                        label: <TypographyCountry iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>
                                                    }))}></Select>
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="date_of_birth"
                                                   normalize={(e) => e && dayjs(e).startOf('day').format('YYYY-MM-DD')}
                                                   getValueProps={(value) =>
                                                       value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}
                                                   }
                                                   label={'Date of Birth'}>
                                            <DatePicker format={'DD/MM/YYYY'} />
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="place_of_birth" label={'Place of Birth'}>
                                            <Input placeholder={'Where you were born?'}/>
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="country_of_birth" label={'Country of Birth'}>
                                            <Select optionFilterProp={'alt'}
                                                    placeholder={'-- SELECT --'}
                                                    showSearch={true}
                                                    style={{minWidth: '100px'}}
                                                    options={this.context.state.countries.map(i => ({
                                                        ...i,
                                                        value: i.id,
                                                        alt: i.iso_alpha_3,
                                                        label: <TypographyCountry iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>
                                                    }))}></Select>
                                        </Form.Item>

                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="position" label={'Position'}>
                                            <Input placeholder={'E.g Director / Shareholder'}/>
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="notes" label={'Notes'}>
                                            <Input.TextArea />
                                        </Form.Item>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </div>
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        registration: state.registration,
        language: state.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createNewRegistration: data => {
            dispatch(createNewRegistration(data));
        },
        updateNewRegistrationCreation: (key, value) => {
            dispatch(updateNewRegistrationCreation(key, value));
        }
    };
};


const Step3AdditionalContactsHolderContainer = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Step3AdditionalContacts));

export default Step3AdditionalContactsHolderContainer;