import React from 'react'
// import ReactTable from "react-table";
// import {matchSorter} from "match-sorter";
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import withStyles from '@material-ui/core/styles/withStyles'
import { matchSorter } from 'match-sorter'
import ReactTable from 'react-table'
import Flag from 'react-world-flags'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'

var axios = require('axios')
const moment = require('moment')

const styles = theme => ({
    tab: {
        minWidth: 40,
    },
    avatar: {
        width: 20,
        height: 20,
        marginLeft: 6,
        backgroundColor: 'rgb(185, 185, 185)',
    },
    chip: {
        margin: theme.spacing.unit,
        '&:hover': {
            backgroundColor: 'rgba(3, 77, 104, 0.7)',
            color: 'white',
            fontWeight: 450,
        },
    },
    chipActive: {
        margin: theme.spacing.unit,
        backgroundColor: 'rgba(3, 77, 104, 0.7)',
        color: 'white',
        fontWeight: 450,
    },
})

class FxRates extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            selectedCurrency: 'AUD',
            rates_rows: [],
        }
    }

    componentDidMount() {
        this.fetchRate()

        this.timerAUDRATES = setInterval(() => this.fetchRate(), 10000)
    }

    fetchRate() {
        let baseCurrencies = ['AUD', 'EUR', 'GBP', 'USD']

        let promises = []
        for (let baseCurrency of baseCurrencies) {
            let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f&currencies=USD,EUR,GBP,NZD,HKD,JPY,SGD&source=${baseCurrency}&format=1`
            promises.push(axios.get(url))
        }

        let rates = []

        axios
            .all(promises)
            .then(results => {
                results.forEach(response => {
                    switch (response.data.source) {
                        case 'AUD': {
                            for (var quote in response.data.quotes) {
                                var base = quote.slice(0, 3)
                                var terms = quote.slice(3, 6)
                                let currentRate = {
                                    id: `${base.toLowerCase()}${terms.toLowerCase()}`,
                                    from: base,
                                    to: terms,
                                    value:
                                        response.data.quotes[`${base}${terms}`],
                                }
                                rates.push(currentRate)
                            }
                        }
                        case 'EUR': {
                            for (var quote in response.data.quotes) {
                                var base = quote.slice(0, 3)
                                var terms = quote.slice(3, 6)
                                let currentRate = {
                                    id: `${base.toLowerCase()}${terms.toLowerCase()}`,
                                    from: base,
                                    to: terms,
                                    value:
                                        response.data.quotes[`${base}${terms}`],
                                }
                                rates.push(currentRate)
                            }
                        }
                        case 'GBP': {
                            for (var quote in response.data.quotes) {
                                var base = quote.slice(0, 3)
                                var terms = quote.slice(3, 6)
                                let currentRate = {
                                    id: `${base.toLowerCase()}${terms.toLowerCase()}`,
                                    from: base,
                                    to: terms,
                                    value:
                                        response.data.quotes[`${base}${terms}`],
                                }
                                rates.push(currentRate)
                            }
                        }
                        case 'USD': {
                            for (var quote in response.data.quotes) {
                                var base = quote.slice(0, 3)
                                var terms = quote.slice(3, 6)
                                let currentRate = {
                                    id: `${base.toLowerCase()}${terms.toLowerCase()}`,
                                    from: base,
                                    to: terms,
                                    value:
                                        response.data.quotes[`${base}${terms}`],
                                }

                                rates.push(currentRate)
                            }
                        }
                    }
                })
            })
            .then(() => {
                this.setState({
                    // rows: rates,
                    rates_rows: rates,
                    loading: false,
                })
            })
            // .then(() => {
            //
            //   this.timerAUDRATES = setInterval(
            //     () => this.update_data_all(),
            //     20000
            //   )
            // })

            .catch(err => {
                console.log(err)
            })
    }

    componentWillUnmount() {
        clearInterval(this.timerAUDRATES)
    }

    update_data_all = () => {
        let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f&currencies=USD,EUR,GBP,NZD,HKD,JPY,SGD&source=AUD&format=1`
        axios
            .get(url)
            .then(res => {
                let rates_majors = this.state.rows.slice(0, 7)
                let audusd = res.data.quotes['AUDUSD']
                let audeur = res.data.quotes['AUDEUR']
                let audgbp = res.data.quotes['AUDGBP']
                let audnzd = res.data.quotes['AUDNZD']
                let audhkd = res.data.quotes['AUDHKD']
                let audjpy = res.data.quotes['AUDJPY']
                let audsgd = res.data.quotes['AUDSGD']
                rates_majors[0].change = (
                    audusd.toFixed(5) - rates_majors[0].value
                ).toFixed(5)
                rates_majors[0].value = audusd
                rates_majors[1].change = (
                    audeur.toFixed(5) - rates_majors[1].value
                ).toFixed(5)
                rates_majors[1].value = audeur
                rates_majors[2].change = (
                    audgbp.toFixed(5) - rates_majors[2].value
                ).toFixed(5)
                rates_majors[2].value = audgbp
                rates_majors[3].change = (
                    audnzd.toFixed(5) - rates_majors[3].value
                ).toFixed(5)
                rates_majors[3].value = audnzd
                rates_majors[4].change = (
                    audhkd.toFixed(5) - rates_majors[4].value
                ).toFixed(5)
                rates_majors[4].value = audhkd
                rates_majors[5].change = (
                    audjpy.toFixed(5) - rates_majors[5].value
                ).toFixed(5)
                rates_majors[5].value = audjpy
                rates_majors[6].change = (
                    audsgd.toFixed(5) - rates_majors[6].value
                ).toFixed(5)
                rates_majors[6].value = audsgd

                this.setState({
                    rows_majors: rates_majors,
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    handleTabChange = (event, value) => {
        this.setState({ selectedCurrency: value })
    }
    handleCurrencyChange = currency => {
        this.setState({
            selectedCurrency: currency.toUpperCase(),
        })
    }

    buildTableData() {
        var post_length = this.state.rates_rows.length
        if (post_length > 0) {
            const rows = this.state.rates_rows.filter(row => {
                if (this.state.selectedCurrency === 'ALL') {
                    return true
                }
                return row.from.toUpperCase() === this.state.selectedCurrency
            })

            return rows.map((prop, key) => {
                return {
                    from: prop.from,
                    to: prop.to,
                    value: parseFloat(prop.value).toFixed(4),
                    inverse: parseFloat(1 / prop.value).toFixed(4),
                }
            })
        }
    }

    render() {
        const { classes } = this.props
        const { selectedCurrency } = this.state
        return (
            <React.Fragment>
                <div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection:
                                this.props.width !== 'xs' ? 'row' : 'column',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Chip
                            avatar={
                                <Avatar>
                                    <span
                                        className={`currency-flag currency-flag-aud`}
                                    />
                                </Avatar>
                            }
                            label="AUD"
                            onClick={() => this.handleCurrencyChange('AUD')}
                            className={classes.chip}
                        />
                        <Chip
                            avatar={
                                <Avatar>
                                    <span
                                        className={`currency-flag currency-flag-eur`}
                                    />
                                </Avatar>
                            }
                            label="EUR"
                            onClick={() => this.handleCurrencyChange('EUR')}
                            className={classes.chip}
                        />
                        <Chip
                            avatar={
                                <Avatar>
                                    <span
                                        className={`currency-flag currency-flag-gbp`}
                                    />
                                </Avatar>
                            }
                            label="GBP"
                            onClick={() => this.handleCurrencyChange('GBP')}
                            className={classes.chip}
                        />
                        <Chip
                            avatar={
                                <Avatar>
                                    <span
                                        className={`currency-flag currency-flag-usd`}
                                    />
                                </Avatar>
                            }
                            label="USD"
                            onClick={() => this.handleCurrencyChange('USD')}
                            className={classes.chip}
                        />
                    </div>

                    <div>
                        <GridContainer justify="center">
                            <GridItem xs={6}>
                                <ReactTable
                                    data={this.buildTableData()}
                                    //filterable
                                    sortable={false}
                                    columns={[
                                        {
                                            Header: 'From',
                                            accessor: 'from',
                                            Cell: c => {
                                                return (
                                                    <span>
                                                        <span
                                                            className={`currency-flag currency-flag-${c.value.toLowerCase()}`}
                                                        />{' '}
                                                        &nbsp; {c.value}
                                                    </span>
                                                )
                                            },
                                            filterMethod: (filter, rows) =>
                                                matchSorter(
                                                    rows,
                                                    filter.value,
                                                    { keys: ['from'] }
                                                ),
                                            filterAll: true,
                                            maxWidth: 150,
                                        },
                                        {
                                            Header: 'To',
                                            accessor: 'to',
                                            Cell: c => {
                                                return (
                                                    <span>
                                                        <span
                                                            className={`currency-flag currency-flag-${c.value.toLowerCase()}`}
                                                        />{' '}
                                                        &nbsp; {c.value}
                                                    </span>
                                                )
                                            },
                                            filterMethod: (filter, rows) =>
                                                matchSorter(
                                                    rows,
                                                    filter.value,
                                                    { keys: ['to'] }
                                                ),
                                            filterAll: true,
                                            maxWidth: 150,
                                        },
                                        {
                                            Header: 'Rate',
                                            accessor: 'value',
                                            filterMethod: (filter, rows) =>
                                                matchSorter(
                                                    rows,
                                                    filter.value,
                                                    { keys: ['value'] }
                                                ),
                                            Cell: c => {
                                                return (
                                                    <div
                                                        style={{
                                                            textAlign: 'right',
                                                            paddingRight: 50,
                                                        }}
                                                    >
                                                        {c.value}
                                                    </div>
                                                )
                                            },
                                            filterAll: true,
                                            maxWidth: 150,
                                        },
                                        {
                                            Header: 'Inverse',
                                            headerClassName: 'text-left',
                                            accessor: 'inverse',
                                            filterMethod: (filter, rows) =>
                                                matchSorter(
                                                    rows,
                                                    filter.value,
                                                    { keys: ['inverse'] }
                                                ),
                                            Cell: c => {
                                                return (
                                                    <div
                                                        style={{
                                                            textAlign: 'right',
                                                            paddingRight: 50,
                                                        }}
                                                    >
                                                        {c.value}
                                                    </div>
                                                )
                                            },
                                            filterAll: true,
                                            maxWidth: 150,
                                        },
                                    ]}
                                    defaultPageSize={7}
                                    pageSizeOptions={[3, 6]}
                                    showPagination={false}
                                />
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(FxRates)
