import { API } from 'aws-amplify'

export const FETCH_REGISTRATION_LIST_REQUEST = 'FETCH_REGISTRATION_LIST_REQUEST'
export const FETCH_REGISTRATION_LIST_SUCCESS = 'FETCH_REGISTRATION_LIST_SUCCESS'
export const FETCH_REGISTRATION_LIST_FAILURE = 'FETCH_REGISTRATION_LIST_FAILURE'
export const REGISTRATION_CREATE_NEW_SHOW_UI = 'REGISTRATION_CREATE_NEW_SHOW_UI'
export const REGISTRATION_CREATE_NEW_START = 'REGISTRATION_CREATE_NEW_START'
export const REGISTRATION_CREATE_NEW_CANCEL = 'REGISTRATION_CREATE_NEW_CANCEL'
export const REGISTRATION_CREATE_NEW_COMPLETE =
    'REGISTRATION_CREATE_NEW_COMPLETE'
export const REGISTRATION_SELECT_UI = 'REGISTRATION_SELECT_UI'
export const REGISTRATION_ENTITIES_LIST_IS_DIRTY =
    'REGISTRATION_ENTITIES_LIST_IS_DIRTY'
export const REGISTRATION_REGISTRATION_EDIT = 'REGISTRATION_REGISTRATION_EDIT'
export const REGISTRATION_SELECT_ENTITY_SUCCESS =
    'REGISTRATION_SELECT_ENTITY_SUCCESS'
export const REGISTRATION_FINISHED = 'REGISTRATION_FINISHED'
export const REGISTRATION_NEW_REGISTRATION_CREATE =
    'REGISTRATION_NEW_REGISTRATION_CREATE'

export function requestREGISTRATIONListRequest() {
    return {
        type: FETCH_REGISTRATION_LIST_REQUEST,
    }
}

export function receiveREGISTRATIONList(json) {
    return {
        type: FETCH_REGISTRATION_LIST_SUCCESS,
        registration: json,
        receivedAt: Date.now(),
    }
}

export function fetchREGISTRATIONList() {
    return function(dispatch) {
        dispatch(requestREGISTRATIONListRequest)
        API.get('beneficiaries', `/beneficiaries/get`)
            .then(response => {
                dispatch(receiveREGISTRATIONList(response))
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function selectREGISTRATIONUi(value) {
    return {
        type: REGISTRATION_SELECT_UI,
        value: value,
    }
}

export function editREGISTRATION(bene_id) {
    return {
        type: REGISTRATION_REGISTRATION_EDIT,
        value: bene_id,
    }
}

export async function create_new_registration(data) {
    API.post('clients', `/client_registration`, {
        body: {
            ...data,
        },
    })
        .then(database_result => {
            API.post('email', `/interaction/main`, {
                body: {
                    interaction: 'New Registration Submission',
                    data: data,
                    result: database_result.insertId,
                },
            })
                .then(response => {
                    // console.log(response);
                })
                .catch(error => {
                    console.log(error)
                })
            return database_result
        })
        .catch(error => {
            console.log(error)
        })
}

export function createNewRegistration(data) {
    return async function(dispatch) {
        console.log('creating registration record')
        try {
            await create_new_registration(data)
        } catch (e) {
            console.log(e)
        }
        dispatch(newRegistrationFinished)
    }
}

export function updateNewRegistrationCreation(key, value) {
    // console.log(key, value);
    return {
        type: REGISTRATION_NEW_REGISTRATION_CREATE,
        value: { key, value },
    }
}

export function cancelCreateNewBeneficiary() {
    return {
        type: REGISTRATION_CREATE_NEW_CANCEL,
    }
}

export function newRegistrationFinished() {
    return {
        type: REGISTRATION_FINISHED,
    }
}

export function loadBeneficiaryDetailsSuccess() {
    return {
        type: REGISTRATION_SELECT_ENTITY_SUCCESS,
    }
}

export function createNewBeneficiaryStart() {
    return {
        type: REGISTRATION_CREATE_NEW_START,
    }
}

export function createNewBeneficiaryComplete() {
    return {
        type: REGISTRATION_CREATE_NEW_COMPLETE,
    }
}
