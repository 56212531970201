import React from 'react'
import { API } from 'aws-amplify'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
import Card from '../../../../components/Card/Card.jsx'
import CardBody from '../../../../components/Card/CardBody.jsx'
import CardHeader from '../../../../components/Card/CardHeader'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import Table from 'components/Table/Table.jsx'

import { cardTitle } from '../../../../assets/jss/material-dashboard-pro-react'

import {
    defaultFont,
    primaryColor,
    dangerColor,
    tooltip,
} from 'assets/jss/material-dashboard-pro-react.jsx'
import { injectIntl } from 'react-intl'

var moment = require('moment')

const style = {
    ...customCheckboxRadioSwitch,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    infoText: {
        fontWeight: '300',
        margin: '10px 0 30px',
        textAlign: 'center',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    inputAdornment: {
        position: 'relative',
    },

    table: {
        marginBottom: '0',
    },
    tableRow: {
        position: 'relative',
        borderBottom: '1px solid #dddddd',
    },
    tableActions: {
        border: 'none',
        padding: '12px 8px !important',
        verticalAlign: 'middle',
    },
    tableCell: {
        ...defaultFont,
        padding: '0',
        verticalAlign: 'middle',
        border: 'none',
        lineHeight: '1.42857143',
        fontSize: '14px',
        width: '80%',
    },
    tableActionButton: {
        width: '27px',
        height: '27px',
        padding: '0',
    },
    tableActionButtonIcon: {
        width: '17px',
        height: '17px',
    },
    edit: {
        backgroundColor: 'transparent',
        color: primaryColor,
        boxShadow: 'none',
    },
    close: {
        backgroundColor: 'transparent',
        color: dangerColor,
        boxShadow: 'none',
    },
    tooltip,
}

class Step5Confirm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            nickname: `FX Transfer ${moment().format('Do MMMM YYYY')}`,
            nickname_state: 'success',
            checked_client: true,
            checked_paying_to: false,
            checked_paying_from: false,
            checked_amount: false,

            purpose_list: [],
        }
    }
    componentDidMount() {
        this.props.updateNewTransferCreation(
            'nickname',
            `FX Transfer ${moment().format('Do MMMM YYYY')}`
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.app_state.current_client !==
            this.props.app_state.current_client
        ) {
            API.get(
                'transfers',
                `/get_purpose/id/${
                    this.props.app_state.current_client.division_id
                }`
            )
                .then(response => {
                    this.setState({
                        purpose_list: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    sendState() {
        return this.state
    }

    // function that verifies if two strings are equal
    compare(string1, string2) {
        if (string1 === string2) {
            return true
        }
        return false
    }
    // function that returns true if value is email, false otherwise
    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (emailRex.test(value)) {
            return true
        }
        return false
    }
    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'email':
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + ' _state']: 'error' })
                }
                break
            case 'equalTo':
                if (
                    this.compare(
                        event.target.value,
                        this.state[stateNameEqualTo]
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
        this.props.updateNewTransferCreation(stateName, event.target.value)
    }

    isValidated() {
        if (this.state.nickname_state !== 'success') {
            this.setState({ nickname_state: 'error' })
        }
        return this.state.nickname_state === 'success'
    }

    render() {
        const { classes } = this.props
        if (!this.props.transfers.new_transfer_data) {
            return null
        }
        if (!this.props.app_state.current_client) {
            return null
        }

        let beneficiary =
            this.props.transfers.new_transfer_data.beneficiary || {}
        // let client = this.props.transfers.new_transfer_data.client || {}
        // let payee = this.props.transfers.new_transfer_data.payee || {}
        let currency_from =
            this.props.transfers.new_transfer_data.currency_from || {}
        let currency_to =
            this.props.transfers.new_transfer_data.currency_to || {}
        let current_rate = Number.parseFloat(
            this.props.transfers.new_transfer_data.current_rate
        ).toFixed(5)
        let amount_from = Number.parseFloat(
            this.props.transfers.new_transfer_data.amount_from
        ).toFixed(2)
        let amount_to = Number.parseFloat(
            this.props.transfers.new_transfer_data.amount_to
        ).toFixed(2)

        let payee = {}
        if (this.props.transfers.new_transfer_data.payee_different === '0') {
            payee.nickname = this.props.app_state.current_client
                ? this.props.app_state.current_client.nickname
                : ''
        } else {
            payee.nickname = `${this.props.transfers.new_transfer_data
                .payee_first_name || ''} ${this.props.transfers
                .new_transfer_data.payee_last_name || ''}`
        }

        let table_data_payment = []

        for (let idx in this.props.transfers.new_transfer_data
            .multiple_beneficiary_id) {
            let payment = this.props.transfers.new_transfer_data
                .multiple_beneficiary_id[idx]
            // let beneficiary = this.props.transfers.new_transfer_data.beneficiary;
            let item1 = (
                <React.Fragment>
                    {beneficiary.nickname}
                    <br />
                    {beneficiary.bsb_code} : {beneficiary.account_number}
                </React.Fragment>
            )
            let item2 = (
                <React.Fragment>
                    {this.state.purpose_list[payment.purposeOfPayment - 1]
                        ? this.state.purpose_list[payment.purposeOfPayment - 1]
                              .description
                        : ''}
                    <br />
                    {payment.detail_1 ? `[reference: ${payment.detail_1}]` : ''}
                </React.Fragment>
            )
            let item3 = (
                <React.Fragment>
                    {this.props.intl.formatNumber(payment.amount)}{' '}
                    {beneficiary.currency_full_name}
                </React.Fragment>
            )

            let data_item = [item1, item2, item3]

            table_data_payment.push(data_item)
        }

        let remitting_account =
            this.props.transfers.new_transfer_data
                .remitting_bank_account_detail || {}
        let remitting_bank =
            this.props.transfers.new_transfer_data.remitting_bank_detail || {}

        let table_data_deposit = [
            [
                'Payment Amount:',
                `${this.props.intl.formatNumber(amount_from)} ${
                    currency_from.short_name
                }`,
            ],
            [
                'Reference to use:',
                <React.Fragment>
                    <span>
                        Ensure that you record this reference on the
                        transaction:
                    </span>
                    <br />
                    {remitting_account.reference_prefix}
                    {this.props.app_state.current_client.id}
                </React.Fragment>,
            ],
            [
                'Account Details:',
                <React.Fragment>
                    Account Name: {remitting_account.eba_accout_name}
                    <br />
                    Account Number: {remitting_account.eba_accout_number}
                    <br />
                    SWIFT: {remitting_account.eba_swift}
                    <br />
                    IBANN: {remitting_account.iban}
                </React.Fragment>,
            ],
            [
                'Bank:',
                <React.Fragment>
                    {remitting_bank.eb_short_name}
                    <br />
                    {remitting_bank.address_line_1} <br />
                    {remitting_bank.address_line_2 ? (
                        <span>
                            {remitting_bank.address_line_2}
                            <br />
                        </span>
                    ) : (
                        ''
                    )}
                    {remitting_bank.address_suburb}{' '}
                    {remitting_bank.address_postcode}
                </React.Fragment>,
            ],
        ]

        return (
            <React.Fragment>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12}>
                        <h4 className={classes.infoText}>
                            You're almost finished...
                        </h4>
                        <p className={classes.infoText}>
                            Please review these details and press "Finish" to
                            submit the transfer.
                        </p>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={10} md={6}>
                        <CustomInput
                            success={this.state.nickname_state === 'success'}
                            error={this.state.nickname_state === 'error'}
                            labelText={
                                <span>
                                    Short description to assist finding
                                    transaction
                                </span>
                            }
                            id="nickname"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: this.state.nickname,
                                onChange: event =>
                                    this.change(event, 'nickname', 'length', 3),
                            }}
                        />
                    </GridItem>
                </GridContainer>

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} lg={6}>
                        <Card style={{ height: '90%' }}>
                            <CardHeader color="rose" icon>
                                <h4 className={classes.cardIconTitle}>
                                    Confirm FX Transaction
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableData={[
                                        [
                                            'Amount from',
                                            `${this.props.intl.formatNumber(
                                                amount_from
                                            )} ${currency_from.iso_alpha_3}`,
                                        ],
                                        [
                                            'Amount to',
                                            `${this.props.intl.formatNumber(
                                                amount_to
                                            )} ${currency_to.iso_alpha_3}`,
                                        ],
                                        [
                                            'Rate',
                                            `${current_rate} locked in until ${moment()
                                                .add(10, 'minutes')
                                                .format(
                                                    'hh:mm A, DD/MM/YYYY'
                                                )}.`,
                                        ],
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={12} lg={6}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <h4 className={classes.cardIconTitle}>
                                    Confirm deposit
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <Table tableData={table_data_deposit} />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader color="rose" icon>
                                <h4 className={classes.cardIconTitle}>
                                    Confirm payment
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <Table tableData={table_data_payment} />

                                <p>
                                    Being a total payment of:{' '}
                                    {this.props.intl.formatNumber(amount_to)}{' '}
                                    {currency_to.iso_alpha_3}
                                </p>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </React.Fragment>
        )
    }
}

export default injectIntl(withStyles(style)(Step5Confirm))
