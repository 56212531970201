import { connect } from 'react-redux'
import Step1TransferTo from './Step1TransferTo'
import { updateNewTransferCreation } from '../../../redux/actions/transfers_2'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers_2: state.transfers_2,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewTransferCreation: (key, value) => {
            dispatch(updateNewTransferCreation(key, value))
        },
    }
}

const Step1TransferToContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Step1TransferTo)

export default Step1TransferToContainer
