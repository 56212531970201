import React, {Fragment} from 'react'
import {connect} from 'react-redux'

import {editBeneficiary, fetchBeneficiaryListByClientId,} from '../../redux/actions/beneficiaries'
import {AiOutlineDollar, AiOutlineSearch} from 'react-icons/ai'

import {API} from 'aws-amplify'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import {withRouter} from 'react-router-dom'

// @material-ui/icons
import Dvr from '@material-ui/icons/Dvr'
import Close from '@material-ui/icons/Close'

import Button from 'components/CustomButtons/Button.jsx'
import {Divider, Table, Tag} from 'antd';

import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx'
import ListTable from "../ListTable/ListTable";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
    },
}

class BeneficiaryList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            entity_list: [],
            currencies_list: [],
            country_list: [],
        }
    }

    componentDidMount() {
        // const { fetchBeneficiaryList } = this.props
        const { fetchBeneficiaryListByClientId } = this.props
        API.get('currencies', `/currencies/get-list-priority`)
            .then(response => {
                this.setState({
                    currencies_list: response,
                })
            })
            .catch(error => {
                console.log(error)
            })
        // fetchBeneficiaryList()
        fetchBeneficiaryListByClientId(this.props.app_state.current_client.id)

        API.get('countries', `/countries/list_not_deleted`, {})
            .then(response => {
                console.log(response);
                this.setState({
                    country_list: response,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    buildTableData() {
        var beneficiary_list_length = this.props.beneficiaries.beneficiaries
            .length
        let tableData
        if (beneficiary_list_length > 0) {
            tableData = this.props.beneficiaries.beneficiaries.map(
                (prop, key) => {
                    return {
                        ...prop,
                        actions: (
                            <div className="actions-right">
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        // this.props.editBeneficiary(prop.id);
                                        this.props.history.push(
                                            `/beneficiaries/edit/${prop.id}`
                                        )
                                    }}
                                    color="warning"
                                    className="edit"
                                >
                                    <Dvr />
                                </Button>{' '}
                                {prop.currency_iso_alpha_3 && <>
                                    <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() => {
                                            // this.props.editBeneficiary(prop.id);
                                            this.props.history.push(
                                                `/shortcut_transfer/${prop.currency_iso_alpha_3 === 'AUD' ? 'USD' : 'AUD'}-${prop.currency_iso_alpha_3}---${prop.id}---`
                                            )
                                        }}
                                        color="warning"
                                        className="edit"
                                    >
                                        <AiOutlineDollar />
                                    </Button>{' '}
                                </>}
                                {/*use this button to remove the data row */}
                                {prop.client_id != null && (
                                    <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() => {
                                            var data = this.props.beneficiaries
                                                .beneficiaries
                                            data.find((o, i) => {
                                                if (o.id === prop.id) {
                                                    API.put(
                                                        'beneficiaries',
                                                        `/beneficiaries/update/id/${
                                                            prop.id
                                                        }`,
                                                        {
                                                            body: {
                                                                deleted: true,
                                                            },
                                                        }
                                                    )
                                                        .then(response => {
                                                            // this.props.fetchBeneficiaryList()
                                                            this.props.fetchBeneficiaryListByClientId(this.props.app_state.current_client.id)
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                        })

                                                    return true
                                                }
                                                return false
                                            })
                                            this.setState({ data: data })
                                        }}
                                        color="danger"
                                        className="remove"
                                    >
                                        <Close />
                                    </Button>
                                )}
                            </div>
                        ),
                    }
                }
            )
            // console.log(data);
        }
        return tableData
    }

    //a method to search currency by id
    searchCurrencyById = keyword => {
        return this.state.currencies_list.map(item => ({
            value: item.iso_alpha_3,
            text: (
                <React.Fragment>
                    <span
                        className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`}
                    />
                    &nbsp;
                    {item.full_name}
                </React.Fragment>
            ),
        }));
    }

    getFilter = (filter, onChange) => {
        return (
            <Fragment>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <AiOutlineSearch />
                    <input
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                    />
                </div>
            </Fragment>
        )
    }

    render() {
        return (
            <Table
                // data={this.state.entity_list}
                dataSource={this.buildTableData()}
                filterable
                columns={[
                    {
                        title: 'Currency',
                        dataIndex: 'currency_iso_alpha_3',
                        key: 'currency_iso_alpha_3',
                        filters: this.searchCurrencyById(),
                        onFilter: (value, record) => record.currency_iso_alpha_3 && record.currency_iso_alpha_3.startsWith(value),
                        ...this.props.getColumnSearchProps({
                            dataIndex: 'currency_iso_alpha_3',
                            filterInputType: 'SELECT',
                            render: (text) => {
                                // const currentRecord = this.state.currencies_list.find((currency) => currency.id === text);
                                const link = `https://wise.com/public-resources/assets/flags/rectangle/${text ? text.toLowerCase() : ''}.png`
                                return (
                                    <>
                                        <img src={link} style={{ height: 16, width: 16, verticalAlign: 'middle', marginBottom: '4px' }} />
                                        &nbsp;
                                        {text}
                                    </>
                                )
                            },
                        })
                    },
                    {
                        title: 'Active',
                        dataIndex: 'active',
                        filters: [{value: 1, text: 'Active'},{value:0 , text: 'Inactive'}],
                        filterSearch: true,
                        onFilter: (value, record) => record.active === value,
                        ...this.props.getColumnSearchProps({
                            dataIndex: 'active',
                            filterInputType: 'SELECT',
                            render: (text, record) => {
                                switch (record.active) {
                                    case 1:
                                        return <Tag color='success' style={{ width: 70, textAlign: "center", cursor: 'pointer' }}>Active</Tag>
                                    default:
                                        return <Tag color='error' style={{ width: 70, textAlign: "center", cursor: 'pointer' }}>Inactive</Tag>
                                }
                            },
                        })

                    },
                    {
                        title: 'Beneficiary',
                        dataIndex: 'nickname',
                        key: 'nickname',
                        sorter: (a, b) => (a.nickname || '').localeCompare(b.nickname, undefined, { sensitivity: 'base' }),
                        ...this.props.getColumnSearchProps({
                            dataIndex: 'nickname',
                            label: 'Beneficiary',
                            render: (text, record) => {
                                return <>
                                    <strong>{text}</strong>
                                    <br />
                                    <span style={{ lineHeight: '20%' }}>
                                {record.ben_address_line_1} <br />{record.ben_address_suburb} {record.ben_address_state} {record.ben_address_postcode}
                            </span>
                                </>
                            }
                        })
                    },
                    {
                        title: 'Beneficiary Bank',
                        dataIndex: 'bank_legal_name',
                        key: 'bank_legal_name',
                        sorter: (a, b) => (a.bank_legal_name || '').localeCompare(b.bank_legal_name, undefined, { sensitivity: 'base' }),
                        ...this.props.getColumnSearchProps({
                            dataIndex: 'bank_legal_name',
                            label:'Beneficiary Bank',
                            render: (text, record) => {
                                return (
                                    <>
                                        <strong>{text}</strong><br />
                                        <span style={{ lineHeight: '20%' }}>
                                {record.bank_address_line_1} <br />{record.bank_address_suburb} {record.bank_address_state} {record.bank_address_postcode}
                            </span>
                                    </>
                                )
                            },
                        })
                    },
                    {
                        title: 'Account',
                        dataIndex: 'bank_address_country',
                        ...this.props.getColumnSearchProps({
                            dataIndex: 'bank_address_country',
                            key: 'bank_address_country',
                            filterInputType: 'NONE',
                            render: (text, record) => {
                                return (
                                    <React.Fragment>
                                        <strong>{record.bank_address_country && this.state.country_list.length > 0 && this.state.country_list.find(item => item.id === record.bank_address_country).full_name}</strong>
                                        <Divider style={{ marginTop: 4, marginBottom: 4 }} />
                                        <span style={{ lineHeight: '20%' }}>
                                {record.swift_code ? <span><strong>SWIFT: </strong>{record.swift_code}<br /></span> : ''}
                                            {record.bsb_code ? <span><strong>BSB: </strong>{record.bsb_code}<br /></span> : ''}
                                            {record.account_number ? <span><strong>Account: </strong>{record.account_number}<br /></span> : ''}
                                            {record.iban ? <span><strong>IBAN: </strong>{record.iban}<br /></span> : ''}
                                            {record.aba_routing_number ? <span><strong>ABA: </strong>{record.aba_routing_number}<br /></span> : ''}
                                            {record.sort_code ? <span><strong>Sort: </strong>{record.sort_code}<br /></span> : ''}
                            </span>
                                    </React.Fragment>
                                );
                            }
                        })
                    },
                    {
                        title: 'Actions',
                        key: 'actions',
                        dataIndex: 'actions',
                        render: (text) => text
                    }
                ]}
                defaultSorted={[
                    {
                        id: 'nickname',
                        asc: true,
                    },
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-highlight"
            />
        )
    }
}

// export default withRouter(withStyles(styles)(BeneficiaryList));

// import BeneficiaryList from "./BeneficiaryList";

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        beneficiaries: state.beneficiaries,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // fetchBeneficiaryList: () => {
        //     dispatch(fetchBeneficiaryList())
        // },
        fetchBeneficiaryListByClientId: (clientId) => {
            dispatch(fetchBeneficiaryListByClientId(clientId))
        },
        editBeneficiary: id => {
            dispatch(editBeneficiary(id))
        },
    }
}

const BeneficiaryListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(styles)(ListTable(BeneficiaryList))))

export default BeneficiaryListContainer
