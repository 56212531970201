import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import ReactTable from 'react-table';
import {FormattedNumber} from 'react-intl';
import Table from 'components/Table/Table.jsx';
import {injectIntl} from 'react-intl';

// import * as jsPDF from 'jspdf'
// import html2canvas from 'html2canvas'

import {API} from 'aws-amplify';
import {Snackbar} from '@material-ui/core';
import {getLetterheadLogo, receiptFooter} from '../../../libs/branding';

const axios = require('axios');
var moment = require('moment');

// const forexWorldWideLogo = require('assets/img/pdf_img/forexworldwide_logo_transparent_280x50.42dc12df.png')
// const forexSportLogo = require('assets/img/pdf_img/fxsport_signature_gmail.png')
// const goStudyLogo = require('assets/img/pdf_img/GOSTUDY_HorizontalUsage_Black_250x50.png')

// const us_flag = require("assets/img/flags/US.png");
// const au_flag = require("assets/img/flags/AU.png");
// const gb_flag = require("assets/img/flags/GB.png");

// var systemNews = [
//   "Alpha Testing: Staff",
//   "Alpha Testing: Clients",
//   "Implementing: Beneficiaries",
//   "Development: Transfers"
// ];

const style = {
  root: {},
  pageHeadings: {
    fontFamily: 'Arial',
    fontWeight: 600,
    fontSize: 22
  },
  pageSubHeadings: {
    fontFamily: 'Arial',
    fontWeight: 600,
    fontSize: 18
  },
  tableHeaders: {
    width: 150,
    textAlign: 'left',
    verticalAlign: 'top',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 8
  },
  tableCells: {
    textAlign: 'left',
    verticalAlign: 'top',
    paddingTop: 0,
    paddingBottom: 0
  },
  backgroundBlue: {
    backgroundColor: '#348feb40'
  },
  topLetterheadLogo: {
    width: '300px'
  }

};


class TransferReceiptPage extends React.Component {
  state = {
    value: 0,
    currencies_list_priority: [],
    map_data: {},
    beneficiaries_list: [],
    beneficiary_id: '',
    transfer_heading: 'Current Transfers',
    transfer: {},
    toast: false
  };

  componentDidMount() {
    // console.log(this.props.match.params);
    this.updateTransferData(this.props.match.params.transfer_id);
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.prefill !== this.props.prefill) {
      this.updateTransferData();
    }
  }

  updateTransferData(option_value) {
    this.setState({transfer_id: option_value});

    API.get('transfers', `/get-full/id/${option_value}`)
      .then(response => {
        console.log(response);
        this.setState({
          transfer: response.fullList,
          payouts_list: response.payouts
        });

        API.get(
          'currencies',
          `/currencies/get/${response.fullList.currency_from_id}`
        )
          .then(response => {
            // console.log(response);
            this.setState({
              currency_from: response
            });
          })
          .catch(error => {
            console.log(error);
          });

        API.get(
          'currencies',
          `/currencies/get/${response.fullList.currency_to_id}`
        )
          .then(response => {
            // console.log(response);
            this.setState({
              currency_to: response
            });
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });

    API.get('beneficiaries', `/beneficiaries/get`).then(response => {
      // console.log(response);
      this.setState({
        beneficiaries_list: response
      });
      return response;
    });

    API.get('currencies', `/currencies/get-list-priority`)
      .then(response => {
        // console.log(response);
        this.setState({
          currencies_list_priority: response
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if a value is larger than a number or not
  isLargerThanNumber(value, number) {
    if (value !== '' && value >= number) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'isLargerThanNumber':
        if (
          this.isLargerThanNumber(
            event.target.value,
            stateNameEqualTo
          )
        ) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
  }

  handleChange = (event, value) => {
    this.setState({value});
  };
  handleChangeIndex = index => {
    this.setState({value: index});
  };

  printDocument() {
    // const input = document.getElementById('divToPrint')
    // html2canvas(input).then(canvas => {
    //     const imgData = canvas.toDataURL('image/png')
    //     const pdf = new jsPDF('p', 'mm', 'a4')
    //     var width = pdf.internal.pageSize.getWidth()
    //     var height = pdf.internal.pageSize.getHeight()
    //     pdf.addImage(imgData, 'JPEG', 0, 0, width, height)
    //     // pdf.output('dataurlnewwindow');
    //     pdf.save(
    //         `transfer_receipt_${this.props.match.params.transfer_id.padStart(
    //             5,
    //             '0'
    //         )}`
    //     )
    // })

    const requestBody = {
      url: 'https://portal.forexworldwide.com/pages/receipt/' + this.props.match.params.transfer_id,
      selector: '#divToPrint'
    };
    this.setState({toast: true});
    axios.post('https://api.neutronapi.com/pdfservice/api/generate', requestBody, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf',
        'Access-Control-Allow-Origin': '*'
      }
    }).then(response => {
      const blob = new Blob([response.data], {type: 'application/pdf'});
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `transfer_receipt_${this.props.match.params.transfer_id.padStart(5, '0')}`;
      link.click();
      this.setState({toast: false});
    }).catch(err => {
      console.log('error');
    });
  }

  buildData = () => {
    let return_data = [];

    return_data.push({date: ['DATE', 'sdsaa']});

    return return_data;
  };

  payoutItemTable = () => {
    const {classes} = this.props;
    // console.log(id)
    // console.log(this.state)
    // console.log(this.state.payouts_list[id])

    let totalPayment = 0;
    for (let i = 0; i < this.state.payouts_list.length; i++) {
      totalPayment += Number.parseFloat(this.state.payouts_list[i].amount_to);
    }

    let returnValue = [];

    if (!this.state.payouts_list.length) {

      returnValue.push(
        <tr>
          <table style={{width: '100%'}}>
            <tbody>
            <tr style={{width: '100%'}}>
              <td>
                <table style={{width: '100%'}}>
                  <tbody style={{width: '100%'}}>
                  <tr style={{width: '100%'}}>
                    <td className={classes.tableCells}>
                      <table style={{width: '100%'}}>
                        <tbody>
                        <tr className={classes.backgroundBlue}>
                          <th className={classes.tableHeaders}>
                            Beneficiary
                          </th>
                          <td className={classes.tableCells}>
                            <React.Fragment>
                              <strong>
                                Payout will be made to your currency balance
                              </strong>
                            </React.Fragment>
                          </td>
                        </tr>

                        <tr>
                          <th className={classes.tableHeaders}>
                            Amount Paid
                          </th>
                          <td className={classes.tableCells}>
                            <React.Fragment>
                              <strong>
                                {this.props.intl.formatNumber(
                                  this.state.transfer.amount_to,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  }
                                )}{` `}
                                <div
                                  className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`} />
                                {` `}
                                {this.state.currency_to.iso_alpha_3.toUpperCase()}
                              </strong>
                            </React.Fragment>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </tr>
      );
    }

    for (let i = 0; i < this.state.payouts_list.length; i++) {
      const payout = this.state.payouts_list[i];
      console.log(payout);
      if (i == 0 || (i > 0 && (payout.beneficiary_id != this.state.payouts_list[0].beneficiary_id))) {

        let beneficiaryNickname = payout.nickname;
        if (payout.holdingAccountPayee) {
          beneficiaryNickname = `Payment made to ${this.state.currency_to.iso_alpha_3.toUpperCase()} Currency Balance`;
        }

        returnValue.push(
          <tr>
            <table style={{width: '100%'}}>
              <tbody>
              {/* {
this.state.payouts_list.map((item) => {
return <tr> */}
              <tr style={{width: '100%'}}>
                {/* <td style={{ verticalAlign: "top", paddingRight: 20, paddingLeft: 8, paddingTop: 10, paddingBottom: 10 }}> */}
                <td>
                  <table style={{width: '100%'}}>
                    <tbody style={{width: '100%'}}>
                    <tr style={{width: '100%'}}>
                      <td className={classes.tableCells}>
                        <table style={{width: '100%'}}>
                          <tbody>

                          <tr className={classes.backgroundBlue}>
                            <th className={classes.tableHeaders}>
                              Beneficiary
                            </th>
                            <td className={classes.tableCells}>
                              <React.Fragment>
                                <strong>
                                  {beneficiaryNickname}
                                </strong>
                              </React.Fragment>
                            </td>
                          </tr>

                          <tr>
                            <th className={classes.tableHeaders}>
                              Beneficiary Address
                            </th>
                            <td className={classes.tableCells}>
                              <React.Fragment>
                                {payout.ben_address_line_1}&nbsp;
                                {payout.ben_address_line_2}&nbsp;
                                {payout.ben_address_suburb}&nbsp;
                                {payout.ben_address_state}&nbsp;
                                {payout.ben_address_postcode}&nbsp;
                                {payout.beneficiary_country_name}
                              </React.Fragment>
                            </td>
                          </tr>

                          {payout.internalAccount &&
                            <>
                              <tr className={classes.backgroundBlue}>
                                <th className={classes.tableHeaders}>
                                  Payment Type
                                </th>
                                <td className={classes.tableCells}>
                                  Internal Account Payment
                                </td>
                              </tr>
                            </>
                          } {/* payout.internalAccount &&  */}

                          {!(payout.internalAccount) &&
                            <>
                              <tr className={classes.backgroundBlue}>
                                <th className={classes.tableHeaders}>
                                  Bank Name
                                </th>
                                <td className={classes.tableCells}>
                                  {payout.bank_legal_name ? (
                                    <span>
                                                {
                                                  payout.bank_legal_name
                                                }
                                      <br />
                                            </span>
                                  ) : (
                                    ''
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <th className={classes.tableHeaders}>
                                  Bank Address
                                </th>
                                <td className={classes.tableCells}>
                                  <React.Fragment>
                                    {payout.bank_address_line_1}&nbsp;
                                    {payout.bank_address_line_2}&nbsp;
                                    {payout.bank_address_suburb}&nbsp;
                                    {payout.bank_address_state}&nbsp;
                                    {payout.bank_address_state}&nbsp;
                                    {payout.bank_address_postcode}&nbsp;
                                    {payout.beneficiary_country_name}
                                  </React.Fragment>
                                </td>
                              </tr>
                            </>
                          }
                          {payout.swift_code && (
                            <tr
                              className={
                                classes.backgroundBlue
                              }
                            >
                              <th
                                className={
                                  classes.tableHeaders
                                }
                              >
                                SWIFT
                                Code
                              </th>
                              <td
                                className={
                                  classes.tableCells
                                }
                              >
                                {payout.swift_code ? (
                                  <span
                                  >
                                                    {
                                                      payout.swift_code
                                                    }
                                    <br />
                                                </span>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          )}
                          {payout.account_number && (
                            <tr
                              // className={
                              //     payout.swift_code ? '' : classes.backgroundBlue
                              // }
                            >
                              <th
                                className={
                                  classes.tableHeaders
                                }
                              >
                                Account
                                Number
                              </th>
                              <td
                                className={
                                  classes.tableCells
                                }
                              >
                                {payout.account_number ? (
                                  <span
                                  >
                                                    {
                                                      payout.account_number
                                                    }
                                    <br />
                                                </span>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          )}
                          {payout.iban && (
                            <tr className={classes.backgroundBlue}>
                              <th className={classes.tableHeaders}>
                                IBAN
                              </th>
                              <td className={classes.tableCells}>
                                {payout.iban ? (
                                  <span>
                                    {payout.iban}
                                    <br />
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          )}
                          {payout.bsb_code && (
                            <tr className={
                              payout.swift_code ? classes.backgroundBlue : ''
                            }>
                              <th
                                className={
                                  classes.tableHeaders
                                }
                              >
                                BSB
                              </th>
                              <td
                                className={
                                  classes.tableCells
                                }
                              >
                                {payout.bsb_code ? (
                                  <span
                                  >
                                                    {
                                                      payout.bsb_code
                                                    }
                                    <br />
                                                </span>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          )}
                          {payout.aba_routing_number && (
                            <tr className={classes.backgroundBlue}>
                              <th
                                className={
                                  classes.tableHeaders
                                }
                              >
                                ABA
                                Routing
                              </th>
                              <td
                                className={
                                  classes.tableCells
                                }
                              >
                                {payout.aba_routing_number ? (
                                  <span
                                  >
                                                    {
                                                      payout.aba_routing_number
                                                    }
                                    <br />
                                                </span>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          )}
                          {payout.sort_code && (
                            <tr>
                              <th
                                className={
                                  classes.tableHeaders
                                }
                              >
                                Sort
                                Code
                              </th>
                              <td
                                className={
                                  classes.tableCells
                                }
                              >
                                {payout.sort_code ? (
                                  <span
                                  >
                                                    {
                                                      payout.sort_code
                                                    }
                                    <br />
                                                </span>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          )}
                          {payout.transit_code && (
                            <tr className={classes.backgroundBlue}>
                              <th
                                className={
                                  classes.tableHeaders
                                }
                              >
                                Transit
                                Code
                              </th>
                              <td
                                className={
                                  classes.tableCells
                                }
                              >
                                {payout.transit_code ? (
                                  <span
                                  >
                                                    {
                                                      payout.transit_code
                                                    }
                                    <br />
                                                </span>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          )}
                          {payout.bank_code && (
                            <tr className={classes.backgroundBlue}>
                              <th
                                className={
                                  classes.tableHeaders
                                }
                              >
                                Bank
                                Code
                              </th>
                              <td
                                className={
                                  classes.tableCells
                                }
                              >
                                {payout.bank_code ? (
                                  <span
                                  >
                                                    {
                                                      payout.bank_code
                                                    }
                                    <br />
                                                </span>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          )}

                          <tr
                            className={classes.backgroundBlue}
                          >
                            <th
                              className={
                                classes.tableHeaders
                              }
                            >
                              Client Ref/Inv No
                            </th>
                            <td
                              className={
                                classes.tableCells
                              }
                            >
                              <React.Fragment
                              >
                                {
                                  payout.detail_1
                                }
                              </React.Fragment>
                            </td>
                          </tr>
                          <tr
                          >
                            <th
                              className={
                                classes.tableHeaders
                              }
                            >
                              Amount Paid
                            </th>
                            <td
                              className={
                                classes.tableCells
                              }
                            >
                              <React.Fragment
                              >
                                <strong>
                                  {this.props.intl.formatNumber(
                                    // payout.amount_to,
                                    totalPayment,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    }
                                  )}{` `}
                                  <div
                                    className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                  />
                                  {` `}
                                  {this.state.currency_to.iso_alpha_3.toUpperCase()}
                                </strong>
                              </React.Fragment>
                            </td>
                          </tr>


                          </tbody>
                        </table>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              {/* })
} */}
              </tbody>
            </table>
          </tr>
        );
      }
      if (this.state.payouts_list.length > 1 && (payout.beneficiary_id == this.state.payouts_list[0].beneficiary_id)) {
        // console.log(payout.amount)

        returnValue.push(
          <tr>
            <table style={{width: '100%'}}>
              <tbody>
              {/* {
    this.state.payouts_list.map((item) => {
    return <tr> */}
              <tr style={{width: '100%'}}>
                {/* <td style={{ verticalAlign: "top", paddingRight: 20, paddingLeft: 8, paddingTop: 10, paddingBottom: 10 }}> */}
                <td>
                  <table style={{width: '100%'}}>
                    <tbody style={{width: '100%'}}>
                    <tr
                      style={{
                        width:
                          '100%'
                      }}
                    >
                      <td
                        className={
                          classes.tableCells
                        }
                      >
                        <table
                          style={{
                            width:
                              '100%'
                          }}
                        >
                          <tbody
                          >


                          <tr
                          >
                            <th
                              className={
                                classes.tableHeaders
                              }
                            >

                            </th>
                            <td
                              className={
                                classes.tableCells
                              }
                            >
                              <React.Fragment
                              >
                                <em>
                                  Payout {(i + 1)}:
                                </em>
                                &nbsp;&nbsp;&nbsp;
                                {this.props.intl.formatNumber(
                                  payout.amount_to,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  }
                                )}{` `}
                                <div
                                  className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                />
                                {` `}
                                {this.state.currency_to.iso_alpha_3.toUpperCase()}
                              </React.Fragment>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </tr>
        );


      }


    }
    return returnValue;
  };

  render() {
    const {classes} = this.props;

    if (!this.props.app_state.current_client) {
      return null;
    }
    if (this.props.app_state.current_client.account_status == 1) {
      this.props.history.push('/pending-registration');
    }
    if (this.props.app_state.current_client.account_status == 5) {
      this.props.history.push('/registration');
    }

    if (this.props.app_state.current_client.account_status == 6) {
      this.props.history.push('/migrate');
    }

    if (!this.state.transfer)
      return (
        <React.Fragment>
          <strong>Error: Unable to access transfer</strong>
        </React.Fragment>
      );
    if (!this.state.currency_from) return null;
    if (!this.state.currency_to) return null;

    const logo = getLetterheadLogo(this.state.transfer.client_team_id);


    return (
      <React.Fragment>
        {
          this.state.toast ?
            <Snackbar anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }} message={'Pdf Generation in progress. Do not close this window until the pdf is downloaded'}
                      open={this.state.toast} autoHideDuration={5000} onClose={() => this.setState({toast: false})}>
            </Snackbar> : ''
        }
        <div>
          <GridContainer alignItems='stretch'>
            <GridItem xs={12}>
              <Card style={{height: '90%'}}>
                <CardBody>
                  <GridContainer justify='center'>
                    <GridItem xs={12} md={10}>
                      <div style={{float: 'right'}}>
                        <Button
                          color='primary'
                          style={{float: 'right'}}
                          onClick={() =>
                            this.printDocument()
                          }
                        >
                          Save PDF
                        </Button>
                      </div>
                    </GridItem>
                  </GridContainer>

                  <div
                    id='divToPrint'
                    className='mt4'
                    style={{
                      hidden: 'hidden',

                      width: '210mm',
                      minHeight: '297mm',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      padding: '20mm'
                    }}
                  >
                    <img
                      src={logo}
                      alt='logo'
                      className={classes.topLetterheadLogo}
                    />
                    <br />
                    <hr />
                    <h3 className={classes.pageHeadings}>
                      Payment Confirmation
                    </h3>
                    <br />

                    <GridContainer justify='center'>
                      <GridItem xs={12} md={12}>
                        <h5 className={classes.pageSubHeadings}>
                          Transaction Details
                        </h5>
                        <div style={{border: '1px solid #a1a1a1'}}>
                          <table style={{width: '100%'}}>
                            <tbody style={{
                              fontFamily: 'Arial',
                              textAlign: 'left',
                              verticalAlign: 'top'
                            }}>
                            <tr className={classes.backgroundBlue}>
                              <th className={classes.tableHeaders}>
                                Settlement Date
                              </th>
                              <td className={classes.tableCells}>
                                <React.Fragment>
                                  {this
                                    .state
                                    .transfer
                                    .settlement_date ? (
                                    <React.Fragment>
                                      {moment(
                                        this.state.transfer.settlement_date).format('DD/MM/YYYY')}
                                    </React.Fragment>
                                  ) : (
                                    ''
                                  )}
                                </React.Fragment>
                              </td>
                            </tr>
                            <tr>
                              <th
                                className={
                                  classes.tableHeaders
                                }
                              >
                                Client Name
                              </th>
                              <td
                                className={
                                  classes.tableCells
                                }
                              >
                                <React.Fragment
                                >
                                  {this
                                    .state
                                    .transfer
                                    .client_nickname
                                    ? this
                                      .state
                                      .transfer
                                      .client_nickname
                                    : ''}
                                </React.Fragment>
                              </td>
                            </tr>
                            <tr
                              className={
                                classes.backgroundBlue
                              }
                            >
                              <th
                                className={
                                  classes.tableHeaders
                                }
                              >
                                Transfer
                                Ref
                              </th>
                              <td
                                className={
                                  classes.tableCells
                                }
                              >
                                <React.Fragment
                                >
                                  {this
                                    .state
                                    .transfer
                                    .id
                                    ? `TR${this.state.transfer.id
                                      .toString()
                                      .padStart(
                                        6,
                                        '0'
                                      )}`
                                    : ''}
                                </React.Fragment>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* <div style={{ border: "1px solid #777" }}>
                          <Table
                            size="small"
                            tableData={[
                              [
                                <span style={{ fontFamily: "Arial" }}>Date</span>,
                                <React.Fragment>
                                  {this.state.transfer.settlement_date ? (
                                    <React.Fragment>
                                      {moment(
                                        this.state.transfer.settlement_date
                                      ).format("DD/MM/YYYY")}
                                    </React.Fragment>
                                  ) : (
                                      ""
                                    )}
                                </React.Fragment>
                              ],
                              [
                                <span style={{ fontFamily: "Arial" }}>Client</span>,
                                <React.Fragment>
                                  {this.state.transfer.client_nickname
                                    ? this.state.transfer.client_nickname
                                    : ""}
                                </React.Fragment>
                              ],
                              [
                                <span style={{ fontFamily: "Arial" }}>Transfer Reference</span>,
                                <React.Fragment>
                                  {this.state.transfer.id
                                    ? `TR${this.state.transfer.id.toString().padStart(6, '0')}`
                                    : ""}
                                </React.Fragment>
                              ],
                              [
                                <span style={{ fontFamily: "Arial" }}>Client Reference</span>,
                                <React.Fragment>
                                  {this.state.payouts_list.length > 1
                                    ? "Multiple references (see below)"
                                    : this.state.payouts_list[0].detail_1}
                                </React.Fragment>
                              ],
                              [
                                <span style={{ fontFamily: "Arial" }}>Client Buys</span>,
                                <React.Fragment>
                                  {this.state.transfer.amount_to ? (
                                    <React.Fragment>
                                      <div
                                        className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                      />{" "}
                                      {this.state.currency_to.iso_alpha_3.toUpperCase()}{" "}
                                      {this.props.intl.formatNumber(
                                        this.state.transfer.amount_to,
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        }
                                      )}
                                    </React.Fragment>
                                  ) : (
                                      ""
                                    )}
                                </React.Fragment>
                              ]
                            ]}
                          />
                        </div> */}
                        {/* <hr /> */}
                        <br />
                        <h5
                          className={
                            classes.pageSubHeadings
                          }
                        >
                          Payout Details
                        </h5>
                        <div
                          style={{
                            border:
                              '1px solid #a1a1a1'
                          }}
                        >
                          <table
                            style={{
                              width: '100%'
                            }}
                          >
                            <tbody>
                            {this.payoutItemTable()}
                            </tbody>
                          </table>
                          {/* <ReactTable
                            style={{ width: "100%" }}
                            data={this.state.payouts_list}
                            //data={this.buildTableData()}
                            // filterable
                            // === #799 Turn this off to merged by Tom ===
                            // defaultExpanded={{ 0: true }} //set to expend state later #852
                            // expanded={{ 0: true }}
                            columns={[
                              {
                                Header: "Beneficiary",
                                accessor: "beneficiary_id", //"nickname", Will switch back to nickname while next build #798
                                sortable: false,
                                filterable: false,
                                // filterMethod: (filter, rows) =>
                                //   matchSorter(rows, filter.value, { keys: ["transfer_id"] }),
                                // filterAll: true,
                                Cell: c => (
                                  //if beneficiary_id === 0, do something, work around #798
                                  <React.Fragment>
                                    {// if(beneficiary_id === 0 or null) // https://stackoverflow.com/questions/6003884/how-do-i-check-for-null-values-in-javascript
                                      c.value === 0 ||
                                        c.value === "null" ||
                                        c.value === "" ? (
                                          <React.Fragment>
                                            {<div>Beneficiary To Be Provided</div>}
                                          </React.Fragment>
                                        ) : (
                                          c.original.nickname
                                        )}
                                    {
                                      //console.log(c)
                                    }
                                  </React.Fragment>
                                ),
                                maxWidth: 450
                              },
                              {
                                Header: "Amount",
                                accessor: "amount_to",
                                sortable: false,
                                filterable: false,
                                Cell: c => (
                                  //set in a currency flag, and fix digit into minimumFractionDigits 2 and maximumFractionDigits={2}
                                  <React.Fragment>
                                    <div
                                      className={`currency-flag currency-flag-${
                                        this.state.transfer
                                          .currency_to_iso_alpha_3
                                          ? this.state.transfer.currency_to_iso_alpha_3.toLowerCase()
                                          : ""
                                        }`}
                                    />{" "}
                                    {this.state.transfer.currency_to_iso_alpha_3}{" "}
                                    <FormattedNumber
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                      value={c.value}
                                    />
                                  </React.Fragment>
                                ),
                                maxWidth: 450
                              },
                              {
                                Header: "Reference",
                                accessor: "detail_1",
                                sortable: false,
                                filterable: false,
                                // filterMethod: (filter, rows) =>
                                //   matchSorter(rows, filter.value, { keys: ["descriptiond"] }),
                                // filterAll: true,
                                maxWidth: 450
                              }
                            ]}
                            // defaultPageSize={5}
                            pageSize={
                              this.state.payouts_list.length > 10
                                ? 10
                                : this.state.payouts_list.length
                            }
                            showPaginationBottom={false}
                            className="-highlight"
                          /> */}
                        </div>
                      </GridItem>
                    </GridContainer>
                    <hr />

                    <GridContainer justify='center'>
                      <GridItem xs={12} md={12}>
                        {/* {payout.internalAccount &&  */}
                        <p>
                          Funds have now been credited to your nominated account.
                        </p>
                        {/* } */}
                        {/* {!(payout.internalAccount) &&
                                                <p>
                                                    Funds will be credited to your nominated account within 24-48 hours.  
                                                </p>
                                            } */}
                      </GridItem>
                    </GridContainer>

                    <GridContainer justify='center'>
                      <GridItem xs={12} md={12}>
                        <div>
                          {receiptFooter(this.state.transfer.client_team_id)}
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    app_state: state.app_state
  };
};

// const mapDispatchToProps = (dispatch) => {
// return {
//   onLoginSuccess: (user_id) => {
//     dispatch(onLoginSuccess(user_id));
//   }
// };
// };

const TransferReceiptPageContainer = connect(
  mapStateToProps
  // mapDispatchToProps
)(injectIntl(withRouter(withStyles(style)(TransferReceiptPage))));

export default TransferReceiptPageContainer;
