import React from 'react'
import { API } from 'aws-amplify'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'

import { cardTitle } from '../../../../assets/jss/material-dashboard-pro-react'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import CardBody from '../../../Card/CardBody'
import Card from '../../../Card/Card'
import CustomReactSelect from '../../../Forms/CustomReactSelect/CustomReactSelect'
import { injectIntl } from 'react-intl'
import Radio from '@material-ui/core/Radio'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'

const uuidv4 = require('uuid/v4')
const axios = require('axios')

const style = {
    ...customCheckboxRadioSwitch,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    infoText: {
        fontWeight: '300',
        margin: '10px 0 30px',
        textAlign: 'center',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    inputAdornment: {
        position: 'relative',
    },
}

class Step3PayingFrom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            client_list: [],
            country_list: [],
            // currency_list: [],
            country_list_prio: [],
            // currency_list_priority: [],
            currencies_list_priority: [],
            currency_list_cross: [],
            allow_currencies: [],
            payee_id: '',
            payee_country_id: '',
            currency_from_id: '',
            payee: {},

            time: {},
            seconds: 30,

            payee_first_name: '',
            payee_first_name_state: '',
            payee_middle_name: '',
            payee_middle_name_state: '',
            payee_last_name: '',
            payee_last_name_state: '',
            payee_email: '',
            payee_email_state: '',

            payee_address_line_1: '',
            payee_address_line_1_state: '',
            payee_address_line_2: '',
            payee_address_line_2_state: '',

            payee_suburb: '',
            payee_suburb_state: '',
            payee_state: '',
            payee_state_state: '',
            payee_postcode: '',
            payee_postcode_state: '',
            payee_country: '',
            payee_country_state: '',

            supported_country_currency: false,
            payment_method_id: '1',
            payeeActiveFlag: false,
        }
        this.timer = 0
        this.handleChangeEnabled = this.handleChangeEnabled.bind(this)
    }

    componentDidMount() {
        // API.get("clients", `/clients/get-all`)
        //   .then(response => {
        //     // console.log(response);
        //     this.setState({
        //       client_list: response
        //     });
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });

        API.get('countries', `/countries_cross`)
            .then(response => {
                this.setState({
                    //country_list: response.fullList,
                    country_list_prio: response,
                })
            })
            .catch(error => {
                // console.log(error);
            })

        API.get('currencies', `/currencies/get-list-priority`)
            .then(response => {
                this.setState({
                    // currencies_list: response.fullList,
                    currencies_list_priority: response,
                })
            })
            .catch(error => {
                console.log(error)
            })

        this.props.updateNewTransferCreation('payee_different', '0')
        this.startTimer()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("componentDidUpdate: CALL");
        if (
            prevProps.transfers.new_transfer_data.payee_country_id !==
                this.props.transfers.new_transfer_data.payee_country_id ||
            prevProps.transfers.new_transfer_data.currency_from_id !==
                this.props.transfers.new_transfer_data.currency_from_id
        ) {
            if (
                this.props.transfers.new_transfer_data.payee_country_id > 0 &&
                this.props.transfers.new_transfer_data.currency_from_id > 0
            ) {
                API.post('transfers', `/remitting-account/get-details`, {
                    body: {
                        country_id: this.props.transfers.new_transfer_data
                            .payee_country_id,
                        currency_id: this.props.transfers.new_transfer_data
                            .currency_from_id,
                    },
                })
                    .then(response => {
                        console.log(response)
                        if (response.length === 1) {
                            this.startTimer()
                            this.setState({ supported_country_currency: true })
                            this.props.updateNewTransferCreation(
                                'remitting_bank',
                                response[0].bank_account_id
                            )
                            this.props.updateNewTransferCreation(
                                'remitting_bank_account_id',
                                response[0].bank_account_id
                            )
                            API.get(
                                'entitybanksaccount',
                                `/get/id/${response[0].bank_account_id}`
                            ).then(response => {
                                console.log(response)
                                this.props.updateNewTransferCreation(
                                    'remitting_bank_account_detail',
                                    response
                                )
                                this.props.updateNewTransferCreation(
                                    'remitting_bank_id',
                                    response.entity_bank_id
                                )
                                API.get(
                                    'entitybanks',
                                    `/get/id/${response.entity_bank_id}`
                                )
                                    .then(response => {
                                        console.log(response)
                                        this.props.updateNewTransferCreation(
                                            'remitting_bank_detail',
                                            response
                                        )
                                        return response
                                    })
                                    .catch(error => {
                                        console.log(error)
                                    })
                                // this.props.loadTransferDetailsSuccess();
                                return response
                            })

                            API.post('email', `/interaction/main`, {
                                body: {
                                    interaction:
                                        'New Transfer Request Step 3 PayingFrom',
                                    data: {
                                        staff_id: this.props.app_state
                                            .current_staff.id,
                                        payee_country_id: this.props.transfers
                                            .new_transfer_data.payee_country_id,
                                        currency_from_id: this.props.transfers
                                            .new_transfer_data.currency_from_id,
                                    },
                                    result: 'New transfer request proceeding',
                                },
                            })
                        } else {
                            clearInterval(this.timer)
                            this.setState({ supported_country_currency: false })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }

        if (
            prevProps.transfers.new_transfer_data.amount_to !==
            this.props.transfers.new_transfer_data.amount_to
        ) {
            this.fetchNewRate()
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    secondsToTime = secs => {
        let hours = Math.floor(secs / (60 * 60))

        let divisor_for_minutes = secs % (60 * 60)
        let minutes = Math.floor(divisor_for_minutes / 60)

        let divisor_for_seconds = divisor_for_minutes % 60
        let seconds = Math.ceil(divisor_for_seconds)

        let obj = {
            h: hours,
            m: minutes,
            s: seconds,
        }
        return obj
    }

    startTimer = () => {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000)
        }
    }

    countDown = () => {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        })
        this.props.updateNewTransferCreation('current_rate_ttl', seconds)

        // Check if we're at zero.
        if (seconds === 0) {
            this.setState({
                seconds: 30,
            })
            this.props.updateNewTransferCreation('current_rate_ttl', 30)
            this.fetchNewRate()
        }
    }

    getPayeeDetails(payee_id) {
        API.get('clients', `/clients/get/id/${payee_id}`)
            .then(response => {
                this.setState({
                    payee: response,
                })
                this.props.updateNewTransferCreation('payee', response)
                return response.team_id
            })
            .then(team_id => {
                API.get('teams', `/get/div-entity/${team_id}`)
                    .then(response => {
                        this.setState({
                            payee: Object.assign({}, this.state.payee, {
                                division_nickname: response.division_nickname,
                                entity_nickname: response.entity_nickname,
                                team_nickname: response.team_nickname,
                            }),
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error)
            })
    }

    getPayeeCurrencyDetails(currency_id) {
        API.get('currencies', `/currencies/get/${currency_id}`)
            .then(response => {
                this.setState({
                    currency_from: response,
                })
                this.props.updateNewTransferCreation('currency_from', response)
            })
            .then(() => {
                this.fetchNewRate()
            })
            .catch(error => {
                console.log(error)
            })
    }

    fetchNewRate = () => {
        if (
            this.props.transfers.new_transfer_data.currency_to &&
            this.props.transfers.new_transfer_data.currency_from
        ) {
            let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
            axios
                .get(url, {
                    params: {
                        currencies: this.props.transfers.new_transfer_data
                            .currency_from.iso_alpha_3,
                        source: this.props.transfers.new_transfer_data
                            .currency_to.iso_alpha_3,
                        format: '1',
                    },
                })
                .then(response => {
                    let key = `${this.props.transfers.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}${this.props.transfers.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}`
                    let rate_string = response.data.quotes[key]

                    let amount_to = Number.parseFloat(
                        this.props.transfers.new_transfer_data.amount_to
                    )
                    let rate = Number.parseFloat(rate_string)
                    let rate_adjust =
                        Number.parseFloat(
                            this.props.app_state.current_client.default_rate
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_division
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_entity
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_team
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_staff
                        )
                    let adjusted_rate = rate + rate * rate_adjust

                    let amount_from = amount_to * adjusted_rate

                    this.props.updateNewTransferCreation(
                        'current_rate',
                        adjusted_rate.toFixed(5)
                    )
                    this.props.updateNewTransferCreation(
                        'amount_from',
                        amount_from.toFixed(5)
                    )

                    if (this.state.payment_method_id == '2') {
                        this.getWorldPayPaymentURL()
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    handleChangeEnabled(event) {
        this.setState({
            payment_method_id: event.target.value,
        })
        // this.setState({ ["payment_method_id"]: event.target.value });
        this.props.updateNewTransferCreation(
            'payment_method_id',
            event.target.value
        )

        if (event.target.value == '2') {
            this.getWorldPayPaymentURL()
            this.setState({
                payeeActiveFlag: true,
            })
        } else {
            this.props.updateNewTransferCreation('payment_method_reference', '')
            this.props.updateNewTransferCreation('payment_method_url', '')
            this.setState({
                payeeActiveFlag: false,
            })
            this.fetchNewRate()
        }
    }

    getWorldPayPaymentURL() {
        if (this.props.transfers.new_transfer_data.currency_from == undefined)
            return

        let uuid_number = uuidv4()
        this.props.updateNewTransferCreation(
            'payment_method_reference',
            uuid_number
        )

        let from_amount =
            Math.ceil(this.props.transfers.new_transfer_data.amount_from) *
                100 || ''

        let from_currency =
            this.props.transfers.new_transfer_data.currency_from.iso_alpha_3.toUpperCase() ||
            ''
        let clientObj = this.props.transfers.new_transfer_data.client || ''

        API.post('worldpay', `/get-payment-url`, {
            body: {
                from_currency: from_currency,
                from_amount: from_amount,
                order_code: uuid_number,
                client: clientObj,
            },
        })
            .then(response => {
                let res = JSON.stringify(
                    response.paymentService.reply[0].orderStatus[0].reference[0]
                )
                var firstExplode = res.split(',')
                var res1 = firstExplode[0].substring(
                    6,
                    firstExplode[0].length - 1
                )
                res1 = res1.concat(
                    '&successURL=https%3A%2F%2Fportal-api.forexworldwide.com%2Fworldpay%2Fresponse%2Fsuccess&pendingURL=https%3A%2F%2Fportal-api.forexworldwide.com%2Fworldpay%2Fresponse%2Fpending&failureURL=https%3A%2F%2Fportal-api.forexworldwide.com%2Fworldpay%2Fresponse%2Ffailure&cancelURL=https%3A%2F%2Fportal-api.forexworldwide.com%2Fworldpay%2Fresponse%2Fcancel&errorURL=https%3A%2F%2Fportal-api.forexworldwide.com%2Fworldpay%2Fresponse%2Ferror'
                )
                this.props.updateNewTransferCreation('payment_method_url', res1)

                let rate = Number.parseFloat(
                    this.props.transfers.new_transfer_data.current_rate
                )
                let adjusted_rate_credit = rate + rate * 0.02

                this.props.updateNewTransferCreation(
                    'current_rate',
                    adjusted_rate_credit.toFixed(5)
                )

                let amount_to = Number.parseFloat(
                    this.props.transfers.new_transfer_data.amount_to
                )
                let amount_from = amount_to * adjusted_rate_credit
                this.props.updateNewTransferCreation(
                    'amount_from',
                    amount_from.toFixed(5)
                )
            })
            .catch(error => {
                console.log(error)
            })
    }

    sendState() {
        return this.state
    }
    // function that verifies if two strings are equal
    compare(string1, string2) {
        if (string1 === string2) {
            return true
        }
        return false
    }
    // function that returns true if value is email, false otherwise
    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (emailRex.test(value)) {
            return true
        }
        return false
    }
    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'email':
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + ' _state']: 'error' })
                }
                break
            case 'equalTo':
                if (
                    this.compare(
                        event.target.value,
                        this.state[stateNameEqualTo]
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
        this.props.updateNewTransferCreation(stateName, event.target.value)
        if (stateName === 'amount_from') {
            this.fetchNewRate()
        }
    }
    toggleEdit = () => {
        const edit_mode = !this.state.edit_mode
        this.setState({ edit_mode: edit_mode })
    }
    toggleDifferentPayee = () => {
        const different_payee = !this.state.different_payee
        this.setState({ different_payee: different_payee })
        this.props.updateNewTransferCreation(
            'payee_different',
            different_payee ? '1' : '0'
        )
    }

    handleChange = event => {
        this.setState({ selectedValue: event.target.value })
    }
    // handleSelectChange = event => {
    //   this.setState({ [event.target.name]: event.target.value });
    //   this.props.updateNewTransferCreation(event.target.name, event.target.value)
    //   // if (event.target.name === "payee_id") {
    //   //   this.getPayeeDetails(event.target.value);
    //   // }
    //   if (event.target.name === "currency_from_id") {
    //     console.log(event.target.value)
    //     this.getPayeeCurrencyDetails(event.target.value);
    //   }
    // };
    handleCustomReactSelectChange = name => value => {
        var option_value
        if (value === null) {
            option_value = null
        } else {
            option_value = value.value
        }
        this.setState({
            [name]: option_value,
        })

        if (name == 'payee_country_id') {
            let countryValue = value.value
            let clientTransfers = [...this.state.country_list_prio]
            let allow_currencies = [...this.state.allow_currencies]

            clientTransfers.map(el => {
                if (el.country_id == countryValue) {
                    //this.props.updateNewTransferCreation("currency_from_id", el.currency_id);
                    //this.getPayeeCurrencyDetails(el.currency_id);

                    allow_currencies.push(el.currency_id)

                    API.get(
                        'countries',
                        `/countries_currencies/id/${el.country_id}`
                    )
                        .then(response => {
                            // console.log(" ALPHA ");
                            // console.log(response);
                            this.setState({
                                currency_from_id: response[0].id,
                            })
                            this.props.updateNewTransferCreation(
                                'currency_from_id',
                                response[0].id
                            )
                            this.getPayeeCurrencyDetails(response[0].id)
                            this.setState({
                                currencies_list_priority: response,
                            })
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
            })

            this.setState({
                allow_currencies: allow_currencies,
            })
        }

        this.props.updateNewTransferCreation(name, option_value)
        if (name === 'currency_from_id') {
            this.getPayeeCurrencyDetails(option_value)
        }
        this.fetchNewRate()
    }

    isValidated() {
        if (
            // this.state.payee_id > 0 &&
            this.state.currency_from_id > 0 &&
            this.state.payee_country_id > 0
        ) {
            clearInterval(this.timer)
            this.props.updateNewTransferCreation('current_rate_locked', true)
            this.props.updateNewTransferCreation('current_rate_ttl', 0)
            return true
        }
        return false
    }

    getSideCardContent() {
        const { classes } = this.props

        if (!this.state.supported_country_currency) {
            return (
                <React.Fragment>
                    <strong>
                        Transfers from that country &amp; currency are not
                        supported.
                    </strong>
                    <br />
                    <p>Please select an alternative.</p>
                </React.Fragment>
            )
        }

        let currency_from =
            this.props.transfers.new_transfer_data.currency_from || {}
        let currency_to =
            this.props.transfers.new_transfer_data.currency_to || {}

        let current_rate = Number.parseFloat(
            this.props.transfers.new_transfer_data.current_rate
        ).toFixed(5)
        let amount_from = Number.parseFloat(
            this.props.transfers.new_transfer_data.amount_from
        )
        let amount_to = Number.parseFloat(
            this.props.transfers.new_transfer_data.amount_to
        )

        return (
            <React.Fragment>
                <h6>New Transfer Request - Confirm your details</h6>
                <strong>You Sell: </strong>
                <br />
                {this.props.intl.formatNumber(amount_from)}{' '}
                {currency_from.iso_alpha_3} ({currency_from.full_name})<br />
                <br />
                <strong>You Buy: </strong>
                <br />
                {this.props.intl.formatNumber(amount_to)}{' '}
                {currency_to.iso_alpha_3} ({currency_to.full_name})<br />
                <br />
                <strong>Exchange Rate: </strong>
                <br />
                {current_rate}
                <br />
                {this.props.transfers.new_transfer_data.current_rate_locked && (
                    <React.Fragment>
                        Rate is locked. Select alternative country or currency
                        to get a new rate.
                    </React.Fragment>
                )}
                {!this.props.transfers.new_transfer_data
                    .current_rate_locked && (
                    <React.Fragment>
                        Rate refreshes in{' '}
                        {
                            this.props.transfers.new_transfer_data
                                .current_rate_ttl
                        }{' '}
                        seconds.
                    </React.Fragment>
                )}
                <br />
                <br />
                <em>Press the "Next" button below to lock in this rate now.</em>
            </React.Fragment>
        )
    }

    render() {
        const { classes } = this.props
        // For CustomReactSelect. Generate select options for dropdown list.
        const country_list_select_options = this.state.country_list_prio.map(
            item => ({
                value: item.id,
                label: item.full_name,
            })
        )
        const currency_list_select_options = this.state.currencies_list_priority.map(
            item => ({
                value: item.id,
                label: item.iso_alpha_3 + ': ' + item.full_name,
            })
        )
        if (!this.props.app_state.current_client) {
            return null
        }

        return (
            <React.Fragment>
                <GridContainer justify="center">
                    <GridItem xs={12} md={10}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12}>
                                <h4 className={classes.infoText}>
                                    Where will payment come from
                                </h4>
                                <p>
                                    Enter details of how payment is being made
                                    by the client. Which country they are paying
                                    from and in what currency.
                                </p>
                            </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12} lg={6}>
                                <Card style={{ height: '90%' }}>
                                    <CardBody>
                                        <CustomReactSelect
                                            label="Country payment coming from"
                                            options={
                                                country_list_select_options
                                            }
                                            value={this.state.payee_country_id}
                                            onChange={this.handleCustomReactSelectChange(
                                                'payee_country_id'
                                            )}
                                            isClearable={false}
                                            // isDisabled={!this.state.edit_mode}
                                        />

                                        <CustomReactSelect
                                            label="Currency being paid by client"
                                            options={
                                                currency_list_select_options
                                            }
                                            value={this.state.currency_from_id}
                                            onChange={this.handleCustomReactSelectChange(
                                                'currency_from_id'
                                            )}
                                            isClearable={false}
                                            // isDisabled={!this.state.edit_mode}
                                        />

                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={
                                                        this.state
                                                            .payment_method_id ===
                                                        '1'
                                                    }
                                                    onChange={
                                                        this.handleChangeEnabled
                                                    }
                                                    value="1"
                                                    name="radio button enabled"
                                                    aria-label="1"
                                                    icon={
                                                        <FiberManualRecord
                                                            className={
                                                                classes.radioUnchecked
                                                            }
                                                        />
                                                    }
                                                    checkedIcon={
                                                        <FiberManualRecord
                                                            className={
                                                                classes.radioChecked
                                                            }
                                                        />
                                                    }
                                                    classes={{
                                                        checked: classes.radio,
                                                    }}
                                                />
                                            }
                                            classes={{
                                                label: classes.label,
                                            }}
                                            label="Direct Deposit"
                                        />
                                        <br />

                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={
                                                        this.state
                                                            .payment_method_id ===
                                                        '2'
                                                    }
                                                    onChange={
                                                        this.handleChangeEnabled
                                                    }
                                                    value="2"
                                                    name="radio button enabled"
                                                    aria-label="2"
                                                    icon={
                                                        <FiberManualRecord
                                                            className={
                                                                classes.radioUnchecked
                                                            }
                                                        />
                                                    }
                                                    checkedIcon={
                                                        <FiberManualRecord
                                                            className={
                                                                classes.radioChecked
                                                            }
                                                        />
                                                    }
                                                    classes={{
                                                        checked: classes.radio,
                                                    }}
                                                />
                                            }
                                            classes={{
                                                label: classes.label,
                                            }}
                                            label="Credit Card"
                                        />
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={6}>
                                <Card style={{ height: '90%' }}>
                                    <CardBody>
                                        {this.getSideCardContent()}
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>

                        {/*<GridContainer justify="center">*/}
                        {/*  <GridItem xs={12} sm={12} md={12} lg={6}>*/}
                        {/*    <FormControl*/}
                        {/*      fullWidth*/}
                        {/*      error={!this.state.payee_id > 0}*/}
                        {/*      className={classes.selectFormControl}*/}
                        {/*    >*/}
                        {/*      <InputLabel*/}
                        {/*        htmlFor="simple-select"*/}
                        {/*        className={classes.selectLabel}*/}
                        {/*      >*/}
                        {/*        Select payee*/}
                        {/*      </InputLabel>*/}
                        {/*      <Select*/}
                        {/*        MenuProps={{*/}
                        {/*          className: classes.selectMenu*/}
                        {/*        }}*/}
                        {/*        classes={{*/}
                        {/*          select: classes.select*/}
                        {/*        }}*/}
                        {/*        // value={this.state.payee_id || ""}*/}
                        {/*        value={this.props.app_state.current_client.id || ""}*/}
                        {/*        onChange={this.handleSelectChange}*/}
                        {/*        inputProps={{*/}
                        {/*          name: "payee_id",*/}
                        {/*          id: "payee_id"*/}
                        {/*        }}*/}
                        {/*      >*/}
                        {/*        <MenuItem*/}
                        {/*          key="0x0"*/}
                        {/*          disabled*/}
                        {/*          classes={{*/}
                        {/*            root: classes.selectMenuItem*/}
                        {/*          }}*/}
                        {/*        >*/}
                        {/*          Select client*/}
                        {/*        </MenuItem>*/}
                        {/*        {this.state.client_list*/}
                        {/*          .sort((a, b) => {*/}
                        {/*            if (a.nickname) {*/}
                        {/*              return a.nickname.localeCompare(b.nickname)*/}
                        {/*            } else {*/}
                        {/*              return a.last_name.localeCompare(b.last_name)*/}
                        {/*            }*/}
                        {/*          }*/}
                        {/*          )*/}
                        {/*          .map((item) => {*/}
                        {/*            return (*/}
                        {/*              <MenuItem*/}
                        {/*                key={item.id}*/}
                        {/*                classes={{*/}
                        {/*                  root: classes.selectMenuItem,*/}
                        {/*                  selected: classes.selectMenuItemSelected*/}
                        {/*                }}*/}
                        {/*                value={item.id}*/}
                        {/*              >*/}
                        {/*                {item.nickname}*/}
                        {/*              </MenuItem>*/}
                        {/*            );*/}
                        {/*          })}*/}
                        {/*      </Select>*/}
                        {/*    </FormControl>*/}
                        {/*  </GridItem>*/}
                        {/*</GridContainer>*/}

                        {/*<GridContainer justify="center">*/}
                        {/*  <GridItem xs={12} sm={12} md={12} lg={6}>*/}
                        {/*    <CustomInput*/}
                        {/*      labelText="First Name"*/}
                        {/*      id="first_name"*/}
                        {/*      formControlProps={{*/}
                        {/*        fullWidth: true*/}
                        {/*      }}*/}
                        {/*      inputProps={{*/}
                        {/*        disabled: true,*/}
                        {/*        value: this.state.payee.first_name || ""*/}
                        {/*      }}*/}
                        {/*    />*/}
                        {/*    <CustomInput*/}
                        {/*      labelText="Middle Name(s)"*/}
                        {/*      id="niddle_name"*/}
                        {/*      formControlProps={{*/}
                        {/*        fullWidth: true*/}
                        {/*      }}*/}
                        {/*      inputProps={{*/}
                        {/*        disabled: true,*/}
                        {/*        value: this.state.payee.niddle_name || ""*/}
                        {/*      }}*/}
                        {/*    />*/}
                        {/*    <CustomInput*/}
                        {/*      labelText="Last Name"*/}
                        {/*      id="last_name"*/}
                        {/*      formControlProps={{*/}
                        {/*        fullWidth: true*/}
                        {/*      }}*/}
                        {/*      inputProps={{*/}
                        {/*        disabled: true,*/}
                        {/*        value: this.state.payee.last_name || ""*/}
                        {/*      }}*/}
                        {/*    />*/}
                        {/*    <CustomInput*/}
                        {/*      labelText="Email"*/}
                        {/*      id="email"*/}
                        {/*      formControlProps={{*/}
                        {/*        fullWidth: true*/}
                        {/*      }}*/}
                        {/*      inputProps={{*/}
                        {/*        disabled: true,*/}
                        {/*        value: this.state.payee.email || ""*/}
                        {/*      }}*/}
                        {/*    />*/}
                        {/*  </GridItem>*/}

                        {/*  <GridItem xs={12} sm={12} md={12} lg={6}>*/}
                        {/*    <CustomInput*/}
                        {/*      labelText="Team"*/}
                        {/*      id="team_nickname"*/}
                        {/*      formControlProps={{*/}
                        {/*        fullWidth: true*/}
                        {/*      }}*/}
                        {/*      inputProps={{*/}
                        {/*        disabled: true,*/}
                        {/*        value: this.state.payee.team_nickname || ""*/}
                        {/*      }}*/}
                        {/*    />*/}
                        {/*    <CustomInput*/}
                        {/*      labelText="Division"*/}
                        {/*      id="division_nickname"*/}
                        {/*      formControlProps={{*/}
                        {/*        fullWidth: true*/}
                        {/*      }}*/}
                        {/*      inputProps={{*/}
                        {/*        disabled: true,*/}
                        {/*        value: this.state.payee.division_nickname || ""*/}
                        {/*      }}*/}
                        {/*    />*/}
                        {/*    <CustomInput*/}
                        {/*      labelText="Entity"*/}
                        {/*      id="entity_nickname"*/}
                        {/*      formControlProps={{*/}
                        {/*        fullWidth: true*/}
                        {/*      }}*/}
                        {/*      inputProps={{*/}
                        {/*        disabled: true,*/}
                        {/*        value: this.state.payee.entity_nickname || ""*/}
                        {/*      }}*/}
                        {/*    />*/}
                        {/*  </GridItem>*/}
                        {/*</GridContainer>*/}

                        <br />
                        <br />

                        {/*<GridContainer justify="center">*/}
                        {/*  <GridItem xs={12} sm={12}>*/}
                        {/*    <h4 className={classes.infoText}>*/}
                        {/*      Different payee &nbsp; &nbsp;&nbsp;*/}
                        {/*      <span>*/}
                        {/*      <FormControlLabel*/}
                        {/*        control={*/}
                        {/*          <Switch*/}
                        {/*            checked={this.state.edit_mode}*/}
                        {/*            onChange={() => this.toggleDifferentPayee()}*/}
                        {/*            value="edit_mode"*/}
                        {/*            classes={{*/}
                        {/*              switchBase: classes.switchBase,*/}
                        {/*              checked: classes.switchChecked,*/}
                        {/*              icon: classes.switchIcon,*/}
                        {/*              iconChecked: classes.switchIconChecked,*/}
                        {/*              bar: classes.switchBar*/}
                        {/*            }}*/}
                        {/*          />*/}
                        {/*        }*/}
                        {/*        classes={{*/}
                        {/*          label: classes.label*/}
                        {/*        }}*/}
                        {/*        label={this.state.edit_mode ? "Different payee" : ""}*/}
                        {/*      />*/}
                        {/*    </span>*/}
                        {/*    </h4>*/}
                        {/*  </GridItem>*/}
                        {/*</GridContainer>*/}

                        {this.state.different_payee && (
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={10}>
                                    <CustomInput
                                        success={
                                            this.state
                                                .payee_first_name_state ===
                                            'success'
                                        }
                                        error={
                                            this.state
                                                .payee_first_name_state ===
                                            'error'
                                        }
                                        labelText="First Name"
                                        id="payee_first_name"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value:
                                                this.state.payee_first_name ||
                                                '',
                                            onChange: event =>
                                                this.change(
                                                    event,
                                                    'payee_first_name',
                                                    'length',
                                                    3
                                                ),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={10}>
                                    <CustomInput
                                        success={
                                            this.state
                                                .payee_middle_name_state ===
                                            'success'
                                        }
                                        error={
                                            this.state
                                                .payee_middle_name_state ===
                                            'error'
                                        }
                                        labelText="Middle Name(s)"
                                        id="payee_middle_name"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value:
                                                this.state.payee_middle_name ||
                                                '',
                                            onChange: event =>
                                                this.change(
                                                    event,
                                                    'payee_middle_name',
                                                    'length',
                                                    3
                                                ),
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={10}>
                                    <CustomInput
                                        success={
                                            this.state.payee_last_name_state ===
                                            'success'
                                        }
                                        error={
                                            this.state.payee_last_name_state ===
                                            'error'
                                        }
                                        labelText="Last Name"
                                        id="payee_last_name"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value:
                                                this.state.payee_last_name ||
                                                '',
                                            onChange: event =>
                                                this.change(
                                                    event,
                                                    'payee_last_name',
                                                    'length',
                                                    3
                                                ),
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={10}>
                                    <CustomInput
                                        success={
                                            this.state.payee_email_state ===
                                            'success'
                                        }
                                        error={
                                            this.state.payee_email_state ===
                                            'error'
                                        }
                                        labelText="Email"
                                        id="payee_email"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: this.state.payee_email || '',
                                            onChange: event =>
                                                this.change(
                                                    event,
                                                    'payee_email',
                                                    'length',
                                                    3
                                                ),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={10}>
                                    <CustomInput
                                        success={
                                            this.state
                                                .payee_address_line_1_state ===
                                            'success'
                                        }
                                        error={
                                            this.state
                                                .payee_address_line_1_state ===
                                            'error'
                                        }
                                        labelText={
                                            <span>
                                                Street Line 1
                                                <small>(required)</small>
                                            </span>
                                        }
                                        id="payee_address_line_1"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: this.state
                                                .payee_address_line_1,
                                            onChange: event =>
                                                this.change(
                                                    event,
                                                    'payee_address_line_1',
                                                    'length',
                                                    3
                                                ),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={10}>
                                    <CustomInput
                                        success={
                                            this.state
                                                .payee_address_line_2_state ===
                                            'success'
                                        }
                                        error={
                                            this.state
                                                .payee_address_line_2_state ===
                                            'error'
                                        }
                                        labelText={<span>Street Line 2</span>}
                                        id="payee_address_line_2"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: this.state
                                                .payee_address_line_2,
                                            onChange: event =>
                                                this.change(
                                                    event,
                                                    'payee_address_line_2',
                                                    'length',
                                                    3
                                                ),
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={10}>
                                    <GridContainer justify="flex-start">
                                        <GridItem xs={12} sm={6}>
                                            <CustomInput
                                                success={
                                                    this.state
                                                        .payee_suburb_state ===
                                                    'success'
                                                }
                                                error={
                                                    this.state
                                                        .payee_suburb_state ===
                                                    'error'
                                                }
                                                labelText={
                                                    <span>
                                                        Suburb
                                                        <small>
                                                            (required)
                                                        </small>
                                                    </span>
                                                }
                                                id="payee_suburb"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state
                                                        .payee_suburb,
                                                    onChange: event =>
                                                        this.change(
                                                            event,
                                                            'payee_suburb',
                                                            'length',
                                                            3
                                                        ),
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={6} sm={3}>
                                            <CustomInput
                                                success={
                                                    this.state
                                                        .payee_state_state ===
                                                    'success'
                                                }
                                                error={
                                                    this.state
                                                        .payee_state_state ===
                                                    'error'
                                                }
                                                labelText={
                                                    <span>
                                                        State
                                                        <small>
                                                            (required)
                                                        </small>
                                                    </span>
                                                }
                                                id="payee_state"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    disabled: !this.state
                                                        .edit_mode,
                                                    value: this.state
                                                        .payee_state,
                                                    onChange: event =>
                                                        this.change(
                                                            event,
                                                            'payee_state',
                                                            'length',
                                                            3
                                                        ),
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={6} sm={3}>
                                            <CustomInput
                                                success={
                                                    this.state
                                                        .payee_postcode_state ===
                                                    'success'
                                                }
                                                error={
                                                    this.state
                                                        .payee_postcode_state ===
                                                    'error'
                                                }
                                                labelText={
                                                    <span>
                                                        PostCode
                                                        <small>
                                                            (required)
                                                        </small>
                                                    </span>
                                                }
                                                id="payee_postcode"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state
                                                        .payee_postcode,
                                                    onChange: event =>
                                                        this.change(
                                                            event,
                                                            'payee_postcode',
                                                            'length',
                                                            3
                                                        ),
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={10}>
                                    {/* <FormControl
              fullWidth
              // error={!this.state.residential_street_country > 0}
              className={classes.selectFormControl}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Select Country
              </InputLabel>


              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.payee_country || "0"}
                onChange={this.handleSelectChange}
                inputProps={{
                  name: "payee_country",
                  id: "payee_country"
                }}
              >
                <MenuItem
                  key="0x0"
                  value="0"
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Select Country
                </MenuItem>

                {this.state.country_list_prio
                  .sort((a, b) => a.list_priority < b.list_priority)
                  .map(item => {
                    return (
                      <MenuItem
                        key={item.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={item.id}
                      >
                        {item.full_name}
                      </MenuItem>
                    );
                  })} */}
                                    {/*<MenuItem*/}
                                    {/*  key="0x0"*/}
                                    {/*  value="0"*/}
                                    {/*  disabled*/}
                                    {/*  classes={{*/}
                                    {/*    root: classes.selectMenuItem*/}
                                    {/*  }}*/}
                                    {/*>*/}
                                    {/*  --------------------------------------------------*/}
                                    {/*</MenuItem>*/}
                                    {/*{this.state.country_list*/}
                                    {/*  .sort((a, b) => a.full_name.localeCompare(b.full_name))*/}
                                    {/*  .map(item => {*/}
                                    {/*    return (*/}
                                    {/*      <MenuItem*/}
                                    {/*        key={item.id}*/}
                                    {/*        classes={{*/}
                                    {/*          root: classes.selectMenuItem,*/}
                                    {/*          selected: classes.selectMenuItemSelected*/}
                                    {/*        }}*/}
                                    {/*        value={item.id}*/}
                                    {/*      >*/}
                                    {/*        {item.full_name}*/}
                                    {/*      </MenuItem>*/}
                                    {/*    );*/}
                                    {/*  })}*/}

                                    {/* </Select>
            </FormControl> */}
                                    <CustomReactSelect
                                        label="Country"
                                        options={country_list_select_options}
                                        value={this.state.payee_country}
                                        onChange={this.handleCustomReactSelectChange(
                                            'payee_country'
                                        )}
                                        isClearable={true}
                                        // isDisabled={!this.state.edit_mode}
                                    />
                                </GridItem>
                            </GridContainer>
                        )}
                    </GridItem>
                </GridContainer>
            </React.Fragment>
        )
    }
}

export default injectIntl(withStyles(style)(Step3PayingFrom))
