//import { API } from "aws-amplify";

export const LANGUAGE_FETCH_LANGUAGE_LIST_REQUEST =
    'LANGUAGE_FETCH_LANGUAGE_LIST_REQUEST'
export const LANGUAGE_FETCH_LANGUAGE_LIST_SUCCESS =
    'LANGUAGE_FETCH_LANGUAGE_LIST_SUCCESS'
export const LANGUAGE_FETCH_LANGUAGE_LIST_FAILURE =
    'LANGUAGE_FETCH_LANGUAGE_LIST_FAILURE'
export const LANGUAGE_SELECT_UI = 'LANGUAGE_SELECT_UI'
export const LANGUAGE_SELECT_SET_ID = 'LANGUAGE_SELECT_SET_ID '

export function fetchLanguageListRequest() {
    return {
        type: LANGUAGE_FETCH_LANGUAGE_LIST_REQUEST,
    }
}

export function fetchLanguageListSuccess(data) {
    return {
        type: LANGUAGE_FETCH_LANGUAGE_LIST_SUCCESS,
        langauge_list: data,
        receivedAt: Date.now(),
    }
}

export function fetchLanguageList() {
    return function(dispatch) {
        dispatch(fetchLanguageListRequest())
    }
}

export function selectLanguageUi(value) {
    return {
        type: LANGUAGE_SELECT_UI,
        value: value,
    }
}

export function setLanguageSelectId(id) {
    return {
        type: LANGUAGE_SELECT_SET_ID,
        value: id,
    }
}

export function loadLanguageDetailsRequest() {
    return {
        type: LANGUAGE_FETCH_LANGUAGE_LIST_REQUEST,
    }
}
export function loadLanguageDetailsSuccess() {
    return {
        type: LANGUAGE_FETCH_LANGUAGE_LIST_SUCCESS,
    }
}
