import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

// import {matchSorter} from 'match-sorter'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'

// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react'
import Button from '../../CustomButtons/Button'

import { API } from 'aws-amplify'

// react component for creating dynamic tables
import ReactTable from 'react-table'
// import { useExpanded } from "react-table";

// import Badge from "../../Badge/Badge";
// import {matchSorter} from "match-sorter";
import { FormattedNumber } from 'react-intl'

// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from '../../CustomInput/CustomInput'
// import Datetime from "react-datetime";
import CustomReactSelect from '../../Forms/CustomReactSelect/CustomReactSelect'

//component to send email #798 // copied from CRM by Tom
import TransferDetailPayoutCommunication from './TransferDetailPayoutCommunication'
// import HorizontalStepper from "../../Timeline/HorizontalStepper";

import { injectIntl } from 'react-intl'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const forexWorldWideLogo = require('assets/img/pdf_img/forexworldwide_logo_transparent_280x50.42dc12df.png')
const forexSportLogo = require('assets/img/pdf_img/fxsport_signature_gmail.png')
const goStudyLogo = require('assets/img/pdf_img/GOSTUDY_HorizontalUsage_Black_250x50.png')

const moment = require('moment')

const style = {
    root: {},
    pageHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 22,
    },
    pageSubHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 18,
    },
    tableHeaders: {
        width: 150,
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 8,
    },
    tableCells: {
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
    },
    backgroundBlue: {
        backgroundColor: '#348feb20',
    },
}


const TRANSFER_STATUS_COMPLETE_VALUE = {
    1: {value: 10, text: "Draft"},
    2: {value: 20, text: "In Process"},
    3: {value: 30, text: "Acknowledged"},
    4: {value: 40, text: "Processing"},
    5: {value: 50, text: "Awaiting Funds"},
    6: {value: 60, text: "Funds Received"},
    7: {value: 100, text: "Payment Made"},
    8: {value: 100, text: "Completed"},
    9: {value: 0, text: "On Hold"},
    10: {value: 0, text: "Not Proceeding"}
  }
  


class TransferDetailSummary_Payout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // register form
            loaded: false,
            is_loading: true,
            beneficiary_list: [],
            user_beneficiaries_list: [],
            currencies_list_priority: [],
            payouts_list: [],
            country_list: [],
            currencies_list: [],
            select_done: false,
            payout_number: 0,
        }
    }

    componentDidMount() {
        // console.log(this.props);
        this.updateTransferData(this.props.transfer_id)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.transfer_id !== this.props.transfer_id) {
            this.updateTransferData(this.props.transfer_id)
        }
    }

    updateTransferData(option_value) {
        this.setState({ transfer_id: option_value })

        API.get('transfers', `/get-full/id/${option_value}`)
            .then(response => {
                console.log(response);
                this.setState(
                    {
                        transfer: response.fullList,
                        payouts_list: response.payouts,
                        beneficiary_id: response.payouts[0].beneficiary_id,
                    },
                    () => {
                        if (this.state.payout_number) {
                            this.setState({
                                beneficiary_id:
                                    response.payouts[this.state.payout_number]
                                        .beneficiary_id,
                            })
                        }
                    }
                )

                API.get(
                    'currencies',
                    `/currencies/get/${response.fullList.currency_from_id}`
                )
                    .then(response => {
                        // console.log(response);
                        this.setState({
                            currency_from: response,
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })

                API.get(
                    'currencies',
                    `/currencies/get/${response.fullList.currency_to_id}`
                )
                    .then(response => {
                        // console.log(response);
                        this.setState({
                            currency_to: response,
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error)
            })

        API.get('currencies', `/currencies/get-list-priority`)
            .then(response => {
                // console.log(response);
                this.setState({
                    currencies_list_priority: response,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    getSignatureLine = brand => {
        // console.log(brand)
        switch (brand) {
            case 1:
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img
                                src={forexWorldWideLogo}
                                alt="ForexWorldWide Logo"
                            />
                            <br />
                            <strong>Forex WorldWide</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexworldwide.com
                            <br />T +61 (03) 9008 1880
                            <br />W www.forexworldwide.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            case 2:
            case 3:
                return null
                break
            case 4:
                return (
                    <React.Fragment>
                        <p>
                            <strong>Forex Student</strong>
                            <br />
                            Forex Student is a trading name of Forex Sport Pty
                            Ltd AFSL 401379.
                            <br />
                            Forex Student is a provider of money transfer
                            services for Go Study Australia Pty Ltd.
                            <br />
                            <br />
                            <table width="40%">
                                <tr>
                                    <td>
                                        <img
                                            src={forexWorldWideLogo}
                                            alt="ForexWorldWide Logo"
                                        />
                                    </td>
                                    <td>
                                        <img
                                            src={goStudyLogo}
                                            alt="ForexWorldWide Logo"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        ABN 22 147 363 175
                                        <br />
                                        AFSL 401379
                                        <br />
                                        Level 4, 100 Collins Street
                                        <br />
                                        Melbourne Vic 3000
                                        <br />
                                        <br />E admin@forexworldwide.com
                                        <br />T +61 (03) 9008 1880
                                        <br />W www.forexworldwide.com
                                        <br />
                                    </td>
                                    <td>
                                        ABN 000 000 000 <br />
                                        <br />
                                        000 <br />
                                        000
                                        <br />
                                        <br />E abc@abc.com
                                        <br />T +61 (03) 0000 0000
                                        <br />F +61 (03) 0000 0000
                                        <br />W www.gostudy.com.au
                                        <br />
                                    </td>
                                </tr>
                            </table>
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            case 24:
            case 29:
            case 48:
            case 49:
            case 52:
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img src={forexSportLogo} alt="ForexSport Logo" />
                            <br />
                            <br />
                            <strong>Forex Sport</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexsport.com
                            <br />W www.forexsport.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            default:
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img
                                src={forexWorldWideLogo}
                                alt="ForexWorldWide Logo"
                            />
                            <br />
                            <strong>Forex WorldWide</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexworldwide.com
                            <br />T +61 (03) 9008 1880
                            <br />W www.forexworldwide.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
        }
    }

    render() {
        const { classes } = this.props
        // if (!this.state.loaded || !this.state.currency_to || !this.state.beneficiary || !this.state.transfer)
        // return null;
        if (!this.props.app_state.current_client) {
            return null
        }
        if (this.props.app_state.current_client.account_status == 1) {
            this.props.history.push('/pending-registration')
        }
        if (this.props.app_state.current_client.account_status == 5) {
            this.props.history.push('/registration')
        }

        if (this.props.app_state.current_client.account_status == 6) {
            this.props.history.push('/migrate')
        }

        if (!this.state.payouts_list) {
            return null
        }
        // assign corresponding payout in payout_list to payout
        let payout = ''
        if (
            this.state.payout_number < this.state.payouts_list.length &&
            this.state.payout_number >= 0
        )
            payout = this.state.payouts_list[this.state.payout_number]

        if (!payout)
            return (
                <React.Fragment>
                    <strong>Error: Unable to access receipt</strong>
                </React.Fragment>
            )
        if (!this.state.currency_from) return null
        if (!this.state.currency_to) return null

        // Branding for top of sidebar
        // ************************************************************************************************
        let logo = require(`../../../assets/img/receipts/${
            this.props.app_state.current_domain.domain_receipt_brand_image
        }`)
        // ************************************************************************************************

        const payoutNickname = payout.holdingAccountPayee ? 
            this.state.transfer.client_nickname + " Currency Balance" :
            payout.nickname

            const addressText1 = `${payout.ben_address_line_1 || ''}
            ${payout.ben_address_line_2 || ''}`
            
            const addressText2 = `${payout.ben_address_suburb || ''}
            ${payout.ben_address_state || ''}
            ${payout.ben_address_postcode || ''}
            ${payout.beneficiary_country_name || ''}`

        // console.log(payout)
        // console.log(this.state);
        // console.log(this.props);
        return (
            <React.Fragment>
                <div>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Card>
                                <CardHeader>
                                <h3 className={classes.pageHeadings}>
                                    Payout Details
                                </h3>
                                </CardHeader>
                                <CardBody>

                                <GridContainer>
                                    <GridItem xs={12}>
                                        <GridContainer>
                                        <GridItem xs={12}>
                                            <CustomInput
                                            labelText={<span>Name </span>}
                                            id='account_number'
                                            formControlProps={{fullWidth: true}}
                                            inputProps={{
                                                value: payoutNickname,
                                                disabled: true
                                            }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <CustomInput
                                            labelText={<span>Address </span>}
                                            id='addressText1'
                                            formControlProps={{fullWidth: true}}
                                            inputProps={{
                                                value: addressText1,
                                                disabled: true
                                            }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <CustomInput
                                            labelText={<span> </span>}
                                            id='addressText2'
                                            formControlProps={{fullWidth: true}}
                                            inputProps={{
                                                value: addressText2,
                                                disabled: true
                                            }}
                                            />
                                        </GridItem>
                                        </GridContainer>
                    <GridContainer>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Bank </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              `${payout.bank_legal_name}`,
                            disabled: true
                          }}
                        />
                      </GridItem>

                      {payout.swift_code && 
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Swift </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              `${payout.swift_code}`,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      }
                      {payout.aba_routing_number && 
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>ABA Routing Number </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              `${payout.aba_routing_number}`,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      }
                      {payout.iban && 
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>IBAN </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              `${payout.iban}`,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      }
                      {payout.account_number && 
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Account Number </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              `${payout.account_number}`,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      }
                      {payout.detail_1 && 
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Detail 1 </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              `${payout.detail_1}`,
                            disabled: true
                          }}
                        />
                      </GridItem>
                        }
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12}>
                        <React.Fragment>
                          <label>
                            <span style={{fontSize: '11px'}}>Amount</span>
                          </label>{' '}
                          <br />
                          <div
                            className={`currency-flag currency-flag-${
                              this.state.transfer && this.state.transfer.currency_to_iso_alpha_3
                                ? this.state.transfer.currency_to_iso_alpha_3.toLowerCase()
                                : ''
                            }`}
                          />{` `}
                          {this.state.transfer && this.state.transfer.currency_to_iso_alpha_3}{`  `}
                          <FormattedNumber
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            value={payout.amount_to}
                          />
                        </React.Fragment>
                      </GridItem>
                    </GridContainer>

                                        </GridItem>
                                        </GridContainer>

                                   
                                        {this.props.showFooter && 
                                        <>
                                        {/* <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                <p>
                                                    Funds will be credited to your nominated account after receiving.
                                                </p>
                                            </GridItem>
                                        </GridContainer> */}
                                        <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                {' '}
                                                <div>
                                                    {' '}
                                                    {/* {this.getSignatureLine(this.props.app_state.current_client.team_id)}{' '} */}
                                                    {this.getSignatureLine(this.state.transfer.client_team_id)}{' '}
                                                </div>{' '}
                                            </GridItem>
                                        </GridContainer>
                                        </>
                                        }
                                </CardBody>
                                <CardFooter />
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </React.Fragment>
        )
    }
    // let remittance_reference = "";
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}
const TransferDetailSummary_PayoutContainer = connect(
    mapStateToProps
    // mapDispatchToProps
)(injectIntl(withRouter(withStyles(style)(TransferDetailSummary_Payout))))

export default TransferDetailSummary_PayoutContainer

// export default injectIntl(withRouter(withStyles(styles)(TransferDetailPayoutSingle)))
