import { API } from 'aws-amplify'

export const NEWS_FETCH_NEWS_LIST_REQUEST = 'NEWS_FETCH_NEWS_LIST_REQUEST'
export const NEWS_FETCH_NEWS_LIST_SUCCESS = 'NEWS_FETCH_NEWS_LIST_SUCCESS'
export const NEWS_FETCH_NEWS_LIST_FAILURE = 'NEWS_FETCH_NEWS_LIST_FAILURE'
export const NEWS_SELECT_UI = 'NEWS_SELECT_UI'
export const NEWS_SELECT_SET_ID = 'NEWS_SELECT_SET_ID '

export function fetchNewsListRequest() {
    return {
        type: NEWS_FETCH_NEWS_LIST_REQUEST,
    }
}

export function fetchNewsListSuccess(data) {
    return {
        type: NEWS_FETCH_NEWS_LIST_SUCCESS,
        news_list: data,
        receivedAt: Date.now(),
    }
}

export function fetchNewsList() {
    return function(dispatch) {
        dispatch(fetchNewsListRequest())
        API.get('news', `/news/get-all`)
            .then(response => {
                dispatch(fetchNewsListSuccess(response))
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchNewsDetails(id) {
    return function(dispatch) {
        dispatch(loadNewsDetailsRequest())
        API.get('news', `/news/get/${id}`)
            .then(response => {
                dispatch(loadNewsDetailsSuccess(response))
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function selectNewsUi(value) {
    return {
        type: NEWS_SELECT_UI,
        value: value,
    }
}

export function setNewsSelectId(id) {
    return {
        type: NEWS_SELECT_SET_ID,
        value: id,
    }
}

export function loadNewsDetailsRequest() {
    return {
        type: NEWS_FETCH_NEWS_LIST_REQUEST,
    }
}
export function loadNewsDetailsSuccess() {
    return {
        type: NEWS_FETCH_NEWS_LIST_SUCCESS,
    }
}
