import { connect } from 'react-redux'
import Step3PayingFrom from './Step3PayingFrom'
import { updateNewTransferCreation } from '../../../../redux/actions/transfers'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers: state.transfers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewTransferCreation: (key, value) => {
            dispatch(updateNewTransferCreation(key, value))
        },
    }
}

const Step3PayingFromContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Step3PayingFrom)

export default Step3PayingFromContainer
