import React from 'react'
import { API } from 'aws-amplify'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
// import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
// import FormControl from "@material-ui/core/FormControl";
import { cardTitle } from '../../../../assets/jss/material-dashboard-pro-react'
import CustomReactSelect from '../../../Forms/CustomReactSelect/CustomReactSelect'
import { isEmpty } from 'libs/miscFunc.js'
import DocumentUpload from 'components/DocumentUpload/DocumentUpload.jsx'
import document_image from 'assets/img/document_image.jpg'
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import Check from "@material-ui/core/SvgIcon/SvgIcon";
// import document_image from "*.jpg";
import Button from 'components/CustomButtons/Button.jsx'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Slide from '@material-ui/core/Slide'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import Card from 'components/Card/Card.jsx'
import CardHeader from '../../../../components/Card/CardHeader'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'

const axios = require('axios')
var moment = require('moment')

const style = {
    ...customCheckboxRadioSwitch,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    infoText: {
        fontWeight: '300',
        margin: '10px 0 30px',
        textAlign: 'center',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    inputAdornment: {
        position: 'relative',
    },
}

// const min_transfer_amount = 100;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />
})

class Step2PayingTo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            beneficiaries_list: [],
            beneficiary_id: '',
            beneficiary: {},
            purpose_of_payment_detail_state: '',
            amount_to_state: '',
            purpose_list: [],

            clientTransfers: [
                {
                    beneficiary_id: '',
                    amount: '',
                    purpose_of_payment_detail: '',
                    beneficiary_id_state: '',
                    amount_state: '',
                    purpose_of_payment_detail_state: '',
                    detail_1: '',
                    detail_2: '',
                    detail_3: '',
                    detail_1_state: '',
                    detail_2_state: '',
                    detail_3_state: '',
                    document: '',
                    purpose_of_payment_other: '',
                    purpose_of_payment_other_state: '',
                    beneficiary: {},
                    showDocumentUpload: false,
                },
            ],

            FIXME: false,

            country_list: [],
            currencies_list: [],

            addBeneficiaryModalOpen: false,
            ben_legal_name: '',
            ben_email_main: '',
            ben_address_line_1: '',
            ben_address_line_2: '',
            ben_address_suburb: '',
            ben_address_state: '',
            ben_address_postcode: '',
            ben_address_country: '',

            bank_legal_name: '',
            account_currency: '',
            bsb_code: '',
            swift_code: '',
            account_number: '',
            aba_routing_number: '',
            sort_code: '',
            currencies_list_priority: [],

            show_iban: false,
            show_bsb: false,
            show_sort_code: false,
            show_swift_code: false,
            show_aba: false,
            show_account_number: false,

            fieldForGostudy: false,
        }
    }

    componentDidMount() {
        API.get('beneficiaries', `/beneficiaries/get`)
            .then(response => {
                this.setState(
                    {
                        beneficiaries_list: response,
                    },
                    () => {
                        this.queryStrings()
                    }
                )
            })
            .catch(error => {
                console.log(error)
            })

        API.get('countries', `/countries/list_not_deleted`)
            .then(response => {
                this.setState({
                    country_list: response,
                })
            })
            .catch(error => {
                console.log(error)
            })

        API.get('currencies', `/currencies/get-all`)
            .then(response => {
                this.setState({
                    currencies_list: response,
                })
            })
            .catch(error => {
                console.log(error)
            })

        if (this.props.app_state.current_client) {
            if (this.props.app_state.current_client.division_id == '7') {
                this.setState({ fieldForGostudy: true })
            }

            API.get(
                'transfers',
                `/get_purpose/id/${
                    this.props.app_state.current_client.division_id
                }`
            )
                .then(response => {
                    this.setState({
                        purpose_list: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }

        API.get('currencies', `/currencies/get-list-priority`).then(
            response => {
                this.setState(
                    {
                        currencies_list_priority: response,
                    },
                    () => {}
                )
            }
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.app_state.current_client !==
            this.props.app_state.current_client
        ) {
            if (this.props.app_state.current_client.division_id == '7') {
                this.setState({ fieldForGostudy: true })
            }
            API.get(
                'transfers',
                `/get_purpose/id/${
                    this.props.app_state.current_client.division_id
                }`
            )
                .then(response => {
                    this.setState({
                        purpose_list: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    queryStrings() {
        const query_strings = queryString.parse(this.props.location.search)

        if (Object.keys(query_strings).length !== 0) {
            if (query_strings.beneficiary_id) {
                this.getBeneficiaryDetails(query_strings.beneficiary_id)

                let clientTransfers = [...this.state.clientTransfers]
                clientTransfers[0] = {
                    ...clientTransfers[0],
                    beneficiary_id: Number(query_strings.beneficiary_id),
                }
                this.setState({ clientTransfers })
                this.props.updateNewTransferCreation(
                    'multiple_beneficiary_id',
                    clientTransfers
                )
            }
        }
    }

    getBeneficiaryDetails(beneficiary_id) {
        API.get('beneficiaries', `/beneficiaries/getb/${beneficiary_id}`)
            .then(response => {
                this.setState({
                    beneficiary: response[0],
                })
                this.props.updateNewTransferCreation('beneficiary', response[0])

                this.setState({
                    beneficiary: Object.assign({}, this.state.beneficiary, {
                        account_currency_nickname: `${response[0].full_name} [${
                            response[0].iso_alpha_3
                        }]`,
                    }),
                })
                // this.props.updateNewTransferCreation("currency_to_id", response[0].account_currency)
                // this.props.updateNewTransferCreation("currency_to", response)
                return response
            })
            .then(response => {
                API.get(
                    'currencies',
                    `/currencies/get/${response[0].account_currency}`
                )
                    .then(response => {
                        this.setState({
                            beneficiary: Object.assign(
                                {},
                                this.state.beneficiary,
                                {
                                    account_currency_nickname: `${
                                        response.full_name
                                    } [${response.iso_alpha_3}]`,
                                }
                            ),
                        })
                        this.props.updateNewTransferCreation(
                            'currency_to_id',
                            response.id
                        )
                        this.props.updateNewTransferCreation(
                            'currency_to',
                            response
                        )

                        API.post('email', `/interaction/main`, {
                            body: {
                                interaction:
                                    'New Transfer Request Step 2 PayingTo',
                                data: {
                                    client_id: this.props.app_state
                                        .current_client.id,
                                    client_nickname: this.props.app_state
                                        .current_client.nickname,
                                    beneficiary_id: this.state.beneficiary.id,
                                    beneficiary_nickname: this.state.beneficiary
                                        .nickname,
                                },
                                result: 'New transfer request proceeding',
                            },
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error)
            })
    }

    fetchNewRate = () => {
        if (
            this.props.transfers.new_transfer_data.currency_to &&
            this.props.transfers.new_transfer_data.currency_from
        ) {
            let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
            axios
                .get(url, {
                    params: {
                        currencies: this.props.transfers.new_transfer_data
                            .currency_from.iso_alpha3,
                        source: this.props.transfers.new_transfer_data
                            .currency_to.iso_alpha3,
                        format: '1',
                    },
                })
                .then(response => {
                    let key = `${this.props.transfers.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}${this.props.transfers.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}`
                    let rate_string = response.data.quotes[key]

                    let amount_to = Number.parseFloat(
                        this.props.transfers.new_transfer_data.amount_to
                    )
                    let rate = Number.parseFloat(rate_string)
                    let rate_adjust =
                        Number.parseFloat(
                            this.props.app_state.current_client.default_rate
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_division
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_entity
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_team
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_staff
                        )
                    let adjusted_rate = rate + rate * rate_adjust

                    let amount_from = amount_to * adjusted_rate

                    this.props.updateNewTransferCreation(
                        'current_rate',
                        adjusted_rate.toFixed(5)
                    )
                    this.props.updateNewTransferCreation(
                        'amount_from',
                        amount_from.toFixed(5)
                    )

                    // this.props.updateNewTransferCreation("current_rate", response.data.quotes["AUDUSD"])
                    // this.props.updateNewTransferCreation("amount_from", this.props.transfers.new_transfer_data.amount_to * parseFloat(response.data.quotes["AUDUSD"]))
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    sendState() {
        return this.state
    }
    // function that verifies if two strings are equal
    compare(string1, string2) {
        if (string1 === string2) {
            return true
        }
        return false
    }
    // function that returns true if value is email, false otherwise
    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (emailRex.test(value)) {
            return true
        }
        return false
    }
    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'email':
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + ' _state']: 'error' })
                }
                break
            case 'equalTo':
                if (
                    this.compare(
                        event.target.value,
                        this.state[stateNameEqualTo]
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
        this.props.updateNewTransferCreation(stateName, event.target.value)
        if (stateName === 'amount_to') {
            this.fetchNewRate()
        }
    }

    handleSelectChange = event => {
        this.setState({ [event.target.name]: event.target.value })
        this.props.updateNewTransferCreation(
            event.target.name,
            event.target.value
        )
        if (event.target.name === 'beneficiary_id') {
            this.getBeneficiaryDetails(event.target.value)
        }
    }

    handleCustomReactSelectChange = name => value => {
        var option_value
        if (value === null) {
            option_value = null
        } else {
            option_value = value.value
        }
        this.setState({
            [name]: option_value,
        })

        if (name === 'beneficiary_id') {
            this.getBeneficiaryDetails(option_value)
        } else {
            this.props.updateNewTransferCreation(name, option_value)
        }

        let clientTransfers = [...this.state.clientTransfers]
        clientTransfers[0] = { ...clientTransfers[0], [name]: option_value }
        this.setState({ clientTransfers })
        this.props.updateNewTransferCreation(
            'multiple_beneficiary_id',
            clientTransfers
        )

        if (name === 'account_currency') {
            let currency_dataset = this.state.currencies_list_priority
            for (let i = 0; i < currency_dataset.length; i++) {
                if (currency_dataset[i].id === option_value) {
                    if (currency_dataset[i].iban === 1) {
                        this.setState({ show_iban: true })
                    } else {
                        this.setState({ show_iban: false })
                    }
                    if (currency_dataset[i].bsb === 1) {
                        this.setState({ show_bsb: true })
                    } else {
                        this.setState({ show_bsb: false })
                    }

                    if (currency_dataset[i].sort_code === 1) {
                        this.setState({ show_sort_code: true })
                    } else {
                        this.setState({ show_sort_code: false })
                    }
                    //swift_code check
                    if (currency_dataset[i].swift_code === 1) {
                        this.setState({ show_swift_code: true })
                    } else {
                        this.setState({ show_swift_code: false })
                    }
                    //aba_routing check
                    if (currency_dataset[i].aba_routing === 1) {
                        this.setState({ show_aba: true })
                    } else {
                        this.setState({ show_aba: false })
                    }
                    //account number check
                    if (currency_dataset[i].account_number === 1) {
                        this.setState({ show_account_number: true })
                    } else {
                        this.setState({ show_account_number: false })
                    }
                }
            }
        }
    }

    isNumeric(number) {
        number = number.replace(/,/g, '')

        if (+number === +number && number > 0) {
            return true
        }

        return false
    }

    isValidated() {
        // console.log(this.state);
        // if (this.state.purpose_of_payment_detail_state !== "success") {
        //   this.setState({ purpose_of_payment_detail_state: "error" });
        // }
        // if (this.state.amount_to_state !== "success") {
        //   this.setState({ amount_to_state: "error" });
        // }
        // if (
        //   this.state.beneficiary_id > 0 &&
        //   this.state.purpose_of_payment_detail_state === "success"
        // ) {
        //   return true;
        // }
        // return false;

        let clientTransfers = [...this.state.clientTransfers]
        let return_flag = true

        clientTransfers.map(el => {
            if (el.beneficiary_id == '') {
                el.beneficiary_id_state = 'error'
                return_flag = false
            } else {
                el.beneficiary_id_state = 'success'
            }

            if (el.purpose_of_payment_detail == '') {
                el.purpose_of_payment_detail_state = 'error'
                return_flag = false
            } else {
                el.purpose_of_payment_detail_state = 'success'
            }

            el.amount = el.amount.replace(/,/g, '')

            if (el.amount == '' || !this.isNumeric(el.amount)) {
                el.amount_state = 'error'
                return_flag = false
            } else {
                el.amount_state = 'success'
            }

            if (el.purpose_of_payment_detail == 1) {
                if (el.purpose_of_payment_other == '') {
                    el.purpose_of_payment_other_state = 'error'
                    return_flag = false
                }
            } else {
                el.purpose_of_payment_other_state = 'success'
            }

            this.setState({
                clientTransfers: clientTransfers,
            })
        })

        return return_flag
    }

    handleChange(e, i, stateName) {
        const { name, value } = e.target
        let clientTransfers = [...this.state.clientTransfers]
        clientTransfers[i] = { ...clientTransfers[i], [stateName]: value }
        this.setState({ clientTransfers })
        this.props.updateNewTransferCreation(
            'multiple_beneficiary_id',
            clientTransfers
        )

        if (stateName == 'beneficiary_id' && i == 0) {
            this.getBeneficiaryDetails(value)

            API.get('beneficiaries', `/get/id/${value}`).then(response => {
                clientTransfers[i] = {
                    ...clientTransfers[i],
                    beneficiary: response,
                }
            })

            clientTransfers[i] = { ...clientTransfers[i], [stateName]: value }

            this.setState({ clientTransfers })
            this.props.updateNewTransferCreation(
                'multiple_beneficiary_id',
                clientTransfers
            )
        } else {
            if (stateName == 'amount') {
                clientTransfers[i] = {
                    ...clientTransfers[i],
                    [stateName]: value.replace(/,/g, ''),
                }
            } else {
                clientTransfers[i] = {
                    ...clientTransfers[i],
                    [stateName]: value,
                }
            }
            this.setState({ clientTransfers })
            this.props.updateNewTransferCreation(
                'multiple_beneficiary_id',
                clientTransfers
            )
        }

        if (stateName == 'amount') {
            let clientTransfers = [...this.state.clientTransfers]
            let total_amount = 0
            let tmpValue = 0

            clientTransfers.map((el, index) => {
                if (index == i) {
                    if (isEmpty(value)) tmpValue = 0
                    else tmpValue = value.replace(/,/g, '')
                    total_amount =
                        Number.parseFloat(total_amount) +
                        Number.parseFloat(tmpValue)
                } else {
                    if (isEmpty(el.amount)) tmpValue = 0
                    else tmpValue = el.amount.replace(/,/g, '')
                    total_amount =
                        Number.parseFloat(total_amount) +
                        Number.parseFloat(tmpValue)
                }
            })
            this.setState({ ['amount_to']: total_amount })
            this.props.updateNewTransferCreation('amount_to', total_amount)
            this.props.updateNewTransferCreation(
                'nickname',
                `FX Transfer ${moment().format('DD/MM/YY')} ${Number.parseFloat(
                    total_amount
                ).toFixed(2)} ${
                    this.props.transfers.new_transfer_data.currency_to
                        ? this.props.transfers.new_transfer_data.currency_to
                              .iso_alpha_3
                        : ''
                }`
            )
        }

        if (stateName === 'purpose_of_payment_detail') {
            //change here
            let document_upload_show = false
            for (let key in this.props.app_state.current_client
                .purpose_of_payment_list) {
                if (
                    this.props.app_state.current_client.purpose_of_payment_list[
                        key
                    ].id == value
                ) {
                    if (
                        this.props.app_state.current_client
                            .purpose_of_payment_list[key].document_upload == 1
                    ) {
                        document_upload_show = true
                    }
                }
            }

            clientTransfers[i] = {
                ...clientTransfers[i],
                ['showDocumentUpload']: document_upload_show,
            }

            this.setState({ clientTransfers })

            if (value == '1')
                this.setState({
                    FIXME: value == 1,
                })
            else
                this.setState({
                    FIXME: false,
                })
        }
    }

    handleBlur(e, i, stateName) {
        const { name, value } = e.target
        let clientTransfers = [...this.state.clientTransfers]
        clientTransfers[i] = {
            ...clientTransfers[i],
            [stateName]: this.props.intl.formatNumber(value.replace(/,/g, '')),
        }
        this.setState({ clientTransfers })
        // this.props.updateNewTransferCreation("multiple_beneficiary_id", clientTransfers);

        if (stateName == 'amount') {
            let clientTransfers = [...this.state.clientTransfers]
            let total_amount = 0
            let tmpValue = 0

            clientTransfers.map((el, index) => {
                if (index == i) {
                    if (isEmpty(value)) tmpValue = 0
                    else tmpValue = value.replace(/,/g, '')
                    total_amount =
                        Number.parseFloat(total_amount) +
                        Number.parseFloat(tmpValue)
                } else {
                    if (isEmpty(el.amount)) tmpValue = 0
                    else tmpValue = el.amount.replace(/,/g, '')
                    total_amount =
                        Number.parseFloat(total_amount) +
                        Number.parseFloat(tmpValue)
                }
            })
            // this.setState({ ["amount_to"]: total_amount });
            // this.props.updateNewTransferCreation("amount_to", total_amount)
            // this.props.updateNewTransferCreation("nickname", `FX Transfer ${moment().format('DD/MM/YY')} ${Number.parseFloat(total_amount).toFixed(2)} ${this.props.transfers.new_transfer_data.currency_to ? this.props.transfers.new_transfer_data.currency_to.iso_alpha_3 : ""}`);
        }
    }

    newBeneficiaryChange(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'email':
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + ' _state']: 'error' })
                }
                break
            case 'equalTo':
                if (
                    this.compare(
                        event.target.value,
                        this.state[stateNameEqualTo]
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
        //this.props.updateNewBeneficiaryCreation([event.target.name], event.target.value)
    }

    isValidateBeneficiary() {
        if (this.state.ben_legal_name == '') {
            this.setState({ ben_legal_name_state: 'error' })
        } else {
            this.setState({ ben_legal_name_state: 'success' })
        }

        if (this.state.account_currency == '') {
            this.setState({ account_currency_state: 'error' })
        } else {
            this.setState({ account_currency_state: 'success' })
        }

        if (
            this.state.ben_legal_name == '' ||
            this.state.account_currency == ''
        ) {
            return false
        } else {
            return true
        }
    }

    createBeneficiaryIntoDatabase = async data => {
        API.post('beneficiaries', `/beneficiaries/create/in-database`, {
            body: {
                ...data,
            },
        })
            .then(database_result => {
                //return database_result;
                API.get('beneficiaries', `/beneficiaries/get`)
                    .then(response => {
                        this.setState({
                            beneficiaries_list: response,
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error)
            })

        this.setState({
            ben_legal_name: '',
            ben_email_main: '',
            ben_address_line_1: '',
            ben_address_line_2: '',
            ben_address_suburb: '',
            ben_address_state: '',
            ben_address_postcode: '',
            ben_address_country: '',

            bank_legal_name: '',
            account_currency: '',
            bsb_code: '',
            swift_code: '',
            account_number: '',
            aba_routing_number: '',
            sort_code: '',
        })

        return
    }

    finished = async () => {
        if (this.isValidateBeneficiary()) {
            const {
                ben_legal_name,
                ben_email_main,
                ben_address_line_1,
                ben_address_line_2,
                ben_address_suburb,
                ben_address_state,
                ben_address_postcode,
                ben_address_country,
                bank_legal_name,
                account_currency,
                bsb_code,
                swift_code,
                account_number,
                aba_routing_number,
                sort_code,
            } = this.state

            try {
                await this.createBeneficiaryIntoDatabase({
                    client_id: this.props.app_state.current_client.id,
                    nickname: ben_legal_name,
                    ben_legal_name: ben_legal_name,
                    ben_email_main: ben_email_main,
                    ben_address_line_1: ben_address_line_1,
                    ben_address_line_2: ben_address_line_2,
                    ben_address_suburb: ben_address_suburb,
                    ben_address_state: ben_address_state,
                    ben_address_postcode: ben_address_postcode,
                    ben_address_country: ben_address_country,
                    bank_legal_name: bank_legal_name,
                    account_currency: account_currency,
                    bsb_code: bsb_code,
                    swift_code: swift_code,
                    account_number: account_number,
                    aba_routing_number: aba_routing_number,
                    sort_code: sort_code,
                })
            } catch (e) {
                this.setState({ isLoading: false })
            }
            // this.setState({
            //   addBeneficiaryModalOpen: false
            // });
        }
    }

    addBeneficiaryModelOpen = () => {
        this.setState({
            addBeneficiaryModalOpen: true,
        })
    }

    addBeneficiaryModelClose = () => {
        this.setState({
            addBeneficiaryModalOpen: false,
        })
    }

    createNewBeneficiaryUi = () => {
        const { classes } = this.props;
        const sortedCountry = this.state.country_list.sort((a,b) => a.full_name.localeCompare(b.full_name));
        const country_list_select_options = sortedCountry.map(
            item => ({
                value: item.id,
                label: item.full_name,
            })
        )
        const select_currency_select_options = this.state.currencies_list_priority.map(
            item => ({
                value: item.id,
                label: (
                    <React.Fragment>
                        <div
                            className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`}
                        />
                        &nbsp;
                        {item.iso_alpha_3 + ': ' + item.full_name}
                    </React.Fragment>
                ),
            })
        )
        return (
            <React.Fragment>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal,
                    }}
                    open={this.state.addBeneficiaryModalOpen}
                    transition={Transition}
                    keepMounted
                    onClose={() => this.addBeneficiaryModelClose()}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description"
                >
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <h4 className={classes.modalTitle}>
                            Add New Beneficiary
                        </h4>
                    </DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}
                    >
                        <GridContainer justify="left">
                            <GridItem xs={12} md={6}>
                                <CustomReactSelect
                                    label="Currency"
                                    options={select_currency_select_options}
                                    value={this.state.account_currency}
                                    onChange={this.handleCustomReactSelectChange(
                                        'account_currency'
                                    )}
                                    isClearable={false}
                                />
                            </GridItem>
                        </GridContainer>
                        <hr />
                        <h6>Beneficiary Details</h6>

                        <GridContainer>
                            <GridItem xs={12} md={6}>
                                <CustomInput
                                    success={
                                        this.state.ben_legal_name_state ===
                                        'success'
                                    }
                                    error={
                                        this.state.ben_legal_name_state ===
                                        'error'
                                    }
                                    labelText={
                                        <span>Beneficiary(Account) Name</span>
                                    }
                                    id="ben_legal_name"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: this.state.ben_legal_name,
                                        onChange: event =>
                                            this.newBeneficiaryChange(
                                                event,
                                                'ben_legal_name',
                                                'length',
                                                3
                                            ),
                                    }}
                                />
                                <CustomInput
                                    success={
                                        this.state.ben_address_line_1_state ===
                                        'success'
                                    }
                                    error={
                                        this.state.ben_address_line_1_state ===
                                        'error'
                                    }
                                    labelText={<span>Address Line 1</span>}
                                    id="ben_address_line_1"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: this.state.ben_address_line_1,
                                        onChange: event =>
                                            this.newBeneficiaryChange(
                                                event,
                                                'ben_address_line_1',
                                                'length',
                                                3
                                            ),
                                    }}
                                />
                                <CustomReactSelect
                                    label="Country"
                                    options={country_list_select_options}
                                    value={this.state.ben_address_country}
                                    onChange={this.handleCustomReactSelectChange(
                                        'ben_address_country'
                                    )}
                                    isClearable={false}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <CustomInput
                                    success={
                                        this.state.ben_email_main_state ===
                                        'success'
                                    }
                                    error={
                                        this.state.ben_email_main_state ===
                                        'error'
                                    }
                                    labelText={<span>Beneficiary Email</span>}
                                    id="ben_email_main"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: this.state.ben_email_main,
                                        onChange: event =>
                                            this.newBeneficiaryChange(
                                                event,
                                                'ben_email_main',
                                                'length',
                                                3
                                            ),
                                    }}
                                />
                                {/*{this.state.ben_address_line_1 &&*/}
                                {/*<CustomInput*/}
                                {/*  success={this.state.ben_address_line_2_state === "success"}*/}
                                {/*  error={this.state.ben_address_line_2_state === "error"}*/}
                                {/*  labelText={*/}
                                {/*    <span>*/}
                                {/*      Address Line 2*/}
                                {/*        </span>*/}
                                {/*  }*/}
                                {/*  id="ben_address_line_2"*/}
                                {/*  formControlProps={{*/}
                                {/*    fullWidth: true*/}
                                {/*  }}*/}
                                {/*  inputProps={{*/}
                                {/*    value: this.state.ben_address_line_2,*/}
                                {/*    onChange: event => this.newBeneficiaryChange(event, "ben_address_line_2", "length", 3)*/}
                                {/*  }}*/}
                                {/*/>*/}
                                {/*}*/}
                                <GridContainer justify="left">
                                    <GridItem xs={12} md={6} xs={3}>
                                        <CustomInput
                                            success={
                                                this.state
                                                    .ben_address_suburb_state ===
                                                'success'
                                            }
                                            error={
                                                this.state
                                                    .ben_address_suburb_state ===
                                                'error'
                                            }
                                            labelText={
                                                <span>Suburb / Region</span>
                                            }
                                            id="ben_address_suburb"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: this.state
                                                    .ben_address_suburb,
                                                onChange: event =>
                                                    this.newBeneficiaryChange(
                                                        event,
                                                        'ben_address_suburb',
                                                        'length',
                                                        3
                                                    ),
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} md={6} xs={3}>
                                        <CustomInput
                                            success={
                                                this.state
                                                    .ben_address_postcode_state ===
                                                'success'
                                            }
                                            error={
                                                this.state
                                                    .ben_address_postcode_state ===
                                                'error'
                                            }
                                            labelText={<span>Postcode</span>}
                                            id="ben_address_postcode"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: this.state
                                                    .ben_address_postcode,
                                                onChange: event =>
                                                    this.newBeneficiaryChange(
                                                        event,
                                                        'ben_address_postcode',
                                                        'length',
                                                        3
                                                    ),
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>

                                {/*<GridContainer justify="left">*/}
                                {/*<GridItem xs={12} md={6} xs={3}>*/}

                                {/*<CustomInput*/}
                                {/*  success={this.state.ben_address_state_state === "success"}*/}
                                {/*  error={this.state.ben_address_state_state === "error"}*/}
                                {/*  labelText={*/}
                                {/*    <span>*/}
                                {/*      State*/}
                                {/*    </span>*/}
                                {/*  }*/}
                                {/*  id="ben_address_state"*/}
                                {/*  formControlProps={{*/}
                                {/*    fullWidth: true*/}
                                {/*  }}*/}
                                {/*  inputProps={{*/}
                                {/*    value: this.state.ben_address_state,*/}
                                {/*    onChange: event => this.newBeneficiaryChange(event, "ben_address_state", "length", 3)*/}
                                {/*  }}*/}
                                {/*/>*/}
                                {/*</GridItem>*/}
                                {/*<GridItem xs={12} md={6} xs={3}>*/}
                                {/**/}
                                {/*</GridItem>*/}
                                {/*</GridContainer>*/}
                            </GridItem>
                        </GridContainer>
                        <hr />
                        <h6>Bank Details</h6>

                        <GridContainer justify="left">
                            <GridItem xs={12} md={6}>
                                <CustomInput
                                    success={
                                        this.state.bank_legal_name_state ===
                                        'success'
                                    }
                                    error={
                                        this.state.bank_legal_name_state ===
                                        'error'
                                    }
                                    labelText={<span>Bank Name</span>}
                                    id="bank_legal_name"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: this.state.bank_legal_name,
                                        onChange: event =>
                                            this.newBeneficiaryChange(
                                                event,
                                                'bank_legal_name',
                                                'length',
                                                3
                                            ),
                                    }}
                                />
                                {this.state.show_iban && (
                                    <CustomInput
                                        success={this.state.iban === 'success'}
                                        error={this.state.iban === 'error'}
                                        labelText={<span>IBAN</span>}
                                        id="iban"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: this.state.iban,
                                            onChange: event =>
                                                this.newBeneficiaryChange(
                                                    event,
                                                    'iban',
                                                    'length',
                                                    3
                                                ),
                                        }}
                                    />
                                )}
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                {this.state.show_swift_code && (
                                    <CustomInput
                                        success={
                                            this.state.swift_code_state ===
                                            'success'
                                        }
                                        error={
                                            this.state.swift_code_state ===
                                            'error'
                                        }
                                        labelText={<span>SWIFT Code</span>}
                                        id="swift_code"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: this.state.swift_code,
                                            onChange: event =>
                                                this.newBeneficiaryChange(
                                                    event,
                                                    'swift_code',
                                                    'length',
                                                    3
                                                ),
                                        }}
                                    />
                                )}
                                {this.state.show_aba && (
                                    <CustomInput
                                        success={
                                            this.state
                                                .aba_routing_number_state ===
                                            'success'
                                        }
                                        error={
                                            this.state
                                                .aba_routing_number_state ===
                                            'error'
                                        }
                                        labelText={
                                            <span>ABA Routing Number</span>
                                        }
                                        id="aba_routing_number"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: this.state
                                                .aba_routing_number,
                                            onChange: event =>
                                                this.newBeneficiaryChange(
                                                    event,
                                                    'aba_routing_number',
                                                    'length',
                                                    3
                                                ),
                                        }}
                                    />
                                )}
                                {this.state.show_sort_code && (
                                    <CustomInput
                                        success={
                                            this.state.sort_code_state ===
                                            'success'
                                        }
                                        error={
                                            this.state.sort_code_state ===
                                            'error'
                                        }
                                        labelText={<span>Sort Code</span>}
                                        id="sort_code"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: this.state.sort_code,
                                            onChange: event =>
                                                this.newBeneficiaryChange(
                                                    event,
                                                    'sort_code',
                                                    'length',
                                                    3
                                                ),
                                        }}
                                    />
                                )}
                                {this.state.show_bsb && (
                                    <CustomInput
                                        success={
                                            this.state.bsb_code_state ===
                                            'success'
                                        }
                                        error={
                                            this.state.bsb_code_state ===
                                            'error'
                                        }
                                        labelText={<span>BSB</span>}
                                        id="bsb_code"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: this.state.bsb_code,
                                            onChange: event =>
                                                this.newBeneficiaryChange(
                                                    event,
                                                    'bsb_code',
                                                    'length',
                                                    3
                                                ),
                                        }}
                                    />
                                )}
                                {this.state.show_account_number && (
                                    <CustomInput
                                        success={
                                            this.state.account_number_state ===
                                            'success'
                                        }
                                        error={
                                            this.state.account_number_state ===
                                            'error'
                                        }
                                        labelText={<span>Account Number </span>}
                                        id="account_number"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: this.state.account_number,
                                            onChange: event =>
                                                this.newBeneficiaryChange(
                                                    event,
                                                    'account_number',
                                                    'length',
                                                    3
                                                ),
                                        }}
                                    />
                                )}
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions
                        className={
                            classes.modalFooter +
                            ' ' +
                            classes.modalFooterCenter
                        }
                    >
                        <Button onClick={() => this.addBeneficiaryModelClose()}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={this.finished}>
                            Add Beneficiary
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }

    onupdate = (file_id, index) => {
        let clientTransfers = this.state.clientTransfers
        clientTransfers[index] = {
            ...clientTransfers[index],
            ['document']: file_id,
        }
        this.setState({ clientTransfers })
        this.props.updateNewTransferCreation(
            'multiple_beneficiary_id',
            clientTransfers
        )
    }

    handleRemove = index => {
        let clientTransfers = this.state.clientTransfers
        clientTransfers[index] = { ...clientTransfers[index], ['document']: '' }
        this.setState({ clientTransfers })
        this.props.updateNewTransferCreation(
            'multiple_beneficiary_id',
            clientTransfers
        )
    }

    addClick() {
        this.setState(prevState => ({
            clientTransfers: [
                ...prevState.clientTransfers,
                {
                    beneficiary_id: '',
                    amount: '',
                    purposeOfPayment: '',
                    beneficiary_id_state: '',
                    amount_state: '',
                    purposeOfPayment_state: '',
                    detail_1: '',
                    detail_2: '',
                    detail_3: '',
                    detail_1_state: '',
                    detail_2_state: '',
                    detail_3_state: '',
                    document: '',
                    purpose_of_payment_other: '',
                    purpose_of_payment_other_state: '',
                    beneficiary: {},
                    showDocumentUpload: false,
                },
            ],
        }))
    }

    removeClick(i) {
        let clientTransfers = [...this.state.clientTransfers]
        clientTransfers.splice(i, 1)
        this.setState({ clientTransfers })

        //clientTransfers = [...this.state.clientTransfers];
        let total_amount = 0
        let tmpValue = 0

        clientTransfers.map((el, index) => {
            tmpValue = el.amount.replace(/,/g, '')
            total_amount =
                Number.parseFloat(total_amount) + Number.parseFloat(tmpValue)
        })
        this.setState({ ['amount_to']: total_amount })
        this.props.updateNewTransferCreation('amount_to', total_amount)
    }

    drawBeneficiaryUI() {
        const { classes } = this.props
        const min_transfer_amount = 100
        const beneficiary_list_select_options = this.state.beneficiaries_list
            .sort((a, b) => {
                if (a.nickname && b.nickname) {
                    return a.nickname.localeCompare(b.nickname)
                } else {
                    return a.id > b.id
                }
            })
            .map(item => {
                return { value: item.id, label: item.nickname }
            })

        return this.state.clientTransfers.map((el, i) => (
            <div>
                <Card>
                    <CardHeader icon>
                        <h4 className={classes.cardIconTitle}>
                            Enter details for beneficiary record {i + 1}
                        </h4>
                    </CardHeader>

                    <CardBody>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12} lg={3}>
                                {/*<CustomReactSelect*/}
                                {/*  label="Select beneficiary"*/}
                                {/*  options={beneficiary_list_select_options}*/}
                                {/*  value={el.beneficiary_id || "0"}*/}
                                {/*  onChange={(event) => this.handleChange(event, i, "beneficiary_id")}*/}
                                {/*  isClearable={false}*/}
                                {/*  error={el.beneficiary_id_state==="error"}*/}
                                {/*/>*/}
                                <Select
                                    style={{ paddingTop: 25 }}
                                    success={
                                        el.beneficiary_id_state === 'success'
                                    }
                                    error={el.beneficiary_id_state === 'error'}
                                    MenuProps={{
                                        className: classes.selectMenu,
                                    }}
                                    classes={{
                                        select: classes.select,
                                    }}
                                    key={i}
                                    value={el.beneficiary_id || '0'}
                                    onChange={event =>
                                        this.handleChange(
                                            event,
                                            i,
                                            'beneficiary_id'
                                        )
                                    }
                                    inputProps={{
                                        name: 'beneficiary_id',
                                        id: 'beneficiary_id',
                                    }}
                                >
                                    <MenuItem
                                        key="0x0"
                                        value="0"
                                        disabled
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        Select Beneficiary
                                    </MenuItem>

                                    {this.state.beneficiaries_list
                                        .sort((a, b) => {
                                            if (a.nickname && b.nickname) {
                                                return a.nickname.localeCompare(
                                                    b.nickname
                                                )
                                            } else {
                                                return a.id > b.id
                                            }
                                        })
                                        .map(item => {
                                            return (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.nickname}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                                <br />
                                <br />
                                <Button
                                    size="sm"
                                    color="info"
                                    key="addKey"
                                    onClick={() =>
                                        this.addBeneficiaryModelOpen()
                                    }
                                >
                                    Create new beneficiary
                                </Button>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={3}>
                                <CustomInput
                                    success={el.amount_state === 'success'}
                                    error={el.amount_state === 'error'}
                                    labelText={
                                        <span>Payment amount required</span>
                                    }
                                    id="nickname"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: el.amount,
                                        onChange: event =>
                                            this.handleChange(
                                                event,
                                                i,
                                                'amount'
                                            ),
                                        onBlur: event =>
                                            this.handleBlur(event, i, 'amount'),
                                    }}
                                    helpText={
                                        this.state.beneficiary
                                            .account_currency_nickname ? (
                                            <span>
                                                {
                                                    this.state.beneficiary
                                                        .account_currency_nickname
                                                }
                                            </span>
                                        ) : (
                                            ''
                                        )
                                    }
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={3}>
                                <Select
                                    fullWidth
                                    success={
                                        el.purpose_of_payment_detail_state ===
                                        'success'
                                    }
                                    error={
                                        el.purpose_of_payment_detail_state ===
                                        'error'
                                    }
                                    MenuProps={{
                                        className: classes.selectMenu,
                                    }}
                                    classes={{
                                        select: classes.select,
                                    }}
                                    key={i}
                                    value={el.purpose_of_payment_detail || '0'}
                                    onChange={event =>
                                        this.handleChange(
                                            event,
                                            i,
                                            'purpose_of_payment_detail'
                                        )
                                    }
                                    // onChange={this.change(event, "residential_street_country", "length", 3)}
                                    inputProps={{
                                        name: 'purpose_of_payment_detail',
                                        id: 'purpose_of_payment_detail',
                                    }}
                                >
                                    <MenuItem
                                        key="0x0"
                                        value="0"
                                        disabled
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        Purpose of Payment
                                    </MenuItem>

                                    {this.state.purpose_list.map(item => {
                                        return (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.description}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {this.state.FIXME && (
                                    <CustomInput
                                        style={{ paddingTop: 25 }}
                                        success={
                                            el.purpose_of_payment_other_state ===
                                            'success'
                                        }
                                        error={
                                            el.purpose_of_payment_other_state ===
                                            'error'
                                        }
                                        labelText={
                                            <span>
                                                Specify purpose of payment
                                            </span>
                                        }
                                        id="purpose_of_payment_other"
                                        name="purpose_of_payment_other"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: el.purpose_of_payment_other,
                                            onChange: event =>
                                                this.handleChange(
                                                    event,
                                                    i,
                                                    'purpose_of_payment_other'
                                                ),
                                        }}
                                    />
                                )}
                                <CustomInput
                                    style={{ paddingTop: 25 }}
                                    success={el.detail_1_state === 'success'}
                                    error={el.detail_1_state === 'error'}
                                    labelText={<span>Invoice Number</span>}
                                    id="detail_1"
                                    name="detail_1"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: el.detail_1,
                                        onChange: event =>
                                            this.handleChange(
                                                event,
                                                i,
                                                'detail_1'
                                            ),
                                    }}
                                />

                                {this.state.fieldForGostudy && (
                                    <CustomInput
                                        success={
                                            el.detail_2_state === 'success'
                                        }
                                        error={el.detail_2_state === 'error'}
                                        labelText={<span>Collage Name</span>}
                                        id="detail_2"
                                        name="detail_2"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: el.detail_2,
                                            onChange: event =>
                                                this.handleChange(
                                                    event,
                                                    i,
                                                    'detail_2'
                                                ),
                                        }}
                                    />
                                )}
                                {this.state.fieldForGostudy && (
                                    <CustomInput
                                        success={
                                            el.detail_3_state === 'success'
                                        }
                                        error={el.detail_3_state === 'error'}
                                        labelText={<span>Course Name</span>}
                                        id="detail_3"
                                        name="detail_3"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: el.detail_3,
                                            onChange: event =>
                                                this.handleChange(
                                                    event,
                                                    i,
                                                    'detail_3'
                                                ),
                                        }}
                                    />
                                )}
                            </GridItem>
                            <GridItem xs={6} md={3}>
                                {el.showDocumentUpload && (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            paddingTop: 25,
                                        }}
                                    >
                                        <DocumentUpload
                                            circle
                                            change={false}
                                            placeholder={document_image}
                                            updateFunction={this.onupdate}
                                            onRemove={this.handleRemove}
                                            index={i}
                                        />
                                        <p>
                                            Please upload a copy of an invoice
                                            or any form of document regarding
                                            this payment.
                                        </p>
                                    </div>
                                )}
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <CardFooter>
                        <GridContainer>
                            <GridItem xs={12} md={2}>
                                <Button
                                    size="sm"
                                    color="warning"
                                    key={i}
                                    onClick={this.removeClick.bind(this, i)}
                                >
                                    Remove This Record
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </CardFooter>
                </Card>
            </div>
        ))
    }

    render() {
        const { classes } = this.props
        const beneficiary_list_select_options = this.state.beneficiaries_list.map(
            item => ({
                value: item.id,
                label: item.nickname,
            })
        )
        const min_transfer_amount = 100
        return (
            <React.Fragment>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12}>
                        <h4 className={classes.infoText}>
                            Please indicate beneficiary from the drop-down menu
                            below ...
                        </h4>
                    </GridItem>
                </GridContainer>
                {this.createNewBeneficiaryUi()}
                {this.drawBeneficiaryUI()}
                <GridContainer justify="left">
                    <GridItem xs={6} md={2}>
                        <Button
                            size="sm"
                            color="info"
                            key="addKey"
                            onClick={this.addClick.bind(this)}
                        >
                            Add New Payout Record
                        </Button>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="left">
                    <GridItem xs={6} md={2}>
                        <br />
                        <br />
                        <span>Total Payout: </span>
                        <CustomInput
                            success={this.state.amount_to_state === 'success'}
                            error={this.state.amount_to_state === 'error'}
                            id="total_amount"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                disabled: true,
                                value: this.state.amount_to
                                    ? this.props.intl.formatNumber(
                                          this.state.amount_to,
                                          { minimumFractionDigits: 2 }
                                      )
                                    : '',
                                onChange: event =>
                                    this.change(
                                        event,
                                        'amount_to',
                                        'length',
                                        3
                                    ),
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </React.Fragment>
        )
    }
}

export default injectIntl(withRouter(withStyles(style)(Step2PayingTo)))
