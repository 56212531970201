import React, { useEffect, useState } from 'react'
// import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx'
// import API from '@aws-amplify/api'
import { useSelector, useDispatch } from 'react-redux'
import {
    // get_current_team_details_v2,
    // onInAppLoginSuccess,
    onInAppLoginSuccesClientId
} from '../../redux/actions'

import {Input, Select} from 'antd';
import FormItem from "antd/es/form/FormItem";
import {API} from "aws-amplify";
const { Search } = Input;


export const ClientDropdownList = () => {
    const [state, setState] = useState({
        loading: true,
        selected: null,
        teamSelected: null,
        isVisible: false,
        clients: [],
        teams: [],
        backOffice: false
    })
    const { current_client } = useSelector(state => state.app_state)
    const dispatch = useDispatch();

    const onSearch = (clientId) => {
        console.log(clientId);
        setState({selected: clientId, isVisible: true, backOffice: true});
        dispatch(onInAppLoginSuccesClientId(clientId))
    }
    const fetchClients = async () => {
        const [response, teams] = await Promise.all([
            API.get('clients', '/multi_client_list'),
            API.get('teams', `/get/client_team/all/${current_client.id}`),
        ])
        setState({
            isVisible: true,
            loading: false,
            teams: teams,
            clients: response.map(item => ({label: `[${item.id}] ${item.nickname}`, value: item.id})),
        })
        return response
    }

    useEffect(() => {
        if ((current_client && current_client.securityBackOffice === 1 && current_client.feature_multiClient === 1) || state.backOffice) {
            fetchClients();
            setState({
                        isVisible: true,
                    })
        }
    }, [current_client])

    return (
        <React.Fragment>
            {state.isVisible && (
                    <Select showSearch
                            style={{ width: '200px' }}
                            onChange={(event) => onSearch(event)}
                            placeholder="Search Client"
                            filterOption={(input, option) => (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) =>
                                (optionA && optionA.label ? optionA.label : '').toLowerCase().localeCompare((optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase())
                            }
                            options={state.clients || []}></Select>
            )}
        </React.Fragment>
    )
}
