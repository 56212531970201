import React from 'react'
import { connect } from 'react-redux'
import {
    // change_current_step,
    // clearNewTransfer,
    update_map_data,
    // updateNewTransferCreation,
    // updateNewTransferCreationBeneficiary
} from '../../redux/actions/transfers_dashboard_multiple'

import withStyles from '@material-ui/core/styles/withStyles'
import dashboardStyle from '../../views/Pages/DashboardPageStyle'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Table from 'components/Table/Table.jsx'

import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'

import Slide from '@material-ui/core/Slide'

//core for react-intl
import { FormattedMessage } from 'react-intl'
// import CustomReactSelect from "../Forms/CustomReactSelect/CustomReactSelect";
import { VectorMap } from 'react-jvectormap'
// import { API } from "aws-amplify";
// import CustomInput from "components/CustomInput/CustomInput.jsx";
// import MultipayTransferStep1 from "./MultipayTransferStep1";
// import MultipayTransferStep2 from "./MultipayTransferStep2";
// import MultipayTransferStep3 from "./MultipayTransferStep3";
// import { fetchTransferListSuccess, loadTransferDetailsSuccess } from "../../redux/actions/transfers";
// import SmallEconomicInfoTabs from "../EconomicInformation/SmallEconomicInfoTabs";
// import EconomicInformationPage from "../EconomicInformation/EconomicInformationPage";
// import EconomicHoliday from "../EconomicInformation/EconomicHoliday";
import CustomReactSelect from '../Forms/CustomReactSelect/CustomReactSelect'
import { API } from 'aws-amplify'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import CustomInput from '../CustomInput/CustomInput'
import { injectIntl } from 'react-intl'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />
})

const moment = require('moment')

class SearchTransfer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            transfers_list: [],
            map_data: {},
            active_status: 0,
            payouts_list: [],
            beneficiary_list: [],
            query_string_transfer_id_given: false,
            transfer_id_exist: false,
        }
    }

    componentDidMount() {
        // API.get("beneficiaries", `/get-all`)
        //   .then(response => {
        //     // console.log(response);
        //     this.setState({
        //       beneficiary_list: response
        //     });
        //     return response;
        //   });
        API.get('transfers', `/get-list-not-deleted`)
            .then(response => {
                this.setState(
                    {
                        transfers_list: response,
                    },
                    () => {
                        this.queryStrings()
                    }
                )
            })
            .catch(error => {})
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.prefill !== this.props.prefill) {
            this.props.clearNewTransfer()
        }
    }

    componentWillUnmount() {
        // this.props.clearNewTransfer();
    }

    queryStrings() {
        const query_strings = queryString.parse(this.props.location.search)
        // console.log(query_strings);
        if (Object.keys(query_strings).length !== 0) {
            if (query_strings.id) {
                this.setState({ query_string_transfer_id_given: true }, () =>
                    this.updateTransferData(Number(query_strings.id))
                )
            }
        }
    }

    isValidated(step) {
        let return_flag = true

        if (step === 1) {
            let beneficiary_array = this.props.transfers_dashboard_multiple
                .new_transfer_data.multiple_beneficiary_id || [{}]
            if (
                this.props.transfers_dashboard_multiple.new_transfer_data
                    .currency_from_state !== 'success'
            ) {
                this.props.updateNewTransferCreation(
                    'currency_from_state',
                    'error'
                )
                return_flag = false
            }
            if (
                this.props.transfers_dashboard_multiple.new_transfer_data
                    .currency_to_state !== 'success'
            ) {
                this.props.updateNewTransferCreation(
                    'currency_to_state',
                    'error'
                )
                return_flag = false
            }
            if (
                this.props.transfers_dashboard_multiple.new_transfer_data
                    .amount_from_state !== 'success'
            ) {
                this.props.updateNewTransferCreation(
                    'amount_from_state',
                    'error'
                )
                return_flag = false
            }
            if (
                this.props.transfers_dashboard_multiple.new_transfer_data
                    .amount_to_state !== 'success'
            ) {
                this.props.updateNewTransferCreation('amount_to_state', 'error')
                return_flag = false
            }

            let multiple_beneficiary = this.props.transfers_dashboard_multiple
                .new_transfer_data.multiple_beneficiary_id || [{}]

            for (let idx in multiple_beneficiary) {
                if (multiple_beneficiary[idx].beneficiary_id == '') {
                    beneficiary_array[idx].beneficiary_id_state = 'error'
                    return_flag = false
                }
                if (multiple_beneficiary[idx].purpose_of_payment_detail == '') {
                    beneficiary_array[idx].purpose_of_payment_detail_state =
                        'error'
                    return_flag = false
                }
                if (multiple_beneficiary[idx].amount == '') {
                    beneficiary_array[0].amount_state = 'error'
                    return_flag = false
                }
            }

            this.props.updateNewTransferCreationBeneficiary(beneficiary_array)
        }

        return return_flag
    }

    handleCustomReactSelectChange = (
        name,
        previous_from_currency,
        previous_to_currency
    ) => value => {
        var option_value
        if (value === null) {
            option_value = null
        } else {
            option_value = value.value
        }

        if (name === 'transfer_id') {
            this.state.query_string_transfer_id_given &&
                this.setState({ query_string_transfer_id_given: false })
            this.updateTransferData(
                option_value,
                previous_from_currency,
                previous_to_currency
            )
        }

        // this.setState({ transfer_id: option_value });

        // let purpose_of_payment_str = "";
        // let transfer_list = this.state.transfers_list;
        // for (var j = 0; j < transfer_list.length; j++){
        //   if(transfer_list[j].id == option_value){
        //     this.setState({
        //       amount_from: transfer_list[j].currency_from_iso_alpha_3 +" " + transfer_list[j].amount_from,
        //       amount_to: transfer_list[j].currency_to_iso_alpha_3 +" " + transfer_list[j].amount_to,
        //       client_rate: transfer_list[j].client_rate,
        //       active_status: transfer_list[j].status
        //     });
        //     this.updateMapData("from_currency", previous_from_currency, previous_to_currency, transfer_list[j].currency_from_id);
        //     this.updateMapData("to_currency", previous_from_currency, previous_to_currency, transfer_list[j].currency_to_id);

        //     API.get("transfers", `/get-full/id/${option_value}`)
        //       .then(response => {
        //         // console.log(response);
        //         this.setState({
        //           //transfer: response.fullList,
        //           payouts_list: response.payouts
        //         });
        //       })
        //       .catch(error => {
        //         console.log(error);
        //       });

        //   }
        // }
    }

    updateTransferData(
        option_value,
        previous_from_currency,
        previous_to_currency,
        currency_object
    ) {
        this.setState({ transfer_id: option_value })

        let purpose_of_payment_str = ''
        let transfer_list = this.state.transfers_list
        for (var j = 0; j < transfer_list.length; j++) {
            if (transfer_list[j].id == option_value) {
                this.setState({
                    // amount_from: transfer_list[j].currency_from_iso_alpha_3 +" " + transfer_list[j].amount_from,
                    amount_from: transfer_list[j].amount_from,
                    currency_from: transfer_list[j].currency_from_iso_alpha_3,
                    // amount_to: transfer_list[j].currency_to_iso_alpha_3 +" " + transfer_list[j].amount_to,
                    amount_to: transfer_list[j].amount_to,
                    currency_to: transfer_list[j].currency_to_iso_alpha_3,
                    client_rate: transfer_list[j].client_rate,
                    active_status: transfer_list[j].status,
                    transfer_id_exist: true,
                })

                this.updateMapData(
                    'from_currency',
                    previous_from_currency,
                    previous_to_currency,
                    transfer_list[j].currency_from_id
                )
                this.updateMapData(
                    'to_currency',
                    previous_from_currency,
                    previous_to_currency,
                    transfer_list[j].currency_to_id
                )

                API.get('transfers', `/get-full/id/${option_value}`)
                    .then(response => {
                        // console.log(response);
                        this.setState({
                            //transfer: response.fullList,
                            payouts_list: response.payouts,
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }
    }

    updateMapData(
        currency_name,
        previous_from_currency,
        previous_to_currency,
        currency_object
    ) {
        var map_values = this.state.map_data
        const from_currency_map_value = 0
        const to_currency_map_value = 1

        // update the properties for visulization
        if (currency_name === 'from_currency') {
            // delele previous currency property in map_values
            if (previous_from_currency !== previous_to_currency) {
                this.deleleCurrency(
                    map_values,
                    previous_from_currency,
                    previous_to_currency
                )
            }
            // add new currency property in map_values
            this.addCurrency(
                map_values,
                currency_object,
                from_currency_map_value
            )
        }
        if (currency_name === 'to_currency') {
            // delele previous currency property in map_values
            if (previous_to_currency !== previous_from_currency) {
                this.deleleCurrency(
                    map_values,
                    previous_to_currency,
                    previous_from_currency
                )
            }
            // add new currency property in map_values
            this.addCurrency(map_values, currency_object, to_currency_map_value)
        }
        this.setState({ map_data: map_values })
        this.props.update_map_data(map_values)
    }

    deleleCurrency(map_values, currency_deleted, the_other_currency) {
        switch (currency_deleted) {
            case 1: // AUD
                delete map_values.AU // Australia
                break
            case 5: // USD
                delete map_values.US // United States
                break
            case 8: // GBP
                // if the other currency is not Euro, delete GB
                if (the_other_currency !== 9) {
                    delete map_values.GB // United Kingdom
                }
                break
            case 9: // Eur. For Europe: italy, spain, france, portugal, germany
                // if the other currency is not GB, delete GB
                if (the_other_currency !== 8) {
                    delete map_values.GB // United Kingdom
                }
                delete map_values.IT // Italy
                delete map_values.ES // Spain
                delete map_values.FR // France
                delete map_values.PT // Portugal
                delete map_values.DE // Germany
                break
            case 6: // ATS
                delete map_values.AT // 	Austria
                break
            default:
        }
    }

    addCurrency(map_values, currency, currency_map_value) {
        switch (currency) {
            case 1:
                map_values.AU = currency_map_value
                break
            case 5:
                map_values.US = currency_map_value
                break
            case 8:
                map_values.GB = currency_map_value
                break
            case 9:
                map_values.GB = currency_map_value
                map_values.IT = currency_map_value
                map_values.ES = currency_map_value
                map_values.FR = currency_map_value
                map_values.PT = currency_map_value
                map_values.DE = currency_map_value
                break
            case 6:
                map_values.AT = currency_map_value
                break
            default:
        }
    }

    getSteps() {
        return [
            'Submitted',
            'Transfer acknowledged',
            'Funds received from client',
            'Payment made to Beneficiary',
            'Completed',
        ]
    }
    getSteps1() {
        return ['Submitted', 'Not Proceeding']
    }

    renderSteeper() {
        const steps = this.getSteps()
        const steps1 = this.getSteps1()

        let ActivTab = 0
        let firstStep = true
        if (this.state.active_status == 2) ActivTab = 0
        if (this.state.active_status == 3) ActivTab = 1
        if (this.state.active_status == 6) ActivTab = 2
        if (this.state.active_status == 7) ActivTab = 3
        if (this.state.active_status == 8) ActivTab = 4

        if (this.state.active_status == 10) {
            ActivTab = 1
            firstStep = false
        }

        return (
            <Card style={{ height: '90%' }}>
                <CardHeader color="info" stats icon />
                <CardBody>
                    <h5>Transfer Status</h5>
                    {firstStep && (
                        <Stepper activeStep={ActivTab}>
                            {steps.map((label, index) => {
                                const stepProps = {}
                                const labelProps = {}

                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>
                                            {label}
                                        </StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    )}
                    {!firstStep && (
                        <Stepper activeStep={ActivTab}>
                            {steps1.map((label, index) => {
                                const stepProps = {}
                                const labelProps = {}

                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>
                                            {label}
                                        </StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    )}
                </CardBody>
            </Card>
        )
    }

    renderLeftContent() {
        const select_currency_select_options = this.state.transfers_list.map(
            item => ({
                value: item.id,
                label: item.id.toString().padStart(5, '0'),
                // label: item.id.toString().padStart(5, '0') + " - " +
                //   item.currency_from_iso_alpha_3 + " " +
                //   this.props.intl.formatNumber(item.amount_from, {
                //     minimumFractionDigits: 2,
                //     maximumFractionDigits: 2
                //   }) + " " +
                //   item.currency_to_iso_alpha_3 + " " +
                //   this.props.intl.formatNumber(item.amount_to, {
                //     minimumFractionDigits: 2,
                //     maximumFractionDigits: 2
                //   })
            })
        )

        return (
            <React.Fragment>
                {(!this.state.query_string_transfer_id_given ||
                    !this.state.transfer_id_exist) && (
                    <GridContainer justify="flex-start">
                        <GridItem xs={12} sm={6}>
                            <br />
                            <CustomReactSelect
                                label={
                                    <span style={{ color: '#00acc1' }}>
                                        <FormattedMessage
                                            id="dashboard.transferId"
                                            defaultMessage={`Select Transfer`}
                                        />
                                    </span>
                                }
                                options={select_currency_select_options}
                                value={this.state.transfer_id}
                                onChange={this.handleCustomReactSelectChange(
                                    'transfer_id'
                                )}
                                isClearable={false}
                            />
                        </GridItem>
                    </GridContainer>
                )}

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader>
                                <h5>Financials</h5>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableData={[
                                        [
                                            'Transfer From',
                                            <React.Fragment>
                                                {this.state.amount_from ? (
                                                    <React.Fragment>
                                                        <div
                                                            className={`currency-flag currency-flag-${this.state.currency_from.toLowerCase()}`}
                                                        />{' '}
                                                        {this.state.currency_from.toUpperCase()}{' '}
                                                        {this.props.intl.formatNumber(
                                                            this.state
                                                                .amount_from,
                                                            {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    ''
                                                )}
                                            </React.Fragment>,
                                        ],
                                        [
                                            'Transfer To',
                                            <React.Fragment>
                                                {this.state.amount_to ? (
                                                    <React.Fragment>
                                                        <div
                                                            className={`currency-flag currency-flag-${this.state.currency_to.toLowerCase()}`}
                                                        />{' '}
                                                        {this.state.currency_to.toUpperCase()}{' '}
                                                        {this.props.intl.formatNumber(
                                                            this.state
                                                                .amount_to,
                                                            {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    ''
                                                )}
                                            </React.Fragment>,
                                        ],
                                        [
                                            'Rate',
                                            <React.Fragment>
                                                {this.state.client_rate ? (
                                                    <React.Fragment>
                                                        {this.props.intl.formatNumber(
                                                            this.state
                                                                .client_rate,
                                                            {
                                                                minimumFractionDigits: 5,
                                                                maximumFractionDigits: 5,
                                                            }
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    ''
                                                )}
                                            </React.Fragment>,
                                        ],
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </React.Fragment>
        )
    }

    renderMapComponent() {
        return (
            <VectorMap
                map={'world_mill'}
                backgroundColor="transparent"
                zoomOnScroll={false}
                containerStyle={{
                    width: '100%',
                    height: '420px',
                }}
                containerClassName="map"
                regionStyle={{
                    initial: {
                        fill: '#e4e4e4',
                        'fill-opacity': 0.9,
                        stroke: 'none',
                        'stroke-width': 0,
                        'stroke-opacity': 0,
                    },
                }}
                series={{
                    regions: [
                        {
                            values: this.state.map_data,
                            scale: ['#26c6da', '#4caf50'],
                            normalizeFunction: 'polynomial',
                        },
                    ],
                }}
            />
        )
    }

    createPayoutUI() {
        const { classes } = this.props
        // const beneficiary_list_select_options = this.state.beneficiary_list
        //   .sort((a, b) =>
        //     a.nickname.localeCompare(b.nickname)
        //   )
        //   .map(item => {
        //     return (
        //       {value: item.id, label: item.nickname}
        //     );
        //   });

        return this.state.payouts_list.map((el, i) => (
            <div key={i}>
                <GridContainer>
                    <GridItem xs={2} sm={2} md={2} lg={2}>
                        <CustomInput
                            labelText={'Beneficiary name'}
                            id="nickname"
                            name="nickname"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: el.nickname,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={1} sm={1} md={1} lg={1}>
                        <CustomInput
                            labelText={'Amount'}
                            id="amount_to"
                            name="amount_to"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: this.props.intl.formatNumber(
                                    el.amount_to
                                ),
                            }}
                        />
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2} lg={2}>
                        <CustomInput
                            labelText={'Purpose of Payment'}
                            id="detail_2"
                            name="detail_2"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: el.description,
                            }}
                        />
                    </GridItem>
                    {/*<GridItem xs={2} sm={2} md={2} lg={2}>*/}
                    {/*  <CustomInput*/}
                    {/*    labelText={"Collage Name"}*/}
                    {/*    id="detail_2"*/}
                    {/*    name="detail_2"*/}
                    {/*    formControlProps={{*/}
                    {/*      fullWidth: true*/}
                    {/*    }}*/}
                    {/*    inputProps={{*/}
                    {/*      value: el.detail_2*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*</GridItem>*/}
                    <GridItem xs={2} sm={2} md={2} lg={2}>
                        <div style={{ padding_left: 5 }}>
                            <CustomInput
                                labelText={'Invoice Number'}
                                id="detail_1"
                                name="detail_1"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    value: el.detail_1,
                                }}
                            />
                        </div>
                    </GridItem>
                    {/*<GridItem xs={2} sm={2} md={2} lg={2}>*/}
                    {/*  <CustomInput*/}
                    {/*    labelText={"Course Name"}*/}
                    {/*    id="detail_3"*/}
                    {/*    name="detail_3"*/}
                    {/*    formControlProps={{*/}
                    {/*      fullWidth: true*/}
                    {/*    }}*/}
                    {/*    inputProps={{*/}
                    {/*      value: el.detail_3,*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*</GridItem>*/}
                </GridContainer>
            </div>
        ))
    }

    render() {
        const { classes } = this.props
        let branding = ''
        try {
            switch (this.props.app_state.current_team.brand_app_name) {
                case 'Forex WorldWide Money Transfer Service':
                    branding = 'Forex WorldWide Money Transfer Service'
                    break
                default:
                    branding = this.props.app_state.current_team.brand_app_name
                    break
            }
        } catch (err) {
            console.log(err)
            branding = 'Forex WorldWide Money Transfer Service'
        }

        return (
            <React.Fragment>
                <GridItem xs={12}>
                    <Card style={{ height: '90%' }}>
                        <CardHeader color="info" stats icon />
                        <CardBody>
                            <p className={classes.cardCategory}>
                                {branding}
                                {/*<FormattedMessage*/}
                                {/*  id="dashboard.heading1"*/}
                                {/*  defaultMessage={*/}
                                {/*    `Welcome to`}*/}
                                {/*/>*/}
                            </p>
                            <h3 className={classes.cardTitle}>
                                Search Transfer
                            </h3>
                            <GridContainer>
                                <GridItem xs={12} md={6}>
                                    {this.renderLeftContent()}
                                </GridItem>
                                <GridItem xs={12} md={6}>
                                    {this.renderMapComponent()}
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {this.state.transfer_id_exist && this.renderSteeper()}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card style={{ height: '90%' }}>
                        <CardHeader color="info" stats icon />
                        <CardBody>
                            <h5>Payout Details</h5>
                            {this.createPayoutUI()}
                        </CardBody>
                    </Card>
                </GridItem>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
        // transfers_dashboard_multiple: state.transfers_dashboard_multiple
    }
}

const mapDispatchToProps = dispatch => {
    return {
        update_map_data: data => {
            dispatch(update_map_data(data))
        },
        // change_current_step: (step) => {
        //   dispatch(change_current_step(step))
        // },
        // clearNewTransfer: () => {
        //   dispatch(clearNewTransfer())
        // },
        // updateNewTransferCreation: (key, value) => {
        //   dispatch(updateNewTransferCreation(key, value))
        // },
        // updateNewTransferCreationBeneficiary: (array) => {
        //   dispatch(updateNewTransferCreationBeneficiary(array))
        // }
    }
}

const SearchTransferContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(injectIntl(withStyles(dashboardStyle)(SearchTransfer))))

export default SearchTransferContainer
