import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

// import {matchSorter} from 'match-sorter'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'

// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react'
import Button from '../../CustomButtons/Button'

import { API } from 'aws-amplify'

import { injectIntl } from 'react-intl'
import 'react-circular-progressbar/dist/styles.css'

const moment = require('moment')

const styles = {}

class TransferDetailPayoutToLedger extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {}

    render() {
        const { classes } = this.props
        return (
            <React.Fragment>
                TRANSFER TO CLIENT HOLDING ACCOUNT LEDGER
            </React.Fragment>
        )
    }
    // let remittance_reference = "";
}

export default injectIntl(
    withRouter(withStyles(styles)(TransferDetailPayoutToLedger))
)
