import React, { Fragment } from 'react'
import ReactTable from 'react-table'
import { matchSorter } from 'match-sorter'
import Badge from '../Badge/Badge'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import withStyles from '@material-ui/core/styles/withStyles'
import WarningIcon from '@material-ui/icons/Warning'
import { connect } from 'react-redux'
import { updateEconomicCalendar } from '../../redux/actions'
import { CustomDialogue } from '../../components/CustomDialogue/CustomDialogue'
import CommoneCurrentComponent from '../../components/DashboardTransfer/Common'
import GridContainer from 'components/Grid/GridContainer.jsx'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import Snackbars from '../../components/Snackbar/Snackbar'
import AddAlert from '@material-ui/icons/AddAlert'
import Table from '../Table/Table'
import { makeStyles } from '@material-ui/core/styles'

import styles from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import { API } from 'aws-amplify'
import Button from '../CustomButtons/Button'
import { FormattedMessage } from 'react-intl'
import CustomReactSelect from '../Forms/CustomReactSelect/CustomReactSelect'
import GridItem from '../Grid/GridItem'
import CustomTextField from '../CustomInput/CustomTextField'

var axios = require('axios')
const moment = require('moment')

const stylesA = theme => ({
    ...styles,
    root: {
        width: '100%',
        maxWidth: 1200,
        marginLeft: 'auto',
        marginRight: 'auto',
        minHeight: 200,
        padding: 10,
    },
    component_title: {},
    table: {
        color: 'white',
    },
    avatar: {
        width: 20,
        height: 20,
        marginLeft: 6,
        backgroundColor: 'rgb(185, 185, 185)',
    },
    chip: {
        fontWeight: 500,
        '&:hover': {
            backgroundColor: '#1976d2',
            color: 'white',
        },
    },
    chipActive: {
        fontWeight: 500,
        backgroundColor: '#1976d2',
        color: 'white',
        '&:hover': {
            backgroundColor: 'white',
            color: '#1976d2',
        },
    },
})

class CurrencyAlerts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            modalState: false,
            c_alerts: [],
            currencies_list_priority: [],
            quoteCurrencyId: 1,
            dateExpires: new Date(),
            baseCurrencyId: 5,
            alertPrice: 0,
        }
    }

    async componentDidMount() {
        await this.populate_data()
    }

    populate_data = async () => {
        try {
            const [currencies_list_priority, response] = await Promise.all([
                API.get('currencies', `/currencies/get-list-priority`),
                API.get('currency-alerts', '/get-all'),
            ])
            console.info('----sssssssssss--', currencies_list_priority)
            this.setState({
                currencies_list_priority,
                c_alerts: response,
            })
        } catch (e) {
            console.error('error', e)
        }
    }

    handleCustomReactSelectChange = (stateName, event) => {
        this.setState({
            [stateName]: event.value || event,
        })
    }

    change = (stateName, event) => {
        this.setState({
            [stateName]: event.target.value,
        })
    }

    toggleModal = async bool => {
        this.setState({
            modalState: bool,
        })
    }

    handleSubmit = async () => {
        try {
            const response = await API.post('currency-alerts', `/create`, {
                body: {
                    dateExpires: this.state.dateExpires,
                    baseCurrencyId: this.state.baseCurrencyId,
                    quoteCurrencyId: this.state.quoteCurrencyId,
                    alertPrice: this.state.alertPrice,
                },
            })
            await this.populate_data()
        } catch (e) {
            console.error('error', e)
        }
    }

    render() {
        const { classes } = this.props
        const state = this.state
        const { className, message, onClose, variant, ...other } = this.props
        // console.log(this.state.posts)
        const select_currency_select_options = this.state.currencies_list_priority.map(
            item => {
                return {
                    value: item.id,
                    label: (
                        <React.Fragment>
                            <span
                                className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`}
                            />
                            &nbsp;
                            {item.iso_alpha_3}
                        </React.Fragment>
                    ),
                }
            }
        )
        return (
            <React.Fragment>
                <div style={{ textAlign: 'right' }}>
                    <Button onClick={() => this.toggleModal(true)}>
                        Create
                    </Button>
                </div>
                <div>
                    <ReactTable
                        data={this.state.c_alerts}
                        //filterable
                        sortable={false}
                        columns={[
                            {
                                Header: 'Created ',
                                accessor: 'dateCreated',
                                Cell: c => {
                                    return (
                                        <div>
                                            {moment(c.value).format(
                                                'YYYY-MMM-DD'
                                            )}
                                        </div>
                                    )
                                },
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                        keys: ['dateCreated'],
                                    }),
                                filterAll: true,
                                maxWidth: 150,
                            },
                            {
                                Header: 'Expires',
                                accessor: 'dateExpires',
                                Cell: c => {
                                    return (
                                        <div>
                                            {moment(c.value).format(
                                                'YYYY-MMM-DD'
                                            )}
                                        </div>
                                    )
                                },
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                        keys: ['dateExpires'],
                                    }),
                                filterAll: true,
                                maxWidth: 500,
                            },
                            {
                                Header: 'Base Currency',
                                accessor: 'baseCurrency',
                                Cell: c => {
                                    return (
                                        <span>
                                            <div
                                                className={`currency-flag currency-flag-${c.value.toLowerCase()}`}
                                            />
                                            &nbsp; {c.value}
                                        </span>
                                    )
                                },
                                filterMethod: (filter, row) => {
                                    if (filter.value === 'all') {
                                        return true
                                    }
                                    return row[filter.id] == filter.value
                                },

                                maxWidth: 150,
                            },
                            {
                                Header: 'Quote Currency',
                                accessor: 'quoteCurrency',
                                Cell: c => {
                                    return (
                                        <span>
                                            <div
                                                className={`currency-flag currency-flag-${c.value.toLowerCase()}`}
                                            />
                                            &nbsp; {c.value}
                                        </span>
                                    )
                                },
                                filterMethod: (filter, row) => {
                                    if (filter.value === 'all') {
                                        return true
                                    }
                                    return row[filter.id] == filter.value
                                },

                                maxWidth: 150,
                            },
                            {
                                Header: 'Alert Price',
                                accessor: 'alertPrice',
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                        keys: ['alertPrice'],
                                    }),
                                filterAll: true,
                                maxWidth: 500,
                            },
                        ]}
                        defaultPageSize={20}
                        // pageSizeOptions={[3, 6]}

                        getTrProps={(state, rowInfo, instance) => {
                            if (rowInfo) {
                                let current_time = moment()
                                let item_time = moment(
                                    rowInfo.row.time,
                                    'hh:mm a'
                                )
                                let is_passed = item_time.isBefore(current_time)
                                return {
                                    style: {
                                        background: is_passed
                                            ? '#e6e6e6'
                                            : 'none',
                                    },
                                }
                            }
                            return {}
                        }}
                    />
                </div>
                <CustomDialogue
                    modalState={this.state.modalState}
                    toggleModal={this.toggleModal}
                    title="New CurrencyAlert"
                    onSubmit={this.handleSubmit}
                >
                    <Fragment>
                        <GridContainer>
                            <GridItem xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        label="Date Expired"
                                        inputVariant="outlined"
                                        value={this.state.dateExpires}
                                        onChange={event =>
                                            this.handleCustomReactSelectChange(
                                                'dateExpires',
                                                event
                                            )
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                            </GridItem>
                            <br />
                            <GridItem xs={12}>
                                <CustomReactSelect
                                    label="Base Currency"
                                    options={select_currency_select_options}
                                    value={this.state.baseCurrencyId}
                                    onChange={event =>
                                        this.handleCustomReactSelectChange(
                                            'baseCurrencyId',
                                            event
                                        )
                                    }
                                    style={{
                                        dropdownIndicator: base => ({
                                            ...base,
                                            color: 'gray', // Custom colour
                                        }),
                                    }}
                                    isClearable={false}
                                />
                            </GridItem>
                            <br />
                            <GridItem xs={12}>
                                <CustomReactSelect
                                    label="Quote Currency"
                                    options={select_currency_select_options}
                                    value={this.state.quoteCurrencyId}
                                    onChange={event =>
                                        this.handleCustomReactSelectChange(
                                            'quoteCurrencyId',
                                            event
                                        )
                                    }
                                    style={{
                                        dropdownIndicator: base => ({
                                            ...base,
                                            color: 'gray', // Custom colour
                                        }),
                                    }}
                                    isClearable={false}
                                />
                            </GridItem>
                            <br />
                            <GridItem xs={12}>
                                <CustomTextField
                                    success={
                                        this.state.alertPrice_state ===
                                        'success'
                                    }
                                    error={
                                        this.state.alertPrice_state === 'error'
                                    }
                                    labelText={<span>Alert Price</span>}
                                    id="alertPrice"
                                    formControlProps={{
                                        fullWidth: true,
                                        style: {
                                            marginLeft: '0',
                                            borderRadius: '0px',
                                        },
                                    }}
                                    inputProps={{
                                        error:
                                            this.state.alertPrice_state ===
                                            'error',
                                        value: this.state.alertPrice,
                                        variant: 'outlined',
                                        size: 'small',
                                        label: <span>Alert Price</span>,
                                        style: { backgroundColor: 'white' },
                                        onChange: event =>
                                            this.change('alertPrice', event),
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    </Fragment>
                </CustomDialogue>
            </React.Fragment>
        )
    }
}

// export default withStyles(stylesA)(EconomicCalender);

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateEconomicCalendar: value => {
            dispatch(updateEconomicCalendar(value))
        },
    }
}

const CurrencyAlertsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(stylesA)(CurrencyAlerts))

export default CurrencyAlertsContainer
