import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import Button from 'components/CustomButtons/Button.jsx'

import {Input} from 'antd';

import ReactTable from 'react-table'
import { FormattedNumber } from 'react-intl'
import Table from 'components/Table/Table.jsx'
import { injectIntl } from 'react-intl'

import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

import { API } from 'aws-amplify'

const axios = require('axios')
var moment = require('moment')

const forexWorldWideLogo = require('assets/img/pdf_img/forexworldwide_logo_transparent_280x50.42dc12df.png')
const forexSportLogo = require('assets/img/pdf_img/fxsport_signature_gmail.png')
const goStudyLogo = require('assets/img/pdf_img/GOSTUDY_HorizontalUsage_Black_250x50.png')

// const us_flag = require("assets/img/flags/US.png");
// const au_flag = require("assets/img/flags/AU.png");
// const gb_flag = require("assets/img/flags/GB.png");

// var systemNews = [
//   "Alpha Testing: Staff",
//   "Alpha Testing: Clients",
//   "Implementing: Beneficiaries",
//   "Development: Transfers"
// ];

const style = {
    root: {},
    pageHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 22,
    },
    pageSubHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 18,
    },
    tableHeaders: {
        fontFamily: 'Arial',
        width: 150,
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 8,
    },
    tableCells: {
        fontFamily: 'Arial',
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
    },
    backgroundBlue: {
        backgroundColor: '#348feb20',
    },
}

// let language = navigator.language.split(/[-_]/)[0];
const TheadComponent = props => null // a component returning null (to hide) or you could write as per your requirement

class TransfersRemittancePage extends React.Component {
    state = {
        value: 0,
        currencies_list_priority: [],
        map_data: {},
        beneficiaries_list: [],
        beneficiary_id: '',
        transfer_heading: 'Current Transfers',
        transfer: {},
    }

    componentDidMount() {
        console.log(this.props.match.params)
        this.updateTransferData(this.props.match.params.transfer_id)
        // API.get("currencies", `/currencies/get-list-priority`)
        //   .then(response => {
        //     this.setState({
        //       // currencies_list: response.fullList,
        //       currencies_list_priority: response
        //     });
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });

        // API.get("beneficiaries", `/beneficiaries/get`)
        //   .then(response => {
        //     this.setState({
        //       beneficiaries_list: response
        //     });
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.prefill !== this.props.prefill) {
            this.updateTransferData()
        }
    }

    updateTransferData(option_value) {
        this.setState({ transfer_id: option_value })

        let purpose_of_payment_str = ''
        // let transfer_list = this.state.transfers_list;
        // for (var j = 0; j < transfer_list.length; j++){
        //   if(transfer_list[j].id == option_value){
        //     this.setState({
        //       // amount_from: transfer_list[j].currency_from_iso_alpha_3 +" " + transfer_list[j].amount_from,
        //       amount_from: transfer_list[j].amount_from,
        //       currency_from: transfer_list[j].currency_from_iso_alpha_3,
        //       // amount_to: transfer_list[j].currency_to_iso_alpha_3 +" " + transfer_list[j].amount_to,
        //       amount_to: transfer_list[j].amount_to,
        //       currency_to: transfer_list[j].currency_to_iso_alpha_3,
        //       client_rate: transfer_list[j].client_rate,
        //       active_status: transfer_list[j].status,
        //       transfer_id_exist:true
        //     });

        //     // this.updateMapData("from_currency", previous_from_currency, previous_to_currency, transfer_list[j].currency_from_id);
        // this.updateMapData("to_currency", previous_from_currency, previous_to_currency, transfer_list[j].currency_to_id);

        API.get('transfers', `/get-full/id/${option_value}`)
            .then(response => {
                console.log(response)
                this.setState({
                    transfer: response.fullList,
                    payouts_list: response.payouts,
                })

                API.get(
                    'currencies',
                    `/currencies/get/${response.fullList.currency_from_id}`
                )
                    .then(response => {
                        console.log(response)
                        this.setState({
                            currency_from: response,
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })

                API.get(
                    'currencies',
                    `/currencies/get/${response.fullList.currency_to_id}`
                )
                    .then(response => {
                        console.log(response)
                        this.setState({
                            currency_to: response,
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })

                return response
            })
            .then(response => {
                if (response.fullList.remitting_bank_account_id) {
                    API.get(
                        'entitybanksaccount',
                        `/get/id/${response.fullList.remitting_bank_account_id}`
                    )
                        .then(response => {
                            this.setState({
                                loaded: true,
                                is_loading: false,
                                entity_bank_account: response,
                            })

                            return response
                        })
                        .then(response => {
                            API.get(
                                'entitybanks',
                                `/get/id/${response.entity_bank_id}`
                            )
                                .then(response => {
                                    this.setState({
                                        loaded: true,
                                        is_loading: false,
                                        entity_bank: response,
                                    })

                                    return response
                                })
                                .catch(error => {
                                    console.log(error)
                                })
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
            })
            .catch(error => {
                console.log(error)
            })

        API.get('beneficiaries', `/beneficiaries/get`).then(response => {
            console.log(response)
            this.setState({
                beneficiaries_list: response,
            })
            return response
        })

        API.get('currencies', `/currencies/get-list-priority`)
            .then(response => {
                console.log(response)
                this.setState({
                    currencies_list_priority: response,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }

    // function that verifies if a value is larger than a number or not
    isLargerThanNumber(value, number) {
        if (value !== '' && value >= number) {
            return true
        }
        return false
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'isLargerThanNumber':
                if (
                    this.isLargerThanNumber(
                        event.target.value,
                        stateNameEqualTo
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
    }

    handleChange = (event, value) => {
        this.setState({ value })
    }
    handleChangeIndex = index => {
        this.setState({ value: index })
    }

    printDocument() {
        const input = document.getElementById('divToPrint')
        html2canvas(input).then(canvas => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF('p', 'mm', 'a4')
            var width = pdf.internal.pageSize.getWidth()
            var height = pdf.internal.pageSize.getHeight()
            pdf.addImage(imgData, 'JPEG', 0, 0, width, height)
            // pdf.output('dataurlnewwindow');
            pdf.save(
                `ForexSettlementInstructions_${this.props.match.params.transfer_id.padStart(
                    6,
                    '0'
                )}`
            )
        })
    }

    buildData = () => {
        let return_data = []

        return_data.push({ date: ['DATE', 'sdsaa'] })

        return return_data
    }
    //temp <img src={brandingImg}https://forexworldwide.com/static/media/forexworldwide_logo_transparent_280x50.42dc12df.png" alt="ForexWorldWide Logo"/>

    /*
   * = There are three logos images we can use in here #882 by Tom
    forexWorldWideLogo
    forexSportLogo
    goStudyLogo
  */
    getSignatureLine = brand => {
        switch (brand) {
            case '1':
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img
                                src={forexWorldWideLogo}
                                alt="ForexWorldWide Logo"
                            />
                            <br />
                            <strong>Forex WorldWide</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexworldwide.com
                            <br />T +61 (03) 9008 1880
                            <br />W www.forexworldwide.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) in Australia and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            case '2':
                break
            case '4':
                return (
                    <React.Fragment>
                        <p>
                            <strong>Forex Student</strong>
                            <br />
                            Forex Student is a trading name of Forex Sport Pty
                            Ltd AFSL 401379.
                            <br />
                            Forex Student is a provider of money transfer
                            services for Go Study Australia Pty Ltd.
                            <br />
                            <br />
                            <table width="40%">
                                <tr>
                                    <td>
                                        <img
                                            src={forexWorldWideLogo}
                                            alt="ForexWorldWide Logo"
                                        />
                                    </td>
                                    <td>
                                        <img
                                            src={goStudyLogo}
                                            alt="ForexWorldWide Logo"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        ABN 22 147 363 175
                                        <br />
                                        AFSL 401379
                                        <br />
                                        Level 4, 100 Collins Street
                                        <br />
                                        Melbourne Vic 3000
                                        <br />
                                        <br />E admin@forexworldwide.com
                                        <br />T +61 (03) 9008 1880
                                        <br />W www.forexworldwide.com
                                        <br />
                                    </td>
                                    <td>
                                        ABN 000 000 000 <br />
                                        <br />
                                        000 <br />
                                        000
                                        <br />
                                        <br />E abc@abc.com
                                        <br />T +61 (03) 0000 0000
                                        <br />F +61 (03) 0000 0000
                                        <br />W www.gostudy.com.au
                                        <br />
                                    </td>
                                </tr>
                            </table>
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) in Australia and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            case '24':
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img src={forexSportLogo} alt="ForexSport Logo" />
                            <br />
                            <br />
                            <strong>Forex Sport</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexsport.com
                            <br />T +61 (03) 9008 1880
                            <br />W www.forexsport.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) in Australia and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            case '29':
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img src={forexSportLogo} alt="ForexSport Logo" />
                            <br />
                            <strong>Forex Sport</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexsport.com
                            <br />T +61 (03) 9008 1880
                            <br />W www.forexsport.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) in Australia and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            default:
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img
                                src={forexWorldWideLogo}
                                alt="ForexWorldWide Logo"
                            />
                            <br />
                            <strong>Forex WorldWide</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexworldwide.com
                            <br />T +61 (03) 9008 1880
                            <br />W www.forexworldwide.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) in Australia and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
        }
    }

    render() {
        const { classes } = this.props

        if (!this.props.app_state.current_client) {
            return null
        }
        if (this.props.app_state.current_client.account_status == 1) {
            this.props.history.push('/pending-registration')
        }
        if (this.props.app_state.current_client.account_status == 5) {
            this.props.history.push('/registration')
        }

        if (this.props.app_state.current_client.account_status == 6) {
            this.props.history.push('/migrate')
        }

        // let main_component = null
        // main_component = <TrackTransfer prefill={this.props.match.params.prefill}/>

        if (!this.state.transfer)
            return (
                <React.Fragment>
                    <strong>Error: Unable to access transfer</strong>
                </React.Fragment>
            )
        if (!this.state.currency_from) return null
        if (!this.state.currency_to) return null

        // Branding for top of sidebar (based on app_state.current_domain
        // ************************************************************************************************
        let logo = require(`../../../assets/img/receipts/${
            this.props.app_state.current_domain.domain_receipt_brand_image
        }`)
        // ************************************************************************************************

        // console.log(this.props)
        // console.log(this.state)
        // console.log(this.state.transfer)
        // console.log(this.state.payouts_list)
        // console.log(this.props);

        // let payouts = <span />
        // let str1 = "";
        // for (let i = 0, l = this.state.payouts_list.length; i < l; i++) {
        //   // str1 = str1.concat(`<tr><td width=\"30%\"><strong>${payouts_list[i].nickname}</strong></td><td>${currency_to.iso_alpha_3} ${Number(payouts_list[i].amount_to)}</td><td>${payouts_list[i].description}</td><td>${payouts_list[i].detail_1}</td></tr>`);
        //   str1 = str1.concat(`<tr><td><strong>${this.state.payouts_list[i].nickname}</strong><br/> ${
        //     this.state.payouts_list[i].ben_address_line_1
        //       ? this.state.payouts_list[i].ben_address_line_1
        //       : ""} ${
        //         this.state.payouts_list[i].ben_address_suburb
        //       ? this.state.payouts_list[i].ben_address_suburb
        //       : ""
        //     }<br/><br/></td><td>${this.state.transfer.currency_to_iso_alpha_3} ${Number(this.state.payouts_list[i].amount_to)}</td><td>${this.state.payouts_list[i].detail_1}</td></tr>`);
        // str1 = str1.concat(`<tr><td></td><td>${payouts_list[i].description} : ${payouts_list[i].detail_1}</td></tr>`);

        // str1 = str1.concat(`<tr><td></td><td colspan="3">
        //   ${payouts_list[i].ben_address_line_1 ? "Beneficiary Address: " + payouts_list[i].ben_address_line_1 + " " + payouts_list[i].ben_address_suburb + "<br/>": ""}
        //   ${payouts_list[i].bank_legal_name ? "Beneficiary Bank: " + payouts_list[i].bank_legal_name + "<br/>": ""}
        //   ${payouts_list[i].swift_code ? "SWIFT Code: " + payouts_list[i].swift_code + "<br/>": ""}
        //   ${payouts_list[i].iban ? "IBAN: " + payouts_list[i].iban + "<br/>": ""}
        //   ${payouts_list[i].bsb_code ? "BSB: " + payouts_list[i].bsb_code + "<br/>": ""}
        //   ${payouts_list[i].sort_code ? "Sort Code: " + payouts_list[i].sort_code + "<br/>": ""}
        //   ${payouts_list[i].aba_routing_number ? "ABA Routing: " + payouts_list[i].aba_routing_number + "<br/>": ""}
        //   ${payouts_list[i].transit_code ? "Transit Code: " + payouts_list[i].transit_code + "<br/>": ""}
        //   ${payouts_list[i].bank_code ? "Bank Code: " + payouts_list[i].bank_code + "<br/>": ""}
        //   ${payouts_list[i].account_number ? "Account: " + payouts_list[i].account_number + "<br/>": ""}
        //   </td></tr>`);
        // str1 = str1.concat(`<tr><td colspan="4" style="color:e6e6e6;"><hr/></td></tr>`);
        // }
        // payouts = payouts.concat(str1);

        let adjusted_remittance_reference = this.props.app_state.current_client.id
        if (this.state.entity_bank_account && this.state.entity_bank_account.reference_mandatory) {
            adjusted_remittance_reference = this.state.entity_bank_account
                .reference_mandatory
        } else if (this.state.entity_bank_account && this.state.entity_bank_account.reference_prefix) {
            adjusted_remittance_reference =
                this.state.entity_bank_account.reference_prefix +
                this.props.app_state.current_client.id
        // } else {
        //     adjusted_remittance_reference =
        //         'FS' +
        //         this.props.app_state.current_client.id
        //             .toString()
        //             .padStart(5, '0')
        }

        const remittanceTotal = Number(this.state.transfer.amount_from);
        const feeVisibleAmount = Number(this.state.transfer.fee_visible_amount);
        const totalRemittance = remittanceTotal + feeVisibleAmount;

        return (
            <React.Fragment>
                <div>
                    <GridContainer alignItems="stretch">
                        <GridItem xs={12}>
                            <Card style={{ height: '95%' }}>
                                <CardHeader color="info" stats icon />
                                <CardBody>
                                    <GridContainer justify="center">
                                        <GridItem xs={12} md={10}>
                                            <div >
                                                <Button
                                                    color="primary"
                                                    // style={{ float: 'right' }}
                                                    onClick={() =>
                                                        this.printDocument()
                                                    }
                                                >
                                                    Save PDF
                                                </Button>
                                                <br/>
                                                {/* <Input
                                                    id="sendToAddress"
                                                    value={this.state.sendToAddress}
                                                /> */}
                                            </div>
                                        </GridItem>
                                    </GridContainer>

                                    <div
                                        id="divToPrint"
                                        className="mt4"
                                        style={{
                                            hidden: 'hidden',

                                            width: '210mm',
                                            minHeight: '297mm',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            padding: '10mm',
                                        }}
                                    >
                                        <img
                                            src={logo}
                                            alt="logo"
                                            className={classes.img}
                                        />
                                        <h3 className={classes.pageHeadings}>
                                            {
                                                this.props.app_state
                                                    .current_domain.domain_text
                                            }{' '}
                                            {this.state.currency_from.iso_alpha_3.toUpperCase()}{' '}
                                            Settlement Instructions
                                        </h3>
                                        <br />

                                        <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                <h5 className={classes.pageSubHeadings}>
                                                    Transaction Details
                                                </h5>
                                                <div
                                                    style={{
                                                        border:
                                                            '1px solid #a1a1a1',
                                                    }}
                                                >
                                                    <table style={{width: '100%'}}>
                                                        <tbody
                                                        >
                                                            <tr className={classes.backgroundBlue}>
                                                            <th className={classes.tableHeaders}>
                                                                    You Sell
                                                                </th>
                                                                <td className={classes.tableCells}>
                                                                    {this.state
                                                                        .transfer
                                                                        .amount_from ? (
                                                                        <React.Fragment
                                                                        >
                                                                            {this.props.intl.formatNumber(
                                                                                this
                                                                                    .state
                                                                                    .transfer
                                                                                    .amount_from,
                                                                                {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                }
                                                                            )}&nbsp;
                                                                            <img
                                                                                src={`https://wise.com/public-resources/assets/flags/rectangle/${this.state.currency_from.iso_alpha_3.toLowerCase()}.png`}
                                                                                width='24px'
                                                                                height='16px'
                                                                                style={{verticalAlign: 'middle', marginBottom: '4px'}}
                                                                            />
                                                                            &nbsp;
                                                                            {this.state.currency_from.iso_alpha_3.toUpperCase()}{' '}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </td>
                                                            </tr>   
                                                            <tr>
                                                                <th className={classes.tableHeaders}>
                                                                    You Buy
                                                                </th>
                                                                <td className={classes.tableCells}>
                                                                    {this.state
                                                                        .transfer
                                                                        .amount_to ? (
                                                                        <React.Fragment
                                                                        >
                                                                            {this.props.intl.formatNumber(
                                                                                this
                                                                                    .state
                                                                                    .transfer
                                                                                    .amount_to,
                                                                                {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                }
                                                                            )}&nbsp;
                                                                            <img
                                                                                src={`https://wise.com/public-resources/assets/flags/rectangle/${this.state.currency_to.iso_alpha_3.toLowerCase()}.png`}
                                                                                width='24px'
                                                                                height='16px'
                                                                                style={{verticalAlign: 'middle', marginBottom: '4px'}}
                                                                            />
                                                                            &nbsp;
                                                                            {this.state.currency_to.iso_alpha_3.toUpperCase()}{' '}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr className={classes.backgroundBlue}>
                                                                <th className={classes.tableHeaders}>
                                                                    Exchange Rate
                                                                </th>
                                                                <td className={classes.tableCells}>
                                                                    {this.state
                                                                        .transfer
                                                                        .client_rate ? (
                                                                        <React.Fragment
                                                                        >
                                                                            {this.props.intl.formatNumber(
                                                                                this
                                                                                    .state
                                                                                    .transfer
                                                                                    .client_rate,
                                                                                {
                                                                                    minimumFractionDigits: 4,
                                                                                    maximumFractionDigits: 4,
                                                                                }
                                                                            )}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className={classes.tableHeaders}>
                                                                    Settlement Date
                                                                </th>
                                                                <td className={classes.tableCells}>
                                                                    {this.state
                                                                        .transfer
                                                                        .settlement_date ? (
                                                                        <React.Fragment
                                                                        >
                                                                            {moment(
                                                                                this
                                                                                    .state
                                                                                    .transfer
                                                                                    .settlement_date
                                                                            ).format(
                                                                                'DD/MM/YYYY'
                                                                            )}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br />
                                                <p>
                                                    Your foreign exchange
                                                    transaction is now in process
                                                    and awaiting settleinment.
                                                </p>
                                                
                                                {this.state.entity_bank_account ?  ( 
                                                <p>
                                                Please remit your{' '}
                                                <strong>
                                                {totalRemittance.toFixed(2)}{` `}
                                                </strong>
                                                <img
                                                    src={`https://wise.com/public-resources/assets/flags/rectangle/${this.state.currency_from.iso_alpha_3.toLowerCase()}.png`}
                                                    width='24px'
                                                    height='16px'
                                                    style={{verticalAlign: 'middle', marginBottom: '4px'}}
                                                />
                                                <strong>
                                                {
                                                    this.state.currency_from
                                                        .full_name
                                                }{` `}
                                                </strong>
                                                to our Client Settlement Account
                                                {` `}
                                                using your <strong> Mandatory Reference: {adjusted_remittance_reference}</strong>.{`  `}

                                                {feeVisibleAmount ? (
                                                    <>
                                                        This amount includes the conversion amount of {remittanceTotal.toFixed(2)} and a
                                                        fee of {feeVisibleAmount.toFixed(2)}.
                                                    </>
                                                ) : ''}
                                                </p>
                                                ) : ''}

                                                <br />

                                                {this.state.entity_bank_account && ( 
                                                    <>
                                                <h5
                                                    className={
                                                        classes.pageSubHeadings
                                                    }
                                                >
                                                    Bank Account Details
                                                </h5>
                                                <div
                                                    style={{
                                                        border:
                                                            '1px solid #a1a1a1',
                                                    }}
                                                >
                                                    <table
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <tbody>



                                                        <tr className={classes.backgroundBlue}>
                                                            <th className={classes.tableHeaders}>
                                                                Account Name
                                                            </th>
                                                            <td className={classes.tableCells}>
                                                                {this.state.entity_bank_account.eba_accout_name && (
                                                                    <span>
                                                                        {this.state.entity_bank_account.eba_accout_name}
                                                                    </span>
                                                                )} 
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th className={classes.tableHeaders}>
                                                                Account Address
                                                            </th>
                                                            <td className={classes.tableCells}>
                                                                <React.Fragment>
                                                                    {this.state.entity_bank_account.address_line_1}{` `}
                                                                    {this.state.entity_bank_account.address_line_2}{` `}
                                                                    {this.state.entity_bank_account.address_suburb}{` `}
                                                                    {this.state.entity_bank_account.address_state}{` `}
                                                                    {this.state.entity_bank_account.address_postcode}<br/>
                                                                    {this.state.entity_bank_account.entity_bank_account_address_country}
                                                                </React.Fragment>
                                                            </td>
                                                        </tr>

                                                        <tr className={classes.backgroundBlue}>
                                                    <th className={classes.tableHeaders}>
                                                        Bank Name
                                                    </th>
                                                    <td className={classes.tableCells}>
                                                        <React.Fragment>
                                                            <strong>
                                                                {this.state.entity_bank.eb_long_name}
                                                            </strong>
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className={classes.tableHeaders}>
                                                        Bank Address
                                                    </th>
                                                    <td className={classes.tableCells}>
                                                        <React.Fragment>
                                                            {this.state.entity_bank.address_line_1}{` `}
                                                            {this.state.entity_bank.address_line_2}{` `}
                                                            {this.state.entity_bank.address_suburb}{` `}
                                                            {this.state.entity_bank.address_state}{` `}
                                                            {this.state.entity_bank.address_postcode}<br/>
                                                            {this.state.entity_bank.entity_bank_account_address_country}
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                                {this.state.entity_bank_account.eba_swift && 
                                                <tr className={classes.backgroundBlue}>
                                                    <th className={classes.tableHeaders}>
                                                    SWIFT Code
                                                    </th>
                                                    <td className={classes.tableCells}>
                                                    <React.Fragment>
                                                    {this.state.entity_bank_account.eba_swift}
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                                }
                                                {this.state.entity_bank_account.eba_sortcode && 
                                                <tr className={classes.backgroundBlue}>
                                                    <th className={classes.tableHeaders}>
                                                        Sort Code
                                                    </th>
                                                    <td className={classes.tableCells}>
                                                        <React.Fragment>
                                                            {this.state.entity_bank_account.eba_sortcode}
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                                }
                                                {this.state.entity_bank_account.eba_bsb && 
                                                <tr>
                                                    <th className={classes.tableHeaders}>
                                                        BSB
                                                    </th>
                                                    <td className={classes.tableCells}>
                                                        <React.Fragment>
                                                            {this.state.entity_bank_account.eba_bsb}
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                                }
                                                {this.state.entity_bank_account.aba_routing_number && 
                                                <tr className={classes.backgroundBlue}>
                                                    <th className={classes.tableHeaders}>
                                                        ABA Routing
                                                    </th>
                                                    <td className={classes.tableCells}>
                                                        <React.Fragment>
                                                            {this.state.entity_bank_account.aba_routing_number}
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                                }
                                                {this.state.entity_bank_account.eba_accout_number && 
                                                <tr className={classes.backgroundBlue}>
                                                    <th className={classes.tableHeaders}>
                                                        Account Number
                                                    </th>
                                                    <td className={classes.tableCells}>
                                                        <React.Fragment>
                                                            {this.state.entity_bank_account.eba_accout_number}
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                                }
                                                {this.state.entity_bank_account.iban && 
                                                <tr>
                                                    <th className={classes.tableHeaders}>
                                                        IBAN
                                                    </th>
                                                    <td className={classes.tableCells}>
                                                        <React.Fragment>
                                                            {this.state.entity_bank_account.iban}
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                                }

                                                        </tbody>
                                                    </table>
                                                </div>

                                                </>)}

                                                <br/>
                                                <p>
                                                    If any of the above details are incorrect, please contact us immediately.
                                                </p>

                                            </GridItem>
                                        </GridContainer>
                                        {/* <hr /> */}
                                        <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                {' '}
                                                <div>
                                                    {' '}
                                                    {this.getSignatureLine(
                                                        `${
                                                            this.props
                                                                .current_user
                                                                .team_id
                                                                ? this.props
                                                                      .current_user
                                                                      .team_id
                                                                : 0
                                                        }`
                                                    )}{' '}
                                                </div>{' '}
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </CardBody>
                                <CardFooter />
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

// const mapDispatchToProps = (dispatch) => {
// return {
//   onLoginSuccess: (user_id) => {
//     dispatch(onLoginSuccess(user_id));
//   }
// };
// };

const TransfersRemittancePageContainer = connect(
    mapStateToProps
    // mapDispatchToProps
)(injectIntl(withRouter(withStyles(style)(TransfersRemittancePage))))

export default TransfersRemittancePageContainer
