import React from 'react'
import { connect } from 'react-redux'
import {
    clearNewTransfer,
    // fetchTransferListRequest,
    // fetchTransferListSuccess,
    // fetchTransferList,
    // fetchTransferDetails
    // createNewTransferInDatabase,
    // createNewTransfer,
    // selectTransferUi,
    // setTransferSelectId,
    // loadTransferDetailsRequest,
    // loadTransferDetailsSuccess,
    // startNewTransferCreation,
    // updateNewTransferCreationTransferType,
    // updateNewTransferCreation,
    // newTransferCreationDatabaseStart,
    // newTransferCreationDatabaseSuccess
} from '../../redux/actions/transfers_dashboard'

import withStyles from '@material-ui/core/styles/withStyles'
import dashboardStyle from '../../views/Pages/DashboardPageStyle'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'

import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'

//core for react-intl
import { FormattedMessage, injectIntl } from 'react-intl'
import CustomReactSelect from '../Forms/CustomReactSelect/CustomReactSelect'
import { VectorMap } from 'react-jvectormap'
import { API } from 'aws-amplify'
import CustomInput from 'components/CustomInput/CustomInput.jsx'

import Table from 'components/Table/Table.jsx'
import { withRouter } from 'react-router-dom'

const moment = require('moment')

class DashboardTransferStep3 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            current_step: 3,
            // youSell: "AUD: 7,373.98",
            // youBuy: "USD: 5,0000.00",
            // exchangeRate: "0.67806",
            // beneficiary: "No Beneficiary Selected",
            // purposeOfPayment: "Payment for goods/services",
            // invoiceNo: "123",
            // holdingAccount: "No holding account.",
            // requestSummary: "AUD buys USD at a rate of 0.67806 resulting in an estimated reutrn of 5,000.00 USD"
        }
    }

    componentDidMount() {
        //this.startTimer();
        this.stopTimer()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //this.stopTimer();
        // if prefill changes, clear new transfer data and then go back to the first step
        if (prevProps.prefill !== this.props.prefill) {
            // clearNewTransfer in DashboardTransfer
            this.props.switch_to_step1()
        }
    }

    // secondsToTime = (secs) => {
    //   let hours = Math.floor(secs / (60 * 60));

    //   let divisor_for_minutes = secs % (60 * 60);
    //   let minutes = Math.floor(divisor_for_minutes / 60);

    //   let divisor_for_seconds = divisor_for_minutes % 60;
    //   let seconds = Math.ceil(divisor_for_seconds);

    //   let obj = {
    //     "h": hours,
    //     "m": minutes,
    //     "s": seconds
    //   };
    //   return obj;
    // };

    // startTimer = () => {
    //   //console.log(this.props.transfers_dashboard.current_step === 2);
    //   if (this.props.transfers_dashboard.current_step !== 2) {
    //     this.go = false;
    //   }

    //   if (this.timer === 0 && this.state.seconds > 0) {
    //     this.timer = setInterval(this.countDown(this.go), 1000);
    //     //this.props.updateNewTransferCreation("current_rate_locked", false);
    //   }
    // };

    // countDown = () => {
    //   //go means this is step 2, we start count down

    //   let seconds = this.state.seconds - 1;
    //   //console.log(this.go);
    //   if (this.go) {
    //     this.setState({
    //       time: this.secondsToTime(seconds),
    //       seconds: seconds
    //     });
    //     //this.props.updateNewTransferCreation("current_rate_ttl", seconds);

    //     if (seconds === 0) {
    //       this.setState({
    //         seconds: 30
    //       });
    //       //this.props.updateNewTransferCreation("current_rate_ttl", 30);
    //       //this.fetchNewRate();
    //     }
    //     console.log(this.state.seconds);
    //   }
    // };

    stopTimer = () => {
        this.go = false
        this.setState({
            seconds: 0,
            time: 0,
        })
        clearInterval(this.timer)
        this.timer = null
    }

    handle_email_beneficiary() {
        // console.log("handle_email_beneficiary")
    }

    handle_new_transfer() {
        this.props.clearNewTransfer()
        this.props.switch_to_step1()
        // this.props.history.push(`/dashboard`);
    }

    handle_view_transfers() {
        this.props.history.push(`/transfers/list`)
    }

    render() {
        const { classes } = this.props

        let request_summary = `You sell
      ${this.props.intl.formatNumber(
          this.props.transfers_dashboard.new_transfer_data.amount_from,
          {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }
      )} ${this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}
      to buy ${this.props.intl.formatNumber(
          this.props.transfers_dashboard.new_transfer_data.amount_to,
          {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }
      )} ${this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}
      `

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <h4>
                        New Transer Request - Submitted and pending confirmation
                    </h4>
                    <p>
                        Thank you! Your FX transfer request has been submitted.{' '}
                        <br />
                        You can view the status of your FX transactions in
                        Transfers. <br />
                        Confirmation has been emailed to{' '}
                        {this.props.app_state.current_client.email}
                    </p>
                    {this.props.transfers_dashboard.new_transfer_data
                        .payment_method === 'worldpay' && (
                        <React.Fragment>
                            <br />
                            <p>
                                <strong>
                                    Please{' '}
                                    <a
                                        href={
                                            this.props.transfers_dashboard
                                                .new_transfer_data
                                                .payment_method_url
                                        }
                                    >
                                        Click Here
                                    </a>{' '}
                                    to make payment now via our secure payment
                                    platform.
                                </strong>
                            </p>
                        </React.Fragment>
                    )}
                </GridItem>
                <GridItem>
                    <Table
                        tableData={[
                            [
                                'You Sell',
                                <React.Fragment>
                                    <div
                                        className={`currency-flag currency-flag-${this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toLowerCase()}`}
                                    />{' '}
                                    {this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}{' '}
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard
                                            .new_transfer_data.amount_from,
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'You Buy',
                                <React.Fragment>
                                    <div
                                        className={`currency-flag currency-flag-${this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toLowerCase()}`}
                                    />{' '}
                                    {this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}{' '}
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard
                                            .new_transfer_data.amount_to,
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'Exchange Rate',
                                <React.Fragment>
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard
                                            .new_transfer_data.client_rate,
                                        {
                                            minimumFractionDigits: 5,
                                            maximumFractionDigits: 5,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'Beneficiary',
                                <React.Fragment>
                                    {this.props.transfers_dashboard
                                        .new_transfer_data
                                        .multiple_beneficiary_id[0]
                                        .checked_beneficiary_details ===
                                    true ? (
                                        <React.Fragment>
                                            To Be Advised
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {
                                                this.props.transfers_dashboard
                                                    .new_transfer_data
                                                    .multiple_beneficiary_id[0]
                                                    .beneficiary.ben_legal_name
                                            }{' '}
                                            <br />
                                            {/* Paying to {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bank_legal_name} */}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'Purpose of Payment',
                                `${
                                    this.props.transfers_dashboard
                                        .new_transfer_data
                                        .multiple_beneficiary_id[0]
                                        .purpose_of_payment_detail_nickname
                                }`,
                            ],
                            [
                                'Payment Detail',
                                `${
                                    this.props.transfers_dashboard
                                        .new_transfer_data
                                        .multiple_beneficiary_id[0].detail_1
                                }`,
                            ],
                            ['Request Summary', `${request_summary}`],
                        ]}
                    />
                </GridItem>
            </GridContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
        transfers_dashboard: state.transfers_dashboard,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearNewTransfer: () => {
            dispatch(clearNewTransfer())
        },
    }
}

const DashboardTransferStep3Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(DashboardTransferStep3))

// export default DashboardTransferStep3Container;
export default withRouter(injectIntl(DashboardTransferStep3Container))
