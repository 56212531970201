import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { VectorMap } from 'react-jvectormap'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Warning from '@material-ui/icons/Warning'
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import Danger from 'components/Typography/Danger.jsx'
import Tabs from 'components/CustomTabs/CustomTabs.jsx'

import dashboardStyle from './DashboardPageStyle'
import { API } from 'aws-amplify'
import EconomicCalender from '../../components/EconomicInformation/EconomicCalender'
import Cloud from '@material-ui/core/SvgIcon/SvgIcon'
import FxCharts from '../../components/EconomicInformation/FxCharts'
import FxRates from '../../components/EconomicInformation/FxRates'
import EconomicHoliday from '../../components/EconomicInformation/EconomicHoliday'

import SearchTransfer from '../../components/SearchTransfer/SearchTransfer'

const axios = require('axios')

// const us_flag = require("assets/img/flags/US.png");
// const au_flag = require("assets/img/flags/AU.png");
// const gb_flag = require("assets/img/flags/GB.png");

// var systemNews = [
//   "Alpha Testing: Staff",
//   "Alpha Testing: Clients",
//   "Implementing: Beneficiaries",
//   "Development: Transfers"
// ];

const min_transfer_amount = 0

// let language = navigator.language.split(/[-_]/)[0];

class SearchTransferPage extends React.Component {
    state = {
        value: 0,
        currencies_list_priority: [],
        map_data: {},
        beneficiaries_list: [],
        beneficiary_id: '',
        transfer_heading: 'Current Transfers',
    }

    componentDidMount() {
        API.get('currencies', `/currencies/get-list-priority`)
            .then(response => {
                this.setState({
                    // currencies_list: response.fullList,
                    currencies_list_priority: response,
                })
            })
            .catch(error => {
                console.log(error)
            })

        API.get('beneficiaries', `/beneficiaries/get`)
            .then(response => {
                this.setState({
                    beneficiaries_list: response,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }

    // function that verifies if a value is larger than a number or not
    isLargerThanNumber(value, number) {
        if (value !== '' && value >= number) {
            return true
        }
        return false
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'isLargerThanNumber':
                if (
                    this.isLargerThanNumber(
                        event.target.value,
                        stateNameEqualTo
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
        // Calculate "to amount"
        if (stateName === 'from_amount') {
            this.setState({ last_user_type_amount_name: 'from_amount' })
            this.handleAmount('to_amount')
        }
        // Calculate "from amount"
        if (stateName === 'to_amount') {
            this.setState({ last_user_type_amount_name: 'to_amount' })
            this.handleAmount('from_amount')
        }

        // if (stateName === "amount_to") {
        //   this.fetchNewRate()
        // }
    }

    handleAmount(amount_name) {
        let from_currency_Iso = ''
        let to_currency_Iso = ''

        this.state.currencies_list_priority.map((item, index) => {
            if (item.id == this.state.from_currency) {
                from_currency_Iso = item.iso_alpha_3
            }
            if (item.id == this.state.to_currency) {
                to_currency_Iso = item.iso_alpha_3
            }
        })

        let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
        axios
            .get(url, {
                params: {
                    currencies: to_currency_Iso,
                    source: from_currency_Iso,
                    format: '1',
                },
            })
            .then(response => {
                let key = from_currency_Iso + to_currency_Iso
                let rate_string = response.data.quotes[key]

                let rate = Number.parseFloat(rate_string)

                let rate_adjust =
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client
                            .default_rate_division
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_entity
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_team
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_staff
                    )
                let adjusted_rate = rate - rate * rate_adjust
                if (this.state.from_currency == this.state.to_currency) {
                    adjusted_rate = rate
                }

                if (
                    this.state.from_currency_state === 'success' &&
                    this.state.to_currency_state === 'success'
                ) {
                    // handle to_amount
                    if (
                        amount_name === 'to_amount' &&
                        this.state.from_amount_state === 'success'
                    ) {
                        let amount_from = Number.parseFloat(
                            this.state.from_amount
                        )
                        let amount_to = amount_from * adjusted_rate

                        this.setState({ ['to_amount']: amount_to.toFixed(2) })
                        if (amount_to >= min_transfer_amount) {
                            this.setState({ ['to_amount_state']: 'success' })
                        } else {
                            this.setState({ ['to_amount_state']: 'error' })
                        }
                    }

                    // handle from_amount
                    if (
                        amount_name === 'from_amount' &&
                        this.state.to_amount_state === 'success'
                    ) {
                        let amount_to = Number.parseFloat(this.state.to_amount)
                        let amount_from = amount_to / adjusted_rate

                        this.setState({
                            ['from_amount']: amount_from.toFixed(2),
                        })
                        if (amount_from >= min_transfer_amount) {
                            this.setState({ ['from_amount_state']: 'success' })
                        } else {
                            this.setState({ ['from_amount_state']: 'error' })
                        }
                    }
                }

                this.setState({ ['adjusted_rate']: adjusted_rate })
                // Save currency name into state for passing query strings
                this.setState({ ['from_currency_name']: from_currency_Iso })
                this.setState({ ['to_currency_name']: to_currency_Iso })
            })
            .catch(error => {
                console.log(error)
            })
    }

    handleChange = (event, value) => {
        this.setState({ value })
    }
    handleChangeIndex = index => {
        this.setState({ value: index })
    }

    handleCustomReactSelectChange = (
        name,
        previous_from_currency,
        previous_to_currency
    ) => value => {
        var option_value
        if (value === null) {
            option_value = null
        } else {
            option_value = value.value
        }
        this.setState(
            {
                [name]: option_value,
            },
            () => {
                switch (this.state.last_user_type_amount_name) {
                    case 'from_amount':
                        this.handleAmount('to_amount')
                        break
                    case 'to_amount':
                        this.handleAmount('from_amount')
                        break
                    default:
                        this.handleAmount()
                }
            }
        )
        this.setState({ [name + '_state']: 'success' })
        if (name === 'from_currency' || name === 'to_currency') {
            this.updateMapData(
                name,
                previous_from_currency,
                previous_to_currency,
                value
            )
        }
    }

    updateMapData(
        currency_name,
        previous_from_currency,
        previous_to_currency,
        currency_object
    ) {
        var map_values = this.state.map_data
        const from_currency_map_value = 1
        const to_currency_map_value = 1
        // update the properties for visulization
        if (currency_name === 'from_currency') {
            // delele previous currency property in map_values
            if (previous_from_currency !== previous_to_currency) {
                this.deleleCurrency(
                    map_values,
                    previous_from_currency,
                    previous_to_currency
                )
            }
            // add new currency property in map_values
            this.addCurrency(
                map_values,
                currency_object.value,
                from_currency_map_value
            )
        }
        if (currency_name === 'to_currency') {
            // delele previous currency property in map_values
            if (previous_to_currency !== previous_from_currency) {
                this.deleleCurrency(
                    map_values,
                    previous_to_currency,
                    previous_from_currency
                )
            }
            // add new currency property in map_values
            this.addCurrency(
                map_values,
                currency_object.value,
                to_currency_map_value
            )
        }
        this.setState({ map_data: map_values })
    }

    deleleCurrency(map_values, currency_deleted, the_other_currency) {
        switch (currency_deleted) {
            case 1: // AUD
                delete map_values.AU // Australia
                break
            case 5: // USD
                delete map_values.US // United States
                break
            case 8: // GBP
                // if the other currency is not Euro, delete GB
                if (the_other_currency !== 9) {
                    delete map_values.GB // United Kingdom
                }
                break
            case 9: // Eur. For Europe: italy, spain, france, portugal, germany
                // if the other currency is not GB, delete GB
                if (the_other_currency !== 8) {
                    delete map_values.GB // United Kingdom
                }
                delete map_values.IT // Italy
                delete map_values.ES // Spain
                delete map_values.FR // France
                delete map_values.PT // Portugal
                delete map_values.DE // Germany
                break
            case 6: // ATS
                delete map_values.AT // 	Austria
                break
            default:
        }
    }

    addCurrency(map_values, currency, currency_map_value) {
        switch (currency) {
            case 1:
                map_values.AU = currency_map_value
                break
            case 5:
                map_values.US = currency_map_value
                break
            case 8:
                map_values.GB = currency_map_value
                break
            case 9:
                map_values.GB = currency_map_value
                map_values.IT = currency_map_value
                map_values.ES = currency_map_value
                map_values.FR = currency_map_value
                map_values.PT = currency_map_value
                map_values.DE = currency_map_value
                break
            case 6:
                map_values.AT = currency_map_value
                break
            default:
        }
    }

    render_account_warning() {
        switch (this.props.app_state.current_client.account_status) {
            case 1:
                return (
                    <React.Fragment>
                        <Danger>
                            <Warning /> Account is not yet activated.
                        </Danger>
                        <br />
                        <p>
                            This account has not yet been activated. Please{' '}
                            <a href="/user-profile?display=todo">click here </a>
                            for more information.
                        </p>
                    </React.Fragment>
                )
            default:
                return null
        }
    }

    renderMapComponent() {
        return (
            <VectorMap
                map={'world_mill'}
                backgroundColor="transparent"
                zoomOnScroll={false}
                containerStyle={{
                    width: '100%',
                    height: '420px',
                }}
                containerClassName="map"
                regionStyle={{
                    initial: {
                        fill: '#e4e4e4',
                        'fill-opacity': 0.9,
                        stroke: 'none',
                        'stroke-width': 0,
                        'stroke-opacity': 0,
                    },
                }}
                series={{
                    regions: [
                        {
                            values: this.state.map_data,
                            scale: ['#ffffff', '#26c6da'],
                            normalizeFunction: 'polynomial',
                        },
                    ],
                }}
            />
        )
    }

    getTabsInfo() {
        var ec_info_economicnews = this.props.app_state.current_client
            .ec_info_economicnews
        var tabsContent = []
        // var tmp = {};
        if (ec_info_economicnews == 1) {
            tabsContent.push({
                tabName: 'Economic Calendar',
                tabIcon: Cloud,
                tabContent: <EconomicCalender />,
            })
        }

        var ec_info_dashboard_charts = this.props.app_state.current_client
            .ec_info_dashboard_charts
        if (ec_info_dashboard_charts == 1) {
            tabsContent.push({
                tabName: 'FX Charts',
                tabIcon: Cloud,
                tabContent: <FxCharts />,
            })
        }

        var economicholidays = this.props.app_state.current_client
            .ec_info_economicholidays

        if (economicholidays == 1) {
            tabsContent.push({
                tabName: 'Economic Holidays',
                tabIcon: Cloud,
                tabContent: <EconomicHoliday />,
            })
        }

        var ec_info_rates = this.props.app_state.current_client.ec_info_rates
        if (ec_info_rates == 1) {
            tabsContent.push({
                tabName: 'Fx Rates',
                tabIcon: Cloud,
                tabContent: <FxRates />,
            })
        }

        return <Tabs title="" headerColor="info" tabs={tabsContent} />
    }

    toggleChecked(checked) {
        if (checked) {
            this.setState({
                checked: false,
                toggleTransfer: '8',
                transfer_heading: 'Past Transfers',
            })
            return false
        }
        this.setState({
            checked: true,
            toggleTransfer: '123456',
            transfer_heading: 'Current Transfers',
        })
        return true
    }

    render() {
        // const { classes } = this.props;

        if (!this.props.app_state.current_client) {
            return null
        }
        if (this.props.app_state.current_client.account_status == 1) {
            this.props.history.push('/pending-registration')
        }
        if (this.props.app_state.current_client.account_status == 5) {
            this.props.history.push('/registration')
        }

        if (this.props.app_state.current_client.account_status == 6) {
            this.props.history.push('/migrate')
        }

        let main_component = null
        main_component = (
            <SearchTransfer prefill={this.props.match.params.prefill} />
        )

        return (
            <React.Fragment>
                <div>
                    <GridContainer alignItems="stretch">
                        {main_component}
                        {/* {main_component_temp} */}
                    </GridContainer>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

// const mapDispatchToProps = (dispatch) => {
// return {
//   onLoginSuccess: (user_id) => {
//     dispatch(onLoginSuccess(user_id));
//   }
// };
// };

const SearchTransferPageContainer = connect(
    mapStateToProps
    // mapDispatchToProps
)(withRouter(withStyles(dashboardStyle)(SearchTransferPage)))

export default SearchTransferPageContainer
