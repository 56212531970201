export default {
    s3: {
        REGION: 'ap-southeast-2',
        BUCKET: 'com.forexworldwide.crm.documents.01',
    },
    apiGateway: {
        REGION: 'ap-southeast-2',
        URL: 'https://bptegaq1p6.execute-api.ap-southeast-2.amazonaws.com/dev',
        // URL: "https://vva088v4o3.execute-api.ap-southeast-2.amazonaws.com/dev/users"
    },
    cognito: {
        REGION: 'ap-southeast-2',
        USER_POOL_ID: 'ap-southeast-2_pLZ4EKm2d',
        APP_CLIENT_ID: '36hpfb5vkdiqp59ptdj6vtccks',
        IDENTITY_POOL_ID: 'ap-southeast-2:8f95960b-dcd6-44c7-80aa-6ab6dbf8ece0',
    },
    social: {
        FB: '2109747472475981',
    },
}
