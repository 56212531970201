import React from 'react'
import { Radio } from 'antd'

const tabOptions = ['aud', 'eur', 'gbp', 'usd']

const CurrencyTabs = ({ selected, onTabClick }) => {
    return (
        <Radio.Group value={selected} buttonStyle="solid" onChange={onTabClick}>
            {tabOptions.map(option => {
                return (
                    <Radio.Button key={option} value={option}>
                        <div
                            className={`currency-flag currency-flag-${option}`}
                        />
                    </Radio.Button>
                )
            })}
        </Radio.Group>
    )
}

export default CurrencyTabs
