// ##############################
// // // Header styles
// #############################

import {
    containerFluid,
    defaultFont,
    primaryColor,
    defaultBoxShadow,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
} from 'assets/jss/material-dashboard-pro-react.jsx'

const headerStyle = theme => ({
    appBar: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderBottom: '0',
        marginBottom: '0',
        position: 'absolute',
        width: '100%',
        paddingTop: '10px',
        zIndex: '500',
        color: '#555555',
        border: '0',
        borderRadius: '3px',
        padding: '10px 0',
        transition: 'all 150ms ease 0s',
        minHeight: '50px',
        display: 'block',
    },
    container: {
        ...containerFluid,
        minHeight: '50px',
    },
    flex: {
        flex: 1,
    },
    title: {
        left: '0%',
        right: '0%',
        top: '0%',
        bottom: '0%',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 42,
        lineHeight: '133%',
        letterSpacing: '-0.01em',
        color: '#131313',
        fontFeatureSettings: `'tnum' on, 'lnum' on`,
        '@media (max-width: 1100px)': {
            fontSize: 40,
            padding: '12px 16px',
        },
    },
    primary: {
        backgroundColor: primaryColor,
        color: '#FFFFFF',
        ...defaultBoxShadow,
    },
    info: {
        backgroundColor: infoColor,
        color: '#FFFFFF',
        ...defaultBoxShadow,
    },
    success: {
        backgroundColor: successColor,
        color: '#FFFFFF',
        ...defaultBoxShadow,
    },
    warning: {
        backgroundColor: warningColor,
        color: '#FFFFFF',
        ...defaultBoxShadow,
    },
    danger: {
        backgroundColor: dangerColor,
        color: '#FFFFFF',
        ...defaultBoxShadow,
    },
    sidebarMinimize: {
        float: 'left',
        padding: '0 0 0 15px',
        display: 'block',
        color: '#555555',
    },
    sidebarMinimizeRTL: {
        padding: '0 15px 0 0 !important',
    },
    sidebarMiniIcon: {
        width: '20px',
        height: '17px',
    },
})

export default headerStyle
