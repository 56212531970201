import React, { Fragment, useEffect, useState } from 'react'
import { CustomDialogue } from '../../../components/CustomDialogue/CustomDialogue'
import CommoneCurrentComponent from '../../../components/DashboardTransfer/Common'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import { FormattedMessage } from 'react-intl'
import API from '@aws-amplify/api'
import DateFnsUtils from '@date-io/date-fns'
import { connect } from 'react-redux'
import {DatePicker, Modal} from "antd";
import dayjs from "dayjs";

const ForwardNewDetailC = ({ modalState, toggleModal, current_client }) => {
    // const { current_client } = useSelector(state => state.app_state);
    const [selectedDate, setSelectedDate] = useState(dayjs())
    const [state, setState] = useState({
        currencies_list_priority: [],
        currency_to_state: 'success',
        from_currency: 1,
        to_currency: 5,
        from_amount: '0',
        to_amount: '0',
        settlementDate: null,
    })

    useEffect(() => {
        fetchAll()
    }, [])

    const fetchAll = async () => {
        try {
            const [currencies_list_priority] = await Promise.all([
                API.get('currencies', `/currencies/get-list-priority`),
            ])
            setState({
                ...state,
                currencies_list_priority: currencies_list_priority,
            })
        } catch (error) {}
    }

    const select_currency_select_options = state.currencies_list_priority.map(
        item => {
            return {
                value: item.id,
                label: (
                    <React.Fragment>
                        <span
                            className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`}
                        />
                        &nbsp;
                        {item.iso_alpha_3}
                    </React.Fragment>
                ),
            }
        }
    )

    const change = (stateName, event) => {
        setState({
            ...state,
            [stateName]: event.target.value,
        })
    }

    const handleCustomReactSelectChange = (stateName, event) => {
        setState({
            ...state,
            [stateName]: event.value,
        })
    }

    const handleSubmit = async () => {
        const {
            from_currency,
            from_amount,
            to_currency,
            to_amount,
            currencies_list_priority,
        } = state

        const payload = {
            client_id: current_client.id,
            from_currency: currencies_list_priority.filter(
                i => i.id === from_currency
            )[0].id,
            from_amount: from_amount,
            to_currency: currencies_list_priority.filter(
                i => i.id === to_currency
            )[0].id,
            to_amount: to_amount,
            status_id: '2',
            settlement_date: selectedDate,
        }

        await API.post('email', '/forward/new-forward', {
            body: payload,
        })
        toggleModal(false);
        alert('Thank you. A member of our staff will be in contact shortly.')
    }

    return (
        <Fragment>
            <Modal
                visible={modalState}
                onCancel={() => toggleModal(false)}
                title="Forward Quote Request"
                onOk={handleSubmit}
            >
                <Fragment>
                    <GridContainer>
                        <GridItem>
                            <p>
                                To request a price on a New Forward Contract, please fill in
                                the details below and a member of our staff will be
                                in contact with you to confirm a rate.
                            </p>
                        </GridItem>
                    </GridContainer>
                    <h4>Sell Currency</h4>
                    <GridContainer>
                        <CommoneCurrentComponent
                            inputDisabled={true}
                            hideAmount={true}
                            selectError={state.currency_from_state === 'error'}
                            selectLabel={
                                <FormattedMessage
                                    id="dashboard.fromCurrency"
                                    defaultMessage={`From currency `}
                                />
                            }
                            color="blue"
                            selectOptions={select_currency_select_options}
                            selectValue={state.from_currency}
                            isClearable={false}
                            onSelectChange={event =>
                                handleCustomReactSelectChange(
                                    'from_currency',
                                    event
                                )
                            }
                            inputValue="Sell Amount"
                            inputValue2={'(will be calculated)'}
                            onInputChange={event => {
                                change('from_amount', event)
                            }}
                        />
                    </GridContainer>
                    <br />
                    <h4>Buy Currency</h4>
                    <GridContainer>
                        <CommoneCurrentComponent
                            selectError={state.currency_to_state === 'error'}
                            selectLabel={
                                <FormattedMessage
                                    id="dashboard.toCurrency"
                                    defaultMessage={`To currency `}
                                />
                            }
                            color="blue"
                            selectOptions={select_currency_select_options}
                            selectValue={state.to_currency}
                            isClearable={false}
                            onSelectChange={event =>
                                handleCustomReactSelectChange(
                                    'to_currency',
                                    event
                                )
                            }
                            inputValue="Buy Amount"
                            inputValue2={state.to_amount}
                            onInputChange={event => {
                                change('to_amount', event)
                            }}
                        />
                    </GridContainer>
                    <br />
                    <h4>Settlement</h4>
                        <DatePicker
                            mode={'date'}
                            format={'DD/MM/YYYY'}
                            value={selectedDate}
                            onChange={setSelectedDate}
                        />
                </Fragment>
            </Modal>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        current_client: state.app_state.current_client,
    }
}
export const ForwardNewDetail = connect(
    mapStateToProps,
    () => {}
)(ForwardNewDetailC)
