import {API} from "aws-amplify";

export const FETCH_CURRENCIES_NOT_DELETED_REQUEST = 'FETCH_CURRENCIES_NOT_DELETED_REQUEST'
export const FETCH_CURRENCIES_NOT_DELETED_SUCCESS = 'FETCH_CURRENCIES_NOT_DELETED_SUCCESS'
export const FETCH_CURRENCIES_NOT_DELETED_FAILURE = 'FETCH_CURRENCIES_NOT_DELETED_FAILURE'


export function fetchCurrenciesNotDeletedListRequest() {
    return {
        type: FETCH_CURRENCIES_NOT_DELETED_REQUEST,
    }
}

export function fetchCurrenciesNotDeletedListFailure() {
    return {
        type: FETCH_CURRENCIES_NOT_DELETED_FAILURE,
    }
}

export function fetchCurrenciesNotDeletedSuccess(response) {
    return {
        type: FETCH_CURRENCIES_NOT_DELETED_SUCCESS,
        payload: response,
        receivedAt: Date.now()
    }
}

const initialState = [];

export function currencies(state = initialState, action) {
    switch (action.type) {
        case FETCH_CURRENCIES_NOT_DELETED_SUCCESS:
            return {...state, ...action.payload}
        default:
            return state;
    }
}

export function fetchCurrenciesNotDeletedList() {
    return async function (dispatch) {
        dispatch(fetchCurrenciesNotDeletedListRequest())
        return await API.get('currencies', `/currencies/get-list-priority`, {})
            .then(response => {
                dispatch(fetchCurrenciesNotDeletedSuccess(response));
                return response;
            })
            .catch((err) => {
                dispatch(fetchCurrenciesNotDeletedListFailure())
                return err;
            });
    }
}