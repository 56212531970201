import { connect } from 'react-redux'
import IdentificationList from './IdentificationList.jsx'
import {
    fetchIdentificationList,
    selectIdentificationUi,
} from '../../../redux/actions/identification'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        identification: state.identification,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchIdentificationList: () => {
            dispatch(fetchIdentificationList())
        },
        selectIdentificationUi: data => {
            dispatch(selectIdentificationUi(data))
        },
    }
}

const IdentificationListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IdentificationList)

export default IdentificationListContainer
