import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import {
    fetchBeneficiaryList,
    selectBeneficiaryUi,
} from '../../../redux/actions/beneficiaries'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import Card from '../../../components/Card/Card.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'

// import blankPageStyle from "./BlankPageStyles";
import CardHeader from '../../../components/Card/CardHeader'
import CardIcon from '../../../components/Card/CardIcon'
import PermIdentity from '@material-ui/icons/PermIdentity'

import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react.jsx'
import Button from '../../../components/CustomButtons/Button'
import BeneficiaryListContainer from '../../../components/Beneficiaries/BeneficiaryList'
import BeneficiaryEditContainer from '../../../components/Beneficiaries/BeneficiaryEdit'
// import BeneficiaryNewContainer from "../../../components/Beneficiaries/BeneficiaryNewContainer";

import { FormattedMessage } from 'react-intl'

const beneficiariesListStyle = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },

    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}

/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientsList
 * ----------------------------------------------------------------------------
 */
class BeneficiaryEditView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    selectUi() {
        switch (this.props.beneficiaries.beneficiaries_current_ui) {
            case 'edit':
                return (
                    <BeneficiaryEditContainer
                        beneficiaries_id={
                            this.props.beneficiaries.beneficiaries_id
                        }
                    />
                )
            default:
                return <BeneficiaryListContainer />
        }
    }

    componentDidMount() {}

    render() {
        // console.log(this.state);
        // console.log(this.props);
        const { classes } = this.props
        return (
            <div>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <PermIdentity />
                        </CardIcon>
                        <div className={classes.flexEnd}>
                            <h4 className={classes.cardIconTitle}>
                                Beneficiary Management Functions
                            </h4>
                            <div>
                                <Button
                                    round
                                    color="info"
                                    onClick={() =>
                                        this.props.history.push(
                                            '/beneficiaries/view-beneficiaries'
                                        )
                                    }
                                >
                                    <FormattedMessage
                                        id="newBeneficiary.cardItem1.showAll"
                                        defaultMessage={`Show All`}
                                    />
                                </Button>
                                <Button
                                    round
                                    color="info"
                                    onClick={() =>
                                        this.props.history.push(
                                            '/beneficiaries/create'
                                        )
                                    }
                                >
                                    <FormattedMessage
                                        id="newBeneficiary.cardItem1.createNew"
                                        defaultMessage={`Create New`}
                                    />
                                </Button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12}>
                                <GridItem xs={12}>
                                    <BeneficiaryEditContainer
                                        beneficiaries_id={
                                            this.props.match.params
                                                .beneficiary_id
                                        }
                                    />
                                </GridItem>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

BeneficiaryEditView.propTypes = {
    classes: PropTypes.object.isRequired,
}

// export default withStyles(beneficiariesListStyle)(BeneficiaryEdit)
const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        beneficiaries: state.beneficiaries,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectBeneficiaryUi: id => {
            dispatch(selectBeneficiaryUi(id))
        },
        fetchBeneficiaryList: () => {
            dispatch(fetchBeneficiaryList())
        },
    }
}

const BeneficiaryEditViewContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(beneficiariesListStyle)(BeneficiaryEditView))

export default BeneficiaryEditViewContainer