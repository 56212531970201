import React from 'react'
import { connect } from 'react-redux'
import BeneficiariesUploadPageComponent from '../../../features/Beneficiaries/Upload'
import Card from '../../../components/Card/Card.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import CardHeader from '../../../components/Card/CardHeader'
import CardIcon from '../../../components/Card/CardIcon'
import PermIdentity from '@material-ui/icons/PermIdentity'
import { FormattedMessage } from 'react-intl'
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react.jsx'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
}

const BeneficiariesUpload = props => {
    const { classes, history } = props

    return (
        <Card>
            <CardHeader color="rose" icon>
                <CardIcon color="rose">
                    <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                    <FormattedMessage
                        id="beneficiariesUpload.cardItem1.title"
                        defaultMessage={'Upload Beneficiary Record'}
                    />
                </h4>
            </CardHeader>
            <CardBody>
                <BeneficiariesUploadPageComponent history={history} />
            </CardBody>
        </Card>
    )
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        beneficiaries: state.beneficiaries,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

const BeneficiariesUploadContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(BeneficiariesUpload))

export default BeneficiariesUploadContainer
