import React from 'react'
import ReactTable from 'react-table'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
// import TransferList from "./TransferList";
import {
    // fetchClientList,
    fetchInvoicePendingList,
    // setTransferSelectId
} from '../../redux/actions/transfers_pending'

// @material-ui/icons
import Dvr from '@material-ui/icons/Dvr'
import Button from 'components/CustomButtons/Button.jsx'

/**
 * STYLING
 */
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'
import { matchSorter } from 'match-sorter'

import { FormattedNumber } from 'react-intl'
import 'react-dates/initialize'

import {
    DateRangePicker,
    SingleDatePicker,
    DayPickerRangeController,
} from 'react-dates'

import Badge from '../Badge/Badge'

import Card from '../Card/Card'
//import CardHeader from "../Card/CardHeader";
import CardBody from '../Card/CardBody'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { API } from 'aws-amplify'

var moment = require('moment')

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
    },
}

/**
 * CLASS - TransferList
 */
class InvoicesListComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            entity_list: [],
            startDate: moment().subtract(6, 'month'),
            endDate: moment().add(1, 'month'),
        }
    }

    componentDidMount() {
        this.props.fetchInvoicePendingList()
    }

    handle_date_change = (startDate, endDate) => {
        // console.log(startDate, endDate);
        this.setState({ startDate, endDate })
        this.buildTableData()
    }

    buildTableData() {
        let transfer_pending_list = this.props.transfers_pending
            .transfer_pending_list
        console.log(transfer_pending_list)

        let date_range_transfer_list = transfer_pending_list
        if (this.state.startDate && this.state.endDate) {
            date_range_transfer_list = transfer_pending_list.filter(
                transfer => {
                    return (
                        this.state.startDate.isBefore(
                            moment(transfer.transaction_datetime).add(1, 'day')
                        ) &&
                        this.state.endDate.isAfter(
                            moment(transfer.transaction_datetime)
                        )
                    )
                }
            )
        }

        console.log(transfer_pending_list)
        if (!transfer_pending_list) return []
        var transfer_list_length = transfer_pending_list.length
        if (transfer_list_length > 0) {
            // return this.props.transfers.transfer_list.map((prop, key) => {
            return transfer_pending_list.map((prop, key) => {
                return {
                    id: prop.id,
                    // client_fullname: prop.client_nickname,
                    transaction_date: prop.transaction_datetime,
                    status_id: prop.status,
                    memo: prop.memo,
                    nickname: prop.nickname,
                    settlement_date: prop.settlement_date,
                    invoice_date: prop.invoice_date,
                    due_date: prop.due_date,
                    // currency_from: `${prop.currency_from_iso_alpha_3}`,
                    // amount_from: `${prop.amount_from}`,
                    currency_iso_alpha_3: `${prop.currency_iso_alpha_3}`,
                    amount_to: `${prop.amount_to}`,
                    // client_rate: prop.client_rate.toFixed(5),
                    status_id: prop.status_id,
                    status: prop.status,
                    actions: (
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    // eslint-disable-next-line react/prop-types
                                    this.props.history.push(
                                        `/invoices/view/${prop.id}`
                                    )
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Dvr />
                            </Button>{' '}
                        </div>
                    ),
                }
            })
        }
    }

    getSteps() {
        return [
            'Submitted',
            'Transfer acknowledged',
            'Funds received from client',
            'Payment made to Beneficiary',
            'Completed',
        ]
    }
    getSteps1() {
        return ['Submitted', 'Not Proceeding']
    }

    render() {
        // console.log(this.props.past)
        // console.log(this.props.current)
        const steps = this.getSteps()
        const steps1 = this.getSteps1()

        if (!this.props.transfers_pending.transfer_pending_list) return null

        // eslint-disable-next-line react/prop-types
        var transfer_status = this.props.transfers_pending.transfer_pending_list.map(
            (prop, key) => {
                return prop.transfer_status
            }
        )
        var transfer_status_unique = [...new Set(transfer_status)]
        var transfer_status_optionlist = transfer_status_unique.map(
            (prop, key) => {
                return (
                    <option value={prop} key={key}>
                        {prop}
                    </option>
                )
            }
        )

        // console.log(this.props);
        return (
            <React.Fragment>
                <DateRangePicker
                    isOutsideRange={() => false}
                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    displayFormat="DD/MM/YYYY"
                    onDatesChange={({ startDate, endDate }) =>
                        this.handle_date_change(startDate, endDate)
                    } // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={focusedInput =>
                        this.setState({ focusedInput })
                    } // PropTypes.func.isRequired,
                />
                <ReactTable
                    data={this.buildTableData()}
                    filterable
                    columns={[
                        {
                            Header: 'Id',
                            accessor: 'id',
                            Cell: c => (
                                <React.Fragment>
                                    <Badge
                                        color={
                                            c.original.transfer_status ===
                                            'Draft'
                                                ? 'warning'
                                                : c.original.transfer_status ===
                                                  'Submitted'
                                                    ? 'info'
                                                    : c.original
                                                          .transfer_status ===
                                                      'Acknowledged'
                                                        ? 'success'
                                                        : c.original
                                                              .transfer_status ===
                                                          'Processing'
                                                            ? 'success'
                                                            : c.original
                                                                  .transfer_status ===
                                                              'Awaiting Funds'
                                                                ? 'danger'
                                                                : c.original
                                                                      .transfer_status ===
                                                                  'Funds Received'
                                                                    ? 'danger'
                                                                    : c.original
                                                                          .transfer_status ===
                                                                      'Payment Made'
                                                                        ? 'success'
                                                                        : c
                                                                              .original
                                                                              .transfer_status ===
                                                                          'Completed'
                                                                            ? 'primary'
                                                                            : c
                                                                                  .original
                                                                                  .transfer_status ===
                                                                              'On Hold'
                                                                                ? 'danger'
                                                                                : c
                                                                                      .original
                                                                                      .transfer_status ===
                                                                                  'Not Proceeding'
                                                                                    ? 'primary'
                                                                                    : 'success'
                                        }
                                    >
                                        ID: {c.original.id}
                                        {
                                            //c.original.transfer_status
                                        }
                                    </Badge>
                                </React.Fragment>
                            ),
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                    keys: ['id'],
                                }),
                            filterAll: true,
                            maxWidth: 75,
                        },
                        // {
                        //   Header: "Client",
                        //   accessor: "client_fullname",
                        //   filterMethod: (filter, rows) =>
                        //     matchSorter(rows, filter.value, { keys: ["client_fullname"] }),
                        //   filterAll: true,
                        // },
                        {
                            Header: 'Invoice Date',
                            accessor: 'invoice_date',
                            Cell: c => (
                                <React.Fragment>
                                    {c.value
                                        ? moment(c.value).format('DD/MM/YYYY')
                                        : ''}{' '}
                                </React.Fragment>
                            ),
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                    keys: ['invoice_date'],
                                }),
                            filterAll: true,
                        },
                        {
                            Header: 'Due Date',
                            accessor: 'due_date',
                            Cell: c => (
                                <React.Fragment>
                                    {c.value
                                        ? moment(c.value).format('DD/MM/YYYY')
                                        : ''}{' '}
                                </React.Fragment>
                            ),
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                    keys: ['due_date'],
                                }),
                            filterAll: true,
                        },
                        // {
                        //   Header: "Amount From",
                        //   accessor: "amount_from",
                        //   Cell: (c) => <React.Fragment>
                        //     <div className={`currency-flag currency-flag-${c.original.currency_from ? c.original.currency_from.toLowerCase() : ""}`}></div> {c.original.currency_from} <br />
                        //     <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2}
                        //       value={c.value} /> </React.Fragment>,
                        //   filterMethod: (filter, rows) =>
                        //     matchSorter(rows, filter.value, { keys: ["amount_from"] }),
                        //   filterAll: true,
                        // },
                        {
                            Header: 'Amount',
                            accessor: 'amount_to',
                            Cell: c => (
                                <React.Fragment>
                                    <div
                                        className={`currency-flag currency-flag-${
                                            c.original.currency_iso_alpha_3
                                                ? c.original.currency_iso_alpha_3.toLowerCase()
                                                : ''
                                        }`}
                                    />{' '}
                                    {c.original.currency_to_iso_alpha_3}
                                    <FormattedNumber
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        value={c.value}
                                    />{' '}
                                </React.Fragment>
                            ),
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                    keys: ['amount_to'],
                                }),
                            filterAll: true,
                        },
                        // {
                        //   Header: "Rate",
                        //   accessor: "client_rate",
                        //   filterMethod: (filter, rows) =>
                        //     matchSorter(rows, filter.value, { keys: ["client_rate"] }),
                        //   filterAll: true,
                        // },
                        {
                            Header: 'Status ',
                            accessor: 'status',
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                    keys: ['status'],
                                }),
                            filterAll: true,
                            // filterMethod: (filter, row) => {
                            //   if (filter.value === "all") {
                            //     return true;
                            //   }
                            //   return row[filter.id] == filter.value;
                            // },
                            // Filter: ({ filter, onChange }) => (
                            //   <select
                            //     onChange={event => onChange(event.target.value)}
                            //     style={{ width: "100%" }}
                            //     value={filter ? filter.value : "all"}
                            //   >
                            //     <option value="all">Show All</option>
                            //     {transfer_status_optionlist}
                            //   </select>)
                        },
                        {
                            Header: 'Actions',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                            maxWidth: 100,
                        },
                    ]}
                    defaultSorted={[
                        {
                            id: 'transaction_date',
                            desc: true,
                        },
                        {
                            id: 'id',
                            desc: true,
                        },
                    ]}
                    defaultPageSize={10}
                    showPaginationBottom
                    className="-highlight"
                    // SubComponent={v => {
                    //   let ActivTab = 0;
                    //   if (v.original.status_id == 2) ActivTab = 0;
                    //   if (v.original.status_id == 3) ActivTab = 1;
                    //   if (v.original.status_id == 4) ActivTab = 1;
                    //   if (v.original.status_id == 5) ActivTab = 1;
                    //   if (v.original.status_id == 6) ActivTab = 2;
                    //   if (v.original.status_id == 7) ActivTab = 3;
                    //   if (v.original.status_id == 8) ActivTab = 4;

                    //   if (v.original.status_id == 10) ActivTab = 1;

                    //   return (
                    //     <Card>
                    //       <CardBody>
                    //         <GridContainer>
                    //           <GridItem xs={12} sm={9}>
                    //             {/*<h6>{v.original.transfer_status_name}</h6>*/}
                    //             {v.original.status_id != 10 &&
                    //               <Stepper activeStep={ActivTab}>
                    //                 {steps.map((label, index) => {
                    //                   const stepProps = {};
                    //                   const labelProps = {};

                    //                   return (
                    //                     <Step key={label} {...stepProps}>
                    //                       <StepLabel {...labelProps}>{label}</StepLabel>
                    //                     </Step>
                    //                   );
                    //                 })}
                    //               </Stepper>
                    //             }
                    //             {v.original.status_id == 10 &&
                    //               <Stepper activeStep={ActivTab}>
                    //                 {steps1.map((label, index) => {
                    //                   const stepProps = {};
                    //                   const labelProps = {};

                    //                   return (
                    //                     <Step key={label} {...stepProps}>
                    //                       <StepLabel {...labelProps}>{label}</StepLabel>
                    //                     </Step>
                    //                   );
                    //                 })}
                    //               </Stepper>
                    //             }

                    //           </GridItem>
                    //           <GridItem xs={12} sm={3}>
                    //             <h6>Actions</h6>
                    //             <p>
                    //               <Button
                    //                 style={{ width: 200 }} color="primary" size="sm"
                    //                 onClick={() => {
                    //                   this.props.history.push(`/invoices/view/${v.original.id}`);
                    //                 }}
                    //                 className="edit">
                    //                 View Invoice Details</Button>
                    //               <br />
                    //             </p>
                    //           </GridItem>
                    //         </GridContainer>
                    //       </CardBody>
                    //     </Card>
                    //   );
                    // }}
                />
            </React.Fragment>
        )
    }
}

// export default withRouter(withStyles(styles)(TransferList));

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers_pending: state.transfers_pending,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchInvoicePendingList: () => {
            dispatch(fetchInvoicePendingList())
        },
        // setTransferSelectId: id => {
        //   dispatch(setTransferSelectId(id));
        // }
    }
}

const InvoicesListComponentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(styles)(InvoicesListComponent)))

export default InvoicesListComponentContainer
