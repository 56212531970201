import { connect } from 'react-redux'
import { updateNewTransferCreation } from '../../../../redux/actions/transfers'
import Step5Confirm from './Step5Confirm'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers: state.transfers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewTransferCreation: (key, value) => {
            dispatch(updateNewTransferCreation(key, value))
        },
    }
}

const Step5ConfirmContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Step5Confirm)

export default Step5ConfirmContainer
