import {
    NEWS_FETCH_NEWS_LIST_REQUEST,
    NEWS_FETCH_NEWS_LIST_SUCCESS,
    NEWS_SELECT_UI,
    NEWS_SELECT_SET_ID,
} from '../actions/news.js'

const initialState = {
    news_current_ui: 'list',
    is_fetching: false,
    did_invalidate: false,
    news_list: [],
    news_detail_fetching: false,
    news_detail_loaded: false,
    news_detail_id: null,
    new_news_data: {},
    new_news_type: null,
    new_news_submitted: false,
    new_news_created: false,
}

function news(state = initialState, action) {
    switch (action.type) {
        case NEWS_FETCH_NEWS_LIST_REQUEST:
            return Object.assign({}, state, {
                is_fetching: true,
            })

        case NEWS_FETCH_NEWS_LIST_SUCCESS:
            return Object.assign({}, state, {
                is_fetching: false,
                news_list: action.news_list,
                lastUpdated: action.receivedAt,
            })

        case NEWS_SELECT_UI:
            return Object.assign({}, state, {
                news_current_ui: action.value,
            })

        case NEWS_SELECT_SET_ID:
            return Object.assign({}, state, {
                news_detail_id: action.value,
            })

        default:
            return state
    }
}

export default news
