import React from 'react'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
import Card from '../../../../components/Card/Card.jsx'
import CardBody from '../../../../components/Card/CardBody.jsx'
import CardHeader from '../../../../components/Card/CardHeader'
import { cardTitle } from '../../../../assets/jss/material-dashboard-pro-react'

const moment = require('moment')
const axios = require('axios')

const style = {
    ...customCheckboxRadioSwitch,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    infoText: {
        fontWeight: '300',
        margin: '10px 0 30px',
        textAlign: 'center',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    inputAdornment: {
        position: 'relative',
    },
}

class Step4Amounts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            transfer_base_currency_nickname: 'United States Dollars (USD)',
            transfer_terms_currency_nickname: 'Australian Dollars (AUD)',
            current_rate: 0,
            amount_from: 0,
            amount_to: 5000,

            time: {},
            seconds: 30,
        }

        this.timer = 0
    }

    componentDidMount() {
        // if (this.props.transfers.new_transfer_data.currency_from && this.props.transfers.new_transfer_data.currency_to) {
        //   let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
        //   axios.get(url, {
        //     params: {
        //       currencies: this.props.transfers.new_transfer_data.currency_from.iso_alpha_3,
        //       source: this.props.transfers.new_transfer_data.currency_to.iso_alpha_3,
        //       format: "1"
        //     }
        //   })
        //     .then((response) => {
        //       console.log(response);
        //       let key = `${this.props.transfers.new_transfer_data.currency_to.iso_alpha_3}${this.props.transfers.new_transfer_data.currency_from.iso_alpha_3}`
        //       let rate = response.data.quotes[key]
        //       this.setState({
        //         current_rate: rate,
        //         amount_from: this.props.transfers.new_transfer_data.amount_to * parseFloat(response.data.quotes["AUDUSD"])
        //       })
        //       this.props.updateNewTransferCreation("current_rate", rate)
        //       this.props.updateNewTransferCreation("amount_from", this.props.transfers.new_transfer_data.amount_to * rate)
        //       // this.startTimer()
        //     })
        //     .catch((error) => {
        //       console.log(error);
        //     })
        // }
        this.startTimer()
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    fetchNewRate = () => {
        if (
            this.props.transfers.new_transfer_data.currency_from &&
            this.props.transfers.new_transfer_data.currency_to
        ) {
            let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
            axios
                .get(url, {
                    params: {
                        currencies: this.props.transfers.new_transfer_data
                            .currency_from.iso_alpha_3,
                        source: this.props.transfers.new_transfer_data
                            .currency_to.iso_alpha_3,
                        format: '1',
                    },
                })
                .then(response => {
                    // let key = `${this.props.transfers.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}${this.props.transfers.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}`
                    // let rate = response.data.quotes[key]
                    // this.setState({
                    //   current_rate: rate,
                    //   amount_from: this.props.transfers.new_transfer_data.amount_to * parseFloat(response.data.quotes["AUDUSD"])
                    // });
                    //this.props.updateNewTransferCreation("current_rate", rate)
                    //this.props.updateNewTransferCreation("amount_from", this.props.transfers.new_transfer_data.amount_to * rate)

                    let key = `${this.props.transfers.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}${this.props.transfers.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}`
                    let rate_string = response.data.quotes[key]

                    let amount_to = Number.parseFloat(
                        this.props.transfers.new_transfer_data.amount_to
                    )
                    let rate = Number.parseFloat(rate_string)
                    let rate_adjust =
                        Number.parseFloat(
                            this.props.app_state.current_client.default_rate
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_division
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_entity
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_team
                        ) +
                        Number.parseFloat(
                            this.props.app_state.current_client
                                .default_rate_staff
                        )
                    let adjusted_rate = rate + rate * rate_adjust

                    let amount_from = amount_to * adjusted_rate

                    this.props.updateNewTransferCreation(
                        'current_rate',
                        adjusted_rate.toFixed(5)
                    )
                    this.props.updateNewTransferCreation(
                        'amount_from',
                        amount_from.toFixed(5)
                    )
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    secondsToTime = secs => {
        let hours = Math.floor(secs / (60 * 60))

        let divisor_for_minutes = secs % (60 * 60)
        let minutes = Math.floor(divisor_for_minutes / 60)

        let divisor_for_seconds = divisor_for_minutes % 60
        let seconds = Math.ceil(divisor_for_seconds)

        let obj = {
            h: hours,
            m: minutes,
            s: seconds,
        }
        return obj
    }
    startTimer = () => {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000)
        }
    }
    countDown = () => {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        })
        this.props.updateNewTransferCreation('current_rate_ttl', seconds)

        // Check if we're at zero.
        if (seconds === 0) {
            this.setState({
                seconds: 30,
            })
            this.props.updateNewTransferCreation('current_rate_ttl', 30)
            this.fetchNewRate()
        }
    }
    sendState() {
        return this.state
    }
    // function that verifies if two strings are equal
    compare(string1, string2) {
        if (string1 === string2) {
            return true
        }
        return false
    }
    // function that returns true if value is email, false otherwise
    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (emailRex.test(value)) {
            return true
        }
        return false
    }
    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'email':
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + ' _state']: 'error' })
                }
                break
            case 'equalTo':
                if (
                    this.compare(
                        event.target.value,
                        this.state[stateNameEqualTo]
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
        this.props.updateNewTransferCreation(stateName, event.target.value)
    }

    isValidated() {
        if (this.state.telephone_preferred_selected === 'success') {
            return true
        } else {
            if (this.state.telephone_preferred_selected !== 'success') {
                this.setState({ telephone_preferred_selected: 'error' })
            }
        }
        return true
    }

    render() {
        if (
            !this.props.transfers.new_transfer_data.currency_from ||
            !this.props.transfers.new_transfer_data.currency_to
        ) {
            return null
        }
        let ttl = ''
        if (this.props.transfers.new_transfer_data) {
            ttl = moment()
                .add(
                    this.props.transfers.new_transfer_data.current_rate_ttl,
                    's'
                )
                .format('LTS')
        }

        let from_currency_nickname =
            `${
                this.props.transfers.new_transfer_data.currency_from.full_name
            } [${
                this.props.transfers.new_transfer_data.currency_from.iso_alpha_3
            }]` || ''
        let to_currency_nickname =
            `${this.props.transfers.new_transfer_data.currency_to.full_name} [${
                this.props.transfers.new_transfer_data.currency_to.iso_alpha_3
            }]` || ''

        return (
            <React.Fragment>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={10}>
                        <Card>
                            <CardHeader>
                                <h5>
                                    You are requesting an FX transfer to
                                    purchase:
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={6}>
                                        <CustomInput
                                            labelText="An amount of"
                                            id="transfer_terms_amount"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value:
                                                    this.props.transfers
                                                        .new_transfer_data
                                                        .amount_to || '',
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <CustomInput
                                            labelText="Currency"
                                            id="transfer_terms_currency_nickname"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value: to_currency_nickname,
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={10}>
                        <Card>
                            <CardHeader>
                                <h5>Current rate on offer:</h5>
                            </CardHeader>
                            <CardBody>
                                <GridContainer justify="center">
                                    <GridItem
                                        xs={12}
                                        sm={10}
                                        style={{ textAlign: 'center' }}
                                    >
                                        <span style={{ fontSize: '2.0rem' }}>
                                            {
                                                this.props.transfers
                                                    .new_transfer_data
                                                    .current_rate
                                            }{' '}
                                            {this.props.transfers
                                                .new_transfer_data
                                                .currency_to &&
                                                this.props.transfers
                                                    .new_transfer_data
                                                    .currency_to
                                                    .iso_alpha_3}{' '}
                                            /{' '}
                                            {this.props.transfers
                                                .new_transfer_data
                                                .currency_from &&
                                                this.props.transfers
                                                    .new_transfer_data
                                                    .currency_from.iso_alpha_3}
                                        </span>
                                        <br />
                                        <span>Rate is valid to: {ttl}</span>
                                        <br />
                                        <span>
                                            At which time it will automatically
                                            refresh
                                        </span>
                                        <br />
                                        <span>
                                            {
                                                this.props.transfers
                                                    .new_transfer_data
                                                    .current_rate_ttl
                                            }{' '}
                                            seconds remaining
                                        </span>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={10}>
                        <Card>
                            <CardHeader>
                                <h5>Which will require payment of:</h5>
                            </CardHeader>
                            <CardBody>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={6}>
                                        <CustomInput
                                            labelText="An amount of"
                                            id="transfer_base_amount"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value:
                                                    this.props.transfers
                                                        .new_transfer_data
                                                        .amount_from || '',
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <CustomInput
                                            labelText="Currency"
                                            id="transfer_base_currency_nickname"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value: from_currency_nickname,
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </React.Fragment>
        )
    }
}

export default withStyles(style)(Step4Amounts)
