import { Typography } from '@material-ui/core'
import React, { Fragment } from 'react'
import CustomInput from '../../CustomInput/CustomInput'
import GridContainer from '../../Grid/GridContainer'
import GridItem from '../../Grid/GridItem'

const Contact = ({
    edit_mode,
    ben_telephone_work,
    ben_telephone_afterhours,
    ben_telephone_mobile,
    ben_email_main,
    ben_email_secondary,
    handleChange,
    handleSubmit,
}) => {
    return (
        <Fragment>
            <Typography variant="h5" style={{ fontWeight: 700 }}>
                Contact
            </Typography>
            <div style={{ padding: 20, marginBottom: 24 }}>
                <form onSubmit={handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Telephone (Work)"
                                id="ben_telephone_work"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: !edit_mode,
                                    value: ben_telephone_work || '',
                                    onChange: event => {
                                        handleChange(event)
                                    },
                                }}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Telephone (After hours)"
                                id="ben_telephone_afterhours"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: !edit_mode,
                                    value: ben_telephone_afterhours || '',
                                    onChange: event => {
                                        handleChange(event)
                                    },
                                }}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Telephone (mobile)"
                                id="ben_telephone_mobile"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: !edit_mode,
                                    value: ben_telephone_mobile || '',
                                    onChange: event => {
                                        handleChange(event)
                                    },
                                }}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Email (main)"
                                id="ben_email_main"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: !edit_mode,
                                    value: ben_email_main || '',
                                    onChange: event => {
                                        handleChange(event)
                                    },
                                }}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Email (Secondary)"
                                id="ben_email_secondary"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: !edit_mode,
                                    value: ben_email_secondary || '',
                                    onChange: event => {
                                        handleChange(event)
                                    },
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </form>
            </div>
        </Fragment>
    )
}

export default Contact
