import { connect } from 'react-redux'
import { fetchForwardList } from '../../../redux/actions/forwards'
import PastForwardList from "./PastForwardList";

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchForwardList: (clientId) => dispatch(fetchForwardList(clientId)),
    }
}

const PastForwardsListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PastForwardList)

export default PastForwardsListContainer
