import { API } from 'aws-amplify'

export const RegistrationUtil = {
    initialState: {
        firstName: '',
        lastName: '',
        email: '',
        telephone: '',
        address_line_1: '',
        address_line_2: '',
        suburb: '',
        state: '',
        postcode: '',
        country_id: '',
        nationality: '',
        date_of_birth: '',
        place_of_birth_city: '',
        country_of_birth: '',
        notes: '',
        position: ''
        // marital_status: '',
    },
    prepareNewClientContactFromState: (state) => {
        return {
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email,
            telephone: state.telephone,
            address_line_1: state.address_line_1,
            address_line_2: state.address_line_2,
            suburb: state.suburb,
            state: state.state,
            postcode: state.postcode,
            country_id: state.country_id,
            nationality: state.nationality,
            date_of_birth: state.date_of_birth,
            place_of_birth: state.place_of_birth,
            country_of_birth: state.country_of_birth,
            position: state.position,
            notes: state.notes
            // marital_status: this.state.marital_status,
        }
    }
}

export const RegistrationAPI = {
    addClientContact: (body) =>  API.post("clients", "/client-contacts", {body: body}),
    removeClientContact: (clientContactId) =>  API.get("clients", `/client-contacts/remove/${clientContactId}`, {})
}