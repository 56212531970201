import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
// import RegistrationNew from "./RegistrationNew";
import {createNewRegistration, updateNewRegistrationCreation,} from '../../redux/actions/registration'
// import {updateRegistrationRedirect} from "../../redux/actions";
// core components
import Wizard from 'components/Wizard/Wizard.jsx'
import {withRouter} from 'react-router-dom'

import Step1AccountHolderContainer from './WizardSteps/Step1AccountHolder'
import Step2ClientContactContainer from './WizardSteps/Step2ClientContact'
import Step3AdditionalContactsContainer from './WizardSteps/Step3AdditionalContacts';
import Step4IdentificationContainer from './WizardSteps/Step4Identification'
import Step5ConfirmContainer from './WizardSteps/Step5Confirm'

import {FormattedMessage} from 'react-intl'
import {API} from 'aws-amplify'
import Step3_1AdditionalDocumentsContainer from './WizardSteps/Step3_1AdditionalDocuments'
import {Col, message, Row} from "antd";
import withStyles from "@material-ui/core/styles/withStyles";
import Status, {DataStatus} from "../ComponentInfo/Status";
import {RegistrationContext} from "../../libs/RegistrationProvider";

//setup for language components
let languageObj_en_json = require('../../translations/en.json')
let languageObj_zh_json = require('../../translations/zh.json')
let languageObj_de_json = require('../../translations/de.json')
let languageObj_es_json = require('../../translations/es.json')
let languageObj_fr_json = require('../../translations/fr.json')
let languageObj_it_json = require('../../translations/it.json')
let languageObj_pt_json = require('../../translations/pt.json')

const styles = {
    expandedBackground: {
        backgroundColor: 'rgb(240, 242, 245)',
        padding: '15px',
        borderRadius: '5px'
    },
}
const RegistrationNew = (props) => {

    const [state, setState] = useState({
        countries: [],
        language_id_list: [],
        current_client: props.app_state.current_client
    });

    const [componentInfos, setComponentInfos] = useState({
        initInfo: {status: DataStatus.Loaded, callback: () => initData()},
        regCompleteInfo: {status: DataStatus.Loaded, callback: () => finished()}
    });

    const [registration, setRegistration] = useState({
        account: null,
        authorizedPerson: null,
        beneficialOwners: [],
        documents: [],
        identifications: [],
        account_type: 1,
        account_business_type: 1,
        rego_confirm_1: false,
        rego_confirm_2: false,
        reg_disabled: false
    });

    const initData = () => {
        const loading = message.loading("loading your details. Please wait..", 0);
        setComponentInfos(prev => ({...prev, initInfo: {...prev.initInfo, status: DataStatus.Loading}}));
        Promise.all([
            API.get('countries', `/countries/list_not_deleted`, {}),
            API.get('admin', `/get_laguages`, {}),
        ]).then(([countries, languages]) => {
            setState(prev => ({
                ...prev,
                countries: countries.sort((a, b) => a.full_name.localeCompare(b.full_name)),
                language_id_list: languages
            }));
            setComponentInfos(prev => ({...prev, initInfo: {...prev.initInfo, status: DataStatus.Loaded}}));
            setRegistration(prev => ({
                ...prev,
                account: props.app_state.current_client,
                authorizedPerson: props.app_state.current_client
            }));
        }).catch(err => {
            console.log("Error. Please refresh the page and try again...");
            setComponentInfos(prev => ({...prev, initInfo: {...prev.initInfo, status: DataStatus.ErrorState}}));
        }).finally(() => loading());
    }

    const finished = async () => {
        const requestBody = {
            ...registration.account,
            ...registration.authorizedPerson,
            ...registration.rego_confirm_1,
            ...registration.rego_confirm_2
        };

        const loading = message.loading("Registering your submission. Please wait...", 0);
        setComponentInfos(prev => ({
            ...prev,
            regCompleteInfo: {...prev.regCompleteInfo, status: DataStatus.Loading }
        }));
        try {
            const result = await API.post('clients', `/client_registration`, {
                body: {
                    ...requestBody,
                },
            });

            await API.post('email', `/interaction/main`, {
                body: {
                    interaction: 'New Registration Submission',
                    data: requestBody,
                    result: result.insertId,
                },
            });

            await API.post('email', `/interaction/main`, {
                body: {
                    interaction: 'Registration Wizard Finished by Client',
                    data: {
                        requestBody,
                        "legal_agreements": "AGREED BY CLIENT"
                    },
                    result: 'Client Completed'
                }
            });

            await API.post('email', `/staff/customer-complete-registration`, {
                body: {
                    clientId: props.app_state.current_client.id
                }
            });

            setComponentInfos(prev => ({
                ...prev,
                regCompleteInfo: {...prev.regCompleteInfo, status: DataStatus.Loaded }
            }));

            window.location.reload();

        } catch (err) {
            console.log(err);
            setComponentInfos(prev => ({
                ...prev,
                regCompleteInfo: {...prev.regCompleteInfo, status: DataStatus.ErrorState }
            }));
            setRegistration(prev => ({...prev, reg_disabled: false }));
        } finally {
            loading();
        }
    }

    useEffect(() => {
        initData();
    }, [props.app_state.current_client]);

    if (props.registration.is_finished) {
        props.history.push('/')
    }
    if (!props.app_state.current_client) {
        return null;
    }
    if (!registration || !registration.account) {
        return null;
    }
    if (props.app_state.current_client.account_status === 1) {
        window.location = '/pending-registration';
    }
    if (props.app_state.current_client.account_status === 2) {
        window.location = '/';
    }

    let languageObj = ''
    switch (props.language.language_current_ui) {
        case 'en':
            languageObj = languageObj_en_json
            break
        case 'es':
            languageObj = languageObj_es_json
            break
        case 'de':
            languageObj = languageObj_de_json
            break
        case 'zh':
            languageObj = languageObj_zh_json
            break
        case 'fr':
            languageObj = languageObj_fr_json
            break
        case 'it':
            languageObj = languageObj_it_json
            break
        case 'pt':
            languageObj = languageObj_pt_json
            break
        default:
            languageObj = languageObj_en_json
    }

    let steps = [
        {
            stepName: 'Account',
            stepComponent: Step1AccountHolderContainer,
            stepId: 'Account',
        },
        {
            stepName: 'Authorised Person',
            stepComponent: Step2ClientContactContainer,
            stepId: 'Personal',
        },
        {
            stepName: "Beneficial Owners",
            stepComponent: Step3AdditionalContactsContainer,
            stepId: 'AdditionalContacts'
        },
        // {
        //   stepName: "Contact",
        //   stepComponent: Step2ContactContainer,
        //   stepId: "Contact"
        // },
        {
            stepName: 'Documents',
            stepComponent: Step3_1AdditionalDocumentsContainer,
            stepId: 'CompanyDocuments',
        },
        {
            stepName: 'Identification',
            stepComponent: Step4IdentificationContainer,
            stepId: 'Identification',
        },
        {
            stepName: 'Confirm',
            stepComponent: Step5ConfirmContainer,
            stepId: 'confirm',
        },
    ]

    if (registration && (registration.account_type === 1
        || (registration.account_business_type < 2 && registration.account_type === 2))) {
        steps = steps.filter(item => item.stepId !== 'AdditionalContacts')
    }

    if (registration && (registration.account_type === 1
        || (registration.account_business_type < 2 && registration.account_type === 2))) {
        steps = steps.filter(item => item.stepId !== 'CompanyDocuments')
    }

    return (
        <RegistrationContext.Provider value={{state: state, registration, setRegistration}}>
            <Status componentInfos={[componentInfos.regCompleteInfo]} showContent={true}>
                <Status componentInfos={[componentInfos.initInfo]}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <Wizard
                                validate
                                steps={steps}
                                // title="Registration"
                                subtitle={
                                    props.app_state.current_client.createdByAdmin ? (
                                        <>
                                    <span>
                                        Please ensure that all information below is correct and all information requested is present
                                    </span>
                                        </>
                                    ) : (
                                        <FormattedMessage
                                            id="newBeneficiary.subtitleTEST"
                                            defaultMessage={`Please provide the following information, to assist in our finalising your account application.`}
                                        />
                                    )
                                }
                                finishButtonClick={() => finished()}
                            />
                        </Col>
                    </Row>
                </Status>
            </Status>
        </RegistrationContext.Provider>
    )
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        registration: state.registration,
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createNewRegistration: data => {
            dispatch(createNewRegistration(data))
        },
        updateNewRegistrationCreation: (key, value) => {
            dispatch(updateNewRegistrationCreation(key, value))
        },
        // updateRegistrationRedirect: (value) => {
        //   dispatch(updateRegistrationRedirect(value));
        // }
    }
}

const RegistrationNewContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RegistrationNew))

export default withStyles(styles)(RegistrationNewContainer)
