import React from 'react'
import PropTypes from 'prop-types'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import { connect } from 'react-redux'
import {
    fetchBeneficiaryList,
    selectBeneficiaryUi,
} from '../../../redux/actions/beneficiaries'

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import Card from '../../../components/Card/Card.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import CardHeader from '../../../components/Card/CardHeader'
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react'
import Clearfix from 'components/Clearfix/Clearfix.jsx'
import { API } from 'aws-amplify'
import Beneficiary from './Beneficiary'
import BeneficiaryAddress from './BeneficiaryAddress'
import Contact from './Contact'
import BankDetails from './BankDetails'
import BankAddress from './BankAddress'
import { FormControlLabel, Switch } from '@material-ui/core'

const staffEditStyles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px',
    },
    registerButton: {
        float: 'right',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    beneficiariesActiveSwitch: {
        '& .MuiSwitch-colorSecondary.Mui-checked': {
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.12,
                backgroundColor: '#000',
            },
        },
    },
}

class BeneficiaryEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            is_loading: true,
            nickname: '',
            nickname_state: 'success',
            ben_legal_name: '',
            ben_legal_name_state: 'success',
            ben_address_line_1: '',
            ben_address_line_2: '',
            ben_address_suburb: '',
            ben_address_state: '',
            ben_address_postcode: '',
            ben_address_country: '',

            bank_legal_name: '',
            bank_address_line_1: '',
            bank_address_line_2: '',
            bank_address_suburb: '',
            bank_address_state: '',
            bank_address_postcode: '',
            bank_address_country: '',

            account_currency: '',
            swift_code: '',
            account_number: '',
            aba_routing_number: '',
            bsb_code: '',
            sort_code: '',
            iban: '',

            ben_telephone_work: '',
            ben_telephone_afterhours: '',
            ben_telephone_mobile: '',
            ben_email_main: '',
            ben_email_secondary: '',

            country_list: [],
            country_list_prio: [],
            currencies_list: [],
            currencies_list_priority: [],
            beneficiariesActiveLoading: false,
            beneficiariesActive: false,
        }
    }

    componentDidMount() {
        if (this.props.beneficiaries_id) {
            API.get(
                'beneficiaries',
                `/beneficiaries/getb/${this.props.beneficiaries_id}`
            )
                .then(beneficiaries_detail => {
                    //console.log(beneficiaries_detail);
                    this.setState({
                        loaded: true,
                        is_loading: false,
                        nickname: beneficiaries_detail[0].nickname,
                        ben_legal_name: beneficiaries_detail[0].ben_legal_name,
                        ben_address_line_1:
                            beneficiaries_detail[0].ben_address_line_1,
                        ben_address_line_2:
                            beneficiaries_detail[0].ben_address_line_2,
                        ben_address_suburb:
                            beneficiaries_detail[0].ben_address_suburb,
                        ben_address_state:
                            beneficiaries_detail[0].ben_address_state,
                        ben_address_postcode:
                            beneficiaries_detail[0].ben_address_postcode,
                        ben_address_country:
                            beneficiaries_detail[0].ben_address_country,

                        bank_legal_name:
                            beneficiaries_detail[0].bank_legal_name,
                        bank_address_line_1:
                            beneficiaries_detail[0].bank_address_line_1,
                        bank_address_line_2:
                            beneficiaries_detail[0].bank_address_line_2,
                        bank_address_suburb:
                            beneficiaries_detail[0].bank_address_suburb,
                        bank_address_state:
                            beneficiaries_detail[0].bank_address_state,
                        bank_address_postcode:
                            beneficiaries_detail[0].bank_address_postcode,
                        bank_address_country:
                            beneficiaries_detail[0].bank_address_country,

                        account_currency:
                            beneficiaries_detail[0].account_currency,
                        swift_code: beneficiaries_detail[0].swift_code,
                        account_number: beneficiaries_detail[0].account_number,
                        aba_routing_number:
                            beneficiaries_detail[0].aba_routing_number,
                        bsb_code: beneficiaries_detail[0].bsb_code,
                        sort_code: beneficiaries_detail[0].sort_code,
                        iban: beneficiaries_detail[0].iban,
                        transit_code: beneficiaries_detail[0].transit_code,
                        bank_code: beneficiaries_detail[0].bank_code,

                        ben_telephone_work:
                            beneficiaries_detail[0].ben_telephone_work,
                        ben_telephone_afterhours:
                            beneficiaries_detail[0].ben_telephone_afterhours,
                        ben_telephone_mobile:
                            beneficiaries_detail[0].ben_telephone_mobile,
                        ben_email_main: beneficiaries_detail[0].ben_email_main,
                        ben_email_secondary:
                            beneficiaries_detail[0].ben_email_secondary,
                        beneficiariesActive: beneficiaries_detail[0].active,
                        beneficiariesActiveLoading: false,
                    })
                    // this.props.loadEntityDetailsSuccess();
                    // console.log(beneficiaries_detail[0].client_id);
                    // console.log(this.props.app_state.current_client.id);
                    if (
                        beneficiaries_detail[0].client_id ===
                        this.props.app_state.current_client.id
                    ) {
                        this.setState({
                            is_my_beneficiary: true,
                        })
                    }
                    // console.log(this.state.is_my_beneficiary);
                })
                .catch(error => {
                    console.log(error)
                    API.get(
                        'beneficiaries',
                        `/beneficiaries/getb/${this.props.beneficiaries_id}`
                    )
                        .then(beneficiaries_detail => {
                            //console.log(beneficiaries_detail);
                            this.setState({
                                loaded: true,
                                is_loading: false,
                                nickname: beneficiaries_detail[0].nickname,
                                ben_legal_name:
                                    beneficiaries_detail[0].ben_legal_name,
                                ben_address_line_1:
                                    beneficiaries_detail[0].ben_address_line_1,
                                ben_address_line_2:
                                    beneficiaries_detail[0].ben_address_line_2,
                                ben_address_suburb:
                                    beneficiaries_detail[0].ben_address_suburb,
                                ben_address_state:
                                    beneficiaries_detail[0].ben_address_state,
                                ben_address_postcode:
                                    beneficiaries_detail[0]
                                        .ben_address_postcode,
                                ben_address_country:
                                    beneficiaries_detail[0].ben_address_country,

                                bank_legal_name:
                                    beneficiaries_detail[0].bank_legal_name,
                                bank_address_line_1:
                                    beneficiaries_detail[0].bank_address_line_1,
                                bank_address_line_2:
                                    beneficiaries_detail[0].bank_address_line_2,
                                bank_address_suburb:
                                    beneficiaries_detail[0].bank_address_suburb,
                                bank_address_state:
                                    beneficiaries_detail[0].bank_address_state,
                                bank_address_postcode:
                                    beneficiaries_detail[0]
                                        .bank_address_postcode,
                                bank_address_country:
                                    beneficiaries_detail[0]
                                        .bank_address_country,

                                account_currency:
                                    beneficiaries_detail[0].account_currency,
                                swift_code: beneficiaries_detail[0].swift_code,
                                account_number:
                                    beneficiaries_detail[0].account_number,
                                aba_routing_number:
                                    beneficiaries_detail[0].aba_routing_number,
                                bsb_code: beneficiaries_detail[0].bsb_code,
                                sort_code: beneficiaries_detail[0].sort_code,
                                iban: beneficiaries_detail[0].iban,
                                transit_code:
                                    beneficiaries_detail[0].transit_code,
                                bank_code: beneficiaries_detail[0].bank_code,

                                ben_telephone_work:
                                    beneficiaries_detail[0].ben_telephone_work,
                                ben_telephone_afterhours:
                                    beneficiaries_detail[0]
                                        .ben_telephone_afterhours,
                                ben_telephone_mobile:
                                    beneficiaries_detail[0]
                                        .ben_telephone_mobile,
                                ben_email_main:
                                    beneficiaries_detail[0].ben_email_main,
                                ben_email_secondary:
                                    beneficiaries_detail[0].ben_email_secondary,
                            })
                            // this.props.loadEntityDetailsSuccess();
                            // console.log(beneficiaries_detail[0].client_id);
                            // console.log(this.props.app_state.current_client.id);
                            if (
                                beneficiaries_detail[0].client_id ===
                                this.props.app_state.current_client.id
                            ) {
                                this.setState({
                                    is_my_beneficiary: true,
                                })
                            }
                            // console.log(this.state.is_my_beneficiary);
                        })
                        .catch(error => {
                            console.log(error)
                        })
                })

            API.get('countries', `/countries/list_not_deleted`)
                .then(response => {
                    this.setState({
                        // country_list: response.fullList,
                        // country_list_prio: response.priorityonly
                        country_list_prio: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })

            API.get('currencies', `/currencies/get-list-priority`)
                .then(response => {
                    // console.log(response);
                    this.setState({
                        //currencies_list: response.fullList,
                        currencies_list_priority: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    async saveEntity(entity_detail) {
        return API.put(
            'beneficiaries',
            `/beneficiaries/update/id/${this.props.beneficiaries_id}`,
            {
                body: entity_detail,
            }
        )
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }

    handleSelectChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value,
        })

        if (event.target.id === 'nickname') {
            if (this.verifyLength(event.target.value, 3)) {
                this.setState({ [event.target.id + '_state']: 'success' })
            } else {
                this.setState({ [event.target.id + '_state']: 'error' })
            }
        }
        if (event.target.id === 'ben_legal_name') {
            if (this.verifyLength(event.target.value, 3)) {
                this.setState({ [event.target.id + '_state']: 'success' })
            } else {
                this.setState({ [event.target.id + '_state']: 'error' })
            }
        }
    }

    isValidated() {
        //return true;
        if (
            this.state.nickname_state === 'success' &&
            this.state.ben_legal_name_state === 'success'
        ) {
            return true
        } else {
            if (this.state.nickname_state !== 'success') {
                this.setState({ nickname_state: 'error' })
            }
            if (this.state.ben_legal_name_state !== 'success') {
                this.setState({ ben_legal_name_state: 'error' })
            }
        }
        return false
    }

    //function to handle toggleEdit switch
    // toggleEdit = () => {
    //   const edit_mode = !this.state.edit_mode;
    //   this.setState({ edit_mode: edit_mode });
    // };

    updateBeneficiaryActiveState = async activeStatus => {
        this.setState({
            beneficiariesActiveLoading: true,
        })
        await this.saveEntity({
            active: activeStatus,
        })
            .then(() => {
                this.setState({
                    beneficiariesActive: activeStatus,
                })
            })
            .catch(err => {
                console.log({ err })
            })
            .finally(() => {
                this.setState({
                    beneficiariesActiveLoading: false,
                })
            })
    }

    handleSubmit = async event => {
        event.preventDefault()

        if (!this.isValidated()) {
            return false
        }

        this.setState({ isLoading: true })
        const {
            nickname,
            ben_legal_name,
            ben_address_line_1,
            ben_address_line_2,
            ben_address_suburb,
            ben_address_state,
            ben_address_postcode,
            ben_address_country,

            bank_legal_name,
            bank_address_line_1,
            bank_address_line_2,
            bank_address_suburb,
            bank_address_state,
            bank_address_postcode,
            bank_address_country,

            account_currency,
            swift_code,
            account_number,
            aba_routing_number,
            bsb_code,
            sort_code,
            iban,
            ben_telephone_work,
            ben_telephone_afterhours,
            ben_telephone_mobile,
            ben_email_main,
            ben_email_secondary,
        } = this.state
        try {
            await this.saveEntity({
                nickname: nickname,
                ben_legal_name: ben_legal_name,
                ben_address_line_1: ben_address_line_1,
                ben_address_line_2: ben_address_line_2,
                ben_address_suburb: ben_address_suburb,
                ben_address_state: ben_address_state,
                ben_address_postcode: ben_address_postcode,
                ben_address_country: ben_address_country,

                bank_legal_name: bank_legal_name,
                bank_address_line_1: bank_address_line_1,
                bank_address_line_2: bank_address_line_2,
                bank_address_suburb: bank_address_suburb,
                bank_address_state: bank_address_state,
                bank_address_postcode: bank_address_postcode,
                bank_address_country: bank_address_country,

                account_currency: account_currency,
                swift_code: swift_code,
                account_number: account_number,
                aba_routing_number: aba_routing_number,
                bsb_code: bsb_code,
                sort_code: sort_code,
                iban: iban,

                ben_telephone_work: ben_telephone_work,
                ben_telephone_afterhours: ben_telephone_afterhours,
                ben_telephone_mobile: ben_telephone_mobile,
                ben_email_main: ben_email_main,
                ben_email_secondary: ben_email_secondary,
            })
            //this.props.selectBeneficiaryUi("list");
        } catch (e) {
            alert(e)
        }
    }

    render() {
        const { classes } = this.props
        if (!this.state.loaded) {
            return <span />
        }

        const swiftOption = { 5: 5, 8: 8, 9: 9 }
        const bsbOption = { 1: 1 }
        const sortCodeOption = { 8: 8, 9: 9 }
        const abaRoutingOption = { 5: 5 }
        const ibanOption = { 8: 8, 9: 9 }
        // const transitCodeOption = {8: 8, 9: 9};
        // const bankCodeOption = {8: 8, 9: 9};

        let show_swift = false
        let show_account_number = false
        let show_iban = false
        let show_bsb = false
        let show_aba = false
        let show_sort = false
        let show_transit = false
        let show_bank = false

        for (let i = 0; i < this.state.country_list_prio.length; i++) {
            if (
                this.state.country_list_prio[i].id ===
                this.state.bank_address_country
            ) {
                let country = this.state.country_list_prio[i]
                show_swift = country.use_swift === 1
                show_account_number = country.use_account_number === 1
                show_iban = country.use_iban === 1
                show_bsb = country.use_bsb_code === 1
                show_aba = country.use_aba === 1
                show_sort = country.use_sort_code === 1
                show_transit = country.use_transit_number === 1
                show_bank = country.use_bank_code === 1
            }
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <div className={classes.flexEnd}>
                                    <h4 className={classes.cardIconTitle}>
                                        {this.state.edit_mode
                                            ? `Edit Record: `
                                            : `View Record: `}
                                        {this.state.nickname}
                                    </h4>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={
                                                    this.state
                                                        .beneficiariesActiveLoading
                                                }
                                                checked={
                                                    this.state
                                                        .beneficiariesActive
                                                }
                                                onChange={event =>
                                                    this.updateBeneficiaryActiveState(
                                                        event.target.checked
                                                    )
                                                }
                                                className={
                                                    classes.beneficiariesActiveSwitch
                                                }
                                            />
                                        }
                                        label={
                                            this.state
                                                .beneficiariesActiveLoading
                                                ? 'syncing...'
                                                : this.state.beneficiariesActive
                                                    ? 'Active'
                                                    : 'Inactive'
                                        }
                                    />
                                </div>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <Beneficiary
                                            classes={classes}
                                            account_currency={
                                                this.state.account_currency
                                            }
                                            ben_legal_name={
                                                this.state.ben_legal_name
                                            }
                                            ben_legal_name_state={
                                                this.state.ben_legal_name_state
                                            }
                                            currencies_list_priority={
                                                this.state
                                                    .currencies_list_priority
                                            }
                                            edit_mode={this.state.edit_mode}
                                            nickname={this.state.nickname}
                                            nickname_state={
                                                this.state.nickname_state
                                            }
                                            handleChange={this.handleChange}
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                            handleSubmit={this.handleSubmit}
                                        />
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <BeneficiaryAddress
                                            classes={classes}
                                            ben_address_country={
                                                this.state.ben_address_country
                                            }
                                            ben_address_line_1={
                                                this.state.ben_address_line_1
                                            }
                                            ben_address_line_2={
                                                this.state.ben_address_line_2
                                            }
                                            ben_address_postcode={
                                                this.state.ben_address_postcode
                                            }
                                            ben_address_state={
                                                this.state.ben_address_state
                                            }
                                            ben_address_suburb={
                                                this.state.ben_address_suburb
                                            }
                                            country_list_prio={
                                                this.state.country_list_prio
                                            }
                                            edit_mode={this.state.edit_mode}
                                            handleChange={this.handleChange}
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                            handleSubmit={this.handleSubmit}
                                        />
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Contact
                                            ben_email_main={
                                                this.state.ben_email_main
                                            }
                                            ben_email_secondary={
                                                this.state.ben_email_secondary
                                            }
                                            ben_telephone_afterhours={
                                                this.state
                                                    .ben_telephone_afterhours
                                            }
                                            ben_telephone_mobile={
                                                this.state.ben_telephone_mobile
                                            }
                                            ben_telephone_work={
                                                this.state.ben_telephone_work
                                            }
                                            edit_mode={this.state.edit_mode}
                                            handleChange={this.handleChange}
                                            handleSubmit={this.handleSubmit}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <BankDetails
                                            classes={classes}
                                            aba_routing_number={
                                                this.state.aba_routing_number
                                            }
                                            account_number={
                                                this.state.account_number
                                            }
                                            bank_address_country={
                                                this.state.bank_address_country
                                            }
                                            bank_code={this.state.bank_code}
                                            bsb_code={this.state.bsb_code}
                                            country_list_prio={
                                                this.state.country_list_prio
                                            }
                                            iban={this.state.iban}
                                            sort_code={this.state.sort_code}
                                            swift_code={this.state.swift_code}
                                            transit_code={
                                                this.state.transit_code
                                            }
                                            show_aba={show_aba}
                                            show_account_number={
                                                show_account_number
                                            }
                                            show_bank={show_bank}
                                            show_bsb={show_bsb}
                                            show_iban={show_iban}
                                            show_sort={show_sort}
                                            show_swift={show_swift}
                                            show_transit={show_transit}
                                            edit_mode={this.state.edit_mode}
                                            handleChange={this.handleChange}
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                            handleSubmit={this.handleSubmit}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <BankAddress
                                            classes={classes}
                                            bank_address_country={
                                                this.state.bank_address_country
                                            }
                                            bank_address_line_1={
                                                this.state.bank_address_line_1
                                            }
                                            bank_address_line_2={
                                                this.state.bank_address_line_2
                                            }
                                            bank_address_postcode={
                                                this.state.bank_address_postcode
                                            }
                                            bank_address_state={
                                                this.state.bank_address_state
                                            }
                                            bank_address_suburb={
                                                this.state.bank_address_suburb
                                            }
                                            bank_legal_name={
                                                this.state.bank_legal_name
                                            }
                                            country_list_prio={
                                                this.state.country_list_prio
                                            }
                                            edit_mode={this.state.edit_mode}
                                            handleChange={this.handleChange}
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                            handleSubmit={this.handleSubmit}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <Clearfix />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

BeneficiaryEdit.propTypes = {
    classes: PropTypes.object.isRequired,
}

// export default withStyles(staffEditStyles)(BeneficiaryEdit)

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        beneficiaries: state.beneficiaries,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // loadEntityDetailsRequest: () => {
        //   dispatch(loadEntityDetailsRequest());
        // },
        fetchBeneficiaryList: () => {
            dispatch(fetchBeneficiaryList())
        },
        selectBeneficiaryUi: value => {
            dispatch(selectBeneficiaryUi(value))
        },
    }
}

const BeneficiaryEditContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(staffEditStyles)(BeneficiaryEdit))

export default BeneficiaryEditContainer
