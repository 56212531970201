import { connect } from 'react-redux'
import IdentificationEdit from './IdentificationEdit'
import {
    //   updateClient,
    loadIdentificationRecordDetailsRequest,
    loadIdentificationRecordDetailsSuccess,
    //   createCognitoAccountForClient,
} from '../../redux/actions/identification'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        identification: state.identification,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadIdentificationRecordDetailsRequest: () => {
            dispatch(loadIdentificationRecordDetailsRequest())
        },
        loadIdentificationRecordDetailsSuccess: () => {
            dispatch(loadIdentificationRecordDetailsSuccess())
        },
        // createCognitoAccountForClient: (id, username, password) => {
        //   dispatch(createCognitoAccountForClient(id, username, password))
        // },
        // updateClient: (data, id) => {
        //   dispatch(updateClient(data, id))
        // }
    }
}

const IdentificationEditContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IdentificationEdit)

export default IdentificationEditContainer
