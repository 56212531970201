import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import Button from 'components/CustomButtons/Button.jsx'

import ReactTable from 'react-table'
import { FormattedNumber } from 'react-intl'
import Table from 'components/Table/Table.jsx'
import { injectIntl } from 'react-intl'

import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

import { API } from 'aws-amplify'

import queryString from 'query-string'

const axios = require('axios')
var moment = require('moment')

const forexWorldWideLogo = require('assets/img/pdf_img/forexworldwide_logo_transparent_280x50.42dc12df.png')
const forexSportLogo = require('assets/img/pdf_img/fxsport_signature_gmail.png')
const goStudyLogo = require('assets/img/pdf_img/GOSTUDY_HorizontalUsage_Black_250x50.png')

const style = {
    root: {},
    pageHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 22,
    },
    pageSubHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 18,
    },
    tableHeaders: {
        width: 150,
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 8,
    },
    tableCells: {
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
    },
    backgroundBlue: {
        backgroundColor: '#348feb20',
    },
}

const TheadComponent = props => null // a component returning null (to hide) or you could write as per your requirement

class PayoutReceiptPage extends React.Component {
    state = {
        value: 0,
        currencies_list_priority: [],
        map_data: {},
        beneficiaries_list: [],
        beneficiary_id: '',
        transfer_heading: 'Current Transfers',
        transfer: {},
    }

    componentDidMount() {
        // console.log(this.props);
        // console.log(this.props.match.params);
        this.updateTransferData(this.props.match.params.transfer_id)
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.prefill !== this.props.prefill) {
            this.updateTransferData()
        }
    }

    updateTransferData(option_value) {
        this.setState({ transfer_id: option_value })

        API.get('transfers', `/get-full/id/${option_value}`)
            .then(response => {
                console.log(response);
                this.setState(
                    {
                        transfer: response.fullList,
                        payouts_list: response.payouts,
                        beneficiary_id: response.payouts[0].beneficiary_id,
                    },
                    () => {
                        this.queryStrings()
                        if (this.state.payout_number) {
                            this.setState({
                                beneficiary_id:
                                    response.payouts[this.state.payout_number]
                                        .beneficiary_id,
                            })
                        }
                    }
                )

                API.get(
                    'currencies',
                    `/currencies/get/${response.fullList.currency_from_id}`
                )
                    .then(response => {
                        // console.log(response);
                        this.setState({
                            currency_from: response,
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })

                API.get(
                    'currencies',
                    `/currencies/get/${response.fullList.currency_to_id}`
                )
                    .then(response => {
                        // console.log(response);
                        this.setState({
                            currency_to: response,
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error)
            })

        // //Testing to have all beneficiaries
        // API.get("beneficiaries", `/get-all`).then(response => {
        // //API.get("beneficiaries", `/beneficiaries/get`).then(response => {
        //   // console.log(response);
        //   this.setState({
        //     beneficiaries_list: response
        //   });
        //   return response;
        // });

        API.get('currencies', `/currencies/get-list-priority`)
            .then(response => {
                // console.log(response);
                this.setState({
                    currencies_list_priority: response,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    queryStrings() {
        const query_strings = queryString.parse(this.props.location.search)
        // console.log(query_strings);
        if (Object.keys(query_strings).length !== 0) {
            this.setState({ payout_number: Number(query_strings.payout) })
        }
    }

    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }

    // function that verifies if a value is larger than a number or not
    isLargerThanNumber(value, number) {
        if (value !== '' && value >= number) {
            return true
        }
        return false
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'isLargerThanNumber':
                if (
                    this.isLargerThanNumber(
                        event.target.value,
                        stateNameEqualTo
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
    }

    handleChange = (event, value) => {
        this.setState({ value })
    }
    handleChangeIndex = index => {
        this.setState({ value: index })
    }

    printDocument() {
        const input = document.getElementById('divToPrint')
        html2canvas(input).then(canvas => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF('p', 'mm', 'a4')
            var width = pdf.internal.pageSize.getWidth()
            var height = pdf.internal.pageSize.getHeight()
            pdf.addImage(imgData, 'JPEG', 0, 0, width, height)
            // pdf.output('dataurlnewwindow');
            pdf.save(
                `transfer_receipt_${this.props.match.params.transfer_id.padStart(
                    5,
                    '0'
                )}`
            )
        })
    }

    sentClientEmail() {
        console.log(this.props)
        console.log(this.state)
        console.log(this.state.payouts_list)
        console.log(this.state.payouts_list[0])
        console.log(this.state.payout_number)

        API.post('email', `/client/bene-payout-advice`, {
            body: {
                id: this.props.current_user.id,
                // rate: 0.00001,
                amount_from: this.state.transfer.amount_from,
                amount_to: this.state.transfer.amount_to,
                currency_from: this.state.currency_from,
                currency_to: this.state.currency_to,
                settlement_date: moment(
                    this.state.transfer.settlement_date
                ).format('DD/MM/YYYY'),
                payouts_list: this.state.payouts_list,
                language_id: this.props.current_user.language_id,
                transfer_id: this.state.transfer_id,
                path_payout_number: this.state.payout_number,
                beneficiary_id: this.state.beneficiary_id,
            },
        })
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    sentBeneficiaryEmail() {
        console.log(this.props)
        console.log(this.state)
        console.log(this.state.payouts_list)
        console.log(this.state.payouts_list[0])
        console.log(this.state.payout_number)
        API.post('email', `/beneficiaries/bene-payout-advice`, {
            body: {
                id: this.props.current_user.id,
                // rate: 0.00001,
                amount_from: this.state.transfer.amount_from,
                amount_to: this.state.transfer.amount_to,
                currency_from: this.state.currency_from,
                currency_to: this.state.currency_to,
                settlement_date: moment(
                    this.state.transfer.settlement_date
                ).format('DD/MM/YYYY'),
                payouts_list: this.state.payouts_list,
                beneficiary_id: this.state.beneficiary_id,
                language_id: this.props.current_user.language_id,
                transfer_id: this.state.transfer_id,
                path_payout_number: this.state.payout_number,
            },
        })
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    buildData = () => {
        let return_data = []

        return_data.push({ date: ['DATE', 'sdsaa'] })

        return return_data
    }
    //temp <img src={brandingImg}https://forexworldwide.com/static/media/forexworldwide_logo_transparent_280x50.42dc12df.png" alt="ForexWorldWide Logo"/>

    /*
   * = There are three logos images we can use in here #882 by Tom
    forexWorldWideLogo
    forexSportLogo
    goStudyLogo
  */
    getSignatureLine = brand => {
        switch (brand) {
            case '1':
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img
                                src={forexWorldWideLogo}
                                alt="ForexWorldWide Logo"
                            />
                            <br />
                            <strong>Forex WorldWide</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexworldwide.com
                            <br />T +61 (03) 9008 1880
                            <br />W www.forexworldwide.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            case '2':
                break
            case '4':
                return (
                    <React.Fragment>
                        <p>
                            <strong>Forex Student</strong>
                            <br />
                            Forex Student is a trading name of Forex Sport Pty
                            Ltd AFSL 401379.
                            <br />
                            Forex Student is a provider of money transfer
                            services for Go Study Australia Pty Ltd.
                            <br />
                            <br />
                            <table width="40%">
                                <tr>
                                    <td>
                                        <img
                                            src={forexWorldWideLogo}
                                            alt="ForexWorldWide Logo"
                                        />
                                    </td>
                                    <td>
                                        <img
                                            src={goStudyLogo}
                                            alt="ForexWorldWide Logo"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        ABN 22 147 363 175
                                        <br />
                                        AFSL 401379
                                        <br />
                                        Level 4, 100 Collins Street
                                        <br />
                                        Melbourne Vic 3000
                                        <br />
                                        <br />E admin@forexworldwide.com
                                        <br />T +61 (03) 9008 1880
                                        <br />W www.forexworldwide.com
                                        <br />
                                    </td>
                                    <td>
                                        ABN 000 000 000 <br />
                                        <br />
                                        000 <br />
                                        000
                                        <br />
                                        <br />E abc@abc.com
                                        <br />T +61 (03) 0000 0000
                                        <br />F +61 (03) 0000 0000
                                        <br />W www.gostudy.com.au
                                        <br />
                                    </td>
                                </tr>
                            </table>
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            case '24':
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img src={forexSportLogo} alt="ForexSport Logo" />
                            <br />
                            <br />
                            <strong>Forex Sport</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexsport.com
                            <br />W www.forexsport.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            case '29':
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img src={forexSportLogo} alt="ForexSport Logo" />
                            <br />
                            <strong>Forex Sport</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexsport.com
                            <br />T +61 (03) 9008 1880
                            <br />W www.forexsport.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
            case '24':
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img src={forexSportLogo} alt="ForexSport Logo" />
                            <br />
                            <br />
                            <strong>Forex Sport</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexsport.com
                            <br />W www.forexsport.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
                break
    
                default:
                return (
                    <React.Fragment>
                        <p>
                            <br />
                            <br />
                            <img
                                src={forexWorldWideLogo}
                                alt="ForexWorldWide Logo"
                            />
                            <br />
                            <strong>Forex WorldWide</strong>
                            <br />
                            ABN 22 147 363 175
                            <br />
                            AFSL 401379
                            <br />
                            Level 4, 100 Collins Street
                            <br />
                            Melbourne Vic 3000
                            <br />
                            <br />E admin@forexworldwide.com
                            <br />T +61 (03) 9008 1880
                            <br />W www.forexworldwide.com
                            <br />
                        </p>
                        <p>
                            Forex Sport Pty Ltd, ABN 22 147 363 175, is
                            regulated by the Australian Securities and
                            Investments Commission (ASIC) and holds
                            an Australian Financial Services Licence (AFSL
                            401379)
                        </p>
                    </React.Fragment>
                )
        }
    }

    render() {
        const { classes } = this.props

        if (!this.props.app_state.current_client) {
            return null
        }
        if (this.props.app_state.current_client.account_status == 1) {
            this.props.history.push('/pending-registration')
        }
        if (this.props.app_state.current_client.account_status == 5) {
            this.props.history.push('/registration')
        }

        if (this.props.app_state.current_client.account_status == 6) {
            this.props.history.push('/migrate')
        }

        if (!this.state.payouts_list) {
            return null
        }
        // assign corresponding payout in payout_list to payout
        let payout = ''
        if (
            this.state.payout_number < this.state.payouts_list.length &&
            this.state.payout_number >= 0
        )
            payout = this.state.payouts_list[this.state.payout_number]

        if (!payout)
            return (
                <React.Fragment>
                    <strong>Error: Unable to access receipt</strong>
                </React.Fragment>
            )
        if (!this.state.currency_from) return null
        if (!this.state.currency_to) return null

        // Branding for top of sidebar
        // ************************************************************************************************
        let logo = require(`../../../assets/img/receipts/${
            this.props.app_state.current_domain.domain_receipt_brand_image
        }`)
        // ************************************************************************************************

        // console.log(this.props)
        // console.log(this.state)
        return (
            <React.Fragment>
                <div>
                    <GridContainer alignItems="stretch">
                        <GridItem xs={12}>
                            <Card style={{ height: '90%' }}>
                                <CardHeader color="info" stats icon />
                                <CardBody>
                                    <p className={classes.cardCategory}>
                                        {/* {branding} */}
                                        {/*<FormattedMessage*/}
                                        {/*  id="dashboard.heading1"*/}
                                        {/*  defaultMessage={*/}
                                        {/*    `Welcome to`}*/}
                                        {/*/>*/}
                                    </p>
                                    <GridContainer justify="center">
                                        <GridItem xs={12} md={10}>
                                            <div style={{ float: 'right' }}>
                                                <Button
                                                    color="info"
                                                    style={{
                                                        float: 'right',
                                                        width: 150,
                                                    }}
                                                    onClick={() =>
                                                        this.sentClientEmail()
                                                    }
                                                >
                                                    Email me
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    style={{
                                                        float: 'right',
                                                        width: 150,
                                                    }}
                                                    onClick={() =>
                                                        this.printDocument()
                                                    }
                                                >
                                                    Save Receipt as PDF
                                                </Button>
                                            </div>
                                        </GridItem>
                                        {/* <GridItem xs={12} md={10}>
                      <div style={{ float: "right" }}>
                        <Button
                          color="info"
                          style={{ float: "right" }}
                          onClick={() => this.sentBeneficiaryEmail()}
                        >
                          Email beneficiary
                        </Button>
                      </div>
                    </GridItem> */}
                                    </GridContainer>

                                    <div
                                        id="divToPrint"
                                        className="mt4"
                                        style={{
                                            hidden: 'hidden',

                                            width: '210mm',
                                            minHeight: '297mm',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            padding: '20mm',
                                        }}
                                    >
                                        <img
                                            src={logo}
                                            alt="logo"
                                            className={classes.img}
                                        />
                                        <br />
                                        <hr />
                                        <h3 className={classes.pageHeadings}>
                                            Payment Confirmation
                                        </h3>
                                        <br />

                                        <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                <h5 className={classes.pageSubHeadings}>
                                                    Transaction Details
                                                </h5>
                                                <div
                                                    style={{
                                                        border:
                                                            '1px solid #a1a1a1',
                                                    }}
                                                >
                                                    <table style={{width: '100%'}}>
                                                        <tbody style={{
                                                                fontFamily: 'Arial',
                                                                textAlign: 'left',
                                                                verticalAlign:'top',
                                                            }}>
                                                            <tr className={classes.backgroundBlue}>
                                                                <th className={classes.tableHeaders}>
                                                                    Settlement Date
                                                                </th>
                                                                <td className={classes.tableCells}>
                                                                    <React.Fragment>
                                                                        {this
                                                                            .state
                                                                            .transfer
                                                                            .settlement_date ? (
                                                                            <React.Fragment
                                                                            >
                                                                                {moment(
                                                                                    this
                                                                                        .state
                                                                                        .transfer
                                                                                        .settlement_date
                                                                                ).format(
                                                                                    'DD/MM/YYYY'
                                                                                )}
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </React.Fragment>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    className={
                                                                        classes.tableHeaders
                                                                    }
                                                                >
                                                                    Client Name
                                                                </th>
                                                                <td
                                                                    className={
                                                                        classes.tableCells
                                                                    }
                                                                >
                                                                    <React.Fragment
                                                                    >
                                                                        {this
                                                                            .state
                                                                            .transfer
                                                                            .client_nickname
                                                                            ? this
                                                                                  .state
                                                                                  .transfer
                                                                                  .client_nickname
                                                                            : ''}
                                                                    </React.Fragment>
                                                                </td>
                                                            </tr>
                                                            <tr
                                                                className={
                                                                    classes.backgroundBlue
                                                                }
                                                            >
                                                                <th
                                                                    className={
                                                                        classes.tableHeaders
                                                                    }
                                                                >
                                                                    Transfer
                                                                    Reference
                                                                </th>
                                                                <td
                                                                    className={
                                                                        classes.tableCells
                                                                    }
                                                                >
                                                                    <React.Fragment
                                                                    >
                                                                        {this
                                                                            .state
                                                                            .transfer
                                                                            .id
                                                                            ? `TR${this.state.transfer.id
                                                                                  .toString()
                                                                                  .padStart(
                                                                                      6,
                                                                                      '0'
                                                                                  )}`
                                                                            : ''}
                                                                    </React.Fragment>
                                                                </td>
                                                            </tr>

                                                            {/* <tr>
                                                                <th
                                                                    className={
                                                                        classes.tableHeaders
                                                                    }
                                                                >
                                                                    You Buy
                                                                </th>
                                                                <td
                                                                    className={
                                                                        classes.tableCells
                                                                    }
                                                                >
                                                                    <React.Fragment
                                                                    >
                                                                        {payout.amount_to ? (
                                                                            <React.Fragment
                                                                            >
                                                                                <div
                                                                                    className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                                                                    // style={{marginTop: 8}}
                                                                                />{' '}
                                                                                {this.state.currency_to.iso_alpha_3.toUpperCase()}{' '}
                                                                                {this.props.intl.formatNumber(
                                                                                    payout.amount_to,
                                                                                    {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2,
                                                                                    }
                                                                                )}
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </React.Fragment>
                                                                </td>
                                                            </tr> */}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* <div style={{ border: "1px solid #777" }}>
                          <Table
                            size="small"
                            tableData={[
                              [
                                <span style={{ fontFamily: "Arial" }}>Date</span>,
                                <React.Fragment>
                                  {this.state.transfer.settlement_date ? (
                                    <React.Fragment>
                                      {moment(
                                        this.state.transfer.settlement_date
                                      ).format("DD/MM/YYYY")}
                                    </React.Fragment>
                                  ) : (
                                      ""
                                    )}
                                </React.Fragment>
                              ],
                              [
                                <span style={{ fontFamily: "Arial" }}>Client</span>,
                                <React.Fragment>
                                  {this.state.transfer.client_nickname
                                    ? this.state.transfer.client_nickname
                                    : ""}
                                </React.Fragment>
                              ],
                              [
                                <span style={{ fontFamily: "Arial" }}>Transfer Reference</span>,
                                <React.Fragment>
                                  {this.state.transfer.id
                                    ? `TR${this.state.transfer.id.toString().padStart(6, '0')}`
                                    : ""}
                                </React.Fragment>
                              ],
                              [
                                <span style={{ fontFamily: "Arial" }}>Client Reference</span>,
                                <React.Fragment>
                                  {this.state.payouts_list.length > 1
                                    ? "Multiple references (see below)"
                                    : this.state.payouts_list[0].detail_1}
                                </React.Fragment>
                              ],
                              [
                                <span style={{ fontFamily: "Arial" }}>Client Buys</span>,
                                <React.Fragment>
                                  {this.state.transfer.amount_to ? (
                                    <React.Fragment>
                                      <div
                                        className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                      />{" "}
                                      {this.state.currency_to.iso_alpha_3.toUpperCase()}{" "}
                                      {this.props.intl.formatNumber(
                                        this.state.transfer.amount_to,
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        }
                                      )}
                                    </React.Fragment>
                                  ) : (
                                      ""
                                    )}
                                </React.Fragment>
                              ]
                            ]}
                          />
                        </div> */}
                                                {/* <hr /> */}
                                                <br />
                                                <h5
                                                    className={
                                                        classes.pageSubHeadings
                                                    }
                                                >
                                                    Payout Details
                                                </h5>
                                                <div
                                                    style={{
                                                        border:
                                                            '1px solid #a1a1a1',
                                                    }}
                                                >
                                                    <table
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <tbody>
                                                            {/* {
                                this.state.payouts_list.map((item) => {
                                  return <tr> */}
                                                            <tr
                                                                style={{
                                                                    width:
                                                                        '100%',
                                                                }}
                                                            >
                                                                {/* <td style={{ verticalAlign: "top", paddingRight: 20, paddingLeft: 8, paddingTop: 10, paddingBottom: 10 }}> */}
                                                                <td>
                                                                    <table
                                                                        style={{
                                                                            width:
                                                                                '100%',
                                                                        }}
                                                                    >
                                                                        <tbody
                                                                            style={{
                                                                                width:
                                                                                    '100%',
                                                                            }}
                                                                        >
                                                                            <tr
                                                                                style={{
                                                                                    width:
                                                                                        '100%',
                                                                                }}
                                                                            >
                                                                                <td
                                                                                    className={
                                                                                        classes.tableCells
                                                                                    }
                                                                                >
                                                                                    <table
                                                                                        style={{
                                                                                            width:
                                                                                                '100%',
                                                                                        }}
                                                                                    >
                                                                                        <tbody
                                                                                        >
                                                                                            <tr
                                                                                                className={
                                                                                                    classes.backgroundBlue
                                                                                                }
                                                                                            >
                                                                                                <th
                                                                                                    className={
                                                                                                        classes.tableHeaders
                                                                                                    }
                                                                                                >
                                                                                                    Beneficiary
                                                                                                </th>
                                                                                                <td
                                                                                                    className={
                                                                                                        classes.tableCells
                                                                                                    }
                                                                                                >
                                                                                                    <React.Fragment
                                                                                                    >
                                                                                                        <strong
                                                                                                        >
                                                                                                            {
                                                                                                                payout.nickname
                                                                                                            }
                                                                                                        </strong>
                                                                                                    </React.Fragment>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr
                                                                                            >
                                                                                                <th
                                                                                                    className={
                                                                                                        classes.tableHeaders
                                                                                                    }
                                                                                                >
                                                                                                    Amount
                                                                                                </th>
                                                                                                <td
                                                                                                    className={
                                                                                                        classes.tableCells
                                                                                                    }
                                                                                                >
                                                                                                    <React.Fragment
                                                                                                    >
                                                                                                        <div
                                                                                                            className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                                                                                        />{' '}
                                                                                                        <strong
                                                                                                        >
                                                                                                            {this.state.currency_to.iso_alpha_3.toUpperCase()}{' '}
                                                                                                            {this.props.intl.formatNumber(
                                                                                                                payout.amount_to,
                                                                                                                {
                                                                                                                    minimumFractionDigits: 2,
                                                                                                                    maximumFractionDigits: 2,
                                                                                                                }
                                                                                                            )}
                                                                                                        </strong>
                                                                                                    </React.Fragment>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr
                                                                                                className={
                                                                                                    classes.backgroundBlue
                                                                                                }
                                                                                            >
                                                                                                <th
                                                                                                    className={
                                                                                                        classes.tableHeaders
                                                                                                    }
                                                                                                >
                                                                                                    Beneficiary Address
                                                                                                </th>
                                                                                                <td
                                                                                                    className={
                                                                                                        classes.tableCells
                                                                                                    }
                                                                                                >
                                                                                                    <React.Fragment
                                                                                                    >
                                                                                                        {payout.ben_address_line_1}&nbsp;
                                                                                                        {payout.ben_address_line_2}&nbsp;
                                                                                                        {payout.ben_address_suburb}&nbsp;
                                                                                                        {payout.ben_address_state}&nbsp;
                                                                                                        {payout.ben_address_postcode}&nbsp;
                                                                                                        {payout.beneficiary_country_name}
                                                                                                    </React.Fragment>
                                                                                                </td>
                                                                                            </tr>
                                                            {/* <tr
                                                                className={
                                                                    classes.backgroundBlue
                                                                }
                                                            >
                                                                <th
                                                                    className={
                                                                        classes.tableHeaders
                                                                    }
                                                                >
                                                                    Country
                                                                </th>
                                                                <td
                                                                    className={
                                                                        classes.tableCells
                                                                    }
                                                                >
                                                                    <React.Fragment
                                                                    >
                                                                        
                                                                    </React.Fragment>
                                                                </td>
                                                            </tr> */}
                                                                                            <tr
                                                                                                // className={
                                                                                                //     classes.backgroundBlue
                                                                                                // }
                                                                                            >
                                                                                                <th
                                                                                                    className={
                                                                                                        classes.tableHeaders
                                                                                                    }
                                                                                                >
                                                                                                    Bank
                                                                                                    Name
                                                                                                </th>
                                                                                                <td
                                                                                                    className={
                                                                                                        classes.tableCells
                                                                                                    }
                                                                                                >
                                                                                                    {payout.bank_legal_name ? (
                                                                                                        <span
                                                                                                        >
                                                                                                            {
                                                                                                                payout.bank_legal_name
                                                                                                            }
                                                                                                            <br />
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        ''
                                                                                                    )}
                                                                                                </td>
                                                                                            </tr>

                                                                                            <tr
                                                                                                className={
                                                                                                    classes.backgroundBlue
                                                                                                }
                                                                                            >
                                                                                                <th
                                                                                                    className={
                                                                                                        classes.tableHeaders
                                                                                                    }
                                                                                                >
                                                                                                    Bank Address
                                                                                                </th>
                                                                                                <td
                                                                                                    className={
                                                                                                        classes.tableCells
                                                                                                    }
                                                                                                >
                                                                                                    <React.Fragment
                                                                                                    >
                                                                                                        {payout.bank_address_line_1}&nbsp;
                                                                                                        {payout.bank_address_line_2}&nbsp;
                                                                                                        {payout.bank_address_suburb}&nbsp;
                                                                                                        {payout.bank_address_state}&nbsp;
                                                                                                        {payout.bank_address_state}&nbsp;
                                                                                                        {payout.bank_address_postcode}&nbsp;
                                                                                                        {payout.beneficiary_country_name}
                                                                                                    </React.Fragment>
                                                                                                </td>
                                                                                            </tr>

                                                                                            {payout.swift_code && (
                                                                                                <tr
                                                                                                    // className={
                                                                                                        // classes.backgroundBlue
                                                                                                    // }
                                                                                                >
                                                                                                    <th
                                                                                                        className={
                                                                                                            classes.tableHeaders
                                                                                                        }
                                                                                                    >
                                                                                                        SWIFT
                                                                                                        Code
                                                                                                    </th>
                                                                                                    <td
                                                                                                        className={
                                                                                                            classes.tableCells
                                                                                                        }
                                                                                                    >
                                                                                                        {payout.swift_code ? (
                                                                                                            <span
                                                                                                            >
                                                                                                                {
                                                                                                                    payout.swift_code
                                                                                                                }
                                                                                                                <br />
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )}
                                                                                            {payout.account_number && (
                                                                                                <tr
                                                                                                className={
                                                                                                    classes.backgroundBlue
                                                                                                }
                                                                                            >
                                                                                                <th
                                                                                                        className={
                                                                                                            classes.tableHeaders
                                                                                                        }
                                                                                                    >
                                                                                                        Account
                                                                                                        Number
                                                                                                    </th>
                                                                                                    <td
                                                                                                        className={
                                                                                                            classes.tableCells
                                                                                                        }
                                                                                                    >
                                                                                                        {payout.account_number ? (
                                                                                                            <span
                                                                                                            >
                                                                                                                {
                                                                                                                    payout.account_number
                                                                                                                }
                                                                                                                <br />
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )}
                                                                                            {payout.iban && (
                                                                                                <tr
                                                                                                >
                                                                                                    <th
                                                                                                        className={
                                                                                                            classes.tableHeaders
                                                                                                        }
                                                                                                    >
                                                                                                        IBAN
                                                                                                    </th>
                                                                                                    <td
                                                                                                        className={
                                                                                                            classes.tableCells
                                                                                                        }
                                                                                                    >
                                                                                                        {payout.iban ? (
                                                                                                            <span
                                                                                                            >
                                                                                                                {
                                                                                                                    payout.iban
                                                                                                                }
                                                                                                                <br />
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )}
                                                                                            {payout.bsb_code && (
                                                                                                <tr
                                                                                                >
                                                                                                    <th
                                                                                                        className={
                                                                                                            classes.tableHeaders
                                                                                                        }
                                                                                                    >
                                                                                                        BSB
                                                                                                    </th>
                                                                                                    <td
                                                                                                        className={
                                                                                                            classes.tableCells
                                                                                                        }
                                                                                                    >
                                                                                                        {payout.bsb_code ? (
                                                                                                            <span
                                                                                                            >
                                                                                                                {
                                                                                                                    payout.bsb_code
                                                                                                                }
                                                                                                                <br />
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )}
                                                                                            {payout.aba_routing_number && (
                                                                                                <tr
                                                                                                >
                                                                                                    <th
                                                                                                        className={
                                                                                                            classes.tableHeaders
                                                                                                        }
                                                                                                    >
                                                                                                        ABA
                                                                                                        Routing
                                                                                                    </th>
                                                                                                    <td
                                                                                                        className={
                                                                                                            classes.tableCells
                                                                                                        }
                                                                                                    >
                                                                                                        {payout.aba_routing_number ? (
                                                                                                            <span
                                                                                                            >
                                                                                                                {
                                                                                                                    payout.aba_routing_number
                                                                                                                }
                                                                                                                <br />
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )}
                                                                                            {payout.sort_code && (
                                                                                                <tr
                                                                                                >
                                                                                                    <th
                                                                                                        className={
                                                                                                            classes.tableHeaders
                                                                                                        }
                                                                                                    >
                                                                                                        Sort
                                                                                                        Code
                                                                                                    </th>
                                                                                                    <td
                                                                                                        className={
                                                                                                            classes.tableCells
                                                                                                        }
                                                                                                    >
                                                                                                        {payout.sort_code ? (
                                                                                                            <span
                                                                                                            >
                                                                                                                {
                                                                                                                    payout.sort_code
                                                                                                                }
                                                                                                                <br />
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )}
                                                                                            {payout.transit_code && (
                                                                                                <tr
                                                                                                >
                                                                                                    <th
                                                                                                        className={
                                                                                                            classes.tableHeaders
                                                                                                        }
                                                                                                    >
                                                                                                        Transit
                                                                                                        Code
                                                                                                    </th>
                                                                                                    <td
                                                                                                        className={
                                                                                                            classes.tableCells
                                                                                                        }
                                                                                                    >
                                                                                                        {payout.transit_code ? (
                                                                                                            <span
                                                                                                            >
                                                                                                                {
                                                                                                                    payout.transit_code
                                                                                                                }
                                                                                                                <br />
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )}
                                                                                            {payout.bank_code && (
                                                                                                <tr
                                                                                                >
                                                                                                    <th
                                                                                                        className={
                                                                                                            classes.tableHeaders
                                                                                                        }
                                                                                                    >
                                                                                                        Bank
                                                                                                        Code
                                                                                                    </th>
                                                                                                    <td
                                                                                                        className={
                                                                                                            classes.tableCells
                                                                                                        }
                                                                                                    >
                                                                                                        {payout.bank_code ? (
                                                                                                            <span
                                                                                                            >
                                                                                                                {
                                                                                                                    payout.bank_code
                                                                                                                }
                                                                                                                <br />
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )}

                                                                                            <tr className={classes.backgroundBlue}>
                                                                                                <th
                                                                                                    className={
                                                                                                        classes.tableHeaders
                                                                                                    }
                                                                                                >
                                                                                                    Client Ref/Inv No
                                                                                                </th>
                                                                                                <td
                                                                                                    className={
                                                                                                        classes.tableCells
                                                                                                    }
                                                                                                >
                                                                                                    <React.Fragment
                                                                                                    >
                                                                                                        {
                                                                                                            payout.detail_1
                                                                                                        }
                                                                                                    </React.Fragment>
                                                                                                </td>
                                                                                            </tr>


                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            {/* })
                              } */}
                                                        </tbody>
                                                    </table>
                                                    {/* <ReactTable
                            style={{ width: "100%" }}
                            data={this.state.payouts_list}
                            //data={this.buildTableData()}
                            // filterable
                            // === #799 Turn this off to merged by Tom ===
                            // defaultExpanded={{ 0: true }} //set to expend state later #852
                            // expanded={{ 0: true }}
                            columns={[
                              {
                                Header: "Beneficiary",
                                accessor: "beneficiary_id", //"nickname", Will switch back to nickname while next build #798
                                sortable: false,
                                filterable: false,
                                // filterMethod: (filter, rows) =>
                                //   matchSorter(rows, filter.value, { keys: ["transfer_id"] }),
                                // filterAll: true,
                                Cell: c => (
                                  //if beneficiary_id === 0, do something, work around #798
                                  <React.Fragment>
                                    {// if(beneficiary_id === 0 or null) // https://stackoverflow.com/questions/6003884/how-do-i-check-for-null-values-in-javascript
                                      c.value === 0 ||
                                        c.value === "null" ||
                                        c.value === "" ? (
                                          <React.Fragment>
                                            {<div>Beneficiary To Be Provided</div>}
                                          </React.Fragment>
                                        ) : (
                                          c.original.nickname
                                        )}
                                    {
                                      //console.log(c)
                                    }
                                  </React.Fragment>
                                ),
                                maxWidth: 450
                              },
                              {
                                Header: "Amount",
                                accessor: "amount_to",
                                sortable: false,
                                filterable: false,
                                Cell: c => (
                                  //set in a currency flag, and fix digit into minimumFractionDigits 2 and maximumFractionDigits={2}
                                  <React.Fragment>
                                    <div
                                      className={`currency-flag currency-flag-${
                                        this.state.transfer
                                          .currency_to_iso_alpha_3
                                          ? this.state.transfer.currency_to_iso_alpha_3.toLowerCase()
                                          : ""
                                        }`}
                                    />{" "}
                                    {this.state.transfer.currency_to_iso_alpha_3}{" "}
                                    <FormattedNumber
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                      value={c.value}
                                    />
                                  </React.Fragment>
                                ),
                                maxWidth: 450
                              },
                              {
                                Header: "Reference",
                                accessor: "detail_1",
                                sortable: false,
                                filterable: false,
                                // filterMethod: (filter, rows) =>
                                //   matchSorter(rows, filter.value, { keys: ["descriptiond"] }),
                                // filterAll: true,
                                maxWidth: 450
                              }
                            ]}
                            // defaultPageSize={5}
                            pageSize={
                              this.state.payouts_list.length > 10
                                ? 10
                                : this.state.payouts_list.length
                            }
                            showPaginationBottom={false}
                            className="-highlight"
                          /> */}
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                        <hr />
                                        <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                <p>
                                                    Funds will be credited to your nominated account within 24 hours.  
                                                </p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                {' '}
                                                <div>
                                                    {' '}
                                                    {this.getSignatureLine(
                                                        `${
                                                            this.props
                                                                .current_user
                                                                .team_id
                                                                ? this.props
                                                                      .current_user
                                                                      .team_id
                                                                : 0
                                                        }`
                                                    )}{' '}
                                                </div>{' '}
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </CardBody>
                                <CardFooter />
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

// const mapDispatchToProps = (dispatch) => {
// return {
//   onLoginSuccess: (user_id) => {
//     dispatch(onLoginSuccess(user_id));
//   }
// };
// };

const PayoutReceiptPageContainer = connect(
    mapStateToProps
    // mapDispatchToProps
)(injectIntl(withRouter(withStyles(style)(PayoutReceiptPage))))

export default PayoutReceiptPageContainer
