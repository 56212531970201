import React from 'react'
import { API } from 'aws-amplify'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
import Card from '../../../components/Card/Card.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import CardHeader from '../../../components/Card/CardHeader'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import Table from 'components/Table/Table.jsx'
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableRow from "@material-ui/core/TableRow";
// import Checkbox from "@material-ui/core/Checkbox";

import Check from '@material-ui/icons/Check'
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react'
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'

import {
    defaultFont,
    primaryColor,
    dangerColor,
    tooltip,
} from 'assets/jss/material-dashboard-pro-react.jsx'

var moment = require('moment')

const style = {
    ...customCheckboxRadioSwitch,
    ...extendedTablesStyle,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    infoText: {
        fontWeight: '300',
        margin: '10px 0 30px',
        textAlign: 'center',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    inputAdornment: {
        position: 'relative',
    },

    table: {
        marginBottom: '0',
    },
    tableRow: {
        position: 'relative',
        borderBottom: '1px solid #dddddd',
    },
    tableActions: {
        border: 'none',
        padding: '12px 8px !important',
        verticalAlign: 'middle',
    },
    tableCell: {
        ...defaultFont,
        padding: '0',
        verticalAlign: 'middle',
        border: 'none',
        lineHeight: '1.42857143',
        fontSize: '14px',
        width: '80%',
    },
    tableActionButton: {
        width: '27px',
        height: '27px',
        padding: '0',
    },
    tableActionButtonIcon: {
        width: '17px',
        height: '17px',
    },
    edit: {
        backgroundColor: 'transparent',
        color: primaryColor,
        boxShadow: 'none',
    },
    close: {
        backgroundColor: 'transparent',
        color: dangerColor,
        boxShadow: 'none',
    },
    tooltip,
}

class Step3Confirm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            nickname: `FX Payment ${moment().format('Do MMMM YYYY')}`,
            nickname_state: 'success',
            checked_client: true,
            checked_paying_to: false,
            checked_paying_from: false,
            checked_amount: false,
            purpose_list: [],
        }
    }
    componentDidMount() {
        this.props.updateNewTransferCreation(
            'nickname',
            `FX Transfer ${moment().format('Do MMMM YYYY')}`
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.app_state.current_client !==
            this.props.app_state.current_client
        ) {
            API.get(
                'transfers',
                `/get_purpose/id/${
                    this.props.app_state.current_client.division_id
                }`
            )
                .then(response => {
                    this.setState({
                        purpose_list: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    sendState() {
        return this.state
    }

    // function that verifies if two strings are equal
    compare(string1, string2) {
        if (string1 === string2) {
            return true
        }
        return false
    }
    // function that returns true if value is email, false otherwise
    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (emailRex.test(value)) {
            return true
        }
        return false
    }
    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'email':
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + ' _state']: 'error' })
                }
                break
            case 'equalTo':
                if (
                    this.compare(
                        event.target.value,
                        this.state[stateNameEqualTo]
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
        this.props.updateNewTransferCreation(stateName, event.target.value)
    }
    // handleChange = event => {
    //   this.setState({ selectedValue: event.target.value });
    // };
    // handleToggle = value => () => {
    //   const current_state = this.state[value];

    //   this.props.updateNewTransferCreation(value, !current_state)
    // };
    isValidated() {
        if (this.state.nickname_state !== 'success') {
            this.setState({ nickname_state: 'error' })
        }
        return (
            this.state.nickname_state === 'success'
            // this.props.payments.new_payment_data &&
            // this.props.payments.new_payment_data.checked_paying_to &&
            // //this.props.payments.new_payment_data.checked_paying_from &&
            // this.props.payments.new_payment_data.checked_amount
        )
    }
    // isValidatedForWarningMessage() {
    //   return (
    //     // this.state.nickname_state === "success" &&
    //     //this.state.checked_client &&
    //     this.state.checked_paying_to &&
    //     //this.state.checked_paying_from &&
    //     this.state.checked_amount
    //   );
    // }

    getClientPortalCardContent() {
        if (this.props.clients.new_client_created) {
            return (
                <React.Fragment>
                    <p>Client record has been created...</p>
                </React.Fragment>
            )
        }
        if (this.props.clients.new_client_submitted) {
            return (
                <React.Fragment>
                    <p>Client record is being created...</p>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <p>
                    Please press the <strong>Finish</strong> button below to
                    create this client record.
                </p>
            </React.Fragment>
        )
    }

    render() {
        const { classes } = this.props
        if (!this.props.transfers_2.new_transfer_data) {
            return null
        }
        if (!this.state.purpose_list.length > 0) {
            return null
        }
        if (!this.props.transfers_2.new_transfer_data.multiple_beneficiary_id) {
            return null
        }

        if (
            !this.state.purpose_list[
                this.props.transfers_2.new_transfer_data
                    .multiple_beneficiary_id[0].purpose_of_payment_detail - 1
            ]
        ) {
            return null
        }

        let beneficiary =
            this.props.transfers_2.new_transfer_data.beneficiary || {}
        let client = this.props.app_state.current_client || {}
        let currency_from =
            this.props.transfers_2.new_transfer_data.currency_from || {}
        let currency_to =
            this.props.transfers_2.new_transfer_data.currency_to || {}
        let current_rate = Number.parseFloat(
            this.props.transfers_2.new_transfer_data.current_rate
        ).toFixed(5)
        let amount_from = Number.parseFloat(
            this.props.transfers_2.new_transfer_data.amount_from
        )
        let amount_to = Number.parseFloat(
            this.props.transfers_2.new_transfer_data.amount_to
        )

        let payee = {}
        if (this.props.transfers_2.new_transfer_data.payee_different === '0') {
            payee.nickname = this.props.app_state.current_client
                ? this.props.app_state.current_client.nickname
                : ''
        } else {
            payee.nickname = `${this.props.transfers_2.new_transfer_data
                .payee_first_name || ''} ${this.props.transfers_2
                .new_transfer_data.payee_last_name || ''}`
        }

        return (
            <React.Fragment>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12}>
                        <h4 className={classes.infoText}>
                            You're almost finished...
                        </h4>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={10} md={6}>
                        <CustomInput
                            success={this.state.nickname_state === 'success'}
                            error={this.state.nickname_state === 'error'}
                            labelText={
                                <span>
                                    Short description to assist finding
                                    transaction
                                </span>
                            }
                            id="nickname"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: this.state.nickname,
                                onChange: event =>
                                    this.change(event, 'nickname', 'length', 3),
                            }}
                        />
                    </GridItem>
                </GridContainer>

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} lg={6}>
                        <Card style={{ height: '45%' }}>
                            <CardHeader color="rose" icon>
                                <h4 className={classes.cardIconTitle}>
                                    Confirm client
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableData={[
                                        [
                                            'Name',
                                            `${client.first_name} ${
                                                client.last_name
                                            }`,
                                        ],
                                        [
                                            'Address',
                                            `${(client.residential_street_line_1 ||
                                                '') +
                                                ' ' +
                                                (client.residential_street_line_2 ||
                                                    '') +
                                                ' ' +
                                                (client.residential_street_suburb ||
                                                    '')}`,
                                        ],
                                        ['Email', `${client.email}`],
                                    ]}
                                />
                            </CardBody>
                        </Card>
                        <Card style={{ height: '45%' }}>
                            <CardHeader color="rose" icon>
                                <h4 className={classes.cardIconTitle}>
                                    Confirm beneficiary
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableData={[
                                        [
                                            'Beneficiary',
                                            `${beneficiary.ben_legal_name}`,
                                        ],
                                        [
                                            'Address',
                                            `${(beneficiary.ben_address_line_1 ||
                                                '') +
                                                ' ' +
                                                (beneficiary.ben_address_line_2 ||
                                                    '') +
                                                ' ' +
                                                (beneficiary.ben_address_suburb ||
                                                    '')}`,
                                        ],
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={12} lg={6}>
                        <Card style={{ height: '90%' }}>
                            <CardHeader color="rose" icon>
                                <h4 className={classes.cardIconTitle}>
                                    Confirm payment
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableData={[
                                        [
                                            'Bank',
                                            `${beneficiary.bank_legal_name}`,
                                        ],
                                        ['BSB', `${beneficiary.bsb_code}`],
                                        [
                                            'Account',
                                            `${beneficiary.account_number}`,
                                        ],
                                        [
                                            'Payment for',
                                            `${
                                                this.state.purpose_list[
                                                    this.props.transfers_2
                                                        .new_transfer_data
                                                        .multiple_beneficiary_id[0]
                                                        .purpose_of_payment_detail -
                                                        1
                                                ].description
                                            }`,
                                        ],
                                        [
                                            'Further Details',
                                            `${
                                                this.props.transfers_2
                                                    .new_transfer_data.detail_1
                                                    ? this.props.transfers_2
                                                          .new_transfer_data
                                                          .detail_1
                                                    : ''
                                            }`,
                                        ],
                                        [
                                            'Amount from',
                                            `${amount_from.toFixed(2)} ${
                                                currency_from.iso_alpha_3
                                            }`,
                                        ],
                                        [
                                            'Amount to',
                                            `${amount_to.toFixed(2)} ${
                                                currency_to.iso_alpha_3
                                            }`,
                                        ],
                                        [
                                            'Rate',
                                            `${Number.parseFloat(
                                                this.props.transfers_2
                                                    .new_transfer_data
                                                    .client_rate
                                            ).toFixed(
                                                5
                                            )} locked in until ${moment()
                                                .add(10, 'minutes')
                                                .format(
                                                    'hh:mm A, DD/MM/YYYY'
                                                )}.`,
                                        ],
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </React.Fragment>
        )
    }
}

export default withStyles(style)(Step3Confirm)
