import React, {createContext, useContext} from 'react';
export const RegistrationContext = createContext({
    registration: {
        account: null,
        authorizedPerson: null,
        beneficialOwners: [],
        documents: [],
        identifications: [],
        account_type: 1,
        rego_confirm_1: false,
        rego_confirm_2: false,
        reg_disabled: true
    },
    setRegistration: useRegistrationContext
});

export function useRegistrationContext() {
    return useContext(RegistrationContext);
}