import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'

// material-ui icons
import Menu from '@material-ui/icons/Menu'
import MoreVert from '@material-ui/icons/MoreVert'
import ViewList from '@material-ui/icons/ViewList'

// core components
import HeaderLinks from './HeaderLinks'
import Button from 'components/CustomButtons/Button.jsx'
import headerStyle from 'assets/jss/material-dashboard-pro-react/components/headerStyle.jsx'

//setup for language components
let languageObj_en_json = require('../../translations/en.json')
let languageObj_zh_json = require('../../translations/zh.json')
let languageObj_de_json = require('../../translations/de.json')
let languageObj_es_json = require('../../translations/es.json')
let languageObj_fr_json = require('../../translations/fr.json')
let languageObj_it_json = require('../../translations/it.json')
let languageObj_pt_json = require('../../translations/pt.json')

function Header({ ...props }) {
    function makeBrand() {
        var name
        //setup language obj to use
        let languageObj = ''
        switch (props.language.language_current_ui) {
            case 'es':
                languageObj = languageObj_es_json
                break
            case 'de':
                languageObj = languageObj_de_json
                break
            case 'zh':
                languageObj = languageObj_zh_json
                break
            case 'fr':
                languageObj = languageObj_fr_json
                break
            case 'it':
                languageObj = languageObj_it_json
                break
            case 'pt':
                languageObj = languageObj_pt_json
                break
            default:
                languageObj = languageObj_en_json
                break
        }
        props.routes.map((prop, key) => {
            if (prop.collapse) {
                //for edit pages
                if (
                    props.location.pathname.split('/', 3)[1] +
                        props.location.pathname.split('/', 3)[2] ===
                    prop.views[1].path.split('/', 3)[1] +
                        prop.views[1].path.split('/', 3)[2]
                ) {
                    try {
                        if (
                            prop.views[1].path.split('/', 3)[2].includes('edit')
                        ) {
                            name =
                                languageObj[
                                    'icon.' +
                                        prop.views[1].path.split('/', 3)[1] +
                                        '.edit'
                                ]
                        }
                    } catch (err) {
                        name = prop.name
                    }
                }
                //for new and listing pages
                prop.views.map((prop, key) => {
                    if (prop.path === props.location.pathname) {
                        try {
                            //temp doing this for staffprofile
                            if (prop.path.split('/', 3)[2] === 'staffprofile') {
                                name =
                                    languageObj[
                                        'icon.' +
                                            prop.path.split('/', 3)[2] +
                                            ''
                                    ]
                            }
                            //other sub components
                            else if (prop.path === '/payment/new') {
                                name = languageObj['icon.transfers.newPayment']
                            } else if (prop.path === '/transfers/new') {
                                name = languageObj['icon.transfers.new']
                            } else if (prop.path === '/transfers/list') {
                                name = languageObj['icon.transfers.list']
                            } else if (prop.path === '/beneficiaries/create') {
                                name = languageObj['icon.beneficiaries.new']
                            } else if (prop.path === '/beneficiaries/upload') {
                                name = languageObj['icon.beneficiaries.upload']
                            } else if (
                                prop.path ===
                                '/beneficiaries/view-beneficiaries'
                            ) {
                                name = languageObj['icon.beneficiaries.list']
                            } else if (prop.path === '/identification/new') {
                                name = languageObj['icon.identification.new']
                            } else if (prop.path === '/identification/list') {
                                name = languageObj['icon.identification.list']
                            } else if (prop.path === '/identification/upload') {
                                name = languageObj['icon.identification.upload']
                            } else {
                                name = prop.name
                            }
                        } catch (err) {
                            name = prop.name
                        }
                    }
                    return null
                })
            }
            if (prop.path === props.location.pathname) {
                switch (prop.path) {
                    case '/externalTransfer':
                        // for dashboard
                        name = languageObj['icon.sendMoney'] //prop.name;
                        break

                    case '/user-profile':
                        name =
                            languageObj[
                                'icon.' +
                                    prop.path.split('/', 2)[1].split('-')[0] +
                                    prop.path.split('/', 2)[1].split('-')[1] +
                                    ''
                            ] //prop.name;
                        break
                    case '/multipay':
                        name = languageObj['icon.multiPay'] //prop.name;
                        break
                    case '/track_transfer':
                        name = languageObj['icon.trackTransfer'] //prop.name;
                        break
                    case '/currency-tools':
                        name = languageObj['icon.currencyTools'] //prop.name;
                        break
                    case '/registration':
                        name = 'Registration'
                        break
                    default:
                        // for dashboard and other components
                        name =
                            languageObj[
                                'icon.' + prop.path.split('/', 2)[1] + ''
                            ] //prop.name;
                        break
                }
            } else if (prop.path.toString().includes('/shortcut_transfer/')) {
                //this section for shortcut_transfer #887
                if (props.app_state.current_team) {
                    name = 'Transfer Details'
                    // languageObj["icon.sendMoneyTo"] +
                    // props.app_state.current_team.team_shortname;
                }
                return name
            } else if (
                window.location.pathname.toString().includes('registration')
            ) {
                //this section for Registration #887
                name = 'Registration'
            } else if (
                window.location.pathname.toString().includes('/transfers/edit')
            ) {
                name = 'Transfer Details'
            }
            return null
        })
        if (name) {
            return window.location.pathname.toString().includes('dashboard')
                ? props.app_state.current_team
                    ? props.app_state.current_team.brand_dashboardTitle ||
                      'Send Money'
                    : 'Send Money'
                : name
        } else {
            return 'Forex WorldWide'
        }
    }
    const { classes, color } = props
    const appBarClasses = cx({
        [' ' + classes[color]]: color,
    })
    const sidebarMinimize =
        classes.sidebarMinimize +
        ' ' +
        cx({
            [classes.sidebarMinimizeRTL]: false,
        })

    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <Hidden smDown implementation="css">
                    <div className={sidebarMinimize}>
                        {props.miniActive ? (
                            <Button
                                justIcon
                                round
                                color="white"
                                onClick={props.sidebarMinimize}
                            >
                                <ViewList className={classes.sidebarMiniIcon} />
                            </Button>
                        ) : (
                            <Button
                                justIcon
                                round
                                color="white"
                                onClick={props.sidebarMinimize}
                            >
                                <MoreVert className={classes.sidebarMiniIcon} />
                            </Button>
                        )}
                    </div>
                </Hidden>
                <div className={classes.flex}>
                    {/* Here we create navbar brand, based on route name */}
                    <Button
                        href="#"
                        className={classes.title}
                        color="transparent"
                    >
                        {makeBrand()}
                    </Button>
                </div>
                <Hidden smDown implementation="css">
                    <HeaderLinks
                        isAuthenticated={props.isAuthenticated}
                        userHasAuthenticated={props.userHasAuthenticated}
                        handleLogout={props.handleLogout}
                    />
                </Hidden>
                <Hidden mdUp implementation="css">
                    <Button
                        className={classes.appResponsive}
                        color="transparent"
                        justIcon
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu />
                    </Button>
                </Hidden>
            </Toolbar>
        </AppBar>
    )
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
    isAuthenticated: PropTypes.bool,
}

export default withStyles(headerStyle)(Header)
