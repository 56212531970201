import { connect } from 'react-redux'
import { updateNewTransferCreation } from '../../../../redux/actions/transfers'
import Step4Amounts from './Step4Amounts'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers: state.transfers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewTransferCreation: (key, value) => {
            dispatch(updateNewTransferCreation(key, value))
        },
    }
}

const Step4AmountsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Step4Amounts)

export default Step4AmountsContainer
