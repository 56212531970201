import { connect } from 'react-redux'
import IdentificationNew from './IdentificationNew'
// import {
// fetchClientList,
// selectClientUi
// } from "../../redux/actions/clients";

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        identification: state.identification,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // fetchClientList: () => {
        //   dispatch(fetchClientList())
        // },
        // selectClientUi: (data) => {
        //   dispatch(selectClientUi(data))
        // },
    }
}

const IdentificationNewContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IdentificationNew)

export default IdentificationNewContainer
