import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

// import {matchSorter} from 'match-sorter'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {FormControl} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import Datetime from 'react-datetime';

// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react'
import Button from '../../CustomButtons/Button'

import { API } from 'aws-amplify'

// react component for creating dynamic tables
import ReactTable from 'react-table'
// import { useExpanded } from "react-table";

// import Badge from "../../Badge/Badge";
// import {matchSorter} from "match-sorter";
import { FormattedNumber } from 'react-intl'

// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from '../../CustomInput/CustomInput'
// import Datetime from "react-datetime";
import CustomReactSelect from '../../Forms/CustomReactSelect/CustomReactSelect'

//component to send email #798 // copied from CRM by Tom
import TransferDetailPayoutCommunication from './TransferDetailPayoutCommunication'
// import HorizontalStepper from "../../Timeline/HorizontalStepper";

import { injectIntl } from 'react-intl'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const moment = require('moment')

const styles = {
    selectStyles,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px',
    },
    registerButton: {
        float: 'right',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    progressBar: {
        width: 50,
    },
}


const TRANSFER_STATUS_COMPLETE_VALUE = {
    1: {value: 10, text: "Draft"},
    2: {value: 20, text: "In Process"},
    3: {value: 30, text: "Acknowledged"},
    4: {value: 40, text: "Processing"},
    5: {value: 50, text: "Awaiting Funds"},
    6: {value: 60, text: "Funds Received"},
    7: {value: 100, text: "Payment Made"},
    8: {value: 100, text: "Completed"},
    9: {value: 0, text: "On Hold"},
    10: {value: 0, text: "Not Proceeding"}
  }
  



class TransferDetailPayout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // register form
            loaded: false,
            is_loading: true,
            beneficiary_list: [],
            user_beneficiaries_list: [],
            currencies_list_priority: [],
            payouts_list: [],
            country_list: [],
            currencies_list: [],
            select_done: false,
            dialog_showPayoutDetails: false
        }
    }

    componentDidMount() {
        // console.log(this.props);
        this.setState({
            beneficiary_id: this.props.beneficiary_id,
        })

        API.get('countries', `/countries/list_not_deleted`)
            .then(response => {
                this.setState({
                    country_list: response,
                })
            })
            .catch(error => {
                console.log(error)
            })

        API.get('currencies', `/currencies/get-all`)
            .then(response => {
                this.setState({
                    currencies_list: response,
                })
            })
            .catch(error => {
                console.log(error)
            })
        //End of Code block

        // console.log(this.props);
        if (this.props.beneficiary_id) {
            API.get('beneficiaries', `/get/id/${this.props.beneficiary_id}`)
                .then(response => {
                    this.setState({
                        loaded: true,
                        is_loading: false,
                        beneficiary: response,
                    })
                    // this.props.loadTransferDetailsSuccess();
                    return response
                })
                .then(response => {
                    API.get(
                        'countries',
                        `/countries/get/${response.ben_address_country}`
                    )
                        .then(response => {
                            this.setState({
                                ben_address_country: response.full_name,
                            })

                            return response
                        })
                        .catch(error => {
                            console.log(error)
                        })

                    API.get(
                        'countries',
                        `/countries/get/${response.bank_address_country}`
                    )
                        .then(response => {
                            this.setState({
                                bank_address_country: response.full_name,
                            })

                            return response
                        })
                        .catch(error => {
                            console.log(error)
                        })
                })
                .catch(error => {
                    console.log(error)
                })
        }

        if (this.props.currency_to_id) {
            API.get(
                'currencies',
                `/currencies/get/${this.props.currency_to_id}`
            )
                .then(response => {
                    // console.log(response);
                    this.setState({
                        currency_to: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }

        if (this.props.currency_from_id) {
            API.get(
                'currencies',
                `/currencies/get/${this.props.currency_from_id}`
            )
                .then(response => {
                    // console.log(response);
                    this.setState({
                        currency_from: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }

        if (this.props.transfer_id) {
            API.get('transfers', `/get-full/id/${this.props.transfer_id}`)
                .then(response => {
                    // console.log(response);
                    this.setState({
                        transfer: response.fullList,
                        payouts_list: response.payouts,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }

        //Client Email: client_email by id
        if (this.props.client_id) {
            API.get('clients', `/clients/get/id/${this.props.client_id}`)
                .then(response => {
                    // console.log(response);
                    this.setState({
                        clients_email: response.email,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.remitting_bank_id !== this.props.remitting_bank_id) {
            API.get(
                'entitybanksaccount',
                `/get/id/${this.props.remitting_bank_id}`
            )
                .then(response => {
                    // console.log(response);
                    this.setState({
                        loaded: true,
                        is_loading: false,
                        entity_bank_account: response,
                    })
                    // this.props.loadTransferDetailsSuccess();
                    return response[0]
                })
                .then(response => {
                    // console.log(response);
                    API.get('entitybanks', `/get/id/${response.entity_bank_id}`)
                        .then(response => {
                            this.setState({
                                loaded: true,
                                is_loading: false,
                                entity_bank: response,
                            })

                            return response
                        })
                        .catch(error => {
                            console.log(error)
                        })
                })
                .catch(error => {
                    console.log(error)
                })
        }
        if (prevProps.currency_from_id !== this.props.currency_from_id) {
            API.get(
                'currencies',
                `/currencies/get/${this.props.currency_from_id}`
            )
                .then(response => {
                    this.setState({
                        currency_from: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }


    handleDialogPayoutDetailsClose = () => {
        this.setState({dialog_showPayoutDetails: false});
      };
    


    render() {
        const { classes } = this.props
        // if (!this.state.loaded || !this.state.currency_to || !this.state.beneficiary || !this.state.transfer)
        // return null;
        if (!this.state.currency_to) return null
        if (!this.state.transfer) return null


        const columns= this.props.small_version ? 
        [
            {
                Header: 'Name',
                accessor: 'id', //"nickname", use c.original.nickname to get that value
                Cell: c => {
                    return c.original
                        .nickname
                },
                maxWidth: 450,
            },
            // {
            //     Header: 'Address',
            //     accessor:
            //         'ben_address_line_1', //"nickname", use c.original.nickname to get that value
            //     Cell: c => (
            //         <React.Fragment>
            //             <div>
            //                 {
            //                     c.original
            //                         .ben_address_line_1
            //                 }
            //                 {
            //                     c.original
            //                         .ben_address_suburb
            //                 }
            //                 {
            //                     c.original
            //                         .ben_address_postcode
            //                 }
            //             </div>
            //         </React.Fragment>
            //     ),
            //     maxWidth: 450,
            // },
            {
                Header: 'Bank',
                accessor: 'bank_legal_name', //"nickname", use c.original.nickname to get that value
                Cell: c => {
                    if (
                        c.original
                            .holdingAccountPayee
                    ) {
                        return (
                            <React.Fragment>
                                Ledger
                                Payment
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment>
                                {
                                    c
                                        .original
                                        .bank_legal_name
                                }
                                {
                                    c
                                        .original
                                        .bank_address_line_1
                                }
                            </React.Fragment>
                        )
                    }
                },
                maxWidth: 450,
            },
            {
                Header: 'Account',
                accessor: 'swift_code', //"nickname", use c.original.nickname to get that value
                Cell: c => {
                    if (
                        c.original
                            .holdingAccountPayee
                    ) {
                        return (
                            <React.Fragment>
                                Internal ID
                                #
                                {
                                    c
                                        .original
                                        .holdingAccountPayee
                                }
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment>
                                { c.original.swift_code
                                    ? <>{c.original.swift_code}<br/></>
                                    : null
                                }
                                {
                                    c
                                        .original
                                        .aba_routing_number
                                }
                                { c.original.iban
                                    ? <>IBAN {c.original.iban}<br/></>
                                    : null
                                }
                                { c.original.account_number
                                    ? <>Account {c.original.account_number}<br/></>
                                    : null
                                }
                            </React.Fragment>
                        )
                    }
                },
                maxWidth: 450,
            },
            {
                Header: 'Amount',
                accessor: 'amount_to',
                Cell: c => (
                    <React.Fragment>
                        <div
                            className={`currency-flag currency-flag-${
                                this.state
                                    .transfer
                                    .currency_to_iso_alpha_3
                                    ? this.state.transfer.currency_to_iso_alpha_3.toLowerCase()
                                    : ''
                            }`}
                        />{' '}
                        {
                            this.state
                                .transfer
                                .currency_to_iso_alpha_3
                        }{' '}
                        <br />
                        <FormattedNumber
                            minimumFractionDigits={
                                2
                            }
                            maximumFractionDigits={
                                2
                            }
                            value={c.value}
                        />
                    </React.Fragment>
                ),
                maxWidth: 450,
            },
        ]
        : [
            {
                Header: 'Name',
                accessor: 'id', //"nickname", use c.original.nickname to get that value
                // filterMethod: (filter, rows) =>
                //   matchSorter(rows, filter.value, { keys: ["transfer_id"] }),
                // filterAll: true,
                Cell: c => {
                    return c.original
                        .nickname
                    // //if beneficiary_id === 0, do something, work around #798
                    //   if (!c.original.holdingAccountPayee) {
                    //     return (c.value === 0 ||
                    //             c.value === "null" ||
                    //             c.value === "") ? <div>Beneficiary To Be Provided</div>
                    //                             :  c.original.nickname
                    //   }
                },
                maxWidth: 450,
            },
            {
                Header: 'Address',
                accessor:
                    'ben_address_line_1', //"nickname", use c.original.nickname to get that value
                // filterMethod: (filter, rows) =>
                //   matchSorter(rows, filter.value, { keys: ["transfer_id"] }),
                // filterAll: true,
                Cell: c => (
                    //if beneficiary_id === 0, do something, work around #798
                    <React.Fragment>
                        <div>
                            {
                                c.original
                                    .ben_address_line_1
                            }
                            {
                                c.original
                                    .ben_address_suburb
                            }
                            {
                                c.original
                                    .ben_address_postcode
                            }
                        </div>
                    </React.Fragment>
                ),
                maxWidth: 450,
            },
            {
                Header: 'Bank',
                accessor: 'bank_legal_name', //"nickname", use c.original.nickname to get that value
                // filterMethod: (filter, rows) =>
                //   matchSorter(rows, filter.value, { keys: ["transfer_id"] }),
                // filterAll: true,
                Cell: c => {
                    if (
                        c.original
                            .holdingAccountPayee
                    ) {
                        return (
                            <React.Fragment>
                                Ledger
                                Payment
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment>
                                {
                                    c
                                        .original
                                        .bank_legal_name
                                }
                                {
                                    c
                                        .original
                                        .bank_address_line_1
                                }
                            </React.Fragment>
                        )
                    }
                },
                maxWidth: 450,
            },
            {
                Header: 'Account Details',
                accessor: 'swift_code', //"nickname", use c.original.nickname to get that value
                // filterMethod: (filter, rows) =>
                //   matchSorter(rows, filter.value, { keys: ["transfer_id"] }),
                // filterAll: true,
                Cell: c => {
                    if (
                        c.original
                            .holdingAccountPayee
                    ) {
                        return (
                            <React.Fragment>
                                Internal ID
                                #
                                {
                                    c
                                        .original
                                        .holdingAccountPayee
                                }
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment>
                                { c.original.swift_code
                                    ? <>{c.original.swift_code}<br/></>
                                    : null
                                },
                                {
                                    c
                                        .original
                                        .aba_routing_number
                                }
                                {
                                    c
                                        .original
                                        .iban
                                }{' '}
                                {
                                    c
                                        .original
                                        .account_number
                                }
                            </React.Fragment>
                        )
                    }
                },
                maxWidth: 450,
            },
            {
                Header: 'Amount',
                accessor: 'amount_to',
                Cell: c => (
                    <React.Fragment>
                        <div
                            className={`currency-flag currency-flag-${
                                this.state
                                    .transfer
                                    .currency_to_iso_alpha_3
                                    ? this.state.transfer.currency_to_iso_alpha_3.toLowerCase()
                                    : ''
                            }`}
                        />{' '}
                        {
                            this.state
                                .transfer
                                .currency_to_iso_alpha_3
                        }{' '}
                        <br />
                        <FormattedNumber
                            minimumFractionDigits={
                                2
                            }
                            maximumFractionDigits={
                                2
                            }
                            value={c.value}
                        />
                    </React.Fragment>
                ),
                maxWidth: 450,
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: c => (
                    <React.Fragment>
                        <div>
                            <CircularProgressbar
                                className={
                                    classes.progressBar
                                }
                                value={
                                    this
                                        .props
                                        .isCompleted
                                        ? 100
                                        : this
                                              .state
                                              .transfer
                                              .status ==
                                          6
                                            ? 70
                                            : this
                                                  .props
                                                  .transfer_status[0]
                                                  .status_id *
                                              10
                                }
                                text={`${parseFloat(
                                    this
                                        .props
                                        .isCompleted
                                        ? 10
                                        : this
                                              .state
                                              .transfer
                                              .status ==
                                          6
                                            ? 7
                                            : this
                                                  .props
                                                  .transfer_status[0]
                                                  .status_id
                                ) * 10}%`}
                                styles={buildStyles(
                                    {
                                        textColor: `#000`,
                                        textSize:
                                            '24px',
                                        pathColor:
                                            '#632DE6',
                                    }
                                )}
                            />
                            {this.props
                                .isCompleted ? (
                                <span>
                                    Completed
                                </span>
                            ) : this.state
                                .transfer
                                .status ==
                            6 ? (
                                <span>
                                    Processing
                                </span>
                            ) : (
                                <span>
                                    In
                                    Process
                                </span>
                            )}
                        </div>
                    </React.Fragment>
                ),
                maxWidth: 450,
            },
            {
                Header: 'Action',
                accessor: 'beneficiary_id',
                Cell: c => (
                    <React.Fragment>
                        {
                            <span>
                                {<>
                                    <Button
                                        fullWidth="true"
                                        color="info"
                                        disabled={
                                            this
                                                .state
                                                .transfer
                                                .status !==
                                            8
                                        } //disable if transfer status is not completed
                                        size="sm"
                                        onClick={() => {
                                            var win = window.open(
                                                `/transfers/receipt/payout/${
                                                    c
                                                        .original
                                                        .transfer_id
                                                }?payout=${
                                                    c.index
                                                }`,
                                                '_blank'
                                            )
                                            win.focus()
                                        }}
                                    >
                                        Receipt
                                    </Button>
                                    <br/>
                                    <Button
                                        fullWidth={true}
                                        color='info'
                                        size='sm'
                                        onClick={() => {
                                        this.setState({
                                            dialog_showPayoutDetails: true,
                                            currentRecord: c.original
                                        });
                                        }}
                                    >
                                        View Details
                                    </Button>
                                </>}
                            </span>
                        }
                    </React.Fragment>
                ),
                maxWidth: 450,
            },
        ]

        // console.log(this.state);
        // console.log(this.props);
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                        {/* <GridItem xs={12} sm={12} md={12}>
            <HorizontalStepper transfer_status={this.props.transfer_status}/>
          </GridItem> */}
                        <GridItem xs={12} sm={12} md={12}>
                            <Card style={{ height: '90%' }}>
                                <CardHeader>
                                    <h4>Payout Details</h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <ReactTable
                                            style={{ width: '100%' }}
                                            data={this.state.payouts_list}
                                            //data={this.buildTableData()}
                                            // filterable
                                            // === #799 Turn this off to merged by Tom ===
                                            // defaultExpanded={{ 0: true }} //set to expend state later #852
                                            //defaultExpanded={{ 0: false }} //`${this.state.select_done}`
                                            //expanded={{ 0: `${this.state.select_done}` }}
                                            columns={columns}
                                            defaultPageSize={
                                                this.state.payouts_list.length
                                            }
                                            //showPageSizeOptions={true}
                                            // showPaginationTop
                                            showPaginationBottom={false}
                                            className="-highlight"
                                        />
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>

            <Dialog
              open={
                this.state.dialog_showPayoutDetails &&
                this.state.currentRecord &&
                Object.values(this.state.currentRecord).length > 0
              }
              onClose={this.handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>Transfer Detail</DialogTitle>
              <DialogContent>
                <GridContainer>
                  <GridItem xs={6}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Name </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value: this.state.currentRecord && this.state.currentRecord.ben_legal_name,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Address </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              this.state.currentRecord &&
                              `${this.state.currentRecord.ben_address_line_1} ${this.state.currentRecord.ben_address_suburb}`,
                            disabled: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Bank </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              this.state.currentRecord &&
                              `${this.state.currentRecord.bank_legal_name} ${this.state.currentRecord.bank_address_line_1}`,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Swift </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              this.state.currentRecord &&
                              `${this.state.currentRecord.swift_code} ${this.state.currentRecord.aba_routing_number}`,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Detail 1 </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              this.state.currentRecord &&
                              `${this.state.currentRecord.detail_1}`,
                            disabled: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12}>
                        {/* <CustomInput
                      labelText={<span>Amount </span>}
                      id="account_number"
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: this.state.currentRecord && this.state.currentRecord.ben_legal_name,
                        disabled: true
                      }}
                    /> */}
                        <React.Fragment>
                          <label>
                            <span style={{fontSize: '11px'}}>Amount</span>
                          </label>{' '}
                          <br />
                          <div
                            className={`currency-flag currency-flag-${
                              this.state.transfer && this.state.transfer.currency_to_iso_alpha_3
                                ? this.state.transfer.currency_to_iso_alpha_3.toLowerCase()
                                : ''
                            }`}
                          />{` `}
                          {this.state.transfer && this.state.transfer.currency_to_iso_alpha_3}{`  `}
                          <FormattedNumber
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            value={this.state.currentRecord && this.state.currentRecord.amount_to}
                          />
                        </React.Fragment>
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Purpose </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value: this.state.currentRecord && `${this.state.currentRecord.description} `,
                            disabled: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12}>
                        {/* <CustomInput
                      labelText={<span>Status </span>}
                      id="account_number"
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: parseInt(this.props.transfer_status) *,
                        disabled: true
                      }}
                    /> */}
                        <label>
                          <span style={{fontSize: '11px'}}>Status</span>
                        </label>
                        <div>
                          <CircularProgressbar
                            className={classes.progressBar}
                            value={this.props.isCompleted ? 100 : TRANSFER_STATUS_COMPLETE_VALUE[this.props.status].value}
                            text={`${this.props.isCompleted ? 100 : TRANSFER_STATUS_COMPLETE_VALUE[this.props.status].value}%`}
                            styles={buildStyles({
                              textColor: '#000',
                              textSize: '24px',
                              pathColor: '#632DE6'
                            })}
                          />
                          <span>{TRANSFER_STATUS_COMPLETE_VALUE[this.props.status].text}</span>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                </GridContainer>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleDialogPayoutDetailsClose} color='primary'>
                  Close
                </Button>
              </DialogActions>
            </Dialog>




                        {/* THIS ITEM IS COMMENTED OUT UNTIL REVIEW IN - TRANSFER PAYOUT COMMUNICATION EPIC - ANDRE  */}
                        {/* <GridItem md={4}>
              <Card style={{ height: "95%" }}>
                <CardHeader>
                  <h6>Payout Advice</h6>
                </CardHeader>
                <CardBody>
                  <TransferDetailPayoutCommunication
                    id={this.props.client_id}
                    rate={this.props.rate}
                    amount_from={this.props.amount_from}
                    amount_to={this.props.amount_to}
                    currency_from={this.state.currency_from}
                    currency_to={this.state.currency_to}
                    beneficiary_id={this.state.beneficiary_id}
                    payouts_list={this.state.payouts_list}
                    transfer_id={this.props.transfer_id}
                    transfer_status={this.state.transfer.status}
                    // currency_from={this.state.currency_from.full_name}
                    // eba_account_name={this.state.entity_bank_account.eba_accout_name}
                    enabled={
                      this.props.isCompleted
                      //true
                      //this.props.current_staff_super_admin
                    }
                    language_nickname={this.state.language_nickname}
                    //language_id={this.props}
                    // remitting_bank_id={this.props.remitting_bank_id}
                    // remittance_reference={remittance_reference}
                    clients_email={this.state.clients_email}
                  />
                </CardBody>
              </Card>
            </GridItem> */}
                        {/* THIS ITEM IS COMMENTED OUT UNTIL REVIEW IN - TRANSFER PAYOUT COMMUNICATION EPIC - ANDRE  */}
                    </GridContainer>
                </form>
            </React.Fragment>
        )
    }
    // let remittance_reference = "";
}

TransferDetailPayout.propTypes = {
    classes: PropTypes.object.isRequired,
    transfer_id: PropTypes.string,
    transfer_nickname: PropTypes.string,
    beneficiary_id: PropTypes.number,
    currency_to_id: PropTypes.number,
    amount_to: PropTypes.number,
    rate: PropTypes.number,
    client_id: PropTypes.number,
    language_id: PropTypes.number,
    isCompleted: PropTypes.bool,
}

export default injectIntl(withRouter(withStyles(styles)(TransferDetailPayout)))
