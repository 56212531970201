import React from 'react'
import { connect } from 'react-redux'
import { API } from 'aws-amplify'
// import {
//   fetchIdentificationList,
//   fetchIdentificationListAll
//   // setBeneficiarySelectId
// } from "../../redux/actions/identification";

// react component for creating dynamic tables
import ReactTable from 'react-table'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'

import Dvr from '@material-ui/icons/Dvr'
import Button from 'components/CustomButtons/Button.jsx'

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'
import { matchSorter } from 'match-sorter'
import {Flex, Table} from "antd";
import ListTable from "../ListTable/ListTable";
import {CSVLink} from "react-csv";
import {DownloadOutlined} from "@ant-design/icons";

var moment = require('moment')

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
    },
}

/**
 * ----------------------------------------------------------------------------
 * CLASS - IdentificationList
 * ----------------------------------------------------------------------------
 */
class HoldingAccountList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            holding_account_list: [],
            dataToDownload: []
        }
    }

    fetchHoldingAccounts = () => {
        API.get(
            'holding_accounts',
            `/get/all/v2/${this.props.app_state.current_client.id}`
        )
            .then(response => {
                this.setState({
                    holding_account_list: response.holding_account_list,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidUpdate(prevprops) {
        if (this.props.state.refresh != prevprops.state.refresh) {
            this.fetchHoldingAccounts()
        }
        if (prevprops.app_state !== this.props.app_state) {
            this.fetchHoldingAccounts()
        }
    }

    componentDidMount() {
        this.fetchHoldingAccounts()
    }

    // componentDidUpdate(prevProps) {
    //   if (prevProps.app_state !== this.props.app_state) {
    //     this.fetchHoldingAccounts()
    //   }
    // }

    buildTableData() {
        var holding_account_list_length = this.state.holding_account_list.length
        if (holding_account_list_length > 0) {
            // eslint-disable-next-line no-unused-vars
            return this.state.holding_account_list.map((prop, key) => {
                return {
                    id: prop.id,
                    client_id: prop.client_id,
                    client_nickname: prop.client_nickname,
                    currency_id: prop.currency_id,
                    currencies_short_name: prop.currencies_short_name,
                    currencies_full_name: prop.currencies_full_name,
                    balance: prop.balance,
                    actions: (
                        <div className="actions-right">
                            <Button
                                // justIcon
                                // round
                                // simple
                                onClick={() => {
                                    this.props.history.push(
                                        `/holding-account?account=${prop.id}`
                                    )
                                }}
                                color="warning"
                                className="edit"
                            >
                                View
                            </Button>
                        </div>
                    ),
                }
            })
        }
    }

    download = event => {
        const currentRecords = this.buildTableData().map(v => {
            const {actions, ...rest} = v;
            return rest;
        });
        const columns = this.buildColumns()
            .filter(col => Object.keys(col).length > 0)
            .filter(i => !['actions'].includes(i.dataIndex));

        const data_to_download = [];
        for (let index = 0; index < currentRecords.length; index++) {
            let record_to_download = {};
            for (let colIndex = 0; colIndex < columns.length; colIndex++) {
                record_to_download[columns[colIndex].title] = currentRecords[index][columns[colIndex].dataIndex];
            }
            record_to_download.Balance = record_to_download.Balance.toFixed(2);
            data_to_download.push(record_to_download);
        }
        this.setState({dataToDownload: data_to_download}, () => {
            // click the CSVLink component to trigger the CSV download
            this.csvLink.link.click();
        });
    };

    buildColumns = () => {
        return [
            {
                title: 'Currencies',
                dataIndex: 'currencies_short_name',
                filters: this.props.app_state.currency_list.map(i => ({ text: i.iso_alpha_3, value: i.iso_alpha_3})),
                onFilter: (text, record) => record.currencies_short_name === text,
                sorter: (a, b) => a.currencies_short_name.localeCompare(b.currencies_short_name),
                ...this.props.getColumnSearchProps({
                    dataIndex: 'currencies_short_name',
                    filterInputType: 'SELECT',
                    render: (text, c) => (
                        <>
                            <div
                                style={{ marginLeft: 6 }}
                                className={`currency-flag currency-flag-${text.toLowerCase()}`}
                            />
                            &nbsp;
                            {`  `}
                            {text}
                        </>
                    ),
                })
            },
            {
                title: '',
                dataIndex: 'currencies_full_name',
            },
            {
                title: 'Balance',
                dataIndex: 'balance',
                align: 'right',
                sorter: (a, b) => a.balance - b.balance,
                render: (text, record) => {
                    if (text) {
                        return (
                            <React.Fragment>
                                <div style={{textAlign: 'right'}}>
                                    {new Intl.NumberFormat('en-GB', {
                                        style: 'currency',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        currency: record.currencies_short_name,
                                        currencyDisplay: 'narrowSymbol'
                                    }).format(text)}
                                </div>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment>
                                <div style={{textAlign: 'right'}}>
                                    {new Intl.NumberFormat('en-GB', {
                                        style: 'currency',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        currency: record.currencies_short_name,
                                        currencyDisplay: 'narrowSymbol'
                                    }).format(0)}
                                </div>
                            </React.Fragment>
                        )
                    }
                },
            },
            {
                title: '',
                dataIndex: 'actions'
            },
        ];
    }

    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button type={'primary'} onClick={(e) => this.download(e)} icon={<DownloadOutlined />}>Download CSV</Button>
                </div>
                <Table
                dataSource={this.buildTableData()}
                columns={this.buildColumns()}
            />
                <CSVLink data={this.state.dataToDownload} filename="data.csv" className={'hidden'} ref={r => (this.csvLink = r)} target={'_blank'}/>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // fetchIdentificationList: (client_id) => {
        //   dispatch(fetchIdentificationList(client_id))
        // },
        // fetchIdentificationListAll: () => {
        //   dispatch(fetchIdentificationListAll())
        // }
        // setBeneficiarySelectId: (id) => {
        //   dispatch(setBeneficiarySelectId(id))
        // }
    }
}

const HoldingAccountListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ListTable(HoldingAccountList))

export default withRouter(withStyles(styles)(HoldingAccountListContainer))
