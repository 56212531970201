import {
    LANGUAGE_FETCH_LANGUAGE_LIST_REQUEST,
    LANGUAGE_FETCH_LANGUAGE_LIST_SUCCESS,
    LANGUAGE_SELECT_UI,
    LANGUAGE_SELECT_SET_ID,
} from '../actions/language.js'

const initialState = {
    language_current_ui: 'en',
    language_list: ['en', 'de', 'es', 'cn', 'sg'],
}

function language(state = initialState, action) {
    switch (action.type) {
        case LANGUAGE_FETCH_LANGUAGE_LIST_REQUEST:
            return Object.assign({}, state, {
                is_fetching: true,
            })

        case LANGUAGE_FETCH_LANGUAGE_LIST_SUCCESS:
            return Object.assign({}, state, {
                is_fetching: false,
                language_list: action.language_list,
                lastUpdated: action.receivedAt,
            })

        case LANGUAGE_SELECT_UI:
            return Object.assign({}, state, {
                language_current_ui: action.value,
            })

        case LANGUAGE_SELECT_SET_ID:
            return Object.assign({}, state, {
                language_detail_id: action.value,
            })

        default:
            return state
    }
}

export default language
