import RateTickerVerticalBoxLarge from '../views/Widgets/FxRateBox'
import FxRatesBoxHorizontal from '../views/Widgets/FxRatesBoxHorizontal/'

const widgetsRoutes = [
    {
        path: '/widgets/fx-rates-box',
        name: 'FX Rates Box',
        short: 'FxRatesBox',
        mini: 'EI',
        invisible: true,
        icon: null,
        component: RateTickerVerticalBoxLarge,
    },
    {
        path: '/widgets/fx-rates-box-horizontal',
        name: 'FX Rates Box Horizontal',
        short: 'FxRatesBoxHorizontal',
        mini: 'EI',
        invisible: true,
        icon: null,
        component: FxRatesBoxHorizontal,
    },
    // {
    //   redirect: true,
    //   path: "/pages",
    //   pathTo: "/pages/login-page",
    //   name: "Login Page"
    // }
]

export default widgetsRoutes
