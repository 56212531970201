import { API } from 'aws-amplify'
import moment from "moment";

export const FETCH_FORWARD_LIST_REQUEST = 'FETCH_FORWARD_LIST_REQUEST'
export const FETCH_FORWARD_LIST_SUCCESS = 'FETCH_FORWARD_LIST_SUCCESS'
export const FETCH_FORWARD_LIST_FAILURE = 'FETCH_FORWARD_LIST_FAILURE'
export const FETCH_FORWARD_DETAIL_REQUEST = 'FETCH_FORWARD_DETAIL_REQUEST'
export const FETCH_FORWARD_DETAIL_SUCCESS = 'FETCH_FORWARD_DETAIL_SUCCESS'
export const FETCH_FORWARD_DETAIL_FAILURE = 'FETCH_FORWARD_DETAIL_FAILURE'
export const FETCH_FORWARD_DRAWDOWN_REQUEST = 'FETCH_FORWARD_DRAWDOWN_REQUEST'
export const FETCH_FORWARD_DRAWDOWN_SUCCESS = 'FETCH_FORWARD_DRAWDOWN_SUCCESS'
export const FETCH_FORWARD_DRAWDOWN_FAILURE = 'FETCH_FORWARD_DRAWDOWN_FAILURE'

export function fetchForwardListRequest() {
    return {
        type: FETCH_FORWARD_LIST_REQUEST,
    }
}

export function fetchForwardListSuccess(data) {
    return {
        type: FETCH_FORWARD_LIST_SUCCESS,
        data,
        receivedAt: Date.now(),
    }
}

export function fetchForwardListFailure(data) {
    return {
        type: FETCH_FORWARD_LIST_FAILURE,
    }
}

export function fetchForwardDetailsRequest() {
    return {
        type: FETCH_FORWARD_DETAIL_REQUEST,
    }
}

export function fetchForwardDetailsSuccess(data) {
    return {
        type: FETCH_FORWARD_DETAIL_SUCCESS,
        data,
    }
}

export function fetchForwardDetailsFailure() {
    return {
        type: FETCH_FORWARD_DETAIL_FAILURE,
    }
}

export function fetchForwardDrawDownRequest() {
    return {
        type: FETCH_FORWARD_DRAWDOWN_REQUEST,
    }
}

export function fetchForwardDrawDownSuccess(data) {
    return {
        type: FETCH_FORWARD_DRAWDOWN_SUCCESS,
        data,
    }
}

export function fetchForwardDrawDownFailure() {
    return {
        type: FETCH_FORWARD_DRAWDOWN_FAILURE,
    }
}

export function fetchForwardList(clientId) {
    return function(dispatch) {
        dispatch(fetchForwardListRequest())
        API.get('forwards', `/get/list/${clientId}`, {})
            .then(response => {
                dispatch(fetchForwardListSuccess(response))
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchForwardListFailure())
            })
    }
}

export function fetchForwardDetails(id) {
    return function(dispatch) {
        dispatch(fetchForwardDetailsRequest())
        API.get('forwards', `/get/id/${id}`)
            .then(response => {
                dispatch(fetchForwardDetailsSuccess(response))
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchForwardDetailsFailure())
            })
    }
}

export function fetchForwardDrawDowns(id) {
    return function(dispatch) {
        dispatch(fetchForwardDrawDownRequest())
        API.get('forwardsDrawDown', `/forwardId/${id}`)
            .then(response => {
                response.forEach(item => {
                    item.settlement_date = moment(item.settlement_date).format('DD/MM/YYYY');
                    item.transaction_datetime = moment(item.transaction_datetime).format('DD/MM/YYYY');
                });
                dispatch(fetchForwardDrawDownSuccess(response))
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchForwardDrawDownFailure())
            })
    }
}
