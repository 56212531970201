import React from 'react'
import { connect } from 'react-redux'
import { clearNewTransfer } from '../../redux/actions/transfers_dashboard_multiple'

import withStyles from '@material-ui/core/styles/withStyles'
import dashboardStyle from '../../views/Pages/DashboardPageStyle'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'

//core for react-intl
import { FormattedMessage, injectIntl } from 'react-intl'
import Table from 'components/Table/Table.jsx'
import { withRouter } from 'react-router-dom'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const moment = require('moment')

class DashboardTransferStep3 extends React.Component {
    constructor(props) {
        super(props)
        this.state = { current_step: 3 }
    }

    componentDidMount() {
        this.stopTimer()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.prefill !== this.props.prefill) {
            // clearNewTransfer in DashboardTransfer
            this.props.switch_to_step1()
        }
    }

    stopTimer = () => {
        this.go = false
        this.setState({
            seconds: 0,
            time: 0,
        })
        clearInterval(this.timer)
        this.timer = null
    }

    handle_new_transfer() {
        this.props.clearNewTransfer()
        this.props.switch_to_step1()
    }

    handle_view_transfers() {
        this.props.history.push(`/transfers/list`)
    }

    render() {
        const { classes } = this.props

        let request_summary = `You sell
      ${this.props.intl.formatNumber(
          this.props.transfers_dashboard_multiple.new_transfer_data.amount_from,
          {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }
      )} ${this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}
      to buy ${this.props.intl.formatNumber(
          this.props.transfers_dashboard_multiple.new_transfer_data.amount_to,
          {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }
      )} ${this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}
      `

        let table_data_beneficiary = []
        let table_data_purpose = []
        let table_data_invoice = []

        for (let idx in this.props.transfers_dashboard_multiple
            .new_transfer_data.multiple_beneficiary_id) {
            let payment = this.props.transfers_dashboard_multiple
                .new_transfer_data.multiple_beneficiary_id[idx]

            let purpose_of_payment_str = ''
            let p_of_Payment = this.props.app_state.current_client
                .purpose_of_payment_list
            for (var j = 0; j < p_of_Payment.length; j++) {
                if (p_of_Payment[j].id == payment.purpose_of_payment_detail)
                    purpose_of_payment_str = p_of_Payment[j].description
            }
            let item_number = Number(idx) + 1
            let item0 = (
                <React.Fragment>
                    {item_number !== 1 && (
                        <React.Fragment>
                            -------------------------------------
                            <br />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )
            let item1 = (
                <React.Fragment>
                    {item_number}. {payment.beneficiary.nickname} (Acc:{' '}
                    {payment.beneficiary.account_number})<br />
                </React.Fragment>
            )
            let item2 = (
                <React.Fragment>
                    {purpose_of_payment_str}{' '}
                    {payment.detail_1 ? `[reference: ${payment.detail_1}]` : ''}
                    <br />
                </React.Fragment>
            )
            let item3 = (
                <React.Fragment>
                    {this.props.intl.formatNumber(payment.amount)}{' '}
                    {payment.beneficiary.currency_full_name} <br />
                </React.Fragment>
            )
            let data_item = [item0, item1, item2, item3]
            let purpose_item = [
                <React.Fragment>
                    {purpose_of_payment_str}
                    <br />
                </React.Fragment>,
            ]

            let invoice_itm = (
                <React.Fragment>
                    {payment.detail_1 ? `[reference: ${payment.detail_1}]` : ''}
                    <br />
                </React.Fragment>
            )

            let invoice_item = [invoice_itm]

            table_data_beneficiary.push(data_item)
            table_data_purpose.push(purpose_item)
            table_data_invoice.push(invoice_item)
        }

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <h4>
                        New Transer Request - Submitted and pending confirmation
                    </h4>
                    <p>
                        Thank you! Your FX transfer request has been submitted.{' '}
                        <br />
                        You can view the status of your FX transactions in
                        Transfers. <br />
                        Confirmation has been emailed to{' '}
                        {this.props.app_state.current_client.email}
                    </p>
                </GridItem>
                <GridItem>
                    <Table
                        tableData={[
                            [
                                'You Sell',
                                <React.Fragment>
                                    <div
                                        className={`currency-flag currency-flag-${this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.iso_alpha_3.toLowerCase()}`}
                                    />{' '}
                                    {this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}{' '}
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard_multiple
                                            .new_transfer_data.amount_from,
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'You Buy',
                                <React.Fragment>
                                    <div
                                        className={`currency-flag currency-flag-${this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3.toLowerCase()}`}
                                    />{' '}
                                    {this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}{' '}
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard_multiple
                                            .new_transfer_data.amount_to,
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'Exchange Rate',
                                <React.Fragment>
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard_multiple
                                            .new_transfer_data.client_rate,
                                        {
                                            minimumFractionDigits: 5,
                                            maximumFractionDigits: 5,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'Payments',
                                <React.Fragment>
                                    {this.props.transfers_dashboard_multiple
                                        .new_transfer_data
                                        .multiple_beneficiary_id[0]
                                        .checked_beneficiary_details ===
                                    true ? (
                                        <React.Fragment>
                                            To Be Advised
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {table_data_beneficiary}
                                            {/* {this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_legal_name} <br/> */}
                                            {/* Paying to {this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bank_legal_name} */}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>,
                            ],
                            ['Request Summary', `${request_summary}`],
                        ]}
                    />
                </GridItem>

                <GridContainer
                    justify="flex-end"
                    style={{ textAlign: 'right' }}
                >
                    <GridItem xs={12}>
                        <Button
                            color="success"
                            onClick={() => this.handle_new_transfer()}
                        >
                            New Transfer
                        </Button>
                    </GridItem>
                </GridContainer>
            </GridContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
        transfers_dashboard_multiple: state.transfers_dashboard_multiple,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearNewTransfer: () => {
            dispatch(clearNewTransfer())
        },
    }
}

const DashboardTransferStep3Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(DashboardTransferStep3))

export default withRouter(injectIntl(DashboardTransferStep3Container))
