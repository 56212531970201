import React from 'react'
import PropTypes from 'prop-types'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import Card from '../../../components/Card/Card.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'

import CardHeader from '../../../components/Card/CardHeader'
import CardIcon from '../../../components/Card/CardIcon'
import PermIdentity from '@material-ui/icons/PermIdentity'
import { CSVLink } from 'react-csv'
/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react.jsx'
import Button from '../../../components/CustomButtons/Button'
import TransferList from '../../../components/Transfers/TransferList.jsx'

import { FormattedMessage } from 'react-intl'
import dayjs from "dayjs";

const styles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },

    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}

/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientsList
 * ----------------------------------------------------------------------------
 */

const columns = [
    {
        Header: 'Id',
        accessor: 'id',
    },
    {
        Header: 'Transaction',
        accessor: 'transaction_date',
    },
    {
        Header: 'Settlement',
        accessor: 'settlement_date',
    },
    {
        Header: 'Currency From',
        accessor: 'currency_from',
    },
    {
        Header: 'From',
        accessor: 'amount_from',
    },
    {
        Header: 'Currency To',
        accessor: 'currency_to',
    },
    {
        Header: 'To',
        accessor: 'amount_to',
    },
    {
        Header: 'Beneficiary',
        accessor: 'beneficiary',
    },
    {
        Header: 'Rate',
        accessor: 'client_rate',
    },
    {
        Header: 'Status ',
        accessor: 'transfer_status',
    },
]
class TransfersListPast extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataToDownload: [],
            reactTable: null,
        }
    }

    componentDidMount() {
        // this.props.fetchTransferListV2(this.props.app_state.current_client.id)
    }

    setReactTable = value => {
        this.setState({
            reactTable: value,
        })
    }

    formatDate = (data, colIndex, columns) => {
        const record = data[columns[colIndex].accessor] || data['_original'][columns[colIndex].accessor];
        if (record != null) {
            return dayjs(record).format('DD/MM/YYYY');
        }
    }
    download = event => {
        if (!this.state.reactTable) return
        const currentRecords = this.state.reactTable;
        var data_to_download = []
        for (var index = 0; index < currentRecords.length; index++) {
            let record_to_download = {}
            for (var colIndex = 0; colIndex < columns.length; colIndex++) {
                if (columns[colIndex].accessor != null) {
                    console.log(columns[colIndex].accessor);
                    if (columns[colIndex].accessor === 'settlement_date' || columns[colIndex].accessor === 'transaction_date') {
                        record_to_download[columns[colIndex].Header] = this.formatDate(currentRecords[index], colIndex, columns);
                    } else {
                        record_to_download[columns[colIndex].Header] =
                            currentRecords[index][columns[colIndex].accessor] ||
                            currentRecords[index]['_original'][
                                columns[colIndex].accessor
                                ]
                    }
                    }

            }
            data_to_download.push(record_to_download)
        }
        this.setState({ dataToDownload: data_to_download }, () => {
            // click the CSVLink component to trigger the CSV download
            this.csvLink.link.click()
        })
    }

    render() {
        // console.log(this.props);

        if (!this.props.app_state.current_client) {
            return null
        }
        if (this.props.app_state.current_client.account_status == 1) {
            this.props.history.push('/pending-registration')
        }
        if (this.props.app_state.current_client.account_status == 5) {
            this.props.history.push('/registration')
        }

        if (this.props.app_state.current_client.account_status == 6) {
            this.props.history.push('/migrate')
        }

        const { classes } = this.props
        return (
            <div>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <PermIdentity />
                        </CardIcon>
                        <div className={classes.flexEnd}>
                            <h4 className={classes.cardIconTitle}>
                                <FormattedMessage
                                    id="transferList.cardItem1.transferListing"
                                    defaultMessage={`Transfers Listing`}
                                />
                            </h4>
                            <div>
                                <Button
                                    round
                                    color="primary"
                                    // onClick={() => this.props.history.push("/transfers/list")}
                                >
                                    <FormattedMessage
                                        id="newTransfer.cardItem1.showAll"
                                        defaultMessage={`Show All`}
                                    />
                                </Button>

                                <Button
                                    round
                                    color={'primary'}
                                    onClick={this.download}
                                >
                                    <i className="material-icons">
                                        cloud_download
                                    </i>{' '}
                                    CSV
                                </Button>
                                {/* Take this one out in version 1150, Issue #904 by Tom
                // <Button
                //   round
                //   color="info"
                //   //onClick={() => this.props.history.push("/transfers/new")} //#Bug fix #612 
                //   onClick={() => this.props.history.push("/payment/new/")}
                // >
                //   <FormattedMessage
                //     id="newTransfer.cardItem1.createNew"
                //     defaultMessage={`Create New`}
                //   />
                </Button>*/}
                                <Button
                                    round
                                    color="info"
                                    onClick={() =>
                                        this.props.fetchTransferListV2(
                                            this.props.app_state.current_client
                                                .id
                                        )
                                    }
                                >
                                    <FormattedMessage
                                        id="newTransfer.cardItem1.refresh"
                                        defaultMessage={`Refresh`}
                                    />
                                </Button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12}>
                                <CSVLink
                                    data={this.state.dataToDownload}
                                    filename="data.csv"
                                    className="hidden"
                                    ref={r => (this.csvLink = r)}
                                    target="_blank"
                                />
                                <TransferList
                                    past={true}
                                    setReactTable={this.setReactTable}
                                />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

TransfersListPast.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TransfersListPast)
