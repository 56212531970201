import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  update_map_data,
  updateNewTransferCreation,
  updateNewTransferCreationBeneficiary
} from "../../redux/actions/transfers_dashboard";
import { Row, Col } from "antd";
import { createNewBeneficiary } from "../../redux/actions/beneficiaries";
import cardPng from "../../assets/img/payment_24px.png";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../views/Pages/DashboardPageStyle";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { FormattedMessage } from "react-intl";
import CustomReactSelect from "../Forms/CustomReactSelect/CustomReactSelect";
import { API, Storage } from "aws-amplify";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "@material-ui/core/Card";
import Select from "@material-ui/core/Select";
import DocumentUpload from "components/DocumentUpload/DocumentUpload.jsx";
import document_image from "assets/img/document_image.jpg";
// import sample_invoice from "assets/img/sample_invoice.png";

import { cardTitle } from "../../assets/jss/material-dashboard-pro-react";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import CommoneCurrentComponent from "../DashboardTransfer/Common";
import { Checkbox, Chip, Divider, FormControlLabel } from "@material-ui/core";
import defaultPdf from 'assets/img/preview_pdf.jpg';

import queryString from "query-string";

import { Alert } from 'antd';
import { Checkbox as AntCheckbox } from 'antd';

import {AppUtil} from "../../AppUtil";
import FileUploader from "../FileUploader/FileUploader";
import Table from "../Table/Table";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const axios = require("axios");
const min_transfer_amount = 0;
const style = {
  ...dashboardStyle,
  ...customCheckboxRadioSwitch,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  transferRate: {
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "2px",
    paddingLeft: "4%",
    color: "#131313",
    paddingTop: "3%",
    textTransform: "uppercase"
  },
  selectContainer: {
    padding: "4%",
    background: "#FFFFFF",
    border: "1px solid #0BACF4",
    boxSizing: "border-box",
    borderRadius: "6px"
  },
  paymentMethod: {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "17.04px",
    float: "right",
    textAlign: "right",
    opacity: "0.7",
    color: "#131313",
    paddingTop: "4%",
    paddingRight: "3%"
  },
  cardImg: {
    textAlign: "center",
    paddingLeft: "7% !important"
  },
  transactionOptions: {
    border: "none",
    width: "100%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "right",
    letterSpacing: "0.15em",
    textTransform: "uppercase",
    color: "#0BACF4",
    backgroundColor: "white"
  }
};

class ShortcutTransferStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentLoading: false,
      beneficiaries_list: [],
      purpose_list: [],
      currencies_list_priority: [],
      map_data: {},
      show_iban: false,
      show_bsb: false,
      show_sort_code: false,
      show_swift_code: true,
      show_aba: false,
      show_account_number: true,
      show_bank_code: true,
      show_transit_code: true,
      show_institution: true,

      country_list: [],
      currencies_list: [],

      clientTransfers: [
        {
          beneficiary_id: "",
          amount_to: "",
          purpose_of_payment_detail: "",
          beneficiary_id_state: "",
          amount_to_state: "",
          purpose_of_payment_detail_state: "",
          detail_1: "",
          detail_2: "",
          detail_3: "",
          detail_1_state: "",
          detail_2_state: "",
          detail_3_state: "",
          document: "",
          purpose_of_payment_other: "",
          purpose_of_payment_other_state: ""
        }
      ],

      showDocumentUpload: true,
      audCollection: false,
      holdingAccountBalances: {},
    };
  }

  // getLedgerBalances = () => {
  //   let chip_list = [];
  //   API.get("holding_accounts", `/get/all`)
  //     .then(response => {
  //       // console.log(response);
  //       this.setState({
  //         holdingAccountBalances: response.holding_account_list
  //       })
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }

  getLedgerBalances = () => {
    let chip_list = [];
    API.get("holding_accounts", `/get/all/v2/${this.props.app_state.current_client.id}`)
      .then(response => {
        console.log(response)
        this.setState({
          holdingAccountBalances: response.holding_account_list
        });
      })
      .catch(error => {
        console.log(error);
      });
  };


  async componentDidMount() {
    await this.initialiseState()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.prefill !== this.props.prefill) {
      this.componentDidMount();
    }
    if (prevProps.app_state.current_client.id !== this.props.app_state.current_client.id) {
      await this.initialiseState()
    }
  }

  componentWillUnmount() {}

  initialiseState = async () => {
    this.setState({componentLoading: true})
    await API.get("currencies", `/currencies/get-list-priority`)
    .then(response => {
      this.setState({
        currencies_list_priority: response
      });
    })
    await API.get("beneficiaries", `/beneficiaries/list/${this.props.app_state.current_client.id}`)
    .then(response => {
      // console.log(response)
      const filteredResponse = response.filter((item) => { return item.active === 1 })
      this.setState(
        {
          beneficiaries_list: filteredResponse,
          prefill_from_currency: false,
          prefill_to_currency: false,
          prefill_from_amount: false,
          prefill_to_amount: false,
          prefill_beneficiary: false,
          prefill_purpose_of_payment: false,
          prefill_payment_detail: false
        },
        () => {
          this.preFillFields();
          // this.set_payment_radio("bank");
          // this.initializeDefaultCurries();
          this.getLedgerBalances();
        }
      );
    })
    .catch(error => {
      console.log(error);
    });
    await API.get("countries", `/countries/list_not_deleted`)
      .then(response => {
        this.setState({
          country_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });

    await API.get("currencies", `/currencies/get-all`)
      .then(response => {
        this.setState({
          currencies_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({
      payment_method: "bank"
    });
    this.setState({componentLoading: false})
  }

  preFillFields() {
    if (!this.props.prefill) return null;
    const prefill_strings_array = this.props.prefill.split("-");

    // set default "from currency"
    if (prefill_strings_array[0] !== "" && !!prefill_strings_array[0]) {
      let prefill_from_currency_name = prefill_strings_array[0];
      let index;
      // only set default value if the prefilled name is under the currency list
      for (index in this.state.currencies_list_priority) {
        if (
          this.state.currencies_list_priority[index].iso_alpha_3 ===
          prefill_from_currency_name
        ) {
          let default_currency_from_id = this.state.currencies_list_priority[
            index
          ].id;
          let currency_from_object = { value: default_currency_from_id }; // label is not important in this object so it's omitted
          // Disable DDL
          this.setState({ prefill_from_currency: true });
          // update map
          this.updateMapData(
            "from_currency",
            this.props.transfers_dashboard.new_transfer_data.currency_from_id,
            this.props.transfers_dashboard.new_transfer_data.currency_to_id,
            currency_from_object
          );
          // set props and state as well as handle amount
          this.setState(
            {
              ["from_currency"]: default_currency_from_id
            },
            () => {
              this.handleAmount();
            }
          );
          this.props.updateNewTransferCreation(
            "currency_from_id",
            default_currency_from_id
          );
          this.props.updateNewTransferCreation(
            "currency_from_state",
            "success"
          );
          API.get("currencies", `/currencies/get/${default_currency_from_id}`)
            .then(response => {
              this.props.updateNewTransferCreation("currency_from", response);
              this.props.updateNewTransferCreation(
                "currency_from_name",
                response.iso_alpha_3
              );
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    }

    // set default "to currency"
    var default_currency_to_id; // set it as variable because it will be used on beneficiary field later
    if (prefill_strings_array[1] !== "" && !!prefill_strings_array[1]) {
      let prefill_to_currency_name = prefill_strings_array[1];
      let index;
      // only set default value if the prefilled name is under the currency list
      for (index in this.state.currencies_list_priority) {
        if (
          this.state.currencies_list_priority[index].iso_alpha_3 ===
          prefill_to_currency_name
        ) {
          default_currency_to_id = this.state.currencies_list_priority[index]
            .id;
          let currency_to_object = { value: default_currency_to_id }; // label is not important in this object so it's omitted
          // Disable DDL
          this.setState({ prefill_to_currency: true });
          // update map
          this.updateMapData(
            "to_currency",
            this.props.transfers_dashboard.new_transfer_data.currency_from_id,
            this.props.transfers_dashboard.new_transfer_data.currency_to_id,
            currency_to_object
          );

          //set show values using tom module
          let currency_dataset = this.state.currencies_list_priority;
          for (let i = 0; i < currency_dataset.length; i++) {
            if (currency_dataset[i].id === default_currency_to_id) {
              //iban check
              if (currency_dataset[i].iban === 1) {
                this.setState({
                  show_iban: true
                });
              } else {
                this.setState({
                  show_iban: false
                });
              }
              //bsb check
              if (currency_dataset[i].bsb === 1) {
                this.setState({
                  show_bsb: true
                });
              } else {
                this.setState({
                  show_bsb: false
                });
              }
              //sort_code check
              if (currency_dataset[i].sort_code === 1) {
                this.setState({
                  show_sort_code: true
                });
              } else {
                this.setState({
                  show_sort_code: false
                });
              }
              //swift_code check
              if (currency_dataset[i].swift_code === 1) {
                this.setState({
                  show_swift_code: true
                });
              } else {
                this.setState({
                  show_swift_code: false
                });
              }
              //aba_routing check
              if (currency_dataset[i].aba_routing === 1) {
                this.setState({
                  show_aba: true
                });
              } else {
                this.setState({
                  show_aba: false
                });
              }
              //account number check
              if (currency_dataset[i].account_number === 1) {
                this.setState({
                  show_account_number: true
                });
              } else {
                this.setState({
                  show_account_number: false
                });
              }
            }
          }
          // set props and state as well as handle amount
          this.setState(
            {
              ["to_currency"]: default_currency_to_id,
              ["account_currency"]: default_currency_to_id
            },
            () => {
              this.handleAmount();
            }
          );
          this.props.updateNewTransferCreation(
            "currency_to_id",
            default_currency_to_id
          );
          this.props.updateNewTransferCreation("currency_to_state", "success");
          API.get("currencies", `/currencies/get/${default_currency_to_id}`)
            .then(response => {
              this.props.updateNewTransferCreation("currency_to", response);
              this.props.updateNewTransferCreation(
                "currency_to_name",
                response.iso_alpha_3
              );
            })
            .catch(error => {
              console.log(error);
            });
        }
      }

      // Test for AUD to AUD transfer
      if (prefill_strings_array[0] == "AUD" && prefill_strings_array[1] == "AUD" ) {
        this.setState({
          audCollection: true
        })
        // console.log("audCollection: TRUE")

        let default_from_amount = Number(prefill_strings_array[3]);
        // Check if the amount is larger than the minimum amount
        if (this.isLargerThanNumber(default_from_amount, min_transfer_amount)) {
          this.props.updateNewTransferCreation("amount_from_state", "success");
        } else {
          this.props.updateNewTransferCreation("amount_from_state", "error");
        }
  
        this.props.updateNewTransferCreation("amount_lock", "from_amount");
        this.props.updateNewTransferCreation("amount_from", default_from_amount);
  
        // Disable CustomInput and then handle "to amount"
        this.setState(
          {
            prefill_from_amount: true
          }
        );

      } else {
        this.setState({
          audCollection: false
        })
        // console.log("audCollection: FALSE")
      }


      //set a resumable_beneficiary_list
      var resume_beneficiaries_list = this.state.beneficiaries_list;

      if (resume_beneficiaries_list.length > 0) {
        //remove items from beneficiary-list by not related this to_currency items
        for (let i = resume_beneficiaries_list.length - 1; i > -1; i--) {
          if (
            resume_beneficiaries_list[i].account_currency !==
            default_currency_to_id
          ) {
            //response.remove(i);
            resume_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
            // console.log("remove index "+i)
          }
        }
      }
    }

    // set default "from amount"
    if (prefill_strings_array[2] !== "" && !!prefill_strings_array[2]) {
      let default_from_amount = Number(prefill_strings_array[2]);
      // Check if the amount is larger than the minimum amount
      if (this.isLargerThanNumber(default_from_amount, min_transfer_amount)) {
        this.props.updateNewTransferCreation("amount_from_state", "success");
      } else {
        this.props.updateNewTransferCreation("amount_from_state", "error");
      }

      this.props.updateNewTransferCreation("amount_lock", "from_amount");
      this.props.updateNewTransferCreation("amount_from", default_from_amount);

      // Disable CustomInput and then handle "to amount"
      this.setState(
        {
          prefill_from_amount: true
        },
        () => {
          this.handleAmount("to_amount");
        }
      );
    }

    // set default "to amount"
    if (prefill_strings_array[3] !== "" && !!prefill_strings_array[3]) {
      let default_to_amount = Number(prefill_strings_array[3]);
      // Check if the amount is larger than the minimum amount
      if (this.isLargerThanNumber(default_to_amount, min_transfer_amount)) {
        this.props.updateNewTransferCreation("amount_to_state", "success");
      } else {
        this.props.updateNewTransferCreation("amount_to_state", "error");
      }

      this.props.updateNewTransferCreation("amount_lock", "to_amount");
      this.props.updateNewTransferCreation("amount_to", default_to_amount);

      // Disable CustomInput and then handle "from amount"
      this.setState(
        {
          prefill_to_amount: true
        },
        () => {
          this.handleAmount("from_amount");
        }
      );
    }

    // set default "beneficiary id"
    if (prefill_strings_array[4] !== "" && !!prefill_strings_array[4]) {
      if (resume_beneficiaries_list.length > 0) {
        //remove items from beneficiary-list by not related this to_currency items
        for (let i = resume_beneficiaries_list.length - 1; i > -1; i--) {
          if (
            resume_beneficiaries_list[i].account_currency !==
            default_currency_to_id
          ) {
            //response.remove(i);
            resume_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
            // console.log("remove index "+i)
          }
        }
      }
      this.setState(
        {
          beneficiaries_list: resume_beneficiaries_list
          // account_currency: "AUD"
        },
        () => {
          let prefill_beneficiary_id = Number(prefill_strings_array[4]);
          let index;
          // only set default value if the prefilled id is under the beneficiaries list
          for (index in this.state.beneficiaries_list) {
            if (
              this.state.beneficiaries_list[index].id === prefill_beneficiary_id
            ) {
              let default_beneficiary_array = this.props.transfers_dashboard
                .new_transfer_data.multiple_beneficiary_id || [{}];
              // Disable DDL
              this.setState({ prefill_beneficiary: true });
              API.get(
                "beneficiaries",
                `/beneficiaries/getb/${prefill_beneficiary_id}`
              )
                .then(beneficiaries_detail => {
                  default_beneficiary_array[0].beneficiary_id = prefill_beneficiary_id;
                  default_beneficiary_array[0].beneficiary_id_state = "success";
                  default_beneficiary_array[0].beneficiary =
                    beneficiaries_detail[0];
                  this.props.updateNewTransferCreationBeneficiary(
                    default_beneficiary_array
                  );
                })
                .catch(error => {
                  console.log(error);
                  // ****************************************************************************
                  // ERROR HANDLING
                  // IF FIRST API CALL FAILS - TRY IT AGAIN
                  API.get(
                    "beneficiaries",
                    `/beneficiaries/getb/${prefill_beneficiary_id}`
                  )
                    .then(beneficiaries_detail => {
                      default_beneficiary_array[0].beneficiary_id = prefill_beneficiary_id;
                      default_beneficiary_array[0].beneficiary_id_state =
                        "success";
                      default_beneficiary_array[0].beneficiary =
                        beneficiaries_detail[0];
                      this.props.updateNewTransferCreationBeneficiary(
                        default_beneficiary_array
                      );
                    })
                    .catch(error => {
                      console.log(error);
                    });
                  // ****************************************************************************
                });
            }
          }
        }
      );
    }

    // set default "purpose of payment id"
    if (prefill_strings_array[5] !== "" && !!prefill_strings_array[5]) {
      let prefill_purpose_of_payment_id = Number(prefill_strings_array[5]);
      let index;
      // only set default value if the prefilled id is under the purpose of payment list
      for (index in this.props.app_state.current_client
        .purpose_of_payment_list) {
        if (
          this.props.app_state.current_client.purpose_of_payment_list[index]
            .id === prefill_purpose_of_payment_id
        ) {
          let default_beneficiary_array = this.props.transfers_dashboard
            .new_transfer_data.multiple_beneficiary_id || [{}];
          let purpose_of_payment_obj = this.props.app_state.current_client
            .purpose_of_payment_list[index];
          // Disable DDL
          this.setState({ prefill_purpose_of_payment: true });
          default_beneficiary_array[0].purpose_of_payment_detail =
            purpose_of_payment_obj.id;
          default_beneficiary_array[0].purpose_of_payment_detail_nickname =
            purpose_of_payment_obj.description;
          default_beneficiary_array[0].purpose_of_payment_detail_state =
            "success";
          this.props.updateNewTransferCreationBeneficiary(
            default_beneficiary_array
          );
        }
      }
    }

    // Handle invoice number
    if (prefill_strings_array[6] !== "" && !!prefill_strings_array[6]) {
      let prefill_payment_detail_string = prefill_strings_array[6];
      let default_beneficiary_array = this.props.transfers_dashboard
        .new_transfer_data.multiple_beneficiary_id || [{}];
      // Disable CustomInput
      this.setState({ prefill_payment_detail: true });
      this.props.updateNewTransferCreation(
        "pending_invoice",
        Number.parseInt(prefill_strings_array[6])
      );
    }

    // set default "payment detail"
    if (prefill_strings_array[7] !== "" && !!prefill_strings_array[7]) {
      let prefill_payment_detail_string = prefill_strings_array[7];
      let default_beneficiary_array = this.props.transfers_dashboard
        .new_transfer_data.multiple_beneficiary_id || [{}];
      // Disable CustomInput
      this.setState({ prefill_payment_detail: true });
      default_beneficiary_array[0].detail_1 = prefill_payment_detail_string;
      default_beneficiary_array[0].detail_1_state = "success";
      this.props.updateNewTransferCreationBeneficiary(
        default_beneficiary_array
      );
    }



    const query_strings = queryString.parse(this.props.location.search);
    if (Object.keys(query_strings).length !== 0) {
      // console.log(query_strings);

      if (query_strings.pt !== undefined) {
        // console.log(query_strings.pt)
        API.get("transfers_pending", "/get-full/id/" + query_strings.pt)
            .then(async response => {
              const state = this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id;
              state[0].document = response.transfer_pending.attachmentFileId;
              this.props.updateNewTransferCreationBeneficiary(state);

              // console.log(response)
              let file_url;
              let mimeType = 'image';
              if (response.transfer_pending.attachmentFileId) {
                file_url = await Storage.get(response.transfer_pending.attachmentFileId, { expires: 60 });
                 mimeType = await AppUtil.getFileInfo(file_url);
                 mimeType = mimeType.fileType;
                // console.log(file_url)
              }
              this.setState({
                pendingTransferId: query_strings.pt,
                pending_transfer: response,
                pending_transfer_mime: mimeType,
                pending_transfer_attachment_url: file_url
              })
              this.props.updateNewTransferCreation(
                "pendingTransferId",
                Number.parseInt(query_strings.pt)
              );
            })
            .catch(error => {
              console.log(error)
            })


      }

    }


  }

  clearBeneficiaryDetails() {
    let beneficiary_array = this.props.transfers_dashboard.new_transfer_data
      .multiple_beneficiary_id || [{}];
    beneficiary_array[0].beneficiary = {};
    beneficiary_array[0].beneficiary_id = "";
    beneficiary_array[0].beneficiary_id_state = "";
    this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if a value is larger than a number or not
  isLargerThanNumber(value, number) {
    if (value !== "" && value >= number) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    console.log('change: ', stateName, event.target.value)
    console.log('ledgerFrom: ', this.props.transfers_dashboard.new_transfer_data.ledgerFrom)
    let ledgerOverride = false;
    console.log('ledgerOverride: ', ledgerOverride)
    let eventTargetValue = event.target.value;
    console.log('eventTargetValue: ', eventTargetValue)

    for (var idx in this.state.holdingAccountBalances) {
      let record = this.state.holdingAccountBalances[idx];
      if (
        record.currency_id === this.props.transfers_dashboard.new_transfer_data.currency_from_id
        && record.balance
      ) {
        console.log('ledger balance: ', record.balance)
        if ((record.balance < event.target.value) && (this.props.transfers_dashboard.new_transfer_data.ledgerFrom)) {
          this.setState({displayLedgerWarning: true})
          eventTargetValue = record.balance.toFixed(2)
        } else {
          this.setState({displayLedgerWarning: false})
          eventTargetValue = event.target.value
        }

      }
    }
    console.log('eventTargetValue: ', eventTargetValue)
    console.log('---')

    switch (type) {
      case "length":
        if (this.verifyLength(eventTargetValue, stateNameEqualTo)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "isLargerThanNumber":
        if (this.isLargerThanNumber(eventTargetValue, stateNameEqualTo)) {
          if (stateName === "from_amount") {
            this.props.updateNewTransferCreation(
              "amount_from_state",
              "success"
            );
          }
          if (stateName === "to_amount") {
            this.props.updateNewTransferCreation("amount_to_state", "success");
          }
        } else {
          if (stateName === "from_amount") {
            this.props.updateNewTransferCreation("amount_from_state", "error");
          }
          if (stateName === "to_amount") {
            this.props.updateNewTransferCreation("amount_to_state", "error");
          }
        }
        break;
      default:
        break;
    }

    // Calculate "to amount"
    if (stateName === "from_amount") {
      this.props.updateNewTransferCreation("amount_lock", "from_amount");
      this.props.updateNewTransferCreation("amount_from", eventTargetValue);
      this.handleAmount("to_amount");
    }

    // Calculate "from amount"
    if (stateName === "to_amount") {
      this.props.updateNewTransferCreation("amount_lock", "to_amount");
      this.props.updateNewTransferCreation("amount_to", eventTargetValue);
      this.handleAmount("from_amount");
    }

    // if(stateName === "from_amount" || stateName === "to_amount") {
    //   this.fetchNewRate();
    // }

    if (stateName === "purpose_of_payment_other") {
      let beneficiary_array = this.props.transfers_dashboard.new_transfer_data
        .multiple_beneficiary_id || [{}];
      beneficiary_array[0].purpose_of_payment_other = eventTargetValue;
      if (eventTargetValue !== "") {
        beneficiary_array[0].purpose_of_payment_other_state = "success";
      } else {
        beneficiary_array[0].purpose_of_payment_other_state = "error";
      }
      this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
    }

    if (stateName === "detail_1") {
      let beneficiary_array = this.props.transfers_dashboard.new_transfer_data
        .multiple_beneficiary_id || [{}];
      beneficiary_array[0].detail_1 = eventTargetValue;
      if (eventTargetValue !== "") {
        beneficiary_array[0].detail_1_state = "success";
      } else {
        beneficiary_array[0].detail_1_state = "";
      }
      this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
    }
  }

  handleAmount(amount_name) {
    let from_currency_Iso = "";
    let to_currency_Iso = "";

    if (this.state.audCollection) {
      this.props.updateNewTransferCreation("client_rate", 1);
      return
    }


    this.state.currencies_list_priority.map((item, index) => {
      if (
        item.id ==
        this.props.transfers_dashboard.new_transfer_data.currency_from_id
      ) {
        from_currency_Iso = item.iso_alpha_3;
      }

      if (
        item.id ==
        this.props.transfers_dashboard.new_transfer_data.currency_to_id
      ) {
        to_currency_Iso = item.iso_alpha_3;
      }
    });

    let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`;
    axios
      .get(url, {
        params: {
          currencies: to_currency_Iso,
          source: from_currency_Iso,
          format: "1"
        }
      })
      .then(response => {
        let key = from_currency_Iso + to_currency_Iso;
        let rate_string = response.data.quotes[key];

        let rate = Number.parseFloat(rate_string);

        let rate_adjust =
          Number.parseFloat(this.props.app_state.current_client.default_rate) +
          Number.parseFloat(this.props.app_state.current_client.default_rate_division) +
          Number.parseFloat(this.props.app_state.current_client.default_rate_entity) +
          Number.parseFloat(this.props.app_state.current_client.default_rate_team) +
          Number.parseFloat(this.props.app_state.current_client.default_rate_staff);
        let adjusted_rate = rate - rate * rate_adjust;

        if (
          this.props.transfers_dashboard.new_transfer_data.currency_from_id ==
          this.props.transfers_dashboard.new_transfer_data.currency_to_id
        ) {
          adjusted_rate = 1;
        }

        if (
          this.props.transfers_dashboard.new_transfer_data.currency_from_state === "success" &&
          this.props.transfers_dashboard.new_transfer_data.currency_to_state === "success"
        ) {
          // handle to_amount
          if (
            amount_name === "to_amount" &&
            this.props.transfers_dashboard.new_transfer_data.amount_from_state === "success"
          ) {
            let amount_from = Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.amount_from);
            let amount_to = amount_from * adjusted_rate;

            this.props.updateNewTransferCreation(
              "amount_to",
              amount_to.toFixed(2)
            );
            if (amount_to >= min_transfer_amount) {
              this.props.updateNewTransferCreation(
                "amount_to_state",
                "success"
              );
            } else {
              this.props.updateNewTransferCreation("amount_to_state", "error");
            }
          }

          // handle from_amount
          if (
            amount_name === "from_amount" &&
            this.props.transfers_dashboard.new_transfer_data.amount_to_state ===
              "success"
          ) {
            let amount_to = Number.parseFloat(
              this.props.transfers_dashboard.new_transfer_data.amount_to
            );
            let amount_from = amount_to / adjusted_rate;

            this.props.updateNewTransferCreation(
              "amount_from",
              amount_from.toFixed(2)
            );
            if (amount_from >= min_transfer_amount) {
              this.props.updateNewTransferCreation(
                "amount_from_state",
                "success"
              );
            } else {
              this.props.updateNewTransferCreation(
                "amount_from_state",
                "error"
              );
            }
          }
        }

        this.props.updateNewTransferCreation("client_rate", adjusted_rate);

        let feeAmount, feeCurrencyIso, transferAmount;
        this.setState({displayFeeWarning: false})
        
        for (var i = 0; i < this.props.app_state.current_team_fees.length; i++) {
          if (this.props.app_state.current_team_fees[i].currency_id === this.props.transfers_dashboard.new_transfer_data.currency_from_id) {
            if (this.props.transfers_dashboard.new_transfer_data.amount_from < this.props.app_state.current_team_fees[i].transferAmount) {
              if (this.props.transfers_dashboard.new_transfer_data.amount_from < transferAmount) {
                // pass
              } else {
                feeAmount = this.props.app_state.current_team_fees[i].feeAmount;
                feeCurrencyIso = this.props.app_state.current_team_fees[i].currencies_short_name;
                transferAmount = this.props.app_state.current_team_fees[i].transferAmount;
                this.props.updateNewTransferCreation("feeCurrencyId", this.props.transfers_dashboard.new_transfer_data.currency_from_id);
                this.props.updateNewTransferCreation("feeCurrencyIso", feeCurrencyIso);
                this.props.updateNewTransferCreation("feeAmount", feeAmount);
                this.props.updateNewTransferCreation("feeDescription", this.props.app_state.current_team_fees[i].feeDescription);
                this.setState({
                  displayFeeWarning: true,
                  fee_feeAmount: feeAmount,
                  fee_feeCurrencyIso: feeCurrencyIso,
                  fee_transferAmount: transferAmount
                })
              }
            }
          }
        }
        // if amount is above fee threshold, set fee to 0
        if (!feeAmount) {
          this.props.updateNewTransferCreation("feeCurrencyId", null);
          this.props.updateNewTransferCreation("feeCurrencyIso", null);
          this.props.updateNewTransferCreation("feeAmount", null);
          this.props.updateNewTransferCreation("feeDescription", null);
          this.setState({
            displayFeeWarning: false,
            fee_feeAmount: null,
            fee_feeCurrencyIso: null,
            fee_transferAmount: transferAmount
          })
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleCustomReactSelectChange = (
    name,
    previous_from_currency,
    previous_to_currency
  ) => value => {
    var option_value;
    var option_label;
    var beneficiary_array = this.props.transfers_dashboard.new_transfer_data
      .multiple_beneficiary_id || [{}];
    if (value === null) {
      option_value = null;
      option_label = null;
    } else {
      option_value = value.value;
      option_label = value.label;
    }

    if (name === "bank_country") {
      // console.log(value.value);
      let country_dataset = this.state.country_list;
      // console.log(country_dataset);
      for (let i = 0; i < country_dataset.length; i++) {
        if (country_dataset[i].id === option_value) {
          console.table(country_dataset[i]);
          this.setState({
            show_swift_code: true,
            show_account_number: true
          });

          //iban check
          if (country_dataset[i].use_iban === 1) {
            this.setState({
              show_iban: true
            });
          } else {
            this.setState({
              show_iban: false
            });
          }

          //bsb check
          if (country_dataset[i].use_bsb === 1) {
            this.setState({
              show_bsb: true
            });
          } else {
            this.setState({
              show_bsb: false
            });
          }

          //aba_routing check
          if (country_dataset[i].use_aba === 1) {
            this.setState({
              show_aba: true
            });
          } else {
            this.setState({
              show_aba: false
            });
          }

          //sort_code check
          if (country_dataset[i].use_sort_code === 1) {
            this.setState({
              show_sort_code: true
            });
          } else {
            this.setState({
              show_sort_code: false
            });
          }
          //account_number check
          if (country_dataset[i].use_account_number === 1) {
            this.setState({
              show_account_number: true
            });
          } else {
            this.setState({
              show_account_number: false
            });
          }

          // transit_code
          if (country_dataset[i].use_transit_code === 1) {
            this.setState({
              show_transit_code: true
            });
          } else {
            this.setState({
              show_transit_code: false
            });
          }

          // institution_number
          if (country_dataset[i].use_institution_number === 1) {
            this.setState({
              show_institution_number: true
            });
          } else {
            this.setState({
              show_institution_number: false
            });
          }

          // bank_code
          if (country_dataset[i].use_bank_code === 1) {
            this.setState({
              show_bank_code: true
            });
          } else {
            this.setState({
              show_bank_code: false
            });
          }
        }
      }
    }

    this.setState(
      {
        [name]: option_value
      },
      () => {
        if (name === "from_currency" || name === "to_currency") {
          switch (
            this.props.transfers_dashboard.new_transfer_data.amount_lock
          ) {
            case "from_amount":
              this.handleAmount("to_amount");
              break;
            case "to_amount":
              this.handleAmount("from_amount");
              break;
            default:
              this.handleAmount();
          }
        }
      }
    );

    if (name === "from_currency" || name === "to_currency") {
      this.updateMapData(
        name,
        previous_from_currency,
        previous_to_currency,
        value
      );
      if (name === "from_currency") {
        this.props.updateNewTransferCreation("currency_from_id", option_value);
        this.props.updateNewTransferCreation("currency_from_state", "success");
        API.get("currencies", `/currencies/get/${option_value}`)
          .then(response => {
            this.props.updateNewTransferCreation("currency_from", response);
            this.props.updateNewTransferCreation(
              "currency_from_name",
              response.iso_alpha_3
            );
          })
          .catch(error => {
            console.log(error);
          });
      }
      if (name === "to_currency") {
        this.props.updateNewTransferCreation("currency_to_id", option_value);
        this.props.updateNewTransferCreation("currency_to_state", "success");

        //set a resumable_beneficiary_list
        API.get("beneficiaries", `/beneficiaries/get`)
          .then(response => {
            //console.log(response);
            let resume_beneficiaries_list = response;
            if (resume_beneficiaries_list.length > 0) {
              //remove items from beneficiary-list by not related this to_currency items
              for (let i = resume_beneficiaries_list.length - 1; i > -1; i--) {
                if (
                  resume_beneficiaries_list[i].account_currency !== option_value
                ) {
                  //response.remove(i);
                  resume_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
                  // console.log("remove index "+i)
                }
              }
            }
            this.setState(
              {
                beneficiaries_list: resume_beneficiaries_list,
                account_currency: option_value
              },
              () => {
                // console.log("Finish beneficiaries API")
                // this.queryStrings()
              }
            );
          })
          .catch(error => {
            console.log(error);
          });

        API.get("currencies", `/currencies/get/${option_value}`)
          .then(response => {
            this.props.updateNewTransferCreation("currency_to", response);
            this.props.updateNewTransferCreation(
              "currency_to_name",
              response.iso_alpha_3
            );
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
    if (name === "beneficiary_id") {
      beneficiary_array[0].beneficiary_id = option_value;
      beneficiary_array[0].beneficiary_id_state = "success";
      API.get("beneficiaries", `/beneficiaries/getb/${option_value}`)
        .then(beneficiaries_detail => {
          beneficiary_array[0].beneficiary = beneficiaries_detail[0];
          this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (name === "purpose_of_payment_detail") {
      beneficiary_array[0].purpose_of_payment_detail = option_value;
      beneficiary_array[0].purpose_of_payment_detail_nickname = option_label;
      beneficiary_array[0].purpose_of_payment_detail_state = "success";
      this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
      let document_upload_show = false;
      for (let key in this.props.app_state.current_client
        .purpose_of_payment_list) {
        if (
          this.props.app_state.current_client.purpose_of_payment_list[key].id ==
          option_value
        ) {
          if (
            this.props.app_state.current_client.purpose_of_payment_list[key]
              .document_upload == 1
          ) {
            document_upload_show = true;
          }
        }
      }
      this.setState({
        showDocumentUpload: document_upload_show
      });
    }
  };

  handleCheckboxToggle = name => event => {
    let beneficiary_array = this.props.transfers_dashboard.new_transfer_data
      .multiple_beneficiary_id || [{}];
    beneficiary_array[0].checked_beneficiary_details = event.target.checked;
    this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
    if (event.target.checked === true) {
      this.clearBeneficiaryDetails();
    }
  };

  updateMapData(
    currency_name,
    previous_from_currency,
    previous_to_currency,
    currency_object
  ) {
    var map_values = this.state.map_data;
    const from_currency_map_value = 0;
    const to_currency_map_value = 1;

    // update the properties for visulization
    if (currency_name === "from_currency") {
      // delele previous currency property in map_values
      if (previous_from_currency !== previous_to_currency) {
        this.deleleCurrency(
          map_values,
          previous_from_currency,
          previous_to_currency
        );
      }
      // add new currency property in map_values
      this.addCurrency(
        map_values,
        currency_object.value,
        from_currency_map_value
      );
    }
    if (currency_name === "to_currency") {
      // delele previous currency property in map_values
      if (previous_to_currency !== previous_from_currency) {
        this.deleleCurrency(
          map_values,
          previous_to_currency,
          previous_from_currency
        );
      }
      // add new currency property in map_values
      this.addCurrency(
        map_values,
        currency_object.value,
        to_currency_map_value
      );
    }
    this.setState({ map_data: map_values });
    this.props.update_map_data(map_values);
  }

  deleleCurrency(map_values, currency_deleted, the_other_currency) {
    switch (currency_deleted) {
      case 1: // AUD
        delete map_values.AU; // Australia
        break;
      case 5: // USD
        delete map_values.US; // United States
        break;
      case 8: // GBP
        // if the other currency is not Euro, delete GB
        if (the_other_currency !== 9) {
          delete map_values.GB; // United Kingdom
        }
        break;
      case 9: // Eur. For Europe: italy, spain, france, portugal, germany
        // if the other currency is not GB, delete GB
        if (the_other_currency !== 8) {
          delete map_values.GB; // United Kingdom
        }
        delete map_values.IT; // Italy
        delete map_values.ES; // Spain
        delete map_values.FR; // France
        delete map_values.PT; // Portugal
        delete map_values.DE; // Germany
        break;
      case 6: // ATS
        delete map_values.AT; // 	Austria
        break;
      case 16:
        delete map_values.JP;
        break;
      case 17:
        delete map_values.NZ;
        break;
      case 18:
        delete map_values.CA;
        break;
      case 19:
        delete map_values.HK;
        break;
      case 20:
        delete map_values.SG;
        break;
      case 21:
        delete map_values.CH;
        break;
      case 22:
        delete map_values.TH;
        break;
      case 23:
        delete map_values.DK;
        break;
      case 24:
        delete map_values.NO;
        break;
      case 25:
        delete map_values.SE;
        break;
      case 30:
        delete map_values.NP;
        break;
      case 31:
        delete map_values.VN;
        break;
      default:
    }
  }

  addCurrency(map_values, currency, currency_map_value) {
    switch (currency) {
      case 1:
        map_values.AU = currency_map_value;
        break;
      case 5:
        map_values.US = currency_map_value;
        break;
      case 8:
        map_values.GB = currency_map_value;
        break;
      case 9:
        map_values.GB = currency_map_value;
        map_values.IT = currency_map_value;
        map_values.ES = currency_map_value;
        map_values.FR = currency_map_value;
        map_values.PT = currency_map_value;
        map_values.DE = currency_map_value;
        break;
      case 6:
        map_values.AT = currency_map_value;
        break;
      case 16:
        map_values.JP = currency_map_value;
        break;
      case 17:
        map_values.NZ = currency_map_value;
        break;
      case 18:
        map_values.CA = currency_map_value;
        break;
      case 19:
        map_values.HK = currency_map_value;
        break;
      case 20:
        map_values.SG = currency_map_value;
        break;
      case 21:
        map_values.CH = currency_map_value;
        break;
      case 22:
        map_values.TH = currency_map_value;
        break;
      case 23:
        map_values.DK = currency_map_value;
        break;
      case 24:
        map_values.NO = currency_map_value;
        break;
      case 25:
        map_values.SE = currency_map_value;
        break;
      case 30:
        map_values.NP = currency_map_value;
        break;
      case 31:
        map_values.VN = currency_map_value;
        break;
      default:
    }
  }

  secondsToTime = secs => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  };
  startTimer = () => {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };
  countDown = () => {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    });
    this.props.updateNewTransferCreation("current_rate_ttl", seconds);

    if (seconds === 0) {
      this.setState({
        seconds: 30
      });
      this.props.updateNewTransferCreation("current_rate_ttl", 30);
      // this.fetchNewRate();
    }
  };

  onupdate = (file_id, index) => {
    let beneficiary_array = this.props.transfers_dashboard.new_transfer_data
      .multiple_beneficiary_id || [{}];
    beneficiary_array[0].document = file_id;
    this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
  };

  onRemove = index => {
    let beneficiary_array = this.props.transfers_dashboard.new_transfer_data
      .multiple_beneficiary_id || [{}];
    beneficiary_array[0].document = null;
    this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
  };

  render_beneficiary_details_section = () => {
    const { classes } = this.props;
    if (
      this.props.transfers_dashboard.new_transfer_data.currency_from_state !==
        "success" ||
      this.props.transfers_dashboard.new_transfer_data.currency_to_state !==
        "success"
    )
      return null;

    // For CustomReactSelect. Generate select options for dropdown list.
    const beneficiary_list_select_options = this.state.beneficiaries_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: `${item.nickname} ${item.client_id ? '' : '[Team Beneficiary]'}`
        // label: item.nickname
      }));

    const purpose_of_payment_select_options = this.props.app_state.current_client.purpose_of_payment_list.map(
      item => ({
        value: item.id,
        label: item.description
      })
    );

    var is_image = false;
    if (
      this.props.transfers_dashboard.new_transfer_data
        .multiple_beneficiary_id[0].document
    ) {
      is_image = true;
    }

    let payment_detail_label =
      this.props.transfers_dashboard &&
      this.props.transfers_dashboard.new_transfer_data &&
      this.props.transfers_dashboard.new_transfer_data
        .multiple_beneficiary_id &&
      this.props.transfers_dashboard.new_transfer_data
        .multiple_beneficiary_id[0].beneficiary_id === 402
        ? "Ninja Trader Account Number"
        : "Payment Detail / Invoice Number";

    let show_invoice;
    if (
      this.props.transfers_dashboard &&
      this.props.transfers_dashboard.new_transfer_data &&
      this.props.transfers_dashboard.new_transfer_data.pending_invoice
    ) {
      show_invoice = (
        <div style={{ textAlign: "center", paddingTop: 25 }}>
          <strong>
            You are paying for invoice #
            {this.props.transfers_dashboard.new_transfer_data.pending_invoice}
          </strong>
        </div>
      );
    }
    // if (
    //   this.props.transfers_dashboard &&
    //   this.props.transfers_dashboard.new_transfer_data &&
    //   this.props.transfers_dashboard.new_transfer_data
    //     .multiple_beneficiary_id &&
    //   this.props.transfers_dashboard.new_transfer_data
    //     .multiple_beneficiary_id[0].beneficiary_id === 487
    // ) {
    //   show_invoice = (
    //     <div style={{ textAlign: "center", paddingTop: 0 }}>
    //       <Card>
    //         <img
    //           style={{ width: 300, padding: 10, paddingRight: 20 }}
    //           src={sample_invoice}
    //         />
    //       </Card>
    //     </div>
    //   );
    // }
    if (this.state.pending_transfer_attachment_url) {
      show_invoice = (
          <div style={{ textAlign: "center", paddingTop: 0 }}>
            <Card>
              {
                this.state.mimeType === 'image' ?
                    <img
                        style={{width: 300, padding: 10, paddingRight: 20}}
                        src={this.state.pending_transfer_attachment_url}
                    /> : <>
                      <img onClick={() => window.open(this.state.pending_transfer_attachment_url, '_blank')}
                           style={{width: 100, height: 100, cursor: 'pointer'}}
                           src={defaultPdf}
                      /> <span onClick={() => window.open(this.state.pending_transfer_attachment_url, '_blank')} style={{ cursor: 'pointer' }}>(Click to view Invoice)</span>
                    </>
              }
            </Card>
          </div>
      )
    }

    return (
        <React.Fragment>
          <GridItem xs={12} sm={12}>
            {this.state.audCollection && <h4>Payment to:</h4>}
            {this.state.audCollection != true && <h4>Beneficiary:</h4>}
          </GridItem>

          <GridItem xs={12} sm={6}>
          {this.props.transfers_dashboard.new_transfer_data
            .multiple_beneficiary_id[0].checked_beneficiary_details !==
            true && (
            <CustomReactSelect
              error={
                this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].beneficiary_id_state === "error"
              }
              label=""
              options={beneficiary_list_select_options}
              value={
                this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].beneficiary_id
              }
              onChange={this.handleCustomReactSelectChange("beneficiary_id")}
              isClearable={false}
              // isDisabled={this.state.prefill_beneficiary}
            />
          )}
            <Table
                style={{color: '333'}}
                size="small"
                tableData={
                  this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary ? [
                  [
                    <span style={{fontWeight: 800, color: '333'}}>
                  BENEFICIARY
                </span>,
                    <span style={{fontWeight: 600, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.nickname}
                </span>
                  ],
                  [
                    <span style={{fontWeight: 800, color: '333'}}>
                  ADDRESS
                </span>,
                    <span style={{fontWeight: 400, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_address_line_1}{` `}
                      {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_adddress_line_2}{` `}
                      {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_address_suburb}{` `}
                      {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_address_state}{` `}
                      {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_address_postcode}{` `}
                      {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_country}{` `}
                </span>
                  ],
                  [
                    <span style={{fontWeight: 800, color: '333'}}>
                  BANK
                </span>,
                    <span style={{fontWeight: 400, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bank_legal_name}
                </span>
                  ],
                  (this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.swift_code && 
                  this.state.audCollection != true) ?
                      [
                        <span style={{fontWeight: 800, color: '333'}}>
                  SWIFT
                </span>,
                        <span style={{fontWeight: 400, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.swift_code}
                </span>
                      ] : [],
                  this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bsb_code ?
                      [
                        <span style={{fontWeight: 800, color: '333'}}>
                  BSB
                </span>,
                        <span style={{fontWeight: 400, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bsb_code}
                </span>
                      ] : [],
                  this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.sort_code ?
                      [
                        <span style={{fontWeight: 800, color: '333'}}>
                  SORT CODE
                </span>,
                        <span style={{fontWeight: 400, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.sort_code}
                </span>
                      ] : [],
                  this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.account_number ?
                      [
                        <span style={{fontWeight: 800, color: '333'}}>
                  ACCOUNT
                </span>,
                        <span style={{fontWeight: 400, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.account_number}
                </span>
                      ] : [],
                  this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.iban ?
                      [
                        <span style={{fontWeight: 800, color: '333'}}>
                  IBAN
                </span>,
                        <span style={{fontWeight: 400, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.iban}
                </span>
                      ] : [],
                ] : []}
            />
          {!this.state.prefill_beneficiary
            ? this.createNewBeneficiaryUi()
            : null}
          {!this.state.prefill_beneficiary ? this.createUI() : null}
          <br />
          {!this.state.prefill_beneficiary && (
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  checked={
                    this.props.transfers_dashboard.new_transfer_data
                      .multiple_beneficiary_id[0].checked_beneficiary_details
                  }
                  onClick={this.handleCheckboxToggle(
                    "checked_beneficiary_details"
                  )}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              label="Provide beneficiary details later"
            />
          )}
          {payment_detail_label === "Ninja Trader Account Number" ? (
            <CustomInput
              // style={{ paddingTop: 25 }}
              success={
                this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].detail_1_state === "success"
              }
              error={
                this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].detail_1_state === "error"
              }
              labelText={<span>{payment_detail_label}</span>}
              id="detail_1"
              name="detail_1"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].detail_1,
                onChange: event => this.change(event, "detail_1"),
                disabled: true // this.state.prefill_payment_detail
              }}
            />
          ) : (
            <CustomInput
              // style={{ paddingTop: 25 }}
              success={
                this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].detail_1_state === "success"
              }
              error={
                this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].detail_1_state === "error"
              }
              labelText={<span>{payment_detail_label}</span>}
              id="detail_1"
              name="detail_1"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].detail_1,
                onChange: event => this.change(event, "detail_1"),
                disabled: this.state.prefill_payment_detail
              }}
            />
          )}
          {(!this.props.transfers_dashboard.new_transfer_data
            .multiple_beneficiary_id[0].detail_1_state === "success" ||
            this.props.transfers_dashboard.new_transfer_data
              .multiple_beneficiary_id[0].detail_1 === "") &&
          this.props.transfers_dashboard.new_transfer_data
            .multiple_beneficiary_id[0].beneficiary_id === 402 ? (
            <span>
              Please ensure the above Ninja Trader Account Number is correct. If
              incorrect, you can change on your{" "}
              <a href="/user-profile">User Profile page</a>.
            </span>
          ) : (
            ""
          )}
          <br /><br />
          <CustomReactSelect
            error={
              this.props.transfers_dashboard.new_transfer_data
                .multiple_beneficiary_id[0].purpose_of_payment_detail_state ===
              "error"
            }
            label="Purpose of payment"
            options={purpose_of_payment_select_options}
            value={
              this.props.transfers_dashboard.new_transfer_data
                .multiple_beneficiary_id[0].purpose_of_payment_detail
            }
            onChange={this.handleCustomReactSelectChange(
              "purpose_of_payment_detail"
            )}
            isClearable={false}
            isDisabled={this.state.prefill_purpose_of_payment}
          />
          {this.props.transfers_dashboard.new_transfer_data
            .multiple_beneficiary_id[0].purpose_of_payment_detail === 1 && (
            <CustomInput
              style={{ paddingTop: 25 }}
              success={
                this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].purpose_of_payment_other_state ===
                "success"
              }
              error={
                this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].purpose_of_payment_other_state ===
                "error"
              }
              labelText={<span>Specify purpose of payment</span>}
              id="purpose_of_payment_other"
              name="purpose_of_payment_other"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].purpose_of_payment_other,
                onChange: event =>
                  this.change(event, "purpose_of_payment_other")
              }}
            />
          )}
          {
            //876 workaround - Typo on dorman text field - by Tom
          }
          
        </GridItem>

        <GridItem xs={12} sm={6}>
          {show_invoice}
          {this.state.showDocumentUpload && (
            <div style={{ textAlign: "center", paddingTop: 25 }}>
              {/*{!is_image && (*/}
                <React.Fragment>

                  {/*<DocumentUpload*/}
                  {/*  circle*/}
                  {/*  change={false}*/}
                  {/*  placeholder={document_image}*/}
                  {/*  updateFunction={this.onupdate}*/}
                  {/*  onRemove={this.onRemove}*/}
                  {/*  index={0}*/}
                  {/*/>*/}
                  <FileUploader uploaderType={'PDF'}
                                onDelete={() => {
                                  this.onRemove(0);
                                  return Promise.resolve();
                                }}
                                thumbType={'picture-card'}
                                ref={this.uploadRef}
                                onUploadSuccess={file => {
                                  this.onupdate(file.name);
                                }}></FileUploader>
                  {this.state.audCollection != true && 
                  <p>
                    Please upload a copy of an invoice or any form of document
                    regarding this payment (optional).
                  </p>
                  }
                  {this.state.audCollection == true &&
                  <p>
                    Please attach any further supporting documents if available
                  </p>
                  }
                </React.Fragment>
              {/*)}*/}
              {/*{is_image && (*/}
              {/*  <React.Fragment>*/}
              {/*    <i className="fas fa-check-circle fa-5x" />*/}
              {/*    <p>Document uploaded successfully</p>*/}
              {/*    <Button onClick={() => this.onRemove()}>*/}
              {/*      <i className="fas fa-times" />*/}
              {/*      Remove*/}
              {/*    </Button>*/}
              {/*  </React.Fragment>*/}
              {/*)}*/}
            </div>
          )}
        </GridItem>
      </React.Fragment>
    );
  };

  handleSelectChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    this.props.updateNewTransferCreation(event.target.name, event.target.value);
    if (event.target.name === "beneficiary_id") {
      this.getBeneficiaryDetails(event.target.value);
    }
  };

  isValidateBeneficiary() {
    if (
      this.state.bank_country == "" ||
      typeof this.state.bank_country === "undefined"
    ) {
      this.setState({ bank_country_state: "error" });
    } else {
      this.setState({ bank_country_state: "success" });
    }

    if (
      this.state.ben_legal_name == "" ||
      typeof this.state.ben_legal_name === "undefined"
    ) {
      this.setState({ ben_legal_name_state: "error" });
    } else {
      this.setState({ ben_legal_name_state: "success" });
    }

    if (
      this.state.account_currency == "" ||
      typeof this.state.account_currency === "undefined"
    ) {
      this.setState({ account_currency_state: "error" });
    } else {
      this.setState({ account_currency_state: "success" });
    }

    if (
      this.state.ben_legal_name == "" ||
      typeof this.state.ben_legal_name === "undefined" ||
      this.state.account_currency == "" ||
      typeof this.state.account_currency === "undefined" ||
      this.state.bank_country == "" ||
      typeof this.state.bank_country === "undefined"
    ) {
      return false;
    } else {
      return true;
    }
  }

  createBeneficiaryIntoDatabase = async data => {
    await this.props.createNewBeneficiary(data);
    //console.log(data);

    // await API.get("beneficiaries", `/beneficiaries/get`)
    await API.get("beneficiaries", `/beneficiaries/list/${this.props.app_state.current_client.id}`)
      .then(response => {
        const filteredResponse = response.filter((item) => { return item.active === 1 })
        for (let i = filteredResponse.length - 1; i > -1; i--) {
          //console.log(response[i].account_currency);
          if (filteredResponse[i].account_currency !== this.state.to_currency) {
            //response.remove(i);
            filteredResponse.splice(i, 1); // Removes the (i)th element of response
            //console.log("remove index"+i)
          }
        }
        //console.log(response);
        this.setState({
          beneficiaries_list: filteredResponse
        });
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({
      ben_legal_name: "",
      ben_email_main: "",
      ben_address_line_1: "",
      ben_address_line_2: "",
      ben_address_suburb: "",
      ben_address_state: "",
      ben_address_postcode: "",
      ben_address_country: "",

      bank_legal_name: "",
      iban: "",
      account_currency: "",
      bsb_code: "",
      swift_code: "",
      account_number: "",
      aba_routing_number: "",
      sort_code: "",
      bank_code: "",
      transit_code: "",
      institution_number: "",
    });

    return;
  };

  handleAddNewBeneficiary = async () => {
    if (this.isValidateBeneficiary()) {
      const {
        bank_country,
        ben_legal_name,
        ben_email_main,
        ben_address_line_1,
        ben_address_line_2,
        ben_address_suburb,
        ben_address_state,
        ben_address_postcode,
        ben_address_country,
        bank_legal_name,
        iban,
        account_currency,
        bsb_code,
        swift_code,
        account_number,
        aba_routing_number,
        sort_code,
        bank_code,
        transit_code,
        institution_number
      } = this.state;

      try {
        await this.createBeneficiaryIntoDatabase({
          client_id: this.props.app_state.current_client.id,
          nickname: ben_legal_name,
          ben_legal_name: ben_legal_name,
          ben_email_main: ben_email_main,
          ben_address_line_1: ben_address_line_1,
          ben_address_line_2: ben_address_line_2,
          ben_address_suburb: ben_address_suburb,
          ben_address_state: ben_address_state,
          ben_address_postcode: ben_address_postcode,
          ben_address_country: ben_address_country,
          bank_legal_name: bank_legal_name,
          bank_address_country: bank_country,
          iban: iban,
          account_currency: account_currency,
          bsb_code: bsb_code,
          swift_code: swift_code,
          account_number: account_number,
          aba_routing_number: aba_routing_number,
          sort_code: sort_code,
          bank_code: bank_code,
          transit_code: transit_code,
          institution_number: institution_number
        });
        this.setState(
          {
            addBeneficiaryModalOpen: false
          },
          () => {
            API.get("beneficiaries", `/beneficiaries/get`)
              .then(response => {
                // let resume_beneficiaries_list = response;
                if (response.length > 0) {
                  for (let i = response.length - 1; i > -1; i--) {
                    if (response[i].account_currency !== account_currency) {
                      response.splice(i, 1); // Removes the (i)th element of response
                    }
                  }
                }
                this.setState({
                  beneficiaries_list: response
                });
              })
              .catch(error => {
                console.log(error);
              });
          }
        );
      } catch (e) {
        this.setState({ isLoading: false });
      }
    }
  };

  addBeneficiaryModelOpen = () => {
    this.setState({
      addBeneficiaryModalOpen: true
    });
  };

  addBeneficiaryModelClose = () => {
    this.setState({
      addBeneficiaryModalOpen: false
    });
  };

  newBeneficiaryChange(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + " _state"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  createUI() {
    const { classes } = this.props;
    const beneficiary_list_select_options = this.state.beneficiaries_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return { value: item.id, label: item.nickname };
      });

    const purpose_of_payment_select_options = this.state.purpose_list.map(
      item => ({
        value: item.id,
        label: item.description
      })
    );

    return (
      <Button
        size="sm"
        color="info"
        key="addKey"
        onClick={() => this.addBeneficiaryModelOpen()}
      >
        Create new beneficiary
      </Button>
    );
  }

  handleDashboardSelectChange = name => value => {
    var option_value;
    if (value === null) {
      option_value = null;
    } else {
      option_value = value.value;
    }
    this.setState({
      [name]: option_value
    });

    this.props.updateNewTransferCreation(name, option_value);

    this.fetchNewRate();
  };

  fetchNewRate = () => {

    if (this.state.audCollection) {
      this.props.updateNewTransferCreation("client_rate", 1);
      this.props.updateNewTransferCreation(
        "current_rate",
        1
      );
      // this.props.updateNewTransferCreation(
      //   "amount_from",
      //   amount_from.toFixed(5)
      // );
      return
    }

    if (
      this.props.transfers_dashboard.new_transfer_data.currency_to &&
      this.props.transfers_dashboard.new_transfer_data.currency_from
    ) {
      let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`;
      axios
        .get(url, {
          params: {
            currencies: this.props.transfers_dashboard.new_transfer_data
              .currency_from.iso_alpha_3,
            source: this.props.transfers_dashboard.new_transfer_data.currency_to
              .iso_alpha_3,
            format: "1"
          }
        })
        .then(response => {
          let key = `${this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}${this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}`;
          let rate_string = response.data.quotes[key];

          let amount_to = Number.parseFloat(
            this.props.transfers_dashboard.new_transfer_data.amount_to
          );
          let rate = Number.parseFloat(rate_string);
          let rate_adjust =
            Number.parseFloat(
              this.props.app_state.current_client.default_rate
            ) +
            Number.parseFloat(
              this.props.app_state.current_client.default_rate_division
            ) +
            Number.parseFloat(
              this.props.app_state.current_client.default_rate_entity
            ) +
            Number.parseFloat(
              this.props.app_state.current_client.default_rate_team
            ) +
            Number.parseFloat(
              this.props.app_state.current_client.default_rate_staff
            );
          let adjusted_rate = rate + rate * rate_adjust;
          console.log(rate);
          console.log(rate_adjust);
          console.log(amount_from);
          let amount_from = amount_to * adjusted_rate;

          this.props.updateNewTransferCreation(
            "current_rate",
            adjusted_rate.toFixed(5)
          );
          this.props.updateNewTransferCreation(
            "amount_from",
            amount_from.toFixed(5)
          );

          if (this.state.payment_method_id == "2") {
            this.getWorldPayPaymentURL();
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  renderFeeWarning = () => {
    if (this.state.audCollection) {
      return null;
    }
    if (this.state.displayFeeWarning) {
    //   return (
    //     <div style={{textAlign: 'right'}}>
    //     <span style={{color: 'red', fontWeight: 800}}>Please Note</span>
    //     <br/>
    //     <em>
    //       Transfers under {this.state.fee_feeCurrencyIso} {this.state.fee_transferAmount} will incur a fee of {this.state.fee_feeCurrencyIso} {this.state.fee_feeAmount} 
    //     </em>
    //   </div>
    // )
      return (
        <Alert message={"Please Note"}
        description={
          <>
          Transfers under {this.state.fee_feeCurrencyIso} {this.state.fee_transferAmount} will incur a fee of {this.state.fee_feeCurrencyIso} {this.state.fee_feeAmount} 
          </>
        } type="warning" showIcon closable />
      )
    }
  }

  createNewBeneficiaryUi = () => {
    //console.log(this.state.country_list)
    const { classes } = this.props;
    const sortedCountry = this.state.country_list.sort((a,b) => a.full_name.localeCompare(b.full_name));
    const country_list_select_options = sortedCountry.map(item => ({
      value: item.id,
      alt: item.full_name,
      label: (
        <React.Fragment>
          <span>
            <img
              src={
                item.iso_alpha_2 !== ""
                  ? require(`../../assets/img/flags/${item.iso_alpha_2}.png`)
                  : ""
              }
            />
          </span>{" "}
          &nbsp; {item.full_name}{" "}
        </React.Fragment>
      )
    }));
    //console.log(this.state.currencies_list_priority)
    //console.log(this.state.currencies_list)
    const select_currency_select_options = this.state.currencies_list_priority.map(
      item => ({
        value: item.id,
        label: (
          <React.Fragment>
            <span
              className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`}
            />
            &nbsp;
            {item.iso_alpha_3 + ": " + item.full_name}
          </React.Fragment>
        )
      })
    );

    return (
      <React.Fragment>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.addBeneficiaryModalOpen || false}
          transition={Transition}
          keepMounted
          onClose={() => this.addBeneficiaryModelClose()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>Add New Beneficiary</h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer justify="flex-start">
              <GridItem xs={12} md={6}>
                <CustomReactSelect
                  label="Currency"
                  options={select_currency_select_options}
                  value={this.state.account_currency}
                  onChange={this.handleCustomReactSelectChange(
                    "account_currency"
                  )}
                  isClearable={false}
                />
                <CustomReactSelect
                  label="Bank Country"
                  options={country_list_select_options}
                  value={this.state.bank_country}
                  onChange={this.handleCustomReactSelectChange("bank_country")}
                  isClearable={false}
                  error={this.state.bank_country_state === "error"}
                />
              </GridItem>
            </GridContainer>
            <hr />
            <h6>Beneficiary Details</h6>

            <GridContainer>
              <GridItem xs={12} md={6}>
                <CustomInput
                  success={this.state.ben_legal_name_state === "success"}
                  error={this.state.ben_legal_name_state === "error"}
                  labelText={<span>Beneficiary (Account) Name</span>}
                  id="ben_legal_name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.ben_legal_name,
                    onChange: event =>
                      this.newBeneficiaryChange(
                        event,
                        "ben_legal_name",
                        "length",
                        3
                      )
                  }}
                />
                <CustomInput
                  success={this.state.ben_address_line_1_state === "success"}
                  error={this.state.ben_address_line_1_state === "error"}
                  labelText={<span>Address Line 1</span>}
                  id="ben_address_line_1"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.ben_address_line_1,
                    onChange: event =>
                      this.newBeneficiaryChange(
                        event,
                        "ben_address_line_1",
                        "length",
                        3
                      )
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomReactSelect
                  label="Country"
                  options={country_list_select_options}
                  value={this.state.ben_address_country}
                  onChange={this.handleCustomReactSelectChange(
                    "ben_address_country"
                  )}
                  isClearable={false}
                />
                <br />
                <GridContainer justify="flex-start">
                  <GridItem
                    xs={12}
                    md={this.state.ben_address_country === 9 ? 6 : 8}
                  >
                    <CustomInput
                      success={
                        this.state.ben_address_suburb_state === "success"
                      }
                      error={this.state.ben_address_suburb_state === "error"}
                      labelText={<span>Suburb / Region</span>}
                      id="ben_address_suburb"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.ben_address_suburb,
                        onChange: event =>
                          this.newBeneficiaryChange(
                            event,
                            "ben_address_suburb",
                            "length",
                            3
                          )
                      }}
                    />
                  </GridItem>
                  {this.state.ben_address_country === 9 && (
                    <GridItem xs={12} md={3}>
                      <CustomInput
                        success={
                          this.state.ben_address_state_state === "success"
                        }
                        error={this.state.ben_address_state_state === "error"}
                        labelText={<span>State</span>}
                        id="ben_address_state"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.ben_address_state,
                          onChange: event =>
                            this.newBeneficiaryChange(
                              event,
                              "ben_address_state",
                              "length",
                              2
                            )
                        }}
                      />
                    </GridItem>
                  )}
                  <GridItem
                    xs={12}
                    md={this.state.ben_address_country === 9 ? 3 : 4}
                  >
                    <CustomInput
                      success={
                        this.state.ben_address_postcode_state === "success"
                      }
                      error={this.state.ben_address_postcode_state === "error"}
                      labelText={<span>Postcode</span>}
                      id="ben_address_postcode"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.ben_address_postcode,
                        onChange: event =>
                          this.newBeneficiaryChange(
                            event,
                            "ben_address_postcode",
                            "length",
                            3
                          )
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer justify="flex-start">
              <GridItem xs={12}>
                <CustomInput
                  success={this.state.ben_email_main_state === "success"}
                  error={this.state.ben_email_main_state === "error"}
                  labelText={<span>Beneficiary Email [optional]</span>}
                  helpText="May be used to provide copy of payout confirmation to beneficiary.  Confirmation is required from you on each transfer."
                  id="ben_email_main"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.ben_email_main,
                    onChange: event =>
                      this.newBeneficiaryChange(
                        event,
                        "ben_email_main",
                        "length",
                        3
                      )
                  }}
                />
              </GridItem>
            </GridContainer>
            <hr />
            <h6>Bank Details</h6>

            <GridContainer justify="flex-start">
              <GridItem xs={12} md={6}>
                <CustomInput
                  success={this.state.bank_legal_name_state === "success"}
                  error={this.state.bank_legal_name_state === "error"}
                  labelText={<span>Bank Name</span>}
                  id="bank_legal_name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.bank_legal_name,
                    onChange: event =>
                      this.newBeneficiaryChange(
                        event,
                        "bank_legal_name",
                        "length",
                        3
                      )
                  }}
                />
                {this.state.show_iban && (
                  <CustomInput
                    success={this.state.iban === "success"}
                    error={this.state.iban === "error"}
                    labelText={<span>IBAN</span>}
                    id="iban"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.iban,
                      onChange: event =>
                        this.newBeneficiaryChange(event, "iban", "length", 3)
                    }}
                  />
                )}
              </GridItem>
              <GridItem xs={12} md={6}>
                {this.state.show_swift_code && (
                  <CustomInput
                    success={this.state.swift_code_state === "success"}
                    error={this.state.swift_code_state === "error"}
                    labelText={<span>SWIFT Code</span>}
                    id="swift_code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.swift_code,
                      onChange: event =>
                        this.newBeneficiaryChange(
                          event,
                          "swift_code",
                          "length",
                          3
                        )
                    }}
                  />
                )}
                {this.state.show_aba && (
                  <CustomInput
                    success={this.state.aba_routing_number_state === "success"}
                    error={this.state.aba_routing_number_state === "error"}
                    labelText={<span>ABA Routing Number</span>}
                    id="aba_routing_number"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.aba_routing_number,
                      onChange: event =>
                        this.newBeneficiaryChange(
                          event,
                          "aba_routing_number",
                          "length",
                          3
                        )
                    }}
                  />
                )}
                {this.state.show_sort_code && (
                  <CustomInput
                    success={this.state.sort_code_state === "success"}
                    error={this.state.sort_code_state === "error"}
                    labelText={<span>Sort Code</span>}
                    id="sort_code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.sort_code,
                      onChange: event =>
                        this.newBeneficiaryChange(
                          event,
                          "sort_code",
                          "length",
                          3
                        )
                    }}
                  />
                )}
                {this.state.show_bsb && (
                  <CustomInput
                    success={this.state.bsb_code_state === "success"}
                    error={this.state.bsb_code_state === "error"}
                    labelText={<span>BSB</span>}
                    id="bsb_code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.bsb_code,
                      onChange: event =>
                        this.newBeneficiaryChange(
                          event,
                          "bsb_code",
                          "length",
                          3
                        )
                    }}
                  />
                )}
                {this.state.show_institution_number && (
                  <CustomInput
                    success={this.state.institution_number_state === "success"}
                    error={this.state.institution_number_state === "error"}
                    labelText={<span>Institution No</span>}
                    id="institution_number"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.institution_number,
                      onChange: event =>
                        this.newBeneficiaryChange(
                          event,
                          "institution_number",
                          "length",
                          2
                        )
                    }}
                  />
                )}
                {this.state.show_transit_code && (
                  <CustomInput
                    success={this.state.transit_code_state === "success"}
                    error={this.state.transit_code_state === "error"}
                    labelText={<span>Transit Code</span>}
                    id="transit_code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.transit_code,
                      onChange: event =>
                        this.newBeneficiaryChange(
                          event,
                          "transit_code",
                          "length",
                          2
                        )
                    }}
                  />
                )}
                {this.state.show_bank_code && (
                  <CustomInput
                    success={this.state.bank_code_state === "success"}
                    error={this.state.bank_code_state === "error"}
                    labelText={<span>Bank Code</span>}
                    id="bank_code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.bank_code,
                      onChange: event =>
                        this.newBeneficiaryChange(
                          event,
                          "bank_code",
                          "length",
                          2
                        )
                    }}
                  />
                )}

                {this.state.show_account_number && (
                  <CustomInput
                    success={this.state.account_number_state === "success"}
                    error={this.state.account_number_state === "error"}
                    labelText={<span>Account Number </span>}
                    id="account_number"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.account_number,
                      onChange: event =>
                        this.newBeneficiaryChange(
                          event,
                          "account_number",
                          "length",
                          3
                        )
                    }}
                  />
                )}
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => this.addBeneficiaryModelClose()}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.handleAddNewBeneficiary}>
              Add Beneficiary
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  // on_view_invoice_click = () => {
  //   this.setState({
  //     view_invoice_modal_open: true
  //   });
  // };

  // view_invoice_modal_close = () => {
  //   this.setState({
  //     view_invoice_modal_open: false
  //   });
  // };
  set_payment_radio_second = e => {
    e.preventDefault();
    this.setState({ payment_method: e.target.value });
    this.props.updateNewTransferCreation("payment_method", e.target.value);
    this.handleAmount("from_amount");
  };


  onClickFromLedger = async (e) => {
    const target = e.target.name
    const value = e.target.checked
    // console.log(target, value)
    if (target == "ledgerFrom") {
      if (value) {
        const clientId = this.props.app_state.current_client.id;
        const currencyId = this.props.transfers_dashboard.new_transfer_data.currency_from_id;
        const holdingAccountList = await API.get(
          "holding_accounts",
          `/get/client-currency/${clientId}/${currencyId}`
        );
        console.log(holdingAccountList);
        this.props.updateNewTransferCreation(
          "ledgerFrom",
          holdingAccountList.holding_account_list[0].id
        );

        this.props.updateNewTransferCreation("amount_from", holdingAccountList.holding_account_list[0].balance.toFixed(2));
        this.handleAmount("to_amount");
        
      } else {
        this.props.updateNewTransferCreation("ledgerFrom", value);
      }
    }
  }


  render() {
    const { classes } = this.props;

    if (this.state.componentLoading) return <span>Loading.  Please wait...</span>

    // For CustomReactSelect. Generate select options for dropdown list.
    const select_currency_from_select_options = [];
    const select_currency_select_options = this.state.currencies_list_priority.map(
      item => {
        if (item.allowFrom) {
          select_currency_from_select_options.push({
            value: item.id,
            // label: item.iso_alpha_3 + ": " + item.full_name
            label: (
              <React.Fragment>
                <Row>
                  <Col>
                    <img
                      src={`https://wise.com/public-resources/assets/flags/rectangle/${item.iso_alpha_3.toLowerCase()}.png`}
                      width='32px' height='16px' style={{verticalAlign: 'middle', marginBottom: '4px', marginRight: 12, border: '1px solid #ccc'}} />
                  </Col>
                  <Col>
                    <strong>
                      <span>{item.iso_alpha_3}</span>
                    </strong>
                  </Col>
                </Row>
              </React.Fragment>
            )
          });
        }
        return {
          value: item.id,
          // label: item.iso_alpha_3 + ": " + item.full_name
          label: (
            <React.Fragment>
              <Row>
                <Col>
                  <img
                    src={`https://wise.com/public-resources/assets/flags/rectangle/${item.iso_alpha_3.toLowerCase()}.png`}
                    width='32px' height='16px' style={{verticalAlign: 'middle', marginBottom: '4px', marginRight: 12, border: '1px solid #ccc'}} />
                </Col>
                <Col>
                  &nbsp;
                  <span>{item.iso_alpha_3}</span>
                </Col>
              </Row>
            </React.Fragment>
          )
        };
      }
    );

    // // Create Ledger Balance Chips
    // let fromCurrencyLedgerChip = (
    //   <Chip icon={<React.Fragment> Ledger Balance: 0.00</React.Fragment>} />
    // );
    // let toCurrencyLedgerChip = (
    //   <Chip icon={<React.Fragment> Ledger Balance: 0.00</React.Fragment>} />
    // );
    let fromCurrencyLedgerChip = null;
    
    for (var idx in this.state.holdingAccountBalances) {
      let record = this.state.holdingAccountBalances[idx];
      if (
        record.currency_id ===
        this.props.transfers_dashboard.new_transfer_data.currency_from_id
        && record.balance
      ) {
        fromCurrencyLedgerChip = (
          <Fragment>
            <p
              style={{
                textAlign: "right",
                textTransform: "capitalize",
                fontWeight: "bold",
                color: "red"
              }}
            >
              {/* {checkledger(record)} */}
              {this.state.displayLedgerWarning && "Amount adjusted to your available balance"}
            </p>
            <Chip
              label={
                <div>
                  <AntCheckbox
                    name="ledgerFrom" 
                    checked={this.props.transfers_dashboard.new_transfer_data.ledgerFrom} 
                    disabled={false} 
                    onChange={this.onClickFromLedger}>
                      Tick to transfer FROM your current {record.currencies_short_name} balance:&nbsp;
                      <strong>
                      {new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: record.currencies_short_name
                      }).format(record.balance)}
                      </strong>
                  </AntCheckbox>
                </div>
              }
            />
          </Fragment>
        );
      } 
    }

    //Dynamic Strings
    let transferRateCondition = "";

    if (!isNaN(this.props.transfers_dashboard.new_transfer_data.client_rate)) {
      transferRateCondition = "dashboard.transferRate";
    } else {
      transferRateCondition = "dashboard.blank";
    }

    let display_invoice;
    if (
      this.props.transfers_dashboard &&
      this.props.transfers_dashboard.new_transfer_data &&
      this.props.transfers_dashboard.new_transfer_data
        .multiple_beneficiary_id &&
      this.props.transfers_dashboard.new_transfer_data
        .multiple_beneficiary_id[0].beneficiary_id === 487
    ) {
      display_invoice = true;
    }

    const newTransferData = this.props.transfers_dashboard.new_transfer_data;
    if (newTransferData.multiple_beneficiary_id && newTransferData.multiple_beneficiary_id.length > 0) {
      if (newTransferData.multiple_beneficiary_id[0].beneficiary &&
        newTransferData.multiple_beneficiary_id[0].beneficiary.holdingAccountId) {
        if (!newTransferData.ledgerTo) {
          this.props.updateNewTransferCreation("ledgerTo",
            newTransferData.multiple_beneficiary_id[0].beneficiary.holdingAccountId
          );
        }
      }
    }

    return (
      <React.Fragment>
        {this.props.app_state.current_brand === "gostudy" && (
          <span>
            Payment provider for <strong>Go Study Australia</strong>
          </span>
        )}
        {this.props.app_state.current_brand === "redhill" && (
          <span>
            Payment provider for <strong>RedHill Education</strong>
          </span>
        )}
        <GridContainer>
          {this.state.audCollection != true && 
          <CommoneCurrentComponent
            selectError={
              this.props.transfers_dashboard.new_transfer_data
                .currency_from_state === "error"
            }
            selectLabel={
              <FormattedMessage
                id="dashboard.fromCurrency"
                defaultMessage={`From currency `}
              />
            }
            // defaultValue={1000}
            color="blue"
            selectOptions={select_currency_from_select_options}
            selectValue={
              this.props.transfers_dashboard.new_transfer_data.currency_from_id
            }
            isClearable={false}
            isSearchable={false}
            onSelectChange={this.handleCustomReactSelectChange(
              "from_currency",
              this.props.transfers_dashboard.new_transfer_data.currency_from_id,
              this.props.transfers_dashboard.new_transfer_data.currency_to_id
            )}
            inputValue="You send"
            inputValue2={
              this.props.transfers_dashboard.new_transfer_data.amount_from
            }
            onInputChange={event => {
              this.change(
                event,
                "from_amount",
                "isLargerThanNumber",
                min_transfer_amount
              );
            }}
          />
          }
          {this.state.audCollection &&
          <>
            <GridItem xs={12} sm={12}>
              <h4>AUD Collection Gateway</h4>
            </GridItem>
          </>
          } 
        </GridContainer>
        {this.renderFeeWarning()}
        {this.props.app_state.current_team.brand_ledger && fromCurrencyLedgerChip}
        {this.state.audCollection != true && <br />}
        {this.props.transfers_dashboard.new_transfer_data.currency_from &&
          this.props.transfers_dashboard.new_transfer_data.currency_to && 
            this.state.audCollection != true && 
          (
            <GridContainer>
              <GridItem xs={8} sm={8} md={7}>
                <p className={classes.transferRate}>
                  transfer rate:&nbsp;
                  {!isNaN(
                    this.props.transfers_dashboard.new_transfer_data.client_rate
                  )
                    ? Number.parseFloat(
                        this.props.transfers_dashboard.new_transfer_data
                          .client_rate
                      ).toFixed(5)
                    : ""}
                </p>
              </GridItem>
              <GridItem xs={8} sm={8} md={5}>
                <div className={classes.selectContainer}>
                  <GridContainer
                    justify="center"
                    style={{ marginBottom: "1px" }}
                  >
                    <GridItem xs={3} md={3} className={classes.cardImg}>
                      <img src={cardPng} alt="" />
                    </GridItem>
                    <GridItem xs={9} md={9} style={{ paddingLeft: "0" }}>
                      <span className={classes.paymentMethod}>
                        Payment method:
                      </span>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12}>
                      <select
                        name="cars"
                        id="cars"
                        className={classes.transactionOptions}
                        defaultChecked={this.state.payment_method}
                        onChange={this.set_payment_radio_second.bind(this)}
                      >
                        <option value="bank">EFT Bank Transfer</option>
                        {/* <option value="bank">EFT Transfer</option> */}
                        {/* <option value="worldpay">Credit Card</option> */}
                      </select>
                    </GridItem>
                    {/* <GridItem xs={1} sm={1} /> */}
                  </GridContainer>
                </div>
                <p style={{ fontWeight: 500 }}>
                  <em>Electronic Funds Transfer</em>
                </p>
              </GridItem>
            </GridContainer>
          )}

        <br />
        {this.state.pendingTransferId && (
          <span>This payment is for Payment Request ID {this.state.pendingTransferId}</span>
        )}
        <GridContainer>
          <CommoneCurrentComponent
            selectError={
              this.props.transfers_dashboard.new_transfer_data
                .currency_to_state === "error"
            }
            selectLabel={
              <FormattedMessage
                id="dashboard.toCurrency"
                defaultMessage={`To currency `}
              />
            }
            color="green"
            selectOptions={select_currency_select_options}
            selectValue={
              this.props.transfers_dashboard.new_transfer_data.currency_to_id
            }
            isClearable={false}
            isSearchable={false}
            // isDisabled={this.state.prefill_from_currency}
            onSelectChange={this.handleCustomReactSelectChange(
              "to_currency",
              this.props.transfers_dashboard.new_transfer_data.currency_from_id,
              this.props.transfers_dashboard.new_transfer_data.currency_to_id
            )}
            inputValue={this.state.audCollection ? "You are paying" : "Beneficiary gets"}
            inputValue2={
              this.props.transfers_dashboard.new_transfer_data.amount_to
            }
            onInputChange={event => {
              this.change(
                event,
                "to_amount",
                "isLargerThanNumber",
                min_transfer_amount
              );
            }}
          />
        </GridContainer>
        {/* {this.props.app_state.current_team.brand_ledger && toCurrencyLedgerChip} */}
        <br />
        <GridContainer justify="center">
          {this.render_beneficiary_details_section()}
        </GridContainer>
        {/* <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.view_invoice_modal_open}
          transition={Transition}
          keepMounted
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>
              Invoice: CrocMedia #100 (1 March 2020)
            </h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <Card>
              <img style={{ width: 600, padding: 10 }} src={sample_invoice} />
            </Card>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button
              onClick={() => this.view_invoice_modal_close()}
              color="success"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    transfers_dashboard: state.transfers_dashboard
  };
};

const mapDispatchToProps = dispatch => {
  return {
    update_map_data: data => {
      dispatch(update_map_data(data));
    },
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    },
    updateNewTransferCreationBeneficiary: array => {
      dispatch(updateNewTransferCreationBeneficiary(array));
    },
    createNewBeneficiary: data => {
      dispatch(createNewBeneficiary(data));
    }
  };
};

const ShortcutTransferStep1Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ShortcutTransferStep1));

export default withRouter(ShortcutTransferStep1Container);
