import {API} from "aws-amplify";

export const FETCH_COUNTRIES_NOT_DELETED_REQUEST = 'FETCH_COUNTRIES_NOT_DELETED_REQUEST'
export const FETCH_COUNTRIES_NOT_DELETED_SUCCESS = 'FETCH_COUNTRIES_NOT_DELETED_SUCCESS'
export const FETCH_COUNTRIES_NOT_DELETED_FAILURE = 'FETCH_COUNTRIES_NOT_DELETED_FAILURE'

export function fetchCountriesNotDeletedListRequest() {
    return {
        type: FETCH_COUNTRIES_NOT_DELETED_REQUEST,
    }
}
export function fetchCountriesNotDeletedListFailure() {
    return {
        type: FETCH_COUNTRIES_NOT_DELETED_FAILURE,
    }
}

export function fetchCountriesNotDeletedSuccess(response) {
    return {
        type: FETCH_COUNTRIES_NOT_DELETED_SUCCESS,
        payload: response,
        receivedAt: Date.now()
    }
}

export function fetchCountriesNotDeletedList() {
    return function(dispatch) {
        dispatch(fetchCountriesNotDeletedListRequest())
        API.get('countries', `/countries/list_not_deleted`, {})
            .then(response => {
                dispatch(fetchCountriesNotDeletedSuccess(response))
            })
            .catch(() => {
                dispatch(fetchCountriesNotDeletedListFailure())
            })
    }
}

