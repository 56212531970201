import React from 'react'
import auFlag from 'assets/img/flags/AU.png'
import deFlag from 'assets/img/flags/DE.png'
import esFlag from 'assets/img/flags/ES.png'
import cnFlag from 'assets/img/flags/CN.png'
import frFlag from 'assets/img/flags/FR.png'
import itFlag from 'assets/img/flags/IT.png'
import ptFlag from 'assets/img/flags/PT.png'

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx'

class LanguageSelection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            language_list: [],
            language_list_prio: [],
            language: '',
        }
    }

    handleDefaultText() {
        let tmpLanguage = ''
        try {
            tmpLanguage = this.props.language.language_current_ui
        } catch (err) {
            console.log(err)
            tmpLanguage = 'en'
        }

        switch (tmpLanguage) {
            case 'en':
                return auFlag
                break
            case 'cn':
                return cnFlag
                break
            case 'es':
                return esFlag
                break
            case 'de':
                return deFlag
                break
            case 'zh':
                return cnFlag
                break
            case 'fr':
                return frFlag
                break
            case 'it':
                return itFlag
                break
            case 'pt':
                return ptFlag
                break
            default:
                return auFlag
                break
        }
    }
    handleCustomReactSelectChange = name => value => {
        let option_value
        if (value === null) {
            option_value = null
        } else {
            option_value = value.value
        }
        this.setState({
            [name]: option_value,
            iconOption: String(option_value) + '.png',
            valueOption: option_value,
        })

        this.props.selectLanguageUi(value.props.id)
    }
    render() {
        let buttonColor = 'info'
        const { page } = this.props

        if (page === 'yes') {
            buttonColor = 'github'
        } //to make it transparent at background and white at foreground

        return (
            <React.Fragment>
                <CustomDropdown
                    buttonText={
                        <span
                            id="sel1"
                            style={{
                                fontFamily: 'Roboto !important',
                                color: '#131313',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '16px',
                            }}
                        >
                            <img src={this.handleDefaultText()} alt="..." />{' '}
                            Language
                        </span>
                    }
                    buttonProps={{
                        round: false,
                        color: 'transparent',
                        lineHeight: '150%',
                    }}
                    value={'userLanguage'}
                    dropdownList={[
                        <span id="en">
                            <img src={auFlag} alt="..." /> English(AU)
                        </span>,
                        <span id="es">
                            <img src={esFlag} alt="..." /> Español
                        </span>,
                        <span id="de">
                            <img src={deFlag} alt="..." /> Deutsch
                        </span>,
                        <span id="zh">
                            <img src={cnFlag} alt="..." /> 中文(CN)
                        </span>,
                        <span id="fr">
                            <img src={frFlag} alt="..." /> Français
                        </span>,
                        <span id="it">
                            <img src={itFlag} alt="..." /> Italiano
                        </span>,
                        <span id="pt">
                            <img src={ptFlag} alt="..." /> Português
                        </span>,
                    ]}
                    onClick={this.handleCustomReactSelectChange(
                        'browserLanguage'
                    )}
                />
            </React.Fragment>
        )
    }
}

export default LanguageSelection
