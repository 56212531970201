import React, {useEffect, useState} from 'react';
import ListTable from "../../components/ListTable/ListTable";

import {Button, Col, Divider, Row, Table} from "antd";
import withStyles from "@material-ui/core/styles/withStyles";
import DashboardWidgetWrapper from "../Dashboards/DashboardWidgetWrapper";
import {NewDashboardUtil} from "../Dashboards/NewDashboardUtil";
import {connect} from "react-redux";
import {fetchTransferListV2} from "../../redux/actions/transfers";
import {useHistory} from "react-router-dom";
import {fetchBeneficiaryList, fetchBeneficiaryListByClientId} from "../../redux/actions/beneficiaries";
import {API} from "aws-amplify";
import CurrencyChip from "../../components/CurrencyChip/CurrencyChip";
import Clearfix from "../../components/Clearfix/Clearfix";
import {ReloadOutlined} from "@ant-design/icons";
import moment from "moment/moment";

const styles = {
    dashboardTopBlock: {
        display: 'flex',
        backgroundColor: 'rgb(219, 239, 243)',
        justifyContent: 'space-between',
        padding: '22px',
        borderRadius: '8px',
        marginTop: '24px',
        position: 'relative',
    },
    dashboardTopBlockItem: {
        display: 'flex',
        flexDirection: 'column'
    },
    dashboardTopBlockButton: {
        marginRight: '20px',
        backgroundColor: 'rgb(74, 176, 193)',
        borderColor: 'rgb(74, 176, 193)'
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    dashboardBtnBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    currency: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'fit-content',
        '& > .currency-flag': {
            marginRight: '0.5rem',
            width: 52,
            height: 26
        }
    },
    currencyLabel: {
        textAlign: 'right',
        '& > p': {
            marginBottom: -5,
            fontSize: '0.6rem'
        }
    },
    progress: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        float: 'left',
        '& > span': {
            // marginLeft: 12,
            fontSize: 12,
            textTransform: 'uppercase'
        }
    },
    progressBar: {width: 50},
    trail: {
        // Trail color
        stroke: '#d6d6d6',
        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: 'butt',
        // Rotate the trail
        transform: 'rotate(0.25turn)',
        transformOrigin: 'left left'
    },
    path: {
        // Path color
        // stroke: `rgba(62, 152, 199, ${percentage / 100})`,
        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: 'butt',
        // Customize transition animation
        transition: 'stroke-dashoffset 0.5s ease 0s',
        // Rotate the path
        transform: 'rotate(0.25turn)',
        transformOrigin: 'left left'
    }
}
const NewDashboardPage = ({
                              classes,
                              getColumnSearchProps,
                              transfers,
                              beneficiaries,
                              app_state,
                              fetchTransferListV2,
                              fetchBeneficiaryListByClientId,
                              history
                          }) => {

    const navigate = useHistory();

    const buttonsA = [
        {type: 'primary', label: <>View Current Transfers</>, action: () => navigate.push('/transfers/current_list')},
    ];

    const buttonsB = [
        {type: 'primary', label: <>View Past Transfers</>, action: () => navigate.push('/transfers/past_list')},
    ];

    const beneficiariesB = [
        {
            type: 'primary',
            label: <>Show All Beneficiaries</>,
            // action: () => navigate.push('/beneficiaries/view-beneficiaries')
            action: () => buildBeneficiariesList()
        },
        // {
        //     type: 'primary',
        //     danger: true,
        //     label: <><ReloadOutlined /></>,
        //     action: () => buildBeneficiariesList()
        // },

    ];

    const [state, setState] = useState({
        transfer_list: [],
        past_transfer_list: [],
        beneficiaries_list: [],
        account_list: [],
        from_code: null,
        to_code: null,
        from_reference: null,
        to_reference: null,
        currencyOptions: []
    });

    const makeTransfersColumn = () => {
        const transfer_status = state.transfer_list.map(
            (prop, key) => {
                return prop.transfer_status;
            });
        const transfer_status_unique = [...new Set(transfer_status)];
        const transfer_status_optionlist = transfer_status_unique.map((prop, key) => ({text: prop, value: prop}));
        return NewDashboardUtil.prepareTransfersColumns(getColumnSearchProps, transfer_status_optionlist, classes, navigate)
    }

    const makeBeneficiariesColumn = () => {
        return NewDashboardUtil.prepareBeneficiariesColumn(getColumnSearchProps, navigate);
    }
    const fetchCurrentTransfers = () => {
        fetchTransferListV2(app_state.current_client.id)
    }

    const fetchHoldingAccounts = async () => {
        if (!app_state.current_client) return [];
        const currentClient = app_state.current_client.id;
        const response = await API.get('holding_accounts', `/get/all/v2/${currentClient}`, {});
        const account_list = response.holding_account_list.map(record => ({
            ...record,
            url: `/holding-account?account=${record.id}`
        }));
        console.log('account_list', account_list)
        setState(prev => {
            return {
                ...prev,
                account_list: account_list
            }
        });
    }

    const buildTransferList = (stateKey) => {
        let transfer_list_data = transfers.transfer_list;

        if (stateKey === 'past_transfer_list') {
            transfer_list_data = transfer_list_data.filter(transfer => {
                return transfer.status === 8 || transfer.status === 10;
            });
        } else {
            transfer_list_data = transfer_list_data.filter(transfer => {
                return transfer.status < 7 || transfer.status === 9;
            });
        }

        const transfer_list_length = transfer_list_data.length;
        let newTransferList = [];
        if (transfer_list_length > 0) {
            for (let i = 0; i < transfer_list_length; i++) {
                newTransferList.push({
                    id: transfer_list_data[i].id,
                    transaction_date: transfer_list_data[i].transaction_datetime != null ? moment(transfer_list_data[i].transaction_datetime).format('DD/MM/YYYY') : '-',
                    isMultiPay: transfer_list_data[i].isMultiPay,
                    status_id: transfer_list_data[i].status,
                    memo: transfer_list_data[i].memo,
                    nickname: transfer_list_data[i].nickname,
                    settlement_date: transfer_list_data[i].settlement_date != null ? moment(transfer_list_data[i].settlement_date).format('DD/MM/YYYY') : '-',
                    beneficiary: transfer_list_data[i].isMultiPay ? 'MultiPay' : transfer_list_data[i].beneficiaries_nickname,
                    currency_from: `${transfer_list_data[i].currency_from_iso_alpha_3}`,
                    amount_from: `${transfer_list_data[i].amount_from}`,
                    currency_to: `${transfer_list_data[i].currency_to_iso_alpha_3}`,
                    amount_to: `${transfer_list_data[i].amount_to}`,
                    client_rate: transfer_list_data[i].client_rate.toFixed(5),
                    transfer_status: transfer_list_data[i].transfer_status,
                    arrow: '⮕',
                });
            }
        }

        setState(prev => {
            return {
                ...prev,
                [stateKey]: newTransferList
            }
        });
    }

    const buildBeneficiariesList = () => {
        setState(prev => {
            return {
                ...prev,
                currencyOptions: beneficiaries.beneficiaries
                    .filter((item, index, self) => index === self.findIndex((t) => (t.currency_iso_alpha_3 === item.currency_iso_alpha_3)))
                    .map(item => ({
                        id: item.account_currency,
                        currencies_short_name: item.currency_iso_alpha_3,
                        balance: 1
                    })),
                beneficiaries_list: beneficiaries.beneficiaries.filter(i => i.active)
            }
        })
    }

    const renderShortcutList = () => {
        return (
            <>
                {/*{*/}
                {/*    state.to_code != null && state.from_code != null*/}
                {/*        && <>*/}
                {/*        <Button type={'primary'} size={"large"}*/}
                {/*                className={classes.dashboardTopBlockButton}>*/}
                {/*            <span className={classes.cursorPointer} onClick={() => {*/}
                {/*                window.location.replace(*/}
                {/*                    `/shortcut_transfer/${state.to_code}`*/}
                {/*                )*/}
                {/*            }}><i className="far fa-paper-plane"></i> {app_state.current_team.brand_send_to_description}</span></Button>*/}
                {/*        <Button type={'primary'} size={"large"}*/}
                {/*                className={classes.dashboardTopBlockButton}>*/}
                {/*            <span className={classes.cursorPointer} onClick={() => {*/}
                {/*                window.location.replace(*/}
                {/*                    `/shortcut_transfer/${state.from_code}`*/}
                {/*                )*/}
                {/*            }}><i className="far fa-paper-plane"></i> {app_state.current_team.brand_send_from_description}</span></Button>*/}
                {/*        <Button type={'primary'} size={"large"}*/}
                {/*                className={classes.dashboardTopBlockButton}>*/}
                {/*            <span className={classes.cursorPointer} onClick={() => {*/}
                {/*                window.location.replace(`/externalTransfer`)*/}
                {/*            }}><i className="far fa-paper-plane"></i> Make FX Transfer </span></Button>*/}
                {/*    </>*/}
                {/*}*/}
                {
                    <Button type={'primary'} size={"large"}
                            style={{
                                height: 60,
                                width: '90%',
                                borderRadius: '5px',
                                marginBottom: '10px',
                                marginLeft: '5%',
                                marginRight: '5%',
                            }}
                            className={classes.dashboardTopBlockButton}>
                            <span className={classes.cursorPointer}
                                  style={{
                                      fontWeight: 800,
                                      fontSize: '1.2em'
                                  }}
                                  onClick={() => {
                                    navigate.push('/externalTransfer')
                                    //   window.location.replace(`/externalTransfer`)
                                  }}>
                                <i className="far fa-paper-plane"></i>&nbsp;&nbsp;
                                Make an FX Transfer
                                </span>
                    </Button>

                }
                {
                    state.to_code != null &&
                    <Button type={'primary'} size={"large"}
                            style={{
                                height: 60,
                                width: '90%',
                                borderRadius: '5px',
                                marginBottom: '10px',
                                marginLeft: '5%',
                                marginRight: '5%',
                            }}
                            className={classes.dashboardTopBlockButton}>
                            <span className={classes.cursorPointer}
                                  style={{
                                      fontWeight: 800,
                                      fontSize: '1.2em'
                                  }}
                                  onClick={() => {
                                      navigate.push(`/shortcut_transfer/${state.to_code}`)
                                    //   window.location.replace(`/shortcut_transfer/${state.to_code}`)
                                  }}>
                                <i className="far fa-paper-plane"></i>&nbsp;&nbsp;
                                {app_state.current_team.brand_send_to_description}
                            </span>
                    </Button>

                }
                <Divider/>
                {
                    <Button type={'primary'} size={"large"}
                            style={{
                                height: 60,
                                width: '90%',
                                borderRadius: '5px',
                                marginBottom: '10px',
                                marginLeft: '5%',
                                marginRight: '5%',
                            }}
                            className={classes.dashboardTopBlockButton}>
                            <span className={classes.cursorPointer}
                                  style={{
                                      fontWeight: 800,
                                      fontSize: '1.2em'
                                  }}
                                  onClick={() => {
                                      navigate.push(`/beneficiaries/view-beneficiaries`)
                                    //   window.location.replace(`/beneficiaries/view-beneficiaries`)
                                  }}>
                                Manage Beneficiaries
                                </span>
                    </Button>

                }
                <Divider/>
                {
                    <Button type={'primary'} size={"large"}
                            style={{
                                height: 60,
                                width: '90%',
                                borderRadius: '5px',
                                marginBottom: '10px',
                                marginLeft: '5%',
                                marginRight: '5%',
                            }}
                            className={classes.dashboardTopBlockButton}>
                            <span className={classes.cursorPointer}
                                  style={{
                                      fontWeight: 800,
                                      fontSize: '1.2em'
                                  }}
                                  onClick={() => {
                                      navigate.push(`/currency-tools/alerts`)
                                    //   window.location.replace(`/currency-tools/alerts`)
                                  }}>
                                Create a Rate Alert
                                </span>
                    </Button>

                }
                

            </>
        )
    }

    const setDefaults = () => {
        let from_code = null;
        let to_code = null;
        let from_reference = null;
        let to_reference = null;
        if (
            app_state.current_team.brand_send_to_code &&
            app_state.current_team.brand_send_to_description
        ) {
            let to_reference = '-'
            switch (app_state.current_team.id) {
                case 24:
                    if (
                        app_state.current_client.external_reference
                    ) {
                        to_reference = `${app_state.current_client.external_reference
                            .toString()
                            .replace('-', ' ')}`
                    } else {
                        to_reference = ``
                    }
                    break
                default:
                    if (
                        app_state.current_client.external_reference
                    ) {
                        to_reference = `${app_state.current_client.external_reference
                            .toString()
                            .replace('-', ' ')}`
                    } else {
                        to_reference = ``
                    }
                    break
            }
            to_code = app_state.current_team.brand_send_to_code
                .toString()
                .replace('CLIENTREF', to_reference);
        }

        if (
            app_state.current_team.brand_send_from_code &&
            app_state.current_team.brand_send_from_description
        ) {
            let from_reference = '-'
            switch (app_state.current_team.id) {
                case 24:
                    if (
                        app_state.current_client.external_reference
                    ) {
                        from_reference = `${app_state.current_client.external_reference
                            .toString()
                            .replace('-', ' ')}`
                    } else {
                        from_reference = ``
                    }
                    break
                default:
                    if (
                        app_state.current_client.external_reference
                    ) {
                        from_reference = `${app_state.current_client.external_reference
                            .toString()
                            .replace('-', ' ')}`
                    } else {
                        from_reference = ``
                    }
                    break
            }
            from_code = app_state.current_team.brand_send_from_code
                .toString()
                .replace('CLIENTREF', from_reference)
        }
        setState(prev => {
            return {
                ...prev,
                from_code: from_code,
                to_code: to_code,
                from_reference: from_reference,
                to_reference: to_reference
            }
        })
    }

    const filterBeneficiaryList = (record) => {
        let currency = record.currencies_short_name;
        let newBeneficiariesList = [];
        if (currency) {
            newBeneficiariesList = beneficiaries.beneficiaries.filter(beneficiary => {
                return beneficiary.currency_iso_alpha_3 === currency;
            });
        } else {
            newBeneficiariesList = beneficiaries.beneficiaries;
        }
        setState(prev => {
            return {
                ...prev,
                beneficiaries_list: newBeneficiariesList
            }
        })
    }

    const navigateToPage = (record, type) => {
        console.log(record);
        if (type === 'LEDGER') {
            navigate.push(record.url)
        } else if (type === 'DEPOSIT') {
            navigate.push(`/externalTransfer?toCurrency=${record.currency_id}&toLedger=${record.id}&purposeOfPayment=87`)
        } else {
            navigate.push(`/externalTransfer?fromCurrency=${record.currency_id}&fromLedger=${record.id}`)
        }
    }
    useEffect(() => {
        // fetchPastTransfers();
        // fetchCurrentTransfers();

    }, [0]);

    useEffect(() => {
        if (app_state.current_client && app_state.current_client.id != null) {
            fetchCurrentTransfers();
            fetchHoldingAccounts();
            setDefaults();
            fetchBeneficiaryListByClientId(app_state.current_client.id);
        }
    }, [app_state]);

    useEffect(() => {
        buildTransferList('past_transfer_list');
        buildTransferList('transfer_list');
    }, [transfers.transfer_list]);

    useEffect(() => {
        buildBeneficiariesList();
    }, [beneficiaries.beneficiaries]);

    if (!app_state.current_team) return null;
    if (!app_state.current_client) return null;
    // if (app_state.current_client.account_status === 1) {
    //     history.push('/pending-registration');
    // }
    // if (app_state.current_client.account_status === 5) {
    //     history.push('/registration');
    // }
    if (app_state.current_client.account_status === 6) {
        history.push('/migrate');
    }

    return (
        <>
            {/* <Row gutter={16} style={{ marginBottom: '15px' }}>
                <Col span={24}>
                    <div className={classes.dashboardTopBlock}>
                        <span className={classes.dashboardBtnBlock}>
                            {render_top_row()}
                        </span>
                    </div>
                </Col>
            </Row> */}
            <Row gutter={16}>
                <Col span={24}>
                    {app_state && app_state.current_team && 
                        app_state.current_team.brand_sidebar_ledger === 1 && 
                        <div style={{
                            borderRadius: '10px',
                            width: '100%',
                            background: '#fff',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            paddingRight: '10px',
                            marginBottom: '10px',
                            height: 'auto'
                        }}>
                            <div style={{paddingLeft: 20}}>
                                <h4 style={{fontWeight: 800}}>Currency Balances: </h4>
                                <div>
                                    <CurrencyChip state={app_state} emitRecord={(record, type) => navigateToPage(record, type) } chipType={'OPTION'} borderColor={'#00acc1'} data={state.account_list}/>
                                    <Clearfix/>
                                </div>
                            </div>

                        </div>}
                </Col>
            </Row>
            <Row gutter={16} style={{marginBottom: 10}}>
                <Col span={8}>
                    <div style={{
                        backgroundColor: '#fff',
                        // border: '1px solid #000',
                        borderRadius: '10px',
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: '100%'
                    }}>
                        {renderShortcutList()}
                    </div>
                </Col>
                <Col span={16}>
                    {/* <DashboardWidgetWrapper contentTitle={'Pay Beneficiary'} buttons={beneficiariesB}> */}
                    <div style={{
                        backgroundColor: '#fff',
                        // border: '1px solid #000',
                        borderRadius: '10px',
                        padding: '10px',
                        // display: 'flex',
                        // justifyContent: 'center',
                        // alignItems: 'center',
                        // flexDirection: 'column',
                        height: '100%'
                    }}>

                        <Row style={{marginBottom: 10}}>
                            <Col span={4}>
                                <h5>Pay Beneficiary</h5>
                            </Col>
                            <Col span={20}>
                                <Button 
                                    type={'primary'} 
                                    size={'large'} 
                                    style={{ backgroundColor: '#00acc1', marginBottom: '10px', borderRadius: '5px', height: '100%'}} 
                                    onClick={() => setState(prev => ({...prev, beneficiaries_list: [...beneficiaries.beneficiaries] }))}>
                                    <strong>
                                        Show All
                                    </strong>
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 10}}>
                            <Col span={4}>
                                <h5>Pay Beneficiary by Currency</h5>
                            </Col>
                            <Col span={20}>
                                <CurrencyChip chipType={'BUTTON'} emitRecord={(e) => filterBeneficiaryList(e) } borderColor={'#00acc1'} showBorder={false} data={state.currencyOptions}
                                    showValue={false}/>
                            </Col>
                        </Row>
                        <Table
                            virutal
                            pagination={{pageSize: 4}}
                            dataSource={state.beneficiaries_list}
                            size={"small"}
                            columns={makeBeneficiariesColumn()}>
                        </Table>
                        </div>
                    {/* </DashboardWidgetWrapper> */}
                </Col>
            </Row>
            <Row gutter={16} style={{marginBottom: '15px'}}>
                <Col span={24}>
                    <DashboardWidgetWrapper contentTitle={'Current Transfers'} buttons={buttonsA}>
                        <Table
                            virtual
                            // scroll={{ x: 1000, y: 300 }}
                            pagination={{pageSize: 5}}
                            dataSource={state.transfer_list} size={"small"}
                            columns={makeTransfersColumn()}>
                        </Table>
                    </DashboardWidgetWrapper>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers: state.transfers,
        beneficiaries: state.beneficiaries,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchTransferListV2: (clientId) => {
            dispatch(fetchTransferListV2(clientId));
        },
        fetchBeneficiaryListByClientId: (clientId) => {
            dispatch(fetchBeneficiaryListByClientId(clientId))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListTable(NewDashboardPage)));