import React from 'react'
import ReactTable from 'react-table'
import { matchSorter } from 'match-sorter'
import Badge from '../Badge/Badge'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import withStyles from '@material-ui/core/styles/withStyles'
import WarningIcon from '@material-ui/icons/Warning'
import { connect } from 'react-redux'
import { updateEconomicCalendar } from '../../redux/actions'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import Snackbars from '../../components/Snackbar/Snackbar'
import AddAlert from '@material-ui/icons/AddAlert'
import Table from '../Table/Table'
import { makeStyles } from '@material-ui/core/styles'

import styles from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'

var axios = require('axios')
const moment = require('moment')

const stylesA = theme => ({
    ...styles,
    root: {
        width: '100%',
        maxWidth: 1200,
        marginLeft: 'auto',
        marginRight: 'auto',
        minHeight: 200,
        padding: 10,
    },
    component_title: {},
    table: {
        color: 'white',
    },
    avatar: {
        width: 20,
        height: 20,
        marginLeft: 6,
        backgroundColor: 'rgb(185, 185, 185)',
    },
    chip: {
        fontWeight: 500,
        '&:hover': {
            backgroundColor: '#1976d2',
            color: 'white',
        },
    },
    chipActive: {
        fontWeight: 500,
        backgroundColor: '#1976d2',
        color: 'white',
        '&:hover': {
            backgroundColor: 'white',
            color: '#1976d2',
        },
    },
})

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
}

class EconomicCalender extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            posts: [],
            important_posts: [],

            selectedDate: moment().format('DD MMMM YYYY'),
            selectedCurrency: 'ALL',
            snackbar_open: false,
            tl: true,
        }
    }

    componentDidMount() {
        this.populate_data()

        setTimeout(() => {
            this.setState({ tl: false })
        }, 10000)
    }

    setTl = data => {
        this.setState({
            tl: data,
        })
    }

    populate_data = () => {
        let selected_date = moment(this.state.selectedDate).dayOfYear()
        let posts = []
        let important_posts = []

        // let url = `https://api.forexworldwide.com/economic-calendar`;
        let url = `https://h571v9oqi8.execute-api.ap-southeast-2.amazonaws.com/dev/economic-info-data`

        axios
            .get(url)
            .then(res => {
                console.log(res.data.message)

                for (let item in res.data.message) {
                    // console.log(item)
                    // console.log(moment(res.data.message[item].data_release))
                    if (
                        moment(
                            res.data.message[item].data_release
                        ).dayOfYear() === selected_date
                    ) {
                        let push_data = {
                            id: item,
                            timestamp: res.data.message[item].data_release,
                            date: moment(
                                res.data.message[item].data_release
                            ).format('DD-MM-YYYY'),
                            time: moment(
                                res.data.message[item].data_release
                            ).format('hh:mm a'),
                            // icon: res.data[item].icon,
                            currency: res.data.message[item].currency,
                            memo: res.data.message[item].title,
                            priority: res.data.message[item].impact,
                            // actual: res.data[item].actual,
                            forecast: res.data.message[item].forecast,
                            previous: res.data.message[item].previous,
                        }
                        posts.push(push_data)
                        if (res.data.message[item].impact === 1) {
                            important_posts.push(push_data)
                        }
                    }
                }
            })
            .then(() => {
                console.log(posts)
                this.setState({
                    rows: posts,
                    posts: posts,
                    important_posts: important_posts,
                    loading: false,
                })
            })
            .then(() => {
                // this.handle_snackbar_opend();
                // console.log("Do Something  .. ");
            })
            .catch(err => {
                console.log(err)
            })
    }

    buildTableData() {
        var post_length = this.state.posts.length
        if (post_length > 0) {
            const rows = this.state.posts.filter(row => {
                if (this.state.selectedCurrency === 'ALL') {
                    return true
                }
                return row.currency === this.state.selectedCurrency
            })

            return rows.map((prop, key) => {
                return {
                    id: prop.id,
                    timestamp: prop.timestamp,
                    date: prop.date,
                    time: prop.time,
                    // icon: prop.icon,
                    currency: prop.currency,
                    memo: prop.memo,
                    priority: prop.priority,
                    // actual: prop.actual,
                    forecast: prop.forecast,
                    previous: prop.previous,
                }
            })
        }
    }

    buildDataForSnackBar() {
        // console.log(this.state.posts);
        var post_length = this.state.posts.length

        if (post_length > 0 && this.props.app_state.show_economic_calendar) {
            const rows = this.state.posts.filter(row => {
                return row.priority === 1
            })

            let return_value = []
            for (let item in rows) {
                let row = rows[item]

                // Format for time of item
                let post_moment = moment(row.timestamp, 'YYYY-MM-DDTHH:mm:ss')
                // console.log(row);
                // console.log(post_moment);

                let item_to_add = [
                    <div
                        style={{ color: 'White', fontWeight: 500, width: 800 }}
                    >
                        {row.time}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                            className={`currency-flag currency-flag-${row.currency.toLowerCase()}`}
                        />
                        &nbsp;&nbsp;
                        {row.currency}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {row.memo}
                    </div>,
                ]
                //let item_to_add = [row.date, row.currency, row.memo ];
                return_value.push(item_to_add)
            }

            // this.props.updateEconomicCalendar(false);
            return return_value
        }
        return []
        // rows.map((prop, key) => {
        //   return {
        //     id: prop.id,
        //     timestamp: prop.timestamp,
        //     date: prop.date,
        //     time: prop.time,
        //     // icon: prop.icon,
        //     currency: prop.currency,
        //     memo: prop.memo
        //     // priority: prop.priority,
        //     // actual: prop.actual,
        //     // forecast: prop.forecast,
        //     // previous: prop.previous
        //   };
        // }
    }

    handleCurrencyChange = currency => {
        this.setState({
            selectedCurrency: currency.toUpperCase(),
        })
    }

    handleDateChange = date => {
        this.setState(
            { selectedDate: date.format('DD MMMM YYYY') },
            this.populate_data
        )
    }

    render() {
        const { classes } = this.props
        var currency = this.state.posts.map((prop, key) => {
            return prop.currency
        })
        var currency_unique = [...new Set(currency)]
        var currency_unique_optionlist = currency_unique.map((prop, key) => {
            return <option value={prop}>{prop}</option>
        })

        const { className, message, onClose, variant, ...other } = this.props
        // console.log(this.state.posts)

        return (
            <React.Fragment>
                {this.props.app_state.show_economic_calendar && (
                    <Snackbars
                        place="tl"
                        color="success"
                        icon={AddAlert}
                        message={
                            <React.Fragment>
                                <h4 style={{ fontWeight: 500 }}>
                                    Today's Important Data
                                </h4>
                                {this.buildDataForSnackBar()}
                            </React.Fragment>
                        }
                        open={this.state.tl}
                        closeNotification={() => this.setTl(false)}
                        close
                    />
                )}
                <div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection:
                                this.props.width === 'sm'
                                    ? 'column'
                                    : this.props.width === 'md'
                                        ? 'column'
                                        : 'row',
                            justifyContent: 'space-between',
                            marginBottom: 4,
                            padding: this.props.width !== 'xs' ? 20 : 0,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection:
                                    this.props.width !== 'xs'
                                        ? 'row'
                                        : 'column',
                            }}
                        >
                            <Chip
                                label={moment()
                                    .subtract(1, 'day')
                                    .format('DD MMMM YYYY')}
                                onClick={() =>
                                    this.handleDateChange(
                                        moment().subtract(1, 'day')
                                    )
                                }
                                onMouseOver={this.props.on_mouse_over}
                                onMouseOut={this.props.on_mouse_out}
                                className={
                                    this.state.selectedDate ===
                                    moment()
                                        .subtract(1, 'day')
                                        .format('DD MMMM YYYY')
                                        ? classes.chipActive
                                        : classes.chip
                                }
                            />
                            <Chip
                                label={moment().format('DD MMMM YYYY')}
                                onClick={() => this.handleDateChange(moment())}
                                onMouseOver={this.props.on_mouse_over}
                                onMouseOut={this.props.on_mouse_out}
                                className={
                                    this.state.selectedDate ===
                                    moment().format('DD MMMM YYYY')
                                        ? classes.chipActive
                                        : classes.chip
                                }
                            />
                            <Chip
                                label={moment()
                                    .add(1, 'day')
                                    .format('DD MMMM YYYY')}
                                onClick={() =>
                                    this.handleDateChange(
                                        moment().add(1, 'day')
                                    )
                                }
                                onMouseOver={this.props.on_mouse_over}
                                onMouseOut={this.props.on_mouse_out}
                                className={
                                    this.state.selectedDate ===
                                    moment()
                                        .add(1, 'day')
                                        .format('DD MMMM YYYY')
                                        ? classes.chipActive
                                        : classes.chip
                                }
                            />
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection:
                                    this.props.width !== 'xs'
                                        ? 'row'
                                        : 'column',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Chip
                                avatar={
                                    <Avatar
                                        className={classes.avatar}
                                        src={require('../../assets/futuro-icons/25_travel_the_world.svg')}
                                    />
                                }
                                label="ALL"
                                onClick={() => this.handleCurrencyChange('ALL')}
                                className={classes.chip}
                                style={{
                                    backgroundColor: '#fff !important',
                                    marginRight: 10,
                                    marginBottom: 10,
                                }}
                            />
                            <Chip
                                avatar={
                                    <Avatar>
                                        <span
                                            className={`currency-flag currency-flag-aud`}
                                        />
                                    </Avatar>
                                }
                                label="AUD"
                                onClick={() => this.handleCurrencyChange('aud')}
                                className={classes.chip}
                                style={{
                                    backgroundColor: '#fff !important',
                                    marginRight: 10,
                                    marginBottom: 10,
                                }}
                            />
                            <Chip
                                avatar={
                                    <Avatar>
                                        <span
                                            className={`currency-flag currency-flag-cad`}
                                        />
                                    </Avatar>
                                }
                                label="CAD"
                                onClick={() => this.handleCurrencyChange('cad')}
                                className={classes.chip}
                                style={{
                                    backgroundColor: '#fff !important',
                                    marginRight: 10,
                                    marginBottom: 10,
                                }}
                            />
                            <Chip
                                avatar={
                                    <Avatar>
                                        <span
                                            className={`currency-flag currency-flag-cny`}
                                        />
                                    </Avatar>
                                }
                                label="CNY"
                                onClick={() => this.handleCurrencyChange('cny')}
                                className={classes.chip}
                                style={{
                                    backgroundColor: '#fff !important',
                                    marginRight: 10,
                                    marginBottom: 10,
                                }}
                            />
                            <Chip
                                avatar={
                                    <Avatar>
                                        <span
                                            className={`currency-flag currency-flag-eur`}
                                        />
                                    </Avatar>
                                }
                                label="EUR"
                                onClick={() => this.handleCurrencyChange('eur')}
                                className={classes.chip}
                                style={{
                                    backgroundColor: '#fff !important',
                                    marginRight: 10,
                                    marginBottom: 10,
                                }}
                            />
                            <Chip
                                avatar={
                                    <Avatar>
                                        <span
                                            className={`currency-flag currency-flag-gbp`}
                                        />
                                    </Avatar>
                                }
                                label="GBP"
                                onClick={() => this.handleCurrencyChange('gbp')}
                                className={classes.chip}
                                style={{
                                    backgroundColor: '#fff !important',
                                    marginRight: 10,
                                    marginBottom: 10,
                                }}
                            />
                            <Chip
                                avatar={
                                    <Avatar>
                                        <span
                                            className={`currency-flag currency-flag-jpy`}
                                        />
                                    </Avatar>
                                }
                                label="JPY"
                                onClick={() => this.handleCurrencyChange('jpy')}
                                className={classes.chip}
                                style={{
                                    backgroundColor: '#fff !important',
                                    marginRight: 10,
                                    marginBottom: 10,
                                }}
                            />
                            <Chip
                                avatar={
                                    <Avatar>
                                        <span
                                            className={`currency-flag currency-flag-nzd`}
                                        />
                                    </Avatar>
                                }
                                label="NZD"
                                onClick={() => this.handleCurrencyChange('nzd')}
                                className={classes.chip}
                                style={{
                                    backgroundColor: '#fff !important',
                                    marginRight: 10,
                                    marginBottom: 10,
                                }}
                            />
                            <Chip
                                avatar={
                                    <Avatar>
                                        <span
                                            className={`currency-flag currency-flag-usd`}
                                        />
                                    </Avatar>
                                }
                                label="USD"
                                onClick={() => this.handleCurrencyChange('usd')}
                                className={classes.chip}
                            />
                        </div>
                    </div>
                    <ReactTable
                        data={this.buildTableData()}
                        //filterable
                        sortable={false}
                        columns={[
                            {
                                Header: 'Time ',
                                accessor: 'time',
                                Cell: c => {
                                    return <div>{c.value}</div>
                                },
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                        keys: ['time'],
                                    }),
                                filterAll: true,
                                maxWidth: 150,
                            },
                            {
                                Header: 'Currency',
                                accessor: 'currency',
                                Cell: c => {
                                    return (
                                        <span>
                                            <div
                                                className={`currency-flag currency-flag-${c.value.toLowerCase()}`}
                                            />
                                            &nbsp; {c.value}
                                        </span>
                                    )
                                },
                                filterMethod: (filter, row) => {
                                    if (filter.value === 'all') {
                                        return true
                                    }
                                    return row[filter.id] == filter.value
                                },

                                maxWidth: 150,
                            },
                            {
                                Header: 'Event Item',
                                accessor: 'memo',
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                        keys: ['memo'],
                                    }),
                                filterAll: true,
                                maxWidth: 500,
                            },
                            {
                                Header: 'Impact',
                                accessor: 'priority',
                                Cell: c => {
                                    if (c.value == 1)
                                        return (
                                            <span>
                                                <Badge color="danger">
                                                    <div
                                                        style={{
                                                            width: '100px',
                                                        }}
                                                    >
                                                        High
                                                    </div>
                                                </Badge>
                                            </span>
                                        )
                                    if (c.value == 2)
                                        return (
                                            <span>
                                                <Badge color="info">
                                                    <div
                                                        style={{
                                                            width: '100px',
                                                        }}
                                                    >
                                                        Medium
                                                    </div>
                                                </Badge>
                                            </span>
                                        )
                                    if (c.value == 3)
                                        return (
                                            <span>
                                                <Badge color="success">
                                                    <div
                                                        style={{
                                                            width: '100px',
                                                        }}
                                                    >
                                                        Low
                                                    </div>
                                                </Badge>
                                            </span>
                                        )
                                    return <span />
                                },
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                        keys: ['priority'],
                                    }),
                                filterAll: true,
                                maxWidth: 150,
                            },
                            // {
                            //   Header: "Actual",
                            //   accessor: "actual",
                            //   Cell: (c) =>
                            //     <React.Fragment>{(c.value) ? moment(c.value).format("DD/MM/YYYY") : ""} </React.Fragment>,
                            //   filterMethod: (filter, rows) =>
                            //     matchSorter(rows, filter.value, {keys: ["actual"]}),
                            //   filterAll: true,
                            //   maxWidth: 120
                            // },
                            {
                                Header: 'Forecast',
                                accessor: 'forecast',
                                Cell: c => {
                                    if (!c.value) return ''
                                    var str = c.value.split('%')
                                    if (str[0] < 0)
                                        return (
                                            <span>
                                                <Badge color="danger">
                                                    <div
                                                        style={{
                                                            width: '80px',
                                                        }}
                                                    >
                                                        {c.value}
                                                    </div>
                                                </Badge>
                                            </span>
                                        )
                                    else if (str[0] > 0)
                                        return (
                                            <span>
                                                <Badge color="success">
                                                    <div
                                                        style={{
                                                            width: '80px',
                                                        }}
                                                    >
                                                        {c.value}
                                                    </div>
                                                </Badge>
                                            </span>
                                        )

                                    return <span>{c.value}</span>
                                },
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                        keys: ['forecast'],
                                    }),
                                filterAll: true,
                                maxWidth: 120,
                            },
                            {
                                Header: 'Previous',
                                headerClassName: 'hdrCls',
                                accessor: 'previous',
                                Cell: c => {
                                    if (!c.value) return ''
                                    var str = c.value.split('%')
                                    if (str[0] < 0)
                                        return (
                                            <span>
                                                <Badge color="danger">
                                                    <div
                                                        style={{
                                                            width: '80px',
                                                        }}
                                                    >
                                                        {c.value}
                                                    </div>
                                                </Badge>
                                            </span>
                                        )
                                    else if (str[0] > 0)
                                        return (
                                            <span>
                                                <Badge color="success">
                                                    <div
                                                        style={{
                                                            width: '80px',
                                                        }}
                                                    >
                                                        {c.value}
                                                    </div>
                                                </Badge>
                                            </span>
                                        )

                                    return <span>{c.value}</span>
                                },
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                        keys: ['previous'],
                                    }),
                                filterAll: true,
                                maxWidth: 120,
                            },
                        ]}
                        defaultPageSize={20}
                        // pageSizeOptions={[3, 6]}

                        getTrProps={(state, rowInfo, instance) => {
                            if (rowInfo) {
                                let current_time = moment()
                                let item_time = moment(
                                    rowInfo.row.time,
                                    'hh:mm a'
                                )
                                let is_passed = item_time.isBefore(current_time)
                                return {
                                    style: {
                                        background: is_passed
                                            ? '#e6e6e6'
                                            : 'none',
                                    },
                                }
                            }
                            return {}
                        }}
                    />
                </div>
            </React.Fragment>
        )
    }
}

// export default withStyles(stylesA)(EconomicCalender);

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateEconomicCalendar: value => {
            dispatch(updateEconomicCalendar(value))
        },
    }
}

const EconomicCalenderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(stylesA)(EconomicCalender))

export default EconomicCalenderContainer
