import { connect } from 'react-redux'
import IdentificationList from './IdentificationList'
import {
    fetchIdentificationList,
    // setBeneficiarySelectId
} from '../../redux/actions/identification'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        identification: state.identification,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchIdentificationList: client_id => {
            dispatch(fetchIdentificationList(client_id))
        },
        // setBeneficiarySelectId: (id) => {
        //   dispatch(setBeneficiarySelectId(id))
        // }
    }
}

const IdentificationListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IdentificationList)

export default IdentificationListContainer
