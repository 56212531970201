import React from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom';
import {
    updateNewTransferCreation,
    updateNewTransferCreationBeneficiary,
    createNewTransfer,
} from '../../redux/actions/transfers_dashboard_multiple'

import withStyles from '@material-ui/core/styles/withStyles'
import dashboardStyle from '../../views/Pages/DashboardPageStyle'
import { injectIntl } from 'react-intl'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'

import { API } from 'aws-amplify'
import Table from 'components/Table/Table.jsx'
import { Alert } from 'antd';

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days';
dayjs.extend(dayjsBusinessDays);

const moment = require('moment')
const axios = require('axios')

class DashboardTransferStep2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            current_step: 2,
            time: {},
            seconds: 1,
            transferSubmitting: false
        }

        this.timer = 0
    }

    async componentDidMount() {
        this.props.setCallable(this.handle_transfer_creation)
        this.props.setRateState(this.fetchNewRate)

        const remittingAccountDetails = await API.post(
            'transfers',
            '/remitting-account/get-details-new',
            {
                body: {
                    currencyId: this.props.transfers_dashboard_multiple
                        .new_transfer_data.currency_from.id,
                    teamId: this.props.app_state.current_team.id,
                    clientId: this.props.app_state.current_client.id,
                },
            }
        )
            .then(response => {
                console.log(response)
                return response
            })
            .catch(error => {
                console.log(error)
            })

        this.props.updateNewTransferCreation(
            'remitting_bank_account_detail',
            remittingAccountDetails.entityBanksAccountResults
        )
        this.props.updateNewTransferCreation(
            'remitting_bank_detail',
            remittingAccountDetails.entityBanksResults
        )

        if (this.props.transfers_dashboard_multiple.current_step === 2) {
            this.props.startTimer()
        }
        if (this.props.transfers_dashboard_multiple.current_step !== 2) {
            this.props.stopTimer()
            this.props.go = false
        }
    }

    componentWillUnmount() {
        this.props.stopTimer()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.prefill !== this.props.prefill) {
            this.props.switch_to_step1()
        }
    }

    secondsToTime = secs => {
        let hours = Math.floor(secs / (60 * 60))

        let divisor_for_minutes = secs % (60 * 60)
        let minutes = Math.floor(divisor_for_minutes / 60)

        let divisor_for_seconds = divisor_for_minutes % 60
        let seconds = Math.ceil(divisor_for_seconds)

        let obj = {
            h: hours,
            m: minutes,
            s: seconds,
        }
        return obj
    }

    startTimer = () => {
        if (this.props.transfers_dashboard_multiple.current_step === 2) {
            this.props.go = true
        }

        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000)
        }
    }

    countDown = () => {
        //go means this is step 2, we start count down

        let seconds = this.state.seconds - 1

        if (this.go) {
            this.setState({
                time: this.secondsToTime(seconds),
                seconds: seconds,
            })

            if (seconds === 0) {
                this.fetchNewRate()
                this.setState({
                    seconds: 30,
                })
            }
        }
    }

    stopTimer = () => {
        this.go = false
        this.setState({
            seconds: 0,
            time: 0,
        })
        clearInterval(this.timer)
        this.timer = null
    }

    fetchNewRate = () => {
        let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
        axios
            .get(url, {
                params: {
                    currencies: this.props.transfers_dashboard_multiple
                        .new_transfer_data.currency_to.iso_alpha_3,
                    source: this.props.transfers_dashboard_multiple
                        .new_transfer_data.currency_from.iso_alpha_3,
                    format: '1',
                },
            })
            .then(response => {
                let key = `${this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}${this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}`
                let rate_string = response.data.quotes[key]

                // let amount_to = Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.amount_to);
                let rate = Number.parseFloat(rate_string)
                let rate_adjust =
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client
                            .default_rate_division
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_entity
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_team
                    ) +
                    Number.parseFloat(
                        this.props.app_state.current_client.default_rate_staff
                    )
                let adjusted_rate = rate - rate * rate_adjust

                if (
                    this.props.transfers_dashboard_multiple.new_transfer_data
                        .currency_from_id ==
                    this.props.transfers_dashboard_multiple.new_transfer_data
                        .currency_to_id
                ) {
                    adjusted_rate = rate
                }

                // handle to_amount
                if (
                    this.props.transfers_dashboard_multiple.new_transfer_data
                        .amount_lock === 'from_amount'
                ) {
                    let amount_from = Number.parseFloat(
                        this.props.transfers_dashboard_multiple
                            .new_transfer_data.amount_from
                    )
                    let amount_to = amount_from * adjusted_rate
                    this.props.updateNewTransferCreation(
                        'amount_to',
                        amount_to.toFixed(2)
                    )
                }

                // handle from_amount
                if (
                    this.props.transfers_dashboard_multiple.new_transfer_data
                        .amount_lock === 'to_amount'
                ) {
                    let amount_to = Number.parseFloat(
                        this.props.transfers_dashboard_multiple
                            .new_transfer_data.amount_to
                    )
                    let amount_from = amount_to / adjusted_rate
                    this.props.updateNewTransferCreation(
                        'amount_from',
                        amount_from.toFixed(2)
                    )
                }
                this.props.updateNewTransferCreation(
                    'client_rate',
                    adjusted_rate
                )
            })
            .catch(error => {
                console.log(error)
            })
    }

    getNextBusinessDay(d, n) {
        d = new Date(d.getTime())
        var day = d.getDay()
        d.setDate(
            d.getDate() +
                n +
                (day === 6 ? 2 : +!day) +
                Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2
        )
        return d
    }

    handle_transfer_creation = async () => {
        console.log('handleTransferCreation')
        console.log(this.props.transfers_dashboard_multiple.new_transfer_data);

        this.setState({transferSubmitting: true})
    
        let testClient = false;
        if (this.props.app_state.current_client.id === 55) {
          testClient = true;
        }   

        const recordCreatedDatetimeLocal = dayjs().format('YYYY-MM-DD HH:mm:ss');
        const transactionDatetimeLocal = dayjs().format('YYYY-MM-DD HH:mm:ss');
        const transactionDateLocal = dayjs().format('YYYY-MM-DD');
    
        let set_date = dayjs().add(2, 'day');
        console.log('set_date', set_date)
    
        if (dayjs().day() === 6) {
            set_date = set_date.add(2, 'day');
          } else if (dayjs().day() === 0) {
            set_date = set_date.add(1, 'day');
          }
      
          let set_date_dayofweek = set_date.day();
          if (set_date_dayofweek === 6) {
            set_date = set_date.add(2, 'day');
          } else if (set_date_dayofweek === 0) {
            set_date = set_date.add(1, 'day');
          }
          console.log('adjusted set_date', set_date)
          const settlement_date = set_date.format('YYYY-MM-DD');
          console.log('settlement_date', settlement_date)

          
        // let set_date = this.getNextBusinessDay(new Date(), 2)
        // let settlement_date = set_date
        //     .toISOString()
        //     .slice(0, 10)
        //     .replace('T', ' ')

        await API.post('email', `/interaction/main`, {
            body: {
                interaction: 'New Transfer Request',
                data: {
                    ...this.props.transfers_dashboard_multiple.new_transfer_data,
                    record_created_datetime: recordCreatedDatetimeLocal,
                    transaction_datetime: transactionDatetimeLocal,
                    transaction_date: transactionDateLocal,
                    settlement_date: settlement_date,
                    status: '2',
                    from: 'Client Portal - New Multipay Transfer',
                    purpose: 'Client Portal New MultiPay Submission'
                },
                result: 'database submission in process',
            },
        })

        // Step 1 - Email client
        // the following API call is working but the content might need to be modified
        let adjusted_remittance_reference = this.props.transfers_dashboard_multiple.new_transfer_data.remitting_bank_account_detail.reference_mandatory
            ? this.props.transfers_dashboard_multiple.new_transfer_data.remitting_bank_account_detail.reference_mandatory
            : this.props.transfers_dashboard_multiple.new_transfer_data.remitting_bank_account_detail.reference_prefix
                ? this.props.transfers_dashboard_multiple.new_transfer_data.remitting_bank_account_detail.reference_prefix + this.props.app_state.current_client.id
                // : 'FS' + this.props.app_state.current_client.id.toString().padStart(5, '0')
                : this.props.app_state.current_client.id.toString().padStart(5, '0')


        // --------------------------------------------------------------------
        // COMMENT OUT REMITTANCE INSTRUCTIONS FOR NOW - AD 11-02-2024
        // --------------------------------------------------------------------
        // API.post('email', `/client/remittance-instructions`, {
        //     body: {
        //         id: this.props.app_state.current_client.id,
        //         rate: this.props.transfers_dashboard_multiple.new_transfer_data.client_rate,
        //         // settlement_date: moment(set_date).format('DD/MM/YYYY'),
        //         settlement_date: `${dayjs(set_date).format('DD/MM/YYYY')}`,
        //         amount_to: this.props.transfers_dashboard_multiple.new_transfer_data.amount_to,
        //         currency_to: this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.full_name,
        //         currency_to_iso3: this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3,
        //         amount_from: this.props.transfers_dashboard_multiple.new_transfer_data.amount_from,
        //         currency_from: this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.full_name,
        //         currency_from_iso3: this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.iso_alpha_3,
        //         remitting_bank_id: this.props.transfers_dashboard_multiple.new_transfer_data.remitting_bank_account_detail.id,
        //         eba_account_name: this.props.transfers_dashboard_multiple.new_transfer_data.remitting_bank_account_detail.eba_accout_name,
        //         // remittance_reference: this.props.transfers_dashboard_multiple.new_transfer_data.remitting_bank_account_detail.reference_prefix + this.props.app_state.current_client.id,
        //         // remittance_reference: this.props.transfers_dashboard_multiple.new_transfer_data.remitting_bank_account_detail.reference_mandatory ? this.props.transfers_dashboard_multiple.new_transfer_data.remitting_bank_account_detail.reference_mandatory : (this.props.transfers_dashboard_multiple.new_transfer_data.remitting_bank_account_detail.reference_prefix + this.props.app_state.current_client.id),
        //         remittance_reference: adjusted_remittance_reference,
        //         language_id: this.props.app_state.language_id,
        //     },
        // })
        //     .then(response => {
        //         console.log(response);
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     })
        // --------------------------------------------------------------------


        // // Step 2 - Email beneficiary
        // if (this.props.transfers_dashboard_multiple.new_transfer_data.checked_email_beneficiary) {
        //   API.post("email", `/interaction/main`, {
        //     body: {
        //       interaction: "New Transfer Request",
        //       data: {
        //         from: "Step 2 - Email beneficiary",
        //         purpose: "test only"
        //       },
        //       result: "database submission in process"
        //     }});
        // }

        // Step 3 - Create transfer


        let createTransferResult = await API.post(
            'payments',
            `/create/from_dashboard_multi`,
            {
                body: {
                    ...this.props.transfers_dashboard_multiple.new_transfer_data,
                    clientId: this.props.app_state.current_client.id,
                    nickname: `${this.props.transfers_dashboard_multiple.new_transfer_data.amount_from}${` `}
                        ${this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_name} to${` `}
                        ${this.props.transfers_dashboard_multiple.new_transfer_data.amount_to}${` `}
                        ${this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_name}${` `}
                        Settling ${dayjs(set_date).format('DD/MM/YYYY')}`,
                    transactionDateLocal,
                    transactionDateLocal,
                    settlement_date: settlement_date,
                    client_id: this.props.app_state.current_client.id,
                    staff_id: this.props.app_state.current_client.responsible_staff_member,
                    beneficiary_ids: this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id,
                    },
            }
          )
          console.log('handleTransferCreation - createTransferResult', createTransferResult)
          const transferID = createTransferResult.payload.database_result.insertId
      
        
        // this.props.createNewTransfer({
        //     ...this.props.transfers_dashboard_multiple.new_transfer_data,
        //     nickname: `${this.props.transfers_dashboard_multiple.new_transfer_data.amount_from}${` `}
        //     ${this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_name} to${` `}
        //     ${this.props.transfers_dashboard_multiple.new_transfer_data.amount_to}${` `}
        //     ${this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_name}${` `}
        //     Settling ${dayjs(set_date).format('DD/MM/YYYY')}`,
        //     transactionDateLocal,
        //     // record_created_datetime: new Date()
        //     //     .toISOString()
        //     //     .slice(0, 10)
        //     //     .replace('T', ' '),
        //     transactionDateLocal,
        //     // transaction_datetime: new Date()
        //     //     .toISOString()
        //     //     .slice(0, 10)
        //     //     .replace('T', ' '),
        //     settlement_date: settlement_date,
        //     client_id: this.props.app_state.current_client.id,
        //     staff_id: this.props.app_state.current_client.responsible_staff_member,
        //     beneficiary_ids: this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id,
        // })

        const message = `
            A new transfer has been created.<br/>
            <strong>Client: </strong>${this.props.app_state.current_client.nickname}<br/>
            <strong>Client ID: </strong>${this.props.app_state.current_client.id}<br/>
            <strong>From Currency: </strong>${this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.full_name}<br/>
            <strong>From Amount: </strong>${this.props.transfers_dashboard_multiple.new_transfer_data.amount_from}<br/>
            <strong>Rate: </strong>${this.props.transfers_dashboard_multiple.new_transfer_data.client_rate}<br/>
            <strong>To Currency: </strong>${this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.full_name}<br/>
            <strong>To Amount: </strong>${this.props.transfers_dashboard_multiple.new_transfer_data.amount_to}<br/>
            <strong>Settlement Date: </strong>${dayjs(set_date).format('DD/MM/YYYY')}<br/>
            <hr/>
            <strong>Transfer ID: </strong>${transferID}<br/>
            <strong>Backend: </strong><a href="http://forexsport.com/fww/fwwadmin/new_requests.php">http://forexsport.com/fww/fwwadmin/new_requests.php</a><br/>
            <strong>Admin: <strong><a href="https://crm.forexworldwide.com/transfers/edit/${transferID}">Transfer ID ${transferID}</a><br/>
        `
        // console.log(message)
        await API.post('email', '/interaction/backoffice', {
        body: {
            testClient: testClient,
            clientID: this.props.app_state.current_client.id,
            subject: `${testClient ? 'TEST TRANSFER - ' : ''} FWW Client Portal: New Transfer Request Created`,
            message: message,
        }
        });

        this.setState({transferSubmitting: false})
        this.props.history.push(`/transfers/edit/${transferID}?tab=remittance`);

        // this.props.on_proceed_click()
    }

    handleCheckboxToggle = name => event => {
        if (name === 'checked_email_beneficiary') {
            let checked_email_beneficiary = event.target.checked
            this.props.updateNewTransferCreation(
                'checked_email_beneficiary',
                checked_email_beneficiary
            )
        }
    }

    render() {
        const { classes } = this.props
        if (
            !this.props.transfers_dashboard_multiple.new_transfer_data.currency_from ||
            !this.props.transfers_dashboard_multiple.new_transfer_data.currency_to
        ) {
            return null
        }

        if (this.state.transferSubmitting) {
            return (
              <GridContainer style={{height: 300}}>
                <GridItem xs={12}>
                <Alert
                    message="Transfer Submission in Process"
                    description={<><p>
                      Please wait while your transfer submission is in process.<br/>
                      Do not leave or refresh this page until the submission is complete.
                    </p>
                    <p>
                      This should take less than 30 seconds.
                    </p>
                    <p>
                      After this process has completed you will be directed to the remittance instructions page.
                    </p>
                    <p>
                      If you have been waiting longer than 30 second please click here to check your transfer list: 
                      <a href='https://portal.forexworldwide.com/transfers/current_list'>Transfer List</a>.
                    </p>
                    </>}
                    type="info"
                    showIcon
                  />
                </GridItem>
              </GridContainer>
            )
          }

        let request_summary = `You sell
      ${this.props.intl.formatNumber(
          this.props.transfers_dashboard_multiple.new_transfer_data.amount_from,
          {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }
      )} ${this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}
      to buy ${this.props.intl.formatNumber(
          this.props.transfers_dashboard_multiple.new_transfer_data.amount_to,
          {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }
      )} ${this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}
      `

        let table_data_beneficiary = []
        let table_data_purpose = []
        let table_data_invoice = []

        for (let idx in this.props.transfers_dashboard_multiple
            .new_transfer_data.multiple_beneficiary_id) {
            let payment = this.props.transfers_dashboard_multiple
                .new_transfer_data.multiple_beneficiary_id[idx]

            let purpose_of_payment_str = ''
            let p_of_Payment = this.props.app_state.current_client
                .purpose_of_payment_list
            for (var j = 0; j < p_of_Payment.length; j++) {
                if (p_of_Payment[j].id == payment.purpose_of_payment_detail)
                    purpose_of_payment_str = p_of_Payment[j].description
            }
            let item_number = Number(idx) + 1
            let item0 = (
                <React.Fragment>
                    {item_number !== 1 && (
                        <React.Fragment>
                            -------------------------------------
                            <br />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )
            let item1 = (
                <React.Fragment>
                    {item_number}. {payment.beneficiary.nickname} (Acc:{' '}
                    {payment.beneficiary.account_number})<br />
                </React.Fragment>
            )
            let item2 = (
                <React.Fragment>
                    {purpose_of_payment_str}{' '}
                    {payment.detail_1 ? `[reference: ${payment.detail_1}]` : ''}
                    <br />
                </React.Fragment>
            )
            let item3 = (
                <React.Fragment>
                    {this.props.intl.formatNumber(payment.amount)}{' '}
                    {payment.beneficiary.currency_full_name} <br />
                </React.Fragment>
            )
            let data_item = [item0, item1, item2, item3]
            let purpose_item = [
                <React.Fragment>
                    {item_number}. {purpose_of_payment_str}
                    <br />
                </React.Fragment>,
            ]

            let invoice_itm = (
                <React.Fragment>
                    {payment.detail_1
                        ? `${item_number}. [reference: ${payment.detail_1}]`
                        : ''}
                    <br />
                </React.Fragment>
            )

            let invoice_item = [invoice_itm]

            table_data_beneficiary.push(data_item)
            table_data_purpose.push(purpose_item)
            table_data_invoice.push(invoice_item)
        }

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <h4>New Transfer Request - Confirm your details</h4>
                </GridItem>
                <GridItem>
                    <Table
                        tableData={[
                            [
                                'You Sell',
                                <React.Fragment>
                                    <div
                                        className={`currency-flag currency-flag-${this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.iso_alpha_3.toLowerCase()}`}
                                    />{' '}
                                    {this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}{' '}
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard_multiple
                                            .new_transfer_data.amount_from,
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'You Buy',
                                <React.Fragment>
                                    <div
                                        className={`currency-flag currency-flag-${this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3.toLowerCase()}`}
                                    />{' '}
                                    {this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}{' '}
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard_multiple
                                            .new_transfer_data.amount_to,
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'Exchange Rate',
                                <React.Fragment>
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard_multiple
                                            .new_transfer_data.client_rate,
                                        {
                                            minimumFractionDigits: 5,
                                            maximumFractionDigits: 5,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'Payments',
                                <React.Fragment>
                                    {this.props.transfers_dashboard_multiple
                                        .new_transfer_data
                                        .multiple_beneficiary_id[0]
                                        .checked_beneficiary_details ===
                                    true ? (
                                        <React.Fragment>
                                            To Be Advised
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {table_data_beneficiary}
                                            {/* {this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_legal_name} <br/> */}

                                            {/* Hide email beneficiary checkbox because it's useless now*/}
                                            {/* {!!this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_email_main &&
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checked={this.props.transfers_dashboard_multiple.new_transfer_data.checked_email_beneficiary}
                              onClick={this.handleCheckboxToggle("checked_email_beneficiary")}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          label="Email beneficiary"
                        />
                      } */}
                                            {/* Paying to {this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bank_legal_name} */}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>,
                            ],
                            // [
                            //   "Purpose of Payment",
                            //   <React.Fragment>
                            //   {table_data_purpose}
                            //   </React.Fragment>
                            // ],
                            // [
                            //   "Payment Detail",
                            //   <React.Fragment>
                            //   {table_data_invoice}
                            //   </React.Fragment>
                            // ],
                            ['Request Summary', `${request_summary}`],
                        ]}
                    />
                </GridItem>

                {/* <GridContainer justify="flex-end" style={{"textAlign": "right"}}>
          <GridItem xs={12}>
            <Button color="info" onClick={() => this.props.on_back_click()}>
              Modify Request
            </Button>
            <Button color="success" onClick={() => this.handle_transfer_creation()}>
              Proceed ({this.state.seconds} sec)
            </Button>
          </GridItem>
        </GridContainer> */}
            </GridContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
        transfers_dashboard_multiple: state.transfers_dashboard_multiple,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewTransferCreation: (key, value) => {
            dispatch(updateNewTransferCreation(key, value))
        },
        updateNewTransferCreationBeneficiary: array => {
            dispatch(updateNewTransferCreationBeneficiary(array))
        },
        createNewTransfer: data => {
            dispatch(createNewTransfer(data))
        },
    }
}

const DashboardTransferStep2Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(dashboardStyle)(DashboardTransferStep2)))

export default injectIntl(DashboardTransferStep2Container)
