import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import PermIdentity from '@material-ui/icons/PermIdentity'
import withStyles from '@material-ui/core/styles/withStyles'

import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import Card from '../../../components/Card/Card.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import CardHeader from '../../../components/Card/CardHeader'
import CardIcon from '../../../components/Card/CardIcon'
import Button from '../../../components/CustomButtons/Button'
import ForwardsDetailComponent from '../../../components/Forwards/ForwardsDetail'

import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react.jsx'
import {fetchForwardDetails, fetchForwardDrawDowns} from "../../../redux/actions/forwards";
import {connect} from "react-redux";

const styles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}

const ForwardsDetail = ({ fetchForwardDetails, classes, app_state, fetchForwardDrawDowns }) => {
    const history = useHistory()
    const { id: forwardId } = useParams()

    if (!app_state.current_client) {
        return null
    }
    if (app_state.current_client.account_status == 1) {
        history.push('/pending-registration')
    }
    if (app_state.current_client.account_status == 5) {
        history.push('/registration')
    }
    if (app_state.current_client.account_status == 6) {
        history.push('/migrate')
    }

    return (
        <div>
            <Card>
                <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                        <PermIdentity />
                    </CardIcon>
                    <div className={classes.flexEnd}>
                        <h4 className={classes.cardIconTitle}>
                            <FormattedMessage
                                id="forwardList.cardItem1.forwardListing"
                                defaultMessage={`Forwards Detail`}
                            />
                        </h4>
                        <div>
                            <Button
                                round
                                color="info"
                                onClick={() => {
                                    forwardId && fetchForwardDetails(forwardId);
                                    fetchForwardDrawDowns(forwardId);
                                }}
                            >
                                <FormattedMessage
                                    id="newTransfer.cardItem1.refresh"
                                    defaultMessage={`Refresh`}
                                />
                            </Button>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12}>
                            <ForwardsDetailComponent current={true} />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </div>
    )
}

ForwardsDetail.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    fetchForwardDrawDowns: id => dispatch(fetchForwardDrawDowns(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForwardsDetail))
