import React, {Fragment, useEffect, useState} from 'react';
import {API, Auth} from 'aws-amplify';
import {
    Button as AntButton,
    Card as AntCard,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    message, Popconfirm,
    Row,
    Select,
    Switch
} from "antd";
import {useForm} from "antd/es/form/Form";
import {AppUtil} from "../../../AppUtil";
import {useParams} from "react-router-dom";
import TypographyCountry from "../../../components/CurrencySelect/TypographyCountry";
import dayjs from "dayjs";
import {connect} from "react-redux";

const ClientContactsEdit = ({ app_state }) => {

    const params = useParams();

    const [clientContactForm] = useForm();

    const [componentState, setComponentState] = useState({
        countriesList:  [],
        hasClientLogin: false,
        isFormLoading: false,
        contact: null,
        passwordChangeLoading: false,
        clientContactResponse: null
    })

    const options = [
        {label: 'Pass', value: 1, score: 0},
        {label: 'Fail - Former PEP', value: 2, score: 8},
        {label: 'Fail - Domestic PEP', value: 3, score: 26},
        {label: 'Fail - Foreign PEP', value: 4, score: 26},
        {label: 'Fail - International Organisation PEP', value: 5, score: 26}
    ];

    const otherOpts = [
        {label: 'Pass', value: 6, score: 0},
        {label: 'Fail', value: 7, score: 26},
    ];

    useEffect(() => {
        fetchCountries();
    }, []);

    const fetchCountries = () => {
        API.get('countries', '/countries/list_not_deleted', {})
            .then(response => {
                setComponentState(prev => ({...prev, countriesList: response}));
            })
            .catch(error => {
                console.log(error);
                setComponentState(prev => ({...prev, countriesList: []}));
            });
    };

    const saveClientData = (clientContactData) => {
        const prevContact = AppUtil.getChangedProperties(componentState.clientContactResponse, clientContactData);
        if (params.client_contact_id != null) {
            API.post('commons', `/update`, {
                body: {
                    context: 'client_contacts',
                    data: clientContactData,
                    condition: {id: params.client_contact_id}
                }})
                .then(response => {
                    message.success("Client Contact saved..");
                    init();
                })
                .catch(error => {
                    console.log(error);
                    message.error("Client contact saving failed.");
                });
        }
        if (prevContact != null && Object.keys(prevContact).length > 0) {
            API.post("commons", "/mail", {
                body: {
                    to: `Portal Notifications <portalNotifications@forexworldwide.com>`,
                    subject: 'There has been a update in client contact # ' + params.client_contact_id,
                    header: [],
                    data: Object.keys(prevContact).map(key => ({label: key, value: `${prevContact[key].current} ( ${prevContact[key].prev} )`})),
                    footer: [`================================================================================`]
                }
            });
        }
    };


    const createClientLogin = async () => {
        const values = clientContactForm.getFieldsValue();
        if (values.email != null) {
            const loading = message.loading("Creating client login. Please wait..", 0);

            const payload = {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                telephone: null
            }

            const password = AppUtil.generateRandomPassword();
            try {
                const awsResponse = await Auth.signUp({username: payload.email, password: password});
                setComponentState(prev => ({...prev, isFormLoading: true}));
                message.success("Client Registered success");
                message.info("Verifying account. Please wait...");
                await message.success("Login Verified...");
                await API.post("commons", `/update`, {
                    body: {
                        context: 'client_contacts',
                        data: {aws_cognito_id: awsResponse.userSub},
                        condition: {id: params.client_contact_id}
                    }
                });
                try {
                    await API.post("email18", "/client/send-email", {
                        body: {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            password: password,
                            clientId: this.props.app_state.current_client.id
                        }
                    })
                } catch (e) {
                    console.log(e);
                    loading();
                }
                message.success("Client Login created. Please wait.");
                setComponentState(prev => ({...prev, isFormLoading: false, hasClientLogin: true}));
            } catch (error) {
                console.log(error);
                if (error.code && error.code === 'UsernameExistsException') {
                    message.error("An account with the given email already exists.");
                } else if (error.code && error.code === 'InvalidPasswordException') {
                    message.error("Password should contain 1 number 1 uppercase and 1 lower case with special character", 3);
                } else if (error.code && error.code === 'InvalidParameterException') {
                    message.error("Invalid..!! Email address not valid.");
                } else {
                    message.error("Failed to register client. Please try again..");
                }
                setComponentState(prev => ({...prev, isFormLoading: false}));
            } finally {
                loading();
            }
        }
    }

    const init = () => {
        if (params.client_contact_id != null) {
            API.post("commons", "/fetch", {
                body: {
                    context: 'client_contacts',
                    fields: ['*'],
                    condition: {id: params.client_contact_id}
                }
            }).then(([res]) => {
                clientContactForm.setFieldsValue({...res, date_of_birth: res.date_of_birth != null ? dayjs(res.date_of_birth) : null});
                setComponentState(prev => ({...prev, hasClientLogin: res.aws_cognito_id != null, contact: res, clientContactResponse: res }));
            });
        }
    }

    const changePassword = () => {
        setComponentState(prev => ({...prev, passwordChangeLoading: true }));
        if (componentState.contact != null) {
            const loading = message.loading("Changing password. Please wait..", 0);
            const password = AppUtil.generateRandomPassword();
            API.post("usersNew", "/clients/password/change", {
                body: {
                    password: password,
                    cognitoId: componentState.contact.aws_cognito_id
                }
            }).then(res => {
                message.success(`Password changed of login ${componentState.contact.email}`);
                setComponentState(prev => ({...prev, passwordChangeLoading: false}));
                API.post("email18", "/client/reset/send-email", {
                    body: {
                        clientContactId: componentState.contact.id,
                        password: password
                    }
                }).then(res => {
                    message.success("Email Sent.");
                });
            }).catch(err => {
                message.error("Failed to change password. please try again..");
            }).finally(() => loading())
        }
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <Fragment>
            <div style={{position: 'relative', width: '100%', background: '#efefef', padding: '10px'}}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                        <Form form={clientContactForm} onFinish={saveClientData} labelCol={{span: 6}}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                                    <AntCard title={'Contacts'} size={'small'} style={{marginBottom: 10}}>
                                        <Form.Item name={'firstName'} label={'First Name'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'lastName'} label={'Last Name'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'email'} label={'Email'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'position'} label={'Position'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'telephone'} label={'Telephone'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'is_ben_owner'} label={'Benficial Owner'}
                                                   valuePropName={'checked'}>
                                            <Checkbox/>
                                        </Form.Item>
                                        <Form.Item name={'ownership'} label={'Ownership'} rules={[
                                            {
                                                type: "number",
                                                min: 0,
                                                max: 100,
                                                transform: (value) => (value ? Number(value) : value),
                                                message: "Number must be between 0 and 100!",
                                            },
                                        ]}>
                                            <Input />
                                        </Form.Item>
                                    </AntCard>
                                    <AntCard title={'Address'} size={'small'} style={{marginBottom: 10}}>
                                        <Form.Item name={'address_line_1'} label={'Address Line 1'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'address_line_2'} label={'Address Line 2'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'suburb'} label={'suburb'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'state'} label={'state'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'postcode'} label={'Post Code'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'country_id'} label={'Country'}>
                                            <Select optionFilterProp={'alt'}
                                                    options={componentState.countriesList.map(i => ({
                                                        value: i.id,
                                                        label: <TypographyCountry
                                                            iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>,
                                                        alt: i.iso_alpha_3 + " " + i.full_name
                                                    }))}
                                                    placeholder={' -- SELECT --'}></Select>
                                        </Form.Item>
                                    </AntCard>
                                    {/*<AntCard title={'Notes'} size={'small'} style={{ marginBottom: 10 }}>*/}
                                    {/*    <Form.Item name={'notes'} label={'Notes'}>*/}
                                    {/*        <Input.TextArea/>*/}
                                    {/*    </Form.Item>*/}
                                    {/*</AntCard>*/}
                                </Col>
                                <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                                    {/*<AntCard title={'Risk'} size={'small'} style={{ marginBottom: 10 }}>*/}
                                    {/*    <Form.Item labelCol={{span: 8}} name={'pep'}*/}
                                    {/*               label={'Politically Exposed Person'}>*/}
                                    {/*        <Select*/}
                                    {/*            options={options.map(i => ({...i, label: `${i.label} (${i.score})`}))}/>*/}
                                    {/*    </Form.Item>*/}
                                    {/*    <Form.Item name={'sanctions'} label={'Sanctions'} >*/}
                                    {/*        <Select options={otherOpts.map(i => ({*/}
                                    {/*            ...i,*/}
                                    {/*            label: `${i.label} (${i.score})`*/}
                                    {/*        }))}/>*/}
                                    {/*    </Form.Item>*/}
                                    {/*    <Form.Item name={'criminalRecord'} label={'Criminal Record'}>*/}
                                    {/*        <Select options={otherOpts.map(i => ({*/}
                                    {/*            ...i,*/}
                                    {/*            label: `${i.label} (${i.score})`*/}
                                    {/*        }))}/>*/}
                                    {/*    </Form.Item>*/}
                                    {/*    <Form.Item name={'terrorism'} label={'Terrorism'}>*/}
                                    {/*        <Select options={otherOpts.map(i => ({*/}
                                    {/*            ...i,*/}
                                    {/*            label: `${i.label} (${i.score})`*/}
                                    {/*        }))}/>*/}
                                    {/*    </Form.Item>*/}
                                    {/*</AntCard>*/}
                                    <AntCard title={'Personal'} size={'small'} style={{marginBottom: 10}}>
                                        <Form.Item name={'date_of_birth'} label={'Date of Birth'}>
                                            <DatePicker format={'DD/MM/YYYY'}></DatePicker>
                                        </Form.Item>
                                        <Form.Item name={'place_of_birth'} label={'Place of Birth'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'country_of_birth'} label={'Country of Birth'}>
                                            <Select optionFilterProp={'alt'}
                                                    options={componentState.countriesList.map(i => ({
                                                        value: i.id,
                                                        label: <TypographyCountry
                                                            iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>,
                                                        alt: i.iso_alpha_3 + " " + i.full_name
                                                    }))}
                                                    placeholder={' -- SELECT --'}></Select>
                                        </Form.Item>
                                        <Form.Item name={'nationality'} label={'Nationality'}>
                                            <Select optionFilterProp={'alt'}
                                                    options={componentState.countriesList.map(i => ({
                                                        value: i.id,
                                                        label: <TypographyCountry
                                                            iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>,
                                                        alt: i.iso_alpha_3 + " " + i.full_name
                                                    }))}
                                                    placeholder={' -- SELECT --'}></Select>
                                        </Form.Item>
                                    </AntCard>
                                    <AntCard title={'User Access'} size={'small'} style={{marginBottom: 10}}>
                                        {
                                            componentState.hasClientLogin &&
                                            <>
                                                <Form.Item labelCol={{span: 8}} label="Holding Withdrawl"
                                                           name="balanceWithdrawal" valuePropName="checked">
                                                    <Switch/>
                                                </Form.Item>
                                                <Form.Item labelCol={{span: 8}} label="Submit Transfer Access"
                                                           name="submitTransfer" valuePropName="checked">
                                                    <Switch/>
                                                </Form.Item>
                                                <Form.Item labelCol={{span: 8}} label="Add Benficiary Access"
                                                           name="addBeneficiary" valuePropName="checked">
                                                    <Switch/>
                                                </Form.Item>
                                            </>

                                        }
                                        {
                                            app_state.current_client && !app_state.current_client.userClient && <>
                                                <Form.Item labelCol={{span: 8}} label="Enable Login"
                                                           name="loginEnabled" valuePropName="checked">
                                                    <Switch/>
                                                </Form.Item>
                                            </>
                                        }
                                        <Form.Item labelCol={{span: 8}} label={'Change Password'}>
                                            <Popconfirm title={'Are you sure ?'} onConfirm={() => changePassword()}>
                                                <AntButton type={'primary'}>Change Password</AntButton>
                                            </Popconfirm>
                                        </Form.Item>
                                    </AntCard>
                                </Col>
                            </Row>
                            <div style={{display: 'flex', justifyContent: 'start', gap: 10}}>
                                <AntButton type={'primary'} htmlType={'submit'}>Save Contact</AntButton>
                                <AntButton type={'primary'} onClick={() => createClientLogin()}
                                           disabled={componentState.hasClientLogin}>Create Client Login</AntButton>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    app_state: state.app_state
});
const mapDispatchToProps = () => {};

export default connect(mapStateToProps, mapDispatchToProps)(ClientContactsEdit);
