import { connect } from 'react-redux'
import ForwardsDetail from './ForwardsDetail'
import { fetchForwardDetails } from '../../../redux/actions/forwards'

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchForwardDetails: id => dispatch(fetchForwardDetails(id)),
    }
}

const ForwardsDetailContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ForwardsDetail)

export default ForwardsDetailContainer
