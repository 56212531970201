import React from 'react'
import { connect } from 'react-redux'
import { clearNewTransfer } from '../../redux/actions/transfers_dashboard'

import withStyles from '@material-ui/core/styles/withStyles'
import dashboardStyle from '../../views/Pages/DashboardPageStyle'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

//core for react-intl
import { injectIntl } from 'react-intl'

import Table from 'components/Table/Table.jsx'
import { withRouter } from 'react-router-dom'

const moment = require('moment')

class ShortcutTransferStep3 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            current_step: 3,
        }
    }

    componentDidMount() {
        //this.startTimer();
        this.stopTimer()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //this.stopTimer();
        // if prefill changes, clear new transfer data and then go back to the first step
        if (prevProps.prefill !== this.props.prefill) {
            // clearNewTransfer in DashboardTransfer
            this.props.switch_to_step1()
        }
    }

    stopTimer = () => {
        this.go = false
        this.setState({
            seconds: 0,
            time: 0,
        })
        clearInterval(this.timer)
        this.timer = null
    }

    handle_email_beneficiary() {
        // console.log("handle_email_beneficiary")
    }

    handle_new_transfer() {
        this.props.clearNewTransfer()
        this.props.switch_to_step1()
        // this.props.history.push(`/dashboard`);
    }

    handle_view_transfers() {
        this.props.history.push(`/transfers/list`)
    }

    render() {
        const { classes } = this.props

        let request_summary = `You sell
      ${this.props.intl.formatNumber(
          this.props.transfers_dashboard.new_transfer_data.amount_from,
          {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }
      )} ${this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}
      to buy ${this.props.intl.formatNumber(
          this.props.transfers_dashboard.new_transfer_data.amount_to,
          {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }
      )} ${this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}
      `

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <h4>
                        New Transer Request - Submitted and pending confirmation
                    </h4>
                    <p>
                        Thank you! Your FX transfer request has been submitted.{' '}
                        <br />
                        You can view the status of your FX transactions in
                        Transfers. <br />
                        Confirmation has been emailed to{' '}
                        {this.props.app_state.current_client.email}
                    </p>
                </GridItem>
                <GridItem>
                    <Table
                        tableData={[
                            [
                                'You Sell',
                                <React.Fragment>
                                    <div
                                        className={`currency-flag currency-flag-${this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toLowerCase()}`}
                                    />{' '}
                                    {this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}{' '}
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard
                                            .new_transfer_data.amount_from,
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'You Buy',
                                <React.Fragment>
                                    <div
                                        className={`currency-flag currency-flag-${this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toLowerCase()}`}
                                    />{' '}
                                    {this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}{' '}
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard
                                            .new_transfer_data.amount_to,
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'Exchange Rate',
                                <React.Fragment>
                                    {this.props.intl.formatNumber(
                                        this.props.transfers_dashboard
                                            .new_transfer_data.client_rate,
                                        {
                                            minimumFractionDigits: 5,
                                            maximumFractionDigits: 5,
                                        }
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'Beneficiary',
                                <React.Fragment>
                                    {this.props.transfers_dashboard
                                        .new_transfer_data
                                        .multiple_beneficiary_id[0]
                                        .checked_beneficiary_details ===
                                    true ? (
                                        <React.Fragment>
                                            To Be Advised
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {
                                                this.props.transfers_dashboard
                                                    .new_transfer_data
                                                    .multiple_beneficiary_id[0]
                                                    .beneficiary.ben_legal_name
                                            }{' '}
                                            <br />
                                            {/* Paying to {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bank_legal_name} */}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>,
                            ],
                            [
                                'Purpose of Payment',
                                `${
                                    this.props.transfers_dashboard
                                        .new_transfer_data
                                        .multiple_beneficiary_id[0]
                                        .purpose_of_payment_detail_nickname
                                }`,
                            ],
                            [
                                'Payment Detail',
                                `${
                                    this.props.transfers_dashboard
                                        .new_transfer_data
                                        .multiple_beneficiary_id[0].detail_1
                                }`,
                            ],
                            ['Request Summary', `${request_summary}`],
                        ]}
                    />
                </GridItem>
            </GridContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
        transfers_dashboard: state.transfers_dashboard,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearNewTransfer: () => {
            dispatch(clearNewTransfer())
        },
    }
}

const ShortcutTransferStep3Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(ShortcutTransferStep3))

// export default DashboardTransferStep3Container;
export default withRouter(injectIntl(ShortcutTransferStep3Container))
