import React from 'react'
import { Switch } from 'react-router-dom'

import withStyles from '@material-ui/core/styles/withStyles'

import widgetsRoutes from 'routes/widgets.jsx'
import UnauthenticatedRoute from '../components/Routes/UnauthenticatedRoute'

const style = {
    root: {
        height: 'auto',
        minHeight: '100vh',
        position: 'relative',
        top: '0',
        width: '100%',
        backgroundColor:'#fff'
    },
}

class Widgets extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        document.body.style.overflow = 'unset'
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <Switch>
                    {widgetsRoutes.map((prop, key) => {
                        return (
                            <UnauthenticatedRoute
                                path={prop.path}
                                component={prop.component}
                                key={key}
                                props={{
                                    isAuthenticated: false,
                                    userHasAuthenticated: false,
                                    set_current_user: null,
                                }}
                            />
                        )
                    })}
                </Switch>
            </div>
        )
    }
}

export default withStyles(style)(Widgets)
