import React from 'react'

// core components
import Wizard from 'components/Wizard/Wizard.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

import Step1TransferToContainer from './WizardSteps/Step1TransferToContainer'
import Step2PayingToContainer from './WizardSteps/Step2PayingToContainer'
import Step3ConfirmContainer from './WizardSteps/Step3ConfirmContainer'

import { withRouter } from 'react-router-dom'

import { FormattedMessage } from 'react-intl'

//setup for language components
let languageObj_en_json = require('../../translations/en.json')
let languageObj_zh_json = require('../../translations/zh.json')
let languageObj_de_json = require('../../translations/de.json')
let languageObj_es_json = require('../../translations/es.json')
let languageObj_fr_json = require('../../translations/fr.json')
let languageObj_it_json = require('../../translations/it.json')
let languageObj_pt_json = require('../../translations/pt.json')

class CreateNewTransferWizard extends React.Component {
    getNextBusinessDay(d, n) {
        d = new Date(d.getTime())
        var day = d.getDay()
        d.setDate(
            d.getDate() +
                n +
                (day === 6 ? 2 : +!day) +
                Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2
        )
        return d
    }

    componentDidMount() {
        this.props.startNewTransferCreation()
    }

    finished = async () => {
        let set_date = this.getNextBusinessDay(new Date(), 2)
        let settlement_date = set_date
            .toISOString()
            .slice(0, 10)
            .replace('T', ' ')

        await this.props.createNewTransfer({
            ...this.props.transfers_2.new_transfer_data,
            record_created_datetime: new Date()
                .toISOString()
                .slice(0, 10)
                .replace('T', ' '),
            transaction_datetime: new Date()
                .toISOString()
                .slice(0, 10)
                .replace('T', ' '),
            settlement_date: settlement_date,
            client_id: this.props.app_state.current_client.id,
            staff_id: this.props.app_state.current_client
                .responsible_staff_member,
            beneficiary_ids: this.props.transfers_2.new_transfer_data
                .multiple_beneficiary_id,
            // client_rate: this.props.payments.new_payment_data.payment_rate
        })

        this.props.history.push(`/transfers/list`)
    }

    render() {
        let languageObj = ''
        switch (this.props.language.language_current_ui) {
            case 'en':
                languageObj = languageObj_en_json
                break
            case 'es':
                languageObj = languageObj_es_json
                break
            case 'de':
                languageObj = languageObj_de_json
                break
            case 'zh':
                languageObj = languageObj_zh_json
                break
            case 'fr':
                languageObj = languageObj_fr_json
                break
            case 'it':
                languageObj = languageObj_it_json
                break
            case 'pt':
                languageObj = languageObj_pt_json
                break
            default:
                languageObj = languageObj_en_json
        }
        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                    <Wizard
                        validate
                        steps={[
                            {
                                //stepName: "Transfer",
                                stepName: languageObj['newTransfer.stepName0'],
                                stepComponent: Step1TransferToContainer,
                                stepId: 'transfer_to',
                            },
                            {
                                //stepName: "Paying To",
                                stepName: languageObj['newTransfer.stepName1'],
                                stepComponent: Step2PayingToContainer,
                                stepId: 'paying_to',
                            },
                            {
                                stepName: languageObj['newTransfer.stepName3'],
                                //stepName: "Confirm",
                                stepComponent: Step3ConfirmContainer,
                                stepId: 'confirm',
                            },
                        ]}
                        title={
                            <FormattedMessage
                                id="newTransfer.title"
                                defaultMessage={`Start a New FX Transfer`}
                            />
                            //"Start a New FX Transfer"
                        }
                        subtitle={
                            <FormattedMessage
                                id="newTransfer.subtitle"
                                defaultMessage={`Transfer from one currency to make payment in another`}
                            />
                        }
                        finishButtonClick={() => this.finished()}
                    />
                </GridItem>
            </GridContainer>
        )
    }
}

export default withRouter(CreateNewTransferWizard)
