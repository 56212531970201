import {
    TRANSFERS_DASHBOARD_MULTIPLE_UPDATE_NEW_TRANSFER_CREATION,
    TRANSFERS_DASHBOARD_MULTIPLE_CHANGE_CURRENT_STEP,
    TRANSFERS_DASHBOARD_MULTIPLE_UPDATE_MAPDATA,
    TRANSFERS_DASHBOARD_MULTIPLE_CLEAR_NEW_TRANSFER,
} from '../actions/transfers_dashboard_multiple.js'

const initialState = {
    current_step: 1,
    map_data: {},
    transfer_current_ui: 'list',
    is_fetching: false,
    did_invalidate: false,
    transfer_list: [],
    transfer_detail_fetching: false,
    transfer_detail_loaded: false,
    transfer_detail_id: null,
    new_transfer_data: {
        amount_from: '',
        amount_to: '',
        multiple_beneficiary_id: [{ detail_1: '' }],
    },
    new_transfer_type: null,
    new_transfer_submitted: false,
    new_transfer_created: false,
}

function transfers_dashboard_multiple(state = initialState, action) {
    switch (action.type) {
        case TRANSFERS_DASHBOARD_MULTIPLE_CHANGE_CURRENT_STEP:
            return Object.assign({}, state, {
                current_step: action.value,
            })

        case TRANSFERS_DASHBOARD_MULTIPLE_UPDATE_MAPDATA:
            return Object.assign({}, state, {
                map_data: action.value,
            })

        case TRANSFERS_DASHBOARD_MULTIPLE_CLEAR_NEW_TRANSFER:
            return Object.assign({}, state, {
                map_data: {},
                new_transfer_data: {
                    amount_from: '',
                    amount_to: '',
                    multiple_beneficiary_id: [{ detail_1: '' }],
                },
            })

        case TRANSFERS_DASHBOARD_MULTIPLE_UPDATE_NEW_TRANSFER_CREATION:
            return Object.assign({}, state, {
                new_transfer_data: Object.assign({}, state.new_transfer_data, {
                    [action.value.key]: action.value.value,
                }),
            })

        default:
            return state
    }
}

export default transfers_dashboard_multiple
