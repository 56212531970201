import React from 'react'
import PropTypes from 'prop-types'

// import Datetime from "react-datetime";
// import {matchSorter} from 'match-sorter'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// import Grid from "@material-ui/core/Grid";

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'

// @material-ui/icons
// import Today from "@material-ui/icons/Today";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import AvTimer from "@material-ui/icons/AvTimer";

// import FormControl from "@material-ui/core/FormControl";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Switch from "@material-ui/core/Switch";

// import staffEditStyles from "./StaffEditStyles";
import CardHeader from '../../components/Card/CardHeader'
// import CardIcon from "../../components/Card/CardIcon";
// import PermIdentity from "@material-ui/icons/PermIdentity";
import CustomInput from '../CustomInput/CustomInput'
// import Button from "../CustomButtons/Button";
// import NavPills from "components/NavPills/NavPills.jsx";
// import Clearfix from "components/Clearfix/Clearfix.jsx";
import { API, Storage } from 'aws-amplify'
// import { loadStaffMemberDetailsSuccess } from "../../redux/actions/staff";
// import FormControl from "@material-ui/core/FormControl";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Switch from "@material-ui/core/Switch";

import { cardTitle } from '../../assets/jss/material-dashboard-pro-react'
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
/*
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
*/
// import appConfig from "../../config/appConfig";

require('react-datetime')

var moment = require('moment')
const staffEditStyles = {
    ...switchStyles,
    ...selectStyles,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px',
    },
    registerButton: {
        float: 'right',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}

class IdentificationEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            is_loading: true,

            country_list: [],
            country_list_prio: [],
            admin_identification_categories_list: [],
            identification_status_list: [],
        }
    }

    componentDidMount = async () => {
        // console.log("componentDidMount");
        // console.log(this.props);
        // this.props.fetchClientDetails(this.props.clients.client_detail_id);

        if (this.props.identification_record_id) {
            this.props.loadIdentificationRecordDetailsRequest()
            API.get(
                'identification',
                `/get/id/${this.props.identification_record_id}`
            )
                .then(async identification_record => {
                    // console.log(identification_record);
                    let {
                        id,
                        nickname,
                        client_id,
                        client,
                        document_category_id,
                        document_category,
                        document_expiry,
                        document_number,
                        document_issue_state,
                        document_issue_country,
                        file_id,
                        record_created_datetime,
                        record_created_staff_id,
                        record_created_staff_first_name,
                        record_created_staff_last_name,
                        document_detail,
                        id_status,
                        id_notes_client,
                    } = identification_record

                    let file_url
                    if (file_id) {
                        file_url = await Storage.get(file_id, { expires: 60 })
                    }
                    this.setState({
                        loaded: true,
                        edit_mode: false,
                        is_loading: false,
                        id,
                        nickname,
                        client_id,
                        client,
                        document_category_id,
                        document_category,
                        document_expiry,
                        document_number,
                        document_issue_state,
                        document_issue_country,
                        file_id,
                        file_url,
                        record_created_datetime,
                        record_created_staff_id,
                        record_created_staff_first_name,
                        record_created_staff_last_name,
                        document_detail,
                        id_status,
                        id_notes_client,
                    })
                    this.props.loadIdentificationRecordDetailsSuccess()
                })
                .catch(error => {
                    console.log(error)
                })

            API.get('countries', `/countries/list_not_deleted`)
                .then(response => {
                    this.setState({
                        // country_list: response.fullList,
                        country_list_prio: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })

            //API call for get table data from table Admin_identification_categories
            API.get('identification', `/get/categories`)
                .then(response => {
                    // console.log(response);
                    this.setState({
                        admin_identification_categories_list: response,
                    })
                    //console.log(this.state.admin_identification_categories_list[0].nickname);
                })
                .catch(error => {
                    console.log(error)
                })
            API.get('identification', `/get/identification_status`)
                .then(response => {
                    // console.log(response);
                    this.setState({
                        identification_status_list: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (prevProps.client_detail_id !== this.props.client_detail_id) {
            this.props.loadIdentificationRecordDetailsRequest()
            API.get(
                'identification',
                `/get/id/${this.props.identification_record_id}`
            )
                .then(async identification_record => {
                    // console.log(identification_record);
                    let {
                        id,
                        nickname,
                        client_id,
                        client,
                        document_category_id,
                        document_category,
                        document_expiry,
                        document_number,
                        document_issue_state,
                        document_issue_country,
                        file_id,
                        record_created_datetime,
                        record_created_staff_id,
                        record_created_staff_first_name,
                        record_created_staff_last_name,
                        document_detail,
                        id_status,
                        id_notes_client,
                    } = identification_record

                    let file_url
                    if (file_id) {
                        file_url = await Storage.get(file_id, { expires: 60 })
                    }
                    this.setState({
                        loaded: true,
                        edit_mode: false,
                        is_loading: false,
                        id,
                        nickname,
                        client_id,
                        client,
                        document_category_id,
                        document_category,
                        document_expiry,
                        document_number,
                        document_issue_state,
                        document_issue_country,
                        file_id,
                        file_url,
                        record_created_datetime,
                        record_created_staff_id,
                        record_created_staff_first_name,
                        record_created_staff_last_name,
                        document_detail,
                        id_status,
                        id_notes_client,
                    })
                    this.props.loadIdentificationRecordDetailsSuccess()
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    render() {
        const { classes } = this.props
        if (!this.state.loaded) {
            return null
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <div className={classes.flexEnd}>
                                    <h4 className={classes.cardIconTitle}>
                                        Identification Document Details
                                    </h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    labelText="Document nickname"
                                    id="nickname"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        disabled: true,
                                        value: this.state.nickname || '',
                                    }}
                                />
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Client"
                                            id="client"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value: this.state.client || '',
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Document category"
                                            id="document_category"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value:
                                                    this.state
                                                        .document_category ||
                                                    '',
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Document number"
                                            id="document_number"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value:
                                                    this.state
                                                        .document_number || '',
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Document expiry"
                                            id="document_expiry"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value:
                                                    moment(
                                                        this.state
                                                            .document_expiry
                                                    ).format('YYYY-MM-DD') ||
                                                    '',
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Document issue state"
                                            id="document_issue_state"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value:
                                                    this.state
                                                        .document_issue_state ||
                                                    '',
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <FormControl
                                            fullWidth
                                            className={
                                                classes.selectFormControl
                                            }
                                        >
                                            <InputLabel
                                                htmlFor="simple-select"
                                                className={classes.selectLabel}
                                            >
                                                Issue Country
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className:
                                                        classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}
                                                value={
                                                    this.state
                                                        .document_issue_country ||
                                                    '0'
                                                }
                                                onChange={
                                                    this.handleSelectChange
                                                }
                                                inputProps={{
                                                    disabled: true,
                                                    name:
                                                        'document_issue_country',
                                                    id:
                                                        'document_issue_country',
                                                }}
                                            >
                                                <MenuItem
                                                    key="0x0"
                                                    value="0"
                                                    disabled
                                                    classes={{
                                                        root:
                                                            classes.selectMenuItem,
                                                    }}
                                                >
                                                    Select Country
                                                </MenuItem>

                                                {this.state.country_list_prio
                                                    .sort(
                                                        (a, b) =>
                                                            a.list_priority <
                                                            b.list_priority
                                                    )
                                                    .map(item => {
                                                        return (
                                                            <MenuItem
                                                                key={item.id}
                                                                classes={{
                                                                    root:
                                                                        classes.selectMenuItem,
                                                                    selected:
                                                                        classes.selectMenuItemSelected,
                                                                }}
                                                                value={item.id}
                                                            >
                                                                {item.full_name}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                </GridContainer>
                                <CustomInput
                                    labelText="Document detail"
                                    id="document_detail"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        disabled: true,
                                        value: this.state.document_detail || '',
                                    }}
                                />
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Record created"
                                            id="record_created_datetime"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value:
                                                    moment(
                                                        this.state
                                                            .record_created_datetime
                                                    ).format(
                                                        'YYYY-MM-DD HH:mm:ss'
                                                    ) || '',
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Record created by"
                                            id="record_created_staff_last_name"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value:
                                                    `${
                                                        this.state
                                                            .record_created_staff_last_name
                                                    }, ${
                                                        this.state
                                                            .record_created_staff_first_name
                                                    }` || '',
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <div className={classes.flexEnd}>
                                    <h4 className={classes.cardIconTitle}>
                                        Status and Notes
                                    </h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <FormControl
                                    fullWidth
                                    error={!this.state.document_category_id > 0}
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                    >
                                        Status
                                    </InputLabel>
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu,
                                        }}
                                        classes={{
                                            select: classes.select,
                                        }}
                                        value={this.state.id_status}
                                        onChange={this.handleSelectChange}
                                        inputProps={{
                                            disabled: true,
                                            name: 'id_status',
                                            id: 'id_status',
                                        }}
                                    >
                                        <MenuItem
                                            key="select_document_category_key"
                                            disabled
                                            classes={{
                                                root: classes.selectMenuItem,
                                            }}
                                        >
                                            Select document category
                                        </MenuItem>
                                        {this.state.identification_status_list
                                            .sort((a, b) =>
                                                a.nickname.localeCompare(
                                                    b.nickname
                                                )
                                            )
                                            .map(item => {
                                                return (
                                                    <MenuItem
                                                        key={item.id}
                                                        classes={{
                                                            root:
                                                                classes.selectMenuItem,
                                                            selected:
                                                                classes.selectMenuItemSelected,
                                                        }}
                                                        value={item.id}
                                                    >
                                                        {item.nickname.toUpperCase()}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                                <CustomInput
                                    labelText="Notes"
                                    id="id_notes_client"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        disabled: true,
                                        value: this.state.id_notes_client || '',
                                    }}
                                />
                            </CardBody>
                        </Card>

                        <Card>
                            <CardHeader color="rose" icon>
                                <div className={classes.flexEnd}>
                                    <h4 className={classes.cardIconTitle}>
                                        Document
                                    </h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    labelText="File id"
                                    id="file_id"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        disabled: true,
                                        value: this.state.file_id || '',
                                    }}
                                />
                                <div className="fileinput text-center">
                                    <div className={'thumbnail'}>
                                        {/* Take out this line of because of the side effect that we allow user use pdf files - #836 Improvements */}
                                        {/* <img src={this.state.file_url} alt="..." /> */}
                                    </div>
                                </div>
                                <br />
                                <a href={this.state.file_url} target="_blank">
                                    Open image in new tab
                                </a>
                                <br />
                                <strong>
                                    Note: this link will expire in 60 seconds
                                </strong>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

IdentificationEdit.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(staffEditStyles)(IdentificationEdit)
