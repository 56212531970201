import {
    FETCH_REGISTRATION_LIST_REQUEST,
    FETCH_REGISTRATION_LIST_SUCCESS,
    REGISTRATION_CREATE_NEW_SHOW_UI,
    REGISTRATION_CREATE_NEW_START,
    REGISTRATION_CREATE_NEW_CANCEL,
    REGISTRATION_CREATE_NEW_COMPLETE,
    REGISTRATION_SELECT_UI,
    REGISTRATION_ENTITIES_LIST_IS_DIRTY,
    REGISTRATION_REGISTRATION_EDIT,
    REGISTRATION_NEW_REGISTRATION_CREATE,
    REGISTRATION_FINISHED,
} from '../actions/registration.js'

const initialState = {
    is_finished: false,
    is_fetching: false,
    registration: [],
    did_invalidate: false,
    registration_list_is_dirty: false,
    registration_current_ui: 'list',
    registration_create_new_in_process: false,
    registration_id: '',
    new_registration_data: null,
}

function registration(state = initialState, action) {
    switch (action.type) {
        case FETCH_REGISTRATION_LIST_REQUEST:
            console.log(action)
            return Object.assign({}, state, {
                is_fetching: true,
            })

        case REGISTRATION_FINISHED:
            return Object.assign({}, state, {
                is_finished: true,
            })
        case FETCH_REGISTRATION_LIST_SUCCESS:
            return Object.assign({}, state, {
                is_fetching: false,
                registration: action.registration,
                lastUpdated: action.receivedAt,
            })

        case REGISTRATION_CREATE_NEW_SHOW_UI:
            return Object.assign({}, state, {
                registration_current_ui: 'list',
            })

        case REGISTRATION_CREATE_NEW_START:
            return Object.assign({}, state, {
                registration_create_new_in_process: true,
            })

        case REGISTRATION_CREATE_NEW_CANCEL:
            return Object.assign({}, state, {
                registration_create_new_in_process: false,
                registration_current_ui: 'list',
            })

        case REGISTRATION_CREATE_NEW_COMPLETE:
            return Object.assign({}, state, {
                registration_create_new_in_process: false,
                registration_current_ui: 'list',
                registration_list_is_dirty: true,
            })

        case REGISTRATION_SELECT_UI:
            return Object.assign({}, state, {
                registration_current_ui: action.value,
            })
        case REGISTRATION_ENTITIES_LIST_IS_DIRTY:
            return Object.assign({}, state, {
                registration_list_is_dirty: action.value,
            })
        case REGISTRATION_REGISTRATION_EDIT:
            return Object.assign({}, state, {
                registration_create_new_in_process: false,
                registration_current_ui: 'edit',
                registration_id: action.value,
            })
        case REGISTRATION_NEW_REGISTRATION_CREATE:
            return Object.assign({}, state, {
                new_registration_data: Object.assign(
                    {},
                    state.new_registration_data,
                    {
                        [action.value.key]: action.value.value,
                    }
                ),
            })
        default:
            return state
    }
}

export default registration
